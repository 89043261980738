import { CounsellorActions } from 'store/Counsellor';
const { COUNSELLOR } = CounsellorActions;

const INITIAL_STATE = {
  counsellorLoading: false,
  counsellor: {
    candidates_list: {},
    startCounselling: {},
    candidateCurrentStage: {},
    sendToVerifier: {},
    leadCurrentStage: '',
    sendProspectFormOnline: '',
  },
  dashboardStats: {
    counselled_lead: '',
    awaiting_payment_leads: '',
    sent_to_verifier_leads: '',
    prospectus_form_sent: '',
    form_submission_pending: '',
  },
  prospectus: {
    interestedProspectusPrice: '',
    interestedProspectusError: {},
    selectedProspectus: '',
    selectedProspectusError: '',
    selectedProspectList: '',
    selectedProspectListError: '',
    deleteSelectedProspect: '',
    deleteSelectedProspectError: '',
    paymentLink: '',
    paymentLinkError: '',
    updatePaymentLink: '',
    updatePaymentLinkError: '',
    appFormDetails: '',
    appFormDetailsError: '',
  },
};

const counsellorReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case COUNSELLOR.LOADING: {
      return {
        ...state,
        counsellorLoading: action.payload,
      };
    }
    case COUNSELLOR.GET_COUNSELLOR_STATS_SUCCESS: {
      return {
        ...state,
        dashboardStats: action.payload,
      };
    }
    case COUNSELLOR.GET_COUNSELLOR_STATS_ERROR: {
      return {
        ...state,
        dashboardStats: action.payload,
      };
    }

    case COUNSELLOR.GET_COUNSELLOR_LEADS_SUCCESS: {
      return {
        ...state,
        counsellor: {
          candidates_list: action.payload,
        },
      };
    }

    case COUNSELLOR.GET_COUNSELLOR_LEADS_ERROR: {
      return {
        ...state,
        counsellor: {
          candidates_list: action.payload,
        },
      };
    }

    case COUNSELLOR.RESET_CANDIDATES_LIST: {
      return {
        ...state,
        counsellor: {
          candidates_list: [],
        },
      };
    }

    case COUNSELLOR.RESET_SELECTED_PROSPECTUS: {
      return {
        ...state,
        prospectus: [],
      };
    }

    case COUNSELLOR.GET_INTERESTED_PROSPECTUS_SUCCESS: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          interestedProspectusPrice: action.payload,
        },
      };
    }

    case COUNSELLOR.GET_INTERESTED_PROSPECTUS_ERROR: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          interestedProspectusError: action.payload,
        },
      };
    }
    case COUNSELLOR.RESET_INTERESTED_PROSPECTUS: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          interestedProspectus: [],
        },
      };
    }

    case COUNSELLOR.ADD_SELECTED_PROSPECT_SUCCESS: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          selectedProspectus: action.payload,
        },
      };
    }

    case COUNSELLOR.ADD_SELECTED_PROSPECT_ERROR: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          selectedProspectusError: action.payload,
        },
      };
    }

    case COUNSELLOR.RESET_ADD_SELECTED_PROSPECT: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          selectedProspectus: '',
          interestedProspectusPrice: '',
          deleteSelectedProspect: '',
          paymentLink: '',
        },
        counsellor: {
          ...state.counsellor,
          startCounselling: '',
          leadCurrentStage: '',
          sendProspectFormOnline: '',
        },
      };
    }

    case COUNSELLOR.GET_SELECTED_PROSPECT_LIST_SUCCESS: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          selectedProspectList: action.payload,
        },
      };
    }

    case COUNSELLOR.GET_SELECTED_PROSPECT_LIST_ERROR: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          selectedProspectListError: action.payload,
        },
      };
    }

    case COUNSELLOR.DELETE_SELECTED_PROSPECT_SUCCESS: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          deleteSelectedProspect: action.payload,
        },
      };
    }

    case COUNSELLOR.DELETE_SELECTED_PROSPECT_ERROR: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          deleteSelectedProspectError: action.payload,
        },
      };
    }

    case COUNSELLOR.SEND_PAYMENT_LINK_SUCCESS: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          paymentLink: action.payload,
        },
      };
    }

    case COUNSELLOR.SEND_PAYMENT_LINK_ERROR: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          paymentLinkError: action.payload,
        },
      };
    }

    case COUNSELLOR.UPDATE_PAYMENT_LINK_SUCCESS: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          paymentLink: action.payload,
        },
      };
    }

    case COUNSELLOR.UPDATE_PAYMENT_LINK_ERROR: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          paymentLinkError: action.payload,
        },
      };
    }

    case COUNSELLOR.START_CANDIDATE_COUNSELLING_SUCCESS: {
      return {
        ...state,
        counsellor: {
          ...state.counsellor,
          startCounselling: action.payload,
        },
      };
    }

    case COUNSELLOR.START_CANDIDATE_COUNSELLING_ERROR: {
      return {
        ...state,
        counsellor: {
          ...state.counsellor,
          startCounselling: action.payload,
        },
      };
    }

    case COUNSELLOR.SEND_TO_VERIFIER_SUCCESS: {
      return {
        ...state,
        counsellor: {
          ...state.counsellor,
          sendToVerifier: action.payload,
        },
      };
    }

    case COUNSELLOR.SEND_TO_VERIFIER_ERROR: {
      return {
        ...state,
        counsellor: {
          ...state.counsellor,
          sendToVerifier: '',
        },
      };
    }

    case COUNSELLOR.GET_CURRENT_STAGE_SUCCESS: {
      return {
        ...state,
        counsellor: {
          ...state.counsellor,
          leadCurrentStage: action.payload,
        },
      };
    }

    case COUNSELLOR.GET_CURRENT_STAGE_ERROR: {
      return {
        ...state,
        counsellor: {
          ...state.counsellor,
          leadCurrentStage: '',
        },
      };
    }

    case COUNSELLOR.SEND_PROSPECT_ONLINE_SUCCESS: {
      return {
        ...state,
        counsellor: {
          ...state.counsellor,
          sendProspectFormOnline: action.payload,
        },
      };
    }

    case COUNSELLOR.SEND_PROSPECT_ONLINE_ERROR: {
      return {
        ...state,
        counsellor: {
          ...state.counsellor,
          sendProspectFormOnline: action.payload,
        },
      };
    }

    case COUNSELLOR.GET_APP_FORM_DETAILS_SUCCESS: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          appFormDetails: action.payload,
        },
      };
    }

    case COUNSELLOR.GET_APP_FORM_DETAILS_ERROR: {
      return {
        ...state,
        prospectus: {
          ...state.prospectus,
          appFormDetailsError: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default counsellorReducer;
