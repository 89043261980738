import React from 'react';

const HotelClassIcon = ({ fill, height = 30, width = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      fill={fill}
    >
      <path d="m668 716 172-148 85 7-189 162 57 246-74-45-51-222Zm-93-289-57-134 32-77 92 217-67-6ZM263 851l157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L20 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-345Z" />
    </svg>
  );
};

export default HotelClassIcon;
