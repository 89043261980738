import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles({
  readingThumbnail: (props) => ({
    width: '10rem',
    height: '12rem',
    border: `1px solid ${props.palette.common.thumbnail_border}`,
    borderRadius: '0.6875rem',
  }),
  readingThumbnailAuto: (props) => ({
    width: '100%',
    height: '75%',
    border: `1px solid ${props.palette.common.thumbnail_border}`,
    borderRadius: '0.5rem',
  }),
  readingThumbnailContainer: (props) => ({
    display: 'flex',
    padding: '10px',
    justifyContent: 'initial',
    alignItems: 'center',
    gap: '10px'
  }),
  fileViewLink: () => ({
    cursor: 'pointer'
  })
});

export default useStyles;