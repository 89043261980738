import { ROUTES } from 'constants/routesConstants';

export const moocAdminItems = (t) => [
  {
    item: t('moocAdmin.affiliateCourses.affiliateCourses'),
    link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.AFFILIATE_COURSES}`,
  },
  {
    item: t('moocAdmin.affiliateCourses.publishCourses'),
    link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.PUBLISH_COURSES}`,
  },
  {
    item: t('monitorSubscriptions'),
    link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.MONITOR_SUBSCRIPTIONS}`,
  },
  {
    item: t('moocAdmin.MOOCSettings.moocsetting'),
    link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.MOOC_SETTINGS}`,
  },
  // {
  //   item: 'MOOC Reports',
  //   link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.MOOC_REPORT}`,
  // },
];
export const moocLearningPath = (t) => [
  {
    item: t('moocAdmin.LearningPath.LearningPath'),
    link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.LEARNING_PATH}`,
  },
  // {
  //   item: 'Monitor Learning Path',
  //   link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.MONITOR_LEARNING_PATH}`,
  // },
];
export const peerGroup = (t) => [
  {
    item: 'Peer Group',
    link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.LEARNING_PATH}`,
  },
];

export const registarItems = (t) => [
  { item: t('staff'), link: '/manage-staff' },
  { item: t('student'), link: '/manage-students' },
  { item: 'OBE', link: ROUTES.OBE.ROOT },
  { item: t('courseManagement.programs'), link: '/manage-course' },
  { item: t('exams.exams'), link: ROUTES.EXAMS.ROOT },
  { item: t('fees.fees'), link: ROUTES.FEES.ROOT },
  { item: t('dmc.result'), link: ROUTES.EVALUATION.ROOT },
  { item: t('verifier.candidates'), link: ROUTES.COUNSELLOR.CANDIDATES_LIST },
  { item: t('prospectusAndApplication'), link: ROUTES.PROSPECTUS.ROOT },
  { item: t('badgeCreation.creation'), link: ROUTES.BADGE.ROOT },
  { item: t('reports.reportTitle'), link: '/reports' },
  {
    item: t('OrdinanaceSettings.ordinanceSettings'),
    link: ROUTES.ORDINANCE_SETTING.ROOT,
  },
];

export const studentItems = (t) => [
  {
    item: t('management.attendance'),
    link: `${ROUTES.ATTENDANCE.MAIN_ATTENDANCE_PAGE}`,
  },
  { item: t('exams.exams_and_results'), link: ROUTES.EXAMS_AND_RESULTS.ROOT },
  { item: 'OBE', link: ROUTES.OBE.ROOT },
  { item: t('discussionBoard'), link: ROUTES.DISCUSSION.ROOT },
  // { item: 'Assets', link: ROUTES.ASSETS.ROOT },
];
export const parentItems = (t) => [
  {
    item: t('management.attendance'),
    link: `${'/attendance/student'}`,
  },
  { item: t('exams.exams_and_results'), link: ROUTES.EXAMS_AND_RESULTS.ROOT },
  { item: t('evaluation.title'), link: ROUTES.EVALUATION.ROOT },
  { item: t('discussionBoard'), link: ROUTES.DISCUSSION.ROOT },
  // { item: 'Assets', link: ROUTES.ASSETS.ROOT },
];

export const hodItems = (id, t) => [
  { item: t('staff'), link: '/manage-staff' },
  { item: t('student'), link: '/manage-students' },
  {
    item: t('department'),
    link: `/${ROUTES.COURSE_MANAGEMENT.DEPARTMENT_DETAILS}/${id}`,
  },
  {
    item: t('assessmentRepository.title'),
    link: `/${ROUTES.ASSESSMENT_REPOSITORY.ROOT}`,
  },
  { item: 'OBE', link: ROUTES.OBE.ROOT },
  {
    item: t('exams.exams'),
    link: ROUTES.EXAMS.ROOT,
  },
  { item: t('evaluation.title'), link: ROUTES.EVALUATION.ROOT },
  {
    item: t('StudentAttendance.myAttendance'),
    link: `${ROUTES.ATTENDANCE.MAIN_ATTENDANCE_PAGE}`,
  },
  {
    item: t('StudentAttendance.studentAttendance'),
    link: ROUTES.STUDENT_ATTENDANCE.ROOT,
  },
  {
    item: t('StudentAttendance.facultyAttendance'),
    link: ROUTES.FACULTY_ATTENDANCE.ROOT,
  },
  { item: t('discussionBoard'), link: ROUTES.DISCUSSION.ROOT },
];

export const facultyItems = (id, t) => [
  { item: t('student'), link: '/manage-students' },
  {
    item: t('courseManagement.programs'),
    link: `/${ROUTES.COURSE_MANAGEMENT.DEPARTMENT_DETAILS}/${id}`,
  },
  { item: 'OBE', link: ROUTES.OBE.ROOT },
  {
    item: t('assessmentRepository.title'),
    link: `/${ROUTES.ASSESSMENT_REPOSITORY.ROOT}`,
  },
  {
    item: t('exams.exams'),
    link: ROUTES.EXAMS.ROOT,
  },
  { item: t('evaluation.title'), link: ROUTES.EVALUATION.ROOT },
  {
    item: t('StudentAttendance.studentAttendance'),
    link: ROUTES.STUDENT_ATTENDANCE.ROOT,
  },
  {
    item: t('StudentAttendance.myAttendance'),
    link: `${ROUTES.ATTENDANCE.MAIN_ATTENDANCE_PAGE}`,
  },
  // { item: 'Reports', link: '/reports' },
  { item: t('discussionBoard'), link: ROUTES.DISCUSSION.ROOT },
];

export const hrItems = (id, t) => [
  {
    item: t('userManagement.userManagement'),
    link: ROUTES.USER_MANAGEMENT.ROOT,
  },
];

export const superAdminItems = (t) => [
  { item: t('tenantManagement.tenants'), link: ROUTES.SUPER_ADMIN.ROOT },
  {
    item: t('tenantManagement.features'),
    link: `/${ROUTES.SUPER_ADMIN.FEATURES}`,
  },
  {
    item: t('tenantManagement.tenantMigration'),
    link: `/${ROUTES.SUPER_ADMIN.TENANT_MIGRATION}`,
  },
];

export const studentLearningItems = (t) => [
  { item: t('courseManagement.programs'), link: '/my-learning' },
  {
    item: t('moocAdmin.MOOCCourses.mooccourses'),
    link: `${ROUTES.MOOC_COURSE.ROOT}`,
  },
  {
    item: t('moocAdmin.LearningPath.LearningPath'),
    link: `${ROUTES.LEARNING_PATH.ROOT}`,
  },
  {
    item: t('discussion.peerGroup'),
    link: `${ROUTES.PEER_GROUP.ROOT}`,
  },
];
