import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Typography } from 'iiht-b2a-ui-components/build/components';
import {
  CommonModal,
  FormDatepicker,
  FormSelectField,
  FormTextField,
  ItemDetails,
} from 'components';
import { validationRules } from 'constants/validationRules';

const SendNotification = ({ modalVisible, setModalVisible, role }) => {
  const { t } = useTranslation();

  const notificationData = {
    start_date: new Date(),
    end_date: new Date(),
  };

  const methods = useForm({
    defaultValues: {
      reason: '',
      start_date: '',
      end_date: '',
      note: '',
    },
  });

  const handleClose = () => {
    setModalVisible(false);
  };
  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    handleClose();
  };

  return (
    (modalVisible && (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CommonModal
            open={modalVisible}
            onClose={handleClose}
            modalTitle={t('studentManagement.sendNotification')}
            onSubmit={handleSubmit(onSubmit)}
            showDividers
            cancelBtnName={t('cancel')}
            submitBtnName={t('submit')}
            fullWidth
          >
            <Grid container direction="column" display="flex">
              <Grid item lg={12} md={12}>
                <FormSelectField
                  label={t('attendanceManagement.absenceReason')}
                  fullWidth
                  // rules={{
                  //   required: validationRules.REQUIRED,
                  // }}
                  size="small"
                  name={'reason'}
                  options={[
                    {
                      id: '1',
                      label: 'Absent Notification',
                      key: '1',
                      value: '1',
                    },
                    {
                      id: '2',
                      label: 'Error in Notification',
                      key: '2',
                      value: '2',
                    },
                  ]}
                />
              </Grid>
              <Grid item>
                <Grid container columnSpacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormDatepicker
                      name={'start_date'}
                      // rules={{
                      //   required: validationRules.REQUIRED,
                      // }}
                      value={notificationData?.start_date}
                      label={t('attendanceManagement.startDate')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormDatepicker
                      name={'end_date'}
                      // rules={{
                      //   required: validationRules.REQUIRED,
                      // }}
                      value={notificationData?.end_date}
                      label={t('attendanceManagement.endDate')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <FormTextField
                  name="note"
                  label={t('attendanceManagement.note')}
                  // rules={{
                  //   required: validationRules.REQUIRED,
                  // }}
                  size="small"
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  columnSpacing={1.5}
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ItemDetails
                      label={
                        role === 'student'
                          ? t('fees.studentName')
                          : t('facultyName')
                      }
                      value="Rajendra Mahadev"
                    />
                  </Grid>
                  <Grid item>
                    <ItemDetails
                      label={t('management.attendance')}
                      value="Absent"
                    />
                  </Grid>
                  <Grid item>
                    <ItemDetails
                      label={t('management.attendanceTaker')}
                      value="Vincent Joseph"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {role === 'student' && (
                <Grid item mt={1}>
                  <Typography color="grey.P1000" variant="bodyText">
                    {t('parentDetails')}
                  </Typography>
                  <Typography>
                    Sudhakar Mahadev (maddyangel@gmail.com)
                  </Typography>
                  <Typography>
                    Sudhakar Mahadev (maddyangel@gmail.com)
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CommonModal>
        </form>
      </FormProvider>
    )) ||
    null
  );
};

export default SendNotification;
