import { feedbackActions } from 'store/Feedback';

const INITIAL_STATE = {
  Feedback: {
    loading: false,
    totalCount: 0,
    results: {},
    courseList :[],
    submitFeedbackSuccess: {},
  },
};

const feedbackReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case feedbackActions.FEEDBACK.GET_SUCCESS_FEEDBACK_LIST: {
      return {
        ...state,
        Feedback: {
          ...state.Feedback,
          totalCount: action.payload.totalCount,
          results: action.payload,
        },
      };
    }
    case feedbackActions.FEEDBACK.POST_FFEDBACK_SUCCESS: {
      return {
        ...state,
        Feedback: { ...state.Feedback, submitFeedbackSuccess: action.payload },
      };
    }

    case feedbackActions.FEEDBACK.GET_COURSE_LIST_SUCCESS: {
      return {
        ...state,
        Feedback: {
          ...state.Feedback,
          courseList: action.payload,
        },
      };
    }

    case feedbackActions.FEEDBACK.LOADING: {
      return {
        ...state,
        Feedback: { ...state.Feedback, loading: action.payload },
      };
    }
    default:
      return state;
  }
};
export default feedbackReducer;
