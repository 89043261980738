import React from 'react';
import {
  FormControl,
  TextField,
} from 'iiht-b2a-ui-components/build/components';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const FormDateAndTimePicker = ({
  name,
  value,
  rules,
  label,
  fullWidth,
  className,
  required,
  isAsterisk,
  size,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { getFieldState } = control;
  return (
    <>
      <FormControl
        margin="normal"
        fullWidth={fullWidth}
        className={className}
        size={size}
      >
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => {
            const { name, value, onChange, onBlur } = field;
            const { invalid, error } = getFieldState(name);
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  name={name}
                  value={value}
                  label={`${label}${isAsterisk ? '*' : ''}`}
                  onChange={onChange}
                  rules={rules}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={error ? true : false}
                      aria-invalid={invalid ? 'true' : 'false'}
                    />
                  )}
                  {...rest}
                />
              </LocalizationProvider>
            );
          }}
        ></Controller>
      </FormControl>
    </>
  );
};

export default FormDateAndTimePicker;
