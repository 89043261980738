import * as React from 'react';

const ReadingIcon = ({ color, height = '24', width = '24', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      className={className}
      viewBox="0 0 26.406 26.406"
      fill={color}
    >
      <path
        id="library_books_FILL0_wght400_GRAD0_opsz48"
        d="M88.681-864.816h7.427V-866.8H88.681Zm0-2.971h13.038v-1.98H88.681Zm0-2.971h13.038v-1.98H88.681Zm-2.74,13.2a1.9,1.9,0,0,1-1.386-.594,1.9,1.9,0,0,1-.594-1.386v-18.484a1.9,1.9,0,0,1,.594-1.386A1.9,1.9,0,0,1,85.941-880h18.484a1.9,1.9,0,0,1,1.386.594,1.9,1.9,0,0,1,.594,1.386v18.484a1.9,1.9,0,0,1-.594,1.386,1.9,1.9,0,0,1-1.386.594Zm0-1.98h18.484v-18.484H85.941Zm-3.961,5.941a1.9,1.9,0,0,1-1.386-.594A1.9,1.9,0,0,1,80-855.574v-20.465h1.98v20.465h20.465v1.98Zm3.961-24.426v0Z"
        transform="translate(-80 880)"
      />
    </svg>
  );
};

export default ReadingIcon;
