import React from 'react';

const ChecklistSvg = ({ fill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="21"
      viewBox="0 0 29.544 21.567"
      fill={fill}
    >
      <g
        id="Group_13065"
        data-name="Group 13065"
        transform="translate(-20 -56.7)"
      >
        <path
          id="checklist_rtl_FILL0_wght400_GRAD0_opsz48"
          d="M2.954,28.288V26.072H16.249v2.216Zm0-11.817V14.255H16.249v2.216ZM24.337,31.095l-5.244-5.244L20.644,24.3l3.693,3.656,6.61-6.61L32.5,22.933Zm0-11.817-5.244-5.244,1.551-1.551,3.693,3.656,6.61-6.61L32.5,11.116Z"
          transform="translate(17.046 47.172)"
          fill="#ac0521"
        />
      </g>
    </svg>
  );
};

export default ChecklistSvg;
