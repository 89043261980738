import * as React from 'react';

function FullscreenIcon(props) {
  return (
    <span className="iiht-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.373"
        height="22.244"
        viewBox="0 0 22.373 22.244"
        {...props}
      >
        <g
          id="Group_11755"
          data-name="Group 11755"
          transform="translate(-1178.354 -97.433)"
        >
          <g
            id="Group_11754"
            data-name="Group 11754"
            transform="translate(476.126 -758.407) rotate(45)"
          >
            <path
              id="Path_43727"
              data-name="Path 43727"
              d="M-8226.557,10653.559l.123,5.76,2-2,4.366,4.212,1.746-1.746-4.388-4.187,1.979-1.979Z"
              transform="translate(14467.705 -1623.258) rotate(45)"
              fill="#3c4252"
            />
            <path
              id="Path_43728"
              data-name="Path 43728"
              d="M0,7.969.124,2.21l2,2L6.488,0,8.234,1.746,3.847,5.933,5.826,7.912Z"
              transform="translate(1111.813 118.714) rotate(-45)"
              fill="#3c4252"
            />
            <path
              id="Path_43729"
              data-name="Path 43729"
              d="M-8226.557,10653.559l.123,5.76,2-2,4.366,4.212,1.746-1.746-4.388-4.187,1.979-1.979Z"
              transform="translate(2849.419 13458.785) rotate(135)"
              fill="#3c4252"
            />
            <path
              id="Path_43730"
              data-name="Path 43730"
              d="M0,7.969.124,2.21l2,2L6.488,0,8.234,1.746,3.847,5.933,5.826,7.912Z"
              transform="translate(1107.447 102.892) rotate(45)"
              fill="#3c4252"
            />
          </g>
        </g>
      </svg>
    </span>
  );
}

export default FullscreenIcon;
