export const Role = {
  superadmin: 'Super-admin',
  tenantUser: 'tenantuser',
  tenantadmin: 'tenantadmin',
  registrar: 'Registrar',
  hod: 'Hod',
  student: 'Student',
  faculty: 'Faculty',
  verifier: 'Verifier',
  candidate: 'Candidate',
  counsellor: 'Counsellor',
  leadExec: 'Lge',
  parent: 'Parent',
  HR: 'Hr',
  moocAdmin: 'Mooc',
};

export const User = {
  localUser: 'LOCAL_USER',
  otpUser: 'OTP_USER',
};

export const USER_ROLE_ENUM = {
  student: 0,
  Faculty: 1,
  hod: 2,
};
