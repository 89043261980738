export const chatActions = {
  GET_CHAT_HISTORY: 'GET_CHAT_HISTORY',
  GET_CHAT_HISTORY_SUCCESS: 'GET_CHAT_HISTORY_SUCCESS',
  GET_CHAT_HISTORY_FAIL: 'GET_CHAT_HISTORY_FAIL',
  CHAT_HISTORY_RESET: 'CHAT_HISTORY_RESET',

  SET_CHAT_HISTORY: 'SET_CHAT_HISTORY',
  SET_CHAT_HISTORY_SUCCESS: 'SET_CHAT_HISTORY_SUCCESS',
  SET_CHAT_HISTORY_FAIL: 'SET_CHAT_HISTORY_FAIL',
  SET_CHAT_HISTORY_RESET: 'SET_CHAT_HISTORY_RESET',
  SET_CHAT_MESSAGE: 'SET_CHAT_MESSAGE',
};
