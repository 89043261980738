import { MyRepositoryManagementAPI } from 'store/MyRepository';
import axiosInstance from 'translator';

export const getMyRepositoryApi = async (payload) => {
  const offset = payload.Sorting * payload.MaxResultCount;
  return axiosInstance
    .get(
      `${MyRepositoryManagementAPI?.getRepositoryList}?offset=${offset}&limit=${payload.MaxResultCount}&userId=${payload.userId}&search=${payload.uploadedFileName}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createMyRepositoryApi = async (payload) => {
  return axiosInstance
    .post(`${MyRepositoryManagementAPI?.createRepository}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteRecordApi = async (payload) => {
  return axiosInstance
    .delete(
      `${MyRepositoryManagementAPI?.deleteRepositoryRecord}?id=${payload.id}&userId=${payload.userId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserHandleApi = async (payload) => {
  const offset = payload.Sorting * payload.MaxResultCount;
  return axiosInstance
    .get(
      `${MyRepositoryManagementAPI?.getUser}?offset=${offset}&limit=${payload.MaxResultCount}&userId=${payload.userId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateDocumentApi = async (payload) => {
  return axiosInstance
    .patch(
      `${MyRepositoryManagementAPI.getRepositoryList}/${payload.id}`,
      payload
    )
    .then((response) => response)
    .catch((error) => error);
};
export const shareFileApi = async (payload) => {
  return axiosInstance
    .put(`${MyRepositoryManagementAPI.shareFile}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const bulkFileShareApi = async (payload) => {
  return axiosInstance
    .put(MyRepositoryManagementAPI.bulkShareFile, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const bulkFileDeleteApi = async (payload) => {
  return axiosInstance
    .delete(MyRepositoryManagementAPI.bulkDeleteFile, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
