import React from 'react';

const MilitaryTech = ({ fill, height = 40, width = 40 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      fill={fill}
    >
      <path d="M280 176h400v326.667q0 23-10.877 41.766t-30.456 30.234l-141.334 82.667L524 752.667h140L550 836l44 140-114-85.333L365.334 976l43.999-140-113.999-83.333h140.74L462 657.334l-140.667-82.667q-19.579-11.468-30.456-30.234Q280 525.667 280 502.667V176Zm66.666 66.666v260.001q0 4.667 3 8.667t9.001 7.334l90.667 51.333V242.666H346.666Zm266.668 0H516v327.335l85.333-51.333q6.001-3.334 9.001-7.334 3-4 3-8.667V242.666ZM482.667 415.667Zm-33.333-9.333Zm66.666 0Z" />
    </svg>
  );
};

export default MilitaryTech;
