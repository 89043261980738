import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles({
  videoThumbnail: (props) => ({
    width: '9rem',
    height: '6rem',
    border: `1px solid ${props.palette.common.thumbnail_border}`,
    borderRadius: '0.5rem',
  }),
  videoThumbnailAuto: (props) => ({
    width: '100%',
    height: '75%',
    border: `1px solid ${props.palette.common.thumbnail_border}`,
    borderRadius: '0.5rem',
  }),
});

export default useStyles;
