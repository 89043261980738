import { put, call, takeLatest, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import { CandidateAction } from './Candidate.constants';
import {
  getCandidateCourseFormApi,
  getCandidateCourseFormInfoApi,
  getCandidateCourseProspectusApi,
  postCandidateCourseFormInfoApi,
  updateCandidateCourseFormInfoApi,
} from './Candidate.api';
import {
  getCandidateCourseFormError,
  getCandidateCourseFormInfoError,
  getCandidateCourseFormInfoSuccess,
  getCandidateCourseFormSuccess,
  getCandidateCourseProspectusError,
  getCandidateCourseProspectusSuccess,
  postCandidateCourseFormInfoError,
  postCandidateCourseFormInfoSuccess,
  setCandidateLoading,
  setCandidateProspectusLoading,
  updateCandidateCourseFormInfoError,
  updateCandidateCourseFormInfoSuccess,
} from './Candidate.action';
import { setToastMessage } from 'store/Toast';

export function* getCandidateCourseFormSaga({ payload }) {
  try {
    yield put(setCandidateLoading(true));
    const response = yield call(getCandidateCourseFormApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCandidateCourseFormSuccess(response?.data));
    } else {
      yield put(getCandidateCourseFormError());
      setToastMessage({
        data: response?.data?.error?.message ?? 'Failed to get lead details',
        severity: 'error',
      });
    }
    yield put(setCandidateLoading(false));
  } catch (error) {
    yield put(getCandidateCourseFormError(error));
    yield put(setCandidateLoading(false));
    setToastMessage({
      data: error?.data?.error?.message ?? 'Failed to get lead details',
      severity: 'error',
    });
  }
}

export function* getCandidateCourseFormInfoSaga({ payload }) {
  try {
    yield put(setCandidateLoading(true));
    const response = yield call(getCandidateCourseFormInfoApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCandidateCourseFormInfoSuccess(response?.data));
    } else {
      yield put(getCandidateCourseFormInfoError());
      setToastMessage({
        data: response?.data?.error?.message ?? 'Failed to get lead details',
        severity: 'error',
      });
    }
    yield put(setCandidateLoading(false));
  } catch (error) {
    yield put(getCandidateCourseFormInfoError(error));
    yield put(setCandidateLoading(false));
    setToastMessage({
      data: error?.data?.error?.message ?? 'Failed to get lead details',
      severity: 'error',
    });
  }
}

export function* postCandidateCourseFormInfoSaga({ payload }) {
  try {
    yield put(setCandidateLoading(true));
    const response = yield call(postCandidateCourseFormInfoApi, payload);
    if (response?.status === statusCode.Created201) {
      setToastMessage({
        data: 'Form submitted successfully.',
        severity: 'success',
      });
      yield put(
        postCandidateCourseFormInfoSuccess({
          data: response?.data,
          status: true,
        })
      );
    } else {
      yield put(postCandidateCourseFormInfoError());
      setToastMessage({
        data:
          response?.data?.error?.message ??
          'Failed to post candidate application form details',
        severity: 'error',
      });
    }
    yield put(setCandidateLoading(false));
  } catch (error) {
    yield put(postCandidateCourseFormInfoError(error));
    yield put(setCandidateLoading(false));
    setToastMessage({
      data:
        error?.data?.error?.message ??
        'Failed to post candidate application form details',
      severity: 'error',
    });
  }
}

export function* updateCandidateCourseFormInfoSaga({
  payload,
  candidateFormInfoId,
}) {
  try {
    yield put(setCandidateLoading(true));
    const response = yield call(
      updateCandidateCourseFormInfoApi,
      payload,
      candidateFormInfoId
    );
    if (response?.status === statusCode.Ok200) {
      setToastMessage({
        data: 'Form information updated successfully.',
        severity: 'success',
      });
      yield put(
        updateCandidateCourseFormInfoSuccess({
          data: response?.data,
          status: true,
        })
      );
    } else {
      yield put(updateCandidateCourseFormInfoError());
      setToastMessage({
        data:
          response?.data?.error?.message ??
          'Failed to update candidate application form details',
        severity: 'error',
      });
    }
    yield put(setCandidateLoading(false));
  } catch (error) {
    yield put(updateCandidateCourseFormInfoError(error));
    yield put(setCandidateLoading(false));
    setToastMessage({
      data:
        error?.data?.error?.message ??
        'Failed to update candidate application form details',
      severity: 'error',
    });
  }
}

export function* getCandidateCourseProspectusSaga({ payload }) {
  try {
    yield put(setCandidateProspectusLoading(true));
    const response = yield call(getCandidateCourseProspectusApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCandidateCourseProspectusSuccess(response?.data));
    } else {
      yield put(getCandidateCourseProspectusError());
      setToastMessage({
        data:
          response?.data?.error?.message ??
          'Failed to get course prospectus details',
        severity: 'error',
      });
    }
    yield put(setCandidateProspectusLoading(false));
  } catch (error) {
    yield put(getCandidateCourseProspectusError(error));
    yield put(setCandidateProspectusLoading(false));
    setToastMessage({
      data:
        error?.data?.error?.message ??
        'Failed to get course prospectus details',
      severity: 'error',
    });
  }
}

export function* watchDummy() {
  yield takeLatest(
    CandidateAction.GET_CANDIDATE_COURSE_FORM,
    getCandidateCourseFormSaga
  );
  yield takeLatest(
    CandidateAction.GET_CANDIDATE_COURSE_FORM_INFO,
    getCandidateCourseFormInfoSaga
  );
  yield takeLatest(
    CandidateAction.POST_CANDIDATE_COURSE_FORM_INFO,
    postCandidateCourseFormInfoSaga
  );
  yield takeLatest(
    CandidateAction.UPDATE_CANDIDATE_COURSE_FORM_INFO,
    updateCandidateCourseFormInfoSaga
  );
  yield takeLatest(
    CandidateAction.GET_CANDIDATE_COURSE_PROSPECTUS,
    getCandidateCourseProspectusSaga
  );
}

export default function* candidateSaga() {
  yield all([watchDummy()]);
}
