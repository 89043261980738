import { prospectusActions } from './Prospectus.constants';
const { APPLICATION_FORM, PROSPECTUS_FORM_LIST } = prospectusActions;

export const postNewApplicationFormAction = (payload) => ({
  type: APPLICATION_FORM.post,
  payload: payload,
});

export const postNewApplicationFormSuccess = (payload) => ({
  type: APPLICATION_FORM.post_success,
  payload: payload,
});

export const postNewApplicationFormError = (payload) => ({
  type: APPLICATION_FORM.post_error,
  payload: payload,
});

export const patchApplicationFormAction = (payload) => ({
  type: APPLICATION_FORM.patch,
  payload: payload,
});

export const patchApplicationFormSuccess = (payload) => ({
  type: APPLICATION_FORM.patch_success,
  payload: payload,
});

export const patchApplicationFormError = (payload) => ({
  type: APPLICATION_FORM.patch_error,
  payload: payload,
});

export const patchCourseFeesAction = (payload) => ({
  type: APPLICATION_FORM.patch_fees,
  payload: payload,
});

export const patchCourseFeesSuccess = (payload) => ({
  type: APPLICATION_FORM.patch_fees_success,
  payload: payload,
});

export const patchCourseFeesError = (payload) => ({
  type: APPLICATION_FORM.patch_fees_error,
  payload: payload,
});

export const getProspectusFormsAction = (payload) => ({
  type: PROSPECTUS_FORM_LIST.get,
  payload: payload,
});

export const getProspectusFormsSuccess = (payload) => ({
  type: PROSPECTUS_FORM_LIST.get_success,
  payload: payload,
});

export const getProspectusFormsError = (payload) => ({
  type: PROSPECTUS_FORM_LIST.get_error,
  payload: payload,
});

export const getSingleProspectusFormAction = (payload) => ({
  type: PROSPECTUS_FORM_LIST.get_form,
  payload: payload,
});

export const getSingleProspectusFormSuccess = (payload) => ({
  type: PROSPECTUS_FORM_LIST.get_form_success,
  payload: payload,
});

export const getSingleProspectusFormError = (payload) => ({
  type: PROSPECTUS_FORM_LIST.get_form_error,
  payload: payload,
});
