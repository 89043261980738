import React from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from 'iiht-b2a-ui-components/build/components';

const HorizontalStepper = ({
  steps,
  activeStep,
  setCurrentStepActive = () => {},
  clickable = true,
  width,
}) => {
  return (
    <Box sx={{ width: width }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              onClick={() => clickable && setCurrentStepActive(index)}
              className={clickable ? 'mouse-pointer' : ''}
            >
              <StepLabel
                className={clickable ? 'mouse-pointer' : ''}
                sx={{
                  '& .MuiStepLabel-labelContainer .Mui-active': {
                    fontWeight: 'bold',
                  },
                }}
                style={{ fontWeight: 'bold' }}
                {...labelProps}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length && (
        <Typography sx={{ mt: 2, mb: 1 }}>All steps completed.</Typography>
      )}
    </Box>
  );
};

export default HorizontalStepper;
