import { toastActions } from './Toast.constants';

const initialState = {
  show: false,
  data: '',
  severity: 'success',
  variant: 'filled',
  vertical: 'top',
  horizontal: 'right',
  duration: 2000,
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case toastActions.SET_TOAST_MESSAGE:
      return {
        ...state,
        show: true,
        data: action.payload?.data || '',
        severity: action.payload?.severity || 'success',
        variant: action.payload?.variant || 'filled',
        vertical: action.payload?.vertical || 'top',
        horizontal: action.payload?.horizontai || 'right',
        duration: action.payload?.duration || 2000,
      };

    case toastActions.RESET_TOAST_MESSAGE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default toastReducer;
