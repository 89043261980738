import { dashboardActions } from './Dashboard.constants';

const {
  PRESENT_DETAILS,
  CLUSTERS,
  REGISTRAR_COURSE_COUNT,
  STUDENT_ATTENDANCE,
  STUDENT_DETAILS_FOR_FACULTY,
  CLASS_COUNT,
  SCHEDULE,
  ASSESSMENT_STATUS,
  MODULE_STATUS,
  ASSESSMENTS_TABLE,
  ONGOING_COURSES,
  PENDING_APPROVALS,
  STUDENT_RATING,
  STUDENT_PROGRESS,
  GET_STUDENT_DASHBOARD,
} = dashboardActions;

export const setPresentDetailForRolesLoading = (payload) => ({
  type: PRESENT_DETAILS.loading,
  payload: payload,
});

export const setClusterByTenantIdLoading = (payload) => ({
  type: CLUSTERS.loading,
  payload: payload,
});

export const setRegistrarTotalCourseLoading = (payload) => ({
  type: REGISTRAR_COURSE_COUNT.loading,
  payload: payload,
});

export const setStudentAttendanceLoading = (payload) => ({
  type: STUDENT_ATTENDANCE.loading,
  payload: payload,
});

export const setClassCountLoading = (payload) => ({
  type: CLASS_COUNT.loading,
  payload: payload,
});

export const setScheduleDashboardLoading = (payload) => ({
  type: SCHEDULE.loading,
  payload: payload,
});

export const setAssessmentStatusDashboardLoading = (payload) => ({
  type: ASSESSMENT_STATUS.loading,
  payload: payload,
});

export const setModuleStatusDashboardLoading = (payload) => ({
  type: MODULE_STATUS.loading,
  payload: payload,
});

export const getDashboardAssessmentTableAction = (payload) => ({
  type: ASSESSMENTS_TABLE.get,
  payload: payload,
});

export const setDashboardAssessmentTableLoading = (payload) => ({
  type: ASSESSMENTS_TABLE.loading,
  payload: payload,
});

export const setOngoingCoursesLoading = (payload) => ({
  type: ONGOING_COURSES.loading,
  payload: payload,
});

export const setPendingApprovalsLoading = (payload) => ({
  type: PENDING_APPROVALS.loading,
  payload: payload,
});

export const setStudentRatingsLoading = (payload) => ({
  type: STUDENT_RATING.loading,
  payload: payload,
});

export const setStudentProgressLoading = (payload) => ({
  type: STUDENT_PROGRESS.loading,
  payload: payload,
});

// Registrar

export const getEnrolmentProgramListRequest = (param) => ({
  type: dashboardActions.GET_ENROLMENT_PROGRAM_LIST_REQUEST,
  param: param,
});

export const getEnrolmentProgramListSuccess = (payload) => ({
  type: dashboardActions.GET_ENROLMENT_PROGRAM_LIST_SUCCESS,
  payload: payload,
});

export const getEnrolmentProgramListError = (payload) => ({
  type: dashboardActions.GET_MORE_ENROLMENT_PROGRAM_LIST_ERROR,
  payload: payload,
});

export const getEnrolmentProgramListReset = () => ({
  type: dashboardActions.GET_ENROLMENT_PROGRAM_LIST_RESET,
});

// Pagination

export const getMoreEnrolmentProgramListRequest = (param) => ({
  type: dashboardActions.GET_MORE_ENROLMENT_PROGRAM_LIST_REQUEST,
  param: param,
});

export const getMoreEnrolmentProgramListSuccess = (payload) => ({
  type: dashboardActions.GET_MORE_ENROLMENT_PROGRAM_LIST_SUCCESS,
  payload: payload,
});

export const getMoreEnrolmentProgramListError = (payload) => ({
  type: dashboardActions.GET_MORE_ENROLMENT_PROGRAM_LIST_ERROR,
  payload: payload,
});

// get dashboard card details

export const getDashboardCardDetailsRequest = (params) => ({
  type: dashboardActions.GET_DASHBOARD_CARD_DETAILS_REQUEST,
  params: params,
});

export const getDashboardCardDetailsSuccess = (payload) => ({
  type: dashboardActions.GET_DASHBOARD_CARD_DETAILS_SUCCESS,
  payload: payload,
});

export const getDashboardCardDetailsReset = () => ({
  type: dashboardActions.GET_DASHBOARD_CARD_DETAILS_RESET,
});

// Get registrar dashboard chart data

export const getDashboardRegistrarChartDataRequest = (params) => ({
  type: dashboardActions.GET_REGISTRAR_DASHBOARD_CHART_DATA_REQUEST,
  params: params,
});

export const getDashboardRegistrarChartDataSuccess = (payload) => ({
  type: dashboardActions.GET_REGISTRAR_DASHBOARD_CHART_DATA_SUCCESS,
  payload: payload,
});

export const getDashboardRegistrarChartDataError = (payload) => ({
  type: dashboardActions.GET_REGISTRAR_DASHBOARD_CHART_DATA_ERROR,
  payload: payload,
});

export const getDashboardRegistrarChartDataReset = () => ({
  type: dashboardActions.GET_REGISTRAR_DASHBOARD_CHART_DATA_RESET,
});

//get hr dashboard card details

export const getHrDashboardCardDetailsRequest = (payload) => ({
  type: dashboardActions.GET_HR_DASHBOARD_CARD_DETAILS_REQUEST,
  payload: payload,
});

export const getHrDashboardCardDetailsSuccess = (payload) => ({
  type: dashboardActions.GET_HR_DASHBOARD_CARD_DETAILS_SUCCESS,
  payload: payload,
});

export const getHrDashboardCardDetailsReset = () => ({
  type: dashboardActions.GET_HR_DASHBOARD_CARD_DETAILS_RESET,
});

//Faculty

export const getStudentAttendanceGraphRequest = (payload) => ({
  type: dashboardActions.GET_STUDENT_ATTENDANCE_GRAPH_REQUEST,
  payload: payload,
});

export const getStudentAttendanceGraphSuccess = (payload) => ({
  type: dashboardActions.GET_STUDENT_ATTENDANCE_GRAPH_SUCCESS,
  payload: payload,
});

export const getStudentAttendanceGraphError = () => ({
  type: dashboardActions.GET_STUDENT_ATTENDANCE_GRAPH_ERROR,
});

// Student Dashboard Graph Attendance

export const studentDashboardGraphRequest = (payload) => ({
  type: dashboardActions.STUDENT_DASHBOARD_GRAPH_REQUEST,
  payload: payload,
});
export const studentDashboardGraphSuccess = (payload) => ({
  type: dashboardActions.STUDENT_DASHBOARD_GRAPH_SUCCESS,
  payload: payload,
});
export const studentDashboardGraphError = (payload) => ({
  type: dashboardActions.STUDENT_DASHBOARD_GRAPH_ERROR,
  payload: payload,
});
export const studentDashboardGraphReset = () => ({
  type: dashboardActions.STUDENT_DASHBOARD_GRAPH_RESET,
});

// Faculty DashBoard
export const getFacultyDashboardRequest = (payload) => ({
  type: dashboardActions.GET_FACULTY_DASHBOARD_REQUEST,
  payload: payload,
});

export const getFacultyDashboardSuccess = (payload) => ({
  type: dashboardActions.GET_FACULTY_DASHBOARD_REQUEST_SUCCESS,
  payload: payload,
});

export const getFacultyDashboardError = () => ({
  type: dashboardActions.GET_FACULTY_DASHBOARD_REQUEST_ERROR,
});

//get student dashboard
export const getStudentDashboardRequest = (payload) => ({
  type: dashboardActions.GET_STUDENT_DASHBOARD_REQUEST,
  payload: payload,
});

export const getStudentDashboardSuccess = (payload) => ({
  type: dashboardActions.GET_STUDENT_DASHBOARD_SUCCESS,
  payload: payload,
});

export const getStudentDashboardError = (payload) => ({
  type: dashboardActions.GET_STUDENT_DASHBOARD_ERROR,
  payload: payload,
});

export const getDashboardTableAction = (payload) => ({
  type: dashboardActions.GET_STUDENT_DASHBOARD_TABLE,
  payload: payload,
});

export const getDashboardTableSuccess = (payload) => ({
  type: dashboardActions.GET_STUDENT_DASHBOARD_TABLE_SUCCESS,
  payload: payload,
});

export const getDashboardError = (payload) => ({
  type: dashboardActions.GET_STUDENT_DASHBOARD_ERROR,
  payload: payload,
});

// HOD DashBoard
export const getFacultyDetailsDashboardRequest = (payload) => ({
  type: dashboardActions.GET_FACULTY_DETAILS_DASHBOARD_REQUEST,
  payload: payload,
});

export const getFacultyDetailsDashboardSuccess = (payload) => ({
  type: dashboardActions.GET_FACULTY_DETAILS_DASHBOARD_SUCCESS,
  payload: payload,
});

export const getFacultyDetailsDashboardError = () => ({
  type: dashboardActions.GET_FACULTY_DETAILS_DASHBOARD_ERROR,
});

export const getFacultyDetailsRequest = (payload) => ({
  type: dashboardActions.GET_FACULTY_USER_DETAILS_REQUEST,
  payload: payload,
});

export const getFacultyDetailsSuccess = (payload) => ({
  type: dashboardActions.GET_FACULTY_USER_DETAILS_SUCCESS,
  payload: payload,
});

export const getFacultyDetailsError = () => ({
  type: dashboardActions.GET_FACULTY_USER_DETAILS_ERROR,
});
