import { put, call, takeLatest, all } from 'redux-saga/effects';
import { VerifierActions } from './Verifier.constants';
import {
  addDiscountApi,
  deleteDiscountApi,
  getCandidateApplicationInfoApi,
  getCandidateListApi,
  getFeesDetailsApi,
  getLeadStageVerifierApi,
  getProdileDetails,
  getProspectusFeedbackApi,
  getVerifierStatsApi,
  receiptFileUploadApi,
  sendPaymentLinkApi,
  sendProspectusFeedbackApi,
  sendToRegistrarApi,
  updatePaymentDetailsApi,
  updateProspectusStatusApi,
} from './Verifier.api';
import {
  setCandidateApplicationInfoError,
  setCandidateApplicationInfoSuccess,
  setCandidateListError,
  setCandidateListSuccess,
  setProspectusFeedbackError,
  setProspectusFeedbackSuccess,
  setVerifierStatsError,
  setVerifierStatsSuccess,
  sendProspectusFeedbackError,
  setProspectusLoading,
  sendProspectusFeedbackSuccess,
  setCandidateListLoading,
  setProspectusFeedbackLoading,
  updateProspectusStatusError,
  updateProspectusStatusSuccess,
  setFeesDetailsError,
  setFeesDetailsSuccess,
  setAddDiscountError,
  setAddDiscountSuccess,
  setDeleteDiscountError,
  setDeleteDiscountSuccess,
  successLeadStageVerifier,
  errorLeadStageVerifier,
  successUpdatePaymentDetails,
  errorUpdatePaymentDetails,
  successSendPaymentLink,
  errorSendPaymentLink,
  successSendToRegistrar,
  errorSendToRegistrar,
  receiptFileUploadSuccess,
  getProfileDetailSuccess,
  getProfileDetailError,
} from './Verifier.action';
import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';

export function* getVerifierStatsSaga({ payload }) {
  try {
    const response = yield call(getVerifierStatsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setVerifierStatsSuccess(response?.data));
    } else {
      yield put(setVerifierStatsError(response?.error));
    }
  } catch (error) {
    yield put(setVerifierStatsError(error));
  }
}

export function* getProfileDetailsSaga({ payload }) {
  try {
    const response = yield call(getProdileDetails, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getProfileDetailSuccess(response?.data));
    } else {
      yield put(getProfileDetailError(response?.error));
    }
  } catch (error) {
    yield put(getProfileDetailError(error));
  }
}

export function* getCandidateListSaga({ payload }) {
  try {
    yield put(setCandidateListLoading(true));
    const response = yield call(getCandidateListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setCandidateListSuccess(response?.data));
    } else {
      yield put(setCandidateListError(response?.error));
    }
    yield put(setCandidateListLoading(false));
  } catch (error) {
    yield put(setCandidateListError(error));
    yield put(setCandidateListLoading(false));
  }
}

export function* getCandidateApplicationInfoSaga({ payload }) {
  try {
    yield put(setCandidateListLoading(true));
    const response = yield call(getCandidateApplicationInfoApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setCandidateApplicationInfoSuccess(response?.data));
    } else {
      yield put(setCandidateApplicationInfoError(response?.error));
    }
    yield put(setCandidateListLoading(false));
  } catch (error) {
    yield put(setCandidateApplicationInfoError(error));
    yield put(setCandidateListLoading(false));
  }
}

export function* getProspectusFeedbackSaga({ payload }) {
  try {
    yield put(setProspectusFeedbackLoading(true));
    const response = yield call(getProspectusFeedbackApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setProspectusFeedbackSuccess(response?.data));
    } else {
      yield put(setProspectusFeedbackError(response?.error));
    }
    yield put(setProspectusFeedbackLoading(false));
  } catch (error) {
    yield put(setProspectusFeedbackError(error));
    yield put(setProspectusFeedbackLoading(false));
  }
}

export function* sendProspectusFeedbackSaga({ payload }) {
  try {
    yield put(setProspectusLoading(true));
    const response = yield call(sendProspectusFeedbackApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(
        setToastMessage({
          data: 'Feedback added successfully.',
          severity: 'success',
        })
      );
      yield put(sendProspectusFeedbackSuccess(response?.data));
    } else {
      yield put(sendProspectusFeedbackError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(setProspectusLoading(false));
  } catch (error) {
    yield put(sendProspectusFeedbackError(error));
    yield put(setProspectusLoading(false));
  }
}

export function* updateProspectusStatusSaga({ payload }) {
  try {
    yield put(setProspectusLoading(true));
    const response = yield call(updateProspectusStatusApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateProspectusStatusSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Prospectus status updated.',
          severity: 'success',
        })
      );
    } else {
      yield put(updateProspectusStatusError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(setProspectusLoading(false));
  } catch (error) {
    yield put(updateProspectusStatusError(error));
    yield put(setProspectusLoading(false));
  }
}

export function* getFeesDetailsSaga({ payload }) {
  try {
    yield put(setCandidateListLoading(true));
    const response = yield call(getFeesDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setFeesDetailsSuccess(response?.data));
    } else {
      yield put(setFeesDetailsError(response?.error));
    }
    yield put(setCandidateListLoading(false));
  } catch (error) {
    yield put(setFeesDetailsError(error));
    yield put(setCandidateListLoading(false));
  }
}

export function* getLeadStageVerifierSaga({ id, payload }) {
  try {
    // yield put(setCandidateListLoading(true));
    const response = yield call(getLeadStageVerifierApi, id, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(successLeadStageVerifier(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(errorLeadStageVerifier(response?.error));
      yield put(
        setToastMessage({
          data: response?.data?.bad_request,
          severity: 'error',
        })
      );
    }
    // yield put(setCandidateListLoading(false));
  } catch (error) {
    yield put(errorLeadStageVerifier(error));
    // yield put(setCandidateListLoading(false));
  }
}

export function* updatePaymentDetailsSaga({ id, payload }) {
  try {
    // yield put(setCandidateListLoading(true));
    const response = yield call(updatePaymentDetailsApi, id, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(successUpdatePaymentDetails(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(errorUpdatePaymentDetails(response?.error));
    }
    // yield put(setCandidateListLoading(false));
  } catch (error) {
    yield put(errorUpdatePaymentDetails(error));
    // yield put(setCandidateListLoading(false));
  }
}

export function* addDiscountSaga({ payload }) {
  try {
    yield put(setCandidateListLoading(true));
    const response = yield call(addDiscountApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(
        setToastMessage({
          data: 'Discount added successfully.',
          severity: 'success',
        })
      );
      yield put(setAddDiscountSuccess(response?.data));
    } else {
      yield put(
        setToastMessage({
          data: response?.error?.message
            ? response.error.message
            : response?.data?.message,
          severity: 'error',
        })
      );
      yield put(setAddDiscountError(response?.error));
    }
    yield put(setCandidateListLoading(false));
  } catch (error) {
    yield put(setAddDiscountError(error));
    yield put(setCandidateListLoading(false));
  }
}

export function* deleteDiscountSaga({ payload }) {
  try {
    yield put(setCandidateListLoading(true));
    const response = yield call(deleteDiscountApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(
        setToastMessage({
          data: 'Discount deleted successfully.',
          severity: 'success',
        })
      );
      yield put(setDeleteDiscountSuccess(response?.data));
    } else {
      yield put(
        setToastMessage({
          data: response?.error?.message,
          severity: 'error',
        })
      );
      yield put(setDeleteDiscountError(response?.error));
    }
    yield put(setCandidateListLoading(false));
  } catch (error) {
    yield put(setDeleteDiscountError(error));
    yield put(setCandidateListLoading(false));
  }
}

export function* sendPaymentLinksSaga({ id, payload }) {
  try {
    // yield put(setProspectusLoading(true));
    const response = yield call(sendPaymentLinkApi, id, payload);
    // yield put(setProspectusLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(successSendPaymentLink(response?.data));
      yield put(
        setToastMessage({
          data: 'Send Payment link successful.',
          severity: 'success',
        })
      );
    } else {
      yield put(errorSendPaymentLink(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    yield put(errorSendPaymentLink(error));
  }
}

export function* sendToRegistrarSaga({ payload }) {
  try {
    // yield put(setProspectusLoading(true));
    const response = yield call(sendToRegistrarApi, payload);
    // yield put(setProspectusLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(successSendToRegistrar(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(errorSendToRegistrar(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(errorSendToRegistrar(error));
  }
}

function* receiptfileUploadLeadSaga({ payload }) {
  try {
    const response = yield call(receiptFileUploadApi, payload?.data);
    if (response?.status === statusCode.Created201) {
      yield put(
        receiptFileUploadSuccess({
          blob_name: response?.data?.blob_name,
          fileType: payload?.data?.get?.('file_type'),
          fileIndex: payload?.fileIndex,
        })
      );
    } else {
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    setToastMessage({
      severity: 'error',
    });
  }
}

export function* watchDummy() {
  yield takeLatest(VerifierActions.GET_VERIFIER_STATS, getVerifierStatsSaga);
  yield takeLatest(VerifierActions.GET_CANDIDATES_LIST, getCandidateListSaga);
  yield takeLatest(
    VerifierActions.GET_CANDIDATE_APPLICATION_INFO,
    getCandidateApplicationInfoSaga
  );
  yield takeLatest(
    VerifierActions.GET_PROSPECTUS_FEEDBACK,
    getProspectusFeedbackSaga
  );
  yield takeLatest(
    VerifierActions.SEND_PROSPECTUS_FEEDBACK,
    sendProspectusFeedbackSaga
  );
  yield takeLatest(
    VerifierActions.UPDATE_PROSPECTUS_STATUS,
    updateProspectusStatusSaga
  );
  yield takeLatest(VerifierActions.GET_FEES_DETAILS, getFeesDetailsSaga);
  yield takeLatest(VerifierActions.ADD_DISCOUNT, addDiscountSaga);
  yield takeLatest(VerifierActions.DELETE_DISCOUNT, deleteDiscountSaga);
  yield takeLatest(
    VerifierActions.GET_LEAD_STAGE_VERIFIER,
    getLeadStageVerifierSaga
  );
  yield takeLatest(
    VerifierActions.UPDATE_PAYMENT_DETAILS,
    updatePaymentDetailsSaga
  );
  yield takeLatest(VerifierActions.SEND_PAYMENT_LINK, sendPaymentLinksSaga);
  yield takeLatest(VerifierActions.SEND_TO_REGISTRAR, sendToRegistrarSaga);
  yield takeLatest(
    VerifierActions.RECEIPT_FILE_UPLOAD,
    receiptfileUploadLeadSaga
  );
  yield takeLatest(
    VerifierActions.GET_USER_DETAILS,
    getProfileDetailsSaga
  );
}

export default function* verifierSaga() {
  yield all([watchDummy()]);
}
