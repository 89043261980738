import axiosInstance from 'translator';
import { courseManagementAPI } from 'store/CourseManagement';
export const getClustersApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.cluster}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postClustersApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.cluster}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDepartmentsApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.department}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDepartmentsByClusterIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.department}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCourseListByDepartmentIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.program}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postDepartmentApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.department}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDepartmentByIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.department}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateDepartmentApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.department}/${payload.get?.('id')}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteDepartmentApi = async (payload) => {
  return axiosInstance
    .delete(`${courseManagementAPI?.department}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteClusterApi = async (payload) => {
  return axiosInstance
    .delete(`${courseManagementAPI?.cluster}/${payload}}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateClusterApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.cluster}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createLiveSessionApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.createLiveSession}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getRubricConfigApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.rubric}/${payload}`, {
      proxy: {
        host: 'http://myaksdev-ing.centralindia.cloudapp.azure.com',
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createRubricConfigApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.rubric}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const editRubricConfigApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.rubric}/${payload?.program}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCbcListApi = async (params) => {
  return axiosInstance
    .get(`${courseManagementAPI?.cbc}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createCbcApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.cbc}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const editCbcApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.cbc}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSessionByIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.getSessionById}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateSessionApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.sessionUpdate}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSessionForSelectedSubModuleIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.getSessionForSelectedSubModuleId}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createProgramYearApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.programYear}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateProgramYearApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.programYear}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteProgramYearApi = async (payload) => {
  return axiosInstance
    .delete(`${courseManagementAPI?.programYear}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramYearListApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.programYear}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramYearByIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.programYear}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createSemesterYearApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.semester}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateSemesterYearApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.semester}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteSemesterYearApi = async (payload) => {
  return axiosInstance
    .delete(`${courseManagementAPI?.semester}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSemesterListApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.semester}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSemesterByIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.semester}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createSubjectApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.subject}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateSubjectApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.subject}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteSubjectApi = async (payload) => {
  return axiosInstance
    .delete(`${courseManagementAPI?.subject}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubjectListApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.subject}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubjectByIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.subject}/${payload?.id}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createProgramApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.program}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateProgramApi = async (payload) => {
  return axiosInstance
    .put(`${courseManagementAPI?.program}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const patchProgramApi = async (payload) => {
  return axiosInstance
    .patch(`${courseManagementAPI?.program}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteProgramApi = async (payload) => {
  return axiosInstance
    .delete(`${courseManagementAPI?.program}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramListApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.program}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramDetailsApi = async (id) => {
  return axiosInstance
    .get(`${courseManagementAPI?.program}/${id?.courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const facultySubjectLinkingApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.facultySubjectLink}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getfacultySubjectDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.facultySubjectLink}?user=${payload?.user}`)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteFacultySubjectLinkApi = async (payload) => {
  return axiosInstance
    .delete(`${courseManagementAPI?.deleteFacultySubjectLink}`, {
      params: payload,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const editFacultySubjectLinkDetailsApi = async (payload) => {
  return axiosInstance
    .put(
      `${courseManagementAPI.facultySubjectLink}/${payload?.id}`,
      payload?.data
    )
    .then((response) => response)
    .catch((error) => error);
};

// Get user mapping by user id
export const getUserMappingByUserIdApi = async (payload) => {
  return axiosInstance
    .get(
      `${courseManagementAPI.getUserMappingByUserId}?user=${payload?.userId}`
    )
    .then((response) => response)
    .catch((error) => error);
};

// Update user to program mapping
export const updateUserToProgramMappingApi = async (payload) => {
  return axiosInstance
    .put(
      `${courseManagementAPI.updateUserToProgramMapping}?user=${payload?.user}`,
      payload
    )
    .then((response) => response)
    .catch((error) => error);
};

export const createGradePointsApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.gradePoints}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateGradePointsApi = async (payload) => {
  return axiosInstance
    .put(
      `${courseManagementAPI?.gradePoints}/${payload?.rubric}`,
      payload?.data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getGradePointsApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.gradePoints}/${payload}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Join Session virtutor api
export const createJoinSessionForVirtutorApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.createJoinSessionForVirtutor}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Create vision for department api
export const createVisionForDepartmentApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.createVisionForDepartment}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Create mission for department api
export const createMissionForDepartmentApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.createMissionForDepartment}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Downlaod for vision API
export const downloadVisionApi = async (payload) => {
  console.log('payload:', payload);
  return axiosInstance
    .get(`${courseManagementAPI?.createVisionForDepartment}`, {
      params: payload,
    })
    .then((response) => response)
    .catch((error) => error);
};

// Downlaod for mission API
export const downloadMissionApi = async (payload) => {
  console.log('payload:', payload);
  return axiosInstance
    .get(`${courseManagementAPI?.createMissionForDepartment}`, {
      params: payload,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getUniversityVissionApi = async () => {
  return axiosInstance
    .get(`${courseManagementAPI?.universityVission}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createUniversityVissionApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.universityVission}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUniversityMissionApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.universityMission}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createUniversityMissionApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.universityMission}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCourseOutcomeApi = async (payload) => {
  return axiosInstance
    .get(`${courseManagementAPI?.courseOutcome}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const sendApprovalApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.sendApproval}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const bulkDeleteApi = async (payload) => {
  return axiosInstance
    .post(`${courseManagementAPI?.bulkDelete}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const updateSchemaApi = async (payload) => {
  console.log('222222222', payload);
  return axiosInstance
    .patch(`${courseManagementAPI?.program}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
