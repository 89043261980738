import { MoocAdminActions } from './MoocAdmin.constants';

const INITIAL_STATE = {
  dashboardLoader: false,
  headerLogoUrl: '',
  dashboardStats: {
    data: [],
    error: '',
  },
  affiliatedCourses: {
    data: [],
    count: 0,
    error: '',
  },
  courseListLoader: false,
  courseFilterLoader: false,
  coursesToAffiliate: {
    data: [],
    count: 0,
    error: '',
  },
  coursesToPublish: {
    data: [],
    count: 0,
    error: '',
  },
  publishedCourses: {
    data: [],
    count: 0,
    error: '',
  },
  courseDetailsLoader: false,
  courseDetails: {
    data: {},
    error: '',
  },
  affiliatePublishMoocCourseLoader: false,
  affiliateMoocCourse: {
    success: '',
    error: '',
  },
  publishMoocCourse: {
    success: '',
    error: '',
  },
  filterData: {},
  moocTypeData: {
    success: '',
    error: '',
  },
  enrollCourse: {
    success: '',
    error: '',
  },
  allCourses: {
    getAllCourseLoader: false,
    data: [],
    error: '',
    count: 0,
  },

  updateCourseDetails: {
    data: [],
    count: 0,
    error: '',
  },

  deleteCourseDetails: {
    data: [],
    error: '',
  },
  updateMoocSettings: {
    success: '',
    error: '',
  },

  getMoocSettingCertificate: {
    data: '',
    error: '',
  },

  moocCourseCertificate: {
    success: '',
    error: '',
  },

  uploadTemplate: {
    success: '',
    error: '',
  },
  uploadFile: {
    success: '',
    error: '',
  },

  getEnrolledCourse: {
    data: {},
    count: 0,
    error: '',
    getEnrolledCourseLoader: false,
  },
  updateCourseStatus: {
    success: '',
    error: '',
  },
  moocCourseStatus: {
    success: '',
    error: '',
  },
  moocDashboard: {
    data: {},
    error: '',
    count: 0,
    loading: false,
  },
  studentList: {
    data: [],
    error: '',
  },
  selectedCourse: {},
  AssessmentLink: {
    data: {},
  },

  getVideoNotes: {
    data: [],
    error: '',
  },
  createVideoNotes: {
    success: '',
    error: '',
  },
  downloadCertificate: {
    success: '',
    error: '',
  },
  learningPathToPublish: {
    success: '',
    error: '',
  },
  publishedLearningPath: {
    success: '',
    error: '',
  },
  learningPath: {
    getLearningPathLoader: false,
    data: [],
    error: '',
  },
  getCourseList: {
    success: '',
    error: '',
  },
  updateLearningPath: {
    updateLearningPathLoader: false,
    success: '',
    error: '',
  },
  myLearningPath: {
    data: [],
    loading: false,
    error: '',
  },
  enrollLearningPath: {
    success: '',
    error: '',
  },
  courseByLpId: {
    success: '',
    error: '',
    loading: false,
  },
  createLearningPath: {
    success: '',
    error: '',
  },
  lpCourseStatus: {
    success: '',
    error: '',
  },
  lpById: {
    success: '',
    error: '',
  },
  editLearningPath: {
    success: '',
    error: '',
  },
};

const moocAdminReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MoocAdminActions.MOOC_ADMIN_DASHBOARD_LOADER:
      return {
        ...state,
        dashboardLoader: action.payload,
      };

    case MoocAdminActions.SET_MOOC_ADMIN_STATS_SUCCESS:
      return {
        ...state,
        dashboardStats: { data: action.payload, error: '' },
      };

    case MoocAdminActions.SET_MOOC_ADMIN_STATS_ERROR:
      return { ...state, dashboardStats: { data: [], error: action.payload } };

    case MoocAdminActions.RESET_MOOC_ADMIN_STATS:
      return { ...state, dashboardStats: INITIAL_STATE.dashboardStats };

    case MoocAdminActions.SET_AFFILIATED_COURSES_SUCCESS:
      return {
        ...state,
        affiliatedCourses: {
          data: action.payload,
          count: action.payload?.count,
          error: '',
        },
      };

    case MoocAdminActions.SET_AFFILIATED_COURSES_ERROR:
      return {
        ...state,
        affiliatedCourses: {
          ...INITIAL_STATE.affiliatedCourses,
          error: action.payload,
        },
      };

    case MoocAdminActions.MOOC_ADMIN_COURSES_LIST_LOADER:
      return {
        ...state,
        courseListLoader: action.payload,
      };

    case MoocAdminActions.MOOC_ADMIN_COURSES_FILTER_LOADER:
      return {
        ...state,
        courseFilterLoader: action.payload,
      };

    case MoocAdminActions.SET_COURSES_TO_AFFILIATE_SUCCESS:
      return {
        ...state,
        coursesToAffiliate: {
          data: action.payload,
          count: action.payload?.count,
          error: '',
        },
      };

    case MoocAdminActions.SET_COURSES_TO_AFFILIATE_ERROR:
      return {
        ...state,
        coursesToAffiliate: {
          ...INITIAL_STATE.coursesToAffiliate,
          error: action.payload,
        },
      };

    case MoocAdminActions.RESET_COURSES_LIST:
      return {
        ...state,
        coursesToAffiliate: INITIAL_STATE.coursesToAffiliate,
        affiliatedCourses: INITIAL_STATE.affiliatedCourses,
        coursesToPublish: INITIAL_STATE.coursesToPublish,
        publishedCourses: INITIAL_STATE.publishedCourses,
      };

    case MoocAdminActions.SET_COURSES_TO_PUBLISH_SUCCESS:
      return {
        ...state,
        coursesToPublish: {
          data: action.payload,
          count: action.payload?.count,
          error: '',
        },
      };

    case MoocAdminActions.SET_COURSES_TO_PUBLISH_ERROR:
      return {
        ...state,
        coursesToPublish: {
          ...INITIAL_STATE.coursesToPublish,
          error: action.payload,
        },
      };

    case MoocAdminActions.SET_PUBLISHED_COURSES_SUCCESS:
      return {
        ...state,
        publishedCourses: {
          data: action.payload,
          count: action.payload?.count,
          error: '',
        },
      };

    case MoocAdminActions.SET_PUBLISHED_COURSES_ERROR:
      return {
        ...state,
        publishedCourses: {
          ...INITIAL_STATE.publishedCourses,
          error: action.payload,
        },
      };

    case MoocAdminActions.MOOC_ADMIN_COURSE_DETAILS_LOADER:
      return {
        ...state,
        courseDetailsLoader: action.payload,
      };

    case MoocAdminActions.SET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        courseDetails: {
          data: action.payload,
          error: '',
        },
      };

    case MoocAdminActions.SET_COURSE_DETAILS_ERROR:
      return {
        ...state,
        courseDetails: {
          data: {},
          error: action.payload,
        },
      };

    case MoocAdminActions.RESET_COURSE_DETAILS:
      return {
        ...state,
        courseDetails: INITIAL_STATE.courseDetails,
      };

    case MoocAdminActions.AFFILIATE_PUBLISH_MOOC_COURSE_LOADER: {
      return {
        ...state,
        affiliatePublishMoocCourseLoader: action.payload,
      };
    }

    case MoocAdminActions.AFFILIATE_MOOC_COURSE_SUCCESS:
      return {
        ...state,
        affiliateMoocCourse: { success: action.payload, error: '' },
      };

    case MoocAdminActions.AFFILIATE_MOOC_COURSE_ERROR:
      return {
        ...state,
        affiliateMoocCourse: { success: '', error: action.payload },
      };

    case MoocAdminActions.PUBLISH_MOOC_COURSE_SUCCESS:
      return {
        ...state,
        publishMoocCourse: { success: action.payload, error: '' },
      };

    case MoocAdminActions.PUBLISH_MOOC_COURSE_ERROR:
      return {
        ...state,
        publishMoocCourse: { success: '', error: action.payload },
      };

    case MoocAdminActions.SET_MOOC_ADMIN_FILTER_SUCCESS:
      return {
        ...state,
        filterData: action.payload,
      };

    case MoocAdminActions.RESET_MOOC_ADMIN_FILTER:
      return {
        ...state,
        filterData: {},
      };

    case MoocAdminActions.SET_MOOC_TYPE_SUCCESS:
      return {
        ...state,
        moocTypeData: { success: action.payload, error: '' },
      };

    case MoocAdminActions.SET_MOOC_TYPE_ERROR:
      return {
        ...state,
        moocTypeData: { success: '', error: action.payload },
      };

    case MoocAdminActions.SET_ENROLL_MOOC_COURSE_SUCCESS:
      return {
        ...state,
        enrollCourse: { success: action.payload, error: '' },
      };

    case MoocAdminActions.SET_ENROLL_MOOC_COURSE_ERROR:
      return {
        ...state,
        enrollCourse: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_ENROLL_MOOC_COURSE:
      return {
        ...state,
        enrollCourse: INITIAL_STATE.enrollCourse,
      };
    //Get All Courses
    case MoocAdminActions.GET_All_COURSES:
      return {
        ...state,
        allCourses: {
          ...state.allCourses,
          loading: true,
        },
      };

    case MoocAdminActions.SET_GET_All_COURSES_SUCCESS:
      return {
        ...state,
        allCourses: {
          data: action.payload?.results || [],
          loading: false,
          count: action.payload?.count,
          error: '',
        },
      };

    case MoocAdminActions.SET_GET_All_COURSES_ERROR:
      return {
        ...state,
        allCourses: {
          ...INITIAL_STATE.allCourses,
          error: action.payload,
        },
      };
    case MoocAdminActions.SET_COURSES_LOADER: {
      return {
        ...state,
        allCourses: {
          ...state.allCourses,
          getAllCourseLoader: action?.payload,
        },
      };
    }

    //Update course details by ID
    case MoocAdminActions.UPDATE_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        updateCourseDetails: {
          data: action.payload,
          error: '',
        },
      };

    case MoocAdminActions.UPDATE_COURSE_DETAILS_ERROR:
      return {
        ...state,
        updateCourseDetails: {
          data: {},
          error: action.payload,
        },
      };

    //Delete course details by ID
    case MoocAdminActions.DELETE_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        deleteCourseDetails: {
          data: action.payload,
          error: '',
        },
      };

    case MoocAdminActions.DELETE_COURSE_DETAILS_ERROR:
      return {
        ...state,
        deleteCourseDetails: {
          data: {},
          error: action.payload,
        },
      };
    //Update Mooc Setting
    case MoocAdminActions.UPDATE_MOOC_SETTING_SUCCESS:
      return {
        ...state,
        updateMoocSettings: {
          success: action.payload,
          error: '',
        },
      };

    case MoocAdminActions.UPDATE_MOOC_SETTING_ERROR:
      return {
        ...state,
        updateMoocSettings: {
          success: '',
          error: action.payload,
        },
      };

    case MoocAdminActions.RESET_UPDATE_MOOC_SETTING:
      return {
        ...state,
        updateMoocSettings: INITIAL_STATE.updateMoocSettings,
      };

    //Get Mooc Setting
    case MoocAdminActions.SET_MOOC_SETTING_SUCCESS:
      return {
        ...state,
        getMoocSettingCertificate: {
          data: action.payload,
          error: '',
        },
      };

    case MoocAdminActions.SET_MOOC_SETTING_ERROR:
      return {
        ...state,
        getMoocSettingCertificate: {
          data: '',
          error: action.payload,
        },
      };
    //Create Mooc Course Certificate
    case MoocAdminActions.CREATE_MOOC_COURSE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        moocCourseCertificate: { success: action.payload, error: '' },
      };

    case MoocAdminActions.CREATE_MOOC_COURSE_CERTIFICATE_ERROR:
      return {
        ...state,
        moocCourseCertificate: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_CREATE_MOOC_COURSE_CERTIFICATE:
      return {
        ...state,
        moocCourseCertificate: INITIAL_STATE.moocCourseCertificate,
      };

    //Upload file for mooc setting
    case MoocAdminActions.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        uploadTemplate: {
          ...state.uploadTemplate,
          fileUploadMetaData: action.payload,
        },
      };
    }
    case MoocAdminActions.UPLOAD_FILE_SCHEMA_SUCCESS: {
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          fileUploadMetaData: action.payload,
        },
      };
    }

    case MoocAdminActions.UPLOAD_FILE_ERROR:
      return {
        ...state,

        uploadFile: { success: '', error: action.payload },
      };

    //Get Enrolled Courses
    case MoocAdminActions.GET_ENROLLED_COURSES:
      return {
        ...state,
        getEnrolledCourse: {
          ...state.getEnrolledCourse,
          loading: true,
        },
      };

    case MoocAdminActions.SET_ENROLLED_COURSES_SUCCESS:
      return {
        ...state,
        getEnrolledCourse: {
          data: action.payload,
          loading: false,
          error: '',
        },
      };

    case MoocAdminActions.SET_ENROLLED_COURSES_ERROR:
      return {
        ...state,
        getEnrolledCourse: {
          ...INITIAL_STATE.getEnrolledCourse,
          error: action.payload,
        },
      };

    case MoocAdminActions.RESET_ENROLLED_COURSES:
      return {
        ...state,
        getEnrolledCourse: INITIAL_STATE.getEnrolledCourse,
      };

    case MoocAdminActions.SET_ENROLLED_COURSES_LOADER: {
      return {
        ...state,
        getEnrolledCourse: {
          ...state.getEnrolledCourse,
          getEnrolledCourseLoader: action?.payload,
        },
      };
    }

    case MoocAdminActions.UPDATE_COURSE_STATUS_SUCCESS:
      return {
        ...state,
        updateCourseStatus: { success: action.payload, error: '' },
      };

    case MoocAdminActions.UPDATE_COURSE_STATUS_ERROR:
      return {
        ...state,
        updateCourseStatus: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_UPDATE_COURSE_STATUS:
      return {
        ...state,
        updateCourseStatus: INITIAL_STATE.updateCourseStatus,
      };

    case MoocAdminActions.SET_MOOC_COURSE_STATUS_SUCCESS:
      return {
        ...state,
        moocCourseStatus: { success: action.payload, error: '' },
      };

    case MoocAdminActions.SET_MOOC_COURSE_STATUS_ERROR:
      return {
        ...state,
        moocCourseStatus: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_MOOC_COURSE_STATUS:
      return {
        ...state,
        moocCourseStatus: INITIAL_STATE.moocCourseStatus,
      };

    case MoocAdminActions.SET_MOOC_ADMIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        moocDashboard: {
          data: action.payload,
          error: '',
          count: action.payload?.count,
        },
        headerLogoUrl: action.payload?.results?.header_logo_url,
      };

    case MoocAdminActions.SET_MOOC_ADMIN_DASHBOARD_ERROR:
      return {
        ...state,
        moocDashboard: {
          data: {},
          error: action.payload,
        },
      };

    case MoocAdminActions.SET_MOOC_ADMIN_DASHBOARD_LOADING: {
      return {
        ...state,
        moocDashboard: {
          ...state.moocDashboard,
          loading: action?.payload,
        },
      };
    }

    case MoocAdminActions.SET_STUDENT_LIST_BY_COURSE_ID_SUCCESS:
      return {
        ...state,
        studentList: { data: action.payload, error: '' },
      };

    case MoocAdminActions.SET_STUDENT_LIST_BY_COURSE_ID_ERROR:
      return {
        ...state,
        studentList: { data: [], error: action.payload },
      };

    case MoocAdminActions.RESET_STUDENT_LIST_BY_COURSE_ID:
      return {
        ...state,
        studentList: INITIAL_STATE.studentList,
      };

    case MoocAdminActions.SELECTED_COURSE_FOR_STUDENTS_LIST:
      return {
        ...state,
        selectedCourse: action.payload,
      };

    case MoocAdminActions.RESET_SELECTED_COURSE_FOR_STUDENTS_LIST:
      return {
        ...state,
        selectedCourse: {},
      };

    case MoocAdminActions.GET_ASSESSMENT_LINK_REQUEST_SUCCESS:
      return {
        ...state,
        AssessmentLink: {
          data: action.payload || {},
        },
      };

    case MoocAdminActions.RESET_ASSESSMENT_LINK_REQUEST:
      return {
        ...state,
        AssessmentLink: INITIAL_STATE.AssessmentLink,
      };

    case MoocAdminActions.GET_VIDEO_NOTES_REQUEST_SUCCESS:
      return {
        ...state,
        getVideoNotes: {
          data: action.payload || {},
        },
      };

    case MoocAdminActions.CREATE_VIDEO_NOTES_REQUEST_SUCCESS:
      return {
        ...state,
        createVideoNotes: { success: action.payload, error: '' },
      };

    case MoocAdminActions.RESET_VIDEO_NOTES:
      return {
        ...state,
        getVideoNotes: INITIAL_STATE.getVideoNotes,
      };

    case MoocAdminActions.CREATE_VIDEO_NOTES_REQUEST_ERROR:
      return {
        ...state,
        createVideoNotes: { success: '', error: action.payload },
      };

    case MoocAdminActions.DOWNLOAD_CERTIFICATE_REQUEST_SUCCESS:
      return {
        ...state,
        downloadCertificate: { success: action.payload, error: '' },
      };

    case MoocAdminActions.DOWNLOAD_CERTIFICATE_REQUEST_ERROR:
      return {
        ...state,
        downloadCertificate: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_DOWNLOAD_CERTIFICATE_REQUEST:
      return {
        ...state,
        downloadCertificate: INITIAL_STATE.downloadCertificate,
      };

    case MoocAdminActions.LEARNING_PATH_TO_PUBLISH_SUCCESS:
      return {
        ...state,
        learningPathToPublish: { success: action.payload, error: '' },
      };

    case MoocAdminActions.LEARNING_PATH_TO_PUBLISH_ERROR:
      return {
        ...state,
        learningPathToPublish: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_LEARNING_PATH_TO_PUBLISH:
      return {
        ...state,
        learningPathToPublish: INITIAL_STATE.downloadCertificate,
      };

    case MoocAdminActions.PUBLISHED_LEARNING_PATH_SUCCESS:
      return {
        ...state,
        publishedLearningPath: { success: action.payload, error: '' },
      };

    case MoocAdminActions.PUBLISHED_LEARNING_PATH_ERROR:
      return {
        ...state,
        publishedLearningPath: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_PUBLISHED_LEARNING_PATH:
      return {
        ...state,
        publishedLearningPath: INITIAL_STATE.downloadCertificate,
      };

    //Get Learning Paths
    case MoocAdminActions.SET_GET_LEARNING_PATH_SUCCESS:
      return {
        ...state,
        learningPath: {
          ...INITIAL_STATE.learningPath,
          data: action.payload || [],
        },
      };

    case MoocAdminActions.SET_GET_LEARNING_PATH_ERROR:
      return {
        ...state,
        learningPath: {
          ...INITIAL_STATE.learningPath,
          error: action.payload,
        },
      };

    case MoocAdminActions.RESET_LEARNING_PATH:
      return {
        ...state,
        learningPath: INITIAL_STATE.learningPath,
      };

    case MoocAdminActions.SET_LEARNING_PATH_LOADER: {
      return {
        ...state,
        learningPath: {
          ...state.learningPath,
          getLearningPathLoader: action?.payload,
        },
      };
    }

    case MoocAdminActions.GET_COURSE_LIST_DROPDOWN_SUCCESS:
      return {
        ...state,
        getCourseList: { success: action.payload, error: '' },
      };

    case MoocAdminActions.GET_COURSE_LIST_DROPDOWN_ERROR:
      return {
        ...state,
        getCourseList: { success: '', error: action.payload },
      };

    case MoocAdminActions.CREATE_LEARNING_PATH_SUCCESS:
      return {
        ...state,
        createLearningPath: { success: action.payload, error: '' },
      };

    case MoocAdminActions.CREATE_LEARNING_PATH_ERROR:
      return {
        ...state,
        createLearningPath: { success: '', error: action.payload },
      };

    case MoocAdminActions.CREATE_LEARNING_PATH_RESET:
      return {
        ...state,
        createLearningPath: INITIAL_STATE.createLearningPath,
      };

    //Update learning path by ID
    case MoocAdminActions.SET_UPDATE_LEARNING_PATH_SUCCESS:
      return {
        ...state,
        updateLearningPath: {
          success: action.payload,
          error: '',
        },
      };

    case MoocAdminActions.SET_UPDATE_LEARNING_PATH_ERROR:
      return {
        ...state,
        updateLearningPath: {
          success: {},
          error: action.payload,
        },
      };
    case MoocAdminActions.SET_UPDATE_LEARNING_PATH_LOADER: {
      return {
        ...state,
        updateLearningPath: {
          ...state.updateLearningPath,
          updateLearningPathLoader: action?.payload,
        },
      };
    }

    case MoocAdminActions.SET_MY_LEARNING_PATH_SUCCESS: {
      return {
        ...state,
        myLearningPath: {
          ...state.myLearningPath,
          data: action?.payload || [],
        },
      };
    }

    case MoocAdminActions.ENROLL_LEARNING_PATH_SUCCESS: {
      return {
        ...state,
        enrollLearningPath: {
          success: action?.payload,
          error: '',
        },
      };
    }

    case MoocAdminActions.ENROLL_LEARNING_PATH_ERROR: {
      return {
        ...state,
        enrollLearningPath: {
          error: action?.payload,
          success: '',
        },
      };
    }

    case MoocAdminActions.RESET_ENROLL_LEARNING_PATH: {
      return {
        ...state,
        enrollLearningPath: INITIAL_STATE.enrollLearningPath,
      };
    }

    case MoocAdminActions.RESET_MY_LEARNING_PATH:
      return { ...state, myLearningPath: INITIAL_STATE.myLearningPath };

    case MoocAdminActions.GET_COURSE_LIST_BY_LP_ID_SUCCESS:
      return {
        ...state,
        courseByLpId: {
          ...INITIAL_STATE.courseByLpId,
          success: action.payload || [],
        },
      };

    case MoocAdminActions.GET_COURSE_LIST_BY_LP_ID_ERROR:
      return {
        ...state,
        courseByLpId: {
          ...INITIAL_STATE.courseByLpId,
          error: action.payload,
        },
      };

    case MoocAdminActions.RESET_COURSE_LIST_BY_LP_ID:
      return { ...state, courseByLpId: INITIAL_STATE.courseByLpId };

    case MoocAdminActions.SET_LP_COURSE_STATUS_SUCCESS:
      return {
        ...state,
        lpCourseStatus: { success: action.payload, error: '' },
      };

    case MoocAdminActions.SET_LP_COURSE_STATUS_ERROR:
      return {
        ...state,
        lpCourseStatus: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_LP_COURSE_STATUS:
      return {
        ...state,
        lpCourseStatus: INITIAL_STATE.lpCourseStatus,
      };

    case MoocAdminActions.SET_LP_BY_ID_SUCCESS:
      return {
        ...state,
        lpById: { success: action.payload, error: '' },
      };

    case MoocAdminActions.SET_LP_BY_ID_ERROR:
      return {
        ...state,
        lpById: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_LP_BY_ID:
      return { ...state, lpById: INITIAL_STATE.lpById };

    case MoocAdminActions.EDIT_LEARNING_PATH_SUCCESS:
      return {
        ...state,
        editLearningPath: { success: action.payload, error: '' },
      };

    case MoocAdminActions.EDIT_LEARNING_PATH_ERROR:
      return {
        ...state,
        editLearningPath: { success: '', error: action.payload },
      };

    case MoocAdminActions.RESET_EDIT_LEARNING_PATH:
      return { ...state, editLearningPath: INITIAL_STATE.editLearningPath };

    default:
      return state;
  }
};

export default moocAdminReducer;
