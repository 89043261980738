export const CounsellorActions = {
  COUNSELLOR: {
    LOADING: 'LOADING',
    GET_COUNSELLOR_STATS: 'GET_COUNSELLOR_STATS',
    GET_COUNSELLOR_STATS_SUCCESS: 'GET_COUNSELLOR_STATS_SUCCESS',
    GET_COUNSELLOR_STATS_ERROR: 'GET_COUNSELLOR_STATS_ERROR',
    GET_COUNSELLOR_LEADS: 'GET_COUNSELLOR_LEADS',
    GET_COUNSELLOR_LEADS_SUCCESS: 'GET_COUNSELLOR_LEADS_SUCCESS',
    GET_COUNSELLOR_LEADS_ERROR: 'GET_COUNSELLOR_LEADS_SUCCESS',
    RESET_CANDIDATES_LIST: 'RESET_CANDIDATES_LIST',
    RESET_SELECTED_PROSPECTUS: 'RESET_SELECTED_PROSPECTUS',
    GET_INTERESTED_PROSPECTUS: 'GET_INTERESTED_PROSPECTUS',
    GET_INTERESTED_PROSPECTUS_SUCCESS: 'GET_INTERESTED_PROSPECTUS_SUCCESS',
    GET_INTERESTED_PROSPECTUS_ERROR: 'GET_INTERESTED_PROSPECTUS_ERROR',
    RESET_INTERESTED_PROSPECTUS: 'RESET_INTERESTED_PROSPECTUS',
    ADD_SELECTED_PROSPECT: 'ADD_SELECTED_PROSPECT',
    ADD_SELECTED_PROSPECT_SUCCESS: 'ADD_SELECTED_PROSPECT_SUCCESS',
    ADD_SELECTED_PROSPECT_ERROR: 'ADD_SELECTED_PROSPECT_ERROR',
    RESET_ADD_SELECTED_PROSPECT: 'RESET_ADD_SELECTED_PROSPECT',
    GET_SELECTED_PROSPECT_LIST: 'GET_SELECTED_PROSPECT_LIST',
    GET_SELECTED_PROSPECT_LIST_SUCCESS: 'GET_SELECTED_PROSPECT_LIST_SUCCESS',
    GET_SELECTED_PROSPECT_LIST_ERROR: 'GET_SELECTED_PROSPECT_LIST_ERROR',
    DELETE_SELECTED_PROSPECT: 'DELETE_SELECTED_PROSPECT',
    DELETE_SELECTED_PROSPECT_SUCCESS: 'DELETE_SELECTED_PROSPECT_SUCCESS',
    DELETE_SELECTED_PROSPECT_ERROR: 'DELETE_SELECTED_PROSPECT_ERROR',
    SEND_PAYMENT_LINK: 'SEND_PAYMENT_LINK',
    SEND_PAYMENT_LINK_SUCCESS: 'SEND_PAYMENT_LINK_SUCCESS',
    SEND_PAYMENT_LINK_ERROR: 'SEND_PAYMENT_LINK_ERROR',
    UPDATE_PAYMENT_LINK: 'UPDATE_PAYMENT_LINK',
    UPDATE_PAYMENT_LINK_SUCCESS: 'UPDATE_PAYMENT_LINK_SUCCESS',
    UPDATE_PAYMENT_LINK_ERROR: 'UPDATE_PAYMENT_LINK_ERROR',
    START_CANDIDATE_COUNSELLING: 'START_CANDIDATE_COUNSELLING',
    START_CANDIDATE_COUNSELLING_SUCCESS: 'START_CANDIDATE_COUNSELLING_SUCCESS',
    START_CANDIDATE_COUNSELLING_ERROR: 'START_CANDIDATE_COUNSELLING_ERROR',
    SEND_TO_VERIFIER: 'SEND_TO_VERIFIER',
    SEND_TO_VERIFIER_SUCCESS: 'SEND_TO_VERIFIER_SUCCESS',
    SEND_TO_VERIFIER_ERROR: 'SEND_TO_VERIFIER_ERROR',
    GET_CURRENT_STAGE: 'GET_CURRENT_STAGE',
    GET_CURRENT_STAGE_SUCCESS: 'GET_CURRENT_STAGE_SUCCESS',
    GET_CURRENT_STAGE_ERROR: 'GET_CURRENT_STAGE_ERROR',
    SEND_PROSPECT_ONLINE: 'SEND_PROSPECT_ONLINE',
    SEND_PROSPECT_ONLINE_SUCCESS: 'SEND_PROSPECT_ONLINE_SUCCESS',
    SEND_PROSPECT_ONLINE_ERROR: 'SEND_PROSPECT_ONLINE_ERROR',
    GET_APP_FORM_DETAILS: 'GET_APP_FORM_DETAILS',
    GET_APP_FORM_DETAILS_SUCCESS: 'GET_APP_FORM_DETAILS_SUCCESS',
    GET_APP_FORM_DETAILS_ERROR: 'GET_APP_FORM_DETAILS_ERROR',
  },
};
