import { Role } from 'data/roles';
import { userManagementActions } from 'store/UserManagement';

const INITIAL_STATE = {
  user: {
    loading: false,
    data: {},
    deleteUserData: '',
    updateUser: false,
  },
  userLoginDetails: {
    data: {},
    loading: false,
  },
  anyUserDetails: {
    data: {},
    loading: false,
  },
  parent_user: {
    loading: false,
    data: [],
  },
  user_role: {
    loading: false,
    data: {},
  },
  all_user_roles: {
    loading: false,
    data: [],
  },
  all_users_roles: {
    loading: false,
    data: [],
    count: 0,
    error: '',
  },
  delete_user_roles: {
    loading: false,
    data: {},
  },

  user_role_details: {
    loading: false,
    data: [],
  },

  create_user_roles: {
    loading: false,
    data: {},
  },
  update_user_roles: {
    loading: false,
    data: {},
  },

  faculty_details: {
    loading: false,
    data: [],
    totalCount: 0,
  },
  hod_details: {
    loading: false,
    data: [],
    totalCount: 0,
  },
  students_details: {
    loading: false,
    data: [],
    totalCount: 0,
  },
  registrar_details: {
    loading: false,
    data: [],
    totalCount: 0,
  },
  dept_users: {
    loading: false,
    data: [],
    totalCount: 0,
  },
  student: {
    loading: false,
    totalCount: 0,
    list: [],
    data: {},
    createStudentError: '',
    studentData: {},
    uploadUserImage: '',
    deleteStudentData: '',
    uploadValidation: '',
    uploadValidationError: '',
    studetnUploadValidation: '',
    studentUploadValidationError: '',
    uploadUserData: '',
    courseMappingSuccess: {},
    allProgramList: [],
    success: false,
  },

  staff: {
    loading: false,
    totalCount: 0,
    list: [],
    data: {},
    staffData: {},
    designationList: [],
    uploadUserImage: '',
    deleteUserData: '',
    blockStaff: {},
    success: false,
    error: '',
  },
  filtered_user_list: {
    totalCount: 0,
    isLoading: false,
    data: [],
  },
  facultiesByDept: {
    totalCount: 0,
    isLoading: false,
    data: [],
  },
  addMoreUsers: {
    totalCount: 0,
    isLoading: false,
    data: [],
  },

  clusters_list: {
    totalCount: 0,
    data: [],
  },

  departments_list: {
    totalCount: 0,
    data: [],
  },
  courses_list: {
    totalCount: 0,
    data: [],
  },
  authentication: {
    tenantName: '',
    loginType: '',
    localUserLoginSuccess: false,
    localUserLogoutSuccess: false,
  },
  tenantPublicLogos: {
    loading: false,
    data: {},
    error: {},
  },
  candidate_registered: { registrationSuccess: {} },
  pendingOnboardingList: { loading: false, totalCount: 0, data: [] },
  onboardedCandidateList: { loading: false, totalCount: 0, data: [] },
  candidateLogin: {
    sendOtpSuccess: false,
    sendOtpError: false,
    verifyOtpSuccess: false,
    verifyOtpError: false,
  },
  hr: {
    userListData: { loading: false, data: {} },
  },
  group: {
    loading: false,
    totalCount: 0,
    data: {},
    staffData: {},
    groupList: {},
    createGroupError: {},
    searchUser: [],
    filterUser: [],
    filterAllUser: [],
    groupIdData: {},
    removeUser: [],
    addSelectedUser: [],
    bulkUpload: {},
    deactivate: {},
  },
  forgot_password: {
    loading: false,
    data: {},
    error: '',
  },
  theoryState: false,
  user_for_notification: {
    data: [],
    totalCount: 0,
  },
};

const userManagementReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case userManagementActions.USER.get_success: {
      if (action?.payload?.role !== Role.parent) {
        return {
          ...state,
          user: {
            ...state.user,
            data: action?.payload ?? {},
          },
        };
      } else {
        return {
          ...state,
          user: {
            ...state.user,
            data:
              (action?.payload?.students?.length > 0 &&
                action?.payload?.students?.[0]) ||
              {},
          },
          parent_user: {
            ...state.parent_user,
            data: action?.payload ?? {},
          },
        };
      }
    }

    case userManagementActions.USER.loading: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: action.payload,
        },
      };
    }

    case userManagementActions.USER_ROLE.get_success: {
      return {
        ...state,
        user_role: {
          ...state.user,
          data: action?.payload?.results ?? {},
        },
      };
    }

    case userManagementActions.USER_ROLE.get_all_roles_success: {
      return {
        ...state,
        all_user_roles: {
          ...state.all_user_roles,
          data: action?.payload?.results || [],
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_all_faculties_success: {
      return {
        ...state,
        faculty_details: {
          ...state.faculty_details,
          data: action?.payload?.results || [],
          totalCount: action?.payload?.count || 0,
        },
      };
    }

    case userManagementActions.USER_DETAILS.reset_all_faculties: {
      return {
        ...state,
        faculty_details: {
          ...state.faculty_details,
          data: [],
          totalCount: 0,
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_all_hods_success: {
      return {
        ...state,
        hod_details: {
          ...state.hod_details,
          data: action?.payload?.users,
          totalCount: action?.payload?.totalCount,
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_all_hods_reset: {
      return {
        ...state,
        hod_details: {
          ...INITIAL_STATE.hod_details,
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_all_hods_by_scroll_success: {
      return {
        ...state,
        hod_details: {
          ...state.hod_details,
          data: [...(state.hod_details.data || []), ...action?.payload?.users],
          totalCount: action?.payload?.totalCount,
        },
      };
    }

    case userManagementActions.STUDENT.get_success: {
      return {
        ...state,
        student: {
          ...state.student,
          totalCount: action?.payload?.totalCount,
          list: action?.payload,
        },
      };
    }

    case userManagementActions.STUDENT
      .get_student_details_with_program_list_success: {
      return {
        ...state,
        student: {
          ...state.student,
          totalCount: action.payload.count,
          list: action.payload.results || [],
        },
      };
    }
    case userManagementActions.STUDENT.get_all_program_list_success: {
      return {
        ...state,
        student: {
          ...state.student,
          allProgramList: action.payload,
        },
      };
    }

    case userManagementActions.STUDENT.reset: {
      return {
        ...state,
        student: {
          ...state.student,
          totalCount: 0,
          list: [],
        },
      };
    }
    case userManagementActions.STUDENT.loading: {
      return {
        ...state,
        student: {
          ...state.student,
          loading: action?.payload,
        },
      };
    }

    case userManagementActions.STAFF.loading: {
      return {
        ...state,
        staff: {
          ...state.staff,
          loading: action?.payload,
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_all_students_success: {
      return {
        ...state,
        students_details: {
          ...state.students_details,
          data: action?.payload?.results,
          totalCount: action?.payload?.count,
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_all_registrars_success: {
      return {
        ...state,
        registrar_details: {
          ...state.registrar_details,
          data: action?.payload?.users,
          totalCount: action?.payload?.totalCount,
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_dept_user_details_success: {
      return {
        ...state,
        dept_users: {
          ...state.dept_users,
          data: action?.payload?.departmentUsers,
          totalCount: action?.payload?.totalCount,
        },
      };
    }

    case userManagementActions.USER_DETAILS
      .get_dept_user_details_by_pagination_success: {
      return {
        ...state,
        dept_users: {
          ...state.dept_users,
          data: [
            ...(state?.dept_users.data || []),
            ...action?.payload?.departmentUsers,
          ],
          totalCount: action?.payload?.totalCount,
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_dept_user_details_reset: {
      return {
        ...state,
        dept_users: {
          ...INITIAL_STATE.dept_users,
        },
      };
    }

    case userManagementActions.STUDENT.create_success: {
      return {
        ...state,
        student: {
          ...state.student,
          data: action?.payload,
        },
      };
    }

    case userManagementActions.STUDENT.create_error: {
      return {
        ...state,
        student: {
          ...state.student,
          data: {},
          courseMappingSuccess: {},
          createStudentError: action.payload,
        },
      };
    }

    case userManagementActions.STUDENT.update_success: {
      return {
        ...state,
        student: {
          ...state.student,
          data: action?.payload,
        },
      };
    }
    case userManagementActions.STUDENT.update_error: {
      return {
        ...state,
        student: {
          ...state.student,
          data: action?.payload,
        },
      };
    }

    case userManagementActions.STUDENT.create_student_course_mapping_success: {
      return {
        ...state,
        student: {
          ...state.student,
          courseMappingSuccess: action?.payload,
        },
      };
    }

    case userManagementActions.STUDENT.reset_create_student: {
      return {
        ...state,
        student: {
          ...state.student,
          data: {},
          courseMappingSuccess: {},
          createStudentError: '',
        },
      };
    }

    case userManagementActions.STUDENT.get_details_success: {
      return {
        ...state,
        student: {
          ...state?.student,
          studentData: action?.payload,
        },
      };
    }
    case userManagementActions.STAFF.delete_user_success: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          deleteUserData: action?.payload,
        },
      };
    }
    case userManagementActions.STAFF.reset_delete_user: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          deleteUserData: '',
        },
      };
    }
    case userManagementActions.STUDENT.reset_details: {
      return {
        ...state,
        student: {
          ...state?.student,
          studentData: {},
          loading: false,
        },
      };
    }
    case userManagementActions.STUDENT.upload_validation_success: {
      return {
        ...state,
        student: {
          ...state?.student,
          uploadValidation: action?.payload,
        },
      };
    }
    case userManagementActions.STUDENT.upload_validation_error: {
      return {
        ...state,
        student: {
          ...state?.student,
          uploadValidationError: action?.payload,
        },
      };
    }
    case userManagementActions.STUDENT.reset_upload_validation: {
      return {
        ...state,
        student: {
          ...state?.student,
          uploadValidation: '',
          uploadValidationError: '',
        },
      };
    }
    case userManagementActions.STUDENT.upload_user_success: {
      return {
        ...state,
        student: {
          ...state?.student,
          uploadUserData: action?.payload,
        },
      };
    }
    case userManagementActions.STUDENT.reset_upload_user: {
      return {
        ...state,
        student: {
          ...state?.student,
          uploadUserData: '',
        },
      };
    }

    case userManagementActions.STUDENT.upload_img_success: {
      return {
        ...state,
        student: {
          ...state?.student,
          uploadUserImage: action?.payload,
        },
      };
    }

    case userManagementActions.STUDENT.reset_upload_img: {
      return {
        ...state,
        student: {
          ...state?.student,
          uploadUserImage: '',
        },
      };
    }

    case userManagementActions.STAFF.get_success: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          totalCount: action?.payload?.totalCount,
          list: action?.payload,
        },
      };
    }

    case userManagementActions.STAFF.create_success: {
      return {
        ...state,
        staff: {
          ...state.staff,
          data: action?.payload,
          success: true,
          error: '',
        },
      };
    }
    case userManagementActions.STAFF.create_error: {
      return {
        ...state,
        staff: {
          ...state.staff,
          data: action?.payload,
          success: '',
          error: true,
        },
      };
    }

    case userManagementActions.STAFF.update: {
      return {
        ...state,
        staff: {
          ...state.staff,
          success: '',
          error: '',
        },
      };
    }

    case userManagementActions.STAFF.update_success: {
      return {
        ...state,
        staff: {
          ...state.staff,
          data: action?.payload,
          success: true,
          error: '',
        },
      };
    }
    case userManagementActions.STAFF.update_error: {
      return {
        ...state,
        staff: {
          ...state.staff,
          success: '',
          error: true,
        },
      };
    }

    case userManagementActions.STAFF.reset_create_staff: {
      return {
        ...state,
        staff: {
          ...state.staff,
          data: {},
          success: '',
          error: '',
        },
      };
    }

    case userManagementActions.STAFF.get_details_success: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          staffData: action?.payload,
        },
      };
    }

    case userManagementActions.STAFF.reset_details: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          staffData: {},
        },
      };
    }

    case userManagementActions.STAFF.get_designation_success: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          designationList: action.payload,
        },
      };
    }

    case userManagementActions.STAFF.reset_designation: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          designationList: [],
        },
      };
    }

    case userManagementActions.STAFF.staff_upload_img_success: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          uploadUserImage: action?.payload,
        },
      };
    }

    case userManagementActions.STAFF.staff_reset_upload_img: {
      return {
        ...state,
        staff: {
          ...state?.staff,
          uploadUserImage: '',
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_filtered_user_details_success: {
      return {
        ...state,
        filtered_user_list: {
          ...state.filtered_user_list,
          data: [...state.filtered_user_list.data, ...action?.payload.users],
          totalCount: action?.payload?.totalCount,
        },
      };
    }

    case userManagementActions.USER_DETAILS.reset_filtered_user_details: {
      return {
        ...state,
        filtered_user_list: {
          ...state.filtered_user_list,
          data: [],
          totalCount: 0,
        },
      };
    }

    case userManagementActions.USER_DETAILS.get_faculties_by_dept_success: {
      return {
        ...state,
        facultiesByDept: {
          ...state.facultiesByDept,
          data: action?.payload.departmentUsers,
          totalCount: action?.payload?.totalCount,
        },
      };
    }
    case userManagementActions.USER_DETAILS.get_add_more_user_details_success: {
      return {
        ...state,
        addMoreUsers: {
          ...state.addMoreUsers,
          data: action?.payload.users,
          totalCount: action?.payload?.totalCount,
        },
      };
    }
    case userManagementActions.CLUSTERS.get_success: {
      return {
        ...state,
        clusters_list: {
          ...state.clusters_list,
          data: [...state.clusters_list.data, ...action?.payload.items],
          totalCount: action?.payload?.totalCount,
        },
      };
    }
    case userManagementActions.CLUSTERS.reset: {
      return {
        ...state,
        clusters_list: {
          ...state.clusters_list,
          data: [],
          totalCount: 0,
        },
      };
    }

    case userManagementActions.DEPARTMENTS.get_by_id_success: {
      return {
        ...state,
        departments_list: {
          ...state.departments_list,
          data: [...state.departments_list.data, ...action?.payload.items],
          totalCount: action?.payload?.totalCount,
        },
      };
    }

    case userManagementActions.DEPARTMENTS.reset_by_id: {
      return {
        ...state,
        departments_list: {
          ...state.departments_list,
          data: [],
          totalCount: 0,
        },
      };
    }

    case userManagementActions.COURSES.get_by_id_success: {
      return {
        ...state,
        courses_list: {
          ...state.courses_list,
          data: [...state.courses_list.data, ...action?.payload.items],
          totalCount: action?.payload?.totalCount,
        },
      };
    }

    case userManagementActions.COURSES.reset_by_id: {
      return {
        ...state,
        courses_list: {
          ...state.courses_list,
          data: [],
          totalCount: 0,
        },
      };
    }
    case userManagementActions.AUTHENTICATION.SAVE_TENANT_NAME: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          tenantName: action?.payload,
        },
      };
    }

    case userManagementActions.AUTHENTICATION.SAVE_LOGIN_TYPE: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loginType: action?.payload,
        },
      };
    }

    case userManagementActions.AUTHENTICATION.LOCAL_USER_LOGIN_SUCCESS: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          localUserLoginSuccess: action?.payload,
        },
      };
    }

    case userManagementActions.AUTHENTICATION.LOCAL_USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          localUserLogoutSuccess: action?.payload,
        },
      };
    }

    // get tenant specific and sponsor public logo's

    case userManagementActions.TENANT_SPECIFIC_LOGO
      .TENANT_SPECIFIC_LOGO_REQUEST: {
      return {
        ...state,
        tenantPublicLogos: {
          ...state.tenantPublicLogos,
          loading: true,
        },
      };
    }

    case userManagementActions.TENANT_SPECIFIC_LOGO
      .TENANT_SPECIFIC_LOGO_SUCCESS: {
      return {
        ...state,
        tenantPublicLogos: {
          ...state.tenantPublicLogos,
          data: { ...action?.payload } ?? {},
          loading: false,
        },
      };
    }

    case userManagementActions.TENANT_SPECIFIC_LOGO
      .TENANT_SPECIFIC_LOGO_RESET: {
      return {
        ...state,
        tenantPublicLogos: {
          ...state.tenantPublicLogos,
          data: {},
          loading: false,
        },
      };
    }
    case userManagementActions.CANDIDATE_STUDENT.CANDIDATE_STUDENT_SUCCESS: {
      return {
        ...state,
        candidate_registered: {
          ...state.candidate_registered,
          registrationSuccess: { ...action?.payload },
        },
      };
    }

    case userManagementActions.CANDIDATE_ONBOARDING_PENDING
      .CANDIDATE_ONBOARDING_SUCCESS: {
      return {
        ...state,
        pendingOnboardingList: {
          ...state.pendingOnboardingList,
          data: action?.payload?.results || [],
          totalCount: action?.payload?.count || 0,
        },
      };
    }

    case userManagementActions.CHANGE_STUDENT.CHANGE_STUDENT_REQUEST: {
      return {
        ...state,
        user: {
          ...state.user,
          data: action?.payload,
        },
      };
    }

    case userManagementActions.CANDIDATE_ONBOARDED
      .CANDIDATE_ONBOARDED_SUCCESS: {
      return {
        ...state,
        onboardedCandidateList: {
          ...state.onboardedCandidateList,
          data: action?.payload?.results || [],
          totalCount: action?.payload?.count || 0,
        },
      };
    }

    case userManagementActions.CANDIDATE_LOGIN.SEND_OTP_SUCCESS: {
      return {
        ...state,
        candidateLogin: {
          ...state.candidateLogin,
          sendOtpSuccess: action.payload,
        },
      };
    }

    case userManagementActions.CANDIDATE_LOGIN.SEND_OTP_ERROR: {
      return {
        ...state,
        candidateLogin: {
          ...state.candidateLogin,
          sendOtpSuccess: false,
          sendOtpError: action.payload,
        },
      };
    }

    case userManagementActions.CANDIDATE_LOGIN.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        candidateLogin: {
          ...state.candidateLogin,
          verifyOtpSuccess: action.payload,
        },
      };
    }

    case userManagementActions.CANDIDATE_LOGIN.VERIFY_OTP_ERROR: {
      return {
        ...state,
        candidateLogin: {
          ...state.candidateLogin,
          verifyOtpSuccess: false,
          verifyOtpError: action.payload,
        },
      };
    }

    case userManagementActions.CANDIDATE_LOGIN.RESET_CANDIDATE_LOGIN: {
      return {
        ...state,
        candidateLogin: {
          sendOtpSuccess: false,
          sendOtpError: false,
          verifyOtpSuccess: false,
          verifyOtpError: false,
        },
      };
    }

    case userManagementActions.HR.GET_ALL_USER_REQUEST: {
      return {
        ...state,
        hr: {
          ...state?.hr,
          userListData: {
            ...state?.hr.userListData,
            loading: true,
            error: false,
            errorMessage: '',
          },
        },
      };
    }

    case userManagementActions.HR.GET_ALL_USER_SUCCESS: {
      return {
        ...state,
        hr: {
          ...state?.hr,
          userListData: {
            ...state?.hr.userListData,
            data: action.payload || {},
            loading: false,
            error: false,
            errorMessage: '',
          },
        },
      };
    }

    case userManagementActions.STAFF.block: {
      return {
        ...state,
        STAFF: {
          ...state?.staff,
          staffData: {
            ...state?.staff.staffData,
            data: action.payload || {},
          },
        },
      };
    }
    case userManagementActions.STAFF.block_success: {
      return {
        ...state,
        STAFF: {
          ...state?.staff,
          staffData: {
            ...state?.staff.staffData,
            data: action.payload || {},
          },
        },
      };
    }

    case userManagementActions.HR.GET_ALL_USER_ERROR: {
      return {
        ...state,
        hr: {
          ...state?.hr,
          userListData: {
            ...state?.hr.userListData,
            loading: false,
            errorMessage: action.payload || '',
            error: true,
          },
        },
      };
    }
    //Get all user role
    case userManagementActions.ALL_USER_ROLE.GET_ALL_USER_ROLES: {
      return {
        ...state,
        all_users_roles: {
          ...state.all_users_roles,
        },
      };
    }

    case userManagementActions.ALL_USER_ROLE.GET_ALL_USER_ROLES_SUCCESS: {
      return {
        ...state,
        all_users_roles: {
          data: action.payload,
          loading: false,
          error: '',
          count: action.payload?.count,
        },
      };
    }

    case userManagementActions.ALL_USER_ROLE.GET_ALL_USER_ROLES_ERROR: {
      return {
        ...state,
        all_users_roles: {
          ...INITIAL_STATE.all_users_roles,
          error: action.payload,
        },
      };
    }
    case userManagementActions.ALL_USER_ROLE.GET_ALL_USER_ROLES_RESET: {
      return {
        ...state,
        all_users_roles: {
          ...INITIAL_STATE.all_users_roles,
        },
      };
    }
    //Delete user role
    case userManagementActions.DELETE_USER_ROLE.DELETE_USER_ROLES: {
      return {
        ...state,
        delete_user_roles: {
          ...state.delete_user_roles,
          data: action.payload,
        },
      };
    }

    case userManagementActions.DELETE_USER_ROLE.DELETE_USER_ROLES_SUCCESS: {
      return {
        ...state,
        delete_user_roles: {
          ...state.delete_user_roles,
          data: action.payload,
        },
        loading: false,
      };
    }

    case userManagementActions.DELETE_USER_ROLE.DELETE_USER_ROLES_ERROR: {
      return {
        ...state,
        delete_user_roles: {
          ...state?.delete_user_roles,
        },
      };
    }

    //Get  user role details
    case userManagementActions.USER_ROLE_DETAILS.GET_USER_ROLE_DETAILS: {
      return {
        ...state,
        user_role_details: {
          ...state.user_role_details,
          data: action.payload,
          loading: true,
        },
      };
    }

    case userManagementActions.USER_ROLE_DETAILS
      .GET_USER_ROLE_DETAILS_SUCCESS: {
      return {
        ...state,
        user_role_details: {
          ...state.user_role_details,
          data: action.payload || [],

          loading: false,
        },
      };
    }

    case userManagementActions.USER_ROLE_DETAILS.GET_USER_ROLE_DETAILS_ERROR: {
      return {
        ...state,
        user_role_details: {
          ...state?.user_role_details,
          loading: false,
        },
      };
    }

    // Create user Role
    case userManagementActions.CREATE_USER_ROLES.CREATE_USER_ROLE: {
      return {
        ...state,
        create_user_roles: {
          ...state.create_user_roles,
        },
      };
    }

    case userManagementActions.CREATE_USER_ROLES.CREATE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        create_user_roles: {
          ...state?.create_user_roles,
          data: action.payload?.results || [],
        },
      };
    }

    case userManagementActions.CREATE_USER_ROLES.CREATE_USER_ROLE_ERROR: {
      return {
        ...state,
        create_user_roles: {
          ...state?.create_user_roles,
        },
      };
    }

    case userManagementActions.CREATE_USER_ROLES.RESET_CREATE_USER_ROLE: {
      return {
        ...state,
        create_user_roles: {
          ...state.create_user_roles,
          data: {},
        },
      };
    }

    // Update user Role
    case userManagementActions.UPDATE_USER_ROLES.UPDATE_USER_ROLE: {
      return {
        ...state,
        update_user_roles: {
          ...state.update_user_roles,
        },
      };
    }

    case userManagementActions.UPDATE_USER_ROLES.UPDATE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        update_user_roles: {
          ...state?.update_user_roles,
          data: action.payload?.results || [],
        },
      };
    }

    case userManagementActions.UPDATE_USER_ROLES.UPDATE_USER_ROLE_ERROR: {
      return {
        ...state,
        update_user_roles: {
          ...state?.update_user_roles,
        },
      };
    }
    case userManagementActions.GROUP.SUCCESS_CREATE_GROUP: {
      return {
        ...state,
        group: {
          ...state.group,
          data: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.get_group_details_success: {
      return {
        ...state,
        group: {
          ...state?.group,
          groupList: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.get_group_details_by_id_success: {
      return {
        ...state,
        group: {
          ...state.group,
          groupIdData: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.get_group_details_by_id_reset: {
      return {
        ...state,
        group: {
          ...state.group,
          groupIdData: [],
        },
      };
    }
    case userManagementActions.GROUP.SUCCESS_EDIT_GROUP: {
      return {
        ...state,
        group: {
          ...state.group,
          data: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.SUCCESS_DELETE_GROUP: {
      return {
        ...state,
        group: {
          ...state.group,
          data: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.SEARCH_USER_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          searchUser: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.APPLY_FILTER_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          filterUser: action.payload,
        },
      };
    }

    case userManagementActions.GROUP.APPLY_ALL_FILTER_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          filterAllUser: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.RESET_FILTERE: {
      return {
        ...state,
        group: {
          ...state.group,
          filterUser: [],
        },
      };
    }
    case userManagementActions.GROUP.REMOVE_USER: {
      return {
        ...state,
        group: {
          ...state.group,
          removeUser: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.SET_ADD_SELECTED_USER_TO_GROUP_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          addSelectedUser: action.payload,
        },
      };
    }

    case userManagementActions.STUDENT.delete_student_success: {
      return {
        ...state,
        student: {
          ...state?.student,
          deleteStudentData: action?.payload,
        },
      };
    }

    case userManagementActions.STUDENT.reset_delete_user: {
      return {
        ...state,
        student: {
          ...state?.student,
          deleteStudentData: '',
        },
      };
    }

    case userManagementActions.FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgot_password: {
          ...state.forgot_password,
          data: action.payload,
        },
      };
    }
    case userManagementActions.FORGOT_PASSWORD.FORGOT_PASSWORD_ERROR: {
      return {
        ...state,
        forgot_password: {
          ...state.forgot_password,
          data: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.USER_GROUP_BULK_UPLOAD_REQUEST: {
      const updatedGroup = {
        ...state.group,
        bulkUpload: {
          loading: true,
          formData: action.payload,
        },
      };
      return {
        ...state,
        group: updatedGroup,
      };
    }

    case userManagementActions.GROUP.SET_USER_GROUP_BULK_UPLOAD_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          bulkUpload: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.SET_USER_GROUP_BULK_UPLOAD_ERROR: {
      return {
        ...state,
        group: {
          ...state.group,
          bulkUpload: action.payload,
        },
      };
    }
    case userManagementActions.GROUP.SET_DEACTIVATE_GROUP_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          deactivate: action.payload,
        },
      };
    }

    // Student Bulk
    case userManagementActions.STUDENT.bulk_upload_student_success: {
      return {
        ...state,
        student: {
          ...state?.student,
          studetnUploadValidation: action?.payload,
          success: true,
        },
      };
    }
    case userManagementActions.STUDENT.bulk_upload_student_error: {
      return {
        ...state,
        student: {
          ...state?.student,
          studentUploadValidationError: action?.payload,
          success: false,
        },
      };
    }
    case userManagementActions.STUDENT.bulk_upload_student_reset: {
      return {
        ...state,
        student: {
          ...state?.student,
          studentUploadValidationError: '',
          success: '',
        },
      };
    }

    case userManagementActions.USER_LOGIN_DETAILS.USER_LOGIN_DETAILS_SUCCESS: {
      return {
        ...state,
        userLoginDetails: {
          ...state?.userLoginDetails,
          data: action?.payload,
        },
      };
    }

    case userManagementActions.ANY_USER_DETAILS.ANY_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        anyUserDetails: {
          ...state?.anyUserDetails,
          data: action?.payload || {},
        },
      };
    }

    case userManagementActions.ANY_USER_DETAILS.ANY_USER_DETAILS_RESET: {
      return {
        ...state,
        anyUserDetails: {
          ...state?.anyUserDetails,
          data: {},
        },
      };
    }

    case userManagementActions.STAFF.set_theory_state: {
      return {
        ...state,
        theoryState: action.payload,
      };
    }

    case userManagementActions.USER_DETAILS.get_user_for_notification_sucess: {
      return {
        ...state,
        user_for_notification: {
          ...state?.user_for_notification,
          data: action?.payload || [],
          totalCount: action?.payload?.length,
        },
      };
    }

    default:
      return state;
  }
};

export default userManagementReducer;
