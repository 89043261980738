import { GamificationActions } from './Gamification.constants';

const INITIAL_STATE = {
  gamification: {
    loading: false,
    list: [],
  },
  Courses: {
    loading: false,
    list: [],
  },
  studentGamification: {
    loading: false,

    topPerformers: [],
    badgeList: [],
    studentbadgeList: [],
  },
  badge: {
    loading: false,
    data: [],
    update: {},
    icons: {},
    createBadge: {},
    error: {},
    get: {},
    totalCount: 0,
    delete_result: {},
    StudentProgram: {},
  },
};
const gamificationReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GamificationActions.GAMIFICATIONS.loading: {
      return {
        ...state,
        gamification: {
          ...state.gamification,
          loading: action?.payload,
        },
      };
    }

    case GamificationActions.COURSE.loading: {
      return {
        ...state,
        Courses: {
          ...state.Courses,
          loading: action?.payload,
        },
      };
    }

    case GamificationActions.STUDENT_GAMIFICATION.loading: {
      return {
        ...state,
        studentGamification: {
          ...state.studentGamification,
          loading: action?.payload,
        },
      };
    }

    case GamificationActions.STUDENT_GAMIFICATION.reset_gamification_list: {
      return {
        ...state,
        studentGamification: {
          ...state.studentGamification,
          totalCount: INITIAL_STATE.studentGamification.totalCount,
          data: INITIAL_STATE.studentGamification.data,
        },
      };
    }
    case GamificationActions.STUDENT_GAMIFICATION.get_top_performer: {
      return {
        ...state,
        studentGamification: {
          ...state.studentGamification,
          topPerformers: action?.payload,
        },
      };
    }

    case GamificationActions.BADGE.get_success: {
      return {
        ...state,
        badge: {
          ...state.badge,
          data: action?.payload?.results || [],
          totalCount: action?.payload?.count,
          loading: action?.payload,
        },
      };
    }
    case GamificationActions.BADGE.icons_success: {
      return {
        ...state,
        badge: {
          ...state.badge,
          icons: action?.payload,
        },
      };
    }
    case GamificationActions.BADGE.delete_success: {
      return {
        ...state,
        badge: {
          ...state.badge,
          delete_result: action?.payload,
        },
      };
    }

    case GamificationActions.BADGE.create_success: {
      return {
        ...state,
        badge: {
          ...state.badge,
          createBadge: action?.payload,
        },
      };
    }
    case GamificationActions.BADGE.create_reset: {
      return {
        ...state,
        badge: {
          ...state.badge,
          createBadge: {},
        },
      };
    }

    case GamificationActions.BADGE.get_by_id_success: {
      return {
        ...state,
        badge: {
          ...state.badge,
          get: action?.payload,
        },
      };
    }
    case GamificationActions.BADGE.get: {
      return {
        ...state,
        badge: {
          ...state.badge,
          get: action?.payload,
        },
      };
    }
    case GamificationActions.BADGE.update: {
      return {
        ...state,
        badge: {
          ...state.badge,
          update: action?.payload,
        },
      };
    }
    case GamificationActions.STUDENT_GAMIFICATION
      .get_student_badge_list_success: {
      return {
        ...state,
        studentGamification: {
          ...state.studentGamification,
          badgeList: action?.payload?.results || [],
          loading: false,
          totalCount: action?.payload?.count,
        },
      };
    }

    case GamificationActions.STUDENT_GAMIFICATION
      .get_student_badge_detail_success: {
      return {
        ...state,
        studentGamification: {
          ...state.studentGamification,
          studentbadgeList: action?.payload?.results || [],
          loading: false,
          totalCount: action?.payload?.count,
        },
      };
    }

    case GamificationActions.BADGE.get_student_program_success: {
      return {
        ...state,
        badge: {
          ...state.badge,
          StudentProgram: action?.payload?.results || [],
          loading: false,
          totalCount: action?.payload?.count,
        },
      };
    }
    default:
      return state;
  }
};

export default gamificationReducer;
