import { examActions } from 'store/Exams';
const INITIAL_STATE = {
  exams: {
    loading: false,
    count: 0,
    data: [],
    examDeleteSuccess: false,
    examSaveSuccess: false,
    examIdData: {},
    examSaveError: {},
    createExamContentSuccess: {},
    publishExamSucces: {},
    getExamContentSuccess: {},
    getPracticalExamContentSuccess: {},
    editExamContentSuccess: {},
    savedTheoryExamContent: {},
    savedPracticalExamContent: {},
    multipleChoiceAnswer: null,
  },
  programs: {
    data: [],
  },
  programYear: {
    data: [],
  },
  semester: {
    data: [],
  },
  subject: {
    data: [],
  },
  exam: {
    data: [],
    dashboardList: [],
  },
};

const examsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case examActions.EXAMS.get_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          count: action?.payload?.count,
          data: action?.payload?.results,
        },
      };
    }

    case examActions.EXAMS.create_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          examSaveSuccess: action?.payload,
        },
      };
    }

    case examActions.EXAMS.create_error: {
      return {
        ...state,
        exams: {
          ...state.exams,
          examSaveError: action?.payload,
        },
      };
    }

    case examActions.EXAMS.delete_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          examDeleteSuccess: action?.payload,
        },
      };
    }

    case examActions.EXAMS.get_by_id_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          examIdData: action?.payload,
        },
      };
    }

    case examActions.EXAMS.create_content_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          createExamContentSuccess: action?.payload,
        },
      };
    }

    case examActions.EXAMS.publish_content_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          publishExamSucces: action?.payload,
        },
      };
    }

    case examActions.EXAMS.get_exam_content_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          getExamContentSuccess: action?.payload[0] || {},
        },
      };
    }

    case examActions.EXAMS.get_practical_content_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          getPracticalExamContentSuccess: action?.payload?.[0] || {},
        },
      };
    }

    case examActions.EXAMS.save_theory_content_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          savedTheoryExamContent: action?.payload,
        },
      };
    }

    case examActions.EXAMS.save_theory_content_reset: {
      return {
        ...state,
        exams: {
          ...state.exams,
          savedTheoryExamContent: action?.payload,
        },
      };
    }

    case examActions.EXAMS.save_practical_content_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          savedPracticalExamContent: action?.payload,
        },
      };
    }

    case examActions.EXAMS.edit_content_success: {
      return {
        ...state,
        exams: {
          ...state.exams,
          editExamContentSuccess: action?.payload,
        },
      };
    }

    case examActions.EXAMS.reset: {
      return {
        ...state,
        exams: {
          ...INITIAL_STATE?.exams,
        },
      };
    }

    case examActions.EXAMS.loading: {
      return {
        ...state,
        exams: {
          ...state.exams,
          loading: action?.payload,
        },
      };
    }
    case examActions.PROGRAM.GET_SUCCESS_PROGRAMS_LIST_INCR: {
      return {
        ...state,
        programs: {
          ...state.programs,
          data: action.payload.user_subjects || [],
        },
      };
    }
    case examActions.PROGRAM_YEAR.GET_EXAMS_SUCCESS_PROGRAM_YEAR_LIST: {
      return {
        ...state,
        programYear: {
          ...state.programYear,
          data: action.payload.user_subjects,
        },
      };
    }
    case examActions.SEMESTER.GET_EXAMS_SUCCESS_SEMESTER_LIST: {
      return {
        ...state,
        semester: {
          ...state.semester,
          data: action.payload.user_subjects,
        },
      };
    }
    case examActions.EXAM.GET_SUCCESS_EXAMS_LIST: {
      return {
        ...state,
        exam: {
          ...state.exam,
          data: action.payload,
        },
      };
    }
    case examActions.EXAM.GET_SUCCESS_EXAMS_DASHBOARD_LIST: {
      return {
        ...state,
        exam: {
          ...state.exam,
          dashboardList: action.payload,
        },
      };
    }

    case examActions.PROGRAM.GET_NEW_PROGRAMS_LIST_SUCCESS: {
      return {
        ...state,
        programs: {
          ...state.programs,
          data: action.payload.results,
        },
      };
    }
    case examActions.PROGRAM_YEAR.GET_NEW_PROGRAM_YEAR_LIST_SUCCESS: {
      return {
        ...state,
        programYear: {
          ...state.programYear,
          data: action.payload.results,
        },
      };
    }
    case examActions.SEMESTER.GET_NEW_SEMESTER_LIST_SUCCESS: {
      return {
        ...state,
        semester: {
          ...state.semester,
          data: action.payload.results,
        },
      };
    }
    case examActions.SUBJECT.GET_SUBJECT_LIST_SUCCESS: {
      return {
        ...state,
        subject: {
          ...state.subject,
          data: action.payload.results,
        },
      };
    }

    case examActions.MULTIPLE_QUESTION_ANSWER
      .set_multiple_choice_question_answer: {
      return {
        ...state,
        exams: {
          ...state.exams,
          multipleChoiceAnswer: action?.payload,
        },
      };
    }

    case examActions.MULTIPLE_QUESTION_ANSWER
      .set_multiple_choice_question_answer_reset: {
      return {
        ...state,
        exams: {
          ...state.exams,
          multipleChoiceAnswer: action?.payload,
        },
      };
    }
    default:
      return state;
  }
};
export default examsReducer;
