import { ROUTES } from 'constants/routesConstants';
import { Redirect } from 'react-router-dom';

const RoleWiseRouter = () => {
  const { DASHBOARD } = ROUTES;

  return <Redirect to={DASHBOARD} />;
};

export default RoleWiseRouter;
