import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import {
  FormTextField,
  FormSelectField,
  FormCheckbox,
  FormDatepicker,
  LoaderComponent,
} from 'components';
import { AddIconCircle, DeleteSvg } from 'icons';
import {
  Typography,
  Grid,
  Divider,
  Button,
} from 'iiht-b2a-ui-components/build/components';
import './OnboardUserForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetClusterData,
  resetCourseData,
  resetDepartmentData,
} from 'store/CourseManagement';
import {
  addressFinder,
  addressMapper,
  dynamicAutoListMapper,
  getUserRole,
} from 'utils';
import moment from 'moment';
import _ from 'lodash';
import { createStudent } from 'store';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getStudentDetails,
  resetStudentDetails,
  requestUploadImage,
  resetCreateStudent,
  resetStaffUploadImage,
  getAllClustersList,
  resetAllClustersList,
  getDepartmentbyId,
  resetDepartmentbyId,
  getCoursesbyId,
  resetCoursesbyId,
  requestStaffUploadImage,
  getDesignation,
} from 'store/UserManagement';
import { ROUTES } from 'constants/routesConstants';
import { FormAutoComplete } from '../FormAutoComplete';
import { validationRules } from 'constants/validationRules';
import { Country, State, City } from 'country-state-city';
import {
  gendersOptions,
  maritialOptions,
  idTypeOptions,
  educationOptions,
  category,
  specially_abled,
} from 'constants/commonConstants';
import { validateFileSize, validateFileType } from 'utils/validation';
import StudentProgramDetails from './StudentProgramDetails';
import { getStudentProgramDetailsAction, requestGetAllUsers } from 'store';

const OnboardUserForm = ({ userType }) => {
  const [mapClusters, setMapClusters] = useState([]);
  const [mapDepartments, setMapDepartments] = useState([]);
  const [mapCourses, setMapCourses] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const [clusterId, setClusterId] = useState('');
  const [addressData, setAddressData] = useState({
    states: [],
    cities: [],
  });
  const [mapCountries, setMapCountries] = useState([]);
  const [mapStates, setMapStates] = useState([]);
  const [mapCities, setMapCities] = useState([]);
  const [mapPermanentStates, setMapPermanentStates] = useState([]);
  const [mapPermanentCities, setMapPermanentCities] = useState([]);
  const [editedCurrentCountry, setEditedCurrentCountry] = useState(null);
  const [editedPermanentCountry, setEditedPermanentCountry] = useState(null);

  const [files, setFiles] = useState('');

  const [student, setStudent] = useState({});

  const role = getUserRole();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const profileImageValidation = {
    fileType: ['image/png', 'image/jpeg', 'image/jpg'],
    fileTypeError: 'Support .png, .jpg and .jpeg formats',
    fileSize: 2, // in MB
    fileSizeError: 'Upload image less than or equal to 2MB',
  };
  const [profileImageError, setProfileImageError] = useState('');

  const {
    data: createdStudent,
    studentData,
    loading,
  } = useSelector((state) => state.userManagementState.student);
  const { idp_tenant_uuid: tenantId } = useSelector(
    (state) => state.userManagementState.user.data
  );
  const { clusters_list, departments_list, courses_list } = useSelector(
    (state) => state.userManagementState
  );
  const { uploadUserImage } = useSelector(
    (state) => state?.userManagementState?.staff || ''
  );

  useEffect(() => {
    dispatch(resetAllClustersList());
    dispatch(resetDepartmentbyId());
    dispatch(resetCoursesbyId());
    dispatch(resetCourseData());
    dispatch(resetClusterData());
    dispatch(resetDepartmentData());
  }, []);

  const getClustersListFunction = useCallback(
    (SearchStringLocal = '', SkipCountLocal = 0, MaxResultCountLocal = 5) => {
      const req = {
        MaxResultCount: MaxResultCountLocal,
        SkipCount: SkipCountLocal,
        // SearchString: SearchStringLocal,
      };
      dispatch(getAllClustersList(req));
    },
    []
  );

  const getDepartmentListFunction = useCallback(
    (
      SearchStringLocal = '',
      SkipCountLocal = 0,
      MaxResultCountLocal = 5,
      id
    ) => {
      const req = {
        MaxResultCount: MaxResultCountLocal,
        SkipCount: SkipCountLocal,
        // SearchString: SearchStringLocal,
        clusterId: id || clusterId,
      };
      dispatch(getDepartmentbyId(req));
    },
    [clusterId]
  );

  const getCoursesListFunction = useCallback(
    (
      SearchStringLocal = '',
      SkipCountLocal = 0,
      MaxResultCountLocal = 5,
      id
    ) => {
      const req = {
        MaxResultCount: MaxResultCountLocal,
        SkipCount: SkipCountLocal,
        // SearchString: SearchStringLocal,
        deptId: id || departmentId,
      };
      dispatch(getCoursesbyId(req));
    },
    [departmentId]
  );

  useEffect(() => {
    getClustersListFunction();
    return () => {
      dispatch(resetAllClustersList());
      dispatch(resetDepartmentbyId());
      dispatch(resetCoursesbyId());
      dispatch(resetStudentDetails());
      dispatch(resetStaffUploadImage());
      dispatch(resetCreateStudent());
      dispatch(resetCourseData());
      dispatch(resetClusterData());
      dispatch(resetDepartmentData());
    };
  }, []);

  useEffect(() => {
    const payload = {
      user: id,
    };
    id &&
      (dispatch(getStudentDetails(id)),
      dispatch(getStudentProgramDetailsAction(payload)));
  }, [id]);

  useEffect(() => {
    if (clusters_list?.data?.length > 0) {
      setMapClusters(
        dynamicAutoListMapper(clusters_list?.data, 'clusterName', 'id')
      );
    }
    if (departments_list?.data?.length > 0) {
      setMapDepartments(
        dynamicAutoListMapper(
          departments_list?.data,
          'departmentName',
          'departmentId'
        )
      );
    }
    if (courses_list?.data?.length > 0) {
      setMapCourses(
        dynamicAutoListMapper(courses_list?.data, 'courseName', 'courseId')
      );
    }
  }, [clusters_list, departments_list, courses_list]);

  useEffect(() => {
    const countryData = Country.getAllCountries();
    // const stateData = State.getAllStates();
    // const cityData = City.getAllCities();
    const countryMapper = addressMapper(countryData, 'name', 'isoCode');
    setMapCountries(countryMapper);
    // setAddressData({
    //   states: stateData,
    //   cities: cityData,
    // });
  }, []);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    const isFileTypeSupport = validateFileType(
      profileImageValidation?.fileType,
      file
    );
    const isValidFileSize = validateFileSize(
      profileImageValidation?.fileSize,
      file
    );
    if (!isFileTypeSupport) {
      setProfileImageError(profileImageValidation.fileTypeError);
    } else if (!isValidFileSize) {
      setProfileImageError(profileImageValidation.fileSizeError);
    }
    if (isFileTypeSupport && isValidFileSize) {
      setFiles(file);
      setProfileImageError('');
      if (file) {
        const reader = new FileReader();
        const { current } = uploadedImage;
        current.file = file;
        reader.onload = (e) => {
          current.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
    if (file) {
      const formData = new FormData();
      formData.append('profile_img', file);
      dispatch(requestStaffUploadImage(formData));
    }
  };

  useEffect(() => {
    if (createdStudent?.isSuccess) {
      setStudent({
        userId: createdStudent?.user?.id,
        onboarding_time: createdStudent?.user?.created_at,
        abc_id: createdStudent?.user?.userMetaData?.abc_id,
        uid: createdStudent?.user?.userMetaData?.uid,
        email: createdStudent?.user?.email,
        user_name:
          createdStudent?.user?.first_name +
          ' ' +
          createdStudent?.user?.middle_name +
          ' ' +
          createdStudent?.user?.last_name,
      });
      if (files) {
        const formData = new FormData();
        formData.append('file', files);
        dispatch(
          requestUploadImage({ userId: createdStudent?.user?.id }, formData)
        );
      } else {
        // history.push(ROUTES?.STUDENT_MANAGEMENT?.ROOT);
      }
    }
  }, [createdStudent]);

  const initialValues = {
    first_name: '',
    tenant_name: '',
    middle_name: '',
    last_name: '',
    contact_no: '',
    email: '',
    uid: '',
    created_by: '',
    medium: '',
    category: '',
    specially_abled: '',
    course_code: '',
    college_id: '',
    institute_name: '',
    abc_id: '',
    enrollment_no: '',
    study_center_code: '',
    gender: '',
    marital_status: '',
    DOB: '',
    id_type: '',
    id_number: '',
    father_name: '',
    father_contact: '',
    father_email: '',
    mother_name: '',
    mother_contact: '',
    mother_email: '',
    mother_maiden_name: '',
    education_detail: [
      { education: '', School: '', University: '', degree: '' },
    ],
    profile_picture: '',
    student_address: [
      {
        p_locality: '',
        p_city: '',
        p_state: '',
        p_country: '',
        p_pincode: '',
        is_current_address_same: false,
        c_locality: '',
        c_city: '',
        c_state: '',
        c_country: '',
        c_pincode: '',
      },
    ],
    role_id: [{ roleId: '' }],
    user_group_id: [{ group_id: '' }],
    user_program_mapping: [
      {
        user_type: '',
        program_name: '',
        department: '',
        program: '',
        year: '',
        year_id: '',
        semester: '',
        semester_id: '',
        exam_medium: '',
      },
    ],
    program_details: '',
  };

  const methods = useForm({
    defaultValues: initialValues,
  });

  const { handleSubmit, watch, setValue, reset } = methods;

  useEffect(() => {
    const countryData = Country.getAllCountries();
    if (studentData?.student_address?.[0]?.c_country) {
      const editedCountry = addressFinder(
        countryData,
        studentData?.student_address?.[0]?.c_country?.toLowerCase()
      );
      setEditedCurrentCountry({
        id: Math.random() * 10,
        label: editedCountry?.name,
        value: editedCountry?.isoCode,
      });
    }

    if (studentData?.student_address?.[0]?.p_country) {
      const editedCountry = addressFinder(
        countryData,
        studentData?.student_address?.[0]?.p_country?.toLowerCase()
      );
      setEditedPermanentCountry({
        id: Math.random() * 10,
        label: editedCountry?.name,
        value: editedCountry?.isoCode,
      });
    }
  }, [studentData]);

  useEffect(() => {
    if (location?.state?.isEdit) {
      setStudent({
        userId: studentData?.id,
        onboarding_time: studentData?.created_at,
        abc_id: studentData?.userMetaData?.abcid,
        uid: studentData?.userMetaData?.uid,
        email: studentData?.email,
        user_name:
          studentData?.first_name +
          ' ' +
          studentData?.middle_name +
          ' ' +
          studentData?.last_name,
      });
      if (studentData?.student_address?.[0]?.is_current_address_same) {
        if (studentData?.student_address?.[0]?.c_state) {
          const editedState = addressFinder(
            addressData?.states,
            studentData?.student_address?.[0]?.c_state?.toLowerCase()
          );
          const currentStates = State.getStatesOfCountry(
            editedState?.countryCode
          );
          const stateMapper = addressMapper(currentStates, 'name', 'isoCode');
          setMapStates(stateMapper);
        }

        if (studentData?.student_address?.[0]?.c_city) {
          const editedCity = addressFinder(
            addressData?.cities,
            studentData?.student_address?.[0]?.c_city?.toLowerCase()
          );
          const currentCities = City.getCitiesOfState(
            editedCity?.countryCode,
            editedCity?.stateCode
          );
          const cityMapper = addressMapper(currentCities, 'name', 'stateCode');
          setMapCities(cityMapper);
        }
      } else {
        if (studentData?.student_address?.[0]?.p_state) {
          const editedState = addressFinder(
            addressData?.states,
            studentData?.student_address?.[0]?.p_state?.toLowerCase()
          );
          const currentStates = State.getStatesOfCountry(
            editedState?.countryCode
          );
          const stateMapper = addressMapper(currentStates, 'name', 'isoCode');
          setMapPermanentStates(stateMapper);
        }

        if (studentData?.student_address?.[0]?.p_city) {
          const editedCity = addressFinder(
            addressData?.cities,
            studentData?.student_address?.[0]?.p_city?.toLowerCase()
          );
          const currentCities = City.getCitiesOfState(
            editedCity?.countryCode,
            editedCity?.stateCode
          );
          const cityMapper = addressMapper(currentCities, 'name', 'stateCode');
          setMapPermanentCities(cityMapper);
        }

        if (studentData?.student_address?.[0]?.c_state) {
          const editedState = addressFinder(
            addressData?.states,
            studentData?.student_address?.[0]?.c_state?.toLowerCase()
          );
          const currentStates = State.getStatesOfCountry(
            editedState?.countryCode
          );
          const stateMapper = addressMapper(currentStates, 'name', 'isoCode');
          setMapStates(stateMapper);
        }

        if (studentData?.student_address?.[0]?.c_city) {
          const editedCity = addressFinder(
            addressData?.cities,
            studentData?.student_address?.[0]?.c_city?.toLowerCase()
          );
          const currentCities = City.getCitiesOfState(
            editedCity?.countryCode,
            editedCity?.stateCode
          );
          const cityMapper = addressMapper(currentCities, 'name', 'stateCode');
          setMapCities(cityMapper);
        }
      }
    }
  }, [studentData]);

  useEffect(() => {
    if (location?.state?.isEdit) {
      uploadedImage.current.src = studentData?.profile_picture;

      const clusterEditData = studentData?.user_program_mapping?.results?.map(
        (item) => {
          return {
            id: item?.department?.cluster || '',
            label: item?.department?.cluster_name || '',
            value: item?.department?.cluster || 0,
          };
        }
      );
      const departmentEditData =
        studentData?.user_program_mapping?.results?.map((item) => {
          return {
            id: item?.department?.id || '',
            label: item?.department?.department_name || '',
            value: item?.department?.id || 0,
          };
        });
      const courseEditData = studentData?.user_program_mapping?.results?.map(
        (item) => {
          return {
            id: item?.program || '',
            label: item?.program_name || '',
            value: item?.program || '',
          };
        }
      );
      reset({
        first_name: studentData?.first_name,
        middle_name: studentData?.middle_name,
        last_name: studentData?.last_name,
        contact_no: studentData?.contact_no,
        email: studentData?.email,
        abc_id: studentData?.abc_id,
        enrollment_no: studentData?.enrollment_id,
        study_center_code: studentData?.study_center_code,
        uid: studentData?.uid,
        gender: studentData?.gender,
        marital_status: studentData?.marital_status,
        DOB: studentData?.DOB ? studentData?.DOB : '',
        created_by: studentData?.created_by,
        medium: studentData?.medium,
        category: studentData?.category,
        specially_abled: studentData?.specially_abled,
        course_code: studentData?.course_code,
        college_id: studentData?.college_id,
        institute_name: studentData?.institute_name,
        education_detail: studentData?.education_detail,
        id_type: studentData?.id_type,
        id_number: studentData?.id_number,
        father_name: studentData?.father_name,
        father_contact: studentData?.father_contact,
        father_email: studentData?.father_email,
        mother_name: studentData?.mother_name,
        mother_email: studentData?.mother_email,
        mother_contact: studentData?.mother_contact,
        mother_maiden_name: studentData?.mother_maiden_name,
        profile_picture: studentData?.profile_picture,
        program: {
          cluster: clusterEditData?.[0],
          department: departmentEditData?.[0],
          course: courseEditData?.[0],
        },
        student_address: [
          {
            p_locality: studentData?.student_address?.[0]?.p_locality,
            p_city: { label: studentData?.student_address?.[0]?.p_city },

            p_state: { label: studentData?.student_address?.[0]?.p_state },

            p_country: editedPermanentCountry,
            p_pincode: studentData?.student_address?.[0]?.p_pincode,
            is_current_address_same:
              studentData?.student_address?.[0]?.is_current_address_same,

            c_locality: studentData?.student_address?.[0]?.c_locality,
            c_city: { label: studentData?.student_address?.[0]?.c_city },
            c_state: { label: studentData?.student_address?.[0]?.c_state },

            c_country: editedCurrentCountry,
            c_pincode: studentData?.student_address?.[0]?.c_pincode,
          },
        ],
      });
    } else {
      reset(initialValues);
    }
  }, [studentData, editedCurrentCountry, editedPermanentCountry]);

  const onSubmit = (data) => {
    console.log('data:', data);
  };

  const resetValues=()=>{
    reset(initialValues);
  }

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: methods.control,
      name: 'education_detail',
    }
  );

  const studentFormData = watch();

  const addEducationalDetails = () => {
    append({ education: '', School: '', University: '', degree: '' });
  };

  const deleteEducationDetails = (index) => {
    remove(index);
  };

  const resetSearch = useCallback(() => {}, []);

  const watchIsAddressSame = watch(
    'student_address.0.is_current_address_same',
    false
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const studentAddress = value?.student_address?.[0];

      if (
        studentAddress?.is_current_address_same &&
        [
          'is_current_address_same',
          'student_address.0.c_locality',
          'student_address.0.c_city',
          'student_address.0.c_state',
          'student_address.0.c_country',
          'student_address.0.c_pincode',
        ]?.includes(name)
      ) {
        setValue('student_address.0.p_locality', studentAddress.c_locality);
        setValue('student_address.0.p_state', studentAddress.c_state);
        setValue('student_address.0.p_country', studentAddress.c_country);
        setValue('student_address.0.p_pincode', studentAddress.c_pincode);
        setValue('student_address.0.p_city', studentAddress.c_city);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'student_address.0.c_country') {
        setValue('student_address.0.c_state', ' ');
        setValue('student_address.0.c_city', ' ');
        const stateData = State.getStatesOfCountry(
          value?.student_address[0]?.c_country?.value
        );
        const mappedState = addressMapper(stateData, 'name', 'isoCode');
        setMapStates(mappedState);
      }
      if (name === 'student_address.0.c_state') {
        setValue('student_address.0.c_city', ' ');
        const cityData = City.getCitiesOfState(
          value?.student_address[0]?.c_country?.value,
          value?.student_address[0]?.c_state?.value
        );
        const mappedCity = addressMapper(cityData, 'name', 'stateCode');
        setMapCities(mappedCity);
      }

      if (!value?.student_address[0].is_current_address_same) {
        if (name === 'student_address.0.p_country') {
          setValue('student_address.0.p_state', ' ');
          setValue('student_address.0.p_city', ' ');
          const stateData = State.getStatesOfCountry(
            value?.student_address?.[0]?.p_country?.value
          );
          const mappedState = addressMapper(stateData, 'name', 'isoCode');
          setMapPermanentStates(mappedState);
        }
        if (name === 'student_address.0.p_state') {
          setValue('student_address.0.p_city', ' ');
          const cityData = City.getCitiesOfState(
            value?.student_address?.[0]?.p_country?.value,
            value?.student_address?.[0]?.p_state?.value
          );
          const mappedCity = addressMapper(cityData, 'name', 'stateCode');
          setMapPermanentCities(mappedCity);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const courseFieldName = name?.split('.');
      if (courseFieldName?.[1] === 'cluster') {
        dispatch(resetDepartmentbyId());
        dispatch(resetCoursesbyId());
        setValue(`course_information.department`, '');
        setMapDepartments([]);
        setValue(`course_information.course`, '');
        setMapCourses([]);
        setClusterId(value?.course_information?.cluster?.value);
        if (value?.course_information?.cluster?.value) {
          getDepartmentListFunction(
            undefined,
            undefined,
            undefined,
            value?.course_information?.cluster?.value
          );
        }
      }

      if (courseFieldName?.[1] === 'department') {
        dispatch(resetCoursesbyId());
        setDepartmentId(value?.course_information?.department?.value);
        setValue(`course_information.course`, '');
        setMapCourses([]);
        if (value?.course_information?.department?.value) {
          getCoursesListFunction(
            undefined,
            undefined,
            undefined,
            value?.course_information?.department?.value
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <LoaderComponent visible={loading} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item lg={10} md={10}>
              <Grid>
                <Typography variant="body1">
                  {userType === 'Student'
                    ? t('management.studentInfo')
                    : t('management.staffInfo')}
                </Typography>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="first_name"
                    rules={{
                      required: validationRules.REQUIRED,
                      pattern: validationRules.FIRST_NAME,
                    }}
                    variant="outlined"
                    label={t('userProfile.firstName')}
                    size="small"
                    fullWidth
                    isAsterisk
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="middle_name"
                    variant="outlined"
                    label={t('userProfile.middleName')}
                    size="small"
                    fullWidth
                    // isAsterisk
                    // rules={{
                    //   required: validationRules.REQUIRED,
                    //   pattern: validationRules.MIDDLE_NAME,
                    // }}
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="last_name"
                    variant="outlined"
                    label={t('userProfile.lastName')}
                    // required
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="contact_no"
                    rules={{
                      required: validationRules.REQUIRED,
                      pattern: validationRules.PHONE_NUMBER,
                    }}
                    variant="outlined"
                    label={t('userProfile.contact')}
                    size="small"
                    fullWidth
                    isAsterisk
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="email"
                    rules={{
                      required: validationRules.REQUIRED,
                      pattern: validationRules.EMAIL,
                    }}
                    variant="outlined"
                    label={t('userProfile.email')}
                    // required
                    size="small"
                    fullWidth
                    isAsterisk
                    disabled={location?.state?.isEdit}
                  />
                </Grid>
                {(userType === 'Student' || userType === 'Registrar') && (
                  <Grid item md={3} lg={3}>
                    <FormTextField
                      name="study_center_code"
                      variant="outlined"
                      label={t('userProfile.study_center_code')}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}
                {userType === 'Student' && location?.state?.isEdit && (
                  <Grid item xl={3} md={3}>
                    <FormTextField
                      name="uid"
                      variant="outlined"
                      label={t('fees.uid')}
                      size="small"
                      fullWidth
                      disabled={location?.state?.isEdit}
                    />
                  </Grid>
                )}
              </Grid>
              {/* UID generated */}
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item md={3} lg={3}>
                  <FormTextField
                    name="course_code"
                    variant="outlined"
                    label={t('studentManagement.coursecode')}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} lg={3}>
                  <FormTextField
                    name="medium"
                    variant="outlined"
                    label={t('studentManagement.medium')}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} lg={3}>
                  <FormTextField
                    name="college_id"
                    variant="outlined"
                    label={t('studentManagement.collegeId')}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item md={3} lg={3}>
                  <FormTextField
                    name="institute_name"
                    variant="outlined"
                    label={t('studentManagement.instituteName')}
                    size="small"
                    fullWidth
                  />
                </Grid>

                {userType === 'Student' && (
                  <Grid item xl={3} md={3}>
                    <FormTextField
                      name="abc_id"
                      variant="outlined"
                      label={t('studentManagement.abcId')}
                      size="small"
                      fullWidth
                      disabled={location?.state?.isEdit}
                    />
                  </Grid>
                )}
                {(userType === 'Student' || userType === 'Registrar') && (
                  <Grid item md={3} lg={3}>
                    <FormTextField
                      name="enrollment_no"
                      variant="outlined"
                      label={t('userProfile.enrollmentNumber')}
                      size="small"
                      fullWidth
                      // disabled={location?.state?.isEdit}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              ></Grid>
            </Grid>
            <Grid
              xl={2}
              md={2}
              container
              item
              direction="row"
              spacing={2}
              style={{ marginBottom: '7rem' }}
            >
              <Grid
                item
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  {t('userProfile.profilePicture')}
                </Typography>
                <input
                  type="file"
                  accept={profileImageValidation.fileType.join(',')}
                  onChange={handleImageUpload}
                  ref={imageUploader}
                  style={{
                    display: 'none',
                  }}
                />
                <Grid
                  item
                  className="profileImgDiv qa-upload-img"
                  onClick={() => imageUploader.current.click()}
                >
                  <img
                    ref={uploadedImage}
                    className="profileImg"
                    style={{ width: '100%', height: '100%' }}
                  />
                  <Grid item className="imgCaption">
                    <Grid item className="captionText">
                      <Typography variant="caption">
                        {location?.state?.isEdit
                          ? t('userProfile.uploadImage')
                          : t('uploadPicture')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {profileImageError?.length > 0 && (
                  <Grid item>
                    <Typography
                      sx={{ color: '#D82C29' }}
                      variant="caption"
                      mt={2}
                      mb={2}
                      align="center"
                    >
                      {profileImageError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 2 }} />
          <Grid container>
            <Grid item lg={10} md={12}>
              <Grid>
                <Typography variant="body1">
                  {t('userProfile.personalInfo')}
                </Typography>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormSelectField
                    name="gender"
                    variant="outlined"
                    label={t('management.gender')}
                    size="small"
                    fullWidth
                    options={gendersOptions}
                    rules={{
                      required: validationRules.REQUIRED,
                    }}
                    isAsterisk
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormSelectField
                    name="marital_status"
                    variant="outlined"
                    label={t('management.maritalStatus')}
                    size="small"
                    fullWidth
                    options={maritialOptions}
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormDatepicker
                    name={'DOB'}
                    // value={overrideData?.start_date}
                    label={t('management.dob')}
                    className="qa-dob"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormSelectField
                    name="id_type"
                    variant="outlined"
                    label={t('userProfile.selectIdType')}
                    size="small"
                    fullWidth
                    options={idTypeOptions}
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="id_number"
                    variant="outlined"
                    label={t('userProfile.idNumber')}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormSelectField
                    name="category"
                    variant="outlined"
                    label={t('studentManagement.category')}
                    size="small"
                    options={category}
                    fullWidth
                                      />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormSelectField
                    name="specially_abled"
                    variant="outlined"
                    label={t('studentManagement.specially_abled')}
                    size="small"
                    fullWidth
                    options={specially_abled}
                    // disabled={selectedCategory !== 'specially_abled'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 2 }} />
          {userType === 'Student' && (
            <Grid item lg={10} md={12}>
              <Grid>
                <Typography variant="body1">
                  {t('studentManagement.parentsContact')}
                </Typography>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="father_name"
                    variant="outlined"
                    label={t('management.fathersName')}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="father_contact"
                    variant="outlined"
                    label={t('userProfile.contact')}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="father_email"
                    variant="outlined"
                    label={t('userProfile.email')}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* UID generated */}
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="mother_name"
                    variant="outlined"
                    label={t('management.mothersName')}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="mother_contact"
                    variant="outlined"
                    label={t('userProfile.contact')}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="mother_email"
                    variant="outlined"
                    label={t('userProfile.email')}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="mother_maiden_name"
                    variant="outlined"
                    label={"Mother's Maiden Name"}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Divider sx={{ marginY: 2 }} />
          <Grid container>
            <Grid item lg={10} md={12}>
              <Grid>
                <Typography variant="body1">
                  {t('userProfile.educationalDetails')}
                </Typography>
              </Grid>
              {fields.map((item, index) => (
                <>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    display="flex"
                    justifyContent="start"
                    alignItems="start"
                  >
                    <Grid item xl={3} md={3}>
                      <FormSelectField
                        name={`education_detail[${index}].education`}
                        variant="outlined"
                        label={t('userProfile.selectEducation')}
                        size="small"
                        fullWidth
                        options={educationOptions}
                      />
                    </Grid>
                    <Grid item xl={3} md={3}>
                      <FormTextField
                        name={`education_detail[${index}].School`}
                        variant="outlined"
                        label={t('userProfile.schoolCollege')}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    display="flex"
                    justifyContent="start"
                    alignItems="start"
                  >
                    <Grid item xl={3} md={3}>
                      <FormTextField
                        name={`education_detail[${index}].University`}
                        variant="outlined"
                        label={t('userProfile.universityBoard')}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xl={3} md={3}>
                      <FormTextField
                        name={`education_detail[${index}].degree`}
                        variant="outlined"
                        label={t('userProfile.degree')}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    {fields?.length - 1 === index && (
                      <Grid item auto>
                        <div className="mt5" onClick={addEducationalDetails}>
                          <AddIconCircle className="qa-add-edu" />
                        </div>
                      </Grid>
                    )}
                    {fields?.length !== 1 && (
                      <Grid item auto>
                        <div
                          className="mt5"
                          onClick={() => deleteEducationDetails(index)}
                        >
                          <DeleteSvg className="qa-delete-edu" />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 2 }} />
          <Grid container>
            <Grid item lg={10} md={12}>
              <Grid>
                <Typography variant="body1">
                  {t('userProfile.address')}
                </Typography>
                <FormCheckbox
                  name="student_address.0.is_current_address_same"
                  label={t('userProfile.sameAdr')}
                  size="small"
                />
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={6} md={6}>
                  <FormTextField
                    name="student_address.0.c_locality"
                    variant="outlined"
                    label={t('userProfile.street')}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormAutoComplete
                    name="student_address.0.c_country"
                    variant="outlined"
                    label={t('userProfile.country')}
                    size="small"
                    fullWidth
                    className="qa-country"
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ''
                    }
                    options={mapCountries}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormAutoComplete
                    name="student_address.0.c_state"
                    variant="outlined"
                    label={t('userProfile.state')}
                    size="small"
                    fullWidth
                    className="qa-state"
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ''
                    }
                    options={mapStates}
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormAutoComplete
                    name="student_address.0.c_city"
                    variant="outlined"
                    label={t('userProfile.city')}
                    size="small"
                    fullWidth
                    className="qa-city"
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ''
                    }
                    options={mapCities}
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="student_address.0.c_pincode"
                    variant="outlined"
                    label={t('userProfile.pinCode')}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* PERMANENT ADDRESS */}
          <Grid container>
            <Grid item lg={10} md={12}>
              <Grid>
                <Typography variant="body1">
                  {t('userProfile.permAddress')}
                </Typography>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={6} md={6}>
                  <FormTextField
                    name="student_address.0.p_locality"
                    variant="outlined"
                    label={t('userProfile.street')}
                    size="small"
                    fullWidth
                    disabled={watchIsAddressSame}
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormAutoComplete
                    name="student_address.0.p_country"
                    variant="outlined"
                    label={t('userProfile.country')}
                    size="small"
                    fullWidth
                    className="qa-permanent-country"
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ''
                    }
                    options={mapCountries}
                    disabled={watchIsAddressSame}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Grid item xl={3} md={3}>
                  <FormAutoComplete
                    name="student_address.0.p_state"
                    variant="outlined"
                    label={t('userProfile.state')}
                    size="small"
                    fullWidth
                    className="qa-permanent-state"
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ''
                    }
                    options={mapPermanentStates}
                    disabled={watchIsAddressSame}
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormAutoComplete
                    name="student_address.0.p_city"
                    variant="outlined"
                    label={t('userProfile.city')}
                    size="small"
                    fullWidth
                    className="qa-permanent-city"
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ''
                    }
                    options={mapPermanentCities}
                    disabled={watchIsAddressSame}
                  />
                </Grid>
                <Grid item xl={3} md={3}>
                  <FormTextField
                    name="student_address.0.p_pincode"
                    variant="outlined"
                    label={t('userProfile.pinCode')}
                    size="small"
                    fullWidth
                    disabled={watchIsAddressSame}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Divider sx={{ marginY: 2 }} /> */}
          <Grid container>
            <Grid item lg={10} md={12}>
              {/* <Grid>
                <Typography variant="body1">
                  {t('management.courseDetails')}
                </Typography>
              </Grid> */}
              {/* {userType === 'Student' && (
                <>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    display="flex"
                    justifyContent="start"
                    alignItems="start"
                  >
                    <Grid item xl={3} md={3}>
                      <FormAutoComplete
                        name={`course_information.cluster`}
                        variant="outlined"
                        label={t('fees.cluster')}
                        size="small"
                        fullWidth
                        // rules={{
                        //   required: validationRules.REQUIRED,
                        // }}
                        handleFetchNext={getClustersListFunction}
                        options={mapClusters}
                        resetSearch={resetSearch}
                        totalCount={clusters_list?.totalCount}
                        className="qa-cluster"
                      />
                    </Grid>
                    <Grid item xl={3} md={3}>
                      <FormAutoComplete
                        name={`course_information.department`}
                        variant="outlined"
                        label={t('fees.department')}
                        size="small"
                        fullWidth
                        // rules={{
                        //   required: validationRules.REQUIRED,
                        // }}
                        handleFetchNext={getDepartmentListFunction}
                        options={mapDepartments}
                        resetSearch={resetSearch}
                        totalCount={departments_list?.totalCount}
                        className="qa-department"
                      />
                    </Grid>
                    <Grid item xl={3} md={3}>
                      <FormAutoComplete
                        name={`course_information.course`}
                        variant="outlined"
                        label={t('fees.course')}
                        size="small"
                        fullWidth
                        // rules={{
                        //   required: validationRules.REQUIRED,
                        // }}
                        handleFetchNext={getCoursesListFunction}
                        options={mapCourses}
                        resetSearch={resetSearch}
                        totalCount={courses_list?.totalCount}
                        className="qa-course"
                      />
                    </Grid>
                  </Grid>
                </>
              )} */}
            </Grid>
          </Grid>
          {/* <Divider sx={{ marginY: 2 }} /> */}
          {/* {userType === 'Student' && (
          <Grid container>
            <Grid item lg={10} md={12}>
              <Grid item>
                <Typography variant="body1">Other details</Typography>
              </Grid>
              {fields.map((item, index) => (
                <Grid container spacing={2}>
                  <Grid item xl={3} md={3}>
                    <FormSelectField
                      name={`other_details[${index}].label_1`}
                      // rules={{
                      //   required: validationRules.REQUIRED,
                      // }}
                      variant="outlined"
                      label={'Label'}
                      size="small"
                      fullWidth
                      options={[
                        { id: '1', label: 'label 1', key: '1', value: '1' },
                        { id: '2', label: 'label 2', key: '2', value: '2' },
                        { id: '3', label: 'label 3', key: '3', value: '3' },
                      ]}
                    />
                  </Grid>
                  <Grid item xl={3} md={3}>
                    <FormSelectField
                      name={`other_details[${index}].label_2`}
                      // rules={{
                      //   required: validationRules.REQUIRED,
                      // }}
                      variant="outlined"
                      label={'Label'}
                      size="small"
                      fullWidth
                      options={[
                        { id: '1', label: 'label 1', key: '1', value: '1' },
                        { id: '2', label: 'label 2', key: '2', value: '2' },
                        { id: '3', label: 'label 3', key: '3', value: '3' },
                      ]}
                    />
                  </Grid>
                  <Grid item xl={3} md={3}>
                    <FormSelectField
                      name={`other_details[${index}].label_3`}
                      // rules={{
                      //   required: validationRules.REQUIRED,
                      // }}
                      variant="outlined"
                      label={'Label'}
                      size="small"
                      fullWidth
                      options={[
                        { id: '1', label: 'label 1', key: '1', value: '1' },
                        { id: '2', label: 'label 2', key: '2', value: '2' },
                        { id: '3', label: 'label 3', key: '3', value: '3' },
                      ]}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )} */}
          <Divider />
          {/* <Grid container>
            <Grid
              item
              xl={12}
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'end',
                mb: 0.5,
              }}
              offset={9}
            >
              <Button
                variant="contained"
                color="grey"
                sx={{ margin: '1rem' }}
                href={ROUTES.STUDENT_MANAGEMENT.ROOT}
                className="qa-cancel-btn"
              >
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ margin: '1rem' }}
                className="qa-submit-btn"
              >
                {location?.state?.isEdit ? t('update') : t('submit')}
              </Button>
            </Grid>
          </Grid> */}

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ margin: '1rem' }}
            className="qa-submit-btn"
            style={{ display: 'none' }}
            id="firstSubmitBtn"
          />
        </form>
      </FormProvider>
      {/* {(createdStudent?.isSuccess || location?.state?.isEdit) && ( */}
      <StudentProgramDetails
        // studentInfo={student}
        resetValues={resetValues}
        userImage={uploadUserImage}
        studentData={studentFormData}
      />
      {/* )} */}
    </>
  );
};

export default OnboardUserForm;
