import * as React from 'react';

const PersonPin = ({ fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 96 960 960"
    width="24"
    fill={fill}
    {...rest}
  >
    <path d="M480 1016 360 896H200q-33 0-56.5-23.5T120 816V256q0-33 23.5-56.5T200 176h560q33 0 56.5 23.5T840 256v560q0 33-23.5 56.5T760 896H600l-120 120ZM200 770q54-53 125.5-83.5T480 656q83 0 154.5 30.5T760 770V256H200v514Zm280-194q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41Zm0-80q-25 0-42.5-17.5T420 436q0-25 17.5-42.5T480 376q25 0 42.5 17.5T540 436q0 25-17.5 42.5T480 496Zm0 408 88-88h112v-10q-42-35-93-52.5T480 736q-56 0-107 17.5T280 806v10h112l88 88Zm0-391Z" />
  </svg>
);

export default PersonPin;
