export const attendanceAPI = {
  markAttendance: '/v2/attendance/mark-attendance',
  markBulkAttendance: '/v2/attendance/bulk-attendance',
  sessionAttendance: '/v1/attendance/session-attendance',
  sessionsList: '/v2/attendance/session',
  sessionsMeetList: '/v2/attendance/session/session_list',
  getFacultyProgramList: '/v2/user-mapping',
  downloadProgramAttendance: '/v1/attendance/download-pgm-attendance',
  getFacultyStudentAttendance: 'v2/attendance/stud-attendance',
  getFilterLiveSession: '/v2/time-table',
  sendAttendanceStudentNotification: '/v2/attendance/student_notifications',
  getUserEmails: '/v2/attendance/student_notifications/user_emails',
  getAttendanceDetailsDashboardCard: '/v2/attendance/attendance-count',

  attendanceApi: '/v2/request_management',

  //ordinance-setting
  ordinanceSetting: '/v2/ordinance',

  // download API endpoint
  downloadAllRequest: '/v2/request_management/download_request',
};
