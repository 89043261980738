import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store/store';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { delay, localStorageHelper } from 'utils';
import axiosInstance from 'translator';
import { userManagementAPI, getCandidateNewAccessTokenApi } from 'store';
import { setToastMessage } from 'store/Toast';
import { statusCode } from 'constants/apis/apiStatus';
import { User } from 'data/roles';
import './index.scss';

const { store, persistor } = configureStore();
const userType = localStorageHelper.get('loginType');
let countLogout = false;
let count = 0;

const fetchRefreshToken = () => {
  if (userType === User.otpUser) {
    return axiosInstance.post(userManagementAPI.refreshToken, {
      refresh: localStorageHelper.get('access_token'),
    });
  }
};

const logoutFunc = () => {
  if (localStorageHelper.get('loginType') === User.otpUser) {
    localStorageHelper.clear();
    sessionStorage.clear();
    window?.location?.replace('/candidate/login');
  }
};

const logOutHandler = (errorData) => {
  countLogout = true;
  //for displaying error msg
  if (
    errorData?.code === 'token_not_valid' ||
    errorData?.message === 'Token is invalid or expired'
  ) {
    store.dispatch(
      setToastMessage({
        data: 'Session has expired. Please login again.',
        severity: 'error',
      })
    );
  } else {
    store.dispatch(
      setToastMessage({
        data: errorData?.message ? errorData?.message : errorData?.detail,
        severity: 'error',
      })
    );
  }

  setTimeout(() => {
    logoutFunc();
  }, 1000);
};

const logoutOnSessionExpired = (errorData) => {
  //for displaying error msg
  setTimeout(() => {
    if (!JSON.parse(localStorageHelper.get('isTokenRefreshed'))) {
      countLogout = true;
      store.dispatch(
        setToastMessage({
          data: 'Session has expired. Please login again.',
          severity: 'error',
        })
      );
      logoutFunc();
    }
  }, 1000);
};

const resetRefreshedTokens = () => {
  localStorageHelper.set('isTokenRefreshed', true);
  setTimeout(() => {
    localStorageHelper.set('isTokenRefreshed', false);
    count = 0;
  }, 20000);
};

const getNewAccessToken = async () => {
  return getCandidateNewAccessTokenApi({
    refresh: localStorageHelper.get('refresh_token'),
  });
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const status = error?.status || error?.response?.status;
    const errorData = error?.response?.data || error?.data;
    const requestData = error?.config || {};

    // Code for generic error handling
    if (
      status === statusCode.Unauthorized401 &&
      (errorData?.code === 'token_not_valid' ||
        errorData?.detail === 'Token is invalid or expired' ||
        errorData?.message === 'Token is expired')
    ) {
      // maintain a flag to call logout api only once
      if (!countLogout) {
        //if session is expired then making logout api call
        logOutHandler(errorData);
      } else {
        return;
      }
    }

    //Code for checking invalid access token
    if (errorData?.message === 'Token is expired') {
      requestData._retry = true;
      try {
        if (count === 0) {
          count = count + 1;
          if (localStorageHelper.get('loginType') === User.otpUser) {
            // fetch new access token for OTP_USER user
            let tokenResponse = await getNewAccessToken();
            // Set new tokens for OTP_USER
            if (tokenResponse && tokenResponse?.data?.access) {
              resetRefreshedTokens();
              localStorageHelper.set(
                'access_token',
                tokenResponse?.data?.access
              );
            } else {
              logoutOnSessionExpired(tokenResponse);
            }

            // return failed request with new tokens
            return axiosInstance.request(requestData);
          } else {
            // TODO: Handle error condition in refresh token fail
            store.dispatch(
              setToastMessage({
                data: 'Something went wrong. Please try after sometime.',
                severity: 'error',
              })
            );
          }
        } else {
          await delay(3000);
          return axiosInstance.request(requestData);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error?.response);
  }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
