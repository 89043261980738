import React from 'react';

const CheckTickIcon = ({
  width = '48',
  height = '48',
  viewBox = '0 -960 960 960',
  fill,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}
    >
      <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
    </svg>
  );
};

export default CheckTickIcon;
