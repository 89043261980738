export const MoocAdminActions = {
  MOOC_ADMIN_DASHBOARD_LOADER: 'MOOC_ADMIN_DASHBOARD_LOADER',
  GET_MOOC_ADMIN_STATS: 'GET_MOOC_ADMIN_STATS',
  SET_MOOC_ADMIN_STATS_SUCCESS: 'SET_MOOC_ADMIN_STATS_SUCCESS',
  SET_MOOC_ADMIN_STATS_ERROR: 'SET_MOOC_ADMIN_STATS_ERROR',
  RESET_MOOC_ADMIN_STATS: 'RESET_MOOC_ADMIN_STATS',

  GET_AFFILIATED_COURSES: 'GET_AFFILIATED_COURSES',
  SET_AFFILIATED_COURSES_SUCCESS: 'SET_AFFILIATED_COURSES_SUCCESS',
  SET_AFFILIATED_COURSES_ERROR: 'SET_AFFILIATED_COURSES_ERROR',

  MOOC_ADMIN_COURSES_FILTER_LOADER: 'MOOC_ADMIN_COURSES_FILTER_LOADER',

  MOOC_ADMIN_COURSES_LIST_LOADER: 'MOOC_ADMIN_COURSES_LIST_LOADER',
  GET_COURSES_TO_AFFILIATE: 'GET_COURSES_TO_AFFILIATE',
  SET_COURSES_TO_AFFILIATE_SUCCESS: 'SET_COURSES_TO_AFFILIATE_SUCCESS',
  SET_COURSES_TO_AFFILIATE_ERROR: 'SET_COURSES_TO_AFFILIATE_ERROR',
  RESET_COURSES_LIST: 'RESET_COURSES_LIST',

  GET_COURSES_TO_PUBLISH: 'GET_COURSES_TO_PUBLISH',
  SET_COURSES_TO_PUBLISH_SUCCESS: 'SET_COURSES_TO_PUBLISH_SUCCESS',
  SET_COURSES_TO_PUBLISH_ERROR: 'SET_COURSES_TO_PUBLISH_ERROR',

  GET_PUBLISHED_COURSES: 'GET_PUBLISHED_COURSES',
  SET_PUBLISHED_COURSES_SUCCESS: 'SET_PUBLISHED_COURSES_SUCCESS',
  SET_PUBLISHED_COURSES_ERROR: 'SET_PUBLISHED_COURSES_ERROR',

  MOOC_ADMIN_COURSE_DETAILS_LOADER: 'MOOC_ADMIN_COURSE_DETAILS_LOADER',
  GET_COURSE_DETAILS: 'GET_COURSE_DETAILS',
  SET_COURSE_DETAILS_SUCCESS: 'SET_COURSE_DETAILS_SUCCESS',
  SET_COURSE_DETAILS_ERROR: 'SET_COURSE_DETAILS_ERROR',
  RESET_COURSE_DETAILS: 'RESET_COURSE_DETAILS',

  AFFILIATE_PUBLISH_MOOC_COURSE_LOADER: 'AFFILIATE_PUBLISH_MOOC_COURSE_LOADER',
  AFFILIATE_MOOC_COURSE: 'AFFILIATE_MOOC_COURSE',
  AFFILIATE_MOOC_COURSE_SUCCESS: 'AFFILIATE_MOOC_COURSE_SUCCESS',
  AFFILIATE_MOOC_COURSE_ERROR: 'AFFILIATE_MOOC_COURSE_ERROR',
  RESET_AFFILIATE_MOOC_COURSE: 'RESET_AFFILIATE_MOOC_COURSE',

  PUBLISH_MOOC_COURSE: 'PUBLISH_MOOC_COURSE',
  PUBLISH_MOOC_COURSE_SUCCESS: 'PUBLISH_MOOC_COURSE_SUCCESS',
  PUBLISH_MOOC_COURSE_ERROR: 'PUBLISH_MOOC_COURSE_ERROR',
  RESET_PUBLISH_MOOC_COURSE: 'RESET_PUBLISH_MOOC_COURSE',

  GET_MOOC_ADMIN_FILTER: 'GET_MOOC_ADMIN_FILTER',
  SET_MOOC_ADMIN_FILTER_SUCCESS: 'SET_MOOC_ADMIN_FILTER_SUCCESS',
  SET_MOOC_ADMIN_FILTER_ERROR: 'SET_MOOC_ADMIN_FILTER_ERROR',
  RESET_MOOC_ADMIN_FILTER: 'RESET_MOOC_ADMIN_FILTER',

  GET_MOOC_TYPE: 'GET_MOOC_TYPE',
  SET_MOOC_TYPE_SUCCESS: 'SET_MOOC_TYPE_SUCCESS',
  SET_MOOC_TYPE_ERROR: 'SET_MOOC_TYPE_ERROR',

  ENROLL_MOOC_COURSE: 'ENROLL_MOOC_COURSE',
  SET_ENROLL_MOOC_COURSE_SUCCESS: 'ENROLL_MOOC_COURSE_SUCCESS',
  SET_ENROLL_MOOC_COURSE_ERROR: 'ENROLL_MOOC_COURSE_ERROR',
  RESET_ENROLL_MOOC_COURSE: 'RESET_ENROLL_MOOC_COURSE',

  //Get All Courses
  GET_All_COURSES: 'GET_All_COURSES',
  SET_GET_All_COURSES_SUCCESS: 'SET_GET_All_COURSES_SUCCESS',
  SET_GET_All_COURSES_ERROR: 'SET_GET_All_COURSES_ERROR',
  SET_COURSES_LOADER: 'SET_COURSES_LOADER',

  //Update Course Details
  UPDATE_COURSE_DETAILS: 'UPDATE_COURSE_DETAILS',
  UPDATE_COURSE_DETAILS_SUCCESS: 'UPDATE_COURSE_DETAILS_SUCCESS',
  UPDATE_COURSE_DETAILS_ERROR: 'UPDATE_COURSE_DETAILS_ERROR',

  //Delete Course Details
  DELETE_COURSE_DETAILS: 'DELETE_COURSE_DETAILS',
  DELETE_COURSE_DETAILS_SUCCESS: 'DELETE_COURSE_DETAILS_SUCCESS',
  DELETE_COURSE_DETAILS_ERROR: 'DELETE_COURSE_DETAILS_ERROR',
  //Update Mooc Setting
  UPDATE_MOOC_SETTING: 'UPDATE_MOOC_SETTING',
  UPDATE_MOOC_SETTING_SUCCESS: 'UPDATE_MOOC_SETTING_SUCCESS',
  UPDATE_MOOC_SETTING_ERROR: 'UPDATE_MOOC_SETTING_ERROR',
  RESET_UPDATE_MOOC_SETTING: 'RESET_UPDATE_MOOC_SETTING',

  //Get Mooc Setting
  GET_MOOC_SETTING: 'GET_MOOC_SETTING',
  SET_MOOC_SETTING_SUCCESS: 'SET_MOOC_SETTING_SUCCESS',
  SET_MOOC_SETTING_ERROR: 'SET_MOOC_SETTING_ERROR',

  //Create certificate
  CREATE_MOOC_COURSE_CERTIFICATE: 'CREATE_MOOC_COURSE_CERTIFICATE',
  CREATE_MOOC_COURSE_CERTIFICATE_SUCCESS:
    'CREATE_MOOC_COURSE_CERTIFICATE_SUCCESS',
  CREATE_MOOC_COURSE_CERTIFICATE_ERROR: 'CREATE_MOOC_COURSE_CERTIFICATE_ERROR',
  RESET_CREATE_MOOC_COURSE_CERTIFICATE: 'RESET_CREATE_MOOC_COURSE_CERTIFICATE',

  //Upload File for Mooc setting
  UPLOAD_FILE: 'CERTIFICATE_UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'CERTIFICATE_UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'CERTIFICATE_UPLOAD_FILE_ERROR',

  //Upload File for Mooc setting
  UPLOAD_FILE_SCHEMA: 'UPLOAD_FILE_SCHEMA',
  UPLOAD_FILE_SCHEMA_SUCCESS: 'UPLOAD_FILE_SCHEMA_SUCCESS',
  UPLOAD_FILE_SCHEMA_ERROR: 'UPLOAD_FILE_SCHEMA_ERROR',

  //Enrolled Courses
  GET_ENROLLED_COURSES: 'GET_ENROLLED_COURSES',
  SET_ENROLLED_COURSES_SUCCESS: 'SET_ENROLLED_COURSES_SUCCESS',
  SET_ENROLLED_COURSES_ERROR: 'SET_ENROLLED_COURSES_ERROR',
  SET_ENROLLED_COURSES_LOADER: 'SET_ENROLLED_COURSES_LOADER',
  RESET_ENROLLED_COURSES: 'RESET_ENROLLED_COURSES',

  UPDATE_COURSE_STATUS: 'UPDATE_COURSE_STATUS',
  UPDATE_COURSE_STATUS_SUCCESS: 'UPDATE_COURSE_STATUS_SUCCESS',
  UPDATE_COURSE_STATUS_ERROR: 'UPDATE_COURSE_STATUS_ERROR',
  RESET_UPDATE_COURSE_STATUS: 'RESET_UPDATE_COURSE_STATUS',

  GET_MOOC_COURSE_STATUS: 'GET_MOOC_COURSE_STATUS',
  SET_MOOC_COURSE_STATUS_SUCCESS: 'SET_MOOC_COURSE_STATUS_SUCCESS',
  SET_MOOC_COURSE_STATUS_ERROR: 'SET_MOOC_COURSE_STATUS_ERROR',
  RESET_MOOC_COURSE_STATUS: 'RESET_MOOC_COURSE_STATUS',

  MOOC_ADMIN_DASHBOARD: 'MOOC_ADMIN_DASHBOARD',
  SET_MOOC_ADMIN_DASHBOARD_SUCCESS: 'SET_MOOC_ADMIN_DASHBOARD_SUCCESS',
  SET_MOOC_ADMIN_DASHBOARD_ERROR: 'SET_MOOC_ADMIN_DASHBOARD_SUCCESS',
  SET_MOOC_ADMIN_DASHBOARD_LOADING: 'SET_MOOC_ADMIN_DASHBOARD_LOADING',

  GET_STUDENT_LIST_BY_COURSE_ID: 'GET_STUDENT_LIST_BY_COURSE_ID',
  SET_STUDENT_LIST_BY_COURSE_ID_SUCCESS:
    'SET_STUDENT_LIST_BY_COURSE_ID_SUCCESS',
  SET_STUDENT_LIST_BY_COURSE_ID_ERROR: 'SET_STUDENT_LIST_BY_COURSE_ID_ERROR',
  RESET_STUDENT_LIST_BY_COURSE_ID: 'RESET_STUDENT_LIST_BY_COURSE_ID',

  SELECTED_COURSE_FOR_STUDENTS_LIST: 'SELECTED_COURSE_FOR_STUDENTS_LIST',
  RESET_SELECTED_COURSE_FOR_STUDENTS_LIST:
    'RESET_SELECTED_COURSE_FOR_STUDENTS_LIST',

  GET_ASSESSMENT_LINK_REQUEST: 'GET_ASSESSMENT_LINK_REQUEST',
  GET_ASSESSMENT_LINK_REQUEST_SUCCESS: 'GET_ASSESSMENT_LINK_REQUEST_SUCCESS',
  GET_ASSESSMENT_LINK_REQUEST_ERROR: 'GET_ASSESSMENT_LINK_REQUEST_ERROR',
  RESET_ASSESSMENT_LINK_REQUEST: 'RESET_ASSESSMENT_LINK_REQUEST',

  GET_VIDEO_NOTES_REQUEST: 'GET_VIDEO_NOTES_REQUEST',
  GET_VIDEO_NOTES_REQUEST_SUCCESS: 'GET_VIDEO_NOTES_REQUEST_SUCCESS',
  GET_VIDEO_NOTES_REQUEST_ERROR: 'GET_VIDEO_NOTES_REQUEST_ERROR',
  RESET_VIDEO_NOTES: 'RESET_VIDEO_NOTES',

  CREATE_VIDEO_NOTES_REQUEST: 'CREATE_VIDEO_NOTES_REQUEST',
  CREATE_VIDEO_NOTES_REQUEST_SUCCESS: 'CREATE_VIDEO_NOTES_REQUEST_SUCCESS',
  CREATE_VIDEO_NOTES_REQUEST_ERROR: 'CREATE_VIDEO_NOTES_REQUEST_ERROR',

  DOWNLOAD_CERTIFICATE_REQUEST: 'DOWNLOAD_CERTIFICATE_REQUEST',
  DOWNLOAD_CERTIFICATE_REQUEST_SUCCESS: 'DOWNLOAD_CERTIFICATE_REQUEST_SUCCESS',
  DOWNLOAD_CERTIFICATE_REQUEST_ERROR: 'DOWNLOAD_CERTIFICATE_REQUEST_ERROR',
  RESET_DOWNLOAD_CERTIFICATE_REQUEST: 'RESET_DOWNLOAD_CERTIFICATE_REQUEST',

  LEARNING_PATH_TO_PUBLISH: 'LEARNINF_PATH_TO_PUBLISH',
  LEARNING_PATH_TO_PUBLISH_SUCCESS: 'LEARNING_PATH_TO_PUBLISH_SUCCESS',
  LEARNING_PATH_TO_PUBLISH_ERROR: 'LEARNING_PATH_TO_PUBLISH_ERROR',
  RESET_LEARNING_PATH_TO_PUBLISH: 'LEARNING_PATH_TO_PUBLISH_SUCCESS',

  PUBLISHED_LEARNING_PATH: 'PUBLISHED_LEARNING_PATH',
  PUBLISHED_LEARNING_PATH_SUCCESS: 'PUBLISHED_LEARNING_PATH_SUCCESS',
  PUBLISHED_LEARNING_PATH_ERROR: 'PUBLISHED_LEARNING_PATH_ERROR',
  RESET_PUBLISHED_LEARNING_PATH: 'RESET_PUBLISHED_LEARNING_PATH',

  //Get learning path
  GET_LEARNING_PATH: 'GET_LEARNING_PATH',
  SET_GET_LEARNING_PATH_SUCCESS: 'SET_GET_LEARNING_PATH_SUCCESS',
  SET_GET_LEARNING_PATH_ERROR: 'SET_GET_LEARNING_PATH_ERROR',
  SET_LEARNING_PATH_LOADER: 'SET_LEARNING_PATH_LOADER',
  RESET_LEARNING_PATH: 'RESET_LEARNING_PATH',

  GET_COURSE_LIST_DROPDOWN: 'GET_COURSE_LIST_DROPDOWN',
  GET_COURSE_LIST_DROPDOWN_SUCCESS: 'GET_COURSE_LIST_DROPDOWN_SUCCESS',
  GET_COURSE_LIST_DROPDOWN_ERROR: 'GET_COURSE_LIST_DROPDOWN_ERROR',

  CREATE_LEARNING_PATH: 'CREATE_LEARNING_PATH',
  CREATE_LEARNING_PATH_SUCCESS: 'CREATE_LEARNING_PATH_SUCCESS',
  CREATE_LEARNING_PATH_ERROR: 'CREATE_LEARNING_PATH_ERROR',
  CREATE_LEARNING_PATH_RESET: 'CREATE_LEARNING_PATH_RESET',

  //Update LP Status
  UPDATE_LEARNING_PATH: 'UPDATE_LEARNING_PATH',
  SET_UPDATE_LEARNING_PATH_SUCCESS: 'SET_UPDATE_LEARNING_PATH_SUCCESS',
  SET_UPDATE_LEARNING_PATH_ERROR: 'SET_UPDATE_LEARNING_PATH_ERROR',
  SET_UPDATE_LEARNING_PATH_LOADER: 'SET_UPDATE_LEARNING_PATH_LOADER',

  GET_MY_LEARNING_PATH: 'GET_MY_LEARNING_PATH',
  SET_MY_LEARNING_PATH_SUCCESS: 'SET_MY_LEARNING_PATH_SUCCESS',
  SET_MY_LEARNING_PATH_ERROR: 'SET_MY_LEARNING_PATH_ERROR',
  RESET_MY_LEARNING_PATH: 'RESET_MY_LEARNING_PATH',

  ENROLL_LEARNING_PATH: 'ENROLL_LEARNING_PATH',
  ENROLL_LEARNING_PATH_SUCCESS: 'ENROLL_LEARNING_PATH_SUCCESS',
  ENROLL_LEARNING_PATH_ERROR: 'ENROLL_LEARNING_PATH_ERROR',
  RESET_ENROLL_LEARNING_PATH: 'RESET_ENROLL_LEARNING_PATH',

  GET_COURSE_LIST_BY_LP_ID: 'GET_COURSE_LIST_BY_LP_ID',
  GET_COURSE_LIST_BY_LP_ID_SUCCESS: 'GET_COURSE_LIST_BY_LP_ID_SUCCESS',
  GET_COURSE_LIST_BY_LP_ID_ERROR: 'GET_COURSE_LIST_BY_LP_ID_ERROR',
  RESET_COURSE_LIST_BY_LP_ID: 'RESET_COURSE_LIST_BY_LP_ID',

  GET_LP_COURSE_STATUS: 'GET_LP_COURSE_STATUS',
  SET_LP_COURSE_STATUS_SUCCESS: 'SET_LP_COURSE_STATUS_SUCCESS',
  SET_LP_COURSE_STATUS_ERROR: 'SET_LP_COURSE_STATUS_ERROR',
  RESET_LP_COURSE_STATUS: 'RESET_LP_COURSE_STATUS',

  GET_LP_BY_ID: 'GET_LP_BY_ID',
  SET_LP_BY_ID_SUCCESS: 'SET_LP_BY_ID_SUCCESS',
  SET_LP_BY_ID_ERROR: ' SET_LP_BY_ID_ERROR',
  RESET_LP_BY_ID: 'RESET_LP_BY_ID',

  EDIT_LEARNING_PATH: 'EDIT_LEARNING_PATH',
  EDIT_LEARNING_PATH_SUCCESS: 'EDIT_LEARNING_PATH_SUCCESS',
  EDIT_LEARNING_PATH_ERROR: 'EDIT_LEARNING_PATH_ERROR',
  RESET_EDIT_LEARNING_PATH: 'RESET_EDIT_LEARNING_PATH',
};
