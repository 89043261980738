export const evaluationActions = {
  SHOW_LOADING: {
    display_loading: 'DISPLAY_LOADING',
  },
  SUBJECT: {
    get_subject_list: 'GET_INTERNAL_EVAL_SUBJECT_LIST',
    get_subject_list_success: 'GET_INTERNAL_EVAL_SUBJECT_LIST_SUCCESS',
    get_subject_list_error: 'GET_INTERNAL_EVAL_SUBJECT_LIST_ERROR',
    reset_get_subject_list: 'RESET_INTERNAL_EVAL_GET_SUBJECT_LIST',
  },
  INTERNAL_EVALUATIONS_LIST: {
    get_internal_evaluation_list: 'GET_INTERNAL_EVALUATION_LIST',
    get_internal_evaluation_list_success:
      'GET_INTERNAL_EVALUATION_LIST_SUCCESS',
    get_internal_evaluation_list_error: 'GET_INTERNAL_EVALUATION_LIST_ERROR',
  },

  INTERNAL_EVALUATIONS_EXPORT: {
    get_internal_evaluation_export: 'GET_INTERNAL_EVALUATION_EXPORT',
    get_internal_evaluation_export_success:
      'GET_INTERNAL_EVALUATION_EXPORT_SUCCESS',
    get_internal_evaluation_export_error:
      'GET_INTERNAL_EVALUATION_EXPORT_ERROR',
  },
  INTERNAL_STUDENT_EVAL_LIST: {
    get_internal_student_eval_list: 'GET_INTERNAL_STUDENT_EVAL_LIST',
    get_internal_student_eval_list_success:
      'GET_INTERNAL_STUDENT_EVAL_LIST_SUCCESS',
    get_internal_student_eval_list_error:
      'GET_INTERNAL_STUDENT_EVAL_LIST_ERROR',
  },
  INTERNAL_EVALUATION_STUDENTS_LIST: {
    get_internal_evaluation_students__list:
      'GET_INTERNAL_EVALUATION_STUDENTS_LIST',
    internal_evaluation_students__list_success:
      'GET_INTERNAL_EVALUATION_STUDENTS_LIST_SUCCESS',
    internal_evaluation_students__list_error:
      'GET_INTERNAL_EVALUATION_STUDENTS_LIST_ERROR',
    reset_internal_evaluation_students__list:
      'RESET_INTERNAL_EVALUATION_STUDENTS_LIST',
    get_internal_evaluation_students__list_loader:
      'GET_INTERNAL_EVALUATION_STUDENTS_LIST_LOADER',
  },
  SUBMIT_INTERNAL_EVAL_MARKS: {
    submit_internal_eval_marks: 'SUBMIT_INTERNAL_EVAL_MARKS',
    submit_internal_eval_marks_success: 'SUBMIT_INTERNAL_EVAL_MARKS_SUCCESS',
    submit_internal_eval_marks_error: 'SUBMIT_INTERNAL_EVAL_MARKS_ERROR',
  },

  SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS: {
    submit_internal_eval_assignment_marks:
      'SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS',
    submit_internal_eval_assignment_marks_success:
      'SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS_SUCCESS',
    submit_internal_eval_assignment_marks_error:
      'SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS_ERROR',
    reset_submit_internal_eval_assignment_marks:
      'RESET_SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS',
    submit_internal_eval_assignment_marks_loader:
      'SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS_LOADER',
  },

  STUDENT_EXAM_AND_RESULTS: {
    get_mid_end_term_exam_list: 'GET_MID_END_TERM_EXAM_LIST',
    get_mid_end_term_exam_list_success: 'GET_MID_END_TERM_EXAM_LIST_SUCCESS',
    get_exam_subject_list: 'GET_EXAM_SUBJECT_LIST',
    get_exam_subject_list_success: 'GET_EXAM_SUBJECT_LIST_SUCCESS',

    loading: 'SET_STUDENT_EXAM_AND_RESULTS_LOADING',
    get_quiz: 'GET_STUDENT_QUIZ',
    get_quiz_success: 'GET_STUDENT_QUIZ_SUCCESS',
    submit_student_quiz: 'SUBMIT_STUDENT_QUIZ',
    submit_student_quiz_success: 'SUBMIT_STUDENT_QUIZ_SUCCESS',

    get_assignment: 'GET_STUDENT_ASSIGNMENT',
    get_assignment_success: 'GET_STUDENT_ASSIGNMENT_SUCCESS',
    submit_student_assignment: 'SUBMIT_STUDENT_ASSIGNMENT',
    submit_student_assignment_success: 'SUBMIT_STUDENT_ASSIGNMENT_SUCCESS',

    get_uploaded_assignment: 'GET_UPLOADED_ASSIGNMENT',
    get_uploaded_assignment_success: 'GET_UPLOADED_ASSIGNMENT_SUCCESS',
    submit_uploaded_assignment: 'SUBMIT_UPLOADED_ASSIGNMENT',
    submit_uploaded_assignment_success: 'SUBMIT_UPLOADED_ASSIGNMENT_SUCCESS',

    recheck_request: 'RECHECK_REQUEST',
    recheck_request_success: 'RECHECK_REQUEST_SUCCESS',
    GET_EXAM_STUDENTS_REQUEST: 'GET_EXAM_STUDENTS_REQUEST',
    GET_EXAM_STUDENTS_SUCCESS: 'GET_EXAM_STUDENTS_SUCCESS',
    GET_EXAM_STUDENTS_ERROR: 'GET_EXAM_STUDENTS_ERROR',
    GET_EXAM_STUDENTS_RESET: 'GET_EXAM_STUDENTS_RESET',

    UPDATE_EXAM_STUDENTS_EVALUATION_REQUEST:
      'UPDATE_EXAM_STUDENTS_EVALUATION_REQUEST',
    UPDATE_EXAM_STUDENTS_EVALUATION_SUCCESS:
      'UPDATE_EXAM_STUDENTS_EVALUATION_SUCCESS',
    UPDATE_EXAM_STUDENTS_EVALUATION_ERROR:
      'UPDATE_EXAM_STUDENTS_EVALUATION_ERROR',
    UPDATE_EXAM_STUDENTS_EVALUATION_RESET:
      'UPDATE_EXAM_STUDENTS_EVALUATION_RESET',

    GET_STUDENT_EVALUATION_DETAILS_REQUEST:
      'GET_STUDENT_EVALUATION_DETAILS_REQUEST',
    GET_STUDENT_EVALUATION_DETAILS_SUCCESS:
      'GET_STUDENT_EVALUATION_DETAILS_SUCCESS',
    GET_STUDENT_EVALUATION_DETAILS_ERROR:
      'GET_STUDENT_EVALUATION_DETAILS_ERROR',
    GET_STUDENT_EVALUATION_DETAILS_RESET:
      'GET_STUDENT_EVALUATION_DETAILS_RESET',

    UPDATE_EXAM_ASSIGNMENT_EVALUATION_REQUEST:
      'UPDATE_EXAM_ASSIGNMENT_EVALUATION_REQUEST',
    UPDATE_EXAM_ASSIGNMENT_EVALUATION_SUCCESS:
      'UPDATE_EXAM_ASSIGNMENT_EVALUATION_SUCCESS',
    UPDATE_EXAM_ASSIGNMENT_EVALUATION_ERROR:
      'UPDATE_EXAM_ASSIGNMENT_EVALUATION_ERROR',
    UPDATE_EXAM_ASSIGNMENT_EVALUATION_RESET:
      'UPDATE_EXAM_ASSIGNMENT_EVALUATION_RESET',

    get_student_exams_list_action: 'GET_STUDENT_EXAMS_LIST_REQUEST',
    get_student_exams_list_success: 'GET_STUDENT_EXAMS_LIST_SUCCESS',
    get_student_exams_list_error: 'GET_STUDENT_EXAMS_LIST_ERROR',
  },
  INTERNAL_STUDENT_EXAM_LIST: {
    get_internal_student_exam_list: 'GET_INTERNAL_STUDENT_EXAM_LIST',
    get_internal_student_exam_list_success:
      'GET_INTERNAL_STUDENT_EXAM_LIST_SUCCESS',
    get_internal_student_exam_list_error:
      'GET_INTERNAL_STUDENT_EXAM_LIST_ERROR',
  },

  GET_USER_MAPPED_SUBJECTS_ACTION: 'GET_USER_MAPPED_SUBJECTS_ACTION',
  GET_USER_MAPPED_PROGRAMS_SUCCESS: 'GET_USER_MAPPED_PROGRAMS_SUCCESS',
  GET_USER_MAPPED_YEAR_SUCCESS: 'GET_USER_MAPPED_YEAR_SUCCESS',
  GET_USER_MAPPED_SEMESTER_SUCCESS: 'GET_USER_MAPPED_SEMESTER_SUCCESS',
  GET_USER_MAPPED_SUBJECTS_SUCCESS: 'GET_USER_MAPPED_SUBJECTS_SUCCESS',
  GET_USER_MAPPED_SUBJECTS_ERROR: 'GET_USER_MAPPED_SUBJECTS_ERROR',
  RESET_USER_MAPPED_SUBJECTS: 'RESET_USER_MAPPED_SUBJECTS',

  GET_USER_MAPPED_SUBJECTS_LOADER: 'GET_USER_MAPPED_SUBJECTS_LOADER',

  GET_STUDENT_MAPPED_SUBJECTS_ACTION: 'GET_STUDENT_MAPPED_SUBJECTS_ACTION',
  GET_STUDENT_MAPPED_PROGRAMS_SUCCESS: 'GET_STUDENT_MAPPED_PROGRAMS_SUCCESS',
  GET_STUDENT_MAPPED_YEAR_SUCCESS: 'GET_STUDENT_MAPPED_YEAR_SUCCESS',
  GET_STUDENT_MAPPED_SEMESTER_SUCCESS: 'GET_STUDENT_MAPPED_SEMESTER_SUCCESS',
  GET_STUDENT_MAPPED_SUBJECTS_SUCCESS: 'GET_STUDENT_MAPPED_SUBJECTS_SUCCESS',
  GET_STUDENT_MAPPED_SUBJECTS_ERROR: 'GET_STUDENT_MAPPED_SUBJECTS_ERROR',
  RESET_STUDENT_MAPPED_SUBJECTS: 'RESET_STUDENT_MAPPED_SUBJECTS',
};
