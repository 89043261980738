export const userManagementAPI = {
  getUserRolePermissions: 'v1/rbac/role',
  getUserDetails: 'v1/user_management/staff',
  getStudentDetails: 'v1/user_management/student',
  getExternalAuthToken: '/api/TokenAuth/GetExternalAuthToken',
  getAllRoles: 'v1/rbac/role',
  getAllUserDetails: 'v1/user_management/student',
  getDeptUserDetails: '/api/userManagement/UserManage/GetDepartmentUserDetails',
  createUser: 'v1/user_management/staff',
  getProgramStudentDetails: '/v2/user-management',
  updateUser: 'v1/user_management/staff',
  createStudent: 'v1/user_management/student',
  updateStudent: 'v1/user_management/student',
  uploadUserImage: 'v1/user_management/user/upload-image',
  getFilteredUserList: 'api/userManagement/UserManage/GetFilteredUsers',
  getDesignationList: 'v1/rbac/role',
  deleteUser: 'v1/user_management/staff',
  getClusters: '/api/courseManagement/Cluster/GetList',
  getDepartmentsById:
    '/api/courseManagement/Department/GetDepartmentByClusterId',
  getCoursesById: '/api/courseManagement/Course/GetCourseByDeptId',
  userUploadValidation: 'v1/user_management/user/upload_users',
  uploadUser: 'v1/user_management/user/upload_users',
  localUserLogin: `${process.env.REACT_APP_AUTH_URL}/api/TokenAuth/Authenticate`,
  localUserLogout: `${process.env.REACT_APP_AUTH_URL}/api/services/platform/UserLogout/UserLogout`,

  // get tenant specific and sponsor logo's - public end point
  get_public_tenant_images: '/v1/tenant',
  update_candidate_to_student:
    'v1/admission/lead-stage?type_info=registrar-onboarded',
  lead_onboarded: 'v1/admission/registrar-candidate?type_info=onboarded',
  lead_onboarding_pending: 'v1/admission/registrar-candidate?type_info=new',

  candidateSendOtp: '/v1/admission/send_otp',
  candidateVerifyOtp: '/v1/admission/verify_otp',
  refreshToken: '/v1/admission/jwt-token/refresh/',
  admission_lead: '/v1/admission/lead',
  getAllUserList: 'v1/user_management/staff',

  userMapping: '/v2/student-semester',
  deleteStudent: 'v1/user_management/student',
  blockUser: 'v1/user_management/staff',
  getAllUserRoles: 'v1/rbac/role',
  // User group API's
  userGroup: '/v1/user_management/usergroups',
  userGroupbyId: '/v1/user_management/usergroup/user',
  searchUserGroup: '/v1/user_management/user_groups/search_users',
  filterStudentUser: '/v2/student-semester',
  filterAllUser: '/v1/user_management/user/user_filter',
  addAndRemoveUserGroup: '/v1/user_management/usergroup/user',
  addSelectedUser: '/v1/user_management/usergroup/user',
  userGroupBulkUpload: '/v1/user_management/user_groups/bulk_upload',
  deleteStudent: 'v1/user_management/student',
  blockUser: 'v1/user_management/staff',
  forgotPassword: '/v1/user_management/user/forgot-password',
  studentUploadValidation: 'v1/user_management/student/user/upload_users',
  bulkUserRoles: '/v1/user_management/user/upload_users',

  // login
  getUserLogin: 'v1/user_management/login',
  superAdminLogin: 'v1/superadmin/login',

  //program list
  getAllProgramList: '/v2/user-mapped-program',
  getAllUserForNotification: 'v1/user_management/combined-list',
};
