import React, { useEffect } from 'react';
const rtfToHTML = require('@iarna/rtf-to-html');

const RtfViewer = (props) => {
  const { filePath = '' } = props || '';

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  useEffect(() => {
    if (filePath) {
      fetch(filePath)
        .then((response) => response.blob())
        .then(blobToBase64)
        .then((fData) => {
          let base64 = fData.split('data:application/rtf;base64,')[1];
          rtfToHTML.fromString(window.atob(base64), (err, html) => {
            let htmlFixed = html.replace('body', '.noclass');
            var target = document.getElementById('rtf-viewer');
            target.insertAdjacentHTML('afterend', htmlFixed);
          });
        })
        .catch((err) => {
          console.error('Error while fetching -  ' + err);
        });
    }
  }, [filePath]);

  return <div id="rtf-viewer"></div>;
};

export default RtfViewer;
