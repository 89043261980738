import React from 'react';

const Ellipses = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
    >
      <path d="M480 837.539q-16.5 0-28.25-11.75T440 797.539q0-16.501 11.75-28.251t28.25-11.75q16.5 0 28.25 11.75T520 797.539q0 16.5-11.75 28.25T480 837.539ZM480 616q-16.5 0-28.25-11.75T440 576q0-16.5 11.75-28.25T480 536q16.5 0 28.25 11.75T520 576q0 16.5-11.75 28.25T480 616Zm0-221.538q-16.5 0-28.25-11.75T440 354.461q0-16.5 11.75-28.25t28.25-11.75q16.5 0 28.25 11.75t11.75 28.25q0 16.501-11.75 28.251T480 394.462Z" />
    </svg>
  );
};

export default Ellipses;
