import { ROUTES } from '../../../constants/routesConstants';
import NotificationSvg from 'icons/Notification';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from 'iiht-b2a-ui-components/build/components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserNotificationsApi } from 'store';

function NotificationTab({ notificationList, t }) {
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const dispatch = useDispatch();
  let history = useHistory();
  const { idp_user_uuid: userId } = useSelector(
    (state) => state.userManagementState.user.data
  );
  const notificationOpen = Boolean(anchorElNotification);
  const popoverId = notificationOpen ? 'notification-popover' : undefined;

  const NotificationPopuphandler = (e) => {
    getNotifications();
    setAnchorElNotification(e.currentTarget);
  };

  const notificationPopupCloseHandler = () => {
    setAnchorElNotification(null);
  };

  const notificationPageHandler = () => {
    history.push(ROUTES.NOTIFICATIONS.ROOT);
  };

  const getNotifications = () => {
    dispatch(
      getUserNotificationsApi({
        offset: 0,
        limit: 3,
        user_id: userId,
        type: 'received',
      })
    );
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const convertEnums = (data) => {
    const originalString = data;

    const replacementConstants = {
      0: 'Spam',
      1: 'Hate Speech',
      2: 'Harassment and Bullying',
      3: 'Harmful Activities',
      4: 'Sexual Exploitation and Abuse',
    };
    const numberRegex = /\b\d+\b/g;
    const replacedString = originalString.replace(numberRegex, (match) => {
      const replacement = replacementConstants[match];
      return replacement !== undefined ? replacement : match;
    });

    return replacedString;
  };

  return (
    <>
      <IconButton
        size="small"
        aria-describedby={popoverId}
        sx={{ p: 1 }}
        onClick={NotificationPopuphandler}
      >
        <Badge variant="dot" color="error">
          <NotificationSvg />
        </Badge>
      </IconButton>
      <Popover
        id={popoverId}
        open={notificationOpen}
        anchorEl={anchorElNotification}
        onClose={notificationPopupCloseHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ width: '16rem' }}>
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
            }}
            disablePadding
          >
            {notificationList.slice(0, 3)?.map((el, i) => (
              <React.Fragment key={i}>
                <ListItem
                  onClick={notificationPageHandler}
                  className={`${
                    el.read_status ? null : 'noti-seen'
                  } notification-list`}
                >
                  <ListItemText
                    primary={convertEnums(el.display_name)}
                    secondary={
                      moment(new Date(el.created_at).toISOString()).format(
                        'DD MMM YYYY'
                      ) +
                      ' | ' +
                      moment(new Date(el.created_at).toISOString()).format(
                        'hh:mm:ss A'
                      )
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
          <Box className="past-btn">
            <Button
              color="primary"
              variant="text"
              onClick={notificationPageHandler}
            >
              {t('notificationsAndBrodcast.allNotifications')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default NotificationTab;
