import { dashboardActions } from 'store/Dashboard';

const INITIAL_STATE = {
  present_details: {
    loading: false,
    data: [],
  },
  clusters: {
    loading: false,
    totalCount: 0,
    data: [],
  },
  course_count: {
    total_courses_registrar: 0,
    loading: false,
  },
  student_attendance: {
    value: 0,
    loading: false,
  },
  class_count: {
    value: 0,
    loading: false,
  },
  schedule: {
    value: [],
    loading: false,
  },
  assessment_count: {
    value: {},
    loading: false,
  },
  module_count: {
    value: {},
    loading: false,
  },
  assessment_table: {
    loading: false,
    totalCount: 0,
    data: [],
    list: {},
    academicYear: '',
  },
  ongoing_courses: { value: '', loading: false },
  pending_evaluations: { value: '', loading: false },
  student_ratings: {
    value: '',
    loading: false,
  },
  student_progress: {
    value: '',
    loading: false,
  },
  getEnrolmentProgramList: {
    totalCount: 0,
    data: null,
    next: '',
    previous: '',
  },
  getDashboardCardDetails: '',
  getRegistrarDashboardChartDataList: {
    totalCount: '',
    data: [],
    next: '',
    previous: '',
  },
  getHrDashboardCardDetails: {
    data: {},
  },
  getStudentAttendanceGraph: {
    data: [],
  },
  studentDashboardGraphData: {
    totalCount: '',
    data: [],
    next: '',
    previous: '',
    loading: false,
  },

  studentDashboardDetails: {
    loading: false,
    data: {},
  },
  getFacultyDashboard: {
    data: [],
  },
  getFacultyDeatils: {
    data: [],
  },
  getUserFacultyDeatils: {
    data: [],
  },
};
// const { PRESENT_DETAILS } = dashboardActions;

const dashboardReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case dashboardActions.PRESENT_DETAILS.get_success: {
      return {
        ...state,
        present_details: {
          ...state.present_details,
          data: action?.payload,
        },
      };
    }
    case dashboardActions.PRESENT_DETAILS.loading: {
      return {
        ...state,
        present_details: {
          ...state.present_details,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.CLUSTERS.loading: {
      return {
        ...state,
        clusters: {
          ...state.clusters,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.REGISTRAR_COURSE_COUNT.loading: {
      return {
        ...state,
        course_count: {
          ...state.course_count,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.STUDENT_ATTENDANCE.loading: {
      return {
        ...state,
        student_attendance: {
          ...state.student_attendance,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.CLASS_COUNT.loading: {
      return {
        ...state,
        class_count: {
          ...state.class_count,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.SCHEDULE.loading: {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.ASSESSMENT_STATUS.loading: {
      return {
        ...state,
        assessment_count: {
          ...state.assessment_count,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.MODULE_STATUS.loading: {
      return {
        ...state,
        module_count: {
          ...state.module_count,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.GET_STUDENT_DASHBOARD_TABLE_SUCCESS: {
      return {
        ...state,
        assessment_table: {
          ...state.assessment_table,
          list: action?.payload || {},
          totalCount: action?.payload?.countount || 0,
        },
      };
    }

    case dashboardActions.ONGOING_COURSES.loading: {
      return {
        ...state,
        ongoing_courses: {
          ...state.ongoing_courses,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.PENDING_APPROVALS.loading: {
      return {
        ...state,
        pending_evaluations: {
          ...state.pending_evaluations,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.ASSESSMENTS_TABLE.loading: {
      return {
        ...state,
        assessment_table: {
          ...state.assessment_table,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.STUDENT_RATING.loading: {
      return {
        ...state,
        student_ratings: {
          ...state.student_attendance,
          loading: action?.payload,
        },
      };
    }

    case dashboardActions.STUDENT_PROGRESS.loading: {
      return {
        ...state,
        student_progress: {
          ...state.student_progress,
          loading: action?.payload,
        },
      };
    }

    // Registrar

    // get dashboard card details
    case dashboardActions.GET_DASHBOARD_CARD_DETAILS_SUCCESS: {
      return {
        ...state,
        getDashboardCardDetails: action?.payload || '',
      };
    }

    case dashboardActions.GET_DASHBOARD_CARD_DETAILS_RESET: {
      return {
        ...state,
        getDashboardCardDetails: '',
      };
    }

    // get enrolment program list
    case dashboardActions.GET_ENROLMENT_PROGRAM_LIST_SUCCESS: {
      const programEnrolListData =
        action?.payload?.results.length > 0 ? action?.payload?.results : [];
      return {
        ...state,
        getEnrolmentProgramList: {
          ...state.getEnrolmentProgramList,
          data: [...programEnrolListData],
          totalCount: action?.payload?.count ?? 0,
          next: action?.payload?.next ?? '',
          previous: action?.payload?.previous ?? '',
        },
      };
    }

    // Pagination - enrolment program list
    case dashboardActions.GET_MORE_ENROLMENT_PROGRAM_LIST_SUCCESS: {
      const nextProgramEnrolListData =
        action?.payload?.results.length > 0 ? action?.payload?.results : [];
      const oldProgramEnrolList = state.getEnrolmentProgramList.data ?? [];

      return {
        ...state,
        getEnrolmentProgramList: {
          ...state.getEnrolmentProgramList,
          data: [...oldProgramEnrolList, ...nextProgramEnrolListData],
          totalCount: action?.payload?.count ?? 0,
          next: action?.payload?.next ?? '',
          previous: action?.payload?.previous ?? '',
        },
      };
    }

    case dashboardActions.GET_ENROLMENT_PROGRAM_LIST_RESET: {
      return {
        ...state,
        getEnrolmentProgramList: {
          totalCount: 0,
          data: [],
          next: '',
          previous: '',
        },
      };
    }

    case dashboardActions.GET_REGISTRAR_DASHBOARD_CHART_DATA_SUCCESS: {
      return {
        ...state,
        getRegistrarDashboardChartDataList: {
          ...state.getRegistrarDashboardChartDataList,
          data: action?.payload?.results || [],
          totalCount: action?.payload?.count ?? 0,
          next: action?.payload?.next ?? '',
          previous: action?.payload?.previous ?? '',
        },
      };
    }

    case dashboardActions.GET_REGISTRAR_DASHBOARD_CHART_DATA_RESET: {
      return {
        ...state,
        getRegistrarDashboardChartDataList: {
          ...state.getRegistrarDashboardChartDataList,
          data: [],
          totalCount: '',
          next: '',
          previous: '',
        },
      };
    }
    // get dashboard card details
    case dashboardActions.GET_HR_DASHBOARD_CARD_DETAILS_SUCCESS: {
      return {
        ...state,
        getHrDashboardCardDetails: {
          ...state.getHrDashboardCardDetails,
          data: action?.payload || {},
        },
      };
    }

    //faculty

    case dashboardActions.GET_STUDENT_ATTENDANCE_GRAPH_SUCCESS: {
      return {
        ...state,
        getStudentAttendanceGraph: {
          ...state.getStudentAttendanceGraph,
          data: action?.payload,
        },
      };
    }
    case dashboardActions.GET_STUDENT_ATTENDANCE_GRAPH_ERROR: {
      return {
        ...state,
        getStudentAttendanceGraph: {
          ...state.getStudentAttendanceGraph,
          data: action?.payload,
        },
      };
    }

    // Student Dashboard graph
    case dashboardActions.STUDENT_DASHBOARD_GRAPH_REQUEST: {
      return {
        ...state,
        studentDashboardGraphData: {
          ...state.studentDashboardGraphData,
          loading: true,
        },
      };
    }
    case dashboardActions.STUDENT_DASHBOARD_GRAPH_SUCCESS: {
      return {
        ...state,
        studentDashboardGraphData: {
          ...state.studentDashboardGraphData,
          data: action?.payload || [],
          loading: false,
        },
      };
    }
    case dashboardActions.STUDENT_DASHBOARD_GRAPH_ERROR: {
      return {
        ...state,
        studentDashboardGraphData: {
          ...state.studentDashboardGraphData,
          data: action?.payload || [],
          loading: false,
        },
      };
    }

    // get student dashboard
    case dashboardActions.GET_STUDENT_DASHBOARD_SUCCESS: {
      return {
        ...state,
        studentDashboardDetails: {
          ...state.studentDashboardDetails,
          data: action?.payload || {},
        },
      };
    }

    // Faculty DashBoard
    case dashboardActions.GET_FACULTY_DASHBOARD_REQUEST_SUCCESS: {
      return {
        ...state,
        getFacultyDashboard: {
          ...state.getFacultyDashboard,
          data: action?.payload,
        },
      };
    }
    case dashboardActions.GET_FACULTY_DASHBOARD_REQUEST_ERROR: {
      return {
        ...state,
        getFacultyDashboard: {
          ...state.getFacultyDashboard,
          data: action?.payload,
        },
      };
    }

    // HOD Dasboard
    case dashboardActions.GET_FACULTY_DETAILS_DASHBOARD_SUCCESS: {
      return {
        ...state,
        getFacultyDeatils: {
          ...state.getFacultyDeatils,
          data: action?.payload,
        },
      };
    }
    case dashboardActions.GET_FACULTY_DETAILS_DASHBOARD_ERROR: {
      return {
        ...state,
        getFacultyDeatils: {
          ...state.getFacultyDeatils,
          data: action?.payload,
        },
      };
    }
    //faculty details in hod dashboard
    case dashboardActions.GET_FACULTY_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        getUserFacultyDeatils: {
          ...state.getUserFacultyDeatils,
          data: action?.payload,
        },
      };
    }
    case dashboardActions.GET_FACULTY_USER_DETAILS_ERROR: {
      return {
        ...state,
        getUserFacultyDeatils: {
          ...state.getUserFacultyDeatils,
          data: action?.payload,
        },
      };
    }

    default:
      return state;
  }
};
export default dashboardReducer;
