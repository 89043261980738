import { studentActions } from 'store/Student';

const INITIAL_STATE = {
  assementList: { loading: false, totalCount: 0, data: [] },
  assignmentList: { loading: false, totalCount: 0, data: [] },
  assignment: {
    loading: false,
    totalCount: 0,
    data: { questionSections: [] },
    deleteAssignSuccess: false,
  },
  assessment: {
    loading: false,
    totalCount: 0,
    data: {},
    deleteAssessSuccess: false,
  },
  modules: { loading: false, totalCount: 0, data: {} },
  subModules: { loading: false, totalCount: 0, data: {} },
  enrolled_course: {
    loading: false,
    data: '',
  },
  assessmentListBySubModule: { loading: false, totalCount: 0, data: [] },
  assignmentListBySubModule: { loading: false, totalCount: 0, data: [] },
  notes_and_bookmarks: {
    loading: false,
    createNotesSuccess: false,
    updateNotesSuccess: false,
    deleteNotesAndBookmarksSuccess: false,
    totalCount: 0,
    data: [],
    getNotesByContent: [],
    createBookMarks: false,
  },
  student_course_details: { loading: false, data: { modules: [] } },
  assessmentDetails: {
    loading: false,
    data: {},
    assessmentSaveSuccess: false,
    saveAssignmentQuestionsSuccess: {},
    saveAssignmentConditions: {},
  },
  quizDetails: {
    loading: false,
    data: {},
    quizSaveSuccess: false,
    saveQuestionsSuccess: {},
    saveQuizConditions: {},
  },
  quizCreationError: { loading: false, data: {} },
  notesListBySubModules: { loading: false, data: [], itemCount: 0 },
  assessmentSubmittedSuccess: {},
  pendingAssessment: { data: [], totalCount: 0 },
  pendingAssignment: { data: [], totalCount: 0 },
  quiz_assessment_list: { data: [], totalCount: 0 },
  allUsers: { data: [], totalCount: 0 },
  editedAssessDetails: {
    loading: false,
    editAssessSaveSuccess: false,
  },
  editedQuizDetails: {
    loading: false,
    quiz: {},
    editQuizSaveSuccess: false,
  },
  loading: false,
  student_course_details_error: { error: {} },
  created_student_program_details: {},
  get_student_program_details_list: {},
  studentYearListData: '',
  studentSemesterListData: '',
  edit_student_program_details: {},
  delete_student_program_details: {},
  learning_quiz: {
    quizSubmitted: false,
    fetchQuiz: {},
    assignmentSubmitted: false,
  },
  reading_progress: { data: {}, readingSubmitted: false },
  video_progress: { data: {}, videoSubmitted: false },
  video_post_progress: {
    data: {},
  },
  subjectProgramDetails: {
    data: '',
    error: '',
    loading: false,
  },
  studentConnect: {
    data: '',
    error: '',
    loading: false,
  },
  studentNotification: {
    data: '',
    error: '',
    loading: false,
  },
};

const studentReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case studentActions.ASSIGNMENT_LIST.get_success: {
      return {
        ...state,
        assignmentList: {
          ...state.assignmentList,
          totalCount: action?.payload?.totalCount,
          data: action?.payload?.items,
        },
      };
    }

    case studentActions.ASSESSMENT_LIST.get_success: {
      return {
        ...state,
        assementList: {
          ...state.assessmentList,
          totalCount: action?.payload?.totalCount,
          data: action?.payload?.items,
        },
      };
    }
    case studentActions.ASSESSMENT_BY_ID.get_success: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          // totalCount: action?.payload?.totalCount,
          data: action?.payload,
        },
      };
    }

    case studentActions.ASSESSMENT_BY_ID.reset: {
      return {
        ...state,
        assessment: {
          ...INITIAL_STATE.assessment,
        },
      };
    }

    case studentActions.ASSIGNMENT_BY_ID.get_success: {
      return {
        ...state,
        assignment: {
          ...state.assignment,
          data: action?.payload,
        },
      };
    }

    case studentActions.ASSIGNMENT_BY_ID.get_quiz_for_edit_success: {
      return {
        ...state,
        editedQuizDetails: {
          ...state.editedQuizDetails,
          quiz: action?.payload,
        },
      };
    }

    case studentActions.ASSIGNMENT_BY_ID.reset: {
      return {
        ...state,
        assignment: {
          ...INITIAL_STATE.assignment,
        },
      };
    }
    case studentActions.MODULES.get_success: {
      return {
        ...state,
        modules: {
          ...state.modules,
          totalCount: action?.payload?.totalCount,
          data: action?.payload,
        },
      };
    }
    case studentActions.SUBMODULES.get_success: {
      return {
        ...state,
        subModules: {
          ...state.subModules,
          data: action?.payload?.items,
          totalCount: action?.payload?.totalCount,
        },
      };
    }
    case studentActions.ASSESSMENT_CREATE.create_success: {
      return {
        ...state,
        assessmentDetails: {
          ...state.assessmentDetails,
          assessmentSaveSuccess: action?.payload,
        },
      };
    }

    case studentActions.ASSESSMENT_CREATE.reset: {
      return {
        ...state,
        assessmentDetails: {
          ...INITIAL_STATE.assessmentDetails,
        },
      };
    }

    case studentActions.ASSESSMENT_DELETE.delete_success: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          deleteAssessSuccess: action?.payload,
        },
      };
    }

    case studentActions.QUIZ_CREATE.create_success: {
      return {
        ...state,
        quizDetails: {
          ...state.quizDetails,
          quizSaveSuccess: action?.payload,
        },
      };
    }

    case studentActions.QUIZ_CREATE.reset: {
      return {
        ...state,
        quizDetails: {
          ...INITIAL_STATE.quizDetails,
        },
      };
    }

    case studentActions.QUIZ_CREATE.create_error: {
      return {
        ...state,
        quizCreationError: {
          ...state.quizCreationError,
          data: action?.payload,
        },
      };
    }

    case studentActions.ASSESSMENT_EDIT.edit_success: {
      return {
        ...state,
        editedAssessDetails: {
          ...state.editedAssessDetails,
          editAssessSaveSuccess: action?.payload,
        },
      };
    }

    case studentActions.QUIZ_EDIT.edit_success: {
      return {
        ...state,
        editedQuizDetails: {
          ...state.editedQuizDetails,
          editQuizSaveSuccess: action?.payload,
        },
      };
    }

    case studentActions.QUIZ_DELETE.delete_success: {
      return {
        ...state,
        assignment: {
          ...state.assignment,
          deleteAssignSuccess: action?.payload,
        },
      };
    }

    case studentActions.QUIZ_CREATE.save_questions: {
      return {
        ...state,
        quizDetails: {
          ...state.quizDetails,
          saveQuestionsSuccess: action?.payload,
        },
      };
    }
    case studentActions.QUIZ_CREATE.save_conditions: {
      return {
        ...state,
        editedQuizDetails: {
          ...state.quizDetails,
          quiz: action?.payload,
        },
      };
    }

    case studentActions.ASSESSMENT_CREATE.save_assignment: {
      return {
        ...state,
        assessmentDetails: {
          ...state.assessmentDetails,
          saveAssignmentQuestionsSuccess: action?.payload,
        },
      };
    }
    case studentActions.ASSESSMENT_CREATE.save_assignment_conditions: {
      return {
        ...state,
        assessmentDetails: {
          ...state.assessmentDetails,
          saveAssignmentConditions: action?.payload,
        },
      };
    }

    case studentActions.STUDENT_COURSE.get_course_by_student_id_success: {
      return {
        ...state,
        enrolled_course: {
          ...state.enrolled_course,
          data: action?.payload,
        },
      };
    }

    case studentActions.STUDENT_COURSE.reset_get_course_by_student_id: {
      return {
        ...state,
        enrolled_course: INITIAL_STATE.enrolled_course,
      };
    }

    case studentActions.ASSIGNMENT_BY_ID.get_assignment_by_subModule_success: {
      return {
        ...state,
        assignmentListBySubModule: {
          ...state.assignmentListBySubModule,
          totalCount: action?.payload?.totalCount || 0,
          data: action?.payload?.items || [],
        },
      };
    }

    case studentActions.ASSESSMENT_BY_ID.get_assessment_by_subModule_success: {
      return {
        ...state,
        assessmentListBySubModule: {
          ...state.assessmentListBySubModule,
          totalCount: action?.payload?.totalCount || 0,
          data: action?.payload?.items || [],
        },
      };
    }

    case studentActions.NOTES_BOOKMARKS_LIST.get_success: {
      return {
        ...state,
        notes_and_bookmarks: {
          ...state.notes_and_bookmarks,
          totalCount: action?.payload?.totalCount,
          data: action?.payload?.items,
        },
      };
    }

    case studentActions.NOTES_BOOKMARKS_LIST.delete_notes_bookmarks_success: {
      return {
        ...state,
        notes_and_bookmarks: {
          ...state.notes_and_bookmarks,
          deleteNotesAndBookmarksSuccess: action.payload,
        },
      };
    }

    case studentActions.NOTES_BOOKMARKS_LIST.create_notes_bookmarks_success: {
      return {
        ...state,
        notes_and_bookmarks: {
          ...state.notes_and_bookmarks,
          createNotesSuccess: action.payload,
        },
      };
    }

    case studentActions.NOTES_BOOKMARKS_LIST.update_notes_bookmarks_success: {
      return {
        ...state,
        notes_and_bookmarks: {
          ...state.notes_and_bookmarks,
          updateNotesSuccess: action.payload,
        },
      };
    }

    case studentActions.COURSE_DETAILS.get_details_by_course_id_success: {
      return {
        ...state,
        student_course_details: {
          ...state.course_details,
          data: { ...action.payload },
        },
      };
    }

    case studentActions.NOTES_BOOKMARKS_LIST.get_by_module_id_success: {
      return {
        ...state,
        notes_and_bookmarks: {
          ...state.notes_and_bookmarks,
          data: action.payload,
          totalCount: action.payload.length,
        },
      };
    }

    case studentActions.NOTES_BOOKMARKS_LIST.get_by_submodule_id_success: {
      return {
        ...state,
        notes_and_bookmarks: {
          ...state.notes_and_bookmarks,
          data: action.payload,
          totalCount: action.payload.length,
        },
      };
    }

    case studentActions.SUBMIT_QUIZ.submit_quiz_success: {
      return {
        ...state,
        assessmentSubmittedSuccess: action.payload,
      };
    }

    case studentActions.PENDING_ASSESSMENT.get_assessment_success: {
      return {
        ...state,
        pendingAssessment: {
          ...state.pendingAssessment,
          totalCount: action?.payload?.totalCount || 0,
          data: action?.payload?.items || [],
        },
      };
    }

    case studentActions.PENDING_ASSIGNMENT.get_assignment_success: {
      return {
        ...state,
        pendingAssignment: {
          ...state.pendingAssignment,
          totalCount: action?.payload?.totalCount || 0,
          data: action?.payload?.items || [],
        },
      };
    }

    case studentActions.ALL_USERS.get_success: {
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          totalCount: action?.payload?.totalCount || 0,
          data: [...state?.allUsers?.data, ...action?.payload?.users],
        },
      };
    }

    case studentActions.ALL_USERS.reset: {
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          totalCount: 0,
          data: [],
        },
      };
    }

    case studentActions.GET_STUDENT_ACTIVITIES.get_student_activities_success: {
      return {
        ...state,
        quiz_assessment_list: {
          ...state.quiz_assessment_list,
          totalCount: action?.payload?.totalCount || 0,
          data: action?.payload?.items || [],
        },
      };
    }

    case studentActions.STUDENT_LOADING.student_loading: {
      return {
        ...state,
        loading: action?.payload,
      };
    }

    case studentActions.COURSE_DETAILS.get_details_by_course_id_error: {
      return {
        ...state,
        student_course_details_error: {
          ...state.course_details,
          error: { ...action.payload },
        },
      };
    }

    case studentActions.STUDENT_SEMESTER
      .create_student_program_details_succcess: {
      return {
        ...state,
        created_student_program_details: action?.payload,
      };
    }

    case studentActions.STUDENT_SEMESTER.get_student_program_details_success: {
      return {
        ...state,
        get_student_program_details_list: action?.payload,
      };
    }

    case studentActions.STUDENT_SEMESTER.GET_STUDENT_YEAR_LIST_SUCCESS: {
      return {
        ...state,
        studentYearListData: action?.payload || '',
      };
    }

    case studentActions.STUDENT_SEMESTER.GET_STUDENT_SEMESTER_LIST_SUCCESS: {
      return {
        ...state,
        studentSemesterListData: action?.payload || '',
      };
    }

    case studentActions.STUDENT_SEMESTER.reset_student_program_details_action: {
      return {
        ...state,
        created_student_program_details: {},
        get_student_program_details_list: {},
        edit_student_program_details: {},
      };
    }

    case studentActions.STUDENT_SEMESTER
      .update_student_program_details_success: {
      return {
        ...state,
        edit_student_program_details: action?.payload,
      };
    }
    case studentActions.STUDENT_SEMESTER
      .delete_student_program_details_action: {
      return {
        ...state,
        delete_student_program_details: action?.payload,
      };
    }

    case studentActions.NOTES_BOOKMARKS_LIST.get_by_content_success: {
      return {
        ...state,
        notes_and_bookmarks: {
          ...state.notes_and_bookmarks,
          getNotesByContent: action?.payload,
        },
      };
    }

    case studentActions.NOTES_BOOKMARKS_LIST.addUpdate_bookmarks_success: {
      return {
        ...state,
        notes_and_bookmarks: {
          ...state.notes_and_bookmarks,
          createBookMarks: action?.payload,
        },
      };
    }

    case studentActions.SUBMIT_QUIZ.submit_learning_quiz_success: {
      return {
        ...state,
        learning_quiz: {
          ...state.learning_quiz,
          quizSubmitted: action?.payload,
        },
      };
    }

    case studentActions.SUBMIT_QUIZ.learning_quiz_success: {
      return {
        ...state,
        editedQuizDetails: {
          ...state.editedQuizDetails,
          quiz: action?.payload,
        },
      };
    }

    case studentActions.SUBMIT_QUIZ.submit_learning_assignment_success: {
      return {
        ...state,
        learning_quiz: {
          ...state.learning_quiz,
          assignmentSubmitted: action?.payload,
        },
      };
    }

    case studentActions.SUBMIT_QUIZ.learning_assignment_success: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          data: action?.payload,
        },
      };
    }

    case studentActions.VIDEO_READINGS_PROGRESS.video_progress_success: {
      return {
        ...state,
        video_progress: {
          ...state.video_progress,
          videoSubmitted: action?.payload,
        },
      };
    }
    case studentActions.VIDEO_READINGS_PROGRESS.reading_progress_success: {
      return {
        ...state,
        reading_progress: {
          ...state.reading_progress,
          videoSubmitted: action?.payload,
        },
      };
    }

    case studentActions.VIDEO_READINGS_PROGRESS.get_video_progress_success: {
      return {
        ...state,
        video_progress: {
          ...state.video_progress,
          data: action?.payload || {},
        },
      };
    }
    case studentActions.VIDEO_READINGS_PROGRESS.get_reading_progress_success: {
      return {
        ...state,
        reading_progress: {
          ...state.reading_progress,
          data: action?.payload,
        },
      };
    }

    case studentActions.VIDEO_READINGS_PROGRESS.video_progress: {
      return {
        ...state,
        video_post_progress: {
          ...state.video_post_progress,
          data: action?.payload || {},
        },
      };
    }

    case studentActions.GET_SUBJECT_PROGRAM_DETAILS.success: {
      return {
        ...state,
        subjectProgramDetails: {
          data: action?.payload,
          loading: false,
          error: '',
        },
      };
    }

    case studentActions.GET_SUBJECT_PROGRAM_DETAILS.error: {
      return {
        ...state,
        subjectProgramDetails: {
          error: action?.payload,
          data: '',
          loading: false,
        },
      };
    }

    case studentActions.GET_SUBJECT_PROGRAM_DETAILS.loading: {
      return {
        ...state,
        subjectProgramDetails: {
          ...state.subjectProgramDetails,
          loading: action?.payload,
        },
      };
    }

    case studentActions.GET_SUBJECT_PROGRAM_DETAILS.reset: {
      return {
        ...state,
        subjectProgramDetails: INITIAL_STATE.subjectProgramDetails,
      };
    }

    case studentActions.GET_STUDENT_CONNECT.GET_STUDENT_SUCCESS: {
      return {
        ...state,
        studentConnect: {
          data: action?.payload,
          loading: false,
          error: '',
        },
      };
    }

    case studentActions.GET_STUDENT_CONNECT.GET_STUDENT_ERROR: {
      return {
        ...state,
        studentConnect: {
          error: action?.payload,
          data: '',
          loading: false,
        },
      };
    }

    case studentActions.GET_STUDENT_CONNECT.GET_STUDENT_RESET: {
      return {
        ...state,
        studentConnect: INITIAL_STATE.studentConnect,
      };
    }

    case studentActions.GET_STUDENT_CONNECT.GET_STUDENT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        studentNotification: {
          data: action?.payload,
          loading: false,
          error: '',
        },
      };
    }

    case studentActions.GET_STUDENT_CONNECT.GET_STUDENT_NOTIFICATION_ERROR: {
      return {
        ...state,
        studentNotification: {
          error: action?.payload,
          data: '',
          loading: false,
        },
      };
    }

    case studentActions.GET_STUDENT_CONNECT.GET_STUDENT_NOTIFICATION_RESET: {
      return {
        ...state,
        studentNotification: INITIAL_STATE.studentNotification,
      };
    }

    default:
      return state;
  }
};

export default studentReducer;
