import { takeLatest, put, call, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';
import {
  createExamsAPI,
  createExamsErrorAction,
  createExamsSuccessAction,
  deleteExamSuccessAction,
  deleteExamsAPI,
  editExamsAPI,
  examActions,
  getExamByIdApi,
  getExamByIdErrorAction,
  getExamByIdSuccessAction,
  getExamListApi,
  getExamsErrorAction,
  getExamsSuccessAction,
  setExamsLoadingAction,
  getExamsSuccessProgramYearList,
  getExamsSuccessSemesterList,
  getSuccesExamsDashboardList,
  getSuccessExamsList,
  getSuccessProgramListIncr,
  getExamsDashboardListApi,
  getUserMappingDataApi,
  createExamContentApi,
  createExamContentSuccessAction,
  createExamContentError,
  publishExamContentApi,
  publishExamContentSuccess,
  publishExamContentError,
  getExamContentApi,
  getExamContentSuccess,
  getExamContentError,
  getPracticalExamContentSuccess,
  getPracticalExamContentError,
  editExamContentSuccessAction,
  editExamContentError,
  editExamContentApi,
  getProgramYearSemMappingDataApi,
  getSuccessProgramList,
  getSuccessProgramYearList,
  getSuccessSemesterList,
  getSuccessSubjectList,
} from 'store/Exams';

function* getExamsListSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(getExamListApi, payload);
    yield put(setExamsLoadingAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getExamsSuccessAction(response?.data));
    } else {
      yield put(getExamsErrorAction(response?.data));
    }
  } catch (error) {
    console.error(error);
    yield put(setExamsLoadingAction(false));
  }
}
function* createExamsSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(createExamsAPI, payload);
    yield put(setExamsLoadingAction(false));
    if (response?.status === statusCode.Created201) {
      yield put(createExamsSuccessAction(true));
      yield put(
        setToastMessage({
          data: 'Exam creation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createExamsErrorAction(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(setExamsLoadingAction(false));
  }
}

function* updateExamsSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(editExamsAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(createExamsSuccessAction(true));
      yield put(
        setToastMessage({
          data: 'Exam update successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createExamsErrorAction(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    console.error(error);
    yield put(setExamsLoadingAction(false));
  }
}

function* getExamsByIdSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(getExamByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getExamByIdSuccessAction(response?.data));
    } else {
      yield put(getExamByIdErrorAction(response?.data));
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    yield put(getExamByIdErrorAction(error?.data));
    yield put(setExamsLoadingAction(false));
  }
}

function* deleteExamsSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(deleteExamsAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteExamSuccessAction(response?.data));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    yield put(
      setToastMessage({
        data: error?.data?.message ?? '',
        severity: 'error',
      })
    );
    yield put(setExamsLoadingAction(false));
  }
}

function* getProgramsListIncrSaga({ payload }) {
  try {
    const response = yield call(getUserMappingDataApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessProgramListIncr(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getProgramYearListSaga({ payload }) {
  try {
    const response = yield call(getUserMappingDataApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getExamsSuccessProgramYearList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getSemesterListSaga({ payload }) {
  try {
    const response = yield call(getUserMappingDataApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getExamsSuccessSemesterList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getExamsDashboardListSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(getExamsDashboardListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccesExamsDashboardList(response?.data));
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    console.error(error);
    yield put(setExamsLoadingAction(false));
  }
}
function* getExamsListFacultySaga({ payload }) {
  try {
    const response = yield call(getExamListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessExamsList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* createExamContentSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(createExamContentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createExamContentSuccessAction(response?.data));
      yield put(
        setToastMessage({
          data: 'Saved successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(createExamContentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    yield put(createExamContentError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.message,
        severity: 'error',
      })
    );
    yield put(setExamsLoadingAction(false));
  }
}

function* publishExamContentSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(publishExamContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(publishExamContentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? 'Exam published successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(publishExamContentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    yield put(publishExamContentError(error?.data));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
    yield put(setExamsLoadingAction(false));
  }
}

function* getExamContentSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(getExamContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getExamContentSuccess(response?.data));
    } else {
      yield put(getExamContentError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    yield put(publishExamContentError(error?.data));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
    yield put(setExamsLoadingAction(false));
  }
}

function* getPracticalExamContentSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(getExamContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getPracticalExamContentSuccess(response?.data));
    } else {
      yield put(getPracticalExamContentError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    yield put(publishExamContentError(error?.data));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
    yield put(setExamsLoadingAction(false));
  }
}

function* editExamContentSaga({ payload }) {
  try {
    yield put(setExamsLoadingAction(true));
    const response = yield call(editExamContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(editExamContentSuccessAction(response?.data));
      yield put(
        setToastMessage({
          data: 'Updated exam successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(editExamContentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wong',
          severity: 'error',
        })
      );
    }
    yield put(setExamsLoadingAction(false));
  } catch (error) {
    yield put(editExamContentError(error?.data));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
    yield put(setExamsLoadingAction(false));
  }
}

// New

function* getNewProgramsListIncrSaga({ payload }) {
  try {
    const response = yield call(getProgramYearSemMappingDataApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessProgramList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getNewProgramYearListSaga({ payload }) {
  try {
    const response = yield call(getProgramYearSemMappingDataApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessProgramYearList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getNewSemesterListSaga({ payload }) {
  try {
    const response = yield call(getProgramYearSemMappingDataApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessSemesterList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getProgramSubjectListSaga({ payload }) {
  try {
    const response = yield call(getProgramYearSemMappingDataApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessSubjectList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchDummy() {
  yield takeLatest(examActions.EXAMS.get, getExamsListSaga);
  yield takeLatest(examActions.EXAMS.create, createExamsSaga);
  yield takeLatest(examActions.EXAMS.edit, updateExamsSaga);
  yield takeLatest(examActions.EXAMS.get_by_id, getExamsByIdSaga);
  yield takeLatest(examActions.EXAMS.delete, deleteExamsSaga);

  yield takeLatest(
    examActions.PROGRAM.GET_PROGRAMS_LIST_INCR,
    getProgramsListIncrSaga
  );
  yield takeLatest(
    examActions.PROGRAM_YEAR.GET_EXAMS_PROGRAM_YEAR_LIST,
    getProgramYearListSaga
  );
  yield takeLatest(
    examActions.SEMESTER.GET_EXAMS_SEMESTER_LIST,
    getSemesterListSaga
  );
  yield takeLatest(examActions.EXAM.GET_EXAMS_LIST, getExamsListSaga);
  yield takeLatest(
    examActions.EXAM.GET_EXAMS_DASHBOARD_LIST,
    getExamsDashboardListSaga
  );
  yield takeLatest(examActions.EXAM.GET_EXAMS_LIST, getExamsListFacultySaga);
  yield takeLatest(examActions.EXAMS.create_content, createExamContentSaga);
  yield takeLatest(examActions.EXAMS.publish_content, publishExamContentSaga);
  yield takeLatest(examActions.EXAMS.get_exam_content, getExamContentSaga);
  yield takeLatest(
    examActions.EXAMS.get_practical_content,
    getPracticalExamContentSaga
  );
  yield takeLatest(examActions.EXAMS.edit_content, editExamContentSaga);

  // New one program year sem

  yield takeLatest(
    examActions.PROGRAM.GET_NEW_PROGRAMS_LIST_REQUEST,
    getNewProgramsListIncrSaga
  );
  yield takeLatest(
    examActions.PROGRAM_YEAR.GET_NEW_PROGRAM_YEAR_LIST_REQUEST,
    getNewProgramYearListSaga
  );
  yield takeLatest(
    examActions.SEMESTER.GET_NEW_SEMESTER_LIST_REQUEST,
    getNewSemesterListSaga
  );
  yield takeLatest(
    examActions.SUBJECT.GET_SUBJECT_LIST_REQUEST,
    getProgramSubjectListSaga
  );
}

export default function* ExamsSaga() {
  yield all([watchDummy()]);
}
