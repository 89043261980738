import * as React from 'react';

const RateStar = ({ fill, height, width, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 96 960 960"
    width={width}
    fill={fill}
    {...rest}
  >
    <path d="m378 767 102-78 102 78-38-124 114-91H521l-41-126-40 126H302l115 91-39 124ZM243 960l90-291L96 480h291l93-288 93 288h291L627 669l90 291-237-180-237 180Zm237-364Z" />
  </svg>
);

export default RateStar;
