import { obeActions } from './Obe.constants';

const {
  DEFINE_OUTCOMES_LOADER,
  CREATE_DEFINE_OUTCOMES,
  GET_DEFINE_OUTCOMES,
  GET_MISSION_VS_PEO_MAPPING,
  CREATE_MISSION_VS_PEO_MAPPING,
  GET_CO_VS_PO_MAPPING,
  CREATE_CO_VS_PO_MAPPING,
} = obeActions;

export const defineOutcomesLoader = (payload) => ({
  type: DEFINE_OUTCOMES_LOADER,
  payload: payload,
});

export const createDefineOutcomes = (payload, outcome_type) => ({
  type: CREATE_DEFINE_OUTCOMES.create,
  payload: payload,
  outcome_type: outcome_type,
});

export const createDefineOutcomesSuccess = (payload) => ({
  type: CREATE_DEFINE_OUTCOMES.success,
  payload: payload,
});

export const createDefineOutcomesError = (payload) => ({
  type: CREATE_DEFINE_OUTCOMES.error,
  payload: payload,
});

export const resetCreateDefineOutcomes = (payload) => ({
  type: CREATE_DEFINE_OUTCOMES.reset,
  payload: payload,
});

export const getDefineOutcomes = (payload) => ({
  type: GET_DEFINE_OUTCOMES.get,
  payload: payload,
});

export const getDefineOutcomesSuccess = (payload) => ({
  type: GET_DEFINE_OUTCOMES.success,
  payload: payload,
});

export const getDefineOutcomesError = (payload) => ({
  type: GET_DEFINE_OUTCOMES.error,
  payload: payload,
});

export const resetGetDefineOutcomes = (payload) => ({
  type: GET_DEFINE_OUTCOMES.reset,
  payload: payload,
});

export const missionvspeoMappingAction = (payload) => ({
  type: GET_MISSION_VS_PEO_MAPPING?.get,
  payload: payload,
});

export const missionvspeoMappingSuccess = (payload) => ({
  type: GET_MISSION_VS_PEO_MAPPING.success,
  payload: payload,
});

export const missionvspeoMappingError = (payload) => ({
  type: GET_MISSION_VS_PEO_MAPPING?.error,
  payload: payload,
});

export const missionVsPeoMappingReset = () => ({
  type: GET_MISSION_VS_PEO_MAPPING.reset,
});

export const createMappingAction = (payload) => ({
  type: CREATE_MISSION_VS_PEO_MAPPING.create,
  payload: payload,
});

export const createMappingSuccess = (payload) => ({
  type: CREATE_MISSION_VS_PEO_MAPPING.success,
  payload: payload,
});

export const createMappingError = (payload) => ({
  type: CREATE_MISSION_VS_PEO_MAPPING.error,
  payload: payload,
});

export const createMappingReset = () => ({
  type: CREATE_MISSION_VS_PEO_MAPPING.reset,
});

export const covspoMappingAction = (payload) => ({
  type: GET_CO_VS_PO_MAPPING?.get,
  payload: payload,
});

export const covspoMappingSuccess = (payload) => ({
  type: GET_CO_VS_PO_MAPPING.success,
  payload: payload,
});

export const covspoMappingError = (payload) => ({
  type: GET_CO_VS_PO_MAPPING?.error,
  payload: payload,
});

export const covspoMappingReset = () => ({
  type: GET_CO_VS_PO_MAPPING.reset,
});

export const createcovspoMappingAction = (payload) => ({
  type: CREATE_CO_VS_PO_MAPPING.create,
  payload: payload,
});

export const createcovspoMappingSuccess = (payload) => ({
  type: CREATE_CO_VS_PO_MAPPING.success,
  payload: payload,
});

export const createcovspoMappingError = (payload) => ({
  type: CREATE_CO_VS_PO_MAPPING.error,
  payload: payload,
});

export const createcovspoMappingReset = () => ({
  type: CREATE_MISSION_VS_PEO_MAPPING.reset,
});
