import React from 'react';
import { DataGrid } from 'iiht-b2a-ui-components/build/components';

const UserDetailsTable = ({
  setSelectedRows,
  setBulk_delete,
  // setBulkdelete,
  tableRows,
  tableColumns,
  getRowId,
  pageSize,
  rowsPerPageOptions,
  experimentalFeatures,
  page,
  onPageSizeChange,
  onPageChange,
  rowCount,
  initialState,
}) => {
  const handleSelectionModelChange = (params) => {
    if(setBulk_delete != undefined){
    setBulk_delete(params);
    setSelectedRows(params.length);
  }
  };
  return (
    <>
      <DataGrid
        checkboxSelection={true}
        autoHeight
        rows={tableRows}
        columns={tableColumns}
        getRowId={getRowId}
        multiple
        pageSize={pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        showCellRightBorder={true}
        experimentalFeatures={experimentalFeatures}
        page={page}
        initialState={initialState}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        rowCount={rowCount}
        paginationMode="server"
        onSelectionModelChange={handleSelectionModelChange}
      />
    </>
  );
};

export default UserDetailsTable;
