import React from 'react';

const LabProfile = ({ fill, height = 40, width = 40 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      width={width}
      height={height}
      viewBox="0 0 15.302 19.127"
    >
      <path
        id="lab_profile_FILL0_wght400_GRAD0_opsz48"
        d="M163.825-870.676v-1.435h7.651v1.435Zm0-3.825v-1.435h7.651v1.435Zm-2.391,6.216h7.651a2.487,2.487,0,0,1,1.177.287,2.914,2.914,0,0,1,.927.765l2.678,3.491v-14.823H161.435Zm0,5.977H173.15l-3.084-4.041a1.3,1.3,0,0,0-.437-.369,1.163,1.163,0,0,0-.543-.133h-7.651Zm12.433,1.434H161.435a1.376,1.376,0,0,1-1-.43,1.376,1.376,0,0,1-.43-1v-16.258a1.376,1.376,0,0,1,.43-1,1.376,1.376,0,0,1,1-.43h12.433a1.376,1.376,0,0,1,1,.43,1.376,1.376,0,0,1,.43,1v16.258a1.377,1.377,0,0,1-.43,1A1.376,1.376,0,0,1,173.867-860.873Zm-12.433-1.434v0Zm0-4.543v0Z"
        transform="translate(-160 880)"
      />
    </svg>
  );
};

export default LabProfile;
