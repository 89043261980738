import { feeManagementActions } from './FeeManagment.constants';
const { FEE } = feeManagementActions;

export const getFeeClusters = (payload) => {
  {
    return {
      type: FEE.get,
      payload: payload,
    };
  }
};

export const getFeeClustersSuccess = (payload) => {
  return {
    type: FEE.get_success,
    payload: payload,
  };
};

export const getFeeClustersError = (payload) => {
  {
    return {
      type: FEE.get_error,
      payload: payload,
    };
  }
};

export const getFeeClustersloading = (payload) => {
  {
    return {
      type: FEE.loading,
      payload: payload,
    };
  }
};

export const createFeesUploadAction = (data, payload) => ({
  type: FEE.create_FeesUpload,
  data: data,
  payload: payload,
});

export const createFeesUploadSuccess = (payload) => ({
  type: FEE.create_FeesUpload_success,
  payload: payload,
});

export const createFeesUploadError = (payload) => ({
  type: FEE.create_FeesUpload_error,
  payload: payload,
});

export const resetFeesUpload = () => ({
  type: FEE.reset_fees_upload,
});

export const createGetStatusByUserAction = (payload) => ({
  type: FEE.create_get_StatusBy_users,
  payload: payload,
});

export const createGetStatusByUserActionSuccess = (payload) => ({
  type: FEE.create_get_StatusBy_users_success,
  payload: payload,
});

export const creategetStatusByUserActionError = (payload) => ({
  type: FEE.create_get_StatusBy_users_error,
  payload: payload,
});

export const createFeeEmailRemainderAction = (payload) => ({
  type: FEE.create_FeeEmailRemainder,
  payload: payload,
});

export const createFeeEmailRemainderActionSuccess = (payload) => ({
  type: FEE.create_FeeEmailRemainder_success,
  payload: payload,
});

export const createFeeEmailRemainderActionError = (payload) => ({
  type: FEE.create_FeeEmailRemainder_error,
  payload: payload,
});
