import axiosInstance from 'translator';
import { courseContentFeedbackAPI } from './CourseContentFeedback.apiEndpoints';
export const courseContentApi = async (payload) => {
  return axiosInstance
    .post(`${courseContentFeedbackAPI?.createContent}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const courseSectionApi = async (payload) => {
  return axiosInstance
    .post(`${courseContentFeedbackAPI?.createSection}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const getSectionApi = async (payload) => {
  return axiosInstance
    .get(`${courseContentFeedbackAPI?.getSection}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const deleteSectionApi = async (payload) => {
  return axiosInstance
    .delete(`${courseContentFeedbackAPI?.getSection}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const updateSectionApi = async (payload, req) => {
  return axiosInstance
    .put(`${courseContentFeedbackAPI?.getSection}/${req.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const getCourseContentApi = async (payload) => {
  return axiosInstance
    .get(`${courseContentFeedbackAPI?.createContent}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const getCourseContentByIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseContentFeedbackAPI?.createContent}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const updateCourseContentApi = async (payload, req) => {
  return axiosInstance
    .put(`${courseContentFeedbackAPI?.createContent}/${req.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const createQuestionApi = async (payload) => {
  return axiosInstance
    .post(`${courseContentFeedbackAPI?.createQuestion}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createDuplicateApi = async (payload) => {
  const formId = payload?.form_id;
  return axiosInstance
    .post(`${courseContentFeedbackAPI?.createDuplicate}?form_id=${formId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const deleteCourseContentApi = async (payload) => {
  return axiosInstance
    .delete(`${courseContentFeedbackAPI?.deleteContent}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const getQuestionApi = async (payload) => {
  return axiosInstance
    .get(`${courseContentFeedbackAPI?.getQuestion}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const getQuestionByIdApi = async (payload) => {
  return axiosInstance
    .get(`${courseContentFeedbackAPI?.getQuestion}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const updateQuestionApi = async (payload, req) => {
  return axiosInstance
    .put(`${courseContentFeedbackAPI?.getQuestion}/${payload}`, req)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const publishContentApi = async (payload) => {
  return axiosInstance
    .post(`${courseContentFeedbackAPI?.publishContent}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const getQuestionbySectionApi = async (payload) => {
  return axiosInstance
    .get(`${courseContentFeedbackAPI?.getQuestionbySection}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const saveFeedbackApi = async (payload) => {
  return axiosInstance
    .post(`${courseContentFeedbackAPI?.saveFeedback}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
