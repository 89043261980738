import { notificationActions } from './Notification.constants';

const initialState = {
  show: false,
  data: '',
  severity: 'success',
  duration: 2000,
  notification: {
    createNotificationSuccess: false,
    updateNotificationSuccess: false,
    deleteNotificationSuccess: false,
    blob_uploaded: '',
    notification_read: false,
    updateUserNotification: false,
  },
  notification_list: { loading: false, totalCount: 0, data: [] },
  broadcast_list: { loading: false, totalCount: 0, data: [] },
  notification_by_id: { loading: false, totalCount: 0, data: {} },
  notificaiton_details: { loading: false, data: [], totalCount: 0 }, // get list of notification for user
  sent_notification_and_broadcat: { loading: false, data: [], totalCount: 0 },
  sent_notification_list: { loading: false, totalCount: 0, data: [] },
  loading: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationActions.SET_NOTIFICATION:
      return {
        ...state,
        show: true,
        data: action.payload?.data || '',
        severity: action.payload?.severity || 'success',
        duration: action.payload?.duration || 2000,
      };

    case notificationActions.RESET_NOTIFICATION:
      return {
        ...initialState,
      };

    case notificationActions.GET_NOTIFICATION_CONTENT
      .get_notification_content_success:
      return {
        ...state,
        notificaiton_details: {
          data: action.payload?.results || [],
          totalCount: action.payload?.count || 0,
        },
      };

    case notificationActions.REVOKE_NOTIFICATION.revoke_notification_success: {
      return {
        ...state,
        notification: {
          ...state.notification,
          deleteNotificationSuccess: action.payload,
        },
      };
    }

    case notificationActions.CREATE_NOTIFICATON.create_notification_success: {
      return {
        ...state,
        notification: {
          ...state.notification,
          createNotificationSuccess: action.payload,
        },
      };
    }

    case notificationActions.UPLOAD_NOTIFICATION_BLOB
      .upload_notification_blob_reset: {
      return {
        ...state,
        notification: {
          ...state.notification,
          blob_uploaded: '',
        },
      };
    }

    case notificationActions.READ_NOTIFICATION
      .mark_as_read_notification_success: {
      return {
        ...state,
        notification: {
          ...state.notification,
          notification_read: action.payload,
        },
      };
    }

    case notificationActions.SENT_NOTIFICATION_LIST
      .sent_notification_list_success: {
      return {
        ...state,
        sent_notification_list: {
          data: action.payload?.results,
          totalCount: action.payload?.count,
        },
      };
    }

    case notificationActions.CREATE_UPDATE_USER_NOTIFICATION
      .create_update_user_notification_success: {
      return {
        ...state,
        notification: {
          ...state.notification,
          updateUserNotification: action.payload,
        },
      };
    }
    case notificationActions.SET_LOADING.set_loading: {
      return {
        ...state,
        loading: action?.payload,
      };
    }
    default:
      return state;
  }
};

export default notificationReducer;
