import axiosInstance from 'translator';
import { notificationAPI } from 'store/Notifications';

export const createNotification = async (payload) => {
  return axiosInstance
    .post(`${notificationAPI?.userNotification}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteNotification = async (payload) => {
  return axiosInstance
    .delete(`${notificationAPI?.deleteNotification}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const markAsReadNotification = async (payload) => {
  return axiosInstance
    .patch(`${notificationAPI?.userNotification}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchNotificationContent = async (payload) => {
  return axiosInstance
    .get(`${notificationAPI?.userNotification}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const sentNotificationListApi = async (payload) => {
  return axiosInstance
    .get(`${notificationAPI?.userNotification}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createUpdateUserNotification = async (payload) => {
  return axiosInstance
    .patch(`${notificationAPI?.userNotification}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
