import { statusCode } from 'constants/apis/apiStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  calendarActions,
  createCalendarEventApi,
  createCalendarEventSuccess,
  deleteCalendarEventApi,
  getCombinedListApi,
  getCombinedListError,
  getCombinedListSuccess,
  getEmailListApi,
  getEmailListError,
  getEmailListSuccess,
  getEventsApi,
  getEventsSuccess,
  getUserDetailsSuccess,
  setEventsLoading,
  updateCalendarEventApi,
  updateEventSuccess,
} from 'store/Calendar';
import { setToastMessage } from 'store/Toast';
import { getUserDetailsApi } from 'store/UserManagement';

function* getCalendarEvents({ payload }) {
  try {
    yield put(setEventsLoading(true));
    const response = yield call(getEventsApi, payload);
    yield put(setEventsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getEventsSuccess(response?.data));
    } else {
      console.error(response.data);
    }
  } catch (error) {
    console.error(error);
  }
}

function* createCalendarEvent({ payload }) {
  try {
    yield put(setEventsLoading(true));
    const response = yield call(createCalendarEventApi, payload?.request);
    if (response?.status === statusCode.Created201) {
      yield put(createCalendarEventSuccess(response?.data));
      yield put(
        setToastMessage({
          data:
            payload?.request?.event_type == 0
              ? 'event created'
              : 'Holiday Created',
          severity: 'success',
        })
      );
      yield put(setEventsLoading(false));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.message?.non_field_errors,
          severity: 'error',
        })
      );
      yield put(setEventsLoading(false));
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
    yield put(setEventsLoading(false));
    console.error(error);
  }
}

function* updateCalendarEvent({ payload }) {
  try {
    yield put(setEventsLoading(true));
    const response = yield call(updateCalendarEventApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateEventSuccess(response?.data));
      yield put(
        setToastMessage({
          data:
            payload?.newEvent?.event_type == 0
              ? 'Event Updated Successfully'
              : 'Holiday Updated Successfully',
          severity: 'success',
        })
      );
      yield put(setEventsLoading(false));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.message?.non_field_errors,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
    console.error(error);
  }
}

function* getUserDetailsSaga({ payload }) {
  try {
    const response = yield call(getUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUserDetailsSuccess(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteCalendarEvent({ payload }) {
  try {
    yield put(setEventsLoading(true));
    const response = yield call(deleteCalendarEventApi, payload?.req.id);
    if (response?.status === statusCode.Ok200) {
      yield put(
        setToastMessage({
          data:
            payload?.req?.eventType === 1
              ? 'Holiday deleted successfully'
              : 'Event deleted successfully',
          severity: 'success',
        })
      );
      yield put(setEventsLoading(false));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* getCombinedListSaga() {
  try {
    const response = yield call(getCombinedListApi);
    if (response?.status === statusCode.Ok200) {
      yield put(getCombinedListSuccess(response?.data));
    } else {
      yield put(getCombinedListError(response?.error));
    }
  } catch (error) {
    yield put(getCombinedListError(error));
  }
}

function* getEmailListSaga({ payload }) {
  try {
    const response = yield call(getEmailListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getEmailListSuccess(response?.data));
    } else {
      yield put(getEmailListError(response?.error));
    }
  } catch (error) {
    yield put(getEmailListError(error));
  }
}

export function* watchDummy() {
  yield takeLatest(calendarActions.CALENDAR.get, getCalendarEvents);
  yield takeLatest(calendarActions.CALENDAR.create, createCalendarEvent);
  yield takeLatest(calendarActions.CALENDAR.update, updateCalendarEvent);
  yield takeLatest(calendarActions.CALENDAR.delete, deleteCalendarEvent);
  yield takeLatest(
    calendarActions.CALENDAR.get_combined_list,
    getCombinedListSaga
  );
  yield takeLatest(calendarActions.CALENDAR.get_email_list, getEmailListSaga);
  yield takeLatest(
    calendarActions.CALENDAR.get_user_details,
    getUserDetailsSaga
  );
}

export default function* calendarSaga() {
  yield all([watchDummy()]);
}
