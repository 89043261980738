import { SuperAdminActions } from 'store/SuperAdmin';

const INITIAL_STATE = {
  tenant: {
    loading: false,
    data: [],
    deleteUserData: '',
    updateUser: false,
  },
  tenantDetails: {
    loading: false,
    data: {},
    success: false,
    error: false,
  },
  tenantFileUpload: {
    loading: false,
    data: {},
  },
  tenantLogoUpload: {
    loading: false,
    data: {},
  },
  tenantLoginUpload: {
    loading: false,
    data: {},
  },
  tenantUserManualUpload: {
    loading: false,
    data: {},
  },
  createTenant: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
  deleteTenant: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
  editTenant: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
  activateTenant: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
  saveFirstData: {
    data: {},
  },
  saveSecondData: {
    data: {},
  },
  uploadAddAdmin: {
    loading: false,
    data: {},
  },
  tenant_admin: {
    loading: false,
    data: [],
    deleteAdminData: '',
    updateAdmin: false,
  },
  deleteAdmin: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
  createAdmin: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
  adminDetails: {
    loading: false,
    data: {},
    success: false,
    error: false,
  },
  activateAdmin: {
    data: {},
    loading: false,
    success: false,
    error: false,
  },
  dashboardDetails: {
    loading: false,
    data: {},
  },
  migrationList: {
    loading: false,
    data: [],
  },
  migrationAdmission: {
    loading: false,
    data: {},
  },
  migrationExam: {
    loading: false,
    data: {},
  },
  migrationAttendance: {
    loading: false,
    data: {},
  },
  migrationUserManagement: {
    loading: false,
    data: {},
  },
};

const SuperAdminReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    // Get All Tenants
    case SuperAdminActions.Tenant.GET_ALL_TENANTS_ACTION: {
      return {
        ...state,
        tenant: {
          ...state.tenant,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_ALL_TENANTS_SUCCESS: {
      return {
        ...state,
        tenant: {
          ...state.tenant,
          data: action?.payload || [],
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_ALL_TENANTS_ERROR: {
      return {
        ...state,
        tenant: {
          ...state.tenant,
          loading: false,
        },
      };
    }

    case SuperAdminActions.Tenant.GET_ALL_TENANTS_RESET: {
      return {
        ...state,
        tenant: {
          ...state.tenant,
          data: [],
        },
      };
    }
    // Get By ID
    case SuperAdminActions.Tenant.GET_TENANT_DETAILS: {
      return {
        ...state,
        tenantDetails: {
          ...state.tenantDetails,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_TENANT_DETAILS_SUCCESS: {
      return {
        ...state,
        tenantDetails: {
          ...state.tenantDetails,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_TENANT_DETAILS_ERROR: {
      return {
        ...state,
        tenantDetails: {
          ...state.tenantDetails,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_TENANT_DETAILS_RESET: {
      return {
        ...state,
        tenantDetails: {
          ...state.tenantDetails,
          data: {},
          success: '',
          error: '',
        },
      };
    }

    // Upload File
    case SuperAdminActions.Tenant.UPLOAD_TENANT_ASSETS: {
      return {
        ...state,
        tenantFileUpload: {
          ...state.tenantFileUpload,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_ASSETS_SUCCESS: {
      return {
        ...state,
        tenantFileUpload: {
          ...state.tenantFileUpload,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_ASSETS_ERROR: {
      return {
        ...state,
        tenantFileUpload: {
          ...state.tenantFileUpload,
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_ASSETS_RESET: {
      return {
        ...state,
        tenantFileUpload: {
          ...state.tenantFileUpload,
          data: {},
        },
      };
    }

    // Logo Upload
    case SuperAdminActions.Tenant.UPLOAD_TENANT_LOGO: {
      return {
        ...state,
        tenantLogoUpload: {
          ...state.tenantLogoUpload,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_LOGO_SUCCESS: {
      return {
        ...state,
        tenantLogoUpload: {
          ...state.tenantLogoUpload,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_LOGO_ERROR: {
      return {
        ...state,
        tenantLogoUpload: {
          ...state.tenantLogoUpload,
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_LOGO_RESET: {
      return {
        ...state,
        tenantLogoUpload: {
          ...state.tenantLogoUpload,
          data: {},
        },
      };
    }

    // Upload Login Image
    case SuperAdminActions.Tenant.UPLOAD_TENANT_LOGIN: {
      return {
        ...state,
        tenantLoginUpload: {
          ...state.tenantLoginUpload,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_LOGIN_SUCCESS: {
      return {
        ...state,
        tenantLoginUpload: {
          ...state.tenantLoginUpload,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_LOGIN_ERROR: {
      return {
        ...state,
        tenantLoginUpload: {
          ...state.tenantLoginUpload,
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_TENANT_LOGIN_RESET: {
      return {
        ...state,
        tenantLoginUpload: {
          ...state.tenantLoginUpload,
          data: {},
        },
      };
    }

    // Upload User Manual
    case SuperAdminActions.Tenant.UPLOAD_USER_MANUAL: {
      return {
        ...state,
        tenantUserManualUpload: {
          ...state.tenantUserManualUpload,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_USER_MANUAL_SUCCESS: {
      return {
        ...state,
        tenantUserManualUpload: {
          ...state.tenantUserManualUpload,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_USER_MANUAL_ERROR: {
      return {
        ...state,
        tenantUserManualUpload: {
          ...state.tenantUserManualUpload,
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.UPLOAD_USER_MANUAL_RESET: {
      return {
        ...state,
        tenantUserManualUpload: {
          ...state.tenantUserManualUpload,
          data: {},
        },
      };
    }

    // Create Tenant
    case SuperAdminActions.Tenant.CREATE_TENANT_ACTION: {
      return {
        ...state,
        createTenant: {
          ...state.createTenant,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.CREATE_TENANT_SUCCESS: {
      return {
        ...state,
        createTenant: {
          ...state.createTenant,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.CREATE_TENANT_ERROR: {
      return {
        ...state,
        createTenant: {
          ...state.createTenant,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.CREATE_TENANT_RESET: {
      return {
        ...state,
        createTenant: {
          ...state.createTenant,
          data: {},
          success: '',
          error: '',
        },
      };
    }

    // Delete Tenant
    case SuperAdminActions.Tenant.DELETE_TENANT_ACTION: {
      return {
        ...state,
        deleteTenant: {
          ...state.deleteTenant,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.DELETE_TENANT_SUCCESS: {
      return {
        ...state,
        deleteTenant: {
          ...state.deleteTenant,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.DELETE_TENANT_ERROR: {
      return {
        ...state,
        deleteTenant: {
          ...state.deleteTenant,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.DELETE_TENANT_RESET: {
      return {
        ...state,
        deleteTenant: {
          ...state.deleteTenant,
          data: {},
        },
      };
    }

    // Edit Tenant
    case SuperAdminActions.Tenant.EDIT_TENANT_ACTION: {
      return {
        ...state,
        editTenant: {
          ...state.editTenant,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.EDIT_TENANT_SUCCESS: {
      return {
        ...state,
        editTenant: {
          ...state.editTenant,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.EDIT_TENANT_ERROR: {
      return {
        ...state,
        editTenant: {
          ...state.editTenant,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.EDIT_TENANT_RESET: {
      return {
        ...state,
        editTenant: {
          ...state.editTenant,
          data: {},
          success: '',
          error: '',
        },
      };
    }

    // Activate Deactivate Tenant
    case SuperAdminActions.Tenant.ACTIVATE_TENANT_ACTION: {
      return {
        ...state,
        activateTenant: {
          ...state.activateTenant,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.ACTIVATE_TENANT_SUCCESS: {
      return {
        ...state,
        activateTenant: {
          ...state.activateTenant,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.ACTIVATE_TENANT_ERROR: {
      return {
        ...state,
        activateTenant: {
          ...state.activateTenant,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.ACTIVATE_TENANT_RESET: {
      return {
        ...state,
        activateTenant: {
          ...state.activateTenant,
          data: {},
          success: '',
          error: '',
        },
      };
    }

    // Save Data
    case SuperAdminActions.Tenant.SAVE_FIRST_FORMDATA_SUCCESS: {
      return {
        ...state,
        saveFirstData: {
          ...state.saveFirstData,
          data: action?.payload || {},
        },
      };
    }
    case SuperAdminActions.Tenant.SAVE_FIRST_FORMDATA_RESET: {
      return {
        ...state,
        saveFirstData: {
          ...state.saveFirstData,
          data: {},
        },
      };
    }

    case SuperAdminActions.Tenant.SAVE_SECOND_FORMDATA_SUCCESS: {
      return {
        ...state,
        saveSecondData: {
          ...state.saveSecondData,
          data: action?.payload || {},
        },
      };
    }
    case SuperAdminActions.Tenant.SAVE_SECOND_FORMDATA_RESET: {
      return {
        ...state,
        saveSecondData: {
          ...state.saveSecondData,
          data: {},
        },
      };
    }

    // Upload Add Admin Image
    case SuperAdminActions.Tenant.ADD_ADMIN_IMAGE: {
      return {
        ...state,
        uploadAddAdmin: {
          ...state.uploadAddAdmin,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.ADD_ADMIN_IMAGE_SUCCESS: {
      return {
        ...state,
        uploadAddAdmin: {
          ...state.uploadAddAdmin,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.ADD_ADMIN_IMAGE_ERROR: {
      return {
        ...state,
        uploadAddAdmin: {
          ...state.uploadAddAdmin,
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.ADD_ADMIN_IMAGE_RESET: {
      return {
        ...state,
        uploadAddAdmin: {
          ...state.uploadAddAdmin,
          data: {},
        },
      };
    }

    // Get All Admins
    case SuperAdminActions.Tenant.GET_ADMIN_ACTION: {
      return {
        ...state,
        tenant_admin: {
          ...state.tenant_admin,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_ADMIN_SUCCESS: {
      return {
        ...state,
        tenant_admin: {
          ...state.tenant_admin,
          data: action?.payload || [],
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_ADMIN_ERROR: {
      return {
        ...state,
        tenant_admin: {
          ...state.tenant_admin,
          loading: false,
        },
      };
    }

    case SuperAdminActions.Tenant.GET_ADMIN_RESET: {
      return {
        ...state,
        tenant_admin: {
          ...state.tenant_admin,
          data: [],
        },
      };
    }

    // Delete Admin
    case SuperAdminActions.Tenant.DELETE_ADMIN_ACTION: {
      return {
        ...state,
        deleteAdmin: {
          ...state.deleteAdmin,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        deleteAdmin: {
          ...state.deleteAdmin,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.DELETE_ADMIN_ERROR: {
      return {
        ...state,
        deleteAdmin: {
          ...state.deleteAdmin,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.DELETE_ADMIN_RESET: {
      return {
        ...state,
        deleteAdmin: {
          ...state.deleteAdmin,
          data: {},
        },
      };
    }

    // Create Admin
    case SuperAdminActions.Tenant.CREATE_ADMIN_ACTION: {
      return {
        ...state,
        createAdmin: {
          ...state.createAdmin,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.CREATE_ADMIN_SUCCESS: {
      return {
        ...state,
        createAdmin: {
          ...state.createAdmin,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.CREATE_ADMIN_ERROR: {
      return {
        ...state,
        createAdmin: {
          ...state.createAdmin,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.CREATE_ADMIN_RESET: {
      return {
        ...state,
        createAdmin: {
          ...state.createAdmin,
          data: {},
          success: '',
          error: '',
        },
      };
    }

    // Get By ID Admin
    case SuperAdminActions.Tenant.GET_ADMIN_DETAILS_ACTION: {
      return {
        ...state,
        adminDetails: {
          ...state.adminDetails,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_ADMIN_DETAILS_SUCCESS: {
      return {
        ...state,
        adminDetails: {
          ...state.adminDetails,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_ADMIN_DETAILS_ERROR: {
      return {
        ...state,
        adminDetails: {
          ...state.adminDetails,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_ADMIN_DETAILS_RESET: {
      return {
        ...state,
        adminDetails: {
          ...state.adminDetails,
          data: {},
          success: '',
          error: '',
        },
      };
    }

    // Activate Deactivate Tenant
    case SuperAdminActions.Tenant.ACTIVATE_ADMIN_ACTION: {
      return {
        ...state,
        activateAdmin: {
          ...state.activateAdmin,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.ACTIVATE_ADMIN_SUCCESS: {
      return {
        ...state,
        activateAdmin: {
          ...state.activateAdmin,
          data: action?.payload || {},
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case SuperAdminActions.Tenant.ACTIVATE_ADMIN_ERROR: {
      return {
        ...state,
        activateAdmin: {
          ...state.activateAdmin,
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    case SuperAdminActions.Tenant.ACTIVATE_ADMIN_RESET: {
      return {
        ...state,
        activateAdmin: {
          ...state.activateAdmin,
          data: {},
          success: '',
          error: '',
        },
      };
    }

    // Get Cards Details
    case SuperAdminActions.Tenant.GET_CARD_ITEM_ACTION: {
      return {
        ...state,
        dashboardDetails: {
          ...state?.dashboardDetails,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_CARD_ITEM_SUCCESS: {
      return {
        ...state,
        dashboardDetails: {
          ...state?.dashboardDetails,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.GET_CARD_ITEM_ERROR: {
      return {
        ...state,
        dashboardDetails: {
          ...state?.dashboardDetails,
          loading: false,
        },
      };
    }

    case SuperAdminActions.Tenant.GET_CARD_ITEM_RESET: {
      return {
        ...state,
        dashboardDetails: {
          ...state?.dashboardDetails,
          data: [],
        },
      };
    }

    // Migration Tenant List
    case SuperAdminActions.Tenant.MIGRATION_TENANT_LIST_ACTION: {
      return {
        ...state,
        migrationList: {
          ...state?.migrationList,
          loading: true,
        },
      };
    }
    case SuperAdminActions.Tenant.MIGRATION_TENANT_LIST_SUCCESS: {
      return {
        ...state,
        migrationList: {
          ...state?.migrationList,
          data: action?.payload || [],
          loading: false,
        },
      };
    }
    case SuperAdminActions.Tenant.MIGRATION_TENANT_LIST_ERROR: {
      return {
        ...state,
        migrationList: {
          ...state?.migrationList,
          loading: false,
        },
      };
    }

    case SuperAdminActions.Tenant.MIGRATION_TENANT_LIST_RESET: {
      return {
        ...state,
        migrationList: {
          ...state?.migrationList,
          data: [],
        },
      };
    }

    // Migration Database
    // Admission
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_ADMISSION_DB_ACTION: {
      return {
        ...state,
        migrationAdmission: {
          ...state?.migrationAdmission,
          loading: true,
        },
      };
    }
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_ADMISSION_DB_SUCCESS: {
      return {
        ...state,
        migrationAdmission: {
          ...state?.migrationAdmission,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_ADMISSION_DB_ERROR: {
      return {
        ...state,
        migrationAdmission: {
          ...state?.migrationAdmission,
          loading: false,
        },
      };
    }

    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_ADMISSION_DB_RESET: {
      return {
        ...state,
        migrationAdmission: {
          ...state?.migrationAdmission,
          data: {},
        },
      };
    }

    // Migration Database
    // Exam
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_EXAM_DB_ACTION: {
      return {
        ...state,
        migrationExam: {
          ...state?.migrationExam,
          loading: true,
        },
      };
    }
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_EXAM_DB_SUCCESS: {
      return {
        ...state,
        migrationExam: {
          ...state?.migrationExam,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_EXAM_DB_ERROR: {
      return {
        ...state,
        migrationExam: {
          ...state?.migrationExam,
          loading: false,
        },
      };
    }

    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_EXAM_DB_RESET: {
      return {
        ...state,
        migrationExam: {
          ...state?.migrationExam,
          data: {},
        },
      };
    }

    // Migration Database
    // Attendance
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_ACTION: {
      return {
        ...state,
        migrationAttendance: {
          ...state?.migrationAttendance,
          loading: true,
        },
      };
    }
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_SUCCESS: {
      return {
        ...state,
        migrationAttendance: {
          ...state?.migrationAttendance,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_ERROR: {
      return {
        ...state,
        migrationAttendance: {
          ...state?.migrationAttendance,
          loading: false,
        },
      };
    }

    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_RESET: {
      return {
        ...state,
        migrationAttendance: {
          ...state?.migrationAttendance,
          data: {},
        },
      };
    }

    // Migration Database
    // User Management
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_ACTION: {
      return {
        ...state,
        migrationUserManagement: {
          ...state?.migrationUserManagement,
          loading: true,
        },
      };
    }
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_SUCCESS: {
      return {
        ...state,
        migrationUserManagement: {
          ...state?.migrationUserManagement,
          data: action?.payload || {},
          loading: false,
        },
      };
    }
    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_ERROR: {
      return {
        ...state,
        migrationUserManagement: {
          ...state?.migrationUserManagement,
          loading: false,
        },
      };
    }

    case SuperAdminActions.MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_RESET: {
      return {
        ...state,
        migrationUserManagement: {
          ...state?.migrationUserManagement,
          data: {},
        },
      };
    }

    default:
      return state;
  }
};

export default SuperAdminReducer;
