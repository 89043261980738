import * as React from 'react';

const AssignmentSubjectIcon = ({
  fill,
  height = '24',
  width = '24',
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 15.302 17.002"
      fill={fill}
    >
      <path
        id="assignment_turned_in_FILL0_wght400_GRAD0_opsz48"
        d="M126.439-907.44l5.887-5.887-.914-.914-4.973,4.973-2.572-2.571-.893.893ZM121.275-903a1.228,1.228,0,0,1-.9-.375,1.228,1.228,0,0,1-.375-.9v-12.751a1.228,1.228,0,0,1,.375-.9,1.228,1.228,0,0,1,.9-.375h4.357a1.886,1.886,0,0,1,.68-1.222,2.028,2.028,0,0,1,1.339-.478,2.028,2.028,0,0,1,1.339.478,1.886,1.886,0,0,1,.68,1.222h4.357a1.228,1.228,0,0,1,.9.375,1.228,1.228,0,0,1,.375.9v12.751a1.228,1.228,0,0,1-.375.9,1.228,1.228,0,0,1-.9.375Zm0-1.275h12.751v-12.751H121.275Zm6.376-13.113a.713.713,0,0,0,.521-.223.714.714,0,0,0,.223-.521.714.714,0,0,0-.223-.521.714.714,0,0,0-.521-.223.714.714,0,0,0-.521.223.714.714,0,0,0-.223.521.714.714,0,0,0,.223.521A.714.714,0,0,0,127.651-917.386Zm-6.376,13.113v0Z"
        transform="translate(-120 920)"
      />
    </svg>
  );
};

export default AssignmentSubjectIcon;
