import * as React from 'react';

const SheildLargeSvg = ({ rank }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="32.42"
      viewBox="0 0 30 32.42"
    >
      <g
        id="Group_12978"
        data-name="Group 12978"
        transform="translate(-1980 -7883)"
      >
        <g id="shield-solid-badged" transform="translate(1974.667 7881.667)">
          <path
            id="Path_49577"
            data-name="Path 49577"
            d="M30.495,13.613a7.229,7.229,0,0,1-7.036-9.029A40.269,40.269,0,0,1,19.4,2.329L18.882,2l-.523.339a43.548,43.548,0,0,1-5.884,3.106A42.377,42.377,0,0,1,6.059,7.71l-.726.184v8.071c0,12.958,13.094,17.807,13.219,17.855l.329.116.329-.116c.135,0,13.219-4.887,13.219-17.855V13.342A7.248,7.248,0,0,1,30.495,13.613Z"
            transform="translate(0 -0.183)"
            fill="#02387a"
          />
          <circle
            id="Ellipse_870"
            data-name="Ellipse 870"
            cx="4.839"
            cy="4.839"
            r="4.839"
            transform="translate(25.656 1.333)"
            fill="#02387a"
          />
        </g>
        <text
          id="请选择"
          transform="translate(1987 7907)"
          fill="#fff"
          font-size="19"
          font-family="Poppins-Medium, Poppins"
          font-weight="500"
          letter-spacing="0em"
        >
          <tspan x="0" y="0">
            {rank}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default SheildLargeSvg;
