export const courseManagementActions = {
  CLUSTERS: {
    get: 'COURSE_MANAGEMENT_CLUSTERS_GET',
    get_success: 'COURSE_MANAGEMENT_CLUSTERS_GET_SUCCESS',
    get_incr: 'COURSE_MANAGEMENT_CLUSTERS_GET_INCR',
    get_success_incr: 'COURSE_MANAGEMENT_CLUSTERS_GET_SUCCESS_INCR',
    reset_incr: 'COURSE_MANAGEMENT_CLUSTERS_RESET_INCR',
    get_error: 'COURSE_MANAGEMENT_CLUSTERS_GET_ERROR',
    create: 'COURSE_MANAGEMENT_CLUSTERS_CREATE',
    create_success: 'COURSE_MANAGEMENT_CLUSTERS_CREATE_SUCCESS',
    create_error: 'COURSE_MANAGEMENT_CLUSTERS_CREATE_ERROR',
    edit: 'COURSE_MANAGEMENT_CLUSTERS_EDIT',
    edit_success: 'COURSE_MANAGEMENT_CLUSTERS_EDIT_SUCCESS',
    edit_error: 'COURSE_MANAGEMENT_CLUSTERS_EDIT_ERROR',
    delete: 'COURSE_MANAGEMENT_CLUSTERS_DELETE',
    delete_success: 'COURSE_MANAGEMENT_CLUSTERS_DELETE_SUCCESS',
    delete_error: 'COURSE_MANAGEMENT_CLUSTERS_DELETE_ERROR',
    loading: 'COURSE_MANAGEMENT_CLUSTERS_SET_LOADING',
    reset: 'RESET_CLUSTER_BY_ID',
    get_by_pagination: 'COURSE_MANAGEMENT_CLUSTERS_GET_BY_PAGINATION',
    get_by_pagination_success:
      'COURSE_MANAGEMENT_CLUSTERS_GET_BY_PAGINATION_SUCCESS',
    get_by_pagination_error:
      'COURSE_MANAGEMENT_CLUSTERS_GET_BY_PAGINATION_ERROR',
  },
  DEPARTMENT: {
    get: 'COURSE_MANAGEMENT_DEPARTMENT_GET',
    get_success: 'COURSE_MANAGEMENT_DEPARTMENT_GET_SUCCESS',
    get_incr: 'COURSE_MANAGEMENT_DEPARTMENTS_GET_INCR',
    get_success_incr: 'COURSE_MANAGEMENT_DEPARTMENTS_GET_SUCCESS_INCR',
    reset_incr: 'COURSE_MANAGEMENT_DEPARTMENTS_RESET_INCR',
    get_error: 'COURSE_MANAGEMENT_DEPARTMENT_GET_ERROR',
    get_by_cluster_id: 'COURSE_MANAGEMENT_DEPARTMENTS_GET_BY_CLUSTER_ID',
    get_by_cluster_id_success:
      'COURSE_MANAGEMENT_DEPARTMENTS_GET_BY_CLUSTER_ID_SUCCESS',
    get_by_cluster_id_error:
      'COURSE_MANAGEMENT_DEPARTMENTS_GET_BY_CLUSTER_ID_ERROR',
    create: 'COURSE_MANAGEMENT_DEPARTMENT_CREATE',
    create_success: 'COURSE_MANAGEMENT_DEPARTMENT_CREATE_SUCCESS',
    create_error: 'COURSE_MANAGEMENT_DEPARTMENT_CREATE_ERROR',
    edit: 'COURSE_MANAGEMENT_DEPARTMENT_EDIT',
    edit_success: 'COURSE_MANAGEMENT_DEPARTMENT_EDIT_SUCCESS',
    edit_error: 'COURSE_MANAGEMENT_DEPARTMENT_EDIT_ERROR',
    delete: 'COURSE_MANAGEMENT_DEPARTMENT_DELETE',
    delete_success: 'COURSE_MANAGEMENT_DEPARTMENT_DELETE_SUCCESS',
    delete_error: 'COURSE_MANAGEMENT_DEPARTMENT_DELETE_ERROR',
    loading: 'COURSE_MANAGEMENT_DEPARTMENT_SET_LOADING',
    reset: 'COURSE_MANAGEMENT_DEPARTMENT_RESET',
    get_by_cluster_id_by_pagination:
      'COURSE_MANAGEMENT_DEPARTMENTS_GET_BY_CLUSTER_ID_BY_PAGINATION',
    get_by_cluster_id_by_pagination_success:
      'COURSE_MANAGEMENT_DEPARTMENTS_GET_BY_CLUSTER_ID_SUCCESS_BY_PAGINATION',
    get_by_cluster_id_by_pagination_error:
      'COURSE_MANAGEMENT_DEPARTMENTS_GET_BY_CLUSTER_ID_ERROR_BY_PAGINATION',
  },
  DEPARTMENT_DETAILS: {
    get: 'COURSE_MANAGEMENT_DEPARTMENT_DETAILS_GET',
    get_success: 'COURSE_MANAGEMENT_DEPARTMENT_DETAILS_GET_SUCCESS',
    get_error: 'COURSE_MANAGEMENT_DEPARTMENT_DETAILS_GET_ERROR',
    loading: 'COURSE_MANAGEMENT_DEPARTMENT_DETAILS_SET_LOADING',
    reset: 'COURSE_MANAGEMENT_DEPARTMENT_DETAILS_RESET',
  },

  COURSE_LIST: {
    get: 'COURSE_MANAGEMENT_COURSE_LIST_GET',
    get_success: 'COURSE_MANAGEMENT_COURSE_LIST_GET_SUCCESS',
    get_error: 'COURSE_MANAGEMENT_COURSE_LIST_GET_ERROR',

    get_by_scroll: 'COURSE_MANAGEMENT_COURSE_LIST_GET_BY_SCROLL',
    get_by_scroll_success:
      'COURSE_MANAGEMENT_COURSE_LIST_GET__BY_SCROLL_SUCCESS',
    get_by_scroll_error: 'COURSE_MANAGEMENT_COURSE_LIST_GET__BY_SCROLL_ERROR',

    get_by_department_id: 'COURSE_MANAGEMENT_COURSE_LIST_GET_BY_DEPARTMENT_ID',
    get_by_department_id_success:
      'COURSE_MANAGEMENT_COURSE_LIST_GET_BY_DEPARTMENT_ID_SUCCESS',
    get_by_department_id_error:
      'COURSE_MANAGEMENT_COURSE_LIST_GET_BY_DEPARTMENT_ID_ERROR',

    reset_data: 'RESET_COURSE_BY_ID',

    loading: 'COURSE_MANAGEMENT_COURSE_LIST_SET_LOADING',
    reset: 'COURSE_MANAGEMENT_COURSE_LIST_RESET',

    save_feedback: 'COURSE_MANAGEMENT_COURSE_HOD_SAVE_FEEDBACK',
  },
  PROGRAM: {
    get: 'GET_PROGRAM_LIST',
    get_success: 'GET_PROGRAM_LIST_SUCCESS',

    get_by_id: 'GET_PROGRAM_DETAILS',
    get_success_by_id: 'GET_PROGRAM_DETAILS_SUCCESS',

    get_incr: 'GET_PROGRAM_LIST_INCR',
    get_success_incr: 'GET_PROGRAM_LIST_SUCCESS_INCR',
    reset_incr: 'GET_PROGRAM_LIST_RESET_INCR',

    create: 'CREATE_PROGRAM_ACTION',
    create_success: 'CREATE_PROGRAM_ACTION_SUCCESS',
    create_success_saveFeedback: 'CREATE_PROGRAM_ACTION_SUCCESS_feedback',
    create_error: 'CREATE_PROGRAM_ACTION_ERROR',

    update: 'UPDATE_PROGRAM_ACTION',
    update_success: 'UPDATE_PROGRAM_ACTION_SUCCESS',
    update_error: 'UPDATE_PROGRAM_ACTION_ERROR',

    patch: 'PATCH_PROGRAM_ACTION',
    patch_success: 'PATCH_PROGRAM_ACTION_SUCCESS',
    patch_error: 'PATCH_PROGRAM_ACTION_ERROR',

    delete: 'DELETE_PROGRAM_ACTION',
    delete_success: 'DELETE_PROGRAM_ACTION_SUCCESS',
    delete_error: 'DELETE_PROGRAM_ACTION_ERROR',

    get_programs_by_user_id: 'GET_PROGRAMS_BY_USER_ID',
    get_success_programs_by_user_id: 'GET_SUCCESS_PROGRAMS_BY_USER_ID',
    get_error_programs_by_user_id: 'GET_ERROR_PROGRAMS_BY_USER_ID',

    loading: 'SET_PROGRAM_LOADING',
  },

  PROGRAM_YEAR: {
    get: 'GET_PROGRAM_YEAR_LIST',
    get_success: 'GET_PROGRAM_YEAR_LIST_SUCCESS',

    get_by_id: 'GET_PROGRAM_YEAR_DETAILS',
    get_success_by_id: 'GET_PROGRAM_YEAR_DETAILS_SUCCESS',

    create: 'CREATE_PROGRAM_YEAR_ACTION',
    create_success: 'CREATE_PROGRAM_YEAR_ACTION_SUCCESS',
    create_error: 'CREATE_PROGRAM_YEAR_ACTION_ERROR',

    update: 'UPDATE_PROGRAM_YEAR_ACTION',
    update_success: 'UPDATE_PROGRAM_YEAR_ACTION_SUCCESS',
    update_error: 'UPDATE_PROGRAM_YEAR_ACTION_ERROR',

    delete: 'DELETE_PROGRAM_YEAR_ACTION',
    delete_success: 'DELETE_PROGRAM_YEAR_ACTION_SUCCESS',
    delete_error: 'DELETE_PROGRAM_YEAR_ACTION_ERROR',

    loading: 'SET_PROGRAM_YEAR_LOADING',
  },

  SEMESTER: {
    get: 'GET_SEMESTER_LIST',
    get_success: 'GET_SEMESTER_LIST_SUCCESS',

    get_by_id: 'GET_SEMESTER_DETAILS',
    get_success_by_id: 'GET_SEMESTER_DETAILS_SUCCESS',

    create: 'CREATE_SEMESTER_ACTION',
    create_success: 'CREATE_SEMESTER_ACTION_SUCCESS',
    create_error: 'CREATE_SEMESTER_ACTION_ERROR',

    update: 'UPDATE_SEMESTER_ACTION',
    update_success: 'UPDATE_SEMESTER_ACTION_SUCCESS',
    update_error: 'UPDATE_SEMESTER_ACTION_ERROR',

    delete: 'DELETE_SEMESTER_ACTION',
    delete_success: 'DELETE_SEMESTER_ACTION_SUCCESS',
    delete_error: 'DELETE_SEMESTER_ACTION_ERROR',

    loading: 'SET_SEMESTER_LOADING',
  },

  SUBJECT: {
    get: 'GET_SUBJECT_LIST',
    get_success: 'GET_SUBJECT_LIST_SUCCESS',

    get_by_id: 'GET_SUBJECT_DETAILS',
    get_success_by_id: 'GET_SUBJECT_DETAILS_SUCCESS',

    get_incr: 'GET_SUBJECT_LIST_INCR',
    get_success_incr: 'GET_SUBJECT_LIST_SUCCESS_INCR',
    reset_incr: 'GET_SUBJECT_LIST_RESET_INCR',

    create: 'CREATE_SUBJECT_ACTION',
    create_success: 'CREATE_SUBJECT_ACTION_SUCCESS',
    create_error: 'CREATE_SUBJECT_ACTION_ERROR',

    update: 'UPDATE_SUBJECT_ACTION',
    update_success: 'UPDATE_SUBJECT_ACTION_SUCCESS',
    update_error: 'UPDATE_SUBJECT_ACTION_ERROR',

    delete: 'DELETE_SUBJECT_ACTION',
    delete_success: 'DELETE_SUBJECT_ACTION_SUCCESS',
    delete_error: 'DELETE_SUBJECT_ACTION_ERROR',

    loading: 'SET_SUBJECT_LOADING',
  },

  FACULTY: {
    loading: 'COURSE_MANAGEMENT_FACULTY_SET_LOADING',
    reset: 'COURSE_MANAGEMENT_FACULTY_RESET',
  },

  MODULE: {
    reset: 'COURSE_MANAGEMENT_MODULE_RESET',

    save_feedback: 'COURSE_MANAGEMENT_COURSE_MODULE_SAVE_FEEDBACK',
    loading: 'COURSE_MANAGEMENT_COURSE_MODULE_LOADING',
  },

  SUB_MODULE: {
    reset: 'COURSE_MANAGEMENT_SUB_MODULE_RESET',

    save_feedback: 'COURSE_MANAGEMENT_COURSE_SUB_MODULE_SAVE_FEEDBACK',
    loading: 'COURSE_MANAGEMENT_COURSE_SUB_MODULE_LOADING',
  },
  LIVE_SESSION: {
    create: 'COURSE_MANAGEMENT_LIVE_SESSION_CREATE',
    create_success: 'COURSE_MANAGEMENT_LIVE_SESSION_CREATE_SUCCESS',
    create_error: 'COURSE_MANAGEMENT_LIVE_SESSION_CREATE_ERROR',
    created_session_success_data:
      'COURSE_MANAGEMENT_LIVE_SESSION_CREATED_SUCCESS_DATA',

    edit: 'COURSE_MANAGEMENT_LIVE_SESSION_EDIT',
    edit_success: 'COURSE_MANAGEMENT_LIVE_SESSION_EDIT_SUCCESS',
    edit_error: 'COURSE_MANAGEMENT_LIVE_SESSION_EDIT_ERROR',

    get_by_id: 'COURSE_MANAGEMENT_LIVE_SESSION_GET_BY_ID',
    get_by_id_success: 'COURSE_MANAGEMENT_LIVE_SESSION_GET_BY_ID_SUCCESS',
    get_by_id_error: 'COURSE_MANAGEMENT_LIVE_SESSION_GET_BY_ID_ERROR',

    get_by_submoduleId: 'COURSE_MANAGEMENT_LIVE_SESSION_GET_by_SUBMODULE_ID',
    get_by_submoduleId_success:
      'COURSE_MANAGEMENT_LIVE_SESSION_GET_by_SUBMODULE_ID_SUCCESS',
    get_by_submoduleId_error:
      'COURSE_MANAGEMENT_LIVE_SESSION_GET_by_SUBMODULE_ID_ERROR',

    loading: 'COURSE_MANAGEMENT_LIVE_SESSION_LOADING',
  },
  RUBRICS: {
    get: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_GET',
    get_success: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_GET_SUCCESS',
    create: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_CREATE',
    create_success: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_CREATE_SUCCESS',
    create_error: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_CREATE_ERROR',
    loading: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_LOADING',
    edit: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_EDIT',
    edit_success: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_EDIT_SUCCESS',
    save_feedback: 'COURSE_MANAGEMENT_RUBRIC_CONFIG_SAVE_FEEDBACK',
    reset: 'RESET_ALL_RUBRIC_DATA',
  },
  GRADE_POINTS: {
    get_by_id: 'GET_GRADE_POINT_DETAILS',
    get_success_by_id: 'GET_GRADE_POINT_DETAILS_SUCCESS',
    create: 'CREATE_GRADE_POINT_ACTION',
    create_success: 'CREATE_GRADE_POINT_ACTION_SUCCESS',
    create_error: 'CREATE_GRADE_POINT_ACTION_ERROR',
    update: 'UPDATE_GRADE_POINT_ACTION',
    update_success: 'UPDATE_GRADE_POINT_ACTION_SUCCESS',
    update_error: 'UPDATE_GRADE_POINT_ACTION_ERROR',
    save_feedback: 'COURSE_MANAGEMENT_GRADE_POINTS_SAVE_FEEDBACK',
    loading: 'SET_GRADE_POINT_LOADING',
  },

  RUBRIC_TABLE: {
    get: 'COURSE_MANAGEMENT_RUBRIC_TABLE_GET',
    get_success: 'COURSE_MANAGEMENT_RUBRIC_TABLE_GET_SUCCESS',
    create: 'COURSE_MANAGEMENT_RUBRIC_TABLE_CREATE',
    create_success: 'COURSE_MANAGEMENT_RUBRIC_TABLE_CREATE_SUCCESS',
    create_error: 'COURSE_MANAGEMENT_RUBRIC_TABLE_CREATE_ERROR',
    loading: 'COURSE_MANAGEMENT_RUBRIC_TABLE_LOADING',
    edit: 'COURSE_MANAGEMENT_RUBRIC_TABLE_EDIT',
    edit_success: 'COURSE_MANAGEMENT_RUBRIC_TABLE__EDIT_SUCCESS',
  },
  CBC_LIST: {
    get: 'COURSE_MANAGEMENT_CBC_LIST_GET',
    get_success: 'COURSE_MANAGEMENT_CBC_LIST_GET_SUCCESS',
  },

  RESET: {
    reset_all: 'RESET_ALL',
  },

  FACULTY_SUBJECT_LINK: {
    faculty_subject_link: 'FACULTY_SUBJECT_LINK',
    faculty_subject_link_success: 'FACULTY_SUBJECT_LINK_SUCCESS',
    faculty_subject_link_error: 'FACULTY_SUBJECT_LINK_ERROR',
    get_faculty_subject_details: 'GET_FACULTY_SUBJECT_DETAILS',
    get_faculty_subject_details_success: 'GET_FACULTY_SUBJECT_DETAILS_SUCCESS',
    get_faculty_subject_details_error: 'GET_FACULTY_SUBJECT_DETAILS_ERROR',
    delete_faculty_subject_link: 'DELETE_FACULTY_SUBJECT_LINK',
    delete_faculty_subject_link_success: 'DELETE_FACULTY_SUBJECT_LINK_SUCCESS',
    delete_faculty_subject_link_error: 'DELETE_FACULTY_SUBJECT_LINK_ERROR',
    edit_faculty_subject_link_details: 'EDIT_FACULTY_SUBJECT_LINK_DETAILS',
    edit_faculty_subject_link_details_success:
      'EDIT_FACULTY_SUBJECT_LINK_DETAILS_SUCCESS',
    edit_faculty_subject_link_details_error:
      'EDIT_FACULTY_SUBJECT_LINK_DETAILS_ERROR',
    reset_faculty_subject_details: 'RESET_FACULTY_SUBJECT_DETAILS',
  },

  USER_MAPPING_TO_PROGRAM_SUBJECT: {
    // Get user mapping by user id
    GET_USER_MAPPING_BY_USER_ID_REQUEST: 'GET_USER_MAPPING_BY_USER_ID_REQUEST',
    GET_USER_MAPPING_BY_USER_ID_SUCCESS: 'GET_USER_MAPPING_BY_USER_ID_SUCCESS',
    GET_USER_MAPPING_BY_USER_ID_ERROR: 'GET_USER_MAPPING_BY_USER_ID_ERROR',
    GET_USER_MAPPING_BY_USER_ID_RESET: 'GET_USER_MAPPING_BY_USER_ID_RESET',

    // Update user mapping
    UPDATE_USER_MAPPING_BY_USER_ID_REQUEST:
      'UPDATE_USER_MAPPING_BY_USER_ID_REQUEST',
    UPDATE_USER_MAPPING_BY_USER_ID_SUCCESS:
      'UPDATE_USER_MAPPING_BY_USER_ID_SUCCESS',
    UPDATE_USER_MAPPING_BY_USER_ID_ERROR:
      'UPDATE_USER_MAPPING_BY_USER_ID_ERROR',
    UPDATE_USER_MAPPING_BY_USER_ID_RESET:
      'UPDATE_USER_MAPPING_BY_USER_ID_RESET',
  },

  // Join Session Virtutor API
  JOIN_SESSION_VIRTUTOR: {
    JOIN_SESSION_VIRTUTOR_CREATE: 'JOIN_SESSION_VIRTUTOR_CREATE',
    JOIN_SESSION_VIRTUTOR_SUCCESS: 'JOIN_SESSION_VIRTUTOR_SUCCESS',
    JOIN_SESSION_VIRTUTOR_ERROR: 'JOIN_SESSION_VIRTUTOR_ERROR',
    JOIN_SESSION_VIRTUTOR_RESET: 'JOIN_SESSION_VIRTUTOR_RESET',
    JOIN_SESSION_VIRTUTOR_LOADER: 'JOIN_SESSION_VIRTUTOR_LOADER',
  },

  //Create vision for department
  VISION_FOR_DEPARTMENT: {
    VISION_FOR_DEPARTMENT_CREATE: 'VISION_FOR_DEPARTMENT_CREATE',
    VISION_FOR_DEPARTMENT_SUCCESS: 'VISION_FOR_DEPARTMENT_SUCCESS',
    VISION_FOR_DEPARTMENT_ERROR: 'VISION_FOR_DEPARTMENT_ERROR',
    VISION_FOR_DEPARTMENT_RESET: 'VISION_FOR_DEPARTMENT_RESET',
  },

  //Create misision for department
  MISSION_FOR_DEPARTMENT: {
    MISSION_FOR_DEPARTMENT_CREATE: 'MISSION_FOR_DEPARTMENT_CREATE',
    MISSION_FOR_DEPARTMENTT_SUCCESS: 'MISSION_FOR_DEPARTMENTT_SUCCESS',
    MISSION_FOR_DEPARTMENT_ERROR: 'MISSION_FOR_DEPARTMENT_ERROR',
    MISSION_FOR_DEPARTMENT_RESET: 'MISSION_FOR_DEPARTMENT_RESET',
  },

  //Download for Vision
  DOWNLOAD_FOR_VISION: {
    DOWNLOAD_VISION_REQUEST: 'DOWNLOAD_VISION_REQUEST',
    DOWNLOAD_VISION_SUCCESS: 'DOWNLOAD_VISION_SUCCESS',
    DOWNLOAD_VISION_ERROR: 'DOWNLOAD_VISION_ERROR',
    DOWNLOAD_VISION_RESET: 'DOWNLOAD_VISION_RESET',
  },

  //Download for Mission
  DOWNLOAD_FOR_MISSION: {
    DOWNLOAD_MISSION_REQUEST: 'DOWNLOAD_MISSION_REQUEST',
    DOWNLOAD_MISSION_SUCCESS: 'DOWNLOAD_MISSION_SUCCESS',
    DOWNLOAD_MISSION_ERROR: 'DOWNLOAD_MISSION_ERROR',
    DOWNLOAD_MISSION_RESET: 'DOWNLOAD_MISSION_RESET',
  },

  //Vission For Program
  VISION_PROGRAM: {
    GET_UNIVERSITY_VISSION: 'GET_UNIVERSITY_VISSION',
    GET_UNIVERSITY_VISSION_SUCCESS: 'GET_UNIVERSITY_VISSION_SUCCESS',
    GET_UNIVERSITY_VISSION_ERROR: 'GET_UNIVERSITY_VISSION_ERROR',

    CREATE_UNIVERSITY_VISSION: 'CREATE_UNIVERSITY_VISSION',
    CREATE_UNIVERSITY_VISSION_SUCCESS: 'CREATE_UNIVERSITY_VISSION_SUCCESS',
    CREATE_UNIVERSITY_VISSION_ERROR: 'CREATE_UNIVERSITY_VISSION_ERROR',
  },
  MISSION_PROGRAM: {
    GET_UNIVERSITY_MISSION: 'GET_UNIVERSITY_MISSION',
    GET_UNIVERSITY_MISSION_SUCCESS: 'GET_UNIVERSITY_MISSION_SUCCESS',
    GET_UNIVERSITY_MISSION_ERROR: 'GET_UNIVERSITY_MISSION_ERROR',

    CREATE_UNIVERSITY_MISSION: 'CREATE_UNIVERSITY_MISSION',
    CREATE_UNIVERSITY_MISSION_SUCCESS: 'CREATE_UNIVERSITY_MISSION_SUCCESS',
    CREATE_UNIVERSITY_MISSION_ERROR: 'CREATE_UNIVERSITY_MISSION_ERROR',
  },

  COURSE_OUTCOME: {
    GET_COURSE_OUTCOME: 'GET_COURSE_OUTCOME',
    GET_COURSE_OUTCOME_SUCCESS: 'GET_COURSE_OUTCOME_SUCCESS',
    GET_COURSE_OUTCOME_ERROR: 'GET_COURSE_OUTCOME_ERROR',
  },

  SEND_APPROVAL: {
    SEND_APPROVAL_ACTION: 'SEND_APPROVAL_ACTION',
    SEND_APPROVAL_SUCCESS: 'SEND_APPROVAL_SUCCESS',
    SEND_APPROVAL_ERROR: 'SEND_APPROVAL_ERROR',
    SEND_APPROVAL_RESET: 'SEND_APPROVAL_RESET',
  },
  BULK_DELETE: {
    BULK_DELETE_ACTION: 'BULK_DELETE_ACTION',
    BULK_DELETE_SUCCESS: 'BULK_DELETE_SUCCESS',
    BULK_DELETE_ERROR: 'BULK_DELETE_ERROR',
    BULK_DELETE_RESET: 'BULK_DELETE_RESET',
  },
  UPDATE_SCHEMA: {
    UPDATE_SCHEMA_REQUEST: 'UPDATE_SCHEMA_REQUEST',
    UPDATE_SCHEMA_SUCCESS: 'UPDATE_SCHEMA_SUCCESS',
    UPDATE_SCHEMA_ERROR: 'UPDATE_SCHEMA_ERROR',
    UPDATE_SCHEMA_RESET: 'UPDATE_SCHEMA_RESET',
  },
};
