import * as React from 'react';

function ClockTimeIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <g fill="#039be5">
        <path
          data-name="Path 43731"
          d="M11.083 8.068h3.795v9.487h9.487v3.795H11.083z"
        />
        <path
          data-name="Path 43732"
          d="M32 16A16 16 0 1116 0a16 16 0 0116 16zm-3.2 0A12.8 12.8 0 1116 3.2 12.8 12.8 0 0128.8 16z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default ClockTimeIcon;
