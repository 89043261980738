import { takeLatest, put, call, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import {
  getCourseListAPI,
  getFeedbackListAPI,
  postFeedbackApi,
} from './Feedback.api';
// import {feedbackActions} from './Feedback.action';
import { feedbackActions } from './Feedback.constants';
import { setToastMessage } from 'store/Toast';
import {
  createStudentFeedbackError,
  createStudentFeedbackSuccess,
  getErrorFeedBackList,
  getFeedbackCourseListError,
  getFeedbackCourseListSuccess,
  getFeedbackLoading,
  getSuccessFeedBackList,
} from './Feedback.action';

function* getFeedbackListSaga({ payload }) {
  try {
    yield put(getFeedbackLoading(true));
    const response = yield call(getFeedbackListAPI, payload);
    yield put(getFeedbackLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessFeedBackList(response?.data));
    } else {
      yield put(getErrorFeedBackList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* sendStudentFeedbackSaga({ payload }) {
  try {
    yield put(getFeedbackLoading(true));
    const response = yield call(postFeedbackApi, payload);
    yield put(getFeedbackLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createStudentFeedbackSuccess(response?.data));
      yield put(
        setToastMessage({
          data: "Feedback Submitted Successfully",
          severity: 'success',
        })
      );
    } else {
      yield put(createStudentFeedbackError(response?.data));
      yield put(
        setToastMessage({
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getCourseListSaga({ payload }) {
  try {
    yield put(getFeedbackLoading(true));
    const response = yield call(getCourseListAPI, payload);
    yield put(getFeedbackLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getFeedbackCourseListSuccess(response?.data));
    } else {
      yield put(getFeedbackCourseListError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchDummy() {
  yield takeLatest(feedbackActions.FEEDBACK.GET_LIST, getFeedbackListSaga);
  yield takeLatest(
    feedbackActions.FEEDBACK.POST_FFEDBACK,
    sendStudentFeedbackSaga
  );
  yield takeLatest(feedbackActions.FEEDBACK.GET_COURSE_LIST, getCourseListSaga);
}

export default function* feedbackSaga() {
  yield all([watchDummy()]);
}
