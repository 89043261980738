import * as React from 'react';

const PendingIconSvg = ({ height = '40', width = '40', fill = '#BC0017' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 29.544 29.544"
    >
      <g
        id="Group_13116"
        data-name="Group 13116"
        transform="translate(-20 -52.711)"
      >
        <path
          fill={fill}
          id="inventory_FILL0_wght400_GRAD0_opsz48"
          d="M16.283,34.833H6.8a2.175,2.175,0,0,1-1.587-.68,2.175,2.175,0,0,1-.68-1.587V10.578a2.357,2.357,0,0,1,.586-1.587q.586-.68,1-.68h7.631a3.788,3.788,0,0,1,6.1-2.172,3.651,3.651,0,0,1,1.3,2.172h7.631a2.324,2.324,0,0,1,2.267,2.267v7.669H28.788V10.578h-4V15.49H10.8V10.578h-4V32.566h9.483Zm7.14-.944-6.045-6.045L19,26.219l4.42,4.42,9.029-9.029,1.625,1.625ZM18.134,10.427a1.523,1.523,0,0,0,1.077-2.588,1.523,1.523,0,0,0-2.153,2.153A1.462,1.462,0,0,0,18.134,10.427Z"
          transform="translate(15.466 47.422)"
        />
      </g>
    </svg>
  );
};

export default PendingIconSvg;
