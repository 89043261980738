import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommonModal, Rating } from 'components';
import { DataGrid, Box } from 'iiht-b2a-ui-components/build/components';

const CumulativeAcademicRating = ({
  tableRows,
  visible,
  modalCloseHandler,
  totalRating,
}) => {
  const { t } = useTranslation();
  //later take rows from component calling api for cumulative rating
  const tableColumns = [
    {
      field: 'userName',
      headerName: t('studentManagement.name'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'designation',
      headerName: t('userProfile.designation'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'rating',
      headerName: t('studentManagement.ratings'),
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Rating
          name="simple-controlled"
          value={params?.row?.rating}
          readOnly
          className="pr2"
          size="medium"
        />
      ),
    },
  ];
  const tableRowsDummy = [
    {
      id: 1,
      userName: 'Dr. Sukumar Banerjee',
      designation: 'Faculty',
      rating: 4,
    },
    {
      id: 2,
      userName: 'Leena Das',
      designation: 'Faculty',
      rating: 4,
    },
    {
      id: 3,
      userName: 'Sudhanshu Sohoni',
      designation: 'HOD',
      rating: 4,
    },
  ];

  return (
    <CommonModal
      open={visible}
      onClose={modalCloseHandler}
      modalTitle={t('studentManagement.cumulativeRating')}
      cancelBtnName={t('cancel')}
      submitBtnName={t('submit')}
      maxWidth={'sm'}
      fullWidth
      onSubmit={modalCloseHandler}
    >
      <Rating
        name="simple-controlled"
        value={totalRating}
        readOnly
        className="pr2"
        size="large"
      />
      <Box sx={{ height: 350, width: '100%' }}>
        <DataGrid columns={tableColumns} rows={tableRowsDummy} pageSize={10} />
      </Box>
    </CommonModal>
  );
};

export default CumulativeAcademicRating;
