export const MoocAdminAPI = {
  getMoocType: '/v1/mooc-type',
  getStats: '',
  getCoursesToAffiliate:
    'https://staging-api-uat.techademy.com/api/web-portal/explore/',
  getCourseDetails:
    'https://staging-api-uat.techademy.com/api/web-portal/explore/',
  searchCourse:
    'https://staging-api-uat.techademy.com/api/web-portal/explore/search/',
  getAffiliatedOrPublishCourses: '/v1/course-list',
  affiliateOrPublishMoocCourse: '/v1/mooc',
  getFilters:
    'https://staging-api-uat.techademy.com/api/web-portal/explore/meta',
  moocCourseStatus: '/v1/mooc-status',
  enrollMoocCourse: '/v1/mooc-student',
  //Get All Courses
  getCourses: 'v1/mooc-course',
  //Update course
  updateCourse: '',
  //Delete course
  deleteCourse: '',
  moocSettings: 'v1/mooc-settings',
  postFileUpload: 'v1/upload-image',

  //Enrolled Courses
  getEnrolledCourse: '/v1/mooc-student',
  moocAdminDashboard: '/v1/mooc-dashboard',

  studentList: '/v1/student-list',
  assessmentLink: 'v1/mooc-assessment',

  // Get Video Notes
  videoNotes: '/v1/mooc-module-notes',

  downloadCertificate: '/v1/download-certificate',

  //Learning Path
  learningPathToPublish: '',
  publishLearningPath: '',

  // lp path
  getLearningPath: 'v1/learning-path',
  courseListDropdown: 'v1/learning-course',
  createLP: 'v1/learning-path',

  //LP in student flow
  myLearningPath: 'v1/learning-student',
  enrollLearningPath: 'v1/learning-student',
  lpCourseStatus: '/v1/learning-status',
};
