import { takeLatest, put, call, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';
import {
  getMappedDepartmentListError,
  getMappedDepartmentListSuccess,
  getMappedYearListApi,
  getProgramForFacultyError,
  getProgramForFacultySuccess,
  getProgramListforFacultyApi,
  getSubjectStatsApi,
  getSubjectStatsError,
  getSubjectStatsSuccess,
  getYearListError,
  getYearListSuccess,
  programContentActions,
  getSemesterListError,
  getSemesterListSuccess,
  getMappedSemesterListApi,
  getChapterListBySubjectApi,
  getChapterListBySubjectError,
  getChapterListBySubjectSuccess,
  getSubjectListError,
  getSubjectListSuccess,
  getSubjectListApi,
  getChapterContentApi,
  getChapterContentSuccess,
  getChapterContentError,
  getProgramDetailsError,
  getProgramDetailsSuccess,
  getProgramDetailsApi,
  getMappedDepartmentListApi,
  addPracticalContentApi,
  addSuccessPracticalContent,
  addErrorPracticalContent,
  addVideoContentApi,
  addSuccessVideoContent,
  addErrorVideoContent,
  fileUploadApi,
  uploadFileSuccess,
  uploadTranscriptFileSuccess,
  setIsLoading,
  createReadingSuccess,
  createReadingContentApi,
  getPracticalContentApi,
  getSuccessPracticalContent,
  getErrorPracticalContent,
  updatePracticalContentApi,
  updateSuccessPracticalContent,
  updateErrorPracticalContent,
  getVideoContentApi,
  getSuccessVideoContent,
  getErrorVideoContent,
  updateVideoContentApi,
  updateSuccessVideoContent,
  updateErrorVideoContent,
  deleteVideoContentApi,
  deleteSuccessVideoContent,
  deleteErrorVideoContent,
  deletePracticalContentApi,
  deleteSuccessPracticalContent,
  deleteErrorPracticalContent,
  addChapterApi,
  addChapterSuccess,
  addChapterError,
  getChapterDetailsByIdSuccess,
  getChapterDetailsByIdError,
  getChapterDetailById,
  updateChapterApi,
  updateChapterSuccess,
  updateChapterError,
  getFacultyListBySubjectId,
  updateReadingContentApi,
  deleteReadingContentApi,
  deleteReadingSuccess,
  deleteChapterApi,
  deleteChapterSuccess,
  deleteChapterError,
  deleteLiveSessionApi,
  deleteLiveSessionSuccess,
  deleteLiveSessionError,
  getFacultyListBySubjectIdSuccess,
  getFacultyListBySubjectIdError,
  getFacultyListBySubjectIdApi,
  getUserDepartmentsApi,
  getUserDepartmentListSuccess,
  getUserDepartmentListError,
  getUserProgramsApi,
  getUserProgramListSuccess,
  getUserProgramListError,
  getReadingContentApi,
  getReadingSuccess,
  getReadingError,
  getLabListApi,
  getLabListSuccess,
  getLabListError,
  createVmApi,
  createVmSuccess,
  createVmError,
  getVmStateApi,
  getVmStateSuccess,
  getVmStateError,
  getChapterListBySubjectLoader,
  videoContentLoader,
  uploadFileError,
} from 'store/ProgramContent';
import { func } from 'prop-types';

function* getDeparmentListSaga({ payload }) {
  try {
    const response = yield call(getMappedDepartmentListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getMappedDepartmentListSuccess(response?.data));
    } else {
      yield put(getMappedDepartmentListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getMappedDepartmentListError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getProgramListForFacultySaga({ payload }) {
  try {
    const response = yield call(getProgramListforFacultyApi, payload);
    yield put(getProgramForFacultySuccess({}));
    if (response?.status === statusCode.Ok200) {
      yield put(getProgramForFacultySuccess(response?.data));
    } else {
      yield put(getProgramForFacultyError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getProgramForFacultyError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getYearListSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getMappedYearListApi, payload);
    yield put(setIsLoading(false));
    yield put(getYearListSuccess({ results: [] }));
    if (response?.status === statusCode.Ok200) {
      yield put(getYearListSuccess(response?.data));
    } else {
      yield put(getYearListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getYearListError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getSubjectStatsSaga({ params, payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getSubjectStatsApi, params, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSubjectStatsSuccess(response?.data));
      yield put(setIsLoading(false));
    } else {
      yield put(getSubjectStatsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
      yield put(setIsLoading(false));
    }
  } catch (error) {
    yield put(setIsLoading(false));
    yield put(getSubjectStatsError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getSemesterListSaga({ payload }) {
  try {
    const response = yield call(getMappedSemesterListApi, payload);
    yield put(getSemesterListSuccess({ results: [] }));
    if (response?.status === statusCode.Ok200) {
      yield put(getSemesterListSuccess(response?.data));
    } else {
      yield put(getSemesterListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getSemesterListError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getSubjectListSaga({ payload }) {
  try {
    const response = yield call(getSubjectListApi, payload);
    yield put(getSubjectListSuccess({ results: [] }));
    if (response?.status === statusCode.Ok200) {
      yield put(getSubjectListSuccess(response?.data));
    } else {
      yield put(getSubjectListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getSubjectListError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getChapterListBySubjectSaga({ payload }) {
  try {
    yield put(getChapterListBySubjectLoader(true));
    const response = yield call(getChapterListBySubjectApi, payload);
    yield put(getChapterListBySubjectSuccess({ results: [] }));
    if (response?.status === statusCode.Ok200) {
      yield put(getChapterListBySubjectSuccess(response?.data));
    } else {
      yield put(getChapterListBySubjectError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(getChapterListBySubjectLoader(false));
  } catch (error) {
    yield put(getSubjectListError(error?.data));
    yield put(getChapterListBySubjectLoader(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getChapterContentSaga({ payload, params }) {
  try {
    yield put(setIsLoading(true));
    yield put(
      getChapterContentSuccess({
        chapter_content: [],
        chapter_credit_points: 0,
        chapter_details: [],
        chapter_name: '',
        objective: '',
        content_creator: '',
      })
    );
    const response = yield call(getChapterContentApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getChapterContentSuccess(response?.data));
      yield put(setIsLoading(false));
    } else {
      yield put(getChapterContentError(response?.data));
      // yield put(
      //   setToastMessage({
      //     data: response?.data?.error?.message,
      //     severity: 'error',
      //   })
      // );
      yield put(setIsLoading(false));
    }
  } catch (error) {
    yield put(setIsLoading(false));
    yield put(getChapterContentError(error?.data));
    // yield put(
    //   setToastMessage({
    //     data: error?.data?.error?.message,
    //     severity: 'error',
    //   })
    // );
  }
}

function* createReadingContent({ payload }) {
  try {
    const response = yield call(createReadingContentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createReadingSuccess(response));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* udpateReadingContent({ payload }) {
  try {
    const response = yield call(updateReadingContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(createReadingSuccess(response));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteReadingContent({ payload }) {
  try {
    const response = yield call(deleteReadingContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteReadingSuccess(response));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* getPrgramDetailsSaga({ payload }) {
  try {
    const response = yield call(getProgramDetailsApi, payload);
    yield put(getProgramDetailsSuccess({ results: {} }));
    if (response?.status === statusCode.Ok200) {
      yield put(getProgramDetailsSuccess(response?.data));
    } else {
      yield put(getProgramDetailsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getProgramDetailsError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* addPracticalContentSaga({ payload }) {
  try {
    const response = yield call(addPracticalContentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(addSuccessPracticalContent(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(addErrorPracticalContent(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(addErrorPracticalContent(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getPracticalContentSaga({ payload }) {
  try {
    const response = yield call(getPracticalContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessPracticalContent(response?.data));
    } else {
      yield put(getErrorPracticalContent(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getErrorPracticalContent(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getVideoContentSaga({ payload, params }) {
  try {
    const response = yield call(getVideoContentApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessVideoContent(response?.data));
    } else {
      yield put(getErrorVideoContent(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getErrorVideoContent(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* updatePracticalContentSaga({ id, payload }) {
  try {
    const response = yield call(updatePracticalContentApi, id, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateSuccessPracticalContent(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(updateErrorPracticalContent(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateErrorPracticalContent(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* updateVideoContentSaga({ id, payload }) {
  try {
    yield put(videoContentLoader(true));
    const response = yield call(updateVideoContentApi, id, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateSuccessVideoContent(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(updateErrorVideoContent(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(videoContentLoader(false));
  } catch (error) {
    yield put(videoContentLoader(false));
    yield put(updateErrorVideoContent(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* addVideoContentSaga({ payload }) {
  try {
    yield put(videoContentLoader(true));
    const response = yield call(addVideoContentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(addSuccessVideoContent(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(addErrorVideoContent(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(videoContentLoader(false));
  } catch (error) {
    yield put(videoContentLoader(false));
    yield put(addErrorVideoContent(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* deleteVideoContentSaga({ payload }) {
  try {
    yield put(videoContentLoader(true));
    const response = yield call(deleteVideoContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteSuccessVideoContent(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(deleteErrorVideoContent(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(videoContentLoader(false));
  } catch (error) {
    yield put(videoContentLoader(false));
    yield put(deleteErrorVideoContent(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* deletePracticalContentSaga({ payload }) {
  try {
    const response = yield call(deletePracticalContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteSuccessPracticalContent(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(deleteErrorPracticalContent(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(deleteErrorPracticalContent(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* fileUploadSaga({ payload }) {
  try {
    yield put(videoContentLoader(true));
    const response = yield call(fileUploadApi, payload?.data);
    if (response?.status === statusCode.Created201) {
      yield put(
        uploadFileSuccess({
          blob_name: response?.data?.blob_name,
          fileType: payload?.data?.get?.('file_type'),
          fileIndex: payload?.fileIndex,
        })
      );
      yield put(
        setToastMessage({
          data: 'File uploaded successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.message ?? 'File upload failed.',
          severity: 'error',
        })
      );
      yield put(
        uploadFileError(response?.data?.message ?? 'File upload failed.')
      );
    }
    yield put(videoContentLoader(false));
  } catch (error) {
    yield put(videoContentLoader(false));
    yield put(
      uploadFileError(error?.response?.data?.message ?? 'File upload failed.')
    );
    yield put(
      setToastMessage({
        data: error?.response?.data?.message ?? 'File upload failed.',
        severity: 'error',
      })
    );
  }
}

function* transcriptUploadSaga({ payload }) {
  try {
    const response = yield call(fileUploadApi, payload?.data);
    if (response?.status === statusCode.Created201) {
      yield put(
        uploadTranscriptFileSuccess({
          blob_name: response?.data?.blob_name,
          fileType: payload?.data?.get?.('file_type'),
          fileIndex: payload?.fileIndex,
        })
      );
    } else {
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    setToastMessage({
      severity: 'error',
    });
  }
}

function* addChapterSaga({ payload }) {
  try {
    const response = yield call(addChapterApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(addChapterSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Chapter added successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(addChapterError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(addChapterError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getChapterDetailsByIdSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getChapterDetailById, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getChapterDetailsByIdSuccess(response?.data));
      yield put(setIsLoading(false));
    } else {
      yield put(getChapterDetailsByIdError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
      yield put(setIsLoading(false));
    }
  } catch (error) {
    yield put(setIsLoading(false));
    yield put(getChapterDetailsByIdError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* updateChapterSaga({ payload }) {
  try {
    const response = yield call(updateChapterApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateChapterSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Chapter updated successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(updateChapterError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateChapterError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* deleteChapterSaga({ payload }) {
  try {
    const response = yield call(deleteChapterApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteChapterSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Chapter deleted successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteChapterError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(deleteChapterError(error));
    yield put(
      setToastMessage({
        data: error?.message,
        severity: 'error',
      })
    );
  }
}

function* deleteLiveSessionSaga({ payload }) {
  try {
    const response = yield call(deleteLiveSessionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteLiveSessionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Session deleted successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteLiveSessionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(deleteLiveSessionError(error));
    yield put(
      setToastMessage({
        data: error?.message,
        severity: 'error',
      })
    );
  }
}

function* getFacultyListBySubjectIdSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getFacultyListBySubjectIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyListBySubjectIdSuccess(response?.data));
      yield put(setIsLoading(false));
    } else {
      yield put(getFacultyListBySubjectIdError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
      yield put(setIsLoading(false));
    }
  } catch (error) {
    yield put(setIsLoading(false));
    yield put(getFacultyListBySubjectIdError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getUserDepartmentsSaga({ payload }) {
  try {
    const response = yield call(getUserDepartmentsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUserDepartmentListSuccess(response?.data));
    } else {
      yield put(getUserDepartmentListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getUserDepartmentListError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getUserProgramsSaga({ payload }) {
  try {
    yield put(getUserProgramListSuccess({ results: [] }));
    const response = yield call(getUserProgramsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUserProgramListSuccess({ results: response?.data?.data }));
    } else {
      yield put(getUserProgramListSuccess({ results: [] }));
      yield put(getUserProgramListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getUserProgramListSuccess({ results: [] }));
    yield put(getUserProgramListError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getReadingContentSaga({ payload, params }) {
  try {
    const response = yield call(getReadingContentApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getReadingSuccess({ results: response?.data }));
    } else {
      yield put(getReadingError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getReadingError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}
//GET Lab List
function* getLabListSaga({ payload }) {
  try {
    const response = yield call(getLabListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getLabListSuccess(response?.data));
    } else {
      yield put(getLabListError(response?.data));
      console.log('response', response);
      yield put(
        setToastMessage({
          data: response?.data?.massage,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getLabListError(error));
    yield put(
      setToastMessage({
        data: response?.data?.massage,
        severity: 'error',
      })
    );
  }
}

//CREATE VM
function* createVmSaga({ payload }) {
  try {
    const response = yield call(createVmApi, payload);
    if (
      response?.status === statusCode.Created201 ||
      response?.status === statusCode.Ok200
    ) {
      yield put(
        createVmSuccess({
          ...response?.data,
          practical_content_id: payload.practical_content_id,
        })
      );
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

//GET VM STATE
function* getVmStateSaga({ payload }) {
  try {
    const response = yield call(getVmStateApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getVmStateSuccess(response?.data));
    } else {
      yield put(getVmStateError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getVmStateError(error));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

export function* watchDummy() {
  yield takeLatest(
    programContentActions.GET_MAPPED_DEPARTMENT_LIST.get,
    getDeparmentListSaga
  );
  yield takeLatest(
    programContentActions.GET_PROGRAM_LIST_FOR_FACULTY.get,
    getProgramListForFacultySaga
  );
  yield takeLatest(
    programContentActions.GET_MAPPED_YEAR_LIST.get,
    getYearListSaga
  );

  yield takeLatest(
    programContentActions.GET_SUBJECT_STATS.get,
    getSubjectStatsSaga
  );

  yield takeLatest(
    programContentActions.GET_MAPPED_SEMESTER_LIST.get,
    getSemesterListSaga
  );

  yield takeLatest(
    programContentActions.GET_MAPPED_SUBJECT_LIST.get,
    getSubjectListSaga
  );

  yield takeLatest(
    programContentActions.GET_CHAPTER_LIST_BY_SUBJECT.get,
    getChapterListBySubjectSaga
  );

  yield takeLatest(
    programContentActions.GET_CHAPTER_CONTENT.get,
    getChapterContentSaga
  );

  yield takeLatest(
    programContentActions.PRACTICAL_CONTENT.set,
    addPracticalContentSaga
  );

  yield takeLatest(
    programContentActions.VIDEO_CONTENT.set,
    addVideoContentSaga
  );

  yield takeLatest(
    programContentActions.VIDEO_CONTENT.upload_file,
    fileUploadSaga
  );

  yield takeLatest(
    programContentActions.VIDEO_CONTENT.upload_transcript_file,
    transcriptUploadSaga
  );
  yield takeLatest(programContentActions.READING.create, createReadingContent);
  yield takeLatest(programContentActions.READING.update, udpateReadingContent);
  yield takeLatest(programContentActions.READING.delete, deleteReadingContent);

  yield takeLatest(
    programContentActions.GET_PROGRAM_DETAILS.get,
    getPrgramDetailsSaga
  );

  yield takeLatest(
    programContentActions.PRACTICAL_CONTENT.get,
    getPracticalContentSaga
  );

  yield takeLatest(
    programContentActions.PRACTICAL_CONTENT.update,
    updatePracticalContentSaga
  );

  yield takeLatest(
    programContentActions.VIDEO_CONTENT.get,
    getVideoContentSaga
  );

  yield takeLatest(
    programContentActions.VIDEO_CONTENT.update,
    updateVideoContentSaga
  );

  yield takeLatest(
    programContentActions.VIDEO_CONTENT.delete_video,
    deleteVideoContentSaga
  );

  yield takeLatest(
    programContentActions.PRACTICAL_CONTENT.delete_practical,
    deletePracticalContentSaga
  );
  yield takeLatest(programContentActions.ADD_CHAPTER.create, addChapterSaga);

  yield takeLatest(
    programContentActions.GET_CHAPTER_DETAILS_BY_ID.get,
    getChapterDetailsByIdSaga
  );

  yield takeLatest(
    programContentActions.UPDATE_CHAPTER.update,
    updateChapterSaga
  );

  yield takeLatest(
    programContentActions.DELETE_CHAPTER.delete,
    deleteChapterSaga
  );

  yield takeLatest(
    programContentActions.DELETE_LIVE_SESSION.delete,
    deleteLiveSessionSaga
  );

  yield takeLatest(
    programContentActions.GET_FACULTY_LIST_BY_SUBJECT_ID.get,
    getFacultyListBySubjectIdSaga
  );

  yield takeLatest(
    programContentActions.GET_USER_DEPARTMENTS.get,
    getUserDepartmentsSaga
  );

  yield takeLatest(
    programContentActions.GET_USER_BASED_PROGRAM.get,
    getUserProgramsSaga
  );

  yield takeLatest(programContentActions.READING.get, getReadingContentSaga);
  yield takeLatest(
    programContentActions.VIRTUAL_LAB.GET_LAB_LIST_REQUEST,
    getLabListSaga
  );

  yield takeLatest(
    programContentActions.CREATE_VM.CREATE_VM_REQUEST,
    createVmSaga
  );
  yield takeLatest(
    programContentActions.GET_VM_STATE.GET_VM_STATE_REQUEST,
    getVmStateSaga
  );
}

export default function* programContentSaga() {
  yield all([watchDummy()]);
}
