import { courseManagementActions } from './CourseManagement.constants';
const {
  CLUSTERS,
  DEPARTMENT,
  DEPARTMENT_DETAILS,
  COURSE_LIST,
  FACULTY,
  MODULE,
  SUB_MODULE,
  COURSE,
  LIVE_SESSION,
  RUBRICS,
  RUBRIC_TABLE,
  GRADE_POINTS,
  CBC_LIST,
  PROGRAM,
  PROGRAM_YEAR,
  SEMESTER,
  SUBJECT,
  RESET,
  FACULTY_SUBJECT_LINK,
  USER_MAPPING_TO_PROGRAM_SUBJECT,
  JOIN_SESSION_VIRTUTOR,
  VISION_FOR_DEPARTMENT,
  MISSION_FOR_DEPARTMENT,
  DOWNLOAD_FOR_VISION,
  DOWNLOAD_FOR_MISSION,
  VISION_PROGRAM,
  MISSION_PROGRAM,
  COURSE_OUTCOME,
  SEND_APPROVAL,
  BULK_DELETE,
  UPDATE_SCHEMA,
} = courseManagementActions;

export const getClusters = (payload) => ({
  type: CLUSTERS.get,
  payload: payload,
});

export const getClustersSuccess = (payload) => ({
  type: CLUSTERS.get_success,
  payload: payload,
});

export const getClustersIncr = (payload) => ({
  type: CLUSTERS.get_incr,
  payload: payload,
});

export const getClustersIncrSuccess = (payload) => ({
  type: CLUSTERS.get_success_incr,
  payload: payload,
});

export const getClustersResetIncr = () => ({
  type: CLUSTERS.reset_incr,
});

export const getClustersError = (payload) => ({
  type: CLUSTERS.get_error,
  payload: payload,
});

export const getClustersByPagination = (payload) => ({
  type: CLUSTERS.get_by_pagination,
  payload: payload,
});

export const getClustersByPaginationSuccess = (payload) => ({
  type: CLUSTERS.get_by_pagination_success,
  payload: payload,
});

export const getClustersByPaginationError = (payload) => ({
  type: CLUSTERS.get_by_pagination_error,
  payload: payload,
});

export const createClusterAction = (payload) => ({
  type: CLUSTERS.create,
  payload: payload,
});

export const createClustersSuccess = (payload) => ({
  type: CLUSTERS.create_success,
  payload: payload,
});

export const createClustersError = (payload) => ({
  type: CLUSTERS.create_error,
  payload: payload,
});

export const getClustersLoading = (payload) => ({
  type: CLUSTERS.loading,
  payload: payload,
});

export const getDepartment = (payload) => ({
  type: DEPARTMENT.get,
  payload: payload,
});

export const getDepartmentSuccess = (payload) => ({
  type: DEPARTMENT.get_success,
  payload: payload,
});

export const getDepartmentError = (payload) => ({
  type: DEPARTMENT.get_error,
  payload: payload,
});

export const getDepartmentsIncr = (payload) => ({
  type: DEPARTMENT.get_incr,
  payload: payload,
});

export const getDepartmentsIncrSuccess = (payload) => ({
  type: DEPARTMENT.get_success_incr,
  payload: payload,
});

export const getDepartmentsResetIncr = () => ({
  type: DEPARTMENT.reset_incr,
});

export const GetDepartmentsByClusterId = (payload) => ({
  type: DEPARTMENT.get_by_cluster_id,
  payload: payload,
});

export const getDepartmentsByClusterIdSuccess = (payload) => ({
  type: DEPARTMENT.get_by_cluster_id_success,
  payload: payload,
});

export const getDepartmentsByClusterIdError = (payload) => ({
  type: DEPARTMENT.get_by_cluster_id_error,
  payload: payload,
});

export const GetDepartmentsByClusterIdByPagination = (payload) => ({
  type: DEPARTMENT.get_by_cluster_id_by_pagination,
  payload: payload,
});

export const getDepartmentsByClusterIdByPaginationSuccess = (payload) => ({
  type: DEPARTMENT.get_by_cluster_id_by_pagination_success,
  payload: payload,
});

export const getDepartmentsByClusterIdByPaginationError = (payload) => ({
  type: DEPARTMENT.get_by_cluster_id_by_pagination_error,
  payload: payload,
});

export const getDepartmentLoading = (payload) => ({
  type: DEPARTMENT.loading,
  payload: payload,
});

export const createDepartmentAction = (payload) => ({
  type: DEPARTMENT.create,
  payload: payload,
});

export const createDepartmentSuccess = (payload) => ({
  type: DEPARTMENT.create_success,
  payload: payload,
});

export const createDepartmentError = (payload) => ({
  type: DEPARTMENT.create_error,
  payload: payload,
});

export const resetDepartmentAction = (payload) => ({
  type: DEPARTMENT.reset,
  payload: payload,
});

export const getDepartmentDetailsAction = (payload) => ({
  type: DEPARTMENT_DETAILS.get,
  payload: payload,
});

export const getDepartmentDetailsSuccess = (payload) => ({
  type: DEPARTMENT_DETAILS.get_success,
  payload: payload,
});

export const setDepartmentDetailsLoading = (payload) => ({
  type: DEPARTMENT_DETAILS.loading,
  payload: payload,
});

export const getDepartmentDetailsError = (payload) => ({
  type: DEPARTMENT_DETAILS.get_error,
  payload: payload,
});

export const getCourseListByDepartmentIdAction = (payload) => ({
  type: COURSE_LIST.get_by_department_id,
  payload: payload,
});

export const getCourseListByDepartmentIdSuccess = (payload) => ({
  type: COURSE_LIST.get_by_department_id_success,
  payload: payload,
});

export const getCourseListByDepartmentIdError = (payload) => ({
  type: COURSE_LIST.get_by_department_id_error,
  payload: payload,
});

export const resetCourseListAction = (payload) => ({
  type: COURSE_LIST.reset,
  payload: payload,
});

export const setCourseListLoading = (payload) => ({
  type: COURSE_LIST.loading,
  payload: payload,
});

export const deleteCourseSuccess = (payload) => ({
  type: COURSE_LIST.delete_success,
  payload: payload,
});
export const deleteCourseAction = (payload) => ({
  type: COURSE_LIST.delete,
  payload: payload,
});

export const setFacultiesLoading = (payload) => ({
  type: FACULTY.loading,
  payload: payload,
});

export const createCourseError = (payload) => ({
  type: COURSE_LIST.create_error,
  payload: payload,
});

export const editCourseHodAction = (payload) => ({
  type: COURSE_LIST.edit,
  payload: payload,
});

export const editDepartmentAction = (payload) => ({
  type: DEPARTMENT.edit,
  payload: payload,
});

export const editDepartmentSuccess = (payload) => ({
  type: DEPARTMENT.edit_success,
  payload: payload,
});

export const editClusterSuccess = (payload) => ({
  type: CLUSTERS.edit_success,
  payload: payload,
});

export const editClusterAction = (payload) => ({
  type: CLUSTERS.edit,
  payload: payload,
});

export const deleteDepartmentAction = (payload) => ({
  type: DEPARTMENT.delete,
  payload: payload,
});

export const deleteDepartmentSuccess = (payload) => ({
  type: DEPARTMENT.delete_success,
  payload: payload,
});
export const deleteClusterAction = (payload) => ({
  type: CLUSTERS.delete,
  payload: payload,
});

export const deleteClusterSuccess = (payload) => ({
  type: CLUSTERS.delete_success,
  payload: payload,
});

export const getCourseByIdAction = (payload) => ({
  type: COURSE_LIST.get_by_id,
  payload: payload,
});

export const getCourseByIdSuccess = (payload) => ({
  type: COURSE_LIST.get_by_id_success,
  payload: payload,
});

export const deleteSubModuleFileAction = (payload) => ({
  type: SUB_MODULE.delete_file,
  payload: payload,
});

export const deleteSubModuleFileSuccessAction = (payload) => ({
  type: SUB_MODULE.delete_file_success,
  payload: payload,
});

export const createModuleSaveFeedback = (payload) => ({
  type: MODULE.save_feedback,
  payload: payload,
});

export const setModuleLoading = (payload) => ({
  type: MODULE.loading,
  payload: payload,
});

export const resetModuleList = () => ({
  type: MODULE.reset,
});

export const setSubModuleLoading = (payload) => ({
  type: SUB_MODULE.loading,
  payload: payload,
});

export const resetSubModuleData = () => ({
  type: SUB_MODULE.reset,
});

export const createSubModuleSaveFeedback = (payload) => ({
  type: SUB_MODULE.save_feedback,
  payload: payload,
});

export const createLiveSessionAction = (payload) => ({
  type: LIVE_SESSION.create,
  payload: payload,
});

export const createLiveSessionSuccessAction = (payload) => ({
  type: LIVE_SESSION.create_success,
  payload: payload,
});

export const createdLiveSessionSuccessDataAction = (payload) => ({
  type: LIVE_SESSION.created_session_success_data,
  payload: payload,
});

export const createLiveSessionDataError = (payload) => ({
  type: LIVE_SESSION.create_error,
  payload: payload,
});

export const editLiveSessionDataError = (payload) => ({
  type: LIVE_SESSION.edit_error,
  payload: payload,
});

export const setLiveSessionLoading = (payload) => ({
  type: LIVE_SESSION.loading,
  payload: payload,
});

export const resetCourseData = () => ({
  type: COURSE_LIST.reset_data,
});

export const resetClusterData = () => ({
  type: CLUSTERS.reset,
});

export const resetDepartmentData = () => ({
  type: DEPARTMENT_DETAILS.reset,
});

export const getRubricConfigAction = (payload) => ({
  type: RUBRICS.get,
  payload: payload,
});
export const getRubricConfigSuccessAction = (payload) => ({
  type: RUBRICS.get_success,
  payload: payload,
});
export const createRubricConfigAction = (payload) => ({
  type: RUBRICS.create,
  payload: payload,
});

export const createRubricConfigSuccessAction = (payload) => ({
  type: RUBRICS.create_success,
  payload: payload,
});

export const createRubricConfigErrorAction = (payload) => ({
  type: RUBRICS.create_error,
  payload: payload,
});

export const createRubricConfigSaveFeedbackAction = (payload) => ({
  type: RUBRICS.save_feedback,
  payload: payload,
});

export const editRubricConfigAction = (payload) => ({
  type: RUBRICS.edit,
  payload: payload,
});

export const setRubricConfigLoading = (payload) => ({
  type: RUBRICS.loading,
  payload: payload,
});

export const getGradePointsAction = (payload) => ({
  type: GRADE_POINTS.get_by_id,
  payload: payload,
});
export const getGradePointsSuccessAction = (payload) => ({
  type: GRADE_POINTS.get_success_by_id,
  payload: payload,
});
export const createGradePointsAction = (payload) => ({
  type: GRADE_POINTS.create,
  payload: payload,
});

export const createGradePointsSuccessAction = (payload) => ({
  type: GRADE_POINTS.create_success,
  payload: payload,
});

export const createGradePointsErrorAction = (payload) => ({
  type: GRADE_POINTS.create_error,
  payload: payload,
});

export const createGradePointsSaveFeedbackAction = (payload) => ({
  type: GRADE_POINTS.save_feedback,
  payload: payload,
});

export const editGradePointsAction = (payload) => ({
  type: GRADE_POINTS.update,
  payload: payload,
});

export const setGradePointsLoading = (payload) => ({
  type: GRADE_POINTS.loading,
  payload: payload,
});

export const getCBCListAction = (payload) => ({
  type: CBC_LIST.get,
  payload: payload,
});

export const getCBCListSuccessAction = (payload) => ({
  type: CBC_LIST.get_success,
  payload: payload,
});

export const createRubricTableAction = (payload) => ({
  type: RUBRIC_TABLE.create,
  payload: payload,
});

export const createRubricTableSuccessAction = (payload) => ({
  type: RUBRIC_TABLE.create_success,
  payload: payload,
});

export const createRubricTableErrorAction = (payload) => ({
  type: RUBRIC_TABLE.create_error,
  payload: payload,
});

export const setRubricTableELoading = (payload) => ({
  type: RUBRIC_TABLE.loading,
  payload: payload,
});

export const editRubricTableAction = (payload) => ({
  type: RUBRIC_TABLE.edit,
  payload: payload,
});

export const getCbcTableAction = (payload) => ({
  type: RUBRIC_TABLE.get,
  payload: payload,
});

export const getCbcTableSuccessAction = (payload) => ({
  type: RUBRIC_TABLE.get_success,
  payload: payload,
});

export const resetAllRubricDataAction = () => ({
  type: RUBRICS.reset,
});

export const getSessionByIdAction = (payload) => ({
  type: LIVE_SESSION.get_by_id,
  payload: payload,
});

export const getSessionByIdSuccessAction = (payload) => ({
  type: LIVE_SESSION.get_by_id_success,
  payload: payload,
});

export const updateSessionAction = (payload) => ({
  type: LIVE_SESSION.edit,
  payload: payload,
});

export const updateSessionSuccess = (payload) => ({
  type: LIVE_SESSION.edit_success,
  payload: payload,
});

export const deleteSessionAction = (payload) => ({
  type: LIVE_SESSION.delete,
  payload: payload,
});

export const getSessionForSelectedSubModuleIdAction = (payload) => ({
  type: LIVE_SESSION.get_by_submoduleId,
  payload: payload,
});

export const getSessionForSelectedSubModuleIdSuccessAction = (payload) => ({
  type: LIVE_SESSION.get_by_submoduleId_success,
  payload: payload,
});

export const createProgramAction = (payload) => ({
  type: PROGRAM.create,
  payload: payload,
});

export const createProgramSuccess = (payload) => ({
  type: PROGRAM.create_success,
  payload: payload,
});
export const createProgramSuccessFeedback = (payload) => ({
  type: PROGRAM.create_success_saveFeedback,
  payload: payload,
});

export const saveProgramErrorAction = (payload) => ({
  type: PROGRAM.create_error,
  payload: payload,
});

export const updateProgramAction = (payload) => ({
  type: PROGRAM.update,
  payload: payload,
});

export const updateProgramSuccess = (payload) => ({
  type: PROGRAM.update_success,
  payload: payload,
});

export const patchProgramAction = (payload) => ({
  type: PROGRAM.patch,
  payload: payload,
});

export const patchProgramSuccess = (payload) => ({
  type: PROGRAM.patch_success,
  payload: payload,
});

export const deleteProgramAction = (payload) => ({
  type: PROGRAM.delete,
  payload: payload,
});

export const deleteProgramSuccess = (payload) => ({
  type: PROGRAM.delete_success,
  payload: payload,
});

export const getProgramListAction = (payload) => ({
  type: PROGRAM.get,
  payload: payload,
});

export const getProgramListSuccess = (payload) => ({
  type: PROGRAM.get_success,
  payload: payload,
});

export const getProgramListIncr = (payload) => ({
  type: PROGRAM.get_incr,
  payload: payload,
});

export const getProgramListSuccessIncr = (payload) => ({
  type: PROGRAM.get_success_incr,
  payload: payload,
});

export const getProgramListResetIncr = () => ({
  type: PROGRAM.reset_incr,
});

export const getProgramByIdAction = (payload) => ({
  type: PROGRAM.get_by_id,
  payload: payload,
});

export const getProgramByIdSuccess = (payload) => ({
  type: PROGRAM.get_success_by_id,
  payload: payload,
});

export const setProgramLoading = (payload) => ({
  type: PROGRAM.loading,
  payload: payload,
});

export const createProgramYearAction = (payload) => ({
  type: PROGRAM_YEAR.create,
  payload: payload,
});

export const createProgramYearSuccess = (payload) => ({
  type: PROGRAM_YEAR.create_success,
  payload: payload,
});

export const saveProgramYearErrorAction = (payload) => ({
  type: PROGRAM_YEAR.create_error,
  payload: payload,
});

export const updateProgramYearAction = (payload) => ({
  type: PROGRAM_YEAR.update,
  payload: payload,
});

export const updateProgramYearSuccess = (payload) => ({
  type: PROGRAM_YEAR.update_success,
  payload: payload,
});

export const patchProgramYearAction = (payload) => ({
  type: PROGRAM_YEAR.patch,
  payload: payload,
});

export const patchProgramYearSuccess = (payload) => ({
  type: PROGRAM_YEAR.patch_success,
  payload: payload,
});

export const deleteProgramYearAction = (payload) => ({
  type: PROGRAM_YEAR.delete,
  payload: payload,
});

export const deleteProgramYearSuccess = (payload) => ({
  type: PROGRAM_YEAR.delete_success,
  payload: payload,
});

export const getProgramYearListAction = (payload) => ({
  type: PROGRAM_YEAR.get,
  payload: payload,
});

export const getProgramYearListSuccess = (payload) => ({
  type: PROGRAM_YEAR.get_success,
  payload: payload,
});

export const getProgramYearByIdAction = (payload) => ({
  type: PROGRAM_YEAR.get_by_id,
  payload: payload,
});

export const getProgramYearByIdSuccess = (payload) => ({
  type: PROGRAM_YEAR.get_success_by_id,
  payload: payload,
});

export const setProgramYearLoading = (payload) => ({
  type: PROGRAM_YEAR.loading,
  payload: payload,
});

export const createSemesterAction = (payload) => ({
  type: SEMESTER.create,
  payload: payload,
});

export const createSemesterSuccess = (payload) => ({
  type: SEMESTER.create_success,
  payload: payload,
});

export const saveSemesterErrorAction = (payload) => ({
  type: SEMESTER.create_error,
  payload: payload,
});

export const updateSemesterAction = (payload) => ({
  type: SEMESTER.update,
  payload: payload,
});

export const updateSemesterSuccess = (payload) => ({
  type: SEMESTER.update_success,
  payload: payload,
});

export const patchSemesterAction = (payload) => ({
  type: SEMESTER.patch,
  payload: payload,
});

export const patchSemesterSuccess = (payload) => ({
  type: SEMESTER.patch_success,
  payload: payload,
});

export const deleteSemesterAction = (payload) => ({
  type: SEMESTER.delete,
  payload: payload,
});

export const deleteSemesterSuccess = (payload) => ({
  type: SEMESTER.delete_success,
  payload: payload,
});

export const getSemesterListAction = (payload) => ({
  type: SEMESTER.get,
  payload: payload,
});

export const getSemesterListSuccess = (payload) => ({
  type: SEMESTER.get_success,
  payload: payload,
});

export const getSemesterByIdAction = (payload) => ({
  type: SEMESTER.get_by_id,
  payload: payload,
});

export const getSemesterByIdSuccess = (payload) => ({
  type: SEMESTER.get_success_by_id,
  payload: payload,
});

export const setSemesterLoading = (payload) => ({
  type: SEMESTER.loading,
  payload: payload,
});

export const createSubjectAction = (payload) => ({
  type: SUBJECT.create,
  payload: payload,
});

export const createSubjectSuccess = (payload) => ({
  type: SUBJECT.create_success,
  payload: payload,
});

export const saveSubjectErrorAction = (payload) => ({
  type: SUBJECT.create_error,
  payload: payload,
});

export const updateSubjectAction = (payload) => ({
  type: SUBJECT.update,
  payload: payload,
});

export const updateSubjectSuccess = (payload) => ({
  type: SUBJECT.update_success,
  payload: payload,
});

export const patchSubjectAction = (payload) => ({
  type: SUBJECT.patch,
  payload: payload,
});

export const patchSubjectSuccess = (payload) => ({
  type: SUBJECT.patch_success,
  payload: payload,
});

export const deleteSubjectAction = (payload) => ({
  type: SUBJECT.delete,
  payload: payload,
});

export const deleteSubjectSuccess = (payload) => ({
  type: SUBJECT.delete_success,
  payload: payload,
});

export const getSubjectListAction = (payload) => ({
  type: SUBJECT.get,
  payload: payload,
});

export const getSubjectListSuccess = (payload) => ({
  type: SUBJECT.get_success,
  payload: payload,
});

export const getSubjectListIncr = (payload) => ({
  type: SUBJECT.get_incr,
  payload: payload,
});

export const getSubjectListIncrSuccess = (payload) => ({
  type: SUBJECT.get_success_incr,
  payload: payload,
});

export const getSubjectListResetIncr = () => ({
  type: SUBJECT.reset_incr,
});

export const getSubjectByIdAction = (payload) => ({
  type: SUBJECT.get_by_id,
  payload: payload,
});

export const getSubjectByIdSuccess = (payload) => ({
  type: SUBJECT.get_success_by_id,
  payload: payload,
});

export const setSubjectLoading = (payload) => ({
  type: SUBJECT.loading,
  payload: payload,
});

export const resetAllAction = () => ({
  type: RESET.reset_all,
});

export const facultySubjectLinkAction = (payload) => ({
  type: FACULTY_SUBJECT_LINK.faculty_subject_link,
  payload: payload,
});

export const facultySubjectLinkSuccess = (payload) => ({
  type: FACULTY_SUBJECT_LINK.faculty_subject_link_success,
  payload: payload,
});

export const facultySubjectLinkError = (payload) => ({
  type: FACULTY_SUBJECT_LINK.faculty_subject_link_error,
  payload: payload,
});

export const getFacultySubjectDetailsAction = (payload) => ({
  type: FACULTY_SUBJECT_LINK.get_faculty_subject_details,
  payload: payload,
});

export const getFacultySubjectDetailsSuccess = (payload) => ({
  type: FACULTY_SUBJECT_LINK.get_faculty_subject_details_success,
  payload: payload,
});

export const deleteFacultySubjectLinkAction = (payload) => ({
  type: FACULTY_SUBJECT_LINK.delete_faculty_subject_link,
  payload: payload,
});

export const deleteFacultySubjectLinkSuccess = (payload) => ({
  type: FACULTY_SUBJECT_LINK.delete_faculty_subject_link_success,
  payload: payload,
});

export const deleteFacultySubjectLinkError = (payload) => ({
  type: FACULTY_SUBJECT_LINK.delete_faculty_subject_link_error,
  payload: payload,
});

export const editFacultySubjectLinkDetailsAction = (payload) => ({
  type: FACULTY_SUBJECT_LINK.edit_faculty_subject_link_details,
  payload: payload,
});

export const editFacultySubjectLinkDetailsSuccess = (payload) => ({
  type: FACULTY_SUBJECT_LINK.edit_faculty_subject_link_details_success,
  payload: payload,
});

export const editFacultySubjectLinkDetailsError = (payload) => ({
  type: FACULTY_SUBJECT_LINK.edit_faculty_subject_link_details_error,
  payload: payload,
});

export const resetFacultSubjectDetailsAction = () => ({
  type: FACULTY_SUBJECT_LINK.reset_faculty_subject_details,
});

export const getProgramsListByUserId = (payload) => ({
  type: PROGRAM.get_programs_by_user_id,
  payload: payload,
});

export const getSuccessProgramsListByUserId = (payload) => ({
  type: PROGRAM.get_success_programs_by_user_id,
  payload: payload,
});

export const getErrorProgramsListByUserId = (payload) => ({
  type: PROGRAM.get_error_programs_by_user_id,
  payload: payload,
});
// Get user mapping by user id

export const getUserMappingByUserIdRequest = (payload) => ({
  type: USER_MAPPING_TO_PROGRAM_SUBJECT.GET_USER_MAPPING_BY_USER_ID_REQUEST,
  payload: payload,
});

export const getUserMappingByUserIdSuccess = (payload) => ({
  type: USER_MAPPING_TO_PROGRAM_SUBJECT.GET_USER_MAPPING_BY_USER_ID_SUCCESS,
  payload: payload,
});

export const getUserMappingByUserIdError = (payload) => ({
  type: USER_MAPPING_TO_PROGRAM_SUBJECT.GET_USER_MAPPING_BY_USER_ID_ERROR,
  payload: payload,
});

export const getUserMappingByUserIdReset = () => ({
  type: USER_MAPPING_TO_PROGRAM_SUBJECT.GET_USER_MAPPING_BY_USER_ID_RESET,
});

// Update user to program mapping

export const updateUserToProgramMappingRequest = (payload) => ({
  type: USER_MAPPING_TO_PROGRAM_SUBJECT.UPDATE_USER_MAPPING_BY_USER_ID_REQUEST,
  payload: payload,
});

export const updateUserToProgramMappingSuccess = (payload) => ({
  type: USER_MAPPING_TO_PROGRAM_SUBJECT.UPDATE_USER_MAPPING_BY_USER_ID_SUCCESS,
  payload: payload,
});

export const updateUserToProgramMappingError = (payload) => ({
  type: USER_MAPPING_TO_PROGRAM_SUBJECT.UPDATE_USER_MAPPING_BY_USER_ID_ERROR,
  payload: payload,
});

export const updateUserToProgramMappingReset = () => ({
  type: USER_MAPPING_TO_PROGRAM_SUBJECT.UPDATE_USER_MAPPING_BY_USER_ID_RESET,
});

// Join Session Virtutor API
export const createJoinSessionForVirtutorAction = (payload) => ({
  type: JOIN_SESSION_VIRTUTOR.JOIN_SESSION_VIRTUTOR_CREATE,
  payload: payload,
});

export const createJoinSessionForVirtutorSuccess = (payload) => ({
  type: JOIN_SESSION_VIRTUTOR.JOIN_SESSION_VIRTUTOR_SUCCESS,
  payload: payload,
});

export const createJoinSessionForVirtutorError = (payload) => ({
  type: JOIN_SESSION_VIRTUTOR.JOIN_SESSION_VIRTUTOR_ERROR,
  payload: payload,
});

export const createJoinSessionForVirtutorLoader = (payload) => ({
  type: JOIN_SESSION_VIRTUTOR.JOIN_SESSION_VIRTUTOR_LOADER,
  payload: payload,
});

export const resetJoinSessionForVirtutor = () => ({
  type: JOIN_SESSION_VIRTUTOR.JOIN_SESSION_VIRTUTOR_RESET,
});

//Create vision for department
export const createVisionForDepartmentAction = (payload) => ({
  type: VISION_FOR_DEPARTMENT.VISION_FOR_DEPARTMENT_CREATE,
  payload: payload,
});

export const createVisionForDepartmentSuccess = (payload) => ({
  type: VISION_FOR_DEPARTMENT.VISION_FOR_DEPARTMENT_SUCCESS,
  payload: payload,
});

export const createVisionForDepartmentError = (payload) => ({
  type: VISION_FOR_DEPARTMENT.VISION_FOR_DEPARTMENT_ERROR,
  payload: payload,
});

export const resetVisionForDepartment = () => ({
  type: VISION_FOR_DEPARTMENT.VISION_FOR_DEPARTMENT_RESET,
});

//Create mission for department
export const createMissionForDepartmentAction = (payload) => ({
  type: MISSION_FOR_DEPARTMENT.MISSION_FOR_DEPARTMENT_CREATE,
  payload: payload,
});

export const createMissionForDepartmentSuccess = (payload) => ({
  type: MISSION_FOR_DEPARTMENT.MISSION_FOR_DEPARTMENTT_SUCCESS,
  payload: payload,
});

export const createMissionForDepartmentError = (payload) => ({
  type: MISSION_FOR_DEPARTMENT.MISSION_FOR_DEPARTMENT_ERROR,
  payload: payload,
});

export const resetMissionForDepartment = () => ({
  type: MISSION_FOR_DEPARTMENT.MISSION_FOR_DEPARTMENT_RESET,
});

// Download for vision
export const getDepartmentVision = (payload) => ({
  type: DOWNLOAD_FOR_VISION.DOWNLOAD_VISION_REQUEST,
  payload: payload,
});
export const getDepartmentVisionSuccess = (payload) => ({
  type: DOWNLOAD_FOR_VISION.DOWNLOAD_VISION_SUCCESS,
  payload: payload,
});
export const getDepartmentVisionError = (payload) => ({
  type: DOWNLOAD_FOR_VISION.DOWNLOAD_VISION_ERROR,
  payload: payload,
});
export const getDepartmentVisionReset = () => ({
  type: DOWNLOAD_FOR_VISION.DOWNLOAD_VISION_RESET,
});

// Download for Mission
export const DownloadMissionRequest = (payload) => ({
  type: DOWNLOAD_FOR_MISSION.DOWNLOAD_MISSION_REQUEST,
  payload: payload,
});
export const DownloadMissionSuccess = (payload) => ({
  type: DOWNLOAD_FOR_MISSION.DOWNLOAD_MISSION_SUCCESS,
  payload: payload,
});
export const DownloadMissionError = (payload) => ({
  type: DOWNLOAD_FOR_MISSION.DOWNLOAD_MISSION_ERROR,
  payload: payload,
});
export const DownloadMissionReset = () => ({
  type: DOWNLOAD_FOR_MISSION.DOWNLOAD_MISSION_RESET,
});

export const getuniversityVissionAction = () => ({
  type: VISION_PROGRAM.GET_UNIVERSITY_VISSION,
});

export const getuniversityVissionActionSuccess = (payload) => ({
  type: VISION_PROGRAM.GET_UNIVERSITY_VISSION_SUCCESS,
  payload: payload,
});

export const getUniversiyVissionError = (payload) => ({
  type: VISION_PROGRAM.GET_UNIVERSITY_VISSION_ERROR,
  payload: payload,
});

export const createUniversityVission = (payload) => ({
  type: VISION_PROGRAM.CREATE_UNIVERSITY_VISSION,
  payload: payload,
});

export const createUniversityVissionSuccess = (payload) => ({
  type: VISION_PROGRAM.CREATE_UNIVERSITY_VISSION_SUCCESS,
  payload: payload,
});

export const createUniversityVissionError = (payload) => ({
  type: VISION_PROGRAM.CREATE_UNIVERSITY_VISSION_ERROR,
  payload: payload,
});

export const getUniversityMission = (payload) => ({
  type: MISSION_PROGRAM.GET_UNIVERSITY_MISSION,
});

export const getUniversityMissionSuccess = (payload) => ({
  type: MISSION_PROGRAM.GET_UNIVERSITY_MISSION_SUCCESS,
  payload: payload,
});

export const getUniversityMissionError = (payload) => ({
  type: MISSION_PROGRAM.GET_UNIVERSITY_MISSION_ERROR,
  payload: payload,
});

export const createUniversityMission = (payload) => ({
  type: MISSION_PROGRAM.CREATE_UNIVERSITY_MISSION,
  payload: payload,
});

export const createUniversityMissionSuccess = (payload) => ({
  type: MISSION_PROGRAM.CREATE_UNIVERSITY_MISSION_SUCCESS,
  payload: payload,
});

export const createUniversityMissionError = (payload) => ({
  type: MISSION_PROGRAM.CREATE_UNIVERSITY_MISSION_ERROR,
  payload: payload,
});

export const getCourseOutcome = (payload) => ({
  type: COURSE_OUTCOME.GET_COURSE_OUTCOME,
  payload: payload,
});

export const getCourseOutcomeSuccess = (payload) => ({
  type: COURSE_OUTCOME.GET_COURSE_OUTCOME_SUCCESS,
  payload: payload,
});

export const getCourseOutcomeError = (payload) => ({
  type: COURSE_OUTCOME.GET_COURSE_OUTCOME_ERROR,
  payload: payload,
});
//send approval
export const sendApprovalAction = (payload) => ({
  type: SEND_APPROVAL.SEND_APPROVAL_ACTION,
  payload: payload,
});

export const sendApprovalSuccess = (payload) => ({
  type: SEND_APPROVAL.SEND_APPROVAL_SUCCESS,
  payload: payload,
});

export const sendApprovalError = (payload) => ({
  type: SEND_APPROVAL.SEND_APPROVAL_ERROR,
  payload: payload,
});
export const sendApprovalreset = (payload) => ({
  type: SEND_APPROVAL.SEND_APPROVAL_RESET,
  payload: payload,
});

// Delete
export const bulkDeleteAction = (payload) => ({
  type: BULK_DELETE.BULK_DELETE_ACTION,
  payload: payload,
});

export const bulkDeleteSuccess = (payload) => ({
  type: BULK_DELETE.BULK_DELETE_SUCCESS,
  payload: payload,
});

export const bulkDeleteError = (payload) => ({
  type: BULK_DELETE.BULK_DELETE_ERROR,
  payload: payload,
});
export const bulkDeletereset = (payload) => ({
  type: BULK_DELETE.BULK_DELETE_RESET,
  payload: payload,
});
// program
export const uploadSchemaAction = (payload) => ({
  type: UPDATE_SCHEMA.UPDATE_SCHEMA_REQUEST,
  payload: payload,
});

export const uploadSchemaSuccess = (payload) => ({
  type: UPDATE_SCHEMA.UPDATE_SCHEMA_SUCCESS,
  payload: payload,
});

export const uploadSchemaError = (payload) => ({
  type: UPDATE_SCHEMA.UPDATE_SCHEMA_ERROR,
  payload: payload,
});
export const uploadSchemareset = (payload) => ({
  type: UPDATE_SCHEMA.UPDATE_SCHEMA_RESET,
  payload: payload,
});
