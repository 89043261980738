import { takeLatest, put, call, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';
import { obeActions } from './Obe.constants';
import {
  createDefineOutcomesError,
  createDefineOutcomesSuccess,
  createMappingError,
  createMappingSuccess,
  defineOutcomesLoader,
  getDefineOutcomesError,
  getDefineOutcomesSuccess,
  missionvspeoMappingError,
  missionvspeoMappingSuccess,
  covspoMappingSuccess,
  covspoMappingError,
  createcovspoMappingSuccess,
  createcovspoMappingError,
} from './Obe.action';
import {
  createDefineOutcomesApi,
  createMappingApi,
  getDefineOutcomesApi,
  getMissionvspeoApi,
  getcovspoApi,
  createCOPOMappingApi,
} from './Obe.api';

function* createDefineOutcomesSaga({ payload, outcome_type }) {
  try {
    yield put(defineOutcomesLoader(true));
    const response = yield call(createDefineOutcomesApi, payload, outcome_type);
    if (response?.status === statusCode.Created201) {
      yield put(createDefineOutcomesSuccess(response?.data));
      yield put(
        setToastMessage({
          data:
            response?.data?.message || 'Define outcomes created successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(createDefineOutcomesError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(defineOutcomesLoader(false));
  } catch (error) {
    yield put(createDefineOutcomesError(error));
    yield put(defineOutcomesLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getDefineOutcomesSaga({ payload }) {
  try {
    yield put(defineOutcomesLoader(true));
    const response = yield call(getDefineOutcomesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getDefineOutcomesSuccess(response?.data));
    } else {
      yield put(getDefineOutcomesError(response?.data));
    }
    yield put(defineOutcomesLoader(false));
  } catch (error) {
    yield put(getDefineOutcomesError(error));
    yield put(defineOutcomesLoader(false));
  }
}

function* getMissionvspeoMappingSaga({ payload }) {
  try {
    yield put(defineOutcomesLoader(true));
    const response = yield call(getMissionvspeoApi, payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(missionvspeoMappingSuccess(response?.data));
    } else {
      yield put(missionvspeoMappingError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Could not fetch data',
          severity: 'error',
        })
      );
    }
    yield put(defineOutcomesLoader(false));
  } catch (error) {
    yield put(missionvspeoMappingError(error));
    yield put(defineOutcomesLoader(false));
    yield put(
      setToastMessage({
        data: 'Something Went Wrong',
        severity: 'error',
      })
    );
  }
}

function* getcovspoMappingSaga({ payload }) {
  try {
    yield put(defineOutcomesLoader(true));
    const response = yield call(getcovspoApi, payload);
    if (response?.status === statusCode?.Created201) {
      yield put(covspoMappingSuccess(response?.data));
    } else {
      yield put(covspoMappingError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Could not fetch data',
          severity: 'error',
        })
      );
    }
    yield put(defineOutcomesLoader(false));
  } catch (error) {
    yield put(covspoMappingError(error));
    yield put(defineOutcomesLoader(false));
    yield put(
      setToastMessage({
        data: 'Something Went Wrong',
        severity: 'error',
      })
    );
  }
}
function* createMappingSaga({ payload }) {
  try {
    yield put(defineOutcomesLoader(true));
    const response = yield call(createMappingApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createMappingSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Created Successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(createMappingError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(defineOutcomesLoader(false));
  } catch (error) {
    yield put(createMappingError(error));
    yield put(defineOutcomesLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* createcopoMappingSaga({ payload }) {
  try {
    yield put(defineOutcomesLoader(true));
    const response = yield call(createCOPOMappingApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createcovspoMappingSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Created Successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(createcovspoMappingError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(defineOutcomesLoader(false));
  } catch (error) {
    yield put(createcovspoMappingError(error));
    yield put(defineOutcomesLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

export function* watchDummy() {
  yield takeLatest(
    obeActions.CREATE_DEFINE_OUTCOMES.create,
    createDefineOutcomesSaga
  );
  yield takeLatest(obeActions.GET_DEFINE_OUTCOMES.get, getDefineOutcomesSaga);
  yield takeLatest(
    obeActions.GET_MISSION_VS_PEO_MAPPING.get,
    getMissionvspeoMappingSaga
  );
  yield takeLatest(
    obeActions.CREATE_MISSION_VS_PEO_MAPPING.create,
    createMappingSaga
  );
  yield takeLatest(obeActions.GET_CO_VS_PO_MAPPING.get, getcovspoMappingSaga);
  yield takeLatest(
    obeActions.CREATE_CO_VS_PO_MAPPING.create,
    createcopoMappingSaga
  );
}

export default function* attendanceSaga() {
  yield all([watchDummy()]);
}
