import axiosInstance from 'translator';
import { evaluationAPI } from 'store/Evaluation';

export const getUserMappingDataApi = async (payload) => {
  return axiosInstance
    .get(`${evaluationAPI?.getUserMapping}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getInternalEvaluationsListApi = async (payload, params) => {
  return axiosInstance
    .get(`${evaluationAPI?.internalEvaluation}/${payload?.programId}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getInternalEvaluationsErrorApi = async (payload, params) => {
  return axiosInstance
    .get(`${evaluationAPI?.internalEvaluation}/${payload?.programId}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getInternalEvaluationStudentsListApi = async (payload) => {
  return axiosInstance
    .get(`${evaluationAPI?.internalEvaluation}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentInternalEvalListApi = async (payload) => {
  return axiosInstance
    .get(`${evaluationAPI?.studentEvalList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const submitStudentMarksApi = async (payload, params) => {
  return axiosInstance
    .put(
      `${evaluationAPI?.internalEvaluation}/${params?.content_id}`,
      payload,
      {
        params: params,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const submitInternalAssignmentStudentMarksApi = async (
  payload,
  params
) => {
  if (params) {
    return axiosInstance
      .put(
        `${evaluationAPI?.assignmentsApiUrl}/${params?.content_id}`,
        payload,
        { params }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
};

export const getExamQuizApi = async (params) => {
  return axiosInstance
    .get(`${evaluationAPI?.exam_quiz}/${params?.quizId}`, {
      params: { user_id: params?.user_id },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const submitExamQuizApi = async (payload, params) => {
  return axiosInstance
    .post(`${evaluationAPI?.exam_quiz}`, payload, {
      params: { params },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExamAssignmentApi = async (params) => {
  return axiosInstance
    .get(`${evaluationAPI?.exam_assignment}/${params?.assignment_id}`, {
      params: { user_id: params?.user_id },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const submitExamAssignmentApi = async (payload, params) => {
  return axiosInstance
    .post(`${evaluationAPI?.exam_assignment}`, payload, {
      params: { params },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const examEvaluationApi = async (params = {}) => {
  return axiosInstance
    .get(`${evaluationAPI?.exam_evaluation}`, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const examSubjectEvaluationApi = async (params = {}) => {
  return axiosInstance
    .get(`${evaluationAPI?.exam_evaluation}/${params?.id}`, {
      params: { user_id: params?.user_id, ...params },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const studentExamsListApi = async (params = {}) => {
  return axiosInstance
    .get(`${evaluationAPI?.getStudentExamsList}`, {
      params: { user_id: params?.user_id, ...params },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentInternalExamListApi = async (payload, params) => {
  return axiosInstance
    .get(`${evaluationAPI?.studentInternalExamList}/${payload?.program_id}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postUploadExamApi = async (params) => {
  return axiosInstance
    .post(`${evaluationAPI?.exam_upload}`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUploadedExamApi = async (params) => {
  return axiosInstance
    .get(`${evaluationAPI?.exam_upload}/${params?.upload_id}`, {
      params: { user_id: params?.user_id },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateExamStudentsEvaluationApi = async (
  payload = '',
  params = ''
) => {
  return axiosInstance
    .patch(
      `${evaluationAPI?.getExamStudentsList}/${payload?.evaluationId}`,
      payload,
      {
        params: params,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const recheckRequestApi = async (params) => {
  return axiosInstance
    .patch(`${evaluationAPI?.recheckRequest}/${params?.evaluation_id}`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentEvaluationDetailsListApi = async (payload, params) => {
  return axiosInstance
    .get(`${evaluationAPI?.getExamStudentsList}/${payload}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExamStudentsListApi = async (params) => {
  return axiosInstance
    .get(`${evaluationAPI?.getExamStudentsList}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateExamAssignmentEvaluationApi = async (
  payload = '',
  requestPath = '',
  params = ''
) => {
  return axiosInstance
    .patch(
      `${evaluationAPI?.updateAssignmentEvaluation}/${requestPath?.assignmentId}`,
      payload,
      {
        params: params,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserMappedSubjectsApi = async (params) => {
  return axiosInstance
    .get(`${evaluationAPI?.getUserMappedSubjects}`, {
      params: params,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getStudentMappedSubjectsApi = async (params) => {
  return axiosInstance
    .get(`${evaluationAPI?.getStudentMappedSubjects}`, {
      params: params,
    })
    .then((response) => response)
    .catch((error) => error);
};
