import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Tooltip,
  CardHeader,
  Stack,
  Grid,
  Box,
} from 'iiht-b2a-ui-components/build/components';
import './ItemsCard.scss';

const ItemsCard = ({ title, text, ...item }) => {
  return (
    <>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          title={<Typography variant="bodyText">{title}</Typography>}
          titleTypographyProps={{ variant: 'bodyText' }}
        />
        <CardContent>
          <Grid container spacing={2} sx={{ px: 2 }}>
            <Grid item xs={6}>
              {item?.icon}
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="end">
              <Box>
                <Typography variant="h6" className="font-bold">
                  {text}
                </Typography>
                <Typography
                  sx={{ marginTop: 'auto' }}
                  color="grey.P1000"
                  variant="caption"
                >
                  {item?.subText}
                </Typography>
              </Box>
            </Grid>
            {item?.description && (
              <Grid
                item
                container
                display="flex"
                justifyContent="space-between"
              >
                <Grid item>
                  <Tooltip title={item?.description}>
                    <span>
                      <Typography color="grey.P1000" variant="caption">
                        {item?.description?.length > 30
                          ? `${item?.description?.substring(0, 30)}...`
                          : item?.description}
                      </Typography>
                    </span>
                  </Tooltip>
                </Grid>
                {item?.descriptionNumberTwo && (
                  <Grid item>
                    <Tooltip title={item?.descriptionNumberTwo}>
                      <span>
                        <Typography color="grey.P1000" variant="caption">
                          {item?.descriptionNumberTwo}
                        </Typography>
                      </span>
                    </Tooltip>
                  </Grid>
                )}
                {item?.descriptionNumber && (
                  <Grid item>
                    <Tooltip title={item?.descriptionNumber}>
                      <span>
                        <Typography color="grey.P1000" variant="caption">
                          {item?.descriptionNumber}
                        </Typography>
                      </span>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ItemsCard;
