export const CounsellorAPI = {
  getCounsellorStats: '/v1/admission/lead-counsellor-stats',
  getCounsellorLeads: '/v1/admission/lead-counsellor',
  getInterestedProspectusPrice: '/v1/admission/counsellor-prospect-form/',
  addSelectedProspectApi: '/v1/admission/counsellor-prospect-form',
  sendPaymentLink: '/v1/admission/lead-counsellor-payment',
  startCounselling: 'v1/admission/lead-counsellor',
  leadStage: 'v1/admission/lead-stage-info',
  sendFormLink: '/v1/admission/send-mail-prospect',
};
