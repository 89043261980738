import React from 'react';

const Undo = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
      className={className}
    >
      <path d="M280 856v-80h284q63 0 109.5-40T720 636q0-60-46.5-100T564 496H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800 636q0 94-69.5 157T564 856H280Z" />
    </svg>
  );
};

export default Undo;
