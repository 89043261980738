import { userManagementActions } from './UserManagement.constants';
const {
  USER,
  USER_ROLE,
  USER_DETAILS,
  STUDENT,
  STAFF,
  CLUSTERS,
  DEPARTMENTS,
  COURSES,
  AUTHENTICATION,
  TENANT_SPECIFIC_LOGO,
  CHANGE_STUDENT,
  CANDIDATE_STUDENT,
  CANDIDATE_ONBOARDED,
  CANDIDATE_ONBOARDING_PENDING,
  CANDIDATE_LOGIN,
  HR,
  ALL_USER_ROLE,
  DELETE_USER_ROLE,
  USER_ROLE_DETAILS,
  CREATE_USER_ROLES,
  UPDATE_USER_ROLES,
  USER_ROLE_POLICY,
  USER_LOGIN_DETAILS,
  ANY_USER_DETAILS,
  GROUP,
  BULK_USER_ROLES,
  FORGOT_PASSWORD,
} = userManagementActions;

export const getUserDetailsAction = (payload) => ({
  type: USER.get,
  payload: payload,
});

export const setUserDetailsLoading = (payload) => ({
  type: USER.loading,
  payload: payload,
});

export const getUserDetailsSuccess = (payload) => ({
  type: USER.get_success,
  payload: payload,
});

export const getUserRoleAction = (payload) => ({
  type: USER_ROLE.get,
  payload: payload,
});

export const setUserRoleLoading = (payload) => ({
  type: USER_ROLE.loading,
  payload: payload,
});

export const getUserRoleSuccess = (payload) => ({
  type: USER_ROLE.get_success,
  payload: payload,
});

export const getAllRolesAction = (payload) => ({
  type: USER_ROLE.get_all_roles,
  payload: payload,
});

export const getAllRolesSuccess = (payload) => ({
  type: USER_ROLE.get_all_roles_success,
  payload: payload,
});

export const getAllRolesError = (payload) => ({
  type: USER_ROLE.get_all_roles_error,
  payload: payload,
});

export const getAllFacultyDetailsAction = (payload) => ({
  type: USER_DETAILS.get_all_faculties,
  payload: payload,
});

export const getAllFacultyDetailsSuccess = (payload) => ({
  type: USER_DETAILS.get_all_faculties_success,
  payload: payload,
});

export const getAllFacultyDetailsError = (payload) => ({
  type: USER_DETAILS.get_all_faculties_error,
  payload: payload,
});
export const getAllUserDetailsNotificationAction = (payload) => ({
  type: USER_DETAILS.get_user_for_notification_action,
  payload: payload,
});

export const getAllUserDetailsNotificationSuccess = (payload) => ({
  type: USER_DETAILS.get_user_for_notification_sucess,
  payload: payload,
});

export const getAllUserDetailsNotificationError = (payload) => ({
  type: USER_DETAILS.get_user_for_notification_error,
  payload: payload,
});

export const getAllHodDetailsAction = (payload) => ({
  type: USER_DETAILS.get_all_hods,
  payload: payload,
});

export const getAllHodDetailsSuccess = (payload) => ({
  type: USER_DETAILS.get_all_hods_success,
  payload: payload,
});

export const getAllHodDetailsError = (payload) => ({
  type: USER_DETAILS.get_all_hods_error,
  payload: payload,
});

export const getAllHodDetailsReset = () => ({
  type: USER_DETAILS.get_all_hods_reset,
});

export const getAllHodDetailsByScrollAction = (payload) => ({
  type: USER_DETAILS.get_all_hods_by_scroll,
  payload: payload,
});

export const getAllHodDetailsByScrollSuccess = (payload) => ({
  type: USER_DETAILS.get_all_hods_by_scroll_success,
  payload: payload,
});

export const getAllHodDetailsByScrollError = (payload) => ({
  type: USER_DETAILS.get_all_hods_by_scroll_error,
  payload: payload,
});

export const getAllStudentsDetailsAction = (payload) => ({
  type: USER_DETAILS.get_all_students,
  payload: payload,
});

export const getAllStudentsDetailsSuccess = (payload) => ({
  type: USER_DETAILS.get_all_students_success,
  payload: payload,
});

export const getAllStudentsDetailsError = (payload) => ({
  type: USER_DETAILS.get_all_students_error,
  payload: payload,
});

export const getAllRegistrarDetailsAction = (payload) => ({
  type: USER_DETAILS.get_all_registrars,
  payload: payload,
});

export const getAllRegistrarDetailsSuccess = (payload) => ({
  type: USER_DETAILS.get_all_registrars_success,
  payload: payload,
});

export const getAllRegistrarDetailsError = (payload) => ({
  type: USER_DETAILS.get_all_registrars_error,
  payload: payload,
});

export const getDeptUserDetailsAction = (payload) => ({
  type: USER_DETAILS.get_dept_user_details,
  payload: payload,
});

export const getDeptUserDetailsSuccess = (payload) => ({
  type: USER_DETAILS.get_dept_user_details_success,
  payload: payload,
});

export const getDeptUserDetailsError = (payload) => ({
  type: USER_DETAILS.get_dept_user_details_error,
  payload: payload,
});

export const getDeptUserDetailsActionByPagination = (payload) => ({
  type: USER_DETAILS.get_dept_user_details_by_pagination,
  payload: payload,
});

export const getDeptUserDetailsSuccessByPagination = (payload) => ({
  type: USER_DETAILS.get_dept_user_details_by_pagination_success,
  payload: payload,
});

export const getDeptUserDetailsErrorByPagination = (payload) => ({
  type: USER_DETAILS.get_dept_user_details_by_pagination_error,
  payload: payload,
});

export const getDeptUserDetailsReset = () => ({
  type: USER_DETAILS.get_dept_user_details_reset,
});

export const getFacultyByDeptDetailsAction = (payload) => ({
  type: USER_DETAILS.get_faculties_by_dept,
  payload: payload,
});

export const getFacultyByDeptDetailsSuccess = (payload) => ({
  type: USER_DETAILS.get_faculties_by_dept_success,
  payload: payload,
});

export const getFacultyByDeptDetailsError = (payload) => ({
  type: USER_DETAILS.get_faculties_by_dept_error,
  payload: payload,
});

export const getAddMoreUsersAction = (payload) => ({
  type: USER_DETAILS.get_add_more_user_details,
  payload: payload,
});

export const getAddMoreUsersSuccess = (payload) => ({
  type: USER_DETAILS.get_add_more_user_details_success,
  payload: payload,
});

export const getAddMoreUsersError = (payload) => ({
  type: USER_DETAILS.get_add_more_user_details_error,
  payload: payload,
});

export const getStudentsList = (payload) => ({
  type: STUDENT?.get,
  payload: payload,
});

export const getSuccessStudentsList = (payload) => ({
  type: STUDENT?.get_success,
  payload: payload,
});

export const getErrorStudentsList = (payload) => ({
  type: STUDENT?.get_error,
  payload: payload,
});
export const resetStudentsList = () => ({
  type: STUDENT?.get_error,
});
export const getStudentListWithProgram = (payload) => ({
  type: STUDENT?.get_student_details_with_program_list,
  action: payload,
});
export const getStudentListWithProgramSuccess = (payload) => ({
  type: STUDENT.get_student_details_with_program_list_success,
  payload: payload,
});
export const getStudentListWithProgramError = (payload) => ({
  type: STUDENT.get_student_details_with_program_list_error,
  payload: payload,
});

export const getAllProgramData = () => ({
  type: STUDENT.get_all_program_list,
});
export const getAllProgramDataSuccess = (payload) => ({
  type: STUDENT.get_all_program_list_success,
  payload: payload,
});
export const getAllProgramDataError = (payload) => ({
  type: STUDENT.get_all_program_list_error,
  payload: payload,
});
export const createStudent = (payload) => ({
  type: STUDENT?.create,
  payload: payload,
});

export const createSuccessStudent = (payload) => ({
  type: STUDENT?.create_success,
  payload: payload,
});

export const createErrorStudent = (payload) => ({
  type: STUDENT?.create_error,
  payload: payload,
});

export const updateStudent = (payload) => ({
  type: STUDENT?.update,
  payload: payload,
});
export const updateStudentSuccess = (payload) => ({
  type: STUDENT?.update_success,
  payload: payload,
});
export const updateStudentError = (payload) => ({
  type: STUDENT?.update_error,
  payload: payload,
});

export const createStudentCourseMapping = (payload) => ({
  type: STUDENT?.create_student_course_mapping,
  payload: payload,
});

export const createSuccessStudentCourseMapping = (payload) => ({
  type: STUDENT?.create_student_course_mapping_success,
  payload: payload,
});

export const createErrorStudentCourseMapping = (payload) => ({
  type: STUDENT?.create_student_course_mapping_error,
  payload: payload,
});

export const getStudentDetails = (payload) => ({
  type: STUDENT.get_details,
  payload: payload,
});

export const getSuccessStudentDetails = (payload) => ({
  type: STUDENT?.get_details_success,
  payload: payload,
});

export const getErrorStudentDetails = (payload) => ({
  type: STUDENT?.get_details_error,
  payload: payload,
});

export const resetStudentDetails = () => ({
  type: STUDENT?.reset_details,
});

export const requestUploadImage = (payload, data) => ({
  type: STUDENT?.upload_img,
  payload: payload,
  data: data,
});

export const successUploadImage = (payload) => ({
  type: STUDENT?.upload_img_success,
  payload: payload,
});

export const errorUploadImage = (payload) => ({
  type: STUDENT?.upload_img_error,
  payload: payload,
});

export const resetUploadImage = () => ({
  type: STUDENT?.reset_upload_img,
});

export const resetCreateStudent = () => ({
  type: STUDENT?.reset_create_student,
});

export const getFilteredUserDetailsAction = (payload) => ({
  type: USER_DETAILS.get_filtered_user_details,
  payload: payload,
});
export const resetFilteredUserDetails = (payload) => ({
  type: USER_DETAILS.reset_filtered_user_details,
  payload: payload,
});

export const getFilteredUserDetailsSuccess = (payload) => ({
  type: USER_DETAILS.get_filtered_user_details_success,
  payload: payload,
});

export const getFilteredUserDetailsError = (payload) => ({
  type: USER_DETAILS.get_filtered_user_details_error,
  payload: payload,
});

export const setStaffLoading = (payload) => ({
  type: STAFF.loading,
  payload: payload,
});

export const getStaffsList = (payload) => ({
  type: STAFF?.get,
  payload: payload,
});

export const getSuccessStaffsList = (payload) => ({
  type: STAFF?.get_success,
  payload: payload,
});

export const getErrorStaffsList = (payload) => ({
  type: STAFF?.get_error,
  payload: payload,
});

export const createStaff = (payload) => ({
  type: STAFF?.create,
  payload: payload,
});

export const createSuccessStaff = (payload) => ({
  type: STAFF?.create_success,
  payload: payload,
});

export const createErrorStaff = (payload) => ({
  type: STAFF?.create_error,
  payload: payload,
});

export const resetCreateStaff = () => ({
  type: STAFF?.reset_create_staff,
});

export const getStaffDetails = (payload) => ({
  type: STAFF?.get_details,
  payload: payload,
});

export const getSuccessStaffDetails = (payload) => ({
  type: STAFF?.get_details_success,
  payload: payload,
});

export const getErrorStaffDetails = (payload) => ({
  type: STAFF?.get_details_error,
  payload: payload,
});

export const resetStaffDetails = () => ({
  type: STAFF?.reset_details,
});

export const getDesignation = (payload) => ({
  type: STAFF?.get_designation,
  payload: payload,
});

export const getDesignationSuccess = (payload) => ({
  type: STAFF?.get_designation_success,
  payload: payload,
});

export const getDesignationError = (payload) => ({
  type: STAFF?.get_designation_error,
  payload: payload,
});

export const resetDesignation = () => ({
  type: STAFF?.reset_designation,
});

export const requestStaffUploadImage = (payload, data) => ({
  type: STAFF?.staff_upload_img,
  payload: payload,
  data: data,
});

export const successStaffUploadImage = (payload) => ({
  type: STAFF?.staff_upload_img_success,
  payload: payload,
});

export const errorStaffUploadImage = (payload) => ({
  type: STAFF?.staff_upload_img_error,
  payload: payload,
});

export const resetStaffUploadImage = () => ({
  type: STAFF?.staff_reset_upload_img,
});

export const deleteUser = (payload) => ({
  type: STAFF?.delete_user,
  payload: payload,
});

export const deleteUserSuccess = (payload) => ({
  type: STAFF?.delete_user_success,
  payload: payload,
});

export const deleteUserError = (payload) => ({
  type: STAFF?.delete_user_error,
  payload: payload,
});

export const resetDeleteUser = () => ({
  type: STAFF?.reset_delete_user,
});

export const getAllClustersList = (payload) => ({
  type: CLUSTERS?.get,
  payload: payload,
});

export const getSuccessAllClustersList = (payload) => ({
  type: CLUSTERS?.get_success,
  payload: payload,
});

export const geterrorAllClustersList = (payload) => ({
  type: CLUSTERS?.get_error,
  payload: payload,
});

export const resetAllClustersList = () => ({
  type: CLUSTERS?.reset,
});

export const getDepartmentbyId = (payload) => ({
  type: DEPARTMENTS?.get_by_id,
  payload: payload,
});

export const getSuccessDepartmentbyId = (payload) => ({
  type: DEPARTMENTS?.get_by_id_success,
  payload: payload,
});

export const getErrorDepartmentbyId = (payload) => ({
  type: DEPARTMENTS?.get_by_id_error,
  payload: payload,
});

export const resetDepartmentbyId = (payload) => ({
  type: DEPARTMENTS?.reset_by_id,
  payload: payload,
});

export const getCoursesbyId = (payload) => ({
  type: COURSES?.get_by_id,
  payload: payload,
});

export const getSuccessCoursesbyId = (payload) => ({
  type: COURSES?.get_by_id_success,
  payload: payload,
});

export const getErrorCoursesbyId = (payload) => ({
  type: COURSES?.get_by_id_error,
  payload: payload,
});

export const resetCoursesbyId = (payload) => ({
  type: COURSES?.reset_by_id,
  payload: payload,
});

export const userUploadValidation = (payload) => ({
  type: STUDENT?.upload_validation,
  payload: payload,
});

export const uploadValidationSuccess = (payload) => ({
  type: STUDENT?.upload_validation_success,
  payload: payload,
});

export const uploadValidationError = (payload) => ({
  type: STUDENT?.upload_validation_error,
  payload: payload,
});

export const resetUploadValidation = () => ({
  type: STUDENT?.reset_upload_validation,
});

export const requestUploadUser = (payload) => ({
  type: STUDENT?.upload_user,
  payload: payload,
});

export const successUploadUser = (payload) => ({
  type: STUDENT?.upload_user_success,
  payload: payload,
});

export const errorUploadUser = (payload) => ({
  type: STUDENT?.upload_user_error,
  payload: payload,
});

export const resetUploadUser = () => ({
  type: STUDENT?.reset_upload_user,
});

export const saveTenantNameAction = (payload) => ({
  type: AUTHENTICATION?.SAVE_TENANT_NAME,
  payload: payload,
});

export const saveLoginTypeAction = (payload) => ({
  type: AUTHENTICATION.SAVE_LOGIN_TYPE,
  payload: payload,
});

export const localUserLoginAction = (payload) => ({
  type: AUTHENTICATION?.LOCAL_USER_LOGIN,
  payload: payload,
});

export const localUserLoginSuccessAction = (payload) => ({
  type: AUTHENTICATION?.LOCAL_USER_LOGIN_SUCCESS,
  payload: payload,
});

export const localUserLogoutAction = (payload) => ({
  type: AUTHENTICATION?.LOCAL_USER_LOGOUT,
  payload: payload,
});

export const localUserLogoutSuccessAction = (payload) => ({
  type: AUTHENTICATION?.LOCAL_USER_LOGOUT_SUCCESS,
  payload: payload,
});

export const studentsListLoading = (payload) => ({
  type: STUDENT?.loading,
  payload: payload,
});

export const staffsListLoading = (payload) => ({
  type: STAFF?.loading,
  payload: payload,
});

export const resetFacultyDetails = (payload) => ({
  type: USER_DETAILS.reset_all_faculties,
  payload: payload,
});

// get tenant specific and sponsor logo's

export const getTenantImagesRequest = (payload) => ({
  type: TENANT_SPECIFIC_LOGO.TENANT_SPECIFIC_LOGO_REQUEST,
  payload: payload,
});

export const getTenantImagesSuccess = (payload) => ({
  type: TENANT_SPECIFIC_LOGO.TENANT_SPECIFIC_LOGO_SUCCESS,
  payload: payload,
});

export const getTenantImagesReset = () => ({
  type: TENANT_SPECIFIC_LOGO.TENANT_SPECIFIC_LOGO_RESET,
});

export const changeStudentRequest = (payload) => ({
  type: CHANGE_STUDENT.CHANGE_STUDENT_REQUEST,
  payload: payload,
});

export const updateCandidateToStudentAction = (payload) => ({
  type: CANDIDATE_STUDENT.CANDIDATE_STUDENT_GET,
  payload: payload,
});

export const updateCandidateToStudentSuccess = (payload) => ({
  type: CANDIDATE_STUDENT.CANDIDATE_STUDENT_SUCCESS,
  payload: payload,
});

export const updateCandidateToStudentError = (payload) => ({
  type: CANDIDATE_STUDENT.CANDIDATE_STUDENT_ERROR,
  payload: payload,
});

export const getCandidatePendingAction = (payload) => ({
  type: CANDIDATE_ONBOARDING_PENDING.CANDIDATE_ONBOARDING_GET,
  payload: payload,
});

export const getCandidatePendingSuccess = (payload) => ({
  type: CANDIDATE_ONBOARDING_PENDING.CANDIDATE_ONBOARDING_SUCCESS,
  payload: payload,
});

export const getCandidatePendingError = (payload) => ({
  type: CANDIDATE_ONBOARDING_PENDING.CANDIDATE_ONBOARDING_ERROR,
  payload: payload,
});

export const getCandidateOnboardedAction = (payload) => ({
  type: CANDIDATE_ONBOARDED.CANDIDATE_ONBOARDED_GET,
  payload: payload,
});

export const getCandidateOnboardedSuccess = (payload) => ({
  type: CANDIDATE_ONBOARDED.CANDIDATE_ONBOARDED_SUCCESS,
  payload: payload,
});

export const getCandidateOnboardedError = (payload) => ({
  type: CANDIDATE_ONBOARDED.CANDIDATE_ONBOARDED_ERROR,
  payload: payload,
});

export const getCandidateLoginOtp = (payload) => ({
  type: CANDIDATE_LOGIN.SEND_OTP,
  payload: payload,
});

export const setCandidateLoginOtpSuccess = (payload) => ({
  type: CANDIDATE_LOGIN.SEND_OTP_SUCCESS,
  payload: payload,
});

export const setCandidateLoginOtpError = (payload) => ({
  type: CANDIDATE_LOGIN.SEND_OTP_ERROR,
  payload: payload,
});

export const getCandidateLoginVerifyOtp = (payload) => ({
  type: CANDIDATE_LOGIN.VERIFY_OTP,
  payload: payload,
});

export const setCandidateLoginVerifyOtpSuccess = (payload) => ({
  type: CANDIDATE_LOGIN.VERIFY_OTP_SUCCESS,
  payload: payload,
});

export const setCandidateLoginVerifyOtpError = (payload) => ({
  type: CANDIDATE_LOGIN.VERIFY_OTP_ERROR,
  payload: payload,
});

export const resetCandidateLogin = () => ({
  type: CANDIDATE_LOGIN.RESET_CANDIDATE_LOGIN,
});

export const getCandidateInfoById = (payload) => ({
  type: CANDIDATE_LOGIN.CANDIDATE_INFORMATION,
  payload,
});

// get user list for HR

export const getUserListForHRRequest = (payload) => ({
  type: HR.GET_ALL_USER_REQUEST,
  payload: payload,
});

export const getUserListForHRSuccess = (payload) => ({
  type: HR.GET_ALL_USER_SUCCESS,
  payload: payload,
});

export const getUserListForHRError = (payload) => ({
  type: HR.GET_ALL_USER_ERROR,
  payload: payload,
});

//get user role list for HR
export const getAllUserRolesAction = (payload) => ({
  type: ALL_USER_ROLE.GET_ALL_USER_ROLES,
  payload: payload,
});

export const getAllUserRolesSuccess = (payload) => ({
  type: ALL_USER_ROLE.GET_ALL_USER_ROLES_SUCCESS,
  payload: payload,
});

export const getAllUserRolesError = (payload) => ({
  type: ALL_USER_ROLE.GET_ALL_USER_ROLES_ERROR,
  payload: payload,
});
export const getAllUserRolesReset = (payload) => ({
  type: ALL_USER_ROLE.GET_ALL_USER_ROLES_RESET,
  payload: payload,
});

//delete user role list for HR
export const deleteUserRolesAction = (payload) => ({
  type: DELETE_USER_ROLE.DELETE_USER_ROLES,
  payload: payload,
});

export const deleteUserRolesSuccess = (payload) => ({
  type: DELETE_USER_ROLE.DELETE_USER_ROLES_SUCCESS,
  payload: payload,
});

export const deleteUserRolesError = (payload) => ({
  type: DELETE_USER_ROLE.DELETE_USER_ROLES_ERROR,
  payload: payload,
});

//User Role details

export const getUserRoleDetailsAction = (payload) => ({
  type: USER_ROLE_DETAILS.GET_USER_ROLE_DETAILS,
  payload: payload,
});
export const resetUserRoleDetailsAction = (payload) => ({
  type: USER_ROLE_DETAILS.RESET_CREATE_USER_ROLE,
  payload: payload,
});

export const getUserRoleDetailsSuccess = (payload) => ({
  type: USER_ROLE_DETAILS.GET_USER_ROLE_DETAILS_SUCCESS,
  payload: payload,
});

export const getUserRoleDetailsError = (payload) => ({
  type: USER_ROLE_DETAILS.GET_USER_ROLE_DETAILS_ERROR,
  payload: payload,
});

//Create User Role

export const createUserRoleAction = (payload) => ({
  type: CREATE_USER_ROLES.CREATE_USER_ROLE,
  payload: payload,
});

export const createUserRoleSuccess = (payload) => ({
  type: CREATE_USER_ROLES.CREATE_USER_ROLE_SUCCESS,
  payload: payload,
});

export const createUserRoleError = (payload) => ({
  type: CREATE_USER_ROLES.CREATE_USER_ROLE_ERROR,
  payload: payload,
});

export const resetCreateUserRole = () => ({
  type: CREATE_USER_ROLES?.RESET_CREATE_USER_ROLE,
});

//Update User Role

export const updateUserRoleAction = (payload) => ({
  type: UPDATE_USER_ROLES.UPDATE_USER_ROLE,
  payload: payload,
});

export const updateUserRoleSuccess = (payload) => ({
  type: UPDATE_USER_ROLES.UPDATE_USER_ROLE_SUCCESS,
  payload: payload,
});

export const updateUserRoleError = (payload) => ({
  type: UPDATE_USER_ROLES.UPDATE_USER_ROLE_ERROR,
  payload: payload,
});

//Create user group
export const createGroup = (payload) => ({
  type: GROUP.CREATE_GROUP,
  payload: payload,
});

export const createSuccessGroup = (payload) => ({
  type: GROUP.SUCCESS_CREATE_GROUP,
  payload: payload,
});

export const createErrorGroup = (payload) => ({
  type: GROUP.ERROR_CREATE_GROUP,
  payload: payload,
});

//get Group details
export const getGroupDetails = (payload) => ({
  type: GROUP.get_group_details,
  payload: payload,
});

export const getGroupDetailsSuccess = (payload) => ({
  type: GROUP.get_group_details_success,
  payload: payload,
});

export const getGroupDetailsError = (payload) => ({
  type: GROUP.get_group_details_error,
  payload: payload,
});

//get group by id
export const getGroupDetailsById = (payload) => ({
  type: GROUP.get_group_details_by_id,
  payload: payload,
});

export const getGroupDetailsByIdSuccess = (payload) => ({
  type: GROUP.get_group_details_by_id_success,
  payload: payload,
});

export const getGroupDetailsByIdError = (payload) => ({
  type: GROUP.get_group_details_by_id_error,
  payload: payload,
});

export const getGroupDetailsByIdReset = () => ({
  type: GROUP.get_group_details_by_id_reset,
});

//Edit User group
export const editGroup = (payload) => ({
  type: GROUP.EDIT_GROUP,
  payload: payload,
});

export const editGroupSuccess = (payload) => ({
  type: GROUP.SUCCESS_EDIT_GROUP,
  payload: payload,
});

export const editGroupError = (payload) => ({
  type: GROUP.ERROR_EDIT_GROUP,
  payload: payload,
});

//Delete user group
export const deleteGroup = (payload) => ({
  type: GROUP.DELETE_GROUP,
  payload: payload,
});

export const deleteGroupSuccess = (payload) => ({
  type: GROUP.SUCCESS_DELETE_GROUP,
  payload: payload,
});

export const deleteGroupError = (payload) => ({
  type: GROUP.ERROR_DELETE_GROUP,
  payload: payload,
});

export const searchUser = (payload) => ({
  type: GROUP.SEARCH_USER,
  payload: payload,
});

export const searchUserSuccess = (payload) => ({
  type: GROUP.SEARCH_USER_SUCCESS,
  payload: payload,
});

export const searchUserError = (payload) => ({
  type: GROUP.SEARCH_USER_ERROR,
  payload: payload,
});
//filter user based on cluster and semester
export const filterUser = (payload) => ({
  type: GROUP.APPLY_FILTER,
  payload: payload,
});

export const filterUserSuccess = (payload) => ({
  type: GROUP.APPLY_FILTER_SUCCESS,
  payload: payload,
});

export const filterUserError = (payload) => ({
  type: GROUP.APPLY_FILTER_ERROR,
  payload: payload,
});
//reset
export const resetFilter = () => ({
  type: GROUP.RESET_FILTERE,
});

export const filterAllUser = (payload) => ({
  type: GROUP.APPLY_ALL_FILTER,
  payload: payload,
});

export const filterAllUserSuccess = (payload) => ({
  type: GROUP.APPLY_ALL_FILTER_SUCCESS,
  payload: payload,
});

export const filterAllUserError = (payload) => ({
  type: GROUP.APPLY_ALL_FILTER_ERROR,
  payload: payload,
});

//reset group by id
export const resetGroupById = () => ({
  type: GROUP.RESET_GROUP_BY_ID,
});

//remove user
export const removeUser = (groupId, userData) => ({
  type: GROUP.REMOVE_USER,
  payload: { groupId, userData },
});

export const setRemoveUserSuccess = (payload) => ({
  type: GROUP.SET_REMOVE_USER_SUCCESS,
  payload: payload,
});

export const setRemoveUserError = (payload) => ({
  type: GROUP.SET_REMOVE_USER_ERROR,
  payload: payload,
});

export const updateStaff = (payload) => ({
  type: STAFF?.update,
  payload: payload,
});
export const updateStaffSuccess = (payload) => ({
  type: STAFF?.update_success,
  payload: payload,
});
export const updateStaffError = (payload) => ({
  type: STAFF?.update_error,
  payload: payload,
});

export const blockStaff = (payload) => ({
  type: STAFF?.block,
  payload: payload,
});
export const blockStaffSuccess = (payload) => ({
  type: STAFF?.block_success,
  payload: payload,
});
export const blockStaffError = (payload) => ({
  type: STAFF?.block_error,
  payload: payload,
});

export const deleteStudent = (payload) => ({
  type: STUDENT?.delete_student,
  payload: payload,
});
export const deleteStudentSuccess = (payload) => ({
  type: STUDENT?.delete_student_success,
  payload: payload,
});
export const deleteStudentError = (payload) => ({
  type: STUDENT?.delete_student_error,
  payload: payload,
});
//add selected group
export const addSelectedUser = (payload) => ({
  type: GROUP.ADD_SELECTED_USER_TO_GROUP,
  payload: payload,
});

export const setAddSelectedUserSuccess = (payload) => ({
  type: GROUP.SET_ADD_SELECTED_USER_TO_GROUP_SUCCESS,
  payload: payload,
});

export const setAddSelectedUserError = (payload) => ({
  type: GROUP.SET_ADD_SELECTED_USER_TO_GROUP_ERROR,
  payload: payload,
});

export const forgotPasswordRequest = (payload) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST,
  payload: payload,
});

export const forgotPasswordSuccess = (payload) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS,
  payload: payload,
});

export const forgotPasswordError = (payload) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_ERROR,
  payload: payload,
});

export const userGrpBulkUpload = (formData) => {
  return {
    type: 'USER_GROUP_BULK_UPLOAD_REQUEST',
    payload: formData,
  };
};
export const userGrpBulkUploadSuccess = (payload) => ({
  type: GROUP.SET_USER_GROUP_BULK_UPLOAD_SUCCESS,
  payload: payload,
});
export const userGrpBulkUploadError = (payload) => ({
  type: GROUP.SET_USER_GROUP_BULK_UPLOAD_ERROR,
  payload: payload,
});

// Login Actions
export const getLoginDetails = (payload) => ({
  type: USER_LOGIN_DETAILS.USER_LOGIN_DETAILS_REQUEST,
  payload: payload,
});
export const getLoginDetailsSuccess = (payload) => ({
  type: USER_LOGIN_DETAILS.USER_LOGIN_DETAILS_SUCCESS,
  payload: payload,
});
export const getLoginDetailsError = (payload) => ({
  type: USER_LOGIN_DETAILS.USER_LOGIN_DETAILS_ERROR,
  payload: payload,
});

//
export const geAnyDetails = (payload) => ({
  type: ANY_USER_DETAILS.ANY_USER_DETAILS_REQUEST,
  payload: payload,
});
export const geAnyDetailsSuccess = (payload) => ({
  type: ANY_USER_DETAILS.ANY_USER_DETAILS_SUCCESS,
  payload: payload,
});
export const geAnyDetailsError = (payload) => ({
  type: ANY_USER_DETAILS.ANY_USER_DETAILS_ERROR,
  payload: payload,
});
export const geAnyDetailsReset = () => ({
  type: ANY_USER_DETAILS.ANY_USER_DETAILS_RESET,
});

export const deactivateGroup = (payload) => ({
  type: GROUP.DEACTIVATE_GROUP,
  payload: payload,
});
export const deactivateGroupSuccess = (payload) => ({
  type: GROUP.SET_DEACTIVATE_GROUP_SUCCESS,
  payload: payload,
});
export const deactivateGroupError = (payload) => ({
  type: GROUP.SET_DEACTIVATE_GROUP_ERROR,
  payload: payload,
});

// STudent Bulk Upload
export const studentBulkUpload = (payload) => ({
  type: STUDENT.bulk_upload_student,
  payload: payload,
});
export const studentBulkUploadSuccess = (payload) => ({
  type: STUDENT.bulk_upload_student_success,
  payload: payload,
});
export const studentBulkUploadError = (payload) => ({
  type: STUDENT.bulk_upload_student_error,
  payload: payload,
});
export const studentBulkUploadReset = () => ({
  type: STUDENT.bulk_upload_student_reset,
});

// Bulk User Role Upload
export const bulkUserRoleAction = (payload) => ({
  type: BULK_USER_ROLES.BULK_USER_ROLE_REQUEST,
  payload: payload,
});

export const bulkUserRoleSuccess = (payload) => ({
  type: BULK_USER_ROLES.BULK_USER_ROLE_SUCCESS,
  payload: payload,
});

export const bulkUserRoleError = (payload) => ({
  type: BULK_USER_ROLES.BULK_USER_ROLE_ERROR,
  payload: payload,
});

export const setTheoryState = (payload) => ({
  type: STAFF.set_theory_state,
  payload: payload,
});
