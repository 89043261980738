import { takeLatest, put, call, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import { CounsellorActions } from './Counsellor.constants';
import {
  setCounsellorLoading,
  getCounsellorStatsSuccess,
  getCounsellorStatsError,
  getCounsellorLeadSuccess,
  getCounsellorLeadError,
  getInterestedProspectusPriceSuccess,
  getInterestedProspectusPriceError,
  addSelectedProspectSuccess,
  addSelectedProspectError,
  getSelectedProspectListSuccess,
  getSelectedProspectListError,
  deleteSelectedProspectSuccess,
  deleteSelectedProspectError,
  sendProspectPaymentLinkSuccess,
  sendProspectPaymentLinkError,
  updatePaymentLinkSuccess,
  updatePaymentLinkError,
  startCandidateCounsellingSuccess,
  startCandidateCounsellingError,
  sendToVerifierSuccess,
  sendToVerifierError,
  getLeadCurrentStageSuccess,
  getLeadCurrentStageError,
  sendProspectFormOnlineError,
  sendProspectFormOnlineSuccess,
  getAppFormDetailsSuccess,
  getAppFormDetailsError,
} from './Counsellor.action';

import {
  getCounsellorStatsApi,
  getCounsellorLeadsApi,
  getInterestedProspectusPriceApi,
  addSelectedProspectApi,
  getSelectedProspectList,
  deleteSelectedProspectApi,
  sendProspectPaymentLinkApi,
  updatePaymentLinkApi,
  startCandidateCounsellingApi,
  sendToVerifierApi,
  getLeadCurrentStageApi,
  sendProspectFormOnlineApi,
  getAppFormDetailsApi,
} from './Counsellor.api';

import { setToastMessage } from 'store/Toast';

const { COUNSELLOR } = CounsellorActions;

export function* getCounsellorStatsSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(getCounsellorStatsApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      //success
      yield put(getCounsellorStatsSuccess(response?.data));
    } else {
      // error
      yield put(getCounsellorStatsError(response?.error));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getCounsellorLeadsSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(getCounsellorLeadsApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getCounsellorLeadSuccess(response?.data));
    } else {
      //error
      yield put(getCounsellorLeadError(response));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getInterestedProspectusPriceSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(getInterestedProspectusPriceApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getInterestedProspectusPriceSuccess(response?.data));
    } else {
      //error
      yield put(getInterestedProspectusPriceError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to add prospect',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addSelectedProspectSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(addSelectedProspectApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(addSelectedProspectSuccess(response?.data));
    } else {
      //error
      yield put(addSelectedProspectError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to add prospect',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getSelectedListSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(getSelectedProspectList, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSelectedProspectListSuccess(response?.data));
    } else {
      //error
      yield put(getSelectedProspectListError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to get prospect list',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteSelectedProspectSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(deleteSelectedProspectApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteSelectedProspectSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Prospect deleted successfully',
          severity: 'success',
        })
      );
    } else {
      //error
      yield put(deleteSelectedProspectError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to delete prospect',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* sendProspectPaymentLinkSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(sendProspectPaymentLinkApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(sendProspectPaymentLinkSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Payment link send',
          severity: 'success',
        })
      );
    } else {
      //error
      yield put(sendProspectPaymentLinkError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to send payment link',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updatePaymentLinkSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(updatePaymentLinkApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updatePaymentLinkSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Payment Link updated',
          severity: 'success',
        })
      );
    } else {
      //error
      yield put(updatePaymentLinkError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to update payment link',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* startCandidateCounsellingSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(startCandidateCounsellingApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(startCandidateCounsellingSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Counselling started',
          severity: 'success',
        })
      );
    } else {
      //error
      yield put(startCandidateCounsellingError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to start counselling',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* sendProspectFormOnlineSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(sendProspectFormOnlineApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(sendProspectFormOnlineSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success ?? 'Email sent',
          severity: 'success',
        })
      );
    } else {
      //error
      yield put(sendProspectFormOnlineError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to send email',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* sendToVerifierSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(sendToVerifierApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(sendToVerifierSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Sent to Verifier',
          severity: 'success',
        })
      );
    } else {
      //error
      yield put(sendToVerifierError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to sent',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getLeadCurrentStageSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    const response = yield call(getLeadCurrentStageApi, payload);
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getLeadCurrentStageSuccess(response?.data));
    } else {
      //error
      yield put(getLeadCurrentStageError(response?.error));
      yield put(
        setToastMessage({
          data: response?.error?.message ?? 'Failed to get current stage',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getAppFormDetailsSaga({ payload }) {
  try {
    yield put(setCounsellorLoading(true));
    //const response = yield call(getAppFormDetailsApi, payload);
    const response = {
      status: 200,
      data: {
        results: [
          {
            academic_year: '2022-2023',
            application_form: 'E&TC',
          },
        ],
      },
    };
    yield put(setCounsellorLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getAppFormDetailsSuccess(response?.data));
    } else {
      //error
      yield put(getAppFormDetailsError(response?.error));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchDummy() {
  yield takeLatest(COUNSELLOR.GET_COUNSELLOR_STATS, getCounsellorStatsSaga);
  yield takeLatest(COUNSELLOR.GET_COUNSELLOR_LEADS, getCounsellorLeadsSaga);
  yield takeLatest(COUNSELLOR.ADD_SELECTED_PROSPECT, addSelectedProspectSaga);
  yield takeLatest(
    COUNSELLOR.GET_INTERESTED_PROSPECTUS,
    getInterestedProspectusPriceSaga
  );
  yield takeLatest(COUNSELLOR.GET_SELECTED_PROSPECT_LIST, getSelectedListSaga);
  yield takeLatest(
    COUNSELLOR.DELETE_SELECTED_PROSPECT,
    deleteSelectedProspectSaga
  );
  yield takeLatest(COUNSELLOR.SEND_PAYMENT_LINK, sendProspectPaymentLinkSaga);
  yield takeLatest(COUNSELLOR.UPDATE_PAYMENT_LINK, updatePaymentLinkSaga);
  yield takeLatest(
    COUNSELLOR.START_CANDIDATE_COUNSELLING,
    startCandidateCounsellingSaga
  );
  yield takeLatest(COUNSELLOR.SEND_TO_VERIFIER, sendToVerifierSaga);
  yield takeLatest(COUNSELLOR.GET_CURRENT_STAGE, getLeadCurrentStageSaga);
  yield takeLatest(COUNSELLOR.SEND_PROSPECT_ONLINE, sendProspectFormOnlineSaga);
  yield takeLatest(COUNSELLOR.GET_APP_FORM_DETAILS, getAppFormDetailsSaga);
}

export default function* counsellorSaga() {
  yield all([watchDummy()]);
}
