import React from 'react';

const VideoLibraryIcon = ({ fill, height = 30, width = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      fill={fill}
    >
      <path d="m460 676 280-180-280-180v360ZM320 816q-33 0-56.5-23.5T240 736V256q0-33 23.5-56.5T320 176h480q33 0 56.5 23.5T880 256v480q0 33-23.5 56.5T800 816H320Zm0-80h480V256H320v480ZM160 976q-33 0-56.5-23.5T80 896V336h80v560h560v80H160Zm160-720v480-480Z" />
    </svg>
  );
};

export default VideoLibraryIcon;
