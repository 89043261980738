import axiosInstance from 'translator';
import { userProfileAPI } from 'store/UserProfile';

export const getUserProfileApi = async () => {
  return axiosInstance
    .get(`${userProfileAPI?.getUserProfile}`)
    .then((response) => {
      console.log(response.data);
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserProfileApi = async (payload) => {
  const id = payload?.id;
  return axiosInstance
    .put(`${userProfileAPI?.updateUserProfile}/${id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createChangePasswordApi = async (payload) => {
  const params = { ...payload };
  delete params.userId;
  return axiosInstance
    .put(`${userProfileAPI?.createChangePassword}/${payload.userId}`, params)
    .then((response) => {
      return response;
    });
};

export const getSocialInformationsApi = async (payload) => {
  return axiosInstance
    .get(`${userProfileAPI?.getSocialInformations}/${payload.userId}`)
    .then((response) => {
      console.log(response.data);
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postSocialDetailsApi = async (payload) => {
  return axiosInstance
    .post(`${userProfileAPI?.createSocialDetails}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
