import React from 'react';

const MultipleUsersSvg = ({ fill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.683"
      height="26.472"
      viewBox="0 0 37.683 26.472"
      fill={fill}
    >
      <path
        id="group_FILL0_wght400_GRAD0_opsz48"
        d="M1.9,35.422V31.415a4.965,4.965,0,0,1,.767-2.707A4.67,4.67,0,0,1,4.8,26.9,33.8,33.8,0,0,1,10.4,24.935a21.993,21.993,0,0,1,5.137-.6,21.725,21.725,0,0,1,5.115.6A34.085,34.085,0,0,1,26.24,26.9a4.8,4.8,0,0,1,2.153,1.812,4.866,4.866,0,0,1,.789,2.707v4.007Zm29.839,0V31.415A6.9,6.9,0,0,0,30.375,27a9.984,9.984,0,0,0-3.581-2.792,42.274,42.274,0,0,1,5.542,1,19.533,19.533,0,0,1,4.22,1.513,6.6,6.6,0,0,1,2.217,2,4.673,4.673,0,0,1,.81,2.686v4.007Zm-16.2-13.683a6.133,6.133,0,0,1-6.394-6.394A6.133,6.133,0,0,1,15.541,8.95a6.133,6.133,0,0,1,6.394,6.394,6.133,6.133,0,0,1-6.394,6.394Zm15.346-6.394a6.133,6.133,0,0,1-6.394,6.394,9.532,9.532,0,0,1-1.044-.064A4.509,4.509,0,0,1,22.4,21.44a7,7,0,0,0,1.556-2.622,10.676,10.676,0,0,0,.533-3.474,9.922,9.922,0,0,0-.533-3.389A9.062,9.062,0,0,0,22.4,9.248a9,9,0,0,1,1.044-.213,7.184,7.184,0,0,1,1.044-.085,6.133,6.133,0,0,1,6.394,6.394ZM4.458,32.864H26.624V31.415a2.438,2.438,0,0,0-.4-1.321,2.228,2.228,0,0,0-1-.9,28.783,28.783,0,0,0-5.158-1.833,20.613,20.613,0,0,0-4.519-.469,20.9,20.9,0,0,0-4.54.469A28.529,28.529,0,0,0,5.822,29.2a2.114,2.114,0,0,0-.98.9,2.535,2.535,0,0,0-.384,1.321ZM15.541,19.181a3.718,3.718,0,0,0,3.836-3.836,3.718,3.718,0,0,0-3.836-3.836A3.718,3.718,0,0,0,11.7,15.344a3.718,3.718,0,0,0,3.836,3.836ZM15.541,32.864ZM15.541,15.344Z"
        transform="translate(-1.9 -8.95)"
        fill="#ac0521"
      />
    </svg>
  );
};

export default MultipleUsersSvg;
