import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  scheduleActions,
  getCourseScheduleApi,
  getCourseScheduleSuccess,
  getCourseScheduleError,
  getModuleScheduleApi,
  getModuleScheduleSuccess,
  getModuleScheduleError,
  getScheduleApprovalsApi,
  getScheduleApprovalsSuccess,
  getScheduleApprovalsError,
  postTimeTableApi,
  postTimeTableSuccess,
  postTimeTableError,
  getTimeTableApi,
  getTimeTableSuccess,
  getTimeTableError,
  getFacultyTimeTableApi,
  getFacultyScheduleSuccess,
  getFacultyScheduleError,
  putScheduleStatusApi,
  putScheduleStatusSuccess,
  putScheduleStatusError,
  getPastConnectionsListApi,
  getSuccessPastConnectionsList,
  getErrorPastConnectionsList,
  deletePastConnectionApi,
  deleteSuccessPastConnection,
  deleteErrorPastConnection,
  createPastNotificationApi,
  createSuccessPastConnection,
  createErrorPastConnection,
  createPastConnectionApi,
  pastConnectionLoadng,
  getScheduleApprovalsByScrollSuccess,
  getScheduleApprovalsByScroll,
  getDeptUsersApi,
  getUsersUnderDeptSuccess,
  getUsersUnderDeptError,
  getStaffUnderDeptApi,
  getStaffUnderDeptSuccess,
  getStaffUnderDeptError,
} from 'store/Schedule';
import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';

function* getCourseScheduleSaga({ payload }) {
  try {
    const response = yield call(getCourseScheduleApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseScheduleSuccess(response?.data));
    } else {
      yield put(getCourseScheduleError(response?.data));
    }
  } catch (error) {
    // setNotification({
    //   data: i18n.t('server-error'),
    //   severity: 'error',
    // })
  }
}

function* getModuleScheduleSaga({ payload }) {
  try {
    const response = yield call(getModuleScheduleApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getModuleScheduleSuccess(response?.data));
    } else {
      yield put(getModuleScheduleError(response?.data));
    }
  } catch (error) {
    // setNotification({
    //   data: i18n.t('server-error'),
    //   severity: 'error',
    // })
  }
}

function* getScheduleApprovalsSaga({ payload }) {
  try {
    const response = yield call(getScheduleApprovalsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getScheduleApprovalsSuccess(response?.data));
    } else {
      yield put(getScheduleApprovalsError(response?.data));
    }
  } catch (error) {
    // setNotification({
    //   data: i18n.t('server-error'),
    //   severity: 'error',
    // })
  }
}

function* getScheduleApprovalsByScrollSaga({ payload }) {
  try {
    const response = yield call(getScheduleApprovalsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getScheduleApprovalsByScrollSuccess(response?.data));
    } else {
      yield put(getScheduleApprovalsByScroll(response?.data));
    }
  } catch (error) {
    // setNotification({
    //   data: i18n.t('server-error'),
    //   severity: 'error',
    // })
  }
}

function* getTimeTableSaga({ payload }) {
  try {
    const response = yield call(getTimeTableApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getTimeTableSuccess(response?.data));
    } else {
      yield put(getTimeTableError(response?.data));
    }
  } catch (error) {
    // setNotification({
    //   data: i18n.t('server-error'),
    //   severity: 'error',
    // })
  }
}

function* postTimeTableSaga({ payload }) {
  try {
    const response = yield call(postTimeTableApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(postTimeTableSuccess(response?.data));
    } else {
      yield put(postTimeTableError(response?.data));
    }
  } catch (error) {
    // setNotification({
    //   data: i18n.t('server-error'),
    //   severity: 'error',
    // })
  }
}

function* getFacultyScheduleSaga({ payload }) {
  try {
    const response = yield call(getFacultyTimeTableApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyScheduleSuccess(response?.data));
    } else {
      yield put(getFacultyScheduleError(response?.data));
    }
  } catch (error) {
    // setNotification({
    //   data: i18n.t('server-error'),
    //   severity: 'error',
    // })
  }
}

function* putScheduleStatusSaga({ payload }) {
  try {
    const response = yield call(putScheduleStatusApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(putScheduleStatusSuccess(response?.data));
    } else {
      yield put(putScheduleStatusError(response?.data));
    }
  } catch (error) {
    // setNotification({
    //   data: i18n.t('server-error'),
    //   severity: 'error',
    // })
  }
}

function* getPastConnectionsListSaga({ payload }) {
  try {
    yield put(pastConnectionLoadng(true));
    const response = yield call(getPastConnectionsListApi, payload);
    yield put(pastConnectionLoadng(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessPastConnectionsList(response?.data));
    } else {
      yield put(getErrorPastConnectionsList(response?.data));
    }
  } catch (error) {
    yield put(getErrorPastConnectionsList(error?.data));
  }
}

function* deletePastConnectionSaga({ payload }) {
  try {
    // yield put(pastConnectionLoadng(true));
    const response = yield call(deletePastConnectionApi, payload);
    // yield put(pastConnectionLoadng(false));
    if (response?.status === statusCode.NoContent204) {
      yield put(deleteSuccessPastConnection({ isDeleted: true }));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(deleteErrorPastConnection(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(deleteErrorPastConnection(error?.data));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

function* createPastConnectionSaga({ payload }) {
  try {
    const response = yield call(createPastConnectionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(createSuccessPastConnection(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(createErrorPastConnection(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(createErrorPastConnection(error?.data));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

function* getUsersUnderDeptSaga({ payload }) {
  try {
    const response = yield call(getDeptUsersApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUsersUnderDeptSuccess(response?.data));
    } else {
      yield put(getUsersUnderDeptError(response?.data));
    }
  } catch (error) {
    yield put(getUsersUnderDeptError(error?.data));
  }
}

function* getStaffUnderDeptSaga({ payload }) {
  try {
    const response = yield call(getStaffUnderDeptApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStaffUnderDeptSuccess(response?.data));
    } else {
      yield put(getStaffUnderDeptError(response?.data));
    }
  } catch (error) {
    yield put(getStaffUnderDeptError(error?.data));
  }
}

export function* watchDummy() {
  yield takeLatest(scheduleActions.COURSE_SCHEDULE.get, getCourseScheduleSaga);
  yield takeLatest(scheduleActions.MODULE_SCHEDULE.get, getModuleScheduleSaga);
  yield takeLatest(
    scheduleActions.SCHEDULE_APPROVALS.get,
    getScheduleApprovalsSaga
  );
  yield takeLatest(
    scheduleActions.SCHEDULE_APPROVALS.get_by_scroll,
    getScheduleApprovalsByScrollSaga
  );
  yield takeLatest(scheduleActions.USER_TIMETABLE.get, getTimeTableSaga);
  yield takeLatest(scheduleActions.USER_TIMETABLE.create, postTimeTableSaga);
  yield takeLatest(
    scheduleActions.USER_TIMETABLE.get_faculty,
    getFacultyScheduleSaga
  );
  yield takeLatest(
    scheduleActions.SCHEDULE_APPROVALS.put_status,
    putScheduleStatusSaga
  );
  yield takeLatest(
    scheduleActions.STUDENT_CONNECT.get,
    getPastConnectionsListSaga
  );
  yield takeLatest(
    scheduleActions.STUDENT_CONNECT.delete,
    deletePastConnectionSaga
  );
  yield takeLatest(
    scheduleActions.STUDENT_CONNECT.create,
    createPastConnectionSaga
  );
  yield takeLatest(scheduleActions.USERS_UNDER_DEPT.get, getUsersUnderDeptSaga);
  yield takeLatest(
    scheduleActions.USERS_UNDER_DEPT.get_staff,
    getStaffUnderDeptSaga
  );
}

export default function* studentSaga() {
  yield all([watchDummy()]);
}
