import axiosInstance from 'translator';
import { calendarAPI } from 'store/Calendar';

export const getEventsApi = async (payload) => {
  return axiosInstance
    .get(`${calendarAPI?.calenders}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createCalendarEventApi = async (payload) => {
  return axiosInstance
    .post(`${calendarAPI?.createEvent}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCalendarEventApi = async (payload) => {
  return axiosInstance
    .put(`${calendarAPI?.updateEvent}/${payload?.id}`, payload?.newEvent)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteCalendarEventApi = async (payload) => {
  return axiosInstance
    .delete(`${calendarAPI?.deleteCalendarEventApi}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCombinedListApi = async () => {
  return axiosInstance
    .get(`${calendarAPI.getCombinedList}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getEmailListApi = async (payload) => {
  return axiosInstance
    .get(`${calendarAPI.getEmailList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
