import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import {
  Button,
  Grid,
  Typography,
} from 'iiht-b2a-ui-components/build/components';
import React from 'react';
const GridHeader = ({ title, buttonTitle, href, className }) => {
  return (
    <Grid xl={12} md={12} container item>
      <Grid item xl={7} md={7}>
        <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
      </Grid>

      <Grid item xl={3} md={3} display="flex" justifyContent="end">
        <GridToolbarQuickFilter />
      </Grid>

      <Grid item xl={2} md={2} display="flex" justifyContent="end">
        <Button
          variant="contained"
          color="secondary"
          href={href}
          className={className}
        >
          {buttonTitle}
        </Button>
      </Grid>
    </Grid>
  );
};
export default GridHeader;
