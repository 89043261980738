import React from 'react';
import useStyles from './ReadingThumbnail.classes';
import { theme } from 'themes';
import {
  Card,
  Link,
  Tooltip,
  Grid,
  Typography,
} from 'iiht-b2a-ui-components/build/components';
import { Delete, Download } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentViewerInModal from '../DocumentViewer/DocumentViewerInModal';
import { DocumentViewerWithFetchUrl } from '../DocumentViewer';

const ReadingThumbnail = ({
  fileUrl = '',
  fileName = '',
  attachmentModalName = '',
  isCardView = false,
  auto = false,
  showDelete,
  onDelete,
  fileId,
  fetchUrl,
  isFileDownloadAvailable = false,
}) => {
  const classes = useStyles(theme);
  const [attachment, setAttachment] = useState([]);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleViewDocument = (fileUrl, fileName) => {
    setAttachmentModalOpen(true);
    let fileObj = {
      fileName: fileName,
      fileURL: fileUrl,
      fileType: fileName?.split('.')[1],
    };
    setAttachment([fileObj]);
  };

  const attachmentCloseHandler = () => {
    setAttachmentModalOpen(false);
    setAttachment([]);
  };

  return (
    <>
      {attachmentModalOpen &&
        (fetchUrl ? (
          <DocumentViewerWithFetchUrl
            isOpen={attachmentModalOpen}
            modalName={t(attachmentModalName)}
            attachmentList={attachment}
            closeHandler={attachmentCloseHandler}
            fetchUrl={fetchUrl}
            isFileDownloadAvailable={isFileDownloadAvailable}
          />
        ) : (
          <DocumentViewerInModal
            isOpen={attachmentModalOpen}
            modalName={t(attachmentModalName)}
            attachmentList={attachment || {}}
            closeHandler={attachmentCloseHandler}
          />
        ))}

      {isCardView && (
        <div className="px1">
          <Card
            className={`${
              auto ? classes.readingThumbnailAuto : classes.readingThumbnail
            } display-flex justify-center items-center`}
          >
            {fetchUrl ? (
              <Link onClick={() => handleViewDocument(fileUrl, fileName)}>
                <Download fill={theme.palette.primary.main} />
              </Link>
            ) : (
              <Link href={fileUrl}>
                <Download fill={theme.palette.primary.main} />
              </Link>
            )}
          </Card>
          <Grid container className="display-flex justify-space-between">
            <Grid
              item
              xs={10}
              xm={10}
              md={10}
              lg={10}
              xl={10}
              className="text-ellipses"
            >
              <Typography color="grey.P1000" variant="caption">
                <Link
                  variant="body2"
                  onClick={() => handleViewDocument(fileUrl, fileName)}
                  color="grey.P1000"
                  className={classes.fileViewLink}
                  title={fileName}
                >
                  {fileName.length > 20
                    ? `${fileName?.substring(0, 20)}...`
                    : fileName}
                </Link>
              </Typography>
            </Grid>

            {(showDelete && (
              <Grid
                item
                xs={2}
                xm={2}
                md={2}
                lg={2}
                xl={2}
                onClick={() => onDelete({ fileName: fileName, fileId: fileId })}
              >
                <Delete
                  height="50%"
                  className="mouse-pointer"
                  color={theme.palette.secondary.main}
                />
              </Grid>
            )) ||
              null}
          </Grid>
        </div>
      )}
      {!isCardView && (
        <div className={classes.readingThumbnailContainer}>
          <Tooltip title={fileName}>
            <Link
              component="button"
              variant="body2"
              onClick={() => handleViewDocument(fileUrl, fileName)}
              color="grey.P1000"
              className={classes.fileViewLink}
            >
              {fileName}
            </Link>
          </Tooltip>
          <Link href={fileUrl}>
            <Download fill={theme.palette.primary.main} />
          </Link>
        </div>
      )}
    </>
  );
};

export default ReadingThumbnail;
