import { courseManagementActions } from 'store/CourseManagement';

const INITIAL_STATE = {
  clusters: {
    loading: false,
    count: 0,
    data: [],
    clusterDeleteSuccess: false,
    clusterSaveSuccess: false,
    clusterIdData: {},
  },
  allDepartment: {
    loading: false,
    count: 0,
    data: [],
    departmentDeleteSuccess: false,
    departmentSaveSuccess: false,
  },
  department: {
    loading: false,
    count: 0,
    data: [],
    departmentDeleteSuccess: false,
    departmentSaveSuccess: false,
  },
  course_list: {
    loading: false,
    totalCount: 0,
    data: [],
    courseDeleteSuccess: false,
  },
  course_details: { saveCourseFeedback: {}, saveCourseSuccess: false },
  requestChangeSuccess: false,
  uploadFileSuccess: false,
  declineFeedBackSuccess: false,
  approveCourseSuccess: false,

  department_details: {
    data: {},
    loading: false,
  },
  modules: {
    module_list: { data: { items: [], totalCount: 0 }, loading: false },
    active_module: {
      saveModuleFeedback: {},
      saveModuleSuccess: false,
      saveSubModuleFeedback: {},
      deleteSubmoduleSuccess: false,
      saveSubModuleSuccess: false,
      submodules_list: { totalCount: 0, items: [] },
      submodule_list_loading: false,
    },
  },
  liveSession: {
    createLiveSessionSuccess: false,
    loading: false,
    activeSession: {},
    liveSessionList: [],
    createdLiveSessionSuccessData: {},
    updateLiveSessionSaveSuccess: {},
  },

  course: {
    list: [],
  },
  rubrics: {
    loading: false,
    config: {},
    rubricConfigSaveSuccess: false,
    rubricConfigSaveFeedback: false,
    rubricConfigError: {},
  },

  gradePoint: {
    loading: false,
    config: {},
    gradePointSaveSuccess: false,
    gradePointSaveFeedback: false,
    gradePointError: {},
  },

  cbc_table: {
    loading: false,
    table_data: {},
    cbcCreateSuccess: false,
    cbcCreateError: {},
  },
  cbc_list: {
    loading: false,
    list: [],
    count: 0,
  },
  program: {
    saveProgramFeedback: {},
    saveProgramSuccess: false,
    saveProgramError: {},
    activeProgram: {},
    programYearDeleteSuccess: false,
    programList: {},
    programYearList: {},
    semesterList: {},
    subjectList: {},
    subjectSaveSuccess: false,
    subjectDeleteSuccess: false,
    deleteSemesterSuccess: false,
    programsListById: [],
    totalCount: 0,
    data: [],
    count: 0,
  },
  facultySubjectLink: {
    createdFacultySubjectLink: '',
    facultySubjectDetails: '',
    deletedFacultySubjectLink: '',
    editFacultySubjectLinkDetails: '',
  },

  subject: {
    data: [],
    count: 0,
  },

  userMapping: {
    loading: false,
    data: [],
    error: false,
    errorMessage: '',
  },

  updateUserToProgramMapping: {
    loading: false,
    data: {},
    error: false,
    errorMessage: '',
  },

  // Join virtutor session
  JoinVirtutorSession: {
    session: {},
    loading: false,
    success: '',
  },
  //vision for dept
  visionForDepartment: {
    data: {},
    loading: false,
    success: '',
  },

  //Mission for dept
  missionForDepartment: {
    data: {},
    loading: false,
    success: '',
  },

  DownloadVision: {
    data: {},
  },
  DownloadMission: {
    data: {},
  },
  universityVision: {
    data: {},
    createVisionSuccess: false,
  },
  universityMission: {
    data: {},
    createMissionSuccess: false,
  },
  courseOutcome: {
    data: {},
  },
  schemaData: {
    data: {},
  },
};

const courseManagementReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case courseManagementActions.CLUSTERS.get_success: {
      return {
        ...state,
        clusters: {
          ...state.clusters,
          count: action?.payload?.count,
          data: action?.payload?.results,
        },
      };
    }

    case courseManagementActions.CLUSTERS.get_success_incr: {
      const oldClusters = state?.clusters?.data || [];
      const newClusters = action?.payload?.results || [];
      return {
        ...state,
        clusters: {
          ...state.clusters,
          count: action?.payload?.count,
          data: [...oldClusters, ...newClusters],
        },
      };
    }

    case courseManagementActions.CLUSTERS.reset_incr: {
      return {
        ...state,
        clusters: {
          ...state.clusters,
          count: 0,
          data: [],
        },
      };
    }

    case courseManagementActions.CLUSTERS.get_by_pagination_success: {
      return {
        ...state,
        clusters: {
          ...state.clusters,
          count: action?.payload?.count,
          data: [...(state?.clusters?.data || []), ...action?.payload?.results],
        },
      };
    }
    case courseManagementActions.CLUSTERS.loading: {
      return {
        ...state,
        clusters: { ...state.clusters, loading: action?.payload },
      };
    }
    case courseManagementActions.CLUSTERS.get_by_id_success: {
      return {
        ...state,
        clusters: { ...state.clusters, clusterIdData: action?.payload },
      };
    }
    case courseManagementActions.CLUSTERS.reset: {
      return {
        ...state,
        clusters: {
          ...INITIAL_STATE?.clusters,
        },
      };
    }
    case courseManagementActions.DEPARTMENT.get_success: {
      return {
        ...state,
        allDepartment: {
          ...state.department,
          count: action?.payload?.count || 0,
          data: action?.payload?.results || action?.payload,
        },
      };
    }

    case courseManagementActions.DEPARTMENT.get_success_incr: {
      const oldDepartments = state?.department?.data || [];
      const newDepartments = action?.payload?.results || [];
      return {
        ...state,
        department: {
          ...state.department,
          count: action?.payload?.count,
          data: [...oldDepartments, ...newDepartments],
        },
      };
    }

    case courseManagementActions.DEPARTMENT.reset_incr: {
      return {
        ...state,
        department: {
          ...state.department,
          count: 0,
          data: [],
        },
      };
    }
    case courseManagementActions.DEPARTMENT.get_by_cluster_id_success: {
      return {
        ...state,
        department: {
          ...state.department,
          count: action?.payload?.count || 0,
          data: action?.payload?.results || action?.payload,
        },
      };
    }

    case courseManagementActions.DEPARTMENT
      .get_by_cluster_id_by_pagination_success: {
      return {
        ...state,
        department: {
          ...state.department,
          count: action?.payload?.count || 0,
          data: [
            ...(state?.department?.data || []),
            ...action?.payload?.results,
          ],
        },
      };
    }

    case courseManagementActions.PROGRAM.create_success: {
      return {
        ...state,
        program: {
          ...state.program,
          saveProgramSuccess: action?.payload,
          saveProgramError: {},
        },
      };
    }

    case courseManagementActions.PROGRAM.create_success_saveFeedback: {
      return {
        ...state,
        program: {
          ...state.program,
          saveProgramFeedback: action?.payload,
        },
      };
    }

    case courseManagementActions.PROGRAM.create_error: {
      return {
        ...state,
        program: {
          ...state.program,
          saveProgramSuccess: false,
          saveProgramFeedback: {},
          saveProgramError: action?.payload,
        },
      };
    }
    case courseManagementActions.COURSE_LIST.get_success: {
      return {
        ...state,
        course_list: {
          ...state.course_list,
          totalCount: action?.payload?.totalCount || 0,
          data: action?.payload?.items,
        },
      };
    }

    case courseManagementActions.COURSE_LIST.get_by_scroll_success: {
      const oldCourseList = state?.course_list?.data || [];
      const newCourseList = action?.payload?.items || [];
      return {
        ...state,
        course_list: {
          ...state.course_list,
          totalCount: action?.payload?.totalCount || 0,
          data: [...oldCourseList, ...newCourseList],
        },
      };
    }

    case courseManagementActions.COURSE_LIST.get_by_department_id_success: {
      const oldCourseList = state?.course_list?.data || [];
      const newCourseList = action?.payload?.results || [];
      return {
        ...state,
        course_list: {
          ...state.course_list,
          totalCount: action?.payload?.count || 0,
          data: [...oldCourseList, ...newCourseList],
        },
      };
    }

    case courseManagementActions.CLUSTERS.create_success: {
      return {
        ...state,
        clusters: { ...state.clusters, clusterSaveSuccess: action.payload },
      };
    }

    case courseManagementActions.DEPARTMENT.create_success: {
      return {
        ...state,
        department: {
          ...state.department,
          departmentSaveSuccess: action.payload,
        },
      };
    }

    case courseManagementActions.CLUSTERS.delete_success: {
      return {
        ...state,
        clusters: { ...state.clusters, clusterDeleteSuccess: action.payload },
      };
    }

    case courseManagementActions.DEPARTMENT.delete_success: {
      return {
        ...state,
        department: {
          ...state.department,
          departmentDeleteSuccess: action.payload,
        },
      };
    }

    case courseManagementActions.DEPARTMENT.loading: {
      return {
        ...state,
        department: {
          ...state.department,
          loading: action?.payload,
        },
      };
    }

    case courseManagementActions.COURSE_LIST.loading: {
      return {
        ...state,
        course_list: {
          ...state.course_list,
          loading: action?.payload,
        },
      };
    }
    case courseManagementActions.COURSE_LIST.reset: {
      return {
        ...state,
        course_list: {
          ...INITIAL_STATE.course_list,
        },
      };
    }
    case courseManagementActions.DEPARTMENT.reset: {
      return {
        ...state,
        department: {
          ...INITIAL_STATE.department,
        },
      };
    }
    case courseManagementActions.DEPARTMENT_DETAILS.get_success: {
      return {
        ...state,
        department_details: {
          ...state.department_details,
          data: action?.payload,
        },
      };
    }
    case courseManagementActions.DEPARTMENT_DETAILS.reset: {
      return {
        ...state,
        department_details: { ...INITIAL_STATE?.department_details },
      };
    }
    case courseManagementActions.DEPARTMENT_DETAILS.loading: {
      return {
        ...state,
        department_details: {
          ...state.department_details,
          loading: action?.payload,
        },
      };
    }

    case courseManagementActions.COURSE_LIST.reset_data: {
      return {
        ...state,
        course_details: { ...INITIAL_STATE?.course_details },
      };
    }

    case courseManagementActions.COURSE_LIST.save_feedback: {
      return {
        ...state,
        course_details: {
          ...state.course_details,
          saveCourseFeedback: action.payload,
        },
      };
    }

    case courseManagementActions.MODULE.save_feedback: {
      return {
        ...state,
        modules: {
          ...state.modules,
          active_module: {
            ...state.modules.active_module,
            saveModuleFeedback: action.payload,
          },
        },
      };
    }

    case courseManagementActions.MODULE.reset: {
      return {
        ...state,
        modules: {
          ...state.modules,
          module_list: INITIAL_STATE.modules?.module_list,
        },
      };
    }

    case courseManagementActions.MODULE.loading: {
      return {
        ...state,
        modules: {
          ...state.modules,
          module_list: {
            ...state.modules.module_list,
            loading: action.payload,
          },
        },
      };
    }

    case courseManagementActions.SUB_MODULE.save_feedback: {
      return {
        ...state,
        modules: {
          ...state.modules,
          active_module: {
            ...state.modules.active_module,
            saveSubModuleFeedback: action?.payload,
          },
        },
      };
    }

    case courseManagementActions.SUB_MODULE.reset: {
      return {
        ...state,
        modules: {
          ...state.modules,
          active_module: {
            ...state.modules.active_module,
            submodules_list:
              INITIAL_STATE.modules.active_module.submodules_list,
          },
        },
      };
    }
    case courseManagementActions.SUB_MODULE.loading: {
      return {
        ...state,
        modules: {
          ...state.modules,
          active_module: {
            ...state.modules.active_module,
            submodule_list_loading: action?.payload,
          },
        },
      };
    }

    case courseManagementActions.LIVE_SESSION.create_success: {
      return {
        ...state,
        liveSession: {
          ...state.liveSession,
          createLiveSessionSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.LIVE_SESSION.edit_success: {
      return {
        ...state,
        liveSession: {
          ...state.liveSession,
          updateLiveSessionSaveSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.LIVE_SESSION.created_session_success_data: {
      return {
        ...state,
        liveSession: {
          ...state.liveSession,
          createdLiveSessionSuccessData: action?.payload,
        },
      };
    }

    case courseManagementActions.LIVE_SESSION.loading: {
      return {
        ...state,
        liveSession: {
          ...state.liveSession,
          loading: action?.payload,
        },
      };
    }

    case courseManagementActions.RUBRICS.loading: {
      return {
        ...state,
        rubrics: {
          ...state.rubrics,
          loading: action?.payload,
        },
      };
    }
    case courseManagementActions.RUBRICS.get_success: {
      return {
        ...state,
        rubrics: {
          ...state.rubrics,
          config: action?.payload,
        },
      };
    }

    case courseManagementActions.RUBRICS.create_success: {
      return {
        ...state,
        rubrics: {
          ...state.rubrics,
          rubricConfigSaveSuccess: action?.payload,
        },
      };
    }
    case courseManagementActions.RUBRICS.create_error: {
      return {
        ...state,
        rubrics: {
          ...state.rubrics,
          rubricConfigError: action?.payload,
        },
      };
    }
    case courseManagementActions.RUBRICS.save_feedback: {
      return {
        ...state,
        rubrics: {
          ...state.rubrics,
          rubricConfigSaveFeedback: action?.payload,
        },
      };
    }
    case courseManagementActions.RUBRIC_TABLE.get_success: {
      return {
        ...state,
        cbc_table: {
          ...state.cbc_table,
          table_data: action?.payload,
        },
      };
    }
    case courseManagementActions.CBC_LIST.get_success: {
      return {
        ...state,
        cbc_list: {
          ...state.cbc_list,
          list: action?.payload,
          count: action?.payload?.count,
        },
      };
    }
    case courseManagementActions.RUBRIC_TABLE.create_success: {
      return {
        ...state,
        cbc_list: {
          ...state.cbc_list,
          cbcCreateSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.RUBRIC_TABLE.create_error: {
      return {
        ...state,
        cbc_list: {
          ...state.cbc_list,
          cbcCreateError: action?.payload,
        },
      };
    }

    case courseManagementActions.RUBRIC_TABLE.loading: {
      return {
        ...state,
        cbc_list: {
          ...state.cbc_list,
          loading: action?.payload,
        },
      };
    }
    case courseManagementActions.RUBRICS.reset: {
      return {
        ...state,
        cbc_list: INITIAL_STATE?.cbc_list,
        rubrics: INITIAL_STATE?.rubrics,
        cbc_table: INITIAL_STATE?.cbc_table,
      };
    }

    case courseManagementActions.LIVE_SESSION.get_by_id_success: {
      return {
        ...state,
        liveSession: { ...state.liveSession, activeSession: action?.payload },
      };
    }

    case courseManagementActions.LIVE_SESSION.get_by_submoduleId_success: {
      return {
        ...state,
        liveSession: { ...state.liveSession, liveSessionList: action?.payload },
      };
    }

    case courseManagementActions.PROGRAM.get_success_by_id: {
      return {
        ...state,
        program: {
          ...state.program,
          saveProgramFeedback: action?.payload,
        },
      };
    }

    case courseManagementActions.PROGRAM.get_success: {
      return {
        ...state,
        program: {
          ...state.program,
          programList: action?.payload,
        },
      };
    }

    case courseManagementActions.PROGRAM.get_success_incr: {
      const oldPrograms = state?.program?.data || [];
      const newPrograms = action?.payload?.results || [];
      return {
        ...state,
        program: {
          ...state.program,
          count: action?.payload?.count,
          data: [...oldPrograms, ...newPrograms],
        },
      };
    }

    case courseManagementActions.PROGRAM.get_success_programs_by_user_id: {
      const oldPrograms = state?.program?.programsListById || [];
      const newPrograms = action?.payload?.results || [];
      return {
        ...state,
        program: {
          ...state.program,
          totalCount: action?.payload?.count,
          programsListById: [...oldPrograms, ...newPrograms],
        },
      };
    }

    case courseManagementActions.PROGRAM.reset_incr: {
      return {
        ...state,
        program: {
          ...state.program,
          count: 0,
          data: [],
          programYearList: {},
          semesterList: {},
        },
      };
    }

    case courseManagementActions.PROGRAM_YEAR.delete_success: {
      return {
        ...state,
        program: {
          ...state.program,
          programYearDeleteSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.PROGRAM.loading: {
      return {
        ...state,
        program: {
          ...state.program,
          loading: action?.payload,
        },
      };
    }

    case courseManagementActions.PROGRAM_YEAR.get_success: {
      return {
        ...state,
        program: {
          ...state.program,
          programYearList: action?.payload,
        },
      };
    }

    case courseManagementActions.SEMESTER.get_success: {
      return {
        ...state,
        program: {
          ...state.program,
          semesterList: action?.payload,
        },
      };
    }

    case courseManagementActions.SUBJECT.get_success: {
      return {
        ...state,
        program: {
          ...state.program,
          subjectList: action?.payload,
        },
      };
    }

    case courseManagementActions.SUBJECT.get_success_incr: {
      const oldSubjects = state?.subject?.data || [];
      const newSubjects = action?.payload?.results || [];
      return {
        ...state,
        subject: {
          ...state.subject,
          count: action?.payload?.count,
          data: [...oldSubjects, ...newSubjects],
        },
      };
    }

    case courseManagementActions.SUBJECT.reset_incr: {
      return {
        ...state,
        subject: {
          ...state.subject,
          count: 0,
          data: [],
        },
      };
    }

    case courseManagementActions.SUBJECT.create_success: {
      return {
        ...state,
        program: {
          ...state.program,
          subjectSaveSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.SUBJECT.delete_success: {
      return {
        ...state,
        program: {
          ...state.program,
          subjectDeleteSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.SEMESTER.delete_success: {
      return {
        ...state,
        program: {
          ...state.program,
          deleteSemesterSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.PROGRAM.delete_success: {
      return {
        ...state,
        program: {
          ...state.program,
          deleteProgramSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.RESET.reset_all: {
      return {
        ...state,
        clusters: {
          ...state.clusters,
          count: INITIAL_STATE.clusters.count,
          data: INITIAL_STATE.clusters.data,
        },
        allDepartment: {
          ...state.department,
          count: INITIAL_STATE.allDepartment.count,
          data: INITIAL_STATE.allDepartment.data,
        },
        department: INITIAL_STATE.department,
        program: INITIAL_STATE.program,
      };
    }

    case courseManagementActions.FACULTY_SUBJECT_LINK
      .faculty_subject_link_success: {
      return {
        ...state,
        facultySubjectLink: {
          ...state.facultySubjectLink,
          createdFacultySubjectLink: action.payload,
        },
      };
    }

    case courseManagementActions.FACULTY_SUBJECT_LINK
      .get_faculty_subject_details_success: {
      return {
        ...state,
        facultySubjectLink: {
          ...state.facultySubjectLink,
          facultySubjectDetails: action.payload,
        },
      };
    }

    case courseManagementActions.FACULTY_SUBJECT_LINK
      .delete_faculty_subject_link_success: {
      return {
        ...state,
        facultySubjectLink: {
          ...state.facultySubjectLink,
          deletedFacultySubjectLink: action.payload,
        },
      };
    }

    case courseManagementActions.FACULTY_SUBJECT_LINK
      .edit_faculty_subject_link_details_success: {
      return {
        ...state,
        facultySubjectLink: {
          ...state.facultySubjectLink,
          editFacultySubjectLinkDetails: action.payload,
        },
      };
    }

    case courseManagementActions.FACULTY_SUBJECT_LINK
      .reset_faculty_subject_details: {
      return {
        ...state,
        facultySubjectLink: {
          ...state.facultySubjectLink,
          editFacultySubjectLinkDetails:
            INITIAL_STATE.facultySubjectLink.editFacultySubjectLinkDetails,
          createdFacultySubjectLink: '',
          facultySubjectDetails: '',
          deletedFacultySubjectLink: '',
        },
      };
    }

    case courseManagementActions.GRADE_POINTS.get_success_by_id: {
      return {
        ...state,
        gradePoint: { ...state.gradePoint, config: action?.payload },
      };
    }

    case courseManagementActions.GRADE_POINTS.create_success: {
      return {
        ...state,
        gradePoint: {
          ...state.gradePoint,
          gradePointSaveSuccess: action?.payload,
        },
      };
    }

    case courseManagementActions.GRADE_POINTS.save_feedback: {
      return {
        ...state,
        gradePoint: {
          ...state.gradePoint,
          gradePointSaveFeedback: action?.payload,
        },
      };
    }

    case courseManagementActions.GRADE_POINTS.loading: {
      return {
        ...state,
        gradePoint: {
          ...state.gradePoint,
          loading: action?.payload,
        },
      };
    }

    // Get user mapping by user id

    case courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .GET_USER_MAPPING_BY_USER_ID_REQUEST: {
      return {
        ...state,
        userMapping: {
          ...state.userMapping,
          loading: true,
          error: false,
          errorMessage: '',
          data: {},
        },
      };
    }

    case courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .GET_USER_MAPPING_BY_USER_ID_SUCCESS: {
      return {
        ...state,
        userMapping: {
          ...state.userMapping,
          loading: false,
          error: false,
          errorMessage: '',
          data: action.payload || {},
        },
      };
    }

    case courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .GET_USER_MAPPING_BY_USER_ID_ERROR: {
      return {
        ...state,
        userMapping: {
          ...state.userMapping,
          loading: false,
          error: true,
          errorMessage: 'Something went wrong',
          data: {},
        },
      };
    }

    case courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .GET_USER_MAPPING_BY_USER_ID_RESET: {
      return {
        ...state,
        userMapping: {
          ...state.userMapping,
          loading: false,
          error: false,
          errorMessage: '',
          data: {},
        },
      };
    }

    // Update user to program mapping

    case courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .UPDATE_USER_MAPPING_BY_USER_ID_REQUEST: {
      return {
        ...state,
        updateUserToProgramMapping: {
          ...state.updateUserToProgramMapping,
          loading: true,
          error: false,
          errorMessage: '',
          data: {},
        },
      };
    }

    case courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .UPDATE_USER_MAPPING_BY_USER_ID_SUCCESS: {
      return {
        ...state,
        updateUserToProgramMapping: {
          ...state.updateUserToProgramMapping,
          loading: false,
          error: false,
          errorMessage: '',
          data: action.payload || {},
        },
      };
    }

    case courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .UPDATE_USER_MAPPING_BY_USER_ID_ERROR: {
      return {
        ...state,
        updateUserToProgramMapping: {
          ...state.updateUserToProgramMapping,
          loading: false,
          error: true,
          errorMessage: 'Something went wrong',
          data: {},
        },
      };
    }

    case courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .UPDATE_USER_MAPPING_BY_USER_ID_RESET: {
      return {
        ...state,
        updateUserToProgramMapping: {
          ...state.updateUserToProgramMapping,
          loading: false,
          error: false,
          errorMessage: '',
          data: {},
        },
      };
    }

    // Join  virtutor Session api
    case courseManagementActions.JOIN_SESSION_VIRTUTOR
      .JOIN_SESSION_VIRTUTOR_SUCCESS: {
      return {
        ...state,
        JoinVirtutorSession: {
          ...state.JoinVirtutorSession,
          session: action?.payload,
        },
      };
    }

    case courseManagementActions.JOIN_SESSION_VIRTUTOR
      .JOIN_SESSION_VIRTUTOR_RESET: {
      return {
        ...state,
        JoinVirtutorSession: {
          ...state.JoinVirtutorSession,
          session: {},
        },
      };
    }

    case courseManagementActions.JOIN_SESSION_VIRTUTOR
      .JOIN_SESSION_VIRTUTOR_LOADER: {
      return {
        ...state,
        JoinVirtutorSession: {
          ...state.JoinVirtutorSession,
          loading: action.payload,
        },
      };
    }

    //Create vision for department
    case courseManagementActions.VISION_FOR_DEPARTMENT
      .VISION_FOR_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        visionForDepartment: {
          ...state.visionForDepartment,
          data: action?.payload,
        },
      };
    }

    case courseManagementActions.VISION_FOR_DEPARTMENT
      .VISION_FOR_DEPARTMENT_RESET: {
      return {
        ...state,
        visionForDepartment: {
          ...state.visionForDepartment,
          data: {},
        },
      };
    }

    //Create Mission for department
    case courseManagementActions.MISSION_FOR_DEPARTMENT
      .MISSION_FOR_DEPARTMENTT_SUCCESS: {
      return {
        ...state,
        missionForDepartment: {
          ...state.missionForDepartment,
          data: action?.payload,
        },
      };
    }

    case courseManagementActions.MISSION_FOR_DEPARTMENT
      .MISSION_FOR_DEPARTMENT_RESET: {
      return {
        ...state,
        missionForDepartment: {
          ...state.missionForDepartment,
          data: {},
        },
      };
    }

    //Download For Vision
    case courseManagementActions.DOWNLOAD_FOR_VISION.DOWNLOAD_VISION_SUCCESS: {
      return {
        ...state,
        DownloadVision: {
          ...state.DownloadVision,
          data: action?.payload || {},
        },
      };
    }
    case courseManagementActions.DOWNLOAD_FOR_VISION.DOWNLOAD_VISION_RESET: {
      return {
        ...state,
        DownloadVision: {
          ...state.DownloadVision,
          data: {},
        },
      };
    }

    //Download For Mission
    case courseManagementActions.DOWNLOAD_FOR_MISSION
      .DOWNLOAD_MISSION_SUCCESS: {
      return {
        ...state,
        DownloadMission: {
          ...state.DownloadMission,
          data: action?.payload || {},
        },
      };
    }
    case courseManagementActions.DOWNLOAD_FOR_MISSION.DOWNLOAD_MISSION_RESET: {
      return {
        ...state,
        DownloadMission: {
          ...state.DownloadMission,
          data: {},
        },
      };
    }
    case courseManagementActions.VISION_PROGRAM
      .GET_UNIVERSITY_VISSION_SUCCESS: {
      return {
        ...state,
        universityVision: {
          ...state.universityVision,
          data: action.payload,
        },
      };
    }
    case courseManagementActions.VISION_PROGRAM
      .CREATE_UNIVERSITY_VISSION_SUCCESS: {
      return {
        ...state,
        universityVision: {
          ...state.universityVision,
          createVisionSuccess: true,
        },
      };
    }
    case courseManagementActions.MISSION_PROGRAM
      .GET_UNIVERSITY_MISSION_SUCCESS: {
      return {
        ...state,
        universityMission: {
          ...state.universityMission,
          data: action.payload,
        },
      };
    }
    case courseManagementActions.MISSION_PROGRAM
      .CREATE_UNIVERSITY_MISSION_SUCCESS: {
      return {
        ...state,
        universityMission: {
          ...state.universityMission,
          createMissionSuccess: true,
        },
      };
    }
    case courseManagementActions.COURSE_OUTCOME.GET_COURSE_OUTCOME_SUCCESS: {
      return {
        ...state,
        courseOutcome: {
          ...state.courseOutcome,
          data: action.payload,
        },
      };
    }
    //  update schema
    case courseManagementActions.UPDATE_SCHEMA.UPDATE_SCHEMA_SUCCESS: {
      return {
        ...state,
        schemaData: {
          ...state.schemaData,
          data: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default courseManagementReducer;
