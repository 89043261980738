import React from 'react';

const FactCheckIcon = ({ fill, height = 20, width = 20, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
      fill={fill}
      {...rest}
    >
      <path d="M162-111q-40.425 0-69.213-28.788Q64-168.575 64-209v-540q0-40.425 28.787-69.213Q121.575-847 162-847h636q40.425 0 69.213 28.787Q896-789.425 896-749v540q0 40.425-28.787 69.212Q838.425-111 798-111H162Zm0-98h636v-540H162v540Zm73-61h181v-98H235v98Zm337-72 178-178-69-69-109 109-51-51-69 69 120 120Zm-337-88h181v-98H235v98Zm0-159h181v-98H235v98Zm-73 380v-540 540Z" />
    </svg>
  );
};

export default FactCheckIcon;
