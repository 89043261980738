import * as React from 'react';

const BackIcon = ({ height = '20', width = '20', ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      {...rest}
    >
      <path d="m8 18-8-8 8-8 1.417 1.417L2.833 10l6.584 6.583Z" />
    </svg>
  );
};

export default BackIcon;
