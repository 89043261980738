export const studentActions = {
  ASSIGNMENT_LIST: {
    get: 'GET_STUDENT_ASSIGNMENT',
    get_success: 'GET_STUDENT_ASSIGNMENT_SUCCESS',
    get_error: 'GET_STUDENT_ASSIGNMENT_ERROR',
  },
  ASSESSMENT_LIST: {
    get: 'GET_STUDENT_ASSESSMENT',
    get_success: 'GET_STUDENT_ASSESSMENT_SUCCESS',
    get_error: 'GET_STUDENT_ASSESSMENT_ERROR',
  },
  ASSESSMENT_BY_ID: {
    getById: 'GET_STUDENT_ASSESSMENT_BY_ID',
    get_success: 'GET_STUDENT_ASSESSMENT_BY_ID_SUCCESS',
    get_error: 'GET_STUDENT_ASSESSMENT_BY_ID_ERROR',
    get_assessment_by_subModule: 'GET_ASSESSMENT_BY_SUBMODULE_ID',
    get_assessment_by_subModule_success:
      'GET_ASSESSMENT_BY_SUBMODULE_ID_SUCCESS',
    get_assessment_by_subModule_error: 'GET_ASSESSMENT_BY_SUBMODULE_ID_ERROR',
    reset: 'RESET_ASSESSMENT_BY_ID',
  },
  ASSIGNMENT_BY_ID: {
    getById: 'GET_STUDENT_ASSIGNMENT_BY_ID',
    get_success: 'GET_STUDENT_ASSIGNMENT_BY_ID_SUCCESS',
    get_error: 'GET_STUDENT_ASSIGNMENT_BY_ID_ERROR',
    get_assignment_by_subModule: 'GET_ASSIGNMENT_BY_SUBMODULE_ID',
    get_assignment_by_subModule_success:
      'GET_ASSIGNMENT_BY_SUBMODULE_ID_SUCCESS',
    get_assignment_by_subModule_error: 'GET_ASSIGNMENT_BY_SUBMODULE_ID_ERROR',
    get_quiz_for_edit: 'GET_QUIZ_FOR_EDIT_BY_ID',
    get_quiz_for_edit_success: 'GET_QUIZ_FOR_EDIT_BY_ID_SUCCESS',
    get_quiz_for_edit_error: 'GET_QUIZ_FOR_EDIT_BY_ID_ERROR',
    reset: 'RESET_ASSIGNMENT_BY_ID',
  },
  MODULES: {
    get: 'GET_COURSE_MODULE',
    get_success: 'GET_COURSE_MODULES_SUCCESS',
    get_error: 'GET_COURSE_MODULES_ERROR',
  },
  SUBMODULES: {
    get: 'GET_COURSE_SUBMODULE',
    get_success: 'GET_COURSE_SUBMODULE_SUCCESS',
    get_error: 'GET_COURSE_SUBMODULE_ERROR',
  },
  NOTES_BOOKMARKS_LIST: {
    get: 'GET_NOTES_BOOKMARKS_LIST',
    get_success: 'GET_NOTES_BOOKMARKS_LIST_SUCCESS',
    get_error: 'GET_NOTES_BOOKMARKS_ERROR',
    create_notes_bookmarks: 'POST_NOTES_BOOKMARKS',
    create_notes_bookmarks_success: 'POST_NOTES_BOOKMARKS_SUCCESS',
    create_notes_bookmarks_error: 'POST_NOTES_BOOKMARKS_ERROR',
    delete_notes_bookmarks: 'DELETE_NOTES_BOOKMARKS',
    delete_notes_bookmarks_success: 'DELETE_NOTES_BOOKMARKS_SUCCESS',
    delete_notes_bookmarks_error: 'DELETE_NOTES_BOOKMARKS_ERROR',
    update_notes_bookmarks: 'UPDATE_NOTES_BOOKMARKS',
    update_notes_bookmarks_success: 'UPDATE_NOTES_BOOKMARKS_SUCCESS',
    update_notes_bookmarks_error: 'UPDATE_NOTES_BOOKMARKS_ERROR',
    get_by_submodule_id: 'GET_BY_SUBMODULE_ID',
    get_by_submodule_id_success: 'GET_BY_SUBMODULE_ID_SUCCESS',
    get_by_submodule_id_error: 'GET_BY_SUBMODULE_ID_ERROR',
    get_by_module_id: 'GET_BY_MODULE_ID',
    get_by_module_id_success: 'GET_BY_MODULE_ID_SUCCESS',
    get_by_module_id_error: 'GET_BY_MODULE_ID_ERROR',
    get_by_content: 'GET_BY_CONTENT',
    get_by_content_success: 'GET_BY_CONTENT_SUCCESS',
    get_by_content_error: 'GET_BY_CONTENT_ERROR',
    addUpdate_bookmarks: 'ADDUPDATE_BOOKMARKS',
    addUpdate_bookmarks_success: 'ADDUPDATE_BOOKMARKS_SUCCESS',
    addUpdate_bookmarks_error: 'ADDUPDATE_BOOKMARKS_ERROR',
  },

  COURSE_DETAILS: {
    get_details_by_course_id: 'GET_DETAILS_BY_COURSE_ID',
    get_details_by_course_id_success: 'GET_DETAILS_BY_COURSE_ID_SUCCESS',
    get_details_by_course_id_error: 'GET_DETAILS_BY_COURSE_ID_ERROR',
  },
  STUDENT_COURSE: {
    get_course_by_student_id: 'GET_COURSE_BY_STUDENT_ID',
    get_course_by_student_id_success: 'GET_COURSE_BY_STUDENT_ID_SUCCESS',
    get_course_by_student_id_error: 'GET_COURSE_BY_STUDENT_ID_ERROR',
    reset_get_course_by_student_id: 'RESET_GET_COURSE_BY_STUDENT_ID',
  },
  ASSESSMENT_CREATE: {
    create: 'CREATE_ASSESSMENT',
    create_success: 'CREATE_ASSESSMENT_SUCCESS',
    create_error: 'CREATE_ASSESSMENT_ERROR',
    reset: 'CREATE_ASSSESSMENT_RESET',
    save_assignment: 'SAVE_ASSIGNMENT_QUESTIONS',
    save_assignment_conditions: 'SAVE_ASSIGNMENT_CONDITIONS',
  },
  QUIZ_CREATE: {
    create: 'CREATE_QUIZ',
    create_success: 'CREATE_QUIZ_SUCCESS',
    create_error: 'CREATE_QUIZ_ERROR',
    reset: 'CREATE_QUIZ_RESET',
    save_questions: 'SAVE_QUESTIONS',
    save_conditions: 'SAVE_CONDITIONS',
  },
  SUBMIT_QUIZ: {
    submit_quiz: 'SUBMIT_QUIZ',
    submit_quiz_success: 'SUBMIT_QUIZ_SUCCESS',
    submit_quiz_error: 'SUBMIT_QUIZ_ERROR',
    learning_quiz_request: 'LEARNING_QUIZ_REQUEST',
    learning_quiz_success: 'LEARNING_QUIZ_SUCCESS',
    learning_quiz_error: 'LEARNING_QUIZ_ERROR',
    submit_learning_quiz_request: 'SUBMIT_LEARNING_QUIZ_REQUEST',
    submit_learning_quiz_success: 'SUBMIT_LEARNING_QUIZ_SUCCESS',
    submit_learning_quiz_error: 'SUBMIT_LEARNING_QUIZ_ERROR',
    submit_learning_assignment_request: 'SUBMIT_LEARNING_ASSIGNMENT_REQUEST',
    submit_learning_assignment_success: 'SUBMIT_LEARNING_ASSIGNMENT_SUCCESS',
    submit_learning_assignment_error: 'SUBMIT_LEARNING_ASSIGNMENT_ERROR',
    learning_assignment_request: 'LEARNING_ASSIGNMENT_REQUEST',
    learning_assignment_success: 'LEARNING_ASSIGNMENT_SUCCESS',
    learning_assignment_error: 'LEARNING_ASSIGNMENT_ERROR',
  },
  PENDING_ASSESSMENT: {
    get_pending_assessment: 'GET',
    get_assessment_success: 'GET_ASSESSMENT_SUCCESS',
    get_error: 'GET_ERROR',
  },
  PENDING_ASSIGNMENT: {
    get_pending_quiz: 'GET',
    get_assignment_success: 'GET_ASSIGNMENT_SUCCESS',
    get_error: 'GET_ERROR',
  },
  GET_STUDENT_ACTIVITIES: {
    get_student_activities: 'get_student_activities',
    get_student_activities_success: 'GET_STUDENT_ACTIVITIES_SUCCESS',
    get_student_activities_error: 'GET_ERROR',
  },

  ALL_USERS: {
    get: 'GET_ALL_USERS',
    get_success: 'GET_SUCCESS_ALL_USERS',
    get_error: 'GET_ERROR_ALL_USERS',
    reset: 'RESET_GET_ALL_USERS',
  },
  ASSESSMENT_EDIT: {
    edit: 'EDIT_ASSESSMENT',
    edit_success: 'EDIT_ASSESSMENT_SUCCESS',
    edit_error: 'EDIT_ASSESSMENT_ERROR',
    reset: 'EDIT_ASSSESSMENT_RESET',
  },
  QUIZ_EDIT: {
    edit: 'EDIT_QUIZ',
    edit_success: 'EDIT_QUIZ_SUCCESS',
    edit_error: 'EDIT_QUIZ_ERROR',
    reset: 'EDIT_QUIZ_RESET',
  },
  ASSESSMENT_DELETE: {
    delete: 'DELETE_ASSESSMENT',
    delete_success: 'DELETE_ASSESSMENT_SUCCESS',
    delete_error: 'DELETE_ASSESSMENT_ERROR',
  },
  QUIZ_DELETE: {
    delete: 'DELETE_QUIZ',
    delete_success: 'DELETE_QUIZ_SUCCESS',
    delete_error: 'DELETE_QUIZ_ERROR',
  },
  STUDENT_LOADING: {
    student_loading: 'STUDENT_LOADING',
  },
  STUDENT_SEMESTER: {
    create_student_program_details_action:
      'CREATE_STUDENT_PROGRAM_DETAILS_ACTION',
    create_student_program_details_succcess:
      'CREATE_STUDENT_PROGRAM_DETAILS_SUCCESS',
    create_student_program_details_error:
      'CREATE_STUDENT_PROGRAM_DETAILS_ERROR',
    get_student_program_details_action: 'GET_STUDENT_PROGRAM_DETAILS_ACTION',
    get_student_program_details_success: 'GET_STUDENT_PROGRAM_DETAILS_SUCCESS',
    get_student_program_details_error: 'GET_STUDENT_PROGRAM_DETAILS_ERROR',

    //Student Year
    GET_STUDENT_YEAR_LIST_REQUEST: 'GET_STUDENT_YEAR_LIST_REQUEST',
    GET_STUDENT_YEAR_LIST_SUCCESS: 'GET_STUDENT_YEAR_LIST_SUCCESS',
    GET_STUDENT_YEAR_LIST_ERROR: 'GET_STUDENT_YEAR_LIST_ERROR',

    // Student Sem
    GET_STUDENT_SEMESTER_LIST_REQUEST: 'GET_STUDENT_SEMESTER_LIST_REQUEST',
    GET_STUDENT_SEMESTER_LIST_SUCCESS: 'GET_STUDENT_SEMESTER_LIST_SUCCESS',
    GET_STUDENT_SEMESTER_LIST_ERROR: 'GET_STUDENT_SEMESTER_LIST_ERROR',
    //
    update_student_program_details_action:
      'UPDATE_STUDENT_PROGRAM_DETAILS_ACTION',
    update_student_program_details_success:
      'UPDATE_STUDENT_PROGRAM_DETAILS_SUCCESS',
    update_student_program_details_error:
      'UPDATE_STUDENT_PROGRAM_DETAILS_ERROR',
    delete_student_program_details_action:
      'DELETE_STUDENT_PROGRAM_DETAILS_ACTION',
    delete_student_program_details_success:
      'DELETE_STUDENT_PROGRAM_DETAILS_SUCCESS',
    delete_student_program_details_error:
      'DELETE_STUDENT_PROGRAM_DETAILS_ERROR',
    reset_student_program_details_action:
      'RESET_STUDENT_PROGRAM_DETAILS_ACTION',
  },
  VIDEO_READINGS_PROGRESS: {
    video_progress: 'VIDEO_PROGRESS',
    video_progress_success: 'VIDEO_PROGRESS_SUCCESS',
    video_progress_error: 'VIDEO_PROGRESS_ERROR',
    reading_progress: 'READING_PROGRESS',
    reading_progress_success: 'READING_PROGRESS_SUCCESS',
    reading_progress_error: 'READING_PROGRESS_ERROR',

    get_video_progress: 'GET_VIDEO_PROGRESS',
    get_video_progress_success: 'GET_VIDEO_PROGRESS_SUCCESS',
    get_video_progress_error: 'GET_VIDEO_PROGRESS_ERROR',
    get_reading_progress: 'GET_READING_PROGRESS',
    get_reading_progress_success: 'GET_READING_PROGRESS_SUCCESS',
    get_reading_progress_error: 'GET_READING_PROGRESS_ERROR',
  },

  GET_SUBJECT_PROGRAM_DETAILS: {
    get: 'GET_SUBJECT_PROGRAM_DETAILS',
    success: 'GET_SUBJECT_PROGRAM_DETAILS_SUCCESS',
    error: 'GET_SUBJECT_PROGRAM_DETAILS_ERROR',
    loading: 'GET_SUBJECT_PROGRAM_DETAILS_LOADING',
    reset: 'RESET_SUBJECT_PROGRAM_DETAILS',
  },
  GET_STUDENT_CONNECT: {
    GET_STUDENT: 'GET_STUDENT_CONNECT',
    GET_STUDENT_SUCCESS: 'GET_STUDENT_SUCCESS',
    GET_STUDENT_ERROR: 'GET_STUDENT_ERROR',
    GET_STUDENT_RESET: 'GET_STUDENT_RESET',

    GET_STUDENT_NOTIFICTION: 'GET_STUDENT_NOTIFICTION',
    GET_STUDENT_NOTIFICATION_SUCCESS: 'GET_STUDENT_NOTIFICATION_SUCCESS',
    GET_STUDENT_NOTIFICATION_ERROR: 'GET_STUDENT_NOTIFICATION_ERROR',
    GET_STUDENT_NOTIFICATION_RESET: 'GET_STUDENT_NOTIFICATION_RESET',

    CREATE_STUDENT_NOTIFICTION: 'CREATE_STUDENT_NOTIFICTION',
    CREATE_STUDENT_NOTIFICATION_SUCCESS: 'CREATE_STUDENT_NOTIFICATION_SUCCESS',
    CREATE_STUDENT_NOTIFICATION_ERROR: 'CREATE_STUDENT_NOTIFICATION_ERROR',
    CREATE_STUDENT_NOTIFICATION_RESET: 'CREATE_STUDENT_NOTIFICATION_RESET',

    DELETE_STUDENT_NOTIFICTION: 'DELETE_STUDENT_NOTIFICTION',
    DELETE_STUDENT_NOTIFICATION_SUCCESS: 'DELETE_STUDENT_NOTIFICATION_SUCCESS',
    DELETE_STUDENT_NOTIFICATION_ERROR: 'DELETE_STUDENT_NOTIFICATION_ERROR',
    DELETE_STUDENT_NOTIFICATION_RESET: 'DELETE_STUDENT_NOTIFICATION_RESET',

    BULK_DELETE_STUDENT_NOTIFICTION: 'BULK_DELETE_STUDENT_NOTIFICTION',
    BULK_DELETE_STUDENT_NOTIFICATION_SUCCESS:
      'BULK_DELETE_STUDENT_NOTIFICATION_SUCCESS',
    BULK_DELETE_STUDENT_NOTIFICATION_ERROR:
      'BULK_DELETE_STUDENT_NOTIFICATION_ERROR',
    BULK_DELETE_STUDENT_NOTIFICATION_RESET:
      'BULK_DELETE_STUDENT_NOTIFICATION_RESET',
  },
};
