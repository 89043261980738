import MenuIcon from '@mui/icons-material/Menu';
import { ROUTES } from 'constants/routesConstants';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
} from 'iiht-b2a-ui-components/build/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetUserProfile } from 'store';
import { getLoginDetails } from 'store/UserManagement';
import { getUserRole, localStorageHelper, removeSpace } from 'utils';
import TechademyLogo from '../../../assets/images/techademy_logo.svg';
import { Role } from '../../../data/roles';
import CandidateHeader from './CandidateHeader';
import CounsellorHeader from './CounsellorHeader';
import FacultyHeader from './FacultyHeader';
import HODHeader from './HODHeader';
import HrHeader from './HrHeader';
import LeadHeader from './LeadExecHeader';
import MoocAdminHeader from './MoocAdminHeader';
import ParentHeader from './ParentHeader';
import RegistrarHeader from './RegistrarHeader';
import StudentHeader from './StudentHeader';
import SuperAdminHeader from './SuperAdminHeader';
import VerifierHeader from './VerifierHeader';
import './headerStyle.scss';

function HeaderView() {
  let history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [responsiveNav, setResponsiveNav] = useState(false);

  const userDetails = useSelector(
    (state) => state.userManagementState.user.data
  );
  const { headerLogoUrl: moocAdminLogoUrl } = useSelector(
    ({ moocAdminState }) => moocAdminState
  );

  const { idp_user_uuid: userId } = useSelector(
    (state) => state.userManagementState.user.data
  );
  const user_id = localStorage.getItem('user_id');
  const { userProfileUpdateData, profileImg } = useSelector(
    (state) => state.userProfileState.UserProfile
  );
  const { data: notificationList } = useSelector(
    (state) => state.notificationState.notificaiton_details
  );

  const tenantLoginLogo = localStorageHelper.get('tenantLoginLogo') ?? '';
  const tenantHeaderImage = localStorageHelper.get('tenantHeaderImage') ?? '';
  const role = getUserRole();

  useEffect(() => {
    if (profileImg || userProfileUpdateData) {
      dispatch(getLoginDetails({ userId: user_id }));
      // dispatch(getStaffDetails(user_id));
      dispatch(resetUserProfile());
    }
  }, [profileImg, userProfileUpdateData]);

  const renderHeader = (roleName) => {
    if (roleName)
      switch (roleName) {
        case Role.candidate:
          return (
            <CandidateHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.verifier:
          return (
            <VerifierHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.counsellor:
          return (
            <CounsellorHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.student:
          return (
            <StudentHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.faculty:
          return (
            <FacultyHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.hod:
          return (
            <HODHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.registrar:
          return (
            <RegistrarHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case removeSpace(Role.leadExec):
          return (
            <LeadHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.parent:
          return (
            <ParentHeader
              t={t}
              userDetails={userDetails} //parentDetails
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.HR:
          return (
            <HrHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );

        case Role.moocAdmin:
          return (
            <MoocAdminHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        case Role.superadmin:
          return (
            <SuperAdminHeader
              t={t}
              userDetails={userDetails}
              role={role}
              notificationList={notificationList}
              responsiveNav={responsiveNav}
              setResponsiveNav={setResponsiveNav}
            />
          );
        default:
          return <></>;
      }
  };

  const homePageHandler = () => {
    history.push(ROUTES.DASHBOARD);
  };

  const handleOnError = (e) => {
    e.target.src = TechademyLogo;
    e.target.style = 'width: 250px; height: auto;';
  };

  return (
    <AppBar
      position="fixed"
      color="transparent"
      className="app-bar-header"
      sx={{
        background: '#fff',
        boxShadow: 'none',
        borderBottom: '1px solid #E0E0E0',
      }}
    >
      <Container maxWidth="false" className="display-flex">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              size="large"
              aria-label="main navigation"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => {
                setResponsiveNav(true);
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              className={responsiveNav ? 'shadow' : ''}
              onClick={() => {
                setResponsiveNav(false);
              }}
            ></Box>
          </Box>
          {tenantHeaderImage ? (
            <img
              className="techademy-logo"
              onClick={homePageHandler}
              alt="Login Cover Image"
              src={tenantHeaderImage}
              onError={handleOnError}
              style={{ width: '180px', height: '48px' }}
            />
          ) : (
            <img
              className="techademy-logo"
              onClick={homePageHandler}
              alt="Default Cover Image"
              src={TechademyLogo}
              style={{ width: '250px', height: 'auto' }}
            />
          )}
        </Toolbar>
        <div className="notification-section">
          {renderHeader(userDetails && removeSpace(role))}
        </div>
      </Container>
    </AppBar>
  );
}

export default HeaderView;
