import { settingsActions } from './Settings.constants';
const { PRIVACY, PRIVACY_NOTIFICATION } = settingsActions;

export const getSettingLoading = (payload) => ({
  type: PRIVACY.loading,
  payload: payload,
});

export const createSettings = (payload) => {
  {
    return {
      type: PRIVACY.get,
      payload: payload,
    };
  }
};

export const createSettingsSuccess = (payload) => ({
  type: PRIVACY.get_success,
  payload: payload,
});

export const createSettingsError = (payload) => ({
  type: PRIVACY.get_error,
  payload: payload,
});

export const getSettings = (payload) => ({
  type: PRIVACY.get_list,
  payload: payload,
});

export const getSettingsSuccess = (payload) => ({
  type: PRIVACY.get_list_success,
  payload: payload,
});

export const getSettingsError = (payload) => ({
  type: PRIVACY.get_list_error,
  payload: payload,
});

export const getSetting = (payload) => ({
  type: PRIVACY.get,
  payload: payload,
});

export const getSettingSuccess = (payload) => ({
  type: PRIVACY.get_success,
  payload: payload,
});

export const getSettingError = (payload) => ({
  type: PRIVACY.get_error,
  payload: payload,
});

export const updateSetting = (payload) => {
  return {
    type: PRIVACY.update,
    payload: payload,
  };
};

export const resetupdateSetting = () => ({
  type: PRIVACY.reset_update,
});

export const updateSettingSuccess = (payload) => ({
  type: PRIVACY.update_success,
  payload: payload,
});

export const updateSettingError = (payload) => ({
  type: PRIVACY.update_error,
  payload: payload,
});

export const deleteSetting = (payload) => ({
  type: PRIVACY.delete,
  payload: payload,
});

export const deleteSettingSuccess = (payload) => ({
  type: PRIVACY.delete_success,
  payload: payload,
});

export const deleteSettingError = (payload) => ({
  type: PRIVACY.delete_error,
  payload: payload,
});

//For Privacy and Notification Settings
export const getAllPrivacySettings = (payload) => ({
  type: PRIVACY_NOTIFICATION.get_all_privacy_notification_list,
  payload: payload,
});

export const getAllPrivacySettingsSuccess = (payload) => ({
  type: PRIVACY_NOTIFICATION.get_all_privacy_notification_list_success,
  payload: payload,
});

export const getAllPrivacySettingsError = (payload) => ({
  type: PRIVACY_NOTIFICATION.get_all_privacy_notification_list_error,
  payload: payload,
});

export const getPrivacySettings = (payload) => ({
  type: PRIVACY_NOTIFICATION.get_privacy_notification_list,
  payload: payload,
});

export const getPrivacySettingsSuccess = (payload) => ({
  type: PRIVACY_NOTIFICATION.get_privacy_notification_list_success,
  payload: payload,
});

export const getPrivacySettingsError = (payload) => ({
  type: PRIVACY_NOTIFICATION.get_privacy_notification_list_error,
  payload: payload,
});

export const updatePrivacySetting = (payload) => {
  return {
    type: PRIVACY_NOTIFICATION.update_privacy_notification,
    payload: payload,
  };
};

export const resetupdatePrivacySetting = () => ({
  type: PRIVACY_NOTIFICATION.reset_privacy_notification_update,
});

export const updatePrivacySettingSuccess = (payload) => ({
  type: PRIVACY_NOTIFICATION.update_privacy_notification_success,
  payload: payload,
});

export const updatePrivacySettingError = (payload) => ({
  type: PRIVACY_NOTIFICATION.update_privacy_notification_error,
  payload: payload,
});
