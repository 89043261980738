export const programContentActions = {
  GET_MAPPED_DEPARTMENT_LIST: {
    get: 'GET_MAPPED_DEPARTMENT_LIST',
    get_success: 'GET_MAPPED_DEPARTMENT_LIST_SUCCESS',
    get_error: 'GET_MAPPED_DEPARTMENT_LIST_ERROR',
  },
  GET_PROGRAM_LIST_FOR_FACULTY: {
    get: 'GET_PROGRAM_LIST_FOR_FACULTY',
    get_success: 'GET_PROGRAM_LIST_FOR_FACULTY_SUCCESS',
    get_error: 'GET_PROGRAM_LIST_FOR_FACULTY_ERROR',
  },
  GET_MAPPED_YEAR_LIST: {
    get: 'GET_MAPPED_YEAR_LIST',
    get_success: 'GET_MAPPED_YEAR_LIST_SUCCESS',
    get_error: 'GET_MAPPED_YEAR_LIST_ERROR',
  },
  GET_SUBJECT_STATS: {
    get: 'GET_SUBJECT_STATS',
    get_success: 'GET_SUBJECT_STATS_SUCCESS',
    get_error: 'GET_SUBJECT_STATS_ERROR',
  },
  GET_MAPPED_SEMESTER_LIST: {
    get: 'GET_MAPPED_SEMESTER_LIST',
    get_success: 'GET_MAPPED_SEMESTER_LIST_SUCCESS',
    get_error: 'GET_MAPPED_SEMESTER_LIST_ERROR',
  },

  // Chapter
  GET_CHAPTER_LIST_BY_SUBJECT: {
    get: 'GET_CHAPTER_LIST_BY_SUBJECT_REQUEST',
    get_success: 'GET_CHAPTER_LIST_BY_SUBJECT_SUCCESS',
    get_error: 'GET_CHAPTER_LIST_BY_SUBJECT_ERROR',
    reset: 'RESET_CHAPTER_LIST_BY_SUBJECT',
    loader: 'GET_CHAPTER_LIST_BY_SUBJECT_LOADER',
  },

  GET_MAPPED_SUBJECT_LIST: {
    get: 'GET_MAPPED_SUBJECT_LIST',
    get_success: 'GET_MAPPED_SUBJECT_LIST_SUCCESS',
    get_error: 'GET_MAPPED_SUBJECT_LIST_ERROR',
    get_reset: 'GET_MAPPED_SUBJECT_LIST_RESET',
  },

  GET_CHAPTER_CONTENT: {
    get: 'GET_CHAPTER_CONTENT_REQUEST',
    get_success: 'GET_CHAPTER_CONTENT_SUCCESS',
    get_error: 'GET_CHAPTER_CONTENT_ERROR',
    get_reset: 'GET_CHAPTER_CONTENT_RESET',
  },

  PRACTICAL_CONTENT: {
    set: 'ADD_PRACTICAL_CONTENT',
    set_success: 'ADD_SUCCESS_PRACTICAL_CONTENT',
    set_error: 'ADD_ERROR_PRACTICAL_CONTENT',
    get: 'GET_PRACTICAL_CONTENT',
    get_success: 'GET_SUCCESS_PRACTICAL_CONTENT',
    get_error: 'GET_ERROR_PRACTICAL_CONTENT',
    update: 'UPDATE_PRACTICAL_CONTENT',
    update_success: 'UPDATE_SUCCESS_PRACTICAL_CONTENT',
    update_error: 'UPDATE_ERROR_PRACTICAL_CONTENT',
    delete_practical: 'DELETE_PRACTICAL_CONTENT',
    delete_success_practical: 'DELETE_SUCCESS_PRACTICAL_CONTENT',
    delete_error_practical: 'DELETE_ERROR_PRACTICAL_CONTENT',
  },

  VIDEO_CONTENT: {
    loader: 'SET_VIDEO_CONTENT_LOADER',
    set: 'SET_VIDEO_CONTENT',
    set_success: 'SET_SUCCESS_VIDEO_CONTENT',
    set_error: 'SET_ERROR_VIDEO_CONTENT',
    upload_file: 'UPLOAD_FILE',
    upload_file_success: 'UPLOAD_FILE_SUCCESS',
    upload_file_error: 'UPLOAD_FILE_ERROR',
    upload_transcript_file: 'UPLOAD_TRANSCRIPT_FILE',
    upload_transcript_file_success: 'UPLOAD_TRANSCRIPT_FILE_SUCCESS',
    get: 'GET_VIDEO_CONTENT',
    get_success: 'GET_SUCCESS_VIDEO_CONTENT',
    get_error: 'GET_ERROR_VIDEO_CONTENT',
    update: 'UPDATE_VIDEO_CONTENT',
    update_success: 'UPDATE_SUCCESS_VIDEO_CONTENT',
    update_error: 'UPDATE_ERROR_VIDEO_CONTENT',
    delete_video: 'DELETE_VIDEO_CONTENT',
    delete_success_video: 'DELETE_SUCCESS_VIDEO_CONTENT',
    delete_error_video: 'DELETE_ERROR_VIDEO_CONTENT',
  },

  GET_FACULTY_LIST_BY_SUBJECT_ID: {
    get: 'GET_FACULTY_LIST_BY_SUBJECT_ID',
    get_success: 'GET_FACULTY_LIST_BY_SUBJECT_ID_SUCCESS',
    get_error: 'GET_FACULTY_LIST_BY_SUBJECT_ID_ERROR',
  },

  GET_PROGRAM_DETAILS: {
    get: 'GET_PROGRAM_DETAILS_REQUEST',
    get_success: 'GET_PROGRAM_DETAILS_SUCCESS',
    get_error: 'GET_PROGRAM_DETAILS_ERROR',
  },
  LOADING: {
    loading: 'LOADING',
  },

  READING: {
    create: 'CREATE_READING_CONTENT',
    create_success: 'CREATE_READING_CONTENT_SUCCESS',
    create_error: 'CREATE_READING_CONTENT_ERROR',
    update: 'UPDATE_READING_CONTENT',
    update_success: 'UPDATE_READING_CONTENT_SUCCESS',
    update_error: 'UPDATE_READING_CONTENT_ERROR',
    delete: 'DELETE_READING_CONTENT',
    delete_success: 'DELETE_READING_CONTENT_SUCCESS',
    get: 'GET_READING_CONTENT',
    get_success: 'GET_READING_CONTENT_SUCCESS',
    get_error: 'GET_READING_CONTENT_ERROR',
  },

  ADD_CHAPTER: {
    create: 'CREATE_CHAPTER',
    create_success: 'CREATE_CHAPTER_SUCCESS',
    create_error: 'CREATE_CHAPTER_ERROR',
  },

  GET_CHAPTER_DETAILS_BY_ID: {
    get: 'GET_CHAPTER_DETAILS_BY_ID',
    get_success: 'GET_CHAPTER_DETAILS_BY_ID_SUCCESS',
    get_error: 'GET_CHAPTER_DETAILS_BY_ID_ERROR',
  },

  UPDATE_CHAPTER: {
    update: 'UPDATE_CHAPTER',
    update_success: 'UPDATE_CHAPTER_SUCCESS',
    update_error: 'UPDATE_CHAPTER_ERROR',
  },

  DELETE_CHAPTER: {
    delete: 'DELETE_CHAPTER',
    delete_success: 'DELETE_CHAPTER_SUCCESS',
    delete_error: 'DELETE_CHAPTER_ERROR',
  },

  DELETE_LIVE_SESSION: {
    delete: 'DELETE_LIVE_SESSION',
    delete_success: 'DELETE_LIVE_SESSION_SUCCESS',
    delete_error: 'DELETE_LIVE_SESSION_ERROR',
  },

  GET_FACULTY_LIST_BY_SUBJECT_ID: {
    get: 'GET_FACULTY_LIST_BY_SUBJECT_ID',
    get_success: 'GET_FACULTY_LIST_BY_SUBJECT_ID_SUCCESS',
    get_error: 'GET_FACULTY_LIST_BY_SUBJECT_ID_ERROR',
  },
  GET_USER_DEPARTMENTS: {
    get: 'GET_USER_DEPARTMENTS',
    get_success: 'GET_USER_DEPARTMENTS_SUCCESS',
    get_error: 'GET_USER_DEPARTMENTS_ERROR',
  },
  GET_USER_BASED_PROGRAM: {
    get: 'GET_USER_BASED_PROGRAM',
    get_success: 'GET_USER_BASED_PROGRAM_SUCCESS',
    get_error: 'GET_USER_BASED_PROGRAM_ERROR',
    reset: 'GET_USER_BASED_PROGRAM_RESET',
  },
  //Get Lab List
  VIRTUAL_LAB: {
    GET_LAB_LIST_REQUEST: 'GET_LAB_LIST_REQUEST',
    GET_LAB_LIST_SUCCESS: 'GET_LAB_LIST_SUCCESS',
    GET_LAB_LIST_ERROR: 'GET_LAB_LIST_ERROR',
  },

  //Create VM
  CREATE_VM: {
    CREATE_VM_REQUEST: 'CREATE_VM_REQUEST',
    CREATE_VM_SUCCESS: 'CREATE_VM_SUCCESS',
    CREATE_VM_ERROR: 'CREATE_VM_ERROR',
    RESET_CREATE_VM_REQUEST: 'RESET_CREATE_VM_REQUEST',
  },

  //Get Vm State
  GET_VM_STATE: {
    GET_VM_STATE_REQUEST: 'GET_VM_STATE_REQUEST',
    GET_VM_STATE_SUCCESS: 'GET_VM_STATE_SUCCESS',
    GET_VM_STATE_ERROR: 'GET_VM_STATE_ERROR',
  },
};
