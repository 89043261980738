export const groupChatApi = {
  //TODO: Needs to update endpoints
  candidateList: 'https://3582-38-137-25-197.ngrok-free.app/v3/users_list',
  createGroup: 'https://3582-38-137-25-197.ngrok-free.app/v3/group',
  addCandidate:
    'https://3582-38-137-25-197.ngrok-free.app/v3/group/131e31bc-d52d-4f07-9102-cc6bf66a3d7d',
  getGroups:
    'https://3582-38-137-25-197.ngrok-free.app/v3/group/131e31bc-d52d-4f07-9102-cc6bf66a3d7d',
  getGroupCandidate:
    'https://3582-38-137-25-197.ngrok-free.app/v3/AllMembersOfGroup',
  getChat:
    'https://3582-38-137-25-197.ngrok-free.app/v3/chats/6286717b-f6a6-4150-be8a-89d1537451a1',
  updateGroup: 'https://3582-38-137-25-197.ngrok-free.app/v3/updategroup',
  updateChat:
    'https://3582-38-137-25-197.ngrok-free.app/v3/chats/block_unblock_user',
};
