import { React } from 'react';
import {
  Box,
  ListItemText,
  List,
  ListItemButton,
  ListItem,
  Grid,
  Typography,
  Button,
} from 'iiht-b2a-ui-components/build/components';
import { EllipsisOptionComponent } from '../EllipsisOption';

export const CustomList = ({
  selectedIndex,
  setSelectedIndex,
  setSelectedItem,
  list,
  label,
  options,
  header = null,
  textVariant = 'b2a-list-item-button',
  btnColor,
  isEditAllowed,
  headerText,
  btnText,
  onClickHandler = () => {},
}) => {
  const handleListItemClick = (item) => {
    setSelectedIndex(
      item?.name || item?.id || item.group_name || item.group_id
    );
    setSelectedItem(item);
  };

  return (
    <Box sx={{ maxWidth: '25rem' }}>
      <List
        component="nav"
        aria-label={textVariant}
        className="side-content-bar"
        subheader={
          <Grid
            className="display-flex mb2"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" className="qa-chapters">
              {headerText ?? ''}
            </Typography>
            {isEditAllowed && (
              <Button
                sx={{ p: 0.5, mr: '6px' }}
                variant="text"
                color={btnColor ? btnColor : 'primary'}
                className="font-uppercase qa-add"
                onClick={onClickHandler}
              >
                {btnText}
              </Button>
            )}
          </Grid>
        }
      >
        {header}
        {list?.map?.((item, index) => (
          <ListItem disablePadding key={item?.name + index}>
            <ListItemButton
              variant={textVariant}
              selected={
                selectedIndex ===
                (item?.name || item?.id || item.group_name || item.group_id)
              }
              onClick={() => handleListItemClick(item)}
            >
              <ListItemText
                primary={item[label]}
                className={`qa-cluster-${item[label]}`}
              />

              {(options?.length && (
                <EllipsisOptionComponent
                  options={options}
                  record={item}
                  className={`qa-elipses-${item[label]}`}
                />
              )) ||
                null}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
