export const SettingsAPI = {
  create: '/api/userManagement/TenantPrivacySetting/Create',
  getList: '/api/userManagement/TenantPrivacySetting/GetList',
  get: '/api/userManagement/TenantPrivacySetting/Get',
  update: '/api/userManagement/TenantPrivacySetting/Update',
  delete: '/api/userManagement/TenantPrivacySetting/Delete',
  getPrivacyList: 'api/userManagement/UserPrivacySetting/GetList',
  updatePrivacy: 'api/userManagement/UserPrivacySetting/Update',
  getAllPrivacyList: 'api/userManagement/RolePrivacy/GetUserPrivacySettings',
};
