import { userProfileActions } from 'store/UserProfile';

const INITIAL_STATE = {
  UserProfile: {
    data: [],
    userProfileUpdateData: false,
    changePasswordSaveSuccess: false,
    profileImg: '',
  },
  SocialInformations: {
    data: [],
    //SocialInformationsCreate:false,
    SocialInformationsSaveSuccess: false,
  },
};

const userProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userProfileActions.USER_PROFILE.get_success: {
      return {
        ...state,
        UserProfile: {
          ...state.UserProfile,
          data: action?.payload,
        },
      };
    }

    case userProfileActions.USER_PROFILE.update_success: {
      return {
        ...state,
        UserProfile: {
          ...state.UserProfile,
          userProfileUpdateData: action.payload,
        },
      };
    }

    case userProfileActions.USER_PROFILE.profile_upload_img_success: {
      return {
        ...state,
        UserProfile: {
          ...state.UserProfile,
          profileImg: action.payload,
        },
      };
    }

    case userProfileActions.USER_PROFILE.reset_profile: {
      return {
        ...state,
        UserProfile: {
          ...state.UserProfile,
          userProfileUpdateData: false,
          profileImg: false,
        },
      };
    }

    case userProfileActions.USER_PROFILE.create_success: {
      return {
        ...state,
        UserProfile: {
          ...state.UserProfile,
          changePasswordSaveSuccess: action?.payload || [],
        },
      };
    }

    case userProfileActions.SOCIAL_HANDLE.get_success: {
      return {
        ...state,
        SocialInformations: {
          ...state.SocialInformations,
          data: action?.payload || [],
        },
      };
    }

    case userProfileActions.SOCIAL_HANDLE.create_success: {
      return {
        ...state,
        SocialInformations: {
          ...state.SocialInformations,
          SocialInformationsSaveSuccess: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default userProfileReducer;
