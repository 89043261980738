import { evaluationActions } from 'store/Evaluation';

const INITIAL_STATE = {
  revaluationSuccess: false,
  loading: false,
  subject: {
    data: [],
  },
  internal_evaluation_list: { data: { results: [], count: 0 } },
  internal_evaluation_export: { data: {} },
  internal_student_eval_list: {
    data: { results: [], count: 0, pending: 0, total_count: 0 },
  },
  submitInternalEval: {
    data: {},
  },
  submitInternalAssignmentEval: {
    data: '',
    loading: false,
  },
  student_exam_results: {
    loading: false,
    mid_end_term_list: {},
    subject_list: [],
    exam_quiz: {},
    exam_assignment: {},
    save_exam_assignment_success: false,
    save_exam_quiz_success: false,
    exam_upload_data: {},
    exam_upload_save_success: false,
    recheck_save_success: false,
    student_exams_list: {},
  },
  internal_exam_list: { data: {} },
  examStudentList: {
    data: '',
  },
  internalExamStudentList: {
    data: '',
    loading: false,
  },
  updateExamStudentEvaluation: '',
  updateExamAssignmentEvaluation: '',
  getStudentEvaluationDetails: '',
  userMappingSubjects: {
    programs: [],
    programYear: [],
    semester: [],
    subjectList: [],
    error: '',
    loading: false,
  },
};

const evaluationReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case evaluationActions.STUDENT_EXAM_AND_RESULTS.recheck_request_success: {
      return {
        ...state,
        student_exam_results: {
          ...state?.student_exam_results,
          recheck_save_success: action?.payload,
        },
      };
    }
    case evaluationActions.STUDENT_EXAM_AND_RESULTS.loading: {
      return {
        ...state,
        student_exam_results: {
          ...state?.student_exam_results,
          loading: action?.payload,
        },
      };
    }
    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .get_mid_end_term_exam_list_success: {
      const temp = {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          mid_end_term_list: {
            ...action?.payload,
            results: action?.payload?.results?.map((item) => ({
              ...item,
              marks: item?.exam
                ?.reduce((acc, curr) => acc.concat(curr?.exam_subject), [])
                ?.reduce(
                  (acc, curr) => ({
                    total_marks: acc.total_marks + curr?.total_marks,
                    total_marks_obtained:
                      acc.total_marks_obtained + curr?.total_marks_obtained,
                  }),
                  {
                    total_marks: 0,
                    total_marks_obtained: 0,
                  }
                ),
            })),
          },
        },
      };
      return temp;
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .get_exam_subject_list_success: {
      return {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          subject_list: action?.payload || [],
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .get_student_exams_list_success: {
      return {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          student_exams_list: action?.payload,
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .get_uploaded_assignment_success: {
      return {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          exam_upload_data: action?.payload,
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .submit_uploaded_assignment_success: {
      return {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          exam_upload_save_success: action?.payload,
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS.get_quiz_success: {
      return {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          exam_quiz: action?.payload,
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS.get_assignment_success: {
      return {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          exam_assignment: action?.payload,
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .submit_student_quiz_success: {
      return {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          save_exam_quiz_success: action?.payload,
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .submit_student_assignment_success: {
      return {
        ...state,
        student_exam_results: {
          ...state.student_exam_results,
          save_exam_assignment_success: action?.payload,
        },
      };
    }

    case evaluationActions.SHOW_LOADING.display_loading: {
      return {
        ...state,
        loading: action?.payload,
      };
    }

    case evaluationActions.SUBJECT.get_subject_list_success: {
      return {
        ...state,
        subject: {
          ...state.subject,
          data: action.payload.user_subjects || action.payload.results,
        },
      };
    }

    case evaluationActions.SUBJECT.reset_get_subject_list: {
      return {
        ...state,
        subject: INITIAL_STATE.subject,
      };
    }

    case evaluationActions.INTERNAL_EVALUATIONS_LIST
      .get_internal_evaluation_list_success: {
      return {
        ...state,
        internal_evaluation_list: {
          ...state.internal_evaluation_list,
          data: action.payload,
          count: action.payload?.count,
        },
      };
    }

    case evaluationActions.INTERNAL_EVALUATIONS_EXPORT
      .get_internal_evaluation_export_success: {
      return {
        ...state,
        internal_evaluation_export: {
          ...state.internal_evaluation_export,
          data: action.payload,
        },
      };
    }

    case evaluationActions.INTERNAL_STUDENT_EVAL_LIST
      .get_internal_student_eval_list_success: {
      return {
        ...state,
        internal_student_eval_list: {
          ...state.internal_student_eval_list,
          data: action.payload,
        },
      };
    }

    case evaluationActions.SUBMIT_INTERNAL_EVAL_MARKS
      .submit_internal_eval_marks_success: {
      return {
        ...state,
        submitInternalEval: {
          ...state.submitInternalEval,
          data: action.payload,
        },
      };
    }

    case evaluationActions.SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS
      .submit_internal_eval_assignment_marks_success: {
      return {
        ...state,
        submitInternalAssignmentEval: {
          ...state.submitInternalAssignmentEval,
          data: action.payload,
        },
      };
    }

    case evaluationActions.SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS
      .reset_submit_internal_eval_assignment_marks: {
      return {
        ...state,
        submitInternalAssignmentEval:
          INITIAL_STATE.submitInternalAssignmentEval,
      };
    }

    case evaluationActions.SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS
      .submit_internal_eval_assignment_marks_loader: {
      return {
        ...state,
        submitInternalAssignmentEval: {
          ...state.submitInternalAssignmentEval,
          loading: action.payload,
        },
      };
    }

    case evaluationActions.INTERNAL_STUDENT_EXAM_LIST
      .get_internal_student_exam_list_success: {
      return {
        ...state,
        internal_exam_list: {
          ...state.internal_exam_list,
          data: action.payload,
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS.GET_EXAM_STUDENTS_SUCCESS: {
      return {
        ...state,
        examStudentList: {
          ...state.examStudentList,
          data: action.payload || '',
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS.GET_EXAM_STUDENTS_RESET: {
      return {
        ...state,
        examStudentList: {
          ...state.examStudentList,
          data: '',
        },
      };
    }

    case evaluationActions.INTERNAL_EVALUATION_STUDENTS_LIST
      .internal_evaluation_students__list_success: {
      return {
        ...state,
        internalExamStudentList: {
          ...state.internalExamStudentList,
          data: action.payload || '',
        },
      };
    }

    case evaluationActions.INTERNAL_EVALUATION_STUDENTS_LIST
      .reset_internal_evaluation_students__list: {
      return {
        ...state,
        internalExamStudentList: INITIAL_STATE.internalExamStudentList,
      };
    }

    case evaluationActions.INTERNAL_EVALUATION_STUDENTS_LIST
      .get_internal_evaluation_students__list_loader: {
      return {
        ...state,
        internalExamStudentList: {
          ...state.internalExamStudentList,
          loading: action.payload,
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .UPDATE_EXAM_STUDENTS_EVALUATION_SUCCESS: {
      return {
        ...state,
        updateExamStudentEvaluation: {
          ...state.updateExamStudentEvaluation,
          updateExamStudentEvaluation: action.payload || '',
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .UPDATE_EXAM_STUDENTS_EVALUATION_RESET: {
      return {
        ...state,
        updateExamStudentEvaluation: {
          ...state.updateExamStudentEvaluation,
          updateExamStudentEvaluation: '',
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .GET_STUDENT_EVALUATION_DETAILS_SUCCESS: {
      return {
        ...state,
        getStudentEvaluationDetails: {
          ...state.getStudentEvaluationDetails,
          getStudentEvaluationDetails: action.payload || '',
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .GET_STUDENT_EVALUATION_DETAILS_RESET: {
      return {
        ...state,
        getStudentEvaluationDetails: {
          ...state.getStudentEvaluationDetails,
          getStudentEvaluationDetails: '',
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .UPDATE_EXAM_ASSIGNMENT_EVALUATION_SUCCESS: {
      return {
        ...state,
        updateExamAssignmentEvaluation: {
          ...state.updateExamAssignmentEvaluation,
          updateExamAssignmentEvaluation: action.payload || '',
        },
      };
    }

    case evaluationActions.STUDENT_EXAM_AND_RESULTS
      .UPDATE_EXAM_ASSIGNMENT_EVALUATION_RESET: {
      return {
        ...state,
        updateExamAssignmentEvaluation: {
          ...state.updateExamAssignmentEvaluation,
          updateExamAssignmentEvaluation: '',
        },
      };
    }

    case evaluationActions.GET_USER_MAPPED_PROGRAMS_SUCCESS: {
      return {
        ...state,
        userMappingSubjects: {
          ...state.userMappingSubjects,
          programs: action.payload,
        },
      };
    }

    case evaluationActions.GET_USER_MAPPED_YEAR_SUCCESS: {
      return {
        ...state,
        userMappingSubjects: {
          ...state.userMappingSubjects,
          programYear: action.payload,
        },
      };
    }

    case evaluationActions.GET_USER_MAPPED_SEMESTER_SUCCESS: {
      return {
        ...state,
        userMappingSubjects: {
          ...state.userMappingSubjects,
          semester: action.payload,
        },
      };
    }

    case evaluationActions.GET_USER_MAPPED_SUBJECTS_SUCCESS: {
      return {
        ...state,
        userMappingSubjects: {
          ...state.userMappingSubjects,
          subjectList: action.payload,
        },
      };
    }

    case evaluationActions.GET_USER_MAPPED_SUBJECTS_ERROR: {
      return {
        ...state,
        userMappingSubjects: {
          ...state.userMappingSubjects,
          error: action.payload,
        },
      };
    }

    case evaluationActions.RESET_USER_MAPPED_SUBJECTS: {
      return {
        ...state,
        userMappingSubjects: INITIAL_STATE.userMappingSubjects,
      };
    }

    case evaluationActions.GET_USER_MAPPED_SUBJECTS_LOADER: {
      return {
        ...state,
        userMappingSubjects: {
          ...state.userMappingSubjects,
          loading: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default evaluationReducer;
