import React from 'react';

const TrendingUp = ({ color, height = '24', width = '24', fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 -960 960 960"
      fill={fill}
      style={{ marginLeft: '1rem' }}
    >
      <path
        fill={fill}
        d="m136-240-56-56 296-298 160 160 208-206H640v-80h240v240h-80v-104L536-320 376-480 136-240Z"
      />
    </svg>
  );
};

export default TrendingUp;
