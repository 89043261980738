import { takeLatest, put, call, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import {
  getCandidateListApi,
  createGroupApi,
  addCandidateToGroupApi,
  getGroupsAPI,
  getGroupCandidateAPI,
  getChatListAPI,
  updateGroupAPI,
  updateChatAPI,
} from './Groupchat.api';
import { groupChatActions } from './Groupchat.constants';
import {
  getCandidateListSuccess,
  getCandidateListFail,
  createGroupSuccess,
  createGroupFail,
  addCandidateToGroupSuccess,
  addCandidateToGroupFail,
  getGroupsSuccess,
  getGroupsFail,
  getGroupCandidateListSuccess,
  getGroupCandidateListFail,
  getChatListSuccess,
  getChatListFail,
  updateGroupDataSuccess,
  updateGroupDataFail,
  updateChatDataSuccess,
  updateChatDataFail,
} from './Groupchat.action';

const { GROUPCHAT } = groupChatActions;

function* getCandidateListSaga({ payload }) {
  try {
    const response = yield call(getCandidateListApi, payload);
    const data = [
      {
        user_uuid: '5c5aeaec-1a50-4aba-a652-14317d2dff53',
        first_name: 'Abhi',
        last_name: 'Khanna',
        email_id: 'abc@gmail.com',
        created_at: '2023-06-13T12:02:56.339554Z',
      },
      {
        user_uuid: '581999c2-6d31-4201-8b8d-53cbe22b8611',
        first_name: 'Abhi',
        last_name: 'Khanna',
        email_id: 'abc11@gmail.com',
        created_at: '2023-06-13T12:04:57.072177Z',
      },
      {
        user_uuid: '412ad97b-aa17-4bbe-90d0-829d25d7a79e',
        first_name: 'Abhi',
        last_name: 'Khanna',
        email_id: 'abc111@gmail.com',
        created_at: '2023-06-13T12:05:33.339062Z',
      },
    ];
    yield put(getCandidateListSuccess(data));
    // if (response?.status === statusCode.Ok200) {
    //   yield put(getCandidateListSuccess(response?.data));
    // } else {
    //   yield put(getCandidateListFail(response?.data));
    // }
  } catch (error) {
    console.error(error);
  }
}

function* createGroupSaga({ payload }) {
  try {
    const response = yield call(createGroupApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createGroupSuccess(response?.data));
    } else {
      yield put(createGroupFail(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* addCandidateToGroupSaga({ payload }) {
  try {
    const response = yield call(addCandidateToGroupApi, payload);
    yield put(addCandidateToGroupSuccess(response));
    if (response?.status === statusCode.Ok200) {
      yield put(addCandidateToGroupSuccess(response?.data));
    } else {
      yield put(addCandidateToGroupFail(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getGroupsSaga({ payload }) {
  try {
    const response = yield call(getGroupsAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getGroupsSuccess(response?.data));
    } else {
      yield put(getGroupsFail(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getGroupCandidateSaga({ payload }) {
  try {
    const response = yield call(getGroupCandidateAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getGroupCandidateListSuccess(response?.data));
    } else {
      yield put(getGroupCandidateListFail(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getChatListSaga({ payload }) {
  try {
    const response = yield call(getChatListAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getChatListSuccess(response?.data));
    } else {
      yield put(getChatListFail(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateGroupSaga({ payload }) {
  try {
    const response = yield call(updateGroupAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateGroupDataSuccess(response?.data));
    } else {
      yield put(updateGroupDataFail(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateChatSaga({ payload }) {
  try {
    const response = yield call(updateChatAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateChatDataSuccess(response?.data));
    } else {
      yield put(updateChatDataFail(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchDummy() {
  yield takeLatest(GROUPCHAT?.GET_CANDIDATE_LIST, getCandidateListSaga);
  yield takeLatest(GROUPCHAT.CREATE_GROUP, createGroupSaga);
  yield takeLatest(GROUPCHAT.ADD_CANDIDATE_TO_GROUP, addCandidateToGroupSaga);
  yield takeLatest(GROUPCHAT.GET_GROUPS, getGroupsSaga);
  yield takeLatest(GROUPCHAT.GET_GROUP_CANDIDATE_LIST, getGroupCandidateSaga);
  yield takeLatest(GROUPCHAT.GET_CHAT_LIST, getChatListSaga);
  yield takeLatest(GROUPCHAT.UPDATE_GROUP_DATA, updateGroupSaga);
  yield takeLatest(GROUPCHAT.UPDATE_CHAT_DATA, updateChatSaga);
}

export default function* groupChatSaga() {
  yield all([watchDummy()]);
}
