export const VerifierActions = {
  GET_VERIFIER_STATS: 'GET_VERIFIER_STATS',
  SET_VERIFIER_STATS_SUCCESS: 'SET_VERIFIER_STATS_SUCCESS',
  SET_VERIFIER_STATS_ERROR: 'SET_VERIFIER_STATS_ERROR',

  GET_CANDIDATES_LIST: 'GET_CANDIDATES_LIST',
  SET_CANDIDATES_LIST_SUCCESS: 'SET_CANDIDATES_LIST_SUCCESS',
  SET_CANDIDATES_LIST_ERROR: 'SET_CANDIDATES_LIST_ERROR',
  SET_CANDIDATES_LIST_LOADING: 'SET_CANDIDATES_LIST_LOADING',

  GET_CANDIDATE_APPLICATION_INFO: 'GET_CANDIDATE_APPLICATION_INFO',
  SET_CANDIDATE_APPLICATION_INFO_SUCCESS:
    'SET_CANDIDATE_APPLICATION_INFO_SUCCESS',
  SET_CANDIDATE_APPLICATION_INFO_ERROR: 'SET_CANDIDATE_APPLICATION_INFO_ERROR',
  RESET_CANDIDATE_APPLICATION_INFO: 'RESET_CANDIDATE_APPLICATION_INFO',

  GET_PROSPECTUS_FEEDBACK: 'GET_PROSPECTUS_FEEDBACK',
  SET_PROSPECTUS_FEEDBACK_SUCCESS: 'SET_PROSPECTUS_FEEDBACK_SUCCESS',
  SET_PROSPECTUS_FEEDBACK_ERROR: 'SET_PROSPECTUS_FEEDBACK_ERROR',
  SET_PROSPECTUS_FEEDBACK_LOADING: 'SET_PROSPECTUS_FEEDBACK_LOADING',

  SEND_PROSPECTUS_FEEDBACK: 'SEND_PROSPECTUS_FEEDBACK',
  SEND_PROSPECTUS_FEEDBACK_SUCCESS: 'SEND_PROSPECTUS_FEEDBACK_SUCCESS',
  SEND_PROSPECTUS_FEEDBACK_ERROR: 'SEND_PROSPECTUS_FEEDBACK_ERROR',
  SET_PROSPECTUS_LOADING: 'SET_PROSPECTUS_LOADING',

  UPDATE_PROSPECTUS_STATUS: 'UPDATE_PROSPECTUS_STATUS',
  UPDATE_PROSPECTUS_STATUS_SUCCESS: 'UPDATE_PROSPECTUS_STATUS_SUCCESS',
  UPDATE_PROSPECTUS_STATUS_ERROR: 'UPDATE_PROSPECTUS_STATUS_ERROR',
  RESET_UPDATE_PROSPECTUS_STATUS: 'RESET_UPDATE_PROSPECTUS_STATUS',

  GET_FEES_DETAILS: 'GET_FEES_DETAILS',
  SET_FEES_DETAILS_SUCCESS: 'SET_FEES_DETAILS_SUCCESS',
  SET_FEES_DETAILS_ERROR: 'SET_FEES_DETAILS_ERROR',
  RESET_FEES_DETAILS: 'RESET_FEES_DETAILS',

  ADD_DISCOUNT: 'ADD_DISCOUNT',
  ADD_DISCOUNT_SUCCESS: 'ADD_DISCOUNT_SUCCESS',
  ADD_DISCOUNT_ERROR: 'ADD_DISCOUNT_ERROR',
  RESET_ADD_DISCOUNT: 'RESET_ADD_DISCOUNT',

  DELETE_DISCOUNT: 'DELETE_DISCOUNT',
  DELETE_DISCOUNT_SUCCESS: 'DELETE_DISCOUNT_SUCCESS',
  DELETE_DISCOUNT_ERROR: 'DELETE_DISCOUNT_ERROR',
  RESET_DELETE_DISCOUNT: 'RESET_DELETE_DISCOUNT',

  GET_LEAD_STAGE_VERIFIER: 'GET_LEAD_STAGE_VERIFIER',
  SUCCESS_LEAD_STAGE_VERIFIER: 'SUCCESS_LEAD_STAGE_VERIFIER',
  ERROR_LEAD_STAGE_VERIFIER: 'ERROR_LEAD_STAGE_VERIFIER',

  UPDATE_PAYMENT_DETAILS: 'REQUEST_UPDATE_PAYMENT_DETAILS',
  SUCCESS_UPDATE_PAYMENT_DETAILS: 'SUCCESS_UPDATE_PAYMENT_DETAILS',
  ERROR_UPDATE_PAYMENT_DETAILS: 'ERROR_UPDATE_PAYMENT_DETAILS',
  RESET_UPDATE_PAYMENT_DETAILS: 'RESET_UPDATE_PAYMENT_DETAILS',

  SEND_PAYMENT_LINK: 'REQUEST_SEND_PAYMENT_LINK',
  SUCCESS_SEND_PAYMENT_LINK: 'SUCCESS_SEND_PAYMENT_LINK',
  ERROR_SEND_PAYMENT_LINK: 'ERROR_SEND_PAYMENT_LINK',
  RESET_SEND_PAYMENT_LINK: 'RESET_SEND_PAYMENT_LINK',

  SEND_TO_REGISTRAR: 'SEND_TO_REGISTRAR',
  SUCCESS_SEND_TO_REGISTRAR: 'SUCCESS_SEND_TO_REGISTRAR',
  ERROR_SEND_TO_REGISTRAR: 'ERROR_SEND_TO_REGISTRAR',
  RESET_SEND_TO_REGISTRAR: 'RESET_SEND_TO_REGISTRAR',

  RECEIPT_FILE_UPLOAD: 'RECEIPT_FILE_UPLOAD',
  RECEIPT_FILE_UPLOAD_SUCCESS: 'RECEIPT_FILE_UPLOAD_SUCCESS',

  GET_USER_DETAILS: 'GET_USER_DETAILS',
  GET_USER_DETAILS_SUCCESS:'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_ERROR:'GET_USER_DETAILS_ERROR'
};
