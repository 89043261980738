export const userProfileActions = {
  USER_PROFILE: {
    get: 'USER_PROFILE_GET',
    get_success: 'USER_PROFILE_GET_SUCCESS',
    get_error: 'USER_PROFILE_GET_ERROR',
    reset_profile: 'RESET_USER_PROFILE',
    update: 'USER_PROFILE_UPDATE',
    update_success: 'USER_PROFILE_UPDATE_SUCCESS',
    update_error: 'USER_PROFILE_UPDATE_ERROR',
    profile_upload_img_success: 'SUCCESS_PROFILE_UPLOAD_IMAGE',
    profile_upload_img_error: 'ERROR_PROFILE_UPLOAD_IMAGE',
    create: 'USER_PROFILE_CHANGE_PASSWORD_CREATE',
    create_success: 'USER_PROFILE_CHANGE_PASSWORD_CREATE_SUCCESS',
    create_error: 'USER_PROFILE_CHANGE_PASSWORD_CREATE_ERROR',
  },
  SOCIAL_HANDLE:{
    get: 'USER_PROFILE_SOCIAL_GET',
    get_success: 'USER_PROFILE_SOCIAL_GET_SUCCESS',
    get_error: 'USER_PROFILE_SOCIAL_GET_ERROR',
    create: 'USER_PROFILE_SOCIAL_CREATE',
    create_success: 'USER_PROFILE_SOCIAL_CREATE_SUCCESS',
    create_error: 'USER_PROFILE_SOCIAL_CREATE_ERROR',
  },
};
