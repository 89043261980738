import axiosInstance from 'translator';
import { LeadAPI } from 'store/Lead';

export const getLeadListApi = async (payload) => {
  return axiosInstance
    .get(`${LeadAPI?.getLeadList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateLeadInfoApi = async (payload) => {
  return axiosInstance
    .patch(`${LeadAPI?.admission_lead}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postLeadInfoApi = async (payload) => {
  return axiosInstance
    .post(`${LeadAPI?.postLeadInformation}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteLeadInfoApi = async (payload) => {
  return axiosInstance
    .delete(`${LeadAPI?.deleteLeadInformation}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postNotesByIdApi = async (payload) => {
  return axiosInstance
    .post(`${LeadAPI?.postNotesById}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getNotesByIdApi = async (payload) => {
  return axiosInstance
    .get(
      `${LeadAPI?.getNotesByLeadId}?lead=${payload.leadId}&limit=${payload?.limit}&offset=${payload?.offset}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadStatusApi = async (payload) => {
  return axiosInstance
    .get(`${LeadAPI?.getLeadStatus}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadStageApi = async (payload) => {
  return axiosInstance
    .get(`${LeadAPI?.getLeadStage}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateLeadStatusApi = async (payload) => {
  return axiosInstance
    .patch(`${LeadAPI?.admission_lead}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDashboardStatsApi = async () => {
  return axiosInstance
    .get(`${LeadAPI?.getDashboardStats}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadPlacementListApi = async (payload) => {
  return axiosInstance
    .get(`${LeadAPI?.getLeadPlacementList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateLeadStageApi = async (payload) => {
  return axiosInstance
    .post(`${LeadAPI?.updateLeadStage}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadInformationByIdApi = async (id) => {
  return axiosInstance
    .get(`${LeadAPI?.admission_lead}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postBulkLeadInfoApi = async (payload) => {
  return axiosInstance
    .post(`${LeadAPI?.bulkUploadLeadData}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createLeadApi = async (payload) => {
  return axiosInstance
    .post(`${LeadAPI?.admission_lead}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const patchUpdateLeadStatusApi = async (id, payload) => {
  return axiosInstance
    .patch(`${LeadAPI?.admission_lead}/${id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const leadFileUploadApi = async (payload) => {
  return axiosInstance
    .post(`${LeadAPI?.fileUpload}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const leadBulkFileUploadApi = async (payload) => {
  return axiosInstance
    .post(`${LeadAPI?.uploadFile}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadFileBlobUrlApi = async (payload) => {
  return axiosInstance
    .get(`${LeadAPI?.fileUpload}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadConversionApi = async (payload) => {
  return axiosInstance
    .get(`${LeadAPI?.leadConversion}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const leadUploadMultiFileApi = async (payload) => {
  return axiosInstance
    .post(`${LeadAPI?.multipleFileUpload}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getRoleApi = async (payload) => {
  return axiosInstance
  .get(`${LeadAPI?.getRole}`, { params: payload })
  .then((response)=>{
    return response;
  })
  .catch((error)=>{
    return error
  })
}