import React, { useEffect, useState } from 'react';
import './DocumentViewerStyle.scss';
import { Button, Link } from 'iiht-b2a-ui-components/build/components';
import FileViewer from 'react-file-viewer';
import { useTranslation } from 'react-i18next';
import CustomFileViewer from './Drivers/CustomFileViewer';
import {
  CUSTOM_FILE_SUPPORT,
  REACT_FILE_VIEWER_SUPPORT,
} from './DocumentViewerConstant';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { PlayCircle } from 'icons';
import { theme } from 'themes';

/*
  attachmentList = [{fileName: '', fileType: '', fileURL: ''}]
  style = {}
  isFileDownloadAvailable = false
*/
const DocumentViewer = (props) => {
  const {
    attachmentList = [],
    style = '',
    isFileDownloadAvailable = false,
    displayNoOfDoc = true,
    selectedItem = -1,
  } = props || '';
  const [currentAttachement, setCurrentAttachment] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [attachmentDocumentList, setAttachmentDocumentList] = useState([]);
  const [attachedDomKey, setAttachedDomKey] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedItem > -1) {
      setCurrentAttachment({});
    }
  }, [selectedItem]);

  useEffect(() => {
    if (
      Object.keys(currentAttachement)?.length === 0 &&
      attachmentList?.length > 0
    ) {
      if (attachmentList[0]) {
        const fileObj = {
          ...attachmentList[0],
          fileURL: attachmentList[0].fileURL.split('?')[0],
        };
        console.log(fileObj);
        setCurrentAttachment(fileObj);
        setAttachmentDocumentList(attachmentList);
      }
    }
  }, [attachmentList, currentAttachement]);

  useEffect(() => {
    return () => {
      setCurrentAttachment({});
      setCurrentPage(0);
      setAttachmentDocumentList([]);
      setAttachedDomKey(0);
    };
  }, []);

  const onError = () => {};

  const handlePrevAttached = () => {
    const prevIndex = currentPage - 1;
    if (prevIndex !== -1) {
      setCurrentPage(prevIndex);
      const foundAttached = attachmentDocumentList[prevIndex];
      if (Object.keys(foundAttached)?.length > 0) {
        setAttachedDomKey((prevCount) => prevCount + 1);
        setCurrentAttachment(foundAttached);
      }
    }
  };

  const handleNextAttached = () => {
    const nextIndex = currentPage + 1;
    if (attachmentDocumentList?.length > nextIndex) {
      setCurrentPage(nextIndex);
      const foundAttached = attachmentDocumentList[nextIndex];
      if (Object.keys(foundAttached)?.length > 0) {
        setAttachedDomKey((prevCount) => prevCount + 1);
        setCurrentAttachment(foundAttached);
      }
    }
  };

  return (
    <div id="document-viewer" className="documentViwer">
      {Object.keys(currentAttachement)?.length > 0 && (
        <>
          <div className="documentViewerHeader">
            <div>{currentAttachement?.fileName || ''}</div>
            {displayNoOfDoc && (
              <div className="documentViewerAction">
                <div>
                  Doc {currentPage + 1} of {attachmentDocumentList?.length}
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handlePrevAttached()}
                  disabled={currentPage === 0}
                >
                  {t('notificationsAndBrodcast.prevAttachment')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleNextAttached()}
                  disabled={currentPage + 1 === attachmentDocumentList?.length}
                >
                  {t('notificationsAndBrodcast.nextAttachment')}
                </Button>
              </div>
            )}
          </div>
          <div className="documentViewDownloadWrapper" style={{ ...style }}>
            {isFileDownloadAvailable && (
              <div className="documentDownload">
                <Link href={currentAttachement?.fileURL} target="_blank">
                  <PlayCircle
                    fill={theme.palette.primary.main}
                    height={26}
                    width={26}
                    sx={{ color: '#02387A' }}
                  />
                </Link>
              </div>
            )}
            <div className="documentViewerBody">
              {REACT_FILE_VIEWER_SUPPORT.includes(
                currentAttachement?.fileType
              ) && (
                <>
                  <FileViewer
                    key={attachedDomKey}
                    fileType={currentAttachement?.fileType}
                    filePath={currentAttachement?.fileURL}
                    onError={onError}
                  />
                </>
              )}
              {CUSTOM_FILE_SUPPORT.includes(currentAttachement?.fileType) && (
                <CustomFileViewer
                  key={attachedDomKey}
                  fileType={currentAttachement?.fileType}
                  filePath={currentAttachement?.fileURL}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentViewer;
