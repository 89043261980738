import { notesActions } from './Notes.constants';

export const setNoteSaveSuccess = () => {
  return {
    type: notesActions.SET_NOTE_SAVE_SUCCESS,
  };
};

export const resetNoteSaveSuccess = () => {
  return {
    type: notesActions.RESET_NOTE_SAVE_SUCCESS,
  };
};
