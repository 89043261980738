import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  Button,
} from 'iiht-b2a-ui-components/build/components';
import { Controller, useFormContext } from 'react-hook-form';
import { isObject, isBoolean } from 'lodash';
import { validateFileSize, validateFileType } from 'utils/validation';

const FormUploadInput = React.memo(
  ({
    name,
    fullWidth,
    className,
    uploadFunc,
    uploadPayload = {},
    required,
    multiple,
    rules = {},
    size,
    color = 'secondary',
    acceptedFormats = [],
    fileSupportErrorMessage = '',
    validFileSize = 2,
    isAsterisk,
    label,
    disabled = false,
    ...rest
  }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const { getFieldState } = control;
    const [dispValue, setDispValue] = useState('');
    const [acceptedFileInputFormat, setAcceptedFileInputFormat] = useState('');
    const [validationRules, setValidationRules] = useState({});

    const validateFile = (file = {}, errorMessage = {}) => {
      if (file && file instanceof File) {
        if (validateFileType(acceptedFormats, file)) {
          return validateFileSize(validFileSize, file) || errorMessage?.size;
        } else {
          return errorMessage?.type;
        }
      }
    };

    useEffect(() => {
      if (
        acceptedFormats?.length > 0 &&
        acceptedFileInputFormat?.length === 0 &&
        Object.keys(validationRules)?.length === 0
      ) {
        setAcceptedFileInputFormat(acceptedFormats?.join());
        setValidationRules({
          ...rules,
          validate: (file) => validateFile(file, fileSupportErrorMessage),
        });
      }
    }, [acceptedFormats]);

    return (
      <FormControl
        margin="normal"
        fullWidth={fullWidth}
        className={className}
        size={size}
      >
        <Controller
          name={name}
          control={control}
          rules={validationRules}
          render={({ field }) => {
            const { name, value, onChange, onBlur } = field;
            const { invalid, error } = getFieldState(name);
            if (!value) {
              setDispValue('');
            }
            const handleChange = (event) => {
              const files = Array.from(event.target.files);
              const [file] = files;
              if (files?.length > 1) {
                setDispValue(`${files?.length} Files`);
              } else if (files?.length === 1) {
                setDispValue(file.name);
              } else if (files?.length === 0) {
                setDispValue('');
              }
              let filesToUpload = multiple ? files : file;
              let filesToUploadPayload = multiple
                ? [...files, uploadPayload]
                : { file, ...uploadPayload };
              onChange(filesToUpload);
              if (isBoolean(validateFile(file, fileSupportErrorMessage))) {
                uploadFunc?.(filesToUploadPayload);
              }
            };

            return (
              <TextField
                error={error ? 'true' : ''}
                aria-invalid={invalid ? 'true' : 'false'}
                helperText={error?.message || ''}
                size={size}
                value={dispValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        //  sx={{ padding: 0 }}
                        variant="text"
                        component="label"
                        color={color}
                        className="qa-browse-btn"
                      >
                        Browse
                        <input
                          hidden
                          required={required}
                          multiple={multiple}
                          type="file"
                          name={name}
                          onChange={handleChange}
                          accept={acceptedFileInputFormat}
                          disabled={disabled}
                        />
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onChange={() => {}}
                // disabled
                color={color}
                label={`${label}${isAsterisk ? '*' : ''}`}
                {...rest}
              />
            );
          }}
        ></Controller>
      </FormControl>
    );
  }
);

export default FormUploadInput;
