export const studentAPI = {
  getAssessmentList: '/api/AssessmentService/Assessment/GetList',
  getAssignmentList: '/api/AssessmentService/Assignment/GetPaginatedList',
  getAssessmentById: '/api/AssessmentService/Assessment/GetByAssessmentId',
  getAssignmentById: '/api/AssessmentService/Assignment/GetAssignmentById',
  getByQuizId: '/api/AssessmentService/Assignment/GetByQuizId',
  getCourseModule: '/api/AssessmentService/CourseModule/GetModuleByCourseId',
  getSubModule: '/api/AssessmentService/SubModules/GetList',
  getCourseByStudentId: '/api/courseManagement/Course/GetCourseByStudentId',
  fetchAssignmentBySubModuleId:
    '/api/AssessmentService/Assignment/GetPaginatedAssignmentsBySubmoduleId',
  fetchAssessmentBySubModuleId:
    '/api/AssessmentService/Assessment/GetPaginatedListBySubModuleId',
  getNotesAndBookmarks: '/api/myDataService/MyNote/GetList',
  // deleteNotesAndBookmarks: '/api/myDataService/MyNote/Delete',
  deleteNotesAndBookmarks: 'v2/student-notes',
  // updateNotesAndBookmarks: '/api/myDataService/MyNote/Update',
  updateNotesAndBookmarks: 'v2/student-notes',
  getAllUserDetails: '/api/userManagement/UserManage/GetAllUserDetails',
  // createNotesAndBookmarks: '/api/myDataService/MyNote/Create',
  createNotesAndBookmarks: 'v2/student-notes',
  getDetailsByCourseId: 'api/AssessmentService/CourseModule/GetListByCourseId',
  createAssessment: '/v2/content/assignments',
  createQuiz: '/v2/content/quiz',
  getNotesBySubModuleId: '/api/myDataService/MyNote/GetMyNoteBySubModuleId',
  getMyNotesByModuleId: '/api/myDataService/MyNote/GetMyNoteBySubModuleId',
  submitAssessment: '/api/AssessmentService/Result/Create',
  getPendingAssessments:
    '/api/AssessmentService/Assessment/GetPaginatedListBySubModuleId',
  getPendingAssigments:
    '/api/AssessmentService/Assignment/GetPaginatedAssignmentsBySubmoduleId',
  fetchAssessmentByStudentId:
    '/api/AssessmentService/Result/GetStudentActivities',
  editAssessment: '/api/AssessmentService/Assessment/Update',
  editAssignment: '/api/AssessmentService/Assignment/UpdateAssignmentById',
  deleteAssessment: '/api/AssessmentService/Assessment/Delete',
  deleteAssignment: '/api/AssessmentService/Assignment/DeleteAssignment',
  studentSemester: '/v2/student-semester',
  studentSemesterBulkUpdate: '/v2/student-semester/bulk_update',
  studentSemesterBulkDelete: '/v2/student-semester/bulk_delete',
  // getNotesByContentId: '/api/myDataService/MyNote/GetMyNotesByContent',
  getNotesByContentId: 'v2/student-notes',
  createBookmarks: '/api/myDataService/MyNote/AddOrUpdateBookMark',
  learningQuiz: '/v2/learning/quiz',
  assignmentsApiUrl: '/v2/learning/assignment',
  videoProgress: '/v2/learning/video',
  readingProgress: '/v2/learning/reading',
  subjectProgramDetails: '/v2/student-program-details',
  // Student Connect
  StudentList: '/v2/student-mapped-semester',
  getStudentNotifiation: '/v2/student-connect',
  bulkDelete: '/v2/student-connect/bulk-delete',
};
