import { statusCode } from 'constants/apis/apiStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  CreateAdminApi,
  CreateTenantApi,
  GetAllAdminsApi,
  GetAllTenantApi,
  GetByAdminApi,
  GetByTenantApi,
  SuperAdminActions,
  UploadAddAdminApi,
  UploadTenantAssetsApi,
  UploadTenantLoginApi,
  UploadTenantLogoApi,
  UploadUserManualApi,
  activateAdminApi,
  activateTenantApi,
  deleteAdminApi,
  deleteTenantApi,
  editTenantApi,
  getSuperAdminCardsApi,
  migrationAdmissionDBAPI,
  migrationAttendanceDBAPI,
  migrationExamDBAPI,
  migrationTenantListAPI,
  migrationUserManagementDBAPI,
} from 'store/SuperAdmin';
import { setToastMessage } from 'store/Toast';
import {
  MigrationLoading,
  TenantLoading,
  activateAdminError,
  activateAdminSuccess,
  activateTenantError,
  activateTenantSuccess,
  createAdminError,
  createAdminSuccess,
  createTenantError,
  createTenantSuccess,
  deleteAddAdminError,
  deleteAddAdminSuccess,
  deleteTenantError,
  deleteTenantSuccess,
  editTenantError,
  editTenantSuccess,
  getAdminDetailsError,
  getAdminDetailsSuccess,
  getAllAddAdminError,
  getAllAddAdminSuccess,
  getAllTenantsError,
  getAllTenantsSuccess,
  getCardItemError,
  getCardItemSuccess,
  getTenantsDetailsError,
  getTenantsDetailsSuccess,
  migrationAdmissionError,
  migrationAdmissionSuccess,
  migrationAttendanceError,
  migrationAttendanceSuccess,
  migrationExamError,
  migrationExamSuccess,
  migrationTenantListError,
  migrationTenantListSuccess,
  migrationUserManagementError,
  migrationUserManagementSuccess,
  uploadAddAdminError,
  uploadAddAdminSuccess,
  uploadTenantAssetsError,
  uploadTenantAssetsSuccess,
  uploadTenantLoginError,
  uploadTenantLoginSuccess,
  uploadTenantLogoError,
  uploadTenantLogoSuccess,
  uploadUserManualError,
  uploadUserManualSuccess,
} from './SuperAdmin.actions';

const { Tenant, MIGRATIONS_DB } = SuperAdminActions;

function* getAllTenants({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(GetAllTenantApi, payload);
    yield put(TenantLoading(false));
    yield put(getAllTenantsSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(getAllTenantsSuccess(response?.data));
    } else {
      yield put(getAllTenantsError('Something went wrong'));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* getByTenantsSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(GetByTenantApi, payload);
    yield put(getTenantsDetailsSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(getTenantsDetailsSuccess(response?.data));
      yield put(TenantLoading(false));
    } else {
      yield put(getTenantsDetailsError('Something went wrong'));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* uploadTenantAssetsSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(UploadTenantAssetsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(uploadTenantAssetsSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
      yield put(TenantLoading(false));
    } else {
      yield put(uploadTenantAssetsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* uploadTenantLogoSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(UploadTenantLogoApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(uploadTenantLogoSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
      yield put(TenantLoading(false));
    } else {
      yield put(uploadTenantLogoError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* uploadTenantLoginSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(UploadTenantLoginApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(uploadTenantLoginSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
      yield put(TenantLoading(false));
    } else {
      yield put(uploadTenantLoginError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* uploadUserManualSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(UploadUserManualApi, payload);
    yield put(TenantLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(uploadUserManualSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(uploadUserManualError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* createTenantSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(CreateTenantApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createTenantSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.success, severity: 'success' })
      );
      yield put(TenantLoading(false));
    } else {
      yield put(createTenantError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
  }
}

function* deleteTenantSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(deleteTenantApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteTenantSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.success, severity: 'success' })
      );
      yield put(TenantLoading(false));
    } else {
      yield put(deleteTenantError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* editTenantSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(editTenantApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(editTenantSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.success, severity: 'success' })
      );
      yield put(TenantLoading(false));
    } else {
      yield put(editTenantError(response?.data));
      let errorMessage = [];
      const flattenErrors = (errorObj) => {
        for (const key in errorObj) {
          if (typeof errorObj[key] === 'object') {
            flattenErrors(errorObj[key]);
          } else {
            errorMessage = errorMessage.concat(errorObj[key]);
          }
        }
      };
      flattenErrors(response?.data?.message);

      yield put(
        setToastMessage({
          data: errorMessage,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    let errorMessage;
    if (error?.response?.data?.message) {
      errorMessage = Object.values(error?.response?.data?.message)?.flat();
    } else {
      errorMessage = [error?.message || 'Something went wrong!'];
    }
    yield put(setToastMessage({ data: errorMessage, severity: 'error' }));
    console.error(error);
  }
}

function* activateTenantSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(activateTenantApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(activateTenantSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.success, severity: 'success' })
      );
      yield put(TenantLoading(false));
    } else {
      yield put(activateTenantError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* uploadAddAdminSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(UploadAddAdminApi, payload);
    yield put(TenantLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(uploadAddAdminSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(uploadAddAdminError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* getAllAdmins({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(GetAllAdminsApi, payload);
    yield put(TenantLoading(false));
    yield put(getAllAddAdminSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(getAllAddAdminSuccess(response?.data));
    } else {
      yield put(getAllAddAdminError('Something went wrong'));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteAdminSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(deleteAdminApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteAddAdminSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.success, severity: 'success' })
      );
      yield put(TenantLoading(false));
    } else {
      yield put(deleteAddAdminError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* createAdminSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(CreateAdminApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createAdminSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.success, severity: 'success' })
      );
      yield put(TenantLoading(false));
    } else {
      yield put(createAdminError(response?.data));
      let errorMessage = [];
      for (const key in response?.data?.message) {
        errorMessage = errorMessage.concat(response?.data?.message[key]);
      }
      yield put(
        setToastMessage({
          data: errorMessage,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    let errorMessage;
    if (error?.response?.data?.message) {
      errorMessage = Object.values(error?.response?.data?.message)?.flat();
    } else {
      errorMessage = [error?.message || 'Something went wrong!'];
    }
    yield put(setToastMessage({ data: errorMessage, severity: 'error' }));
    console.error(error);
  }
}

function* getByAdminSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(GetByAdminApi, payload);
    yield put(getAdminDetailsSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(getAdminDetailsSuccess(response?.data));
      yield put(TenantLoading(false));
    } else {
      yield put(getAdminDetailsError('Something went wrong'));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* activateAdminSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(activateAdminApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(activateAdminSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.success, severity: 'success' })
      );
      yield put(TenantLoading(false));
    } else {
      yield put(activateAdminError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* getSuperAdminCardSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(getSuperAdminCardsApi, payload);
    yield put(TenantLoading(false));
    yield put(getCardItemSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(getCardItemSuccess(response?.data));
    } else {
      yield put(getCardItemError('Something went wrong'));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* migrationTenantListSaga({ payload }) {
  try {
    yield put(TenantLoading(true));
    const response = yield call(migrationTenantListAPI, payload);
    yield put(TenantLoading(false));
    yield put(migrationTenantListSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(migrationTenantListSuccess(response?.data));
    } else {
      yield put(migrationTenantListError(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

// Migration DataBase
// Admission
function* migrationAdminssionDBSaga({ payload }) {
  try {
    yield put(MigrationLoading(true));
    const response = yield call(migrationAdmissionDBAPI, payload);
    yield put(MigrationLoading(false));
    yield put(migrationAdmissionSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(migrationAdmissionSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'success' })
      );
    } else {
      yield put(migrationAdmissionError(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

// Migration DataBase
// Exam
function* migrationExamDBSaga({ payload }) {
  try {
    yield put(MigrationLoading(true));
    const response = yield call(migrationExamDBAPI, payload);
    yield put(MigrationLoading(false));
    yield put(migrationExamSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(migrationExamSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'success' })
      );
    } else {
      yield put(migrationExamError(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

// Migration DataBase
// Attendance
function* migrationAttendanceDBSaga({ payload }) {
  try {
    yield put(MigrationLoading(true));
    const response = yield call(migrationAttendanceDBAPI, payload);
    yield put(MigrationLoading(false));
    yield put(migrationAttendanceSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(migrationAttendanceSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'success' })
      );
    } else {
      yield put(migrationAttendanceError(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

// Migration DataBase
// UserManagement
function* migrationUserManagementDBSaga({ payload }) {
  try {
    yield put(MigrationLoading(true));
    const response = yield call(migrationUserManagementDBAPI, payload);
    yield put(MigrationLoading(false));
    yield put(migrationUserManagementSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(migrationUserManagementSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'success' })
      );
    } else {
      yield put(migrationUserManagementError(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchDummy() {
  yield takeLatest(Tenant.GET_ALL_TENANTS_ACTION, getAllTenants);
  yield takeLatest(Tenant.GET_TENANT_DETAILS, getByTenantsSaga);
  yield takeLatest(Tenant.UPLOAD_TENANT_ASSETS, uploadTenantAssetsSaga);
  yield takeLatest(Tenant.UPLOAD_TENANT_LOGO, uploadTenantLogoSaga);
  yield takeLatest(Tenant.UPLOAD_TENANT_LOGIN, uploadTenantLoginSaga);
  yield takeLatest(Tenant.UPLOAD_USER_MANUAL, uploadUserManualSaga);
  yield takeLatest(Tenant.CREATE_TENANT_ACTION, createTenantSaga);
  yield takeLatest(Tenant.DELETE_TENANT_ACTION, deleteTenantSaga);
  yield takeLatest(Tenant.EDIT_TENANT_ACTION, editTenantSaga);
  yield takeLatest(Tenant.ACTIVATE_TENANT_ACTION, activateTenantSaga);
  yield takeLatest(Tenant.ADD_ADMIN_IMAGE, uploadAddAdminSaga);
  yield takeLatest(Tenant.GET_ADMIN_ACTION, getAllAdmins);
  yield takeLatest(Tenant.DELETE_ADMIN_ACTION, deleteAdminSaga);
  yield takeLatest(Tenant.CREATE_ADMIN_ACTION, createAdminSaga);
  yield takeLatest(Tenant.GET_ADMIN_DETAILS_ACTION, getByAdminSaga);
  yield takeLatest(Tenant.ACTIVATE_ADMIN_ACTION, activateAdminSaga);
  yield takeLatest(Tenant.GET_CARD_ITEM_ACTION, getSuperAdminCardSaga);
  yield takeLatest(
    Tenant.MIGRATION_TENANT_LIST_ACTION,
    migrationTenantListSaga
  );
  yield takeLatest(
    MIGRATIONS_DB.MIGRATION_ADMISSION_DB_ACTION,
    migrationAdminssionDBSaga
  );
  yield takeLatest(MIGRATIONS_DB.MIGRATION_EXAM_DB_ACTION, migrationExamDBSaga);
  yield takeLatest(
    MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_ACTION,
    migrationAttendanceDBSaga
  );
  yield takeLatest(
    MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_ACTION,
    migrationUserManagementDBSaga
  );
}

export default function* superAdminSaga() {
  yield all([watchDummy()]);
}
