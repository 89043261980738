import { evaluationActions } from './Evaluation.constants';
const {
  SHOW_LOADING,
  SUBJECT,
  INTERNAL_EVALUATIONS_LIST,
  SUBMIT_INTERNAL_EVAL_MARKS,
  INTERNAL_STUDENT_EVAL_LIST,
  STUDENT_EXAM_AND_RESULTS,
  INTERNAL_STUDENT_EXAM_LIST,
  INTERNAL_EVALUATION_STUDENTS_LIST,
  SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS,
  INTERNAL_EVALUATIONS_EXPORT,
} = evaluationActions;

export const setLoading = (payload) => {
  return {
    type: SHOW_LOADING.display_loading,
    payload: payload,
  };
};

export const getSubjectListAction = (payload) => ({
  type: SUBJECT.get_subject_list,
  payload: payload,
});

export const getSubjectListSuccess = (payload) => ({
  type: SUBJECT.get_subject_list_success,
  payload: payload,
});

export const getSubjectListError = (payload) => ({
  type: SUBJECT.get_subject_list_error,
  payload: payload,
});

export const resetGetSubjectListAction = () => ({
  type: SUBJECT.reset_get_subject_list,
});

export const getInteralEvaluationListAction = (payload, params) => ({
  type: INTERNAL_EVALUATIONS_LIST.get_internal_evaluation_list,
  payload: payload,
  params: params,
});

export const getInteralEvaluationListSuccess = (payload) => ({
  type: INTERNAL_EVALUATIONS_LIST.get_internal_evaluation_list_success,
  payload: payload,
});

export const getInteralEvaluationListError = (payload) => ({
  type: INTERNAL_EVALUATIONS_LIST.get_internal_evaluation_list_error,
  payload: payload,
});

export const getInteralEvaluationExport = (payload, params) => ({
  type: INTERNAL_EVALUATIONS_EXPORT.get_internal_evaluation_export,
  payload: payload,
  params: params,
});

export const getInteralEvaluationExportSuccess = (payload) => ({
  type: INTERNAL_EVALUATIONS_EXPORT.get_internal_evaluation_export_success,
  payload: payload,
});

export const getInteralEvaluationExportError = (payload) => ({
  type: INTERNAL_EVALUATIONS_EXPORT.get_internal_evaluation_export_error,
  payload: payload,
});

export const getStudentInternalEvalListAction = (payload) => ({
  type: INTERNAL_STUDENT_EVAL_LIST.get_internal_student_eval_list,
  payload: payload,
});

export const getStudentInternalEvalListSuccess = (payload) => ({
  type: INTERNAL_STUDENT_EVAL_LIST.get_internal_student_eval_list_success,
  payload: payload,
});

export const getStudentInternalEvalListError = (payload) => ({
  type: INTERNAL_STUDENT_EVAL_LIST.get_internal_student_eval_list_error,
  payload: payload,
});

export const submitInternalMarksAction = (payload, params) => ({
  type: SUBMIT_INTERNAL_EVAL_MARKS.submit_internal_eval_marks,
  payload: payload,
  params: params,
});

export const submitInternalMarksSuccess = (payload) => ({
  type: SUBMIT_INTERNAL_EVAL_MARKS.submit_internal_eval_marks_success,
  payload: payload,
});

export const submitInternalMarksError = (payload) => ({
  type: SUBMIT_INTERNAL_EVAL_MARKS.submit_internal_eval_marks_error,
  payload: payload,
});

export const submitInternalAssignmentMarksAction = (payload, params) => ({
  type: SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS.submit_internal_eval_assignment_marks,
  payload: payload,
  params: params,
});

export const submitInternalAssignmentMarksSuccess = (payload) => ({
  type: SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS.submit_internal_eval_assignment_marks_success,
  payload: payload,
});

export const submitInternalAssignmentMarksError = (payload) => ({
  type: SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS.submit_internal_eval_assignment_marks_error,
  payload: payload,
});

export const resetSubmitInternalAssignmentMarks = () => ({
  type: SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS.reset_submit_internal_eval_assignment_marks,
});

export const submitInternalAssignmentMarksLoader = (payload) => ({
  type: SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS.submit_internal_eval_assignment_marks_loader,
  payload: payload,
});

export const getQuizAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_quiz,
  payload: payload,
});

export const getQuizSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_quiz_success,
  payload: payload,
});

export const submitQuizAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.submit_student_quiz,
  payload: payload,
});

export const submitQuizSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.submit_student_quiz_success,
  payload: payload,
});

export const getAssignmentAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_assignment,
  payload: payload,
});

export const getAssignmentSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_assignment_success,
  payload: payload,
});

export const submitAssignmentAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.submit_student_assignment,
  payload: payload,
});

export const submitAssignmentSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.submit_student_assignment_success,
  payload: payload,
});

export const setExamLoading = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.loading,
  payload: payload,
});

export const getMidEndTermExamListAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_mid_end_term_exam_list,
  payload: payload,
});

export const getMidEndTermExamListSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_mid_end_term_exam_list_success,
  payload: payload,
});

export const getSubjectExamListAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_exam_subject_list,
  payload: payload,
});

export const getSubjectExamListSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_exam_subject_list_success,
  payload: payload,
});

export const getStudentInternalExamListAction = (payload, params) => ({
  type: INTERNAL_STUDENT_EXAM_LIST.get_internal_student_exam_list,
  payload: payload,
  params: params,
});

export const getStudentExamsListAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_student_exams_list_action,
  payload: payload,
});

export const getStudentExamsListSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_student_exams_list_success,
  payload: payload,
});

export const getStudentExamsListErrorAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_student_exams_list_error,
  payload: payload,
});

export const getStudentInternalExamListSuccess = (payload) => ({
  type: INTERNAL_STUDENT_EXAM_LIST.get_internal_student_exam_list_success,
  payload: payload,
});

export const getStudentInternalExamListError = (payload) => ({
  type: INTERNAL_STUDENT_EXAM_LIST.get_internal_student_exam_list_error,
  payload: payload,
});

export const getUploadedAssignmentAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_uploaded_assignment,
  payload: payload,
});

export const getUploadedAssignmentSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.get_uploaded_assignment_success,
  payload: payload,
});

export const submitUploadAssignmentAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.submit_uploaded_assignment,
  payload: payload,
});

export const submitUploadAssignmentSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.submit_uploaded_assignment_success,
  payload: payload,
});

export const recheckRequestStudentAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.recheck_request,
  payload: payload,
});

export const recheckRequestSuccessAction = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.recheck_request_success,
  payload: payload,
});
// Get student list for exam
export const getStudentListForExamRequest = (params) => ({
  type: STUDENT_EXAM_AND_RESULTS.GET_EXAM_STUDENTS_REQUEST,
  params: params,
});

export const getStudentListForExamSuccess = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.GET_EXAM_STUDENTS_SUCCESS,
  payload: payload,
});

export const getStudentListForExamError = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.GET_EXAM_STUDENTS_ERROR,
  payload: payload,
});

export const getStudentListForExamReset = () => ({
  type: STUDENT_EXAM_AND_RESULTS.GET_EXAM_STUDENTS_RESET,
});

// Updated evaluation

export const updateStudentEvaluationForExamRequest = (payload, params) => ({
  type: STUDENT_EXAM_AND_RESULTS.UPDATE_EXAM_STUDENTS_EVALUATION_REQUEST,
  payload: payload,
  params: params,
});

export const updateStudentEvaluationForExamSuccess = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.UPDATE_EXAM_STUDENTS_EVALUATION_SUCCESS,
  payload: payload,
});

export const updateStudentEvaluationForExamError = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.UPDATE_EXAM_STUDENTS_EVALUATION_ERROR,
  payload: payload,
});

export const updateStudentEvaluationForExamReset = () => ({
  type: STUDENT_EXAM_AND_RESULTS.UPDATE_EXAM_STUDENTS_EVALUATION_RESET,
});

// Get specific student evaluation details

export const getStudentEvaluationDetailsRequest = (payload, params) => ({
  type: STUDENT_EXAM_AND_RESULTS.GET_STUDENT_EVALUATION_DETAILS_REQUEST,
  payload: payload,
  params: params,
});

export const getStudentEvaluationDetailsSuccess = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.GET_STUDENT_EVALUATION_DETAILS_SUCCESS,
  payload: payload,
});

export const getStudentEvaluationDetailsError = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.GET_STUDENT_EVALUATION_DETAILS_ERROR,
  payload: payload,
});

export const getStudentEvaluationDetailsReset = () => ({
  type: STUDENT_EXAM_AND_RESULTS.GET_STUDENT_EVALUATION_DETAILS_RESET,
});

// Update assignment evaluation

export const updateAssignmentEvaluationForExamRequest = (
  payload,
  requestPath,
  params
) => ({
  type: STUDENT_EXAM_AND_RESULTS.UPDATE_EXAM_ASSIGNMENT_EVALUATION_REQUEST,
  payload: payload,
  requestPath: requestPath,
  params: params,
});

export const updateAssignmentEvaluationForExamSuccess = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.UPDATE_EXAM_ASSIGNMENT_EVALUATION_SUCCESS,
  payload: payload,
});

export const updateAssignmentEvaluationForExamError = (payload) => ({
  type: STUDENT_EXAM_AND_RESULTS.UPDATE_EXAM_ASSIGNMENT_EVALUATION_ERROR,
  payload: payload,
});

export const updateAssignmentEvaluationForExamReset = () => ({
  type: STUDENT_EXAM_AND_RESULTS.UPDATE_EXAM_ASSIGNMENT_EVALUATION_RESET,
});

export const getInternalEvaluationStudentsList = (payload) => ({
  type: INTERNAL_EVALUATION_STUDENTS_LIST.get_internal_evaluation_students__list,
  payload: payload,
});

export const setInternalEvaluationStudentsListSuccess = (payload) => ({
  type: INTERNAL_EVALUATION_STUDENTS_LIST.internal_evaluation_students__list_success,
  payload: payload,
});

export const setInternalEvaluationStudentsListError = (payload) => ({
  type: INTERNAL_EVALUATION_STUDENTS_LIST.internal_evaluation_students__list_error,
  payload: payload,
});

export const resetInternalEvaluationStudentsList = () => ({
  type: INTERNAL_EVALUATION_STUDENTS_LIST.reset_internal_evaluation_students__list,
});

export const getInternalEvaluationStudentsListLoader = (payload) => ({
  type: INTERNAL_EVALUATION_STUDENTS_LIST.get_internal_evaluation_students__list_loader,
  payload: payload,
});

export const getUserMappedSubjectsAction = (payload) => ({
  type: evaluationActions.GET_USER_MAPPED_SUBJECTS_ACTION,
  payload: payload,
});

export const getUserMappedProgramsSuccess = (payload) => ({
  type: evaluationActions.GET_USER_MAPPED_PROGRAMS_SUCCESS,
  payload: payload,
});

export const getUserMappedYearSuccess = (payload) => ({
  type: evaluationActions.GET_USER_MAPPED_YEAR_SUCCESS,
  payload: payload,
});

export const getUserMappedSemesterSuccess = (payload) => ({
  type: evaluationActions.GET_USER_MAPPED_SEMESTER_SUCCESS,
  payload: payload,
});

export const getUserMappedSubjectsSuccess = (payload) => ({
  type: evaluationActions.GET_USER_MAPPED_SUBJECTS_SUCCESS,
  payload: payload,
});

export const getUserMappedSubjectsError = (payload) => ({
  type: evaluationActions.GET_USER_MAPPED_SUBJECTS_ERROR,
  payload: payload,
});

export const resetUserMappedSubjects = () => ({
  type: evaluationActions.RESET_USER_MAPPED_SUBJECTS,
});

export const getUserMappedSubjectsLoader = (payload) => ({
  type: evaluationActions.GET_USER_MAPPED_SUBJECTS_LOADER,
  payload: payload,
});

export const getStudentMappedSubjectsAction = (payload) => ({
  type: evaluationActions.GET_STUDENT_MAPPED_SUBJECTS_ACTION,
  payload: payload,
});
