import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import parse from 'html-react-parser';
import {
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  Grid,
  Divider,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Box,
  Snackbar,
  Alert,
} from 'iiht-b2a-ui-components/build/components';
import { VideoLibraryIcon, FileCopyIcon, QuizIcon } from 'icons';
import { theme } from 'themes';
import {
  AssignmentDuration,
  CourseLevel,
  TotalCredits,
} from 'common/enum/StudentEnum';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from 'iiht-b2a-ui-components/build/components/Button';
import '../../common/collapsibleContent/CollapsibleContent.scss';
import { isHttp, localStorageHelper } from 'utils';

export const CollapsibleContent = ({
  moduleId,
  moduleDuration,
  title = 'test title',
  content = [],
  assessment = [],
  liveSession = [],
  quizes = [],
  navigateToModuleContent,
  subModuleId,
  joinSessionHandler,
  copyLinkHandler,
}) => {
  const { t } = useTranslation();
  const [id, setId] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [collapseId, setCollapseId] = useState(0);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedParent, setSelectedParent] = useState('');
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const STUDENT_COURSE_PREVIEW = 'student-course-preview';

  const studentPreviewOnHODFlow =
    JSON.parse(localStorageHelper.get('studentPreview')) || '';

  const toggleDropdown = (id) => {
    setOpen(!isOpen);
    // setCollapseId(id);
    setCollapseId((prevStateId) => (prevStateId === id ? '' : id));
  };

  const copyToClipBoard = (copyMe) => {
    setOpenAlertBox(true);
    navigator.clipboard.writeText(copyMe);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertBox(false);
  };

  return (
    <>
      <List
        key={`Module${moduleId}`}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <>
            <Grid
              className="display-flex justify-space-between mouse-pointer qa-dropdown"
              sx={{
                borderBottom: 1,
                borderBottomColor: '#68747B',
                paddingTop: '30px',
                mb: 1,
              }}
              item
              xl={12}
              md={12}
            >
              <Stack
                onClick={() =>
                  studentPreviewOnHODFlow.from !== STUDENT_COURSE_PREVIEW &&
                  navigateToModuleContent(
                    0,
                    content.filter((d) => d.fileType === 1)?.[0].fileName,
                    'video',
                    subModuleId
                  )
                }
              >
                <Typography
                  // onClick={() => navigateToModuleContent}
                  color={theme.palette.secondary.text}
                  variant="subtitle1"
                  className="font-18"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {title}
                </Typography>
                {/* To uncommmet when B2A-671 resolves 
                <Typography
                  sx={{ paddingBottom: '10px' }}
                  color="grey.P1000"
                  variant="subtitle2"
                >{`${AssignmentDuration[moduleDuration] || moduleDuration} ${t(
                  'myLearning.toComplete'
                )}`}</Typography> */}
              </Stack>

              {+collapseId !== moduleId ? (
                <ExpandMore onClick={() => toggleDropdown(moduleId)} />
              ) : (
                <ExpandLess onClick={() => toggleDropdown(moduleId)} />
              )}
            </Grid>
            {/* <Divider flexItem sx={{ ml: 2 }} /> */}
          </>
        }
      >
        {+collapseId !== moduleId &&
          content.map(
            (item, index) =>
              item.fileType === 1 && (
                <>
                  <ListItemButton
                    key={`parent${index}`}
                    onClick={() =>
                      studentPreviewOnHODFlow.from !== STUDENT_COURSE_PREVIEW &&
                      navigateToModuleContent(
                        item?.id,
                        item?.fileName?.substring(
                          item?.fileName.lastIndexOf('.'),
                          0
                        ),
                        'video', // only supporting video for now
                        subModuleId
                      )
                    }
                    // divider
                    className={
                      `parent${index}` === selectedParent
                        ? 'selectedParent'
                        : ''
                    }
                  >
                    <Stack className="qa-videoTab">
                      <ListItemText
                        primary={
                          <>
                            {item?.type === 'video' ? (
                              <span style={{ verticalAlign: 'sub' }}>
                                <VideoLibraryIcon
                                  className="qa-VideoLibraryIcon"
                                  fill={theme.palette.primary.main}
                                  height={16}
                                  width={16}
                                />
                              </span>
                            ) : (
                              <span style={{ verticalAlign: 'sub' }}>
                                <FileCopyIcon
                                  fill={theme.palette.primary.main}
                                  className="qa-FileCopyIcon"
                                  height={16}
                                  width={16}
                                />
                              </span>
                            )}

                            <Typography
                              sx={{
                                textDecoration:
                                  studentPreviewOnHODFlow.from ===
                                  STUDENT_COURSE_PREVIEW
                                    ? 'none'
                                    : 'underline',
                                paddingLeft: '16px',
                              }}
                              variant="h7"
                              color={theme.palette.secondary.text}
                              className="qa-fileName"
                            >
                              {item?.fileName}
                            </Typography>
                          </>
                        }
                      />
                      {/* <Typography
                      color="grey.P1000"
                      variant="subtitle2"
                      sx={{ paddingLeft: '35px' }}
                    >
                      {(item?.type === 'video'
                        ? 'Video-'
                        : item?.type === 'text'
                        ? 'Reading-'
                        : '') + item?.duration}
                    </Typography> */}
                    </Stack>
                  </ListItemButton>
                </>
              )
          )}

        {(assessment.length > 0 || quizes?.length > 0) &&
          +collapseId !== moduleId && (
            <Typography
              variant="h7"
              color={theme.palette.secondary.text}
              sx={{ paddingLeft: '46px', fontWeight: 'bold' }}
            >
              {t('myLearning.assessmentAndQuizes')}
            </Typography>
          )}

        {assessment.length > 0 && +collapseId !== moduleId && (
          <>
            <ListItemButton
              key={`assement_parent}`}
              onClick={() =>
                studentPreviewOnHODFlow.from !== STUDENT_COURSE_PREVIEW &&
                navigateToModuleContent(
                  assessment[0]?.id,
                  assessment[0]?.title,
                  'assessment',
                  subModuleId
                )
              }
              // divider
              className={
                `parent${0}` === selectedParent ? 'selectedParent' : ''
              }
            >
              <Stack>
                <ListItemText
                  primary={
                    <>
                      <span
                        style={{ verticalAlign: 'sub' }}
                        className="qa-quizIcon"
                      >
                        <QuizIcon
                          fill={theme.palette.primary.main}
                          height={16}
                          width={16}
                        />
                      </span>
                      <Typography
                        sx={{
                          textDecoration:
                            studentPreviewOnHODFlow.from ===
                            STUDENT_COURSE_PREVIEW
                              ? 'none'
                              : 'underline',
                          paddingLeft: '16px',
                        }}
                        className="qa-assessments"
                        variant="h7"
                        color={theme.palette.secondary.text}
                      >
                        {t('myLearning.assessments')}
                      </Typography>
                    </>
                  }
                />

                {/* <Typography
                  color="grey.P1000"
                  variant="subtitle2"
                  sx={{ paddingLeft: '35px' }}
                >
                  {`${t('myLearning.attended')} : ${
                    item?.attended || '--'
                  }  | ${t('myLearning.grade')} : ${item?.grade || '--'}`}
                </Typography> */}
              </Stack>
            </ListItemButton>
          </>
        )}

        {quizes.length > 0 && +collapseId !== moduleId && (
          <>
            <ListItemButton
              key={`assement_parent`}
              onClick={() =>
                studentPreviewOnHODFlow.from !== STUDENT_COURSE_PREVIEW &&
                navigateToModuleContent(
                  quizes[0]?.id,
                  quizes[0]?.title,
                  'quiz',
                  subModuleId
                )
              }
              // divider
              className={
                `parent${0}` === selectedParent ? 'selectedParent' : ''
              }
            >
              <Stack>
                <ListItemText
                  primary={
                    <>
                      <span style={{ verticalAlign: 'sub' }}>
                        <QuizIcon
                          fill={theme.palette.primary.main}
                          height={16}
                          width={16}
                        />
                      </span>
                      <Typography
                        sx={{
                          textDecoration:
                            studentPreviewOnHODFlow.from ===
                            STUDENT_COURSE_PREVIEW
                              ? 'none'
                              : 'underline',
                          paddingLeft: '16px',
                        }}
                        variant="h7"
                        color={theme.palette.secondary.text}
                      >
                        {t('myLearning.quiz')}
                      </Typography>
                    </>
                  }
                />

                {/* <Typography
                  color="grey.P1000"
                  variant="subtitle2"
                  sx={{ paddingLeft: '35px' }}
                >
                  {`${t('myLearning.attended')} : ${
                    item?.attended || '--'
                  }  | ${t('myLearning.grade')} : ${item?.grade}`}
                </Typography> */}
              </Stack>
            </ListItemButton>
          </>
        )}
        {liveSession?.length > 0 && +collapseId !== moduleId && (
          <Divider orientation="horizontal" />
        )}
        {liveSession?.length > 0 &&
          +collapseId !== moduleId &&
          liveSession.map((session, i) => {
            let date = moment
              .utc(session?.sessionDateTime)
              .format('dddd, MMMM DD YYYY, hh:mm A');
            let endTimeStr = moment.utc(session?.endTime).format('H:MM');
            let localTime = moment.utc(date).toDate();
            localTime =
              moment(localTime).format('DD MMMM YYYY | H:MM') +
              ' - ' +
              endTimeStr;
            return (
              <>
                <Stack>
                  <Accordion className="p3 accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="secondary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={`qa-${session?.title}`}
                    >
                      <Typography
                        variant="fieldValue"
                        className="accordionsummary"
                      >
                        {session?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: '80%' }}>
                      <Typography
                        variant="fieldValue"
                        className="accordiondetails"
                      >
                        {session?.description}
                      </Typography>
                    </AccordionDetails>
                    <Grid sx={{ display: 'flex' }} container spacing={2}>
                      <Grid item className="sub-grid" lg={4} md={4}>
                        <span className="font-weight-bold span-head">
                          {t('faculty')}
                        </span>
                        <span>{session?.facultyName}</span>
                      </Grid>
                      <Grid item className="sub-grid" lg={4} md={4}>
                        <span className="font-weight-bold span-head">
                          {t('calendar.dateTime')}
                        </span>
                        <span>{localTime}</span>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{ display: 'flex', marginTop: '5px' }}
                      container
                      spacing={2}
                    >
                      <Grid item lg={4} md={8} className="sub-grid">
                        <span className="font-weight-bold span-head">
                          {t('myLearning.liveSessionLink')}
                        </span>
                        <Box
                          style={{
                            display: 'flex',
                            alignItem: 'center',
                          }}
                        >
                          <Tooltip title={session?.sessionLink}>
                            <span className="link-length">
                              <Link
                                className="link-length"
                                target="_blank"
                                color="secondary"
                                href={isHttp(session?.sessionLink)}
                              >
                                {session?.sessionLink}
                              </Link>
                            </span>
                          </Tooltip>
                          <Button
                            className="mr3 p0 qa-copy"
                            onClick={() =>
                              copyToClipBoard(`${session?.sessionLink}`)
                            }
                          >
                            <FileCopyIcon
                              height="24"
                              width="24"
                              fill={theme.palette.secondary.main}
                            />
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{
                              textTransform: 'Uppercase',
                            }}
                            href={isHttp(session?.sessionLink)}
                            target="_blank"
                            className="qa-join"
                            onClick={() => joinSessionHandler(session)}
                          >
                            {t('myLearning.join')}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} className="left-align sub-grid">
                      <span className="span-head">{t('password')}</span>
                      <span>{session?.password}</span>
                    </Grid>
                  </Accordion>
                </Stack>
              </>
            );
          })}
      </List>
      <Snackbar
        open={openAlertBox}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%', marginTop: '2rem' }}
          >
            {t('myLearning.linkcopied')}
          </Alert>
        </div>
      </Snackbar>
    </>
  );
};

export default CollapsibleContent;
