import axiosInstance from 'translator';
import { CounsellorAPI } from './Counsellor.apiEndpoint';

export const getCounsellorStatsApi = async (payload) => {
  return axiosInstance
    .get(
      `${CounsellorAPI?.getCounsellorStats}?assignee=${payload?.assignee}&stage=counselling`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCounsellorLeadsApi = async (payload) => {
  const url = payload?.type
    ? `${CounsellorAPI.getCounsellorLeads}`
    : `${CounsellorAPI.getCounsellorLeads}`;
  return axiosInstance
    .get(url, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getInterestedProspectusPriceApi = async (payload) => {
  return axiosInstance
    .get(`${CounsellorAPI.getInterestedProspectusPrice}${payload?.prospectus}`)
    .then((response) => response)
    .catch((error) => error);
};

export const getSelectedProspectApi = async (payload) => {
  return axiosInstance
    .get(`${CounsellorAPI.getSelectedProspect}?assignee=${payload?.assignee}`)
    .then((response) => response)
    .catch((error) => error);
};

export const addSelectedProspectApi = async (payload) => {
  return axiosInstance
    .post(`${CounsellorAPI.addSelectedProspectApi}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getSelectedProspectList = async (payload) => {
  return axiosInstance
    .get(`${CounsellorAPI.addSelectedProspectApi}?lead_id=${payload?.lead_id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteSelectedProspectApi = async (payload) => {
  return axiosInstance
    .delete(
      `${CounsellorAPI.getInterestedProspectusPrice}${payload?.courseId}?lead_id=${payload.lead_id}`
    )
    .then((response) => response)
    .catch((error) => error);
};

export const sendProspectPaymentLinkApi = async (payload) => {
  return axiosInstance
    .post(`${CounsellorAPI.sendPaymentLink}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const updatePaymentLinkApi = async (payload) => {
  const { queryParams, body } = payload;
  return axiosInstance
    .put(
      `${CounsellorAPI.sendPaymentLink}?interested_course_id=${queryParams?.interested_course_id}&lead=${queryParams?.lead}`,
      body
    )
    .then((response) => response)
    .catch((error) => error);
};

export const startCandidateCounsellingApi = async (payload) => {
  return axiosInstance
    .post(`${CounsellorAPI.startCounselling}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const sendToVerifierApi = async (payload) => {
  return axiosInstance
    .post(`${CounsellorAPI.startCounselling}?action=sendverifier`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getLeadCurrentStageApi = async (payload) => {
  return axiosInstance
    .get(`${CounsellorAPI.leadStage}?lead=${payload?.lead}`)
    .then((response) => response)
    .catch((error) => error);
};

export const sendProspectFormOnlineApi = async (payload) => {
  return axiosInstance
    .post(`${CounsellorAPI.sendFormLink}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getAppFormDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${CounsellorAPI.sendFormLink}`)
    .then((response) => response)
    .catch((error) => error);
};
