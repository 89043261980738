export const SuperAdminAPI = {
  Tenants: 'v1/tenant_management/tenant',
  UploadFiles: 'v1/tenant_management/upload-file',
  Tenant_Admin: 'v1/tenant_management/tenant-admin',
  SuperAdminDashboard: '/v1/tenant_management/dashboard',
  MigrationTenantList: 'v1/tenant/migration-tenant-list',
};

export const SuperAdminMigrationDB = {
  AdmissionDB: `${process.env.REACT_APP_API_ENDPOINT}/v1/admission/migrate_database`,
  ExamDB: `${process.env.REACT_APP_API_ENDPOINT}/v1/access/migrate_database`,
  Attendance_ProgramDB: `${process.env.REACT_APP_API_ENDPOINT}/v2/attendance/migrate_database`,
  UserManagementDB: `${process.env.REACT_APP_API_ENDPOINT}/v1/user_management/migrate_database`,
};
