import { calendarActions } from 'store/Calendar';

const INITIAL_STATE = {
  user: {
    loading: false,
    data: {},
  },
  calendar: {
    totalCount: 0,
    calendarEvents: [],
    combinedList: [],
    emailList: [],
    loading: false,
    createEventsSuccess: false,
  },
};

const calendarReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case calendarActions.CALENDAR.get_success: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          // totalCount: action?.payload?.totalCount,
          createEventsSuccess: false,
          calendarEvents: {
            ...state.calendarEvents,
            calendarEvents: action?.payload,
          },
        },
      };
    }
    case calendarActions.CALENDAR.get_combined_list_success: {
      console.log(action.payload);
      return {
        ...state,
        calendar: {
          ...state.calendar,
          combinedList: action?.payload || [],
        },
      };
    }
    case calendarActions.CALENDAR.get_email_list_success: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          emailList: action?.payload || [],
        },
      };
    }
    case calendarActions.CALENDAR.update_event_success: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          createEventsSuccess: false,
        },
      };
    }
    case calendarActions.CALENDAR.create_success: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          createEventsSuccess: true,
        },
      };
    }
    case calendarActions.CALENDAR.get_user_details_success: {
      return {
        ...state,
        user: {
          ...state.user,
          data: action?.payload,
        },
      };
    }
    case calendarActions.CALENDAR.delete:
    case calendarActions.CALENDAR.loading: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: action?.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default calendarReducer;
