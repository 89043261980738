import React from 'react';
import { Grid, Box, Typography } from 'iiht-b2a-ui-components/build/components';
import UserDetailsTable from '../userDetailsTable/UserDetailsTable';
import { useTranslation } from 'react-i18next';
// import { ItemsCard } from 'components';
import './AttendanceDetails.scss';

const AttendanceDetails = ({ userType, tableColumns, tableRows, getRowId }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12}>
          <Typography variant="body1">
            {userType === 'student'
              ? t('management.studentsAttendance')
              : t('management.staffAttendance')}
          </Typography>
          <Box sx={{ height: 500, width: '100%' }}>
            <UserDetailsTable
              tableRows={tableRows}
              tableColumns={tableColumns}
              getRowId={getRowId}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AttendanceDetails;
