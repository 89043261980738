import { statusCode } from 'constants/apis/apiStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  dashboardActions,
  facultyDashboardApi,
  getDashboardCardDetailsApi,
  getDashboardCardDetailsSuccess,
  getDashboardError,
  getDashboardRegistrarChartDataReset,
  getDashboardRegistrarChartDataSuccess,
  getDashboardRegistrarChartListApi,
  getDashboardTableSuccess,
  getEnrolmentProgramListApi,
  getEnrolmentProgramListSuccess,
  getFacultyDashboardError,
  getFacultyDashboardSuccess,
  getFacultyDetailsDashboardError,
  getFacultyDetailsDashboardSuccess,
  getFacultyDetailsError,
  getFacultyDetailsSuccess,
  getFacultyUserDetailsApi,
  getHRDashboardCardDetailsApi,
  getHrDashboardCardDetailsSuccess,
  getMoreEnrolmentProgramListSuccess,
  getStudentAttendanceGraphApi,
  getStudentAttendanceGraphSuccess,
  getStudentDashboardApi,
  getStudentDashboardError,
  getStudentDashboardSuccess,
  getStudentDashboardTable,
  studentDashboardGraphApi,
  studentDashboardGraphError,
  studentDashboardGraphSuccess,
} from 'store/Dashboard';
import { setEnableDisableLoaderAction } from 'store/Loader';
import { setToastMessage } from 'store/Toast';

function* getDashboardCardDetailsSaga({ params }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getDashboardCardDetailsApi, params);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDashboardCardDetailsSuccess(response?.data));
    } else {
      // setToastMessage({ data: 'Something went wrong', severity: 'error' })
    }
  } catch (error) {
    // setToastMessage({ data: 'Something went wrong', severity: 'error' })
  }
}

function* getEnrolmentProgramListSaga({ param }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getEnrolmentProgramListApi, param);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getEnrolmentProgramListSuccess(response?.data));
    } else {
      // setToastMessage({ data: 'Something went wrong', severity: 'error' })
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

function* getMoreEnrolmentProgramListSaga({ param }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getEnrolmentProgramListApi, param);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getMoreEnrolmentProgramListSuccess(response?.data));
    } else {
      // setToastMessage({ data: 'Something went wrong', severity: 'error' })
    }
  } catch (error) {
    // setToastMessage({ data: 'Something went wrong', severity: 'error' })
  }
}

function* getRegistrarDashboardChartDataSaga({ param }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getDashboardRegistrarChartListApi, param);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDashboardRegistrarChartDataSuccess(response?.data));
    } else {
      yield put(getDashboardRegistrarChartDataReset(response?.data));

      // setToastMessage({ data: 'Something went wrong', severity: 'error' })
    }
  } catch (error) {
    // setToastMessage({ data: 'Something went wrong', severity: 'error' })
  }
}

//hr
function* getHrDashboardCardDetailsSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getHRDashboardCardDetailsApi, payload);
    console.log('rsponse', response);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getHrDashboardCardDetailsSuccess(response?.data));
    } else {
      // setToastMessage({ data: 'Something went wrong', severity: 'error' })
    }
  } catch (error) {
    // setToastMessage({ data: 'Something went wrong', severity: 'error' })
  }
}

//Faculty
function* getStudentAttendanceGraphSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getStudentAttendanceGraphApi, payload);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      console.log('response?.data', response?.data);
      yield put(getStudentAttendanceGraphSuccess(response?.data));
    } else {
      // setToastMessage({ data: 'Something went wrong', severity: 'error' })
    }
  } catch (error) {
    // setToastMessage({ data: 'Something went wrong', severity: 'error' })
  }
}

// Student Graph
function* studentDashboardGraphSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(studentDashboardGraphApi, payload);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(studentDashboardGraphSuccess(response?.data));
    } else {
      yield put(studentDashboardGraphError(response?.data));
      yield put(
        setToastMessage({ data: 'Something went wrong', severity: 'error' })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: 'Something went wrong', severity: 'error' })
    );
  }
}

//get student dashboard
function* getStudentDashboardSaga({ payload }) {
  try {
    const response = yield call(getStudentDashboardApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentDashboardSuccess(response?.data));
    } else {
      yield put(getStudentDashboardError(response?.data));
    }
  } catch (error) {
    yield put(getStudentDashboardError(error));
  }
}

function* getStudentDashboardTableSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getStudentDashboardTable, payload);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDashboardTableSuccess(response?.data));
    } else {
      yield put(getDashboardError(response?.data));
    }
  } catch (error) {
    yield put(getDashboardError(error));
  }
}

function* facultyDashBoardSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(facultyDashboardApi, payload);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyDashboardSuccess(response?.data));
    } else {
      yield put(getFacultyDashboardError(response?.data));
    }
  } catch (error) {
    // setToastMessage({ data: 'Something went wrong', severity: 'error' })
  }
}
function* facultyDetailsHODDashBoardSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(facultyDashboardApi, payload);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyDetailsDashboardSuccess(response?.data));
    } else {
      yield put(getFacultyDetailsDashboardError(response?.data));
    }
  } catch (error) {
    // setToastMessage({ data: 'Something went wrong', severity: 'error' })
  }
}
function* getFacultyDetailsSaga({ payload }) {
  try {
    const response = yield call(getFacultyUserDetailsApi, payload);

    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyDetailsSuccess(response?.data));
    } else {
      yield put(getFacultyDetailsError(response?.data));
    }
  } catch (error) {
    // setToastMessage({ data: 'Something went wrong', severity: 'error' })
  }
}

export function* watchDummy() {
  // Registrar

  yield takeLatest(
    dashboardActions.GET_DASHBOARD_CARD_DETAILS_REQUEST,
    getDashboardCardDetailsSaga
  );

  yield takeLatest(
    dashboardActions.GET_ENROLMENT_PROGRAM_LIST_REQUEST,
    getEnrolmentProgramListSaga
  );
  yield takeLatest(
    dashboardActions.GET_MORE_ENROLMENT_PROGRAM_LIST_REQUEST,
    getMoreEnrolmentProgramListSaga
  );
  yield takeLatest(
    dashboardActions.GET_REGISTRAR_DASHBOARD_CHART_DATA_REQUEST,
    getRegistrarDashboardChartDataSaga
  );
  //Hr
  yield takeLatest(
    dashboardActions.GET_HR_DASHBOARD_CARD_DETAILS_REQUEST,
    getHrDashboardCardDetailsSaga
  );

  //faculty
  yield takeLatest(
    dashboardActions.GET_STUDENT_ATTENDANCE_GRAPH_REQUEST,
    getStudentAttendanceGraphSaga
  );
  //Student
  yield takeLatest(
    dashboardActions.STUDENT_DASHBOARD_GRAPH_REQUEST,
    studentDashboardGraphSaga
  );
  //student Dashboard
  yield takeLatest(
    dashboardActions.GET_STUDENT_DASHBOARD_REQUEST,
    getStudentDashboardSaga
  );
  yield takeLatest(
    dashboardActions.GET_STUDENT_DASHBOARD_TABLE,
    getStudentDashboardTableSaga
  );

  // faculty Dashboard
  yield takeLatest(
    dashboardActions.GET_FACULTY_DASHBOARD_REQUEST,
    facultyDashBoardSaga
  );
  //HOD DashBoard
  yield takeLatest(
    dashboardActions.GET_FACULTY_DETAILS_DASHBOARD_REQUEST,
    facultyDetailsHODDashBoardSaga
  );
  yield takeLatest(
    dashboardActions.GET_FACULTY_USER_DETAILS_REQUEST,
    getFacultyDetailsSaga
  );
}

export default function* dashboardSaga() {
  yield all([watchDummy()]);
}
