import axiosInstance from 'translator';
import { MoocAdminAPI } from './MoocAdmin.apiEndpoints';
import axios from 'axios';
import { customMoocApiHeader } from 'utils';

export const getMoocAdminStatsApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getStats}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getCoursesToAffiliateApi = async (payload) => {
  return axios
    .post(
      `${MoocAdminAPI.getCoursesToAffiliate}${payload?.queryParams ?? ''}`,
      payload?.body,
      customMoocApiHeader()
    )
    .then((response) => response)
    .catch((error) => error);
};

export const getAffiliatedCoursesApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getAffiliatedOrPublishCourses}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getCoursesToPublishApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getAffiliatedOrPublishCourses}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getPublishedCoursesApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getAffiliatedOrPublishCourses}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getCourseDetailsApi = async (payload) => {
  return axios
    .get(
      `${MoocAdminAPI.getCourseDetails}${payload?.type}/${payload?.id}`,
      customMoocApiHeader()
    )
    .then((response) => response)
    .catch((error) => error);
};

export const affiliateOrPublishMoocCourseApi = async (payload) => {
  return axiosInstance
    .post(`${MoocAdminAPI.affiliateOrPublishMoocCourse}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getMoocAdminFilterApi = async (payload) => {
  return axios
    .get(`${MoocAdminAPI.getFilters}`, payload, customMoocApiHeader())
    .then((response) => response)
    .catch((error) => error);
};

export const getMoocCourseStatusApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.moocCourseStatus}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getMoocTypeAPi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getMoocType}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const enrollMoocCourseAPi = async (payload) => {
  return axiosInstance
    .post(`${MoocAdminAPI.enrollMoocCourse}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

//Get All Courses
export const getAllCoursesApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getCourses}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

//Update Course Details
export const updateCourseDetailsApi = async (payload) => {
  return axios
    .put(`${MoocAdminAPI.updateCourse}${payload?.type}/${payload?.id}`)
    .then((response) => response)
    .catch((error) => error);
};

//Delete Course Details
export const deleteCourseDetailsApi = async (payload) => {
  return axios
    .delete(`${MoocAdminAPI.deleteCourse}${payload?.type}/${payload?.id}`)
    .then((response) => response)
    .catch((error) => error);
};
//Update Mooc Setting
export const updateMookSettingApi = async (payload) => {
  return axiosInstance
    .put(`${MoocAdminAPI.moocSettings}/${payload?.id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

//Get Mooc setting
export const getMoocSettingApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.moocSettings}/${payload?.tenant_id}`)
    .then((response) => response)
    .catch((error) => error);
};

//create Certificate
export const createMoocCourseCertificateApi = async (payload) => {
  return axiosInstance
    .post(`${MoocAdminAPI.moocSettings}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

//upload file for mooc setting
export const uploadFileApi = async (payload) => {
  return axiosInstance
    .post(`${MoocAdminAPI.postFileUpload}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

//Get Enrolled Courses
export const getEnrolledCourseApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getEnrolledCourse}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const updateCourseStatusApi = async (payload) => {
  return axiosInstance
    .patch(
      `${MoocAdminAPI.getEnrolledCourse}/${payload?.courseId}`,
      payload.body
    )
    .then((response) => response)
    .catch((error) => error);
};

export const moocAdminDashboardApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.moocAdminDashboard}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getStudentListByCourseIdApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.studentList}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

// Assessment
export const getAssessmentListRequestApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.assessmentLink}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

// Video Notes
export const getVideoNotesRequestApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.videoNotes}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

// Create Video Notes
export const createVideoNotesApi = async (payload) => {
  return axiosInstance
    .post(`${MoocAdminAPI.videoNotes}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const downloadCertificateApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.downloadCertificate}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const learningPathToPublishApi = async (payload) => {
  return axios
    .get(`${MoocAdminAPI.learningPathToPublish}`, payload)
    .then((response) => response)
    .catch((error) => error);
};
export const publishLearningPathApi = async (payload) => {
  return axios
    .get(`${MoocAdminAPI.publishLearningPath}`, payload)
    .then((response) => response)
    .catch((error) => error);
};
// Get Learning path
export const getLearningPathApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getLearningPath}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getCourseListDropDownApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.courseListDropdown}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const createLPApi = async (payload) => {
  return axiosInstance
    .post(`${MoocAdminAPI.createLP}`, payload)
    .then((response) => response)
    .catch((error) => error);
};
//Update Learning Path
export const updateLearningPathStatusApi = async (payload) => {
  return axiosInstance
    .patch(
      `${MoocAdminAPI.courseListDropdown}/${payload?.courseId}`,
      payload.body
    )
    .then((response) => response)
    .catch((error) => error);
};

export const myLearningPathApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.myLearningPath}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const enrollLearningPathAPI = async (payload) => {
  return axiosInstance
    .post(`${MoocAdminAPI.enrollLearningPath}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const myLearningPathByIdApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.myLearningPath}/${payload?.LpId}`, {
      params: payload?.params,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getLpCourseStatusApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.lpCourseStatus}`, {
      params: payload,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getLearningPathByIdApi = async (payload) => {
  return axiosInstance
    .get(`${MoocAdminAPI.getLearningPath}/${payload?.id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const editLPApi = async (payload) => {
  return axiosInstance
    .patch(`${MoocAdminAPI.createLP}/${payload?.id}`, payload.body)
    .then((response) => response)
    .catch((error) => error);
};