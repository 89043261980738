import { Rating as RatingStar } from 'iiht-b2a-ui-components/build/components';
import withStyles from '@material-ui/core/styles/withStyles';
import { theme } from 'themes';

export const Rating = withStyles({
  iconEmpty: {
    color: theme.palette.primary.main,
  },
  iconFilled: {
    color: theme.palette.primary.main,
  },
})(RatingStar);
