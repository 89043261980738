import React from 'react';

const CalenderViewDay = ({ fill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="28"
      viewBox="0 -960 960 960"
      width="24"
      fill={fill}
    >
      <path d="M200-280q-33 0-56.5-23.5T120-360v-240q0-33 23.5-56.5T200-680h560q33 0 56.5 23.5T840-600v240q0 33-23.5 56.5T760-280H200Zm0-80h560v-240H200v240Zm-80-400v-80h720v80H120Zm0 640v-80h720v80H120Zm80-480v240-240Z" />
    </svg>
  );
};

export default CalenderViewDay;
