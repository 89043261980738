import { Ellipses } from 'icons';
import {
  IconButton,
  Menu,
  MenuItem,
} from 'iiht-b2a-ui-components/build/components';
import React, { useState } from 'react';
import './Style.scss';

const IconButtonPopoverMenu = (props) => {
  const { menuOptions = [] } = props || '';
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuCloseClick = () => {
    setAnchorEl(null);
  };

  const handleMenuOptionClick = (menuOptionClick = () => {}) => {
    handleMenuCloseClick();
    menuOptionClick();
  };

  return (
    <>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleMenuOpenClick}
        >
          <Ellipses />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuCloseClick}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {menuOptions.map((option) => (
            <MenuItem
              key={option}
              onClick={() => handleMenuOptionClick(option?.handleMenuClick)}
              sx={{ p: 1, '&:hover': { color: '#EB2929' } }}
              className="menuOption"
            >
              {option?.menuIcon}
              {option?.menuName}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default IconButtonPopoverMenu;
