import React from 'react';

const AddIcon = ({ height = 20, width = 20, fill = '#02387A' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill={fill}
    >
      <path d="M9.25 15v-4.25H5v-1.5h4.25V5h1.5v4.25H15v1.5h-4.25V15Z" />
    </svg>
  );
};

export default AddIcon;
