import * as React from 'react';

function RefreshIcon(props) {
  return (
    <span className="iiht-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.924"
        height="32.413"
        viewBox="0 0 26.924 32.413"
        {...props}
      >
        <g
          id="Group_10745"
          data-name="Group 10745"
          transform="translate(28.032 12.598) rotate(120)"
        >
          <path
            id="Path_543"
            data-name="Path 543"
            d="M4326.471,2226.23a7.947,7.947,0,0,1,3.1-6.132v3.812h2.206v-6.494h-8.562v1.884h4.211a9.564,9.564,0,0,0-3.16,6.93c0,5.115,4.464,9.507,10.384,10.218l.306-1.865C4330.122,2234,4326.471,2230.412,4326.471,2226.23Z"
            transform="translate(-4324.949 -2214.821)"
            fill="#3c4252"
            stroke="#3d4251"
            strokeWidth="0.4"
          />
          <path
            id="Path_544"
            data-name="Path 544"
            d="M3.256,8.815a7.944,7.944,0,0,1,3.1-6.132V6.494h2.2V0H0V1.884H4.211a9.566,9.566,0,0,0-3.16,6.93c0,5.115,4.465,9.507,10.384,10.218l.307-1.865C6.9,16.586,3.256,13,3.256,8.815Z"
            transform="translate(22.553 19.032) rotate(180)"
            fill="#3c4252"
            stroke="#3d4251"
            strokeWidth="0.4"
          />
        </g>
      </svg>
    </span>
  );
}

export default RefreshIcon;
