export const dashboardAPI = {
  cluster: '/v2/cluster',

  //Registrar
  getRegistrarDashboardCardDetails: '/v1/user_management/dashboard',
  getEnrolmentProgramList: '/v2/program',
  getRegistrarDashboardChartData: '/v1/access/graph',
  getHRDashboardCardDetails: '/v1/user_management/dashboard',

  //Faculty
  getStudentAttendanceGraph: 'v2/attendance/attendance-dashboard',
  studentDashboardGraph: '/v2/attendance/attendance-dashboard',
  getFacultyDashBoard: '/v2/dashboard',
  StudentDashboardApi: '/v2/dashboard',
  getFacultyDetails: '/v1/user_management/login',
};
