import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Typography,
} from 'iiht-b2a-ui-components/build/components';
import { red } from '@mui/material/colors';
import user from '../../../assets/images/user.jpg';

import './ProfileCard.scss';
const ProfileCard = ({
  profileUrl,
  designation,
  name,
  middleName,
  surname,
  education,
}) => {
  return (
    <Card
      sx={{
        maxWidth: 170,
        height: 230,
        borderRadius: '6px',
        border: '1px solid #CBD2DC',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
      }}
      variant="outlined"
    >
      <CardContent>
        <Avatar
          src={profileUrl}
          sx={{
            width: 140,
            height: 140,
          }}
          aria-label="user"
        ></Avatar>
        <Typography
          variant="subtitle2"
          className="maintext text-ellipses"
          sx={{ mt: 1 }}
          color="secondary"
        >
          {`${name} ${surname}`}
        </Typography>
        <Typography className="subtext1" variant="subtitle2" color="grey.P1000">
          {designation}
        </Typography>
        <Typography className="subtext2" variant="subtitle2" color="grey.P1000">
          {education}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
