import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import axios from 'axios';
import Login from 'components/Keycloak/Login';
import ToastMessage from 'components/common/toastMessage';
import CssBaseline from 'iiht-b2a-ui-components/build/components/CssBaseline';
import {
  StyledEngineProvider,
  ThemeProvider,
} from 'iiht-b2a-ui-components/build/components/Styles';
import Keycloak from 'keycloak-js';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './AppRoute';
import { theme } from './themes';
import { checkIfObjectNotEmpty } from 'utils';
import './App.scss';

const App = () => {
  const keycloakRealm = JSON.parse(localStorage.getItem('kcRealm') || 'null');
  const [token, setToken] = useState(null);
  const [errormsg, setErrmsg] = useState('');
  const [tenantName, setTenantName] = useState(null);
  const [state, setState] = useState({
    keycloak: keycloakRealm ? new Keycloak(keycloakRealm) : null,
    keycloakSettings: [],
  });

  const tenantNameFromUrl = () => {
    const path = window.location.pathname;
    const parts = path.split('/');
    const tenantName = parts[1];

    setTenantName(tenantName);
  };

  useEffect(() => {
    tenantNameFromUrl();
  }, [tenantName]);

  const methods = useForm({
    defaultValues: {
      email: '',
    },
  });
  const { handleSubmit } = methods;

  const eventLogger = (event, error) => {
    console.log('!!!!!!!onKeycloakEvent', event, error);
  };

  const tokenLogger = (k) => {
    k.token ? localStorage.setItem('access_token', k.token) : '';
    setToken(k.token);
  };
  const initOptions = {
    checkLoginIframe: true,
    // onLoad: 'login-required',
    usernameEditDisabled: false,
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { status, data: body } = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/tenant_management/realm-discovery`,
        {
          domain: window.location.host,
        }
      );
      if (status >= 400 && status <= 599) {
        return;
      }
      if (body.error && body.message) {
        setErrmsg(body.message);
      }
      if (checkIfObjectNotEmpty(body)) {
        const client = {
          ...body,
          url: body.domainUrl,
        };
        localStorage.setItem('kcRealm', JSON.stringify(client));
        localStorage.setItem('tenantId', client.tenantId);
        const keycloakClient = new Keycloak(client);
        setState((prev) => ({ ...prev, keycloak: keycloakClient }));
        return;
      }
      setState((prev) => ({ ...prev, keycloakSettings: body }));
    } catch (error) {
      console.log(error);
    }
  });

  const handleKeyCloakSubmit = () => {};

  if (!state.keycloak) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <FormProvider {...methods}>
            <Login
              onSubmit={onSubmit}
              keycloakSettings={state.keycloakSettings}
              handleKeyCloakSubmit={handleKeyCloakSubmit}
              tenantName={tenantName}
              errormsg={errormsg}
              setErrmsg={errormsg}
            />
          </FormProvider>
        </ThemeProvider>
      </>
    );
  }

  return (
    <ReactKeycloakProvider
      initOptions={initOptions}
      authClient={state.keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <StyledEngineProvider injectFirst>
        <ToastMessage />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <AppRoute />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
