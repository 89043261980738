// React file viewer supports below type
export const REACT_FILE_VIEWER_SUPPORT = [
  'png',
  'jpeg',
  'jpg',
  'gif',
  'bmp',
  'pdf',
  'csv',
  'xlsx',
  'docx',
  'mp4',
  'webm',
  'mp3',
];

// Custom file viewer implementation supports below type
export const CUSTOM_FILE_SUPPORT = ['txt', 'srt', 'rtf'];
