import { examActions } from './Exams.constants';
const {
  EXAMS,
  PROGRAM,
  PROGRAM_YEAR,
  SEMESTER,
  EXAM,
  SUBJECT,
  MULTIPLE_QUESTION_ANSWER,
} = examActions;

export const createExamAction = (payload) => ({
  type: EXAMS.create,
  payload: payload,
});

export const editExamAction = (payload) => ({
  type: EXAMS.edit,
  payload: payload,
});

export const getExamsAction = (payload) => ({
  type: EXAMS.get,
  payload: payload,
});

export const getExamsSuccessAction = (payload) => ({
  type: EXAMS.get_success,
  payload: payload,
});

export const getExamsIncrAction = (payload) => ({
  type: EXAMS.get_incr,
  payload: payload,
});

export const getExamsIncrSuccessAction = (payload) => ({
  type: EXAMS.get_success_incr,
  payload: payload,
});

export const getExamsResetIncrAction = () => ({
  type: EXAMS.reset_incr,
});

export const getExamsErrorAction = (payload) => ({
  type: EXAMS.get_error,
  payload: payload,
});

export const getExamsByPaginationAction = (payload) => ({
  type: EXAMS.get_by_pagination,
  payload: payload,
});

export const getExamsByPaginationSuccessAction = (payload) => ({
  type: EXAMS.get_by_pagination_success,
  payload: payload,
});

export const getExamsByPaginationErrorAction = (payload) => ({
  type: EXAMS.get_by_pagination_error,
  payload: payload,
});

export const createExamsSuccessAction = (payload) => ({
  type: EXAMS.create_success,
  payload: payload,
});

export const createExamsErrorAction = (payload) => ({
  type: EXAMS.create_error,
  payload: payload,
});

export const setExamsLoadingAction = (payload) => ({
  type: EXAMS.loading,
  payload: payload,
});

export const getExamByIdAction = (payload) => ({
  type: EXAMS.get_by_id,
  payload: payload,
});

export const getExamByIdSuccessAction = (payload) => ({
  type: EXAMS.get_by_id_success,
  payload: payload,
});

export const getExamByIdErrorAction = (payload) => ({
  type: EXAMS.get_by_id_error,
  payload: payload,
});
export const deleteExamAction = (payload) => ({
  type: EXAMS.delete,
  payload: payload,
});

export const deleteExamSuccessAction = (payload) => ({
  type: EXAMS.delete_success,
  payload: payload,
});

export const getProgramListIncr = (payload) => ({
  type: PROGRAM.GET_PROGRAMS_LIST_INCR,
  payload: payload,
});

export const getSuccessProgramListIncr = (payload) => ({
  type: PROGRAM.GET_SUCCESS_PROGRAMS_LIST_INCR,
  payload: payload,
});

export const getExamsProgramYearList = (payload) => ({
  type: PROGRAM_YEAR.GET_EXAMS_PROGRAM_YEAR_LIST,
  payload: payload,
});

export const getExamsSuccessProgramYearList = (payload) => ({
  type: PROGRAM_YEAR.GET_EXAMS_SUCCESS_PROGRAM_YEAR_LIST,
  payload: payload,
});

export const getExamsSemesterList = (payload) => ({
  type: SEMESTER.GET_EXAMS_SEMESTER_LIST,
  payload: payload,
});

export const getExamsSuccessSemesterList = (payload) => ({
  type: SEMESTER.GET_EXAMS_SUCCESS_SEMESTER_LIST,
  payload: payload,
});

export const getExamsList = (payload) => ({
  type: EXAM.GET_EXAMS_LIST,
  payload: payload,
});

export const getSuccessExamsList = (payload) => ({
  type: EXAM.GET_SUCCESS_EXAMS_LIST,
  payload: payload,
});

export const getExamsDashboardList = (payload) => ({
  type: EXAM.GET_EXAMS_DASHBOARD_LIST,
  payload: payload,
});

export const getSuccesExamsDashboardList = (payload) => ({
  type: EXAM.GET_SUCCESS_EXAMS_DASHBOARD_LIST,
  payload: payload,
});

//Faculty exam creation content

export const createExamContentAction = (payload) => ({
  type: EXAMS.create_content,
  payload: payload,
});

export const createExamContentSuccessAction = (payload) => ({
  type: EXAMS.create_content_success,
  payload: payload,
});

export const createExamContentError = (payload) => ({
  type: EXAMS.create_content_error,
  payload: payload,
});

export const publishExamContentAction = (payload) => ({
  type: EXAMS.publish_content,
  payload: payload,
});

export const publishExamContentSuccess = (payload) => ({
  type: EXAMS.publish_content_success,
  payload: payload,
});

export const publishExamContentError = (payload) => ({
  type: EXAMS.publish_content_error,
  payload: payload,
});

export const getExamContentAction = (payload) => ({
  type: EXAMS.get_exam_content,
  payload: payload,
});

export const getExamContentSuccess = (payload) => ({
  type: EXAMS.get_exam_content_success,
  payload: payload,
});

export const getExamContentError = (payload) => ({
  type: EXAMS.get_exam_content_error,
  payload: payload,
});

export const getPracticalExamContentAction = (payload) => ({
  type: EXAMS.get_practical_content,
  payload: payload,
});

export const getPracticalExamContentSuccess = (payload) => ({
  type: EXAMS.get_practical_content_success,
  payload: payload,
});

export const getPracticalExamContentError = (payload) => ({
  type: EXAMS.get_practical_content_error,
  payload: payload,
});

export const saveTheoryExamSuccess = (payload) => ({
  type: EXAMS.save_theory_content_success,
  payload: payload,
});

export const saveTheoryExamReset = (payload) => ({
  type: EXAMS.save_theory_content_reset,
  payload: payload,
});

export const savePracticalExamSuccess = (payload) => ({
  type: EXAMS.save_practical_content_success,
  payload: payload,
});

export const editExamContentAction = (payload) => ({
  type: EXAMS.edit_content,
  payload: payload,
});

export const editExamContentSuccessAction = (payload) => ({
  type: EXAMS.edit_content_success,
  payload: payload,
});

export const editExamContentError = (payload) => ({
  type: EXAMS.edit_content_error,
  payload: payload,
});

// Get program, year, sem

export const getProgramListRequest = (payload) => ({
  type: PROGRAM.GET_NEW_PROGRAMS_LIST_REQUEST,
  payload: payload,
});

export const getSuccessProgramList = (payload) => ({
  type: PROGRAM.GET_NEW_PROGRAMS_LIST_SUCCESS,
  payload: payload,
});

export const getProgramYearListRequest = (payload) => ({
  type: PROGRAM_YEAR.GET_NEW_PROGRAM_YEAR_LIST_REQUEST,
  payload: payload,
});

export const getSuccessProgramYearList = (payload) => ({
  type: PROGRAM_YEAR.GET_NEW_PROGRAM_YEAR_LIST_SUCCESS,
  payload: payload,
});

export const getSemesterListRequest = (payload) => ({
  type: SEMESTER.GET_NEW_SEMESTER_LIST_REQUEST,
  payload: payload,
});

export const getSuccessSemesterList = (payload) => ({
  type: SEMESTER.GET_NEW_SEMESTER_LIST_SUCCESS,
  payload: payload,
});

export const getSubjectListRequest = (payload) => ({
  type: SUBJECT.GET_SUBJECT_LIST_REQUEST,
  payload: payload,
});

export const getSuccessSubjectList = (payload) => ({
  type: SUBJECT.GET_SUBJECT_LIST_SUCCESS,
  payload: payload,
});

export const setMultiplechoicequestion = (payload) => ({
  type: MULTIPLE_QUESTION_ANSWER.set_multiple_choice_question_answer,
  payload: payload,
});

export const setMultiplechoicequestionReset = (payload) => ({
  type: MULTIPLE_QUESTION_ANSWER.set_multiple_choice_question_answer_reset,
  payload: payload,
});
