export const calendarActions = {
  CALENDAR: {
    get: 'CALENDAR_ALL_EVENTS_GET',
    get_success: 'CALENDAR_ALL_EVENTS_GET_SUCCESS',
    create: 'CALENDAR_CREATE_EVENT',
    create_success: 'CALENDAR_CREATE_EVENT_SUCCESS',
    create_error: 'CALENDAR_CREATE_EVENT_SUCCESS',

    get_user_details: 'GET_USER_DETAILS',
    get_user_details_success: 'GET_USER_DETAILS_SUCCESS',
    loading: 'LOADING',
    delete: 'CALENDAR_EVENT_DELETE',
    update: 'CALENDAR_EVENT_UPDATE',
    update_event_success: 'UPDATE_EVENT__SUCCESS',
    get_combined_list: 'GET_COMBINED_LIST',
    get_combined_list_success: 'GET_COMBINED_LIST_SUCCESS',
    get_combined_list_error: 'GET_COMBINED_LIST_ERROR',
    get_email_list: 'GET_EMAIL_LIST',
    get_email_list_success: 'GET_EMAIL_LIST_SUCCESS',
    get_email_list_error: 'GET_EMAIL_LIST_ERROR',
  },
};
