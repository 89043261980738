//Vendors
import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from 'iiht-b2a-ui-components/build/components/Typography';
import Link from 'iiht-b2a-ui-components/build/components/Link';
import MUIBreadcrumbs from 'iiht-b2a-ui-components/build/components/Breadcrumbs';
import { useTheme } from 'iiht-b2a-ui-components/build/components/Styles';

const Breadcrumbs = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const { crumbs } = props;
  return (
    <MUIBreadcrumbs aria-label="breadcrumb" separator="›">
      {crumbs?.map((crumb, index) => {
        const isLast = index === crumbs.length - 1;
        return isLast ? (
          <Typography
            key={crumb.label}
            variant="span"
            color="text.primary"
            sx={{
              fontSize: theme.typography.caption.fontSize,
              textTransform: 'capitalize',
            }}
          >
            {crumb.label}
          </Typography>
        ) : (
          <Link
            key={crumb.label}
            onClick={() =>
              history.push({
                pathname: crumb?.link,
                state: crumb?.payload || {},
              })
            }
            underline="hover"
            sx={{
              cursor: 'pointer',
              fontSize: theme.typography.caption.fontSize,
              color: theme.palette.text.disabled,
              textDecoration: 'none',
              textTransform: 'capitalize',
            }}
            color="text.disabled"
          >
            {crumb.label}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};
export default Breadcrumbs;
