import axios from 'axios';
import { SuperAdminAPI, SuperAdminMigrationDB } from 'store/SuperAdmin';
import axiosInstance from 'translator';

export const GetAllTenantApi = (payload) => {
  return axiosInstance
    .get(`${SuperAdminAPI?.Tenants}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const GetByTenantApi = (payload) => {
  return axiosInstance
    .get(`${SuperAdminAPI?.Tenants}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const UploadTenantAssetsApi = (payload) => {
  return axiosInstance
    .post(`${SuperAdminAPI?.UploadFiles}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const UploadTenantLogoApi = (payload) => {
  return axiosInstance
    .post(`${SuperAdminAPI?.UploadFiles}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const UploadTenantLoginApi = (payload) => {
  return axiosInstance
    .post(`${SuperAdminAPI?.UploadFiles}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const UploadUserManualApi = (payload) => {
  return axiosInstance
    .post(`${SuperAdminAPI?.UploadFiles}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const CreateTenantApi = (payload) => {
  return axiosInstance
    .post(`${SuperAdminAPI?.Tenants}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteTenantApi = async (payload) => {
  return axiosInstance
    .delete(`${SuperAdminAPI?.Tenants}/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const editTenantApi = async (payload) => {
  return axiosInstance
    .patch(`${SuperAdminAPI?.Tenants}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const activateTenantApi = async (payload) => {
  return axiosInstance
    .patch(`${SuperAdminAPI?.Tenants}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const UploadAddAdminApi = (payload) => {
  return axiosInstance
    .post(`${SuperAdminAPI?.UploadFiles}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const GetAllAdminsApi = (payload) => {
  return axiosInstance
    .get(`${SuperAdminAPI?.Tenant_Admin}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteAdminApi = async (payload) => {
  return axiosInstance
    .delete(`${SuperAdminAPI?.Tenant_Admin}/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const CreateAdminApi = (payload) => {
  return axiosInstance
    .post(`${SuperAdminAPI?.Tenant_Admin}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const GetByAdminApi = (payload) => {
  return axiosInstance
    .get(`${SuperAdminAPI?.Tenant_Admin}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const activateAdminApi = async (payload) => {
  return axiosInstance
    .patch(`${SuperAdminAPI?.Tenant_Admin}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSuperAdminCardsApi = (payload) => {
  return axiosInstance
    .get(`${SuperAdminAPI?.SuperAdminDashboard}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const migrationTenantListAPI = (payload) => {
  return axiosInstance
    .get(`${SuperAdminAPI?.MigrationTenantList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// MIgration Data Base

export const migrationAdmissionDBAPI = (payload) => {
  const tenantId = payload?.tenant;
  const token = payload?.token;
  return axios
    .get(`${SuperAdminMigrationDB?.AdmissionDB}`, {
      headers: {
        tenant: tenantId,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const migrationExamDBAPI = (payload) => {
  const tenantId = payload?.tenant;
  const token = payload?.token;
  return axios
    .get(`${SuperAdminMigrationDB?.ExamDB}`, {
      headers: {
        tenant: tenantId,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const migrationAttendanceDBAPI = (payload) => {
  const tenantId = payload?.tenant;
  const token = payload?.token;
  return axios
    .get(`${SuperAdminMigrationDB?.Attendance_ProgramDB}`, {
      headers: {
        tenant: tenantId,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const migrationUserManagementDBAPI = (payload) => {
  const tenantId = payload?.tenant;
  const token = payload?.token;
  return axios
    .get(`${SuperAdminMigrationDB?.UserManagementDB}`, {
      headers: {
        tenant: tenantId,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
