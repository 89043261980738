import axiosInstance from 'translator';
import { VerifierAPI } from 'store/Verifier';

export const getVerifierStatsApi = async (payload) => {
  return axiosInstance
    .get(`${VerifierAPI.verifierStats}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getCandidateListApi = async (payload) => {
  return axiosInstance
    .get(`${VerifierAPI.leadVerifier}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getLeadStageVerifierApi = async (id, payload) => {
  return axiosInstance
    .patch(`${VerifierAPI.startVerifying}/${id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getCandidateApplicationInfoApi = async (payload) => {
  return axiosInstance
    .get(`${VerifierAPI.leadVerifier}/${payload?.uuid}`, {
      params: { type: 'application_info' },
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getProspectusFeedbackApi = async (payload) => {
  return axiosInstance
    .get(`${VerifierAPI.prospectusFeedback}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const sendProspectusFeedbackApi = async (payload) => {
  return axiosInstance
    .post(`${VerifierAPI.prospectusFeedback}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const updateProspectusStatusApi = async (payload) => {
  return axiosInstance
    .patch(`${VerifierAPI.prospectus}/${payload?.prospectus_id}`, {
      prospectus_status: payload?.prospectus_status,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getFeesDetailsApi = async (payload) => {
  return axiosInstance
    .get(VerifierAPI.prospectus, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const addDiscountApi = async (payload) => {
  return axiosInstance
    .post(`${VerifierAPI.payment}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteDiscountApi = async (payload) => {
  return axiosInstance
    .delete(`${VerifierAPI.payment}/${payload?.discountId}`)
    .then((response) => response)
    .catch((error) => error);
};

export const updatePaymentDetailsApi = async (id, payload) => {
  return axiosInstance
    .patch(`${VerifierAPI.updatePayment}/${id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const sendPaymentLinkApi = async (id, payload) => {
  return axiosInstance
    .patch(`${VerifierAPI.sendPaymentLink}/${id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const sendToRegistrarApi = async (payload) => {
  return axiosInstance
    .post(`${VerifierAPI.sendToRegistrar}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const receiptFileUploadApi = async (payload) => {
  return axiosInstance
    .post(`${VerifierAPI?.fileUpload}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getReceiptFileBlobUrlApi = async (payload) => {
  return axiosInstance
    .get(`${VerifierAPI?.fileUpload}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProdileDetails = async (payload) => {
  return axiosInstance
    .get(`${VerifierAPI.getProfileDetail}?userId=${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
