import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import { LoaderComponent } from 'components';
import RoleWiseRouter from 'config/RolewiseRouter';
import { ConnectedRouter } from 'connected-react-router';
import Box from 'iiht-b2a-ui-components/build/components/Box';
import CircularProgress from 'iiht-b2a-ui-components/build/components/Progress/CircularProgress';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { getLoginDetails } from 'store/UserManagement';
import configureStore from 'store/store';
import { localStorageHelper, removeSpace } from 'utils';
import { NotFoundPage } from './components/common';
import { Role } from './data/roles';
import './App.scss';

// Lazy loading of role based routes
const RegistrarRoute = React.lazy(() => import('routing/RegistrarRoute'));
const StudentRoute = React.lazy(() => import('routing/StudentRoute'));
const HodRoute = React.lazy(() => import('routing/HodRoute'));
const FacultyRoute = React.lazy(() => import('routing/FacultyRoute'));
const LeadRoute = React.lazy(() => import('routing/LeadRoute'));
const CounsellorRoute = React.lazy(() => import('routing/CounsellorRoute'));
const CandidateRoute = React.lazy(() => import('routing/CandidateRoute'));
const VerifierRoute = React.lazy(() => import('routing/VerifierRoute'));
const ParentRoute = React.lazy(() => import('routing/ParentRoute'));
const MoocAdminRouter = React.lazy(() => import('routing/MoocAdminRoute'));
const HrRoute = React.lazy(() => import('routing/HrRoute'));
const SuperAdminRouter = React.lazy(() => import('routing/SuperAdminRoute'));

const AppRoute = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { keycloak, initialized } = useKeycloak();
  const headerRef = useRef(null);
  const [decodToken, setDecodToken] = useState(null);
  const tenantName = localStorageHelper.get('tenantName');
  const tenantId = localStorageHelper.get('tenantId');
  const token = localStorageHelper.get('access_token');
  const role = localStorageHelper.get('role_name');
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.userManagementState.user.data
  );

  const logoutAction = (history) => {
    history.push(`${tenantName}/login`);
  };

  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === 'access_token' && e.oldValue && !e.newValue) {
        const { persistor } = configureStore();
        persistor.pause();
        persistor.flush().then(() => {
          return persistor.purge();
        });
        logoutAction(history);
      }
    };
    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, [logoutAction]);

  useEffect(() => {
    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/tenant_management/decode-token`,
          {
            tenant_id: tenantId,
            access_token: `Bearer ${token}`,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            localStorageHelper.set('user_id', res.data.decoded_token.user_id);
            localStorageHelper.set(
              'tenantName',
              res.data.decoded_token.tenant_name
            );
            localStorageHelper.set('role_name', res.data.decoded_token.role);
            localStorageHelper.set(
              'decodRes',
              JSON.stringify(res.data.decoded_token)
            );
            setDecodToken(res.data);
          } else {
            keycloak.logout();
            localStorage.clear();
          }
        })
        .catch((err) => {
          keycloak.logout();
          localStorage.clear();
        });
    }
  }, [token]);

  useEffect(() => {
    if (decodToken) {
      dispatch(getLoginDetails({ userId: decodToken.decoded_token.user_id }));
    }
  }, [decodToken]);

  const renderSwitch = (roleName) => {
    if (roleName)
      switch (roleName) {
        case Role.registrar:
          return <RegistrarRoute />;
        case Role.hod:
          return <HodRoute />;
        case Role.student:
          return <StudentRoute />;
        case Role.faculty:
          return <FacultyRoute />;
        case removeSpace(Role.leadExec):
          return <LeadRoute />;
        case Role.counsellor:
          return <CounsellorRoute />;
        case Role.candidate:
          return <CandidateRoute />;
        case Role.verifier:
          return <VerifierRoute />;
        case Role.parent:
          return <ParentRoute />;
        case Role.HR:
          return <HrRoute />;
        case Role.moocAdmin:
          return <MoocAdminRouter />;
        case Role.superadmin:
          return <SuperAdminRouter />;
        default:
          return (
            <>
              <Route path={'/'} component={NotFoundPage} />
            </>
          );
      }
  };
  const redirectToLogin = () => {
    history.push({
      pathname: '/',
    });
    keycloak.login();
  };

  if (!initialized && !keycloak?.authenticated) {
    return <LoaderComponent visible={true} loaderText={t('loading')} />;
  }

  if (initialized && !keycloak?.authenticated) {
    return <>{redirectToLogin()}</>;
  }
  return (
    <>
      {decodToken && userDetails && Object.keys(userDetails)?.length > 0 ? (
        <div className="App-content" ref={headerRef}>
          <ConnectedRouter history={history}>
            <Suspense
              fallback={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Switch>
                <Route exact path={'/'} component={RoleWiseRouter} />
                {renderSwitch(role)}
              </Switch>
            </Suspense>
          </ConnectedRouter>
        </div>
      ) : (
        <LoaderComponent visible={true} loaderText={t('loading')} />
      )}
    </>
  );
};

export default AppRoute;
