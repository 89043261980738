import React from 'react';
import TextViewer from './TextViewer';
import './CustomFileViewer.scss';
import RtfViewer from './RtfViewer';

const CustomFileViewer = (props) => {
  const { fileType = '', filePath = '' } = props || '';

  const getDriver = (fType, fPath) => {
    switch (fType) {
      case 'srt':
      case 'txt': {
        return <TextViewer fileType={fType} filePath={fPath} />;
      }
      case 'rtf': {
        return <RtfViewer fileType={fType} filePath={fPath} />;
      }
      default: {
        return <p>No Supported File</p>;
      }
    }
  };

  return (
    <div className="customFileViewerContainer">
      {getDriver(fileType, filePath)}
    </div>
  );
};

export default CustomFileViewer;
