import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from 'iiht-b2a-ui-components/build/components';
import axios from 'axios';
import { DomainNotFoundPage, LoaderComponent } from 'components/common';
import { localStorageHelper } from 'utils';
import './Login.scss';

const Login = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [tenantData, setTenantData] = useState(null);
  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [loader, setLoader] = useState(true);

  const checkDomain = async () => {
    try {
      const { status } = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/tenant_management/verify-domain`,
        {
          domain: window.location.host,
        }
      );
      if (status === 200) {
        setLoader(false);
        await fetchData();
      } else {
        setShowPageNotFound(true);
      }
    } catch (error) {
      console.log(error);
      setShowPageNotFound(true);
    }
  };

  const fetchData = async () => {
    const { status, data: body } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/tenant_management/tenant-logo?domain=${window.location.host}`
    );
    if (status >= 400 && status <= 599) {
      return;
    }
    if (status === 200) {
      setTenantData(body);
      localStorageHelper.set('tenantHeaderImage', body.tenant_logo) ?? '';
    }
  };

  useEffect(() => {
    checkDomain();
  }, []);

  if (showPageNotFound) {
    return <DomainNotFoundPage />;
  }

  return (
    <>
      {loader ? (
        <LoaderComponent />
      ) : (
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          height={'100vh'}
          sx={{
            background: tenantData?.login_customization?.tenant_login_image
              ? `url(${tenantData?.login_customization?.tenant_login_image})`
              : 'linear-gradient(to bottom right, #EDE6FF, #02387A)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Stack
            spacing={2}
            bgcolor={'#FFFFFF'}
            p={6}
            borderRadius={'28px'}
            minWidth={280}
            maxWidth={560}
            minHeight={250}
            alignItems="center"
          >
            {tenantData?.tenant_logo && (
              <img
                src={tenantData.tenant_logo}
                alt="Tenant Logo"
                width="250px"
                height="120px"
              />
            )}
            <Stack
              spacing={3}
              sx={{ p: 0, m: 0, width: '100%' }}
              // display="flex"
              // alignItems="center"
              // justifyContent="center"
            >
              {tenantData?.login_customization?.login_text_header && (
                <Typography
                  variant="headline"
                  color="#49454F"
                  sx={{ fontSize: '18px', textAlign: 'center' }}
                >
                  Welcome to {tenantData.login_customization.login_text_header}
                </Typography>
              )}
              <Divider height={10} />

              <Button
                className="qa-sign-in-button mt1"
                variant="contained"
                color="secondary"
                sx={{
                  width: '100%',
                  borderRadius: '20px ',
                }}
                // type="submit"
                onClick={onSubmit}
              >
                {t('Login')}
              </Button>

              <Divider />

              <Typography
                variant="caption"
                color="#A3A3A3"
                sx={{ textAlign: 'center' }}
              >
                All rights reserved
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Login;
