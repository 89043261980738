import { takeLatest, put, call, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import {
  getFeesApi,
  postFeesUploadApi,
  postFessEmailRemainderApi,
  postgetStatusByUsersApi,
} from './FeeManagment.api';
import {
  getFeeClustersError,
  getFeeClustersloading,
  getFeeClustersSuccess,
  createFeesUploadSuccess,
  createFeesUploadError,
  createGetStatusByUserActionSuccess,
  creategetStatusByUserActionError,
  createFeeEmailRemainderAction,
  createFeeEmailRemainderActionError,
  createFeeEmailRemainderActionSuccess,
} from './FeeManagment.action';
import { feeManagementActions } from './FeeManagment.constants';
import { setToastMessage } from 'store/Toast';

function* getFeeClustersSaga({ payload }) {
  try {
    yield put(getFeeClustersloading(true));
    const response = yield call(getFeesApi, payload);
    yield put(getFeeClustersloading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getFeeClustersSuccess(response?.data));
    } else {
      yield put(getFeeClustersError(response?.data));
    }
  } catch (error) {
    return error;
  }
}

function* createfeeUploadSaga({ data, payload }) {
  try {
    const response = yield call(postFeesUploadApi, data, payload);
    if (response?.status === statusCode.Ok200) {
      if (response?.data?.length === 0) {
        yield put(createFeesUploadSuccess(true));
        yield put(setToastMessage({ data: '', severity: 'success' }));
      } else {
        yield put(createFeesUploadError(response?.data));
        yield put(
          setToastMessage({
            data: '',
            severity: 'error',
          })
        );
      }
    } else {
      yield put(createFeesUploadError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* createStatusByUserSaga({ payload }) {
  try {
    yield put(getFeeClustersloading(true));
    const response = yield call(postgetStatusByUsersApi, payload);
    yield put(getFeeClustersloading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createGetStatusByUserActionSuccess(true));
    } else {
      yield put(creategetStatusByUserActionError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* SendFessEmailRemainderSaga({ payload }) {
  try {
    yield put(getFeeClustersloading(true));
    const response = yield call(postFessEmailRemainderApi, payload);
    yield put(getFeeClustersloading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createFeeEmailRemainderActionSuccess(true));
    } else {
      yield put(createFeeEmailRemainderActionError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchDummy() {
  yield takeLatest(feeManagementActions.FEE.get, getFeeClustersSaga);
  yield takeLatest(
    feeManagementActions.FEE.create_FeesUpload,
    createfeeUploadSaga
  );
  yield takeLatest(
    feeManagementActions.FEE.create_get_StatusBy_users,
    createStatusByUserSaga
  );
  yield takeLatest(
    feeManagementActions.FEE.create_FeeEmailRemainder,
    SendFessEmailRemainderSaga
  );
}

export default function* feeManagementSaga() {
  yield all([watchDummy()]);
}
