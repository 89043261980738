import React, { useState, useEffect } from 'react';
import { useTheme } from 'iiht-b2a-ui-components/build/components/Styles';
import { Box, IconButton } from 'iiht-b2a-ui-components/build/components';
import { registrarAndHodItems } from './profileItems';
import { hodHeaderOptions } from './headerTabOptions';
import ProfileTab from './ProfileTab';
import HeaderTabs from './HeaderTabs';
import NotificationTab from './NotificationTab';
import { Link } from 'react-router-dom';
import { Calender, Campaign } from 'icons';
import { hodItems } from './headerMenus';
import CreateBrodcast from 'modules/notifications/CreateBrodcast';
import MessageIcon from 'icons/MessageIcon';
import { ROUTES } from 'constants/routesConstants';
import { CloseIcon } from 'icons';

const HODHeader = ({
  userDetails,
  role,
  notificationList,
  t,
  responsiveNav,
  setResponsiveNav,
}) => {
  const theme = useTheme();

  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const [profileItems, setProfileItems] = useState([]);
  const [headerTabs, setHeaderTabs] = useState([]);
  const [manageItems, setManageItems] = useState([]);
  const [brodcastModal, setBrodcastModal] = useState(false);

  const getHeaderTabs = () => {
    return hodHeaderOptions(t);
  };

  useEffect(() => {
    const tabOptions = getHeaderTabs(selectedLanguage);
    setHeaderTabs(tabOptions);
  }, [t]);

  const createBrodcastHandler = () => {
    setBrodcastModal(true);
  };

  useEffect(() => {
    if (role) {
      const taboptions = getHeaderTabs();
      setHeaderTabs(taboptions);
    }
    setManageItems(
      hodItems(userDetails?.userCourseInformation?.[0]?.departmentId, t)
    );
    setProfileItems(registrarAndHodItems(t));
  }, [role, userDetails, selectedLanguage, t]);

  return (
    <>
      {brodcastModal && (
        <CreateBrodcast
          modalVisible={brodcastModal}
          setModalVisible={setBrodcastModal}
        />
      )}
      <Box className={responsiveNav ? 'navigations open' : 'navigations'}>
        <HeaderTabs
          headerTabs={headerTabs}
          manageItems={manageItems}
          theme={theme}
        />
        <Box className="header-icons">
          <IconButton
            size="small"
            aria-label="main navigation"
            aria-controls="close-appbar"
            aria-haspopup="false"
            color="inherit"
            className="closeNav"
            onClick={() => {
              setResponsiveNav(false);
            }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton size="small">
            <Link to={`${ROUTES?.CALENDAR?.ROOT}`}>
              <Calender fontSize="small" />
            </Link>
          </IconButton>
          {/* <IconButton>
            <Link to={'/chats'}>
              <MessageIcon />
            </Link>
          </IconButton> */}
          <IconButton size="small" onClick={createBrodcastHandler}>
            <Campaign fontSize="small" />
          </IconButton>
          <NotificationTab notificationList={notificationList} t={t} />
        </Box>
      </Box>
      <ProfileTab
        userDetails={userDetails}
        theme={theme}
        profileItems={profileItems}
      />
    </>
  );
};

export default HODHeader;
