export const obeActions = {
  DEFINE_OUTCOMES_LOADER: 'DEFINE_OUTCOMES_LOADER',
  CREATE_DEFINE_OUTCOMES: {
    create: 'CREATE_DEFINE_OUTCOMES',
    success: 'CREATE_DEFINE_OUTCOMES_SUCCESS',
    error: 'CREATE_DEFINE_OUTCOMES_ERROR',
    reset: 'RESET_CREATE_DEFINE_OUTCOMES',
  },
  GET_DEFINE_OUTCOMES: {
    get: 'GET_DEFINE_OUTCOMES',
    success: 'GET_DEFINE_OUTCOMES_SUCCESS',
    error: 'GET_DEFINE_OUTCOMES_ERROR',
    reset: 'RESET_GET_DEFINE_OUTCOMES',
  },

  GET_MISSION_VS_PEO_MAPPING: {
    get: 'GET_MISSION_VS_PEO_MAP',
    success: 'GET_MISSION_VS_PEO_MAP_SUCCESS',
    error: 'GET_MISSION_VS_PEO_MAP_ERROR',
    reset: 'GET_MISSION_VS_PEO_MAP_RESET',
  },

  CREATE_MISSION_VS_PEO_MAPPING: {
    create: 'CREATE_MISSION_VS_PEO_MAP',
    success: 'CREATE_MISSION_VS_PEO_MAP_SUCCESS',
    error: 'CREATE_MISSION_VS_PEO_MAP_ERROR',
    reset: 'CREATE_MISSION_VS_PEO_MAP_RESET',
  },

  GET_CO_VS_PO_MAPPING: {
    get: 'GET_CO_VS_PO_MAP',
    success: 'GET_CO_VS_PO_MAP_SUCCESS',
    error: 'GET_CO_VS_PO_MAP_ERROR',
    reset: 'GET_CO_VS_PO_MAP_RESET',
  },

  CREATE_CO_VS_PO_MAPPING: {
    create: 'CREATE_CO_VS_PO_MAP',
    success: 'CREATE_CO_VS_PO_MAP_SUCCESS',
    error: 'CREATE_CO_VS_PO_MAP_ERROR',
    reset: 'CREATE_CO_VS_PO_MAP_RESET',
  },
};
