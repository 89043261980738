import { MyRepositoryActions } from './MyRepository.constants';

const INITIAL_STATE = {
  loading: false,
  repository: {
    data: [],
    list: [],
    createdDocument: {},
    shareBulkFile: {},
    deleteBulkFile: '',
  },
  emailList: {
    dataObj: [],
  },
};
const myRepositoryReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MyRepositoryActions.MYREPOSITORY.loading: {
      return {
        ...state,
        loading: action?.payload,
      };
    }
    case MyRepositoryActions.MYREPOSITORY.get: {
      return {
        ...state,
        payload: action?.payload,
      };
    }
    case MyRepositoryActions.MYREPOSITORY.get_success: {
      return {
        ...state,
        repository: {
          ...state.repository,
          list: action?.payload?.totalCount,
          data: action?.payload,
        },
      };
    }
    case MyRepositoryActions.MYREPOSITORY.get_reset: {
      return {
        ...state,
        repository: {
          ...state.repository,
          data: [],
        },
      };
    }
    case MyRepositoryActions.MYREPOSITORY.create_Report_Document_success: {
      return {
        ...state,
        repository: {
          ...state.repository,
          createdDocument: action?.payload,
        },
      };
    }
    case MyRepositoryActions.MYREPOSITORY.create_Report_Document_reset: {
      return {
        ...state,
        repository: {
          ...state.repository,
          createdDocument: {},
        },
      };
    }
    case MyRepositoryActions.MYREPOSITORY.delete_success: {
      return {
        ...state,
        repository: {
          ...state.repository,
          list: action?.payload?.totalCount,
          data: action?.payload,
        },
      };
    }
    case MyRepositoryActions.MYREPOSITORY.get_by_user_success: {
      return {
        ...state,
        repository: {
          ...state.repository,
          list: action?.payload?.totalCount,
          data: action?.payload,
        },
      };
    }

    case MyRepositoryActions.MYREPOSITORY.BULK_SHARE_FILE_SUCCESS: {
      return {
        ...state,
        repository: {
          ...state.repository,
          shareBulkFile: action?.payload,
        },
      };
    }

    case MyRepositoryActions.MYREPOSITORY.BULK_DELETE_FILE: {
      return {
        ...state,
        repository: {
          ...state.repository,
          deleteBulkFile: action?.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default myRepositoryReducer;
