import React from 'react';
import {
  DragDrop,
  Grid,
  Button,
  Box,
} from 'iiht-b2a-ui-components/build/components';
import DropZone from 'components/common/DropZone';
import { FileUpload } from 'icons';
import { useTranslation } from 'react-i18next';
import './BulkUpload.scss';
import { fileSupportMessage, fileViewerSupportedType } from 'constants/index';

const BulkUploadDetails = ({ csvFile, csvFileName, addFileToData }) => {
  const { t } = useTranslation();
  const fileViewerSupportedType = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
  };
  const fileSupportMessage = `Support .xlsx formats`;
  return (
    <>
      <Grid container>
        <Grid xl={5} lg={5} md={5}>
          <DropZone
            addFile={addFileToData}
            supportedFiles={fileViewerSupportedType}
            isDocumentViewerAvailable={false}
            invalidFileMessage={fileSupportMessage}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginY: '1rem' }}>
        <a href={csvFile} download={csvFileName}>
          <Button variant="outlined" color="secondary" sx={{ my: 2 }}>
            {t('management.downloadCSV')}
          </Button>
        </a>
      </Box>
    </>
  );
};

export default BulkUploadDetails;
