import React from 'react';
import { Grid, Typography } from 'iiht-b2a-ui-components/build/components';

const HeadingWithIcon = ({ icon, title }) => {
  return (
    <Grid item xl={12} md={12} display="flex" sx={{ my: 1 }}>
      {icon}
      <Typography variant="subtitle" sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Grid>
  );
};

export default HeadingWithIcon;
