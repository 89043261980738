import React, { useEffect } from 'react';

const TextViewer = (props) => {
  const { filePath = '' } = props || '';

  useEffect(() => {
    if (filePath) {
      fetchFileFromURL();
    }
  }, [filePath]);

  const fetchFileFromURL = () => {
    fetch(filePath)
      .then((response) => response.text())
      .then((fData) => {
        let textFileViewerContainer = document.getElementById('txtFileData');
        if (textFileViewerContainer) {
          textFileViewerContainer.innerText += fData;
        }
      })
      .catch((err) => {
        console.error('Error while fetching -  ' + err);
      });
  };

  return <div id="txtFileData"></div>;
};

export default TextViewer;
