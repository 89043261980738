export const dashboardActions = {
  PRESENT_DETAILS: {
    loading: 'DASHBOARD_PRESENT_DETAILS_LOADING',
  },
  CLUSTERS: {
    loading: 'DASHBOARD_CLUSTERS_LOADING',
  },
  DEPARTMENT: {
    get: 'DASHBOARD_DEPARTMENT_GET',
    get_success: 'DASHBOARD_DEPARTMENT_GET_SUCCESS',
    get_error: 'DASHBOARD_DEPARTMENT_GET_ERROR',
    loading: 'DASHBOARD_DEPARTMENT_LOADING',
  },
  REGISTRAR_COURSE_COUNT: {
    loading: 'GET_REGISTRAR_COURSE_COUNT_LOADING',
  },
  STUDENT_ATTENDANCE: {
    loading: 'GET_STUDENT_ATTENDANCE_LOADING',
  },
  STUDENT_RATING: {
    loading: 'GET_STUDENT_RATINGS_LOADING',
  },
  STUDENT_PROGRESS: {
    loading: 'GET_STUDENT_PROGRESS_LOADING',
  },

  CLASS_COUNT: {
    loading: 'GET_CLASS_COUNT_FOR_FACULTY_LOADING',
  },
  SCHEDULE: {
    loading: 'GET_DASHBOARD_SCHEDULE_LOADING',
  },
  ASSESSMENT_STATUS: {
    loading: 'GET_DASHBOARD_ASSESSMENT_STATUS_LOADING',
  },
  MODULE_STATUS: {
    loading: 'GET_DASHBOARD_MODULE_STATUS_LOADING',
  },
  ASSESSMENTS_TABLE: {
    loading: 'GET_DASHBOARD_ASSESSMENTS_TABLE_LOADING',
  },
  ONGOING_COURSES: {
    loading: 'GET_DASHBOARD_ONGOING_COURSES_LOADING',
  },
  PENDING_APPROVALS: {
    loading: 'GET_DASHBOARD_PENDING_APPROVALS_LOADING',
  },

  // Registrar

  GET_DASHBOARD_CARD_DETAILS_REQUEST: 'GET_DASHBOARD_CARD_DETAILS_REQUEST',
  GET_DASHBOARD_CARD_DETAILS_SUCCESS: 'GET_DASHBOARD_CARD_DETAILS_SUCCESS',
  GET_DASHBOARD_CARD_DETAILS_ERROR: 'GET_DASHBOARD_CARD_DETAILS_ERROR',
  GET_DASHBOARD_CARD_DETAILS_RESET: 'GET_DASHBOARD_CARD_DETAILS_RESET',

  GET_ENROLMENT_PROGRAM_LIST_REQUEST: 'GET_ENROLMENT_PROGRAM_LIST_REQUEST',
  GET_ENROLMENT_PROGRAM_LIST_SUCCESS: 'GET_ENROLMENT_PROGRAM_LIST_SUCCESS',
  GET_ENROLMENT_PROGRAM_LIST_ERROR: 'GET_ENROLMENT_PROGRAM_LIST_ERROR',
  GET_ENROLMENT_PROGRAM_LIST_RESET: 'GET_ENROLMENT_PROGRAM_LIST_RESET',

  // Get more enrolment program pagination
  GET_MORE_ENROLMENT_PROGRAM_LIST_REQUEST:
    'GET_MORE_ENROLMENT_PROGRAM_LIST_REQUEST',
  GET_MORE_ENROLMENT_PROGRAM_LIST_SUCCESS:
    'GET_MORE_ENROLMENT_PROGRAM_LIST_SUCCESS',
  GET_MORE_ENROLMENT_PROGRAM_LIST_ERROR:
    'GET_MORE_ENROLMENT_PROGRAM_LIST_ERROR',

  // Get registrar dashboard chart data
  GET_REGISTRAR_DASHBOARD_CHART_DATA_REQUEST:
    'GET_REGISTRAR_DASHBOARD_CHART_DATA_REQUEST',
  GET_REGISTRAR_DASHBOARD_CHART_DATA_SUCCESS:
    'GET_REGISTRAR_DASHBOARD_CHART_DATA_SUCCESS',
  GET_REGISTRAR_DASHBOARD_CHART_DATA_ERROR:
    'GET_REGISTRAR_DASHBOARD_CHART_DATA_ERROR',
  GET_REGISTRAR_DASHBOARD_CHART_DATA_RESET:
    'GET_REGISTRAR_DASHBOARD_CHART_DATA_RESET',

  //HR
  GET_HR_DASHBOARD_CARD_DETAILS_REQUEST:
    'GET_HR_DASHBOARD_CARD_DETAILS_REQUEST',
  GET_HR_DASHBOARD_CARD_DETAILS_SUCCESS:
    'GET_HR_DASHBOARD_CARD_DETAILS_SUCCESS',
  GET_HR_DASHBOARD_CARD_DETAILS_ERROR: 'GET_HR_DASHBOARD_CARD_DETAILS_ERROR',
  GET_HR_DASHBOARD_CARD_DETAILS_RESET: 'GET_HR_DASHBOARD_CARD_DETAILS_RESET',

  //Faculty

  GET_STUDENT_ATTENDANCE_GRAPH_REQUEST: 'GET_STUDENT_ATTENDANCE_GRAPH_REQUEST',
  GET_STUDENT_ATTENDANCE_GRAPH_SUCCESS: 'GET_STUDENT_ATTENDANCE_GRAPH_SUCCESS',
  GET_STUDENT_ATTENDANCE_GRAPH_ERROR: 'GET_STUDENT_ATTENDANCE_GRAPH_ERROR',

  GET_FACULTY_DASHBOARD_REQUEST: 'GET_FACULTY_DASHBOARD_REQUEST',
  GET_FACULTY_DASHBOARD_REQUEST_SUCCESS:
    'GET_FACULTY_DASHBOARD_REQUEST_SUCCESS',
  GET_FACULTY_DASHBOARD_REQUEST_ERROR: 'GET_FACULTY_DASHBOARD_REQUEST_ERROR',

  // Student Dashboard
  STUDENT_DASHBOARD_GRAPH_REQUEST: 'STUDENT_DASHBOARD_GRAPH_REQUEST',
  STUDENT_DASHBOARD_GRAPH_SUCCESS: 'STUDENT_DASHBOARD_GRAPH_SUCCESS',
  STUDENT_DASHBOARD_GRAPH_ERROR: 'STUDENT_DASHBOARD_GRAPH_ERROR',
  STUDENT_DASHBOARD_GRAPH_RESET: 'STUDENT_DASHBOARD_GRAPH_RESET',

  //Get student dashboard details

  GET_STUDENT_DASHBOARD_REQUEST: 'GET_STUDENT_DASHBOARD_REQUEST',
  GET_STUDENT_DASHBOARD_SUCCESS: 'GET_STUDENT_DASHBOARD_SUCCESS',
  GET_STUDENT_DASHBOARD_ERROR: 'GET_STUDENT_DASHBOARD_ERROR',

  GET_STUDENT_DASHBOARD_TABLE: 'GET_STUDENT_DASHBOARD_TABLE',
  GET_STUDENT_DASHBOARD_TABLE_SUCCESS: 'GET_STUDENT_DASHBOARD_TABLE_SUCCESS',
  GET_STUDENT_DASHBOARD_TABLE_ERROR: 'GET_STUDENT_DASHBOARD_TABLE_ERROR',

  //HOD DashBoard
  GET_FACULTY_DETAILS_DASHBOARD_REQUEST:
    'GET_FACULTY_DETAILS_DASHBOARD_REQUEST',
  GET_FACULTY_DETAILS_DASHBOARD_SUCCESS:
    'GET_FACULTY_DETAILS_DASHBOARD_SUCCESS',
  GET_FACULTY_DETAILS_DASHBOARD_ERROR: 'GET_FACULTY_DETAILS_DASHBOARD_ERROR',

  GET_FACULTY_USER_DETAILS_REQUEST: 'GET_FACULTY_USER_DETAILS_REQUEST',
  GET_FACULTY_USER_DETAILS_SUCCESS: 'GET_FACULTY_USER_DETAILS_SUCCESS',
  GET_FACULTY_USER_DETAILS_ERROR: 'GET_FACULTY_USER_DETAILS_ERROR',
};
