import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  Date,
  TextField,
} from 'iiht-b2a-ui-components/build/components';

const FormDatepicker = React.memo(
  ({
    name,
    value,
    rules,
    label,
    fullWidth,
    className,
    required = false,
    size,
    isAsterisk,
    ...rest
  }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const { getFieldState } = control;
    return (
      <FormControl
        margin="normal"
        fullWidth={fullWidth}
        className={className}
        size={size}
      >
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => {
            const { name, value, onChange, onBlur } = field;
            const { invalid, error } = getFieldState(name);
            return (
              <Date
                name={name}
                label={`${label}${isAsterisk ? '*' : ''}`}
                onChange={onChange}
                onBlur={onBlur}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    aria-invalid={invalid ? 'true' : 'false'}
                    error={error ? true : false}
                    helperText={error?.message}
                  />
                )}
                variant="inline"
                value={value}
                {...rest}
              />
            );
          }}
        ></Controller>
      </FormControl>
    );
  }
);
export default FormDatepicker;
