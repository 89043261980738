export const AssignmentStatus = {
  0: 'Pending',
  1: 'Pending',
  2: 'InProcess',
  3: 'Submitted',
};

export const AssignmentDuration = {
  1: '60 mins',
  2: '2 hours',
  3: '3 hours',
  4: '4 hours',
  5: '5 hours',
  6: '6 hours',
  7: '7 hours',
  8: '8 hours',
  9: '9 hours',
  10: '10 hours',
};

export const CourseLevel = {
  1: 'Basic',
  2: 'Medium',
  3: 'Advance',
};

export const CourseLanguage = {
  0: 'English',
  1: 'Hindi',
};

export const CourseFileType = {
  1: 'CourseImage',
  2: 'Syllabus',
  3: 'Schema',
  4: 'OtherDocuments',
  5: 'RequestNote',
  6: 'DepartmentProfile',
};

export const SubModuleFileType = {
  1: 'Media',
  2: 'Transcript',
};

export const TotalCredits = {
  1: 'Credit1',
  2: 'Credit2',
  3: 'Credit3',
};

export const AssignmentQuestionType = {
  1: 'MultipleChoice',
  2: 'Descriptive',
  3: 'TrueorFalse',
  4: 'FillInTheBlanks',
  5: 'MultiSelect',
  6: 'Ordering',
  7: 'ShortAnswer',
  8: 'LongAnswer',
  9: 'Matching',
};

export const EvaluationStatus = {
  1: 'Pending',
  2: 'Published',
  3: 'Completed',
  4: 'Reappear', //reappear
  5: 'Revaluate',
  6: 'Exempted',
};

export const ActivityType = {
  1: 'Assessment',
  2: 'Quiz',
};

export const feedbackEnum = {
  0: 'Other',
  1: 'Course',
  2: 'Faculty',
};

// as per new Python api
export const EVAL_STATUS = {
  0: 'Pending',
  1: 'Complete',
  2: 'Recheck Pending',
  3: 'Recheck Complete'
};
