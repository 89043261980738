import { assessmentRepositoryActions } from './AssessmentRepository.constants';

const {
  createQuestionBank,
  createRubrics,
  listRubrics,
  getRubricsById,
  deleteRubricsById,
  updateRubricsById,
  createQuestionBankBulkUpload,
  getQuestionBankList,
  getQuestionBankDetailsById,
  deleteQuestionBank,
  evaluateQuestionBank,
  editQuestion,
  deleteQuestion,
  createAssessment,
  getAssessmentList,
  deleteAssessment,
  getAssessmentDetailsById,
  editSection,
  createSection,
  deleteSection,
  importQuestion,
  sendAssessmentForApproval,
  updateAssessent,
  evaluateAssesment,
} = assessmentRepositoryActions;

const INITIAL_STATE = {
  questionBankLoader: false,
  rubricsLoader: false,
  createQuestionBank: {
    success: '',
    error: '',
  },
  createQuestionBankBulkUpload: {
    success: '',
    error: '',
  },
  createRubrics: {
    success: '',
    error: '',
  },
  listRubrics: {
    data: [],
    error: '',
  },
  getRubricsById: {
    data: '',
    error: '',
  },
  deleteRubricsById: {
    success: '',
    error: '',
  },
  updateRubricsById: {
    success: '',
    error: '',
  },
  titleAndCourse: {
    title: '',
    subject_name: '',
    subject_id: '',
    department_id: '',
    subject_code: '',
  },
  questionBankList: {
    data: [],
    error: '',
  },
  questionBankDetails: {
    data: {},
    error: '',
  },
  assessmentDetails: {
    data: {},
    error: '',
  },
  deleteQuestionBank: {
    success: '',
    error: '',
  },
  deleteAssessment: {
    success: '',
    error: '',
  },
  deleteSection: {
    success: '',
    error: '',
  },
  evaluateQuestionBank: {
    success: '',
    error: '',
  },
  evaluateAssessment: {
    success: '',
    error: '',
  },
  sentForApproval: {
    success: '',
    error: '',
  },
  editQuestion: {
    data: {},
    error: '',
  },
  editSection: {
    data: {},
    error: '',
  },
  updateAssessent: {
    data: {},
    error: '',
  },
  deleteQuestion: {
    success: '',
    error: '',
  },
  createAssessment: {
    success: '',
    error: '',
  },
  importQuestion: {
    success: '',
    error: '',
  },
  createSection: {
    success: '',
    error: '',
  },
  assessmentList: {
    data: {},
    error: '',
  },
};

const assessmentRepositoryReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case assessmentRepositoryActions.QUESTION_BANK_LOADER: {
      return {
        ...state,
        questionBankLoader: action.payload,
      };
    }

    case assessmentRepositoryActions.SAVE_TITLE_COURSE: {
      return {
        ...state,
        titleAndCourse: {
          ...state.titleAndCourse,
          title: action.payload.title,
          subject_name: action.payload.subject_name,
          subject_id: action.payload.subject_id,
          department_id: action.payload.department_id,
          subject_code: action.payload.subject_code,
        },
      };
    }

    case createQuestionBank.success: {
      return {
        ...state,
        createQuestionBank: {
          success: action.payload,
          error: '',
        },
      };
    }
    case createQuestionBank.error: {
      return {
        ...state,
        createQuestionBank: {
          success: '',
          error: action.payload,
        },
      };
    }
    case createQuestionBank.reset: {
      return {
        ...state,
        createQuestionBank: INITIAL_STATE.createQuestionBank,
      };
    }
    case createQuestionBankBulkUpload.success: {
      return {
        ...state,
        createQuestionBankBulkUpload: {
          success: action.payload,
          error: '',
        },
      };
    }
    case createQuestionBankBulkUpload.error: {
      return {
        ...state,
        createQuestionBankBulkUpload: {
          success: '',
          error: action.payload,
        },
      };
    }
    case createQuestionBankBulkUpload.reset: {
      return {
        ...state,
        createQuestionBankBulkUpload:
          INITIAL_STATE.createQuestionBankBulkUpload,
      };
    }
    case assessmentRepositoryActions.QUESTION_BANK_RUBRICS_LOADER: {
      return {
        ...state,
        rubricsLoader: action.payload,
      };
    }
    case createRubrics.success: {
      return {
        ...state,
        createRubrics: {
          success: action.payload,
          error: '',
        },
      };
    }
    case createRubrics.error: {
      return {
        ...state,
        createRubrics: {
          success: '',
          error: action.payload,
        },
      };
    }
    case createRubrics.reset: {
      return {
        ...state,
        createRubrics: INITIAL_STATE.createRubrics,
      };
    }
    case listRubrics.success: {
      return {
        ...state,
        listRubrics: {
          data: action.payload,
          error: '',
        },
      };
    }
    case listRubrics.error: {
      return {
        ...state,
        listRubrics: {
          data: [],
          error: action.payload,
        },
      };
    }
    case listRubrics.reset: {
      return {
        ...state,
        listRubrics: INITIAL_STATE.listRubrics,
      };
    }
    case getRubricsById.success: {
      return {
        ...state,
        getRubricsById: {
          data: action.payload,
          error: '',
        },
      };
    }
    case getRubricsById.error: {
      return {
        ...state,
        getRubricsById: {
          data: '',
          error: action.payload,
        },
      };
    }
    case getRubricsById.reset: {
      return {
        ...state,
        getRubricsById: INITIAL_STATE.getRubricsById,
      };
    }
    case deleteRubricsById.success: {
      return {
        ...state,
        deleteRubricsById: {
          success: action.payload,
          error: '',
        },
      };
    }
    case deleteRubricsById.error: {
      return {
        ...state,
        deleteRubricsById: {
          success: '',
          error: action.payload,
        },
      };
    }
    case deleteRubricsById.reset: {
      return {
        ...state,
        deleteRubricsById: INITIAL_STATE.deleteRubricsById,
      };
    }
    case updateRubricsById.success: {
      return {
        ...state,
        updateRubricsById: {
          success: action.payload,
          error: '',
        },
      };
    }
    case updateRubricsById.error: {
      return {
        ...state,
        updateRubricsById: {
          success: '',
          error: action.payload,
        },
      };
    }
    case updateRubricsById.reset: {
      return {
        ...state,
        updateRubricsById: INITIAL_STATE.updateRubricsById,
      };
    }
    case getQuestionBankList.success: {
      return {
        ...state,
        questionBankList: {
          data: action.payload,
          error: '',
        },
      };
    }
    case getQuestionBankList.error: {
      return {
        ...state,
        questionBankList: {
          data: [],
          error: action.payload,
        },
      };
    }
    case getQuestionBankList.reset: {
      return {
        ...state,
        questionBankList: INITIAL_STATE.questionBankList,
      };
    }
    case getQuestionBankDetailsById.success: {
      return {
        ...state,
        questionBankDetails: {
          data: action.payload,
          error: '',
        },
      };
    }
    case getQuestionBankDetailsById.error: {
      return {
        ...state,
        questionBankDetails: {
          data: {},
          error: action.payload,
        },
      };
    }
    case getQuestionBankDetailsById.reset: {
      return {
        ...state,
        questionBankDetails: INITIAL_STATE.questionBankDetails,
      };
    }
    case deleteQuestionBank.success: {
      return {
        ...state,
        deleteQuestionBank: {
          success: action.payload,
          error: '',
        },
      };
    }
    case deleteQuestionBank.error: {
      return {
        ...state,
        deleteQuestionBank: {
          success: '',
          error: action.payload,
        },
      };
    }
    case deleteQuestionBank.reset: {
      return {
        ...state,
        deleteQuestionBank: INITIAL_STATE.deleteQuestionBank,
      };
    }

    case evaluateQuestionBank.success: {
      return {
        ...state,
        evaluateQuestionBank: {
          success: action.payload,
          error: '',
        },
      };
    }

    case evaluateQuestionBank.error: {
      return {
        ...state,
        evaluateQuestionBank: {
          success: '',
          error: action.payload,
        },
      };
    }

    case evaluateQuestionBank.reset: {
      return {
        ...state,
        evaluateQuestionBank: INITIAL_STATE.evaluateQuestionBank,
      };
    }

    case editQuestion.success: {
      return {
        ...state,
        editQuestion: {
          data: action.payload,
          error: '',
        },
      };
    }

    case editQuestion.error: {
      return {
        ...state,
        editQuestion: {
          data: {},
          error: action.payload,
        },
      };
    }

    case editQuestion.reset: {
      return {
        ...state,
        editQuestion: INITIAL_STATE.evaluateQuestionBank,
      };
    }

    case deleteQuestion.success: {
      return {
        ...state,
        deleteQuestion: {
          success: action.payload,
          error: '',
        },
      };
    }
    case deleteQuestion.error: {
      return {
        ...state,
        deleteQuestion: {
          success: '',
          error: action.payload,
        },
      };
    }
    case deleteQuestion.reset: {
      return {
        ...state,
        deleteQuestion: INITIAL_STATE.deleteQuestionBank,
      };
    }

    case createAssessment.success: {
      return {
        ...state,
        createAssessment: {
          success: action.payload,
          error: '',
        },
      };
    }
    case createAssessment.error: {
      return {
        ...state,
        createAssessment: {
          success: '',
          error: action.payload,
        },
      };
    }
    case createAssessment.reset: {
      return {
        ...state,
        createAssessment: INITIAL_STATE.createAssessment,
      };
    }

    case getAssessmentList.success: {
      return {
        ...state,
        assessmentList: {
          data: action.payload,
          error: '',
        },
      };
    }
    case getAssessmentList.error: {
      return {
        ...state,
        assessmentList: {
          data: {},
          error: action.payload,
        },
      };
    }
    case getAssessmentList.reset: {
      return {
        ...state,
        assessmentList: INITIAL_STATE.assessmentList,
      };
    }
    case deleteAssessment.success: {
      return {
        ...state,
        deleteAssessment: {
          success: action.payload,
          error: '',
        },
      };
    }
    case deleteAssessment.error: {
      return {
        ...state,
        deleteAssessment: {
          success: '',
          error: action.payload,
        },
      };
    }
    case deleteAssessment.reset: {
      return {
        ...state,
        deleteAssessment: INITIAL_STATE.deleteAssessment,
      };
    }
    case getAssessmentDetailsById.success: {
      return {
        ...state,
        assessmentDetails: {
          data: action.payload,
          error: '',
        },
      };
    }
    case getAssessmentDetailsById.error: {
      return {
        ...state,
        assessmentDetails: {
          data: {},
          error: action.payload,
        },
      };
    }
    case getAssessmentDetailsById.reset: {
      return {
        ...state,
        assessmentDetails: {
          data: {},
          error: '',
        },
      };
    }
    case editSection.success: {
      return {
        ...state,
        editSection: {
          data: action.payload,
          error: '',
        },
      };
    }

    case editSection.error: {
      return {
        ...state,
        editSection: {
          data: {},
          error: action.payload,
        },
      };
    }

    case editSection.reset: {
      return {
        ...state,
        editSection: INITIAL_STATE.editSection,
      };
    }

    case createSection.success: {
      return {
        ...state,
        createSection: {
          success: action.payload,
          error: '',
        },
      };
    }
    case createSection.error: {
      return {
        ...state,
        createSection: {
          success: '',
          error: action.payload,
        },
      };
    }
    case createSection.reset: {
      return {
        ...state,
        createSection: INITIAL_STATE.createSection,
      };
    }
    case deleteSection.success: {
      return {
        ...state,
        deleteSection: {
          success: action.payload,
          error: '',
        },
      };
    }
    case deleteSection.error: {
      return {
        ...state,
        deleteSection: {
          success: '',
          error: action.payload,
        },
      };
    }
    case deleteSection.reset: {
      return {
        ...state,
        deleteSection: INITIAL_STATE.deleteSection,
      };
    }

    case importQuestion.success: {
      return {
        ...state,
        importQuestion: {
          success: action.payload,
          error: '',
        },
      };
    }
    case importQuestion.error: {
      return {
        ...state,
        importQuestion: {
          success: '',
          error: action.payload,
        },
      };
    }
    case importQuestion.reset: {
      return {
        ...state,
        importQuestion: INITIAL_STATE.importQuestion,
      };
    }

    case sendAssessmentForApproval.success: {
      return {
        ...state,
        sentForApproval: {
          success: action.payload,
          error: '',
        },
      };
    }
    case sendAssessmentForApproval.error: {
      return {
        ...state,
        sentForApproval: {
          success: '',
          error: action.payload,
        },
      };
    }
    case sendAssessmentForApproval.reset: {
      return {
        ...state,
        sentForApproval: INITIAL_STATE.sentForApproval,
      };
    }
    case updateAssessent.success: {
      return {
        ...state,
        updateAssessent: {
          data: action.payload,
          error: '',
        },
      };
    }

    case updateAssessent.error: {
      return {
        ...state,
        updateAssessent: {
          data: {},
          error: action.payload,
        },
      };
    }

    case updateAssessent.reset: {
      return {
        ...state,
        updateAssessent: INITIAL_STATE.updateAssessent,
      };
    }

    case evaluateAssesment.success: {
      return {
        ...state,
        evaluateAssessment: {
          success: action.payload,
          error: '',
        },
      };
    }

    case evaluateAssesment.error: {
      return {
        ...state,
        evaluateAssessment: {
          success: '',
          error: action.payload,
        },
      };
    }

    case evaluateAssesment.reset: {
      return {
        ...state,
        evaluateAssessment: INITIAL_STATE.evaluateAssessment,
      };
    }
    default:
      return state;
  }
};

export default assessmentRepositoryReducer;
