import { all, fork } from 'redux-saga/effects';
import { courseManagementSaga } from 'store/CourseManagement';
import { calendarSaga } from 'store/Calendar';
import { userManagementSaga } from 'store/UserManagement';
import { studentSaga } from 'store/Student';
import { userProfileSaga } from 'store/UserProfile';
import { notificationSaga } from 'store/Notifications';
import settingSaga from './Settings/Settings.saga';
import { feeManagementSaga } from 'store/Fees';
import { discussionBoardSaga } from './DiscussionBoard';
import { gamificationSaga } from './Gamification';
import repositorySaga from './MyRepository/MyRepository.sagas';
import { scheduleSaga } from 'store/Schedule';
import { attendanceSaga } from 'store/Attendance';
import { dashboardSaga } from 'store/Dashboard';
import { evaluationSaga } from 'store/Evaluation';
import { feedbackSaga } from './Feedback';
import { leadSaga } from './Lead';
import { counsellorSaga } from './Counsellor';
import { verifierSaga } from './Verifier';
import { candidateSaga } from './Candidate';
import { prospectusSaga } from './Prospectus';
import { programContentSaga } from './ProgramContent';
import groupChatSaga from './Groupchat/Groupchat.sagas';
import { ExamsSaga } from './Exams';
import { moocAdminSaga } from './MoocAdmin';
import { assessmentRepositorySaga } from './AssessmentRepository';
import { obeSaga } from './Obe';
import { CourseContentSaga } from './CourseContentFeedback';
import { superAdminSaga } from './SuperAdmin';

export default function* rootSaga() {
  yield all([fork(courseManagementSaga)]);
  yield all([fork(userManagementSaga)]);
  yield all([fork(superAdminSaga)]);
  yield all([fork(studentSaga)]);
  yield all([fork(userProfileSaga)]);
  yield all([fork(calendarSaga)]);
  yield all([fork(notificationSaga)]);
  yield all([fork(settingSaga)]);
  yield all([fork(feeManagementSaga)]);
  yield all([fork(discussionBoardSaga)]);
  yield all([fork(gamificationSaga)]);
  yield all([fork(scheduleSaga)]);
  yield all([fork(attendanceSaga)]);
  yield all([fork(dashboardSaga)]);
  yield all([fork(repositorySaga)]);
  yield all([fork(evaluationSaga)]);
  yield all([fork(feedbackSaga)]);
  yield all([fork(leadSaga)]);
  yield all([fork(counsellorSaga)]);
  yield all([fork(verifierSaga)]);
  yield all([fork(candidateSaga)]);
  yield all([fork(prospectusSaga)]);
  yield all([fork(groupChatSaga)]);
  yield all([fork(programContentSaga)]);
  yield all([fork(ExamsSaga)]);
  yield all([fork(moocAdminSaga)]);
  yield all([fork(assessmentRepositorySaga)]);
  yield all([fork(obeSaga)]);
  yield all([fork(CourseContentSaga)]);
}
