import axiosInstance from 'translator';
import { feeManagementAPI } from 'store/Fees';

export const getFeesApi = async (payload) => {
  return axiosInstance
    .get(`${feeManagementAPI?.fees}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postFeesUploadApi = async (data, payload) => {
  return axiosInstance
    .post(`${feeManagementAPI?.createFeesUpload}`, payload, { params: data })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postgetStatusByUsersApi = async (payload) => {
  return axiosInstance
    .post(`${feeManagementAPI?.getStatusByUsers}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postFessEmailRemainderApi = async (payload) => {
  return axiosInstance
    .post(`${feeManagementAPI?.SendFessEmailRemainder}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
