import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  Autocomplete,
  TextField,
  Checkbox,
  Box,
  Chip,
} from 'iiht-b2a-ui-components/build/components';
import { useTheme } from 'iiht-b2a-ui-components/build/components/Styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CancelIcon } from 'icons';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormAutoComplete = React.memo(
  ({
    name,
    rules,
    fullWidth,
    className,
    label,
    size,
    options,
    totalCount,
    handleFetchNext,
    resetSearch,
    multiple,
    isAsterisk,
    onSelectionChange = () => {},
    labelKey = 'label',
    freeSolo = true,
    ...rest
  }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const { getFieldState } = control;
    const [searchUsers, setSearchUsers] = useState('');
    const theme = useTheme();
    const fetchNext = useCallback(() => {
      if (options?.length < totalCount) {
        const offset = options?.length;
        handleFetchNext?.(searchUsers, offset);
      }
    }, [options, totalCount, handleFetchNext, searchUsers]);

    useEffect(() => {
      if ((searchUsers && searchUsers?.length > 2) || searchUsers == '') {
        resetSearch?.(searchUsers);
      }
    }, [searchUsers]);
    return (
      <FormControl
        margin="normal"
        fullWidth={fullWidth}
        className={className}
        size={size}
        variant="standard"
      >
        <Controller
          name={name}
          control={control}
          rules={rules}
          noRef={true}
          render={({ field }) => {
            const { name, value, onChange, onBlur } = field;
            const { invalid, error } = getFieldState(name);
            // Ensure value is an array when multiple is true
            const normalizedValue = multiple
              ? Array.isArray(value)
                ? value
                : []
              : value ?? '';
            return (
              <>
                <Autocomplete
                  freeSolo={freeSolo}
                  name={name}
                  id="free-solo-2-demo"
                  multiple={multiple}
                  disableCloseOnSelect={multiple}
                  label={`${label ?? ''}${isAsterisk ? '*' : ''}`}
                  value={normalizedValue}
                  onChange={(e, val) => {
                    setSearchUsers('');
                    onChange(val);
                    onSelectionChange(val);
                  }}
                  onBlur={onBlur}
                  sx={{
                    '& .MuiAutocomplete-clearIndicator': {
                      color: theme.palette.secondary.light,
                    },
                  }}
                  error={error ? error.message : undefined}
                  aria-invalid={invalid ? 'true' : 'false'}
                  size={size}
                  options={options}
                  autoComplete
                  ListboxProps={{
                    style: {
                      maxHeight: '300px',
                      border: 'secondary',
                      fontSize: '14px',
                    },
                    onScroll: (event) => {
                      const listboxNode = event.currentTarget;
                      if (
                        listboxNode.scrollTop + listboxNode.clientHeight + 10 >=
                        listboxNode.scrollHeight
                      ) {
                        fetchNext();
                      }
                    },
                  }}
                  // renderTags={(selected, getTagProps) => (
                  //   <Box
                  //     sx={{
                  //       display: 'flex',
                  //       flexWrap: 'wrap',
                  //       gap: 0.5,
                  //     }}
                  //   >
                  //     {selected?.map((value, index) => (
                  //       <Chip
                  //         key={value.key}
                  //         label={value.label}
                  //         deleteIcon={
                  //           <CancelIcon
                  //             width="22"
                  //             height="22"
                  //             onMouseDown={(event) => event.stopPropagation()}
                  //           />
                  //         }
                  //         {...getTagProps({ index })}
                  //       />
                  //     ))}
                  //   </Box>
                  // )}
                  // renderOption={(props, option, { selected }) => {
                  //   const { ...optionProps } = props;
                  //   const key = option.id || option[labelKey];
                  //   return (
                  //     <li key={key} {...optionProps}>
                  //       {multiple ? (
                  //         <>
                  //           <Checkbox
                  //             icon={icon}
                  //             checkedIcon={checkedIcon}
                  //             style={{ marginRight: 8 }}
                  //             checked={selected}
                  //           />
                  //           {option[labelKey]}
                  //         </>
                  //       ) : (
                  //         option[labelKey]
                  //       )}
                  //     </li>
                  //   );
                  // }}
                  renderInput={(params) => (
                    <TextField
                      color="secondary"
                      {...params}
                      label={`${label ?? ''}${isAsterisk ? '*' : ''}`}
                      error={error ? true : false}
                      aria-invalid={invalid ? 'true' : 'false'}
                      helperText={error?.message}
                      onChange={(e) => {
                        setSearchUsers(e.target.value);
                      }}
                      sx={{
                        '& .MuiAutocomplete-clearIndicator': {
                          color: theme.palette.grey.P1000,
                        },
                        '& .MuiAutocomplete-tag': {
                          maxWidth: 'calc(100% - 30px)',
                        },
                      }}
                    />
                  )}
                  {...rest}
                />
                {/* <FormHelperText>{error?.message}</FormHelperText> */}
              </>
            );
          }}
        ></Controller>
      </FormControl>
    );
  }
);

export default FormAutoComplete;
