import axiosInstance from 'translator';
import { dashboardAPI } from 'store/Dashboard';

// Registrar

export const getDashboardCardDetailsApi = async (params) => {
  return axiosInstance
    .get(`${dashboardAPI?.getRegistrarDashboardCardDetails}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getEnrolmentProgramListApi = async (params) => {
  return axiosInstance
    .get(`${dashboardAPI?.getEnrolmentProgramList}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDashboardRegistrarChartListApi = async (params) => {
  return axiosInstance
    .get(`${dashboardAPI?.getRegistrarDashboardChartData}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getHRDashboardCardDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI?.getHRDashboardCardDetails}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  //Faculty
};
export const getStudentAttendanceGraphApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI?.getStudentAttendanceGraph}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Student Dashboard Graph
export const studentDashboardGraphApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI?.studentDashboardGraph}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
// Faculty DashBoard
export const facultyDashboardApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI?.getFacultyDashBoard}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentDashboardApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI.StudentDashboardApi}/${payload.userId}`, {
      params: {
        role: payload.role,
        date: payload?.date,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentDashboardTable = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI.StudentDashboardApi}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const getFacultyDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI.StudentDashboardApi}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const getFacultyUserDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI.getFacultyDetails}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
