import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  bulkFileDeleteApi,
  bulkFileShareApi,
  createMyRepositoryApi,
  deleteRecordApi,
  getMyRepositoryApi,
  getUserHandleApi,
  shareFileApi,
  updateDocumentApi,
} from './MyRepository.api';

import {
  bulkDeleteFileError,
  bulkDeleteFileSuccess,
  bulkShareFileError,
  bulkShareFileSuccess,
  createReportDocumentError,
  createReportDocumentSuccess,
  deleteReportDocumentError,
  deleteReportDocumentSuccess,
  getRepositoriesError,
  getRepositoriesSuccess,
  getRepositoryLoading,
  getUserError,
  getUserSuccess,
  shareFileError,
  shareFileSuccess,
  updateReportError,
  updateReportSuccess,
} from './MyRepository.action';

import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';
import { MyRepositoryActions } from './MyRepository.constants';

function* myRepositoryGetListSaga({ payload }) {
  try {
    yield put(getRepositoryLoading(true));
    const response = yield call(getMyRepositoryApi, payload);
    yield put(getRepositoryLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getRepositoriesSuccess(response?.data));
    } else {
      yield put(getRepositoriesError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* reportUploadDocumentSaga({ payload }) {
  const fileUploadSuccess = 'File uploaded';
  const fileUploadFailed = 'Failed to upload file';
  try {
    yield put(getRepositoryLoading(true));
    const response = yield call(createMyRepositoryApi, payload);
    yield put(getRepositoryLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createReportDocumentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: fileUploadSuccess,
          severity: 'success',
        })
      );
    } else {
      yield put(createReportDocumentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? fileUploadFailed,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: response?.data?.error?.message ?? fileUploadFailed,
        severity: 'error',
      })
    );
  }
}

function* deleteDocumentSaga({ payload }) {
  try {
    // yield put(getRepositoryLoading(true));
    const response = yield call(deleteRecordApi, payload);
    // yield put(getRepositoryLoading(false));
    if (
      response?.status === statusCode.Ok200 ||
      response?.status === statusCode.NoContent204
    ) {
      yield put(deleteReportDocumentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'File Deleted',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteReportDocumentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to delete',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to delete',
        severity: 'error',
      })
    );
  }
}

function* myRepositoryBulkShareSaga({ payload }) {
  try {
    yield put(getRepositoryLoading(true));
    const response = yield call(bulkFileShareApi, payload);
    yield put(getRepositoryLoading(false));
    if (response?.status === statusCode?.Ok200) {
      yield put(bulkShareFileSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Files Shared',
          severity: 'success',
        })
      );
    } else {
      yield put(bulkShareFileError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'Error',
        })
      );
    }
  } catch (error) {
    yield put(bulkShareFileError(error));
    yield put(
      setToastMessage({
        data: 'Request Failed',
        severity: 'Error',
      })
    );
  }
}

function* myRepositoryBulkdELETESaga({ payload }) {
  try {
    yield put(getRepositoryLoading(true));
    const response = yield call(bulkFileDeleteApi, payload);
    yield put(getRepositoryLoading(false));
    if (response?.status === statusCode?.Ok200) {
      yield put(bulkDeleteFileSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Files Deleted',
          severity: 'success',
        })
      );
    } else {
      yield put(bulkDeleteFileError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'Error',
        })
      );
    }
  } catch (error) {
    yield put(bulkDeleteFileError(error));
    yield put(
      setToastMessage({
        data: 'Request Failed',
        severity: 'Error',
      })
    );
  }
}
function* myRepositoryGetUserSaga({ payload }) {
  try {
    yield put(getRepositoryLoading(true));
    const response = yield call(getUserHandleApi, payload);
    yield put(getRepositoryLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getUserSuccess(response?.data));
    } else {
      yield put(getUserError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* myRepositoryUpdateSaga({ payload }) {
  try {
    yield put(getRepositoryLoading(true));
    const response = yield call(updateDocumentApi, payload);
    // yield put(getRepositoryLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateReportSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'File Renamed',
          severity: 'success',
        })
      );
    } else {
      yield put(updateReportError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* myRepositoryShareFileSaga({ payload }) {
  try {
    // yield put(getRepositoryLoading(true));
    const response = yield call(shareFileApi, payload);
    // yield put(getRepositoryLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(shareFileSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'File Shared',
          severity: 'success',
        })
      );
    } else {
      yield put(shareFileError(response?.data));
    }
  } catch (error) {
    yield put(shareFileError(error));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'Error',
      })
    );
  }
}

export function* watchDummy() {
  yield takeLatest(
    MyRepositoryActions.MYREPOSITORY.get,
    myRepositoryGetListSaga
  );
  yield takeLatest(
    MyRepositoryActions.MYREPOSITORY.create_Report_Document,
    reportUploadDocumentSaga
  );
  yield takeLatest(MyRepositoryActions.MYREPOSITORY.delete, deleteDocumentSaga);
  yield takeLatest(
    MyRepositoryActions.MYREPOSITORY.get_by_user,
    myRepositoryGetUserSaga
  );
  // yield takeLatest(
  //   MyRepositoryActions.MYREPOSITORY.Send_Email,
  //   myRepositorySendEmailSaga
  // );
  // yield takeLatest(
  //   MyRepositoryActions.MYREPOSITORY.Email_List,
  //   myRepositoryEmailListSaga
  // );
  yield takeLatest(
    MyRepositoryActions.MYREPOSITORY.UPDATE_LIST,
    myRepositoryUpdateSaga
  );
  yield takeLatest(
    MyRepositoryActions.MYREPOSITORY.SHARE_FILE,
    myRepositoryShareFileSaga
  );
  yield takeLatest(
    MyRepositoryActions?.MYREPOSITORY.BULK_SHARE_FILE,
    myRepositoryBulkShareSaga
  );
  yield takeLatest(
    MyRepositoryActions.MYREPOSITORY.BULK_DELETE_FILE,
    myRepositoryBulkdELETESaga
  );
}

export default function* repositorySaga() {
  yield all([watchDummy()]);
}
