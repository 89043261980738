import React, { useRef } from 'react';
import useStyles from './VideoThumbnail.classes';
import { theme } from 'themes';
import {
  Card,
  Typography,
  Grid,
} from 'iiht-b2a-ui-components/build/components';
import { Delete, PlayCircle } from 'icons';
import ReactPlayer from 'react-player';

const VideoThumbnail = ({
  fileUrl,
  fileName,
  auto,
  showDelete,
  onDelete,
  fileId,
  className,
  ...rest
}) => {
  const classes = useStyles(theme);
  const playerRef = useRef();
  const [isPlaying, setIsPlaying] = React.useState(false);

  return (
    <div style={{ height: '100%' }} className="text-ellipses">
      <Card
        className={`
        ${auto ? classes.videoThumbnailAuto : classes.videoThumbnail}
        display-flex
        justify-center items-center ${className}`}
      >
        <ReactPlayer
          playing={isPlaying}
          ref={playerRef}
          url={fileUrl}
          width={'100%'}
          height={'100%'}
          controls={true}
          playIcon={
            <PlayCircle
              fill={theme.palette.primary.main}
              onClick={() => setIsPlaying(true)}
            />
          }
          onPause={() => {
            setIsPlaying(false);
          }}
          {...rest}
        />
      </Card>
      <Grid container className="display-flex justify-space-between">
        <Grid
          item
          xs={10}
          xm={10}
          md={10}
          lg={10}
          xl={10}
          className="text-ellipses"
        >
          <Typography color="grey.P1000" variant="caption">
            {fileName}
          </Typography>
        </Grid>

        {(showDelete && (
          <Grid
            item
            xs={2}
            xm={2}
            md={2}
            lg={2}
            xl={2}
            onClick={() => onDelete({ fileName: fileName, fileId: fileId })}
          >
            <Delete
              height="50%"
              className="mouse-pointer"
              color={theme.palette.secondary.main}
            />
          </Grid>
        )) ||
          null}
      </Grid>
    </div>
  );
};

export default VideoThumbnail;
