import { all, call, put, takeLatest } from 'redux-saga/effects';
import { MoocAdminActions } from './MoocAdmin.constants';
import {
  affiliateOrPublishMoocCourseApi,
  getAffiliatedCoursesApi,
  getCourseDetailsApi,
  getCoursesToAffiliateApi,
  getCoursesToPublishApi,
  getMoocAdminStatsApi,
  getPublishedCoursesApi,
  getMoocAdminFilterApi,
  getMoocTypeAPi,
  enrollMoocCourseAPi,
  getAllCoursesApi,
  updateCourseDetailsApi,
  deleteCourseDetailsApi,
  updateMookSettingApi,
  getMoocSettingApi,
  createMoocCourseCertificateApi,
  uploadFileApi,
  getEnrolledCourseApi,
  updateCourseStatusApi,
  getMoocCourseStatusApi,
  moocAdminDashboardApi,
  getStudentListByCourseIdApi,
  getAssessmentListRequestApi,
  getVideoNotesRequestApi,
  createVideoNotesApi,
  downloadCertificateApi,
  learningPathToPublishApi,
  publishLearningPathApi,
  getLearningPathApi,
  getCourseListDropDownApi,
  createLPApi,
  updateLearningPathStatusApi,
  myLearningPathApi,
  enrollLearningPathAPI,
  myLearningPathByIdApi,
  getLpCourseStatusApi,
  getLearningPathByIdApi,
  editLPApi,
} from './MoocAdmin.api';
import {
  affiliateMoocCourseError,
  affiliateMoocCourseSuccess,
  affiliatePublishMoocCourseLoader,
  publishMoocCourseError,
  publishMoocCourseSuccess,
  setAffiliatedCoursesError,
  setAffiliatedCoursesSuccess,
  setCourseDetailsError,
  setCourseDetailsSuccess,
  setCoursesToAffiliateError,
  setCoursesToAffiliateSuccess,
  setCoursesToPublishError,
  setCoursesToPublishSuccess,
  setMookAdminCoursesListLoader,
  setMookAdminDashboardLoader,
  setMookAdminStatsError,
  setMookAdminStatsSuccess,
  setPublishedCoursesError,
  setPublishedCoursesSuccess,
  setMoocAdminFilterError,
  setMoocAdminFilterSuccess,
  setMoocTypeSuccess,
  setMoocTypeError,
  setEnrollMoocCourseSuccess,
  setEnrollMoocCourseError,
  setAllCoursesSuccess,
  setAllCoursesError,
  setAllCoursesLoader,
  updateCourseDetailsSuccess,
  updateCourseDetailsError,
  deleteCourseDetailsError,
  deleteCourseDetailsSuccess,
  updateMoocSettingSuccess,
  updateMoocSettingError,
  setMoocSettingSuccess,
  setMoocSettingError,
  createMoocCourseCertificateSuccess,
  createMoocCourseCertificateError,
  uploadFileSuccess,
  uploadFileeError,
  setEnrolledCoursesSuccess,
  setEnrolledCoursesError,
  setEnrolledCoursesLoader,
  setupdateCourseStatusSuccess,
  setupdateCourseStatusError,
  setMoocCourseStatusSuccess,
  setMoocCourseStatusError,
  setMoocAdminDashboardSuccess,
  setMoocAdminDashboardError,
  setDashboardMoocTableLoading,
  setStudentListByCourseIdSuccess,
  setStudentListByCourseIdError,
  assessmentLinkRequestActionError,
  assessmentLinkRequestActionSuccess,
  getVideoNotesRequestSuccess,
  getVideoNotesRequestError,
  createVideoNotesSuccess,
  createVideoNotesError,
  downloadCertificateSuccess,
  downloadCertificateError,
  learningPathToPublishSuccess,
  learningPathToPublishError,
  publishedLearningPathSuccess,
  publishedLearningPathError,
  setLearningPathSuccess,
  setLearningPathError,
  setLearningPathLoader,
  getCourseListDropdownSuccess,
  getCourseListDropdownError,
  createLpSuccess,
  createLpError,
  setUpdateLearningStatusSuccess,
  setUpdateLearningStatusError,
  setUpdateLearningPathLoader,
  setMyLearningPathSuccess,
  setMyLearningPathError,
  enrollLearningPathSuccess,
  enrollLearningPathError,
  setCourseByLPIdSuccess,
  setCourseByLPIdError,
  setLpCourseStatusError,
  setLpCourseStatusSuccess,
  setMookAdminCoursesFilterLoader,
  getLPByIdSuccess,
  getLPByIdError,
  editLpSuccess,
  editLpError,
  uploadFileSchemaSuccess,
  uploadFileSchemaeError,
} from './MoocAdmin.action';
import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';
import { localStorageHelper } from 'utils';

function* getMoocAdminStatsSaga({ payload }) {
  try {
    yield put(setMookAdminDashboardLoader(true));
    const response = yield call(getMoocAdminStatsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setMookAdminStatsSuccess(response?.data));
    } else {
      yield put(setMookAdminStatsError(response?.data));
    }
    yield put(setMookAdminDashboardLoader(false));
  } catch (error) {
    yield put(setMookAdminDashboardLoader(false));
    yield put(setMookAdminStatsError(error));
  }
}

function* getAffiliatedCoursesSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    yield put(setMookAdminDashboardLoader(true));
    const response = yield call(getAffiliatedCoursesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setAffiliatedCoursesSuccess(response?.data));
    } else {
      yield put(setAffiliatedCoursesError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
    yield put(setMookAdminDashboardLoader(false));
  } catch (error) {
    yield put(setMookAdminCoursesListLoader(false));
    yield put(setMookAdminDashboardLoader(false));
    yield put(setAffiliatedCoursesError(error));
  }
}

function* getCoursesToAffiliateSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(getCoursesToAffiliateApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setCoursesToAffiliateSuccess(response?.data));
    } else {
      yield put(setCoursesToAffiliateError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(setMookAdminCoursesListLoader(false));
    yield put(setCoursesToAffiliateError(error));
  }
}

function* getCoursesToPublishSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(getCoursesToPublishApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setCoursesToPublishSuccess(response?.data));
    } else {
      yield put(setCoursesToPublishError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(setMookAdminCoursesListLoader(false));
    yield put(setCoursesToPublishError(error));
  }
}

function* getPublishedCoursesSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(getPublishedCoursesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setPublishedCoursesSuccess(response?.data));
    } else {
      yield put(setPublishedCoursesError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(setMookAdminCoursesListLoader(false));
    yield put(setPublishedCoursesError(error));
  }
}

function* getCourseDetailsSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(getCourseDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setCourseDetailsSuccess(response?.data));
    } else {
      yield put(setCourseDetailsError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(setMookAdminCoursesListLoader(false));
    yield put(setCourseDetailsError(error));
  }
}

function* affiliateMoocCourseSaga({ payload }) {
  try {
    yield put(affiliatePublishMoocCourseLoader(true));
    const response = yield call(affiliateOrPublishMoocCourseApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(affiliateMoocCourseSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(affiliateMoocCourseError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(affiliatePublishMoocCourseLoader(false));
  } catch (error) {
    yield put(affiliatePublishMoocCourseLoader(false));
    yield put(affiliateMoocCourseError(error));
  }
}

function* publishMoocCourseSaga({ payload }) {
  try {
    yield put(affiliatePublishMoocCourseLoader(true));
    const response = yield call(affiliateOrPublishMoocCourseApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(publishMoocCourseSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(publishMoocCourseError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(affiliatePublishMoocCourseLoader(false));
  } catch (error) {
    yield put(affiliatePublishMoocCourseLoader(false));
    yield put(publishMoocCourseError(error));
  }
}

function* getMoocAdminFilterSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesFilterLoader(true));
    const response = yield call(getMoocAdminFilterApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setMoocAdminFilterSuccess(response?.data));
    } else {
      yield put(setMoocAdminFilterError(response?.data));
    }
    yield put(setMookAdminCoursesFilterLoader(false));
  } catch (error) {
    yield put(setMookAdminCoursesFilterLoader(false));
    yield put(setMoocAdminFilterError(error));
  }
}

function* getMoocTypeSaga({ payload }) {
  try {
    const response = yield call(getMoocTypeAPi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setMoocTypeSuccess(response?.data));
      response?.data?.tenant_type === 'SENSITIVE' &&
        localStorageHelper.set('moocToken', response.data?.token);
    } else {
      yield put(setMoocTypeError(response?.data));
    }
  } catch (error) {
    yield put(setMoocTypeError(error));
  }
}

function* enrollMoocCourseSaga({ payload }) {
  try {
    yield put(affiliatePublishMoocCourseLoader(true));
    const response = yield call(enrollMoocCourseAPi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(setEnrollMoocCourseSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(setEnrollMoocCourseError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(affiliatePublishMoocCourseLoader(false));
  } catch (error) {
    yield put(affiliatePublishMoocCourseLoader(false));
    yield put(setEnrollMoocCourseError(error));
  }
}

//Get All Courses
function* getAllCoursesSaga({ payload }) {
  try {
    yield put(setAllCoursesLoader(true));
    const response = yield call(getAllCoursesApi, payload);
    yield put(setAllCoursesLoader(false));
    if (response?.status === statusCode.Ok200) {
      yield put(setAllCoursesSuccess(response?.data));
    } else {
      yield put(setAllCoursesError(response?.data));
    }
  } catch (error) {
    yield put(setAllCoursesLoader(false));
    yield put(setAllCoursesError(error));
  }
}

//Update course details by ID
function* updateCourseDetailsSaga({ payload }) {
  try {
    const response = yield call(updateCourseDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateCourseDetailsSuccess(response?.data));
    }
  } catch (error) {
    yield put(updateCourseDetailsError(error));
  }
}

//Delete course details by ID
function* deleteCourseDetailsSaga({ payload }) {
  try {
    const response = yield call(deleteCourseDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteCourseDetailsSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(deleteCourseDetailsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}
//Update Mooc setting
function* updateMoocSettingSaga({ payload }) {
  try {
    yield put(setMookAdminDashboardLoader(true));
    const response = yield call(updateMookSettingApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateMoocSettingSuccess(response?.data));
    } else {
      yield put(updateMoocSettingError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setMookAdminDashboardLoader(false));
  } catch (error) {
    yield put(updateMoocSettingError(error));
    yield put(setMookAdminDashboardLoader(false));
  }
}

//Get course details by ID
function* getMoocSettingSaga({ payload }) {
  try {
    yield put(setMookAdminDashboardLoader(true));
    const response = yield call(getMoocSettingApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setMoocSettingSuccess(response?.data));
    } else {
      yield put(setMoocSettingError(response?.data));
    }
    yield put(setMookAdminDashboardLoader(false));
  } catch (error) {
    yield put(setMoocSettingError(error));
    yield put(setMookAdminDashboardLoader(false));
  }
}
//Create Mooc Course Certificate
function* createMoocCourseCertificateSaga({ payload }) {
  try {
    yield put(setMookAdminDashboardLoader(true));
    const response = yield call(createMoocCourseCertificateApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createMoocCourseCertificateSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(createMoocCourseCertificateError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setMookAdminDashboardLoader(false));
  } catch (error) {
    yield put(createMoocCourseCertificateError(error));
    yield put(setMookAdminDashboardLoader(false));
  }
}

//Upload File upload
function* uploadFileSaga({ payload }) {
  try {
    const response = yield call(uploadFileApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(uploadFileSuccess(response.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(uploadFileeError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(uploadFileeError(error));
  }
}
function* uploadFileScehmaSaga({ payload }) {
  try {
    const response = yield call(uploadFileApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(uploadFileSchemaSuccess(response.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(uploadFileSchemaeError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(uploadFileSchemaeError(error));
  }
}
//Get Enrolled Courses
function* getEnrolledCoursesSaga({ payload }) {
  try {
    yield put(setEnrolledCoursesLoader(true));
    const response = yield call(getEnrolledCourseApi, payload);
    yield put(setEnrolledCoursesLoader(false));
    if (response?.status === statusCode.Ok200) {
      yield put(setEnrolledCoursesSuccess(response?.data));
    } else {
      yield put(setEnrolledCoursesError(response?.data));
    }
  } catch (error) {
    yield put(setEnrolledCoursesLoader(false));
    yield put(setEnrolledCoursesError(error));
  }
}

function* updateCourseStatusSaga({ payload }) {
  try {
    const response = yield call(updateCourseStatusApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setupdateCourseStatusSuccess(response?.data));
    } else {
      yield put(setupdateCourseStatusError(response?.data));
    }
  } catch (error) {
    yield put(setupdateCourseStatusError(error));
  }
}

function* getMoocCourseStatusSaga({ payload }) {
  try {
    const response = yield call(getMoocCourseStatusApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setMoocCourseStatusSuccess(response?.data));
    } else {
      yield put(setMoocCourseStatusError(response?.data));
    }
  } catch (error) {
    yield put(setMoocCourseStatusError(error));
  }
}
function* moocAdminDashboardSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(moocAdminDashboardApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setMoocAdminDashboardSuccess(response?.data));
    } else {
      yield put(setMoocAdminDashboardError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(setMookAdminCoursesListLoader(false));
    yield put(setMoocAdminDashboardError(error));
  }
}

function* getStudentListByCourseIdSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(getStudentListByCourseIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setStudentListByCourseIdSuccess(response?.data));
    } else {
      yield put(setStudentListByCourseIdError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(setMookAdminCoursesListLoader(false));
    yield put(setStudentListByCourseIdError(error));
  }
}

function* moocAdminDashboardLoadingSaga({ payload }) {
  try {
    yield put(setDashboardMoocTableLoading(true));
    const response = yield call(moocAdminDashboardApi, payload);
    yield put(setDashboardMoocTableLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(setMoocAdminDashboardSuccess(response?.data));
    } else {
      yield put(setMoocAdminDashboardError(response?.data));
    }
  } catch (error) {
    yield put(setDashboardMoocTableLoading(false));
    yield put(setMoocAdminDashboardError(error));
  }
}

function* getAssessmentLinkRequestSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(getAssessmentListRequestApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(assessmentLinkRequestActionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(assessmentLinkRequestActionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(assessmentLinkRequestActionError(error));
  }
}

function* getVideoNotesRequestSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(getVideoNotesRequestApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getVideoNotesRequestSuccess(response?.data));
    } else {
      yield put(getVideoNotesRequestError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(getVideoNotesRequestError(error));
  }
}

function* createVideoNotesRequestSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(createVideoNotesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(createVideoNotesSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(createVideoNotesError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(createVideoNotesError(error));
  }
}

function* downloadCertificateSaga({ payload }) {
  try {
    yield put(setEnrolledCoursesLoader(true));
    const response = yield call(downloadCertificateApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(downloadCertificateSuccess(response?.data));
    } else {
      yield put(downloadCertificateError(response?.data));
    }
    yield put(setEnrolledCoursesLoader(false));
  } catch (error) {
    yield put(downloadCertificateError(error));
    yield put(setEnrolledCoursesLoader(false));
  }
}

function* learningPathToPublishSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(learningPathToPublishApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(learningPathToPublishSuccess(response?.data));
    } else {
      yield put(learningPathToPublishError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(learningPathToPublishError(error));
    yield put(setMookAdminCoursesListLoader(false));
  }
}

function* publishedLearningPathSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(publishLearningPathApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(publishedLearningPathSuccess(response?.data));
    } else {
      yield put(publishedLearningPathError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(publishedLearningPathError(error));
    yield put(setMookAdminCoursesListLoader(false));
  }
}
function* getLearningPathSaga({ payload }) {
  try {
    yield put(setLearningPathLoader(true));
    const response = yield call(getLearningPathApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setLearningPathSuccess(response?.data));
    } else {
      yield put(setLearningPathError(response?.data));
    }
    yield put(setLearningPathLoader(false));
  } catch (error) {
    yield put(setLearningPathLoader(false));
    yield put(setLearningPathError(error));
  }
}

function* getCourseListDropdownSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(getCourseListDropDownApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseListDropdownSuccess(response?.data));
    } else {
      yield put(getCourseListDropdownError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(getCourseListDropdownError(error));
    yield put(setMookAdminCoursesListLoader(false));
  }
}

function* createLpSaga({ payload }) {
  try {
    yield put(setMookAdminDashboardLoader(true));
    const response = yield call(createLPApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createLpSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(createLpError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setMookAdminDashboardLoader(false));
  } catch (error) {
    yield put(createLpError(error));
    yield put(setMookAdminDashboardLoader(false));
  }
}

function* updateLearningPathSaga({ payload }) {
  try {
    const response = yield call(updateLearningPathStatusApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setUpdateLearningStatusSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(setUpdateLearningStatusError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(setUpdateLearningStatusError(error));
  }
}

function* getMyLearningPathSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(myLearningPathApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setMyLearningPathSuccess(response?.data));
    } else {
      yield put(setMyLearningPathError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(setMyLearningPathError(error));
    yield put(setMookAdminCoursesListLoader(false));
  }
}

function* enrollLearningPathSaga({ payload }) {
  try {
    yield put(setMookAdminDashboardLoader(true));
    const response = yield call(enrollLearningPathAPI, payload);
    if (response?.status === statusCode.Created201) {
      yield put(enrollLearningPathSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(enrollLearningPathError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setMookAdminDashboardLoader(false));
  } catch (error) {
    yield put(enrollLearningPathError(error));
    yield put(setMookAdminDashboardLoader(false));
  }
}

function* getCourseByLpIdSaga({ payload }) {
  try {
    yield put(setMookAdminCoursesListLoader(true));
    const response = yield call(myLearningPathByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setCourseByLPIdSuccess(response?.data));
    } else {
      yield put(setCourseByLPIdError(response?.data));
    }
    yield put(setMookAdminCoursesListLoader(false));
  } catch (error) {
    yield put(setCourseByLPIdError(error));
    yield put(setMookAdminCoursesListLoader(false));
  }
}

function* getLpCourseStatusSaga({ payload }) {
  try {
    const response = yield call(getLpCourseStatusApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setLpCourseStatusSuccess(response?.data));
    } else {
      yield put(setLpCourseStatusError(response?.data));
    }
  } catch (error) {
    yield put(setLpCourseStatusError(error));
  }
}

function* getLpByIdSaga({ payload }) {
  try {
    const response = yield call(getLearningPathByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getLPByIdSuccess(response?.data));
    } else {
      yield put(getLPByIdError(response?.data));
    }
  } catch (error) {
    yield put(getLPByIdError(error));
  }
}

function* editLearningPathSaga({ payload }) {
  try {
    const response = yield call(editLPApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(editLpSuccess(response?.data));
      yield put(
        setToastMessage({
          severity: 'success',
        })
      );
    } else {
      yield put(editLpError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message?.lp_name,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(editLpError(error));
  }
}

export function* watchDummy() {
  yield takeLatest(
    MoocAdminActions.GET_MOOC_ADMIN_STATS,
    getMoocAdminStatsSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_AFFILIATED_COURSES,
    getAffiliatedCoursesSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_COURSES_TO_AFFILIATE,
    getCoursesToAffiliateSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_COURSES_TO_PUBLISH,
    getCoursesToPublishSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_PUBLISHED_COURSES,
    getPublishedCoursesSaga
  );
  yield takeLatest(MoocAdminActions.GET_COURSE_DETAILS, getCourseDetailsSaga);
  yield takeLatest(
    MoocAdminActions.AFFILIATE_MOOC_COURSE,
    affiliateMoocCourseSaga
  );
  yield takeLatest(MoocAdminActions.PUBLISH_MOOC_COURSE, publishMoocCourseSaga);
  yield takeLatest(
    MoocAdminActions.GET_MOOC_ADMIN_FILTER,
    getMoocAdminFilterSaga
  );
  yield takeLatest(MoocAdminActions.GET_MOOC_TYPE, getMoocTypeSaga);
  yield takeLatest(MoocAdminActions.ENROLL_MOOC_COURSE, enrollMoocCourseSaga);
  //Get All course
  yield takeLatest(MoocAdminActions.GET_All_COURSES, getAllCoursesSaga);

  //Update course details
  yield takeLatest(
    MoocAdminActions.UPDATE_COURSE_DETAILS,
    updateCourseDetailsSaga
  );

  //Delete course details
  yield takeLatest(
    MoocAdminActions.DELETE_COURSE_DETAILS,
    deleteCourseDetailsSaga
  );
  //Update mooc setting
  yield takeLatest(MoocAdminActions.UPDATE_MOOC_SETTING, updateMoocSettingSaga);

  //Get mooc setting
  yield takeLatest(MoocAdminActions.GET_MOOC_SETTING, getMoocSettingSaga);

  //Create Mooc Course Certificate
  yield takeLatest(
    MoocAdminActions.CREATE_MOOC_COURSE_CERTIFICATE,
    createMoocCourseCertificateSaga
  );

  //Upload file for mooc setting
  yield takeLatest(MoocAdminActions.UPLOAD_FILE, uploadFileSaga);
  yield takeLatest(MoocAdminActions.UPLOAD_FILE_SCHEMA, uploadFileScehmaSaga);

  yield takeLatest(
    MoocAdminActions.GET_ENROLLED_COURSES,
    getEnrolledCoursesSaga
  );

  yield takeLatest(
    MoocAdminActions.UPDATE_COURSE_STATUS,
    updateCourseStatusSaga
  );

  yield takeLatest(
    MoocAdminActions.GET_MOOC_COURSE_STATUS,
    getMoocCourseStatusSaga
  );
  yield takeLatest(
    MoocAdminActions.MOOC_ADMIN_DASHBOARD,
    moocAdminDashboardSaga
  );
  yield takeLatest(
    MoocAdminActions.MOOC_ADMIN_DASHBOARD,
    moocAdminDashboardLoadingSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_STUDENT_LIST_BY_COURSE_ID,
    getStudentListByCourseIdSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_ASSESSMENT_LINK_REQUEST,
    getAssessmentLinkRequestSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_VIDEO_NOTES_REQUEST,
    getVideoNotesRequestSaga
  );
  yield takeLatest(
    MoocAdminActions.CREATE_VIDEO_NOTES_REQUEST,
    createVideoNotesRequestSaga
  );
  yield takeLatest(
    MoocAdminActions.DOWNLOAD_CERTIFICATE_REQUEST,
    downloadCertificateSaga
  );
  yield takeLatest(
    MoocAdminActions.LEARNING_PATH_TO_PUBLISH,
    learningPathToPublishSaga
  );
  yield takeLatest(
    MoocAdminActions.PUBLISHED_LEARNING_PATH,
    publishedLearningPathSaga
  );
  yield takeLatest(MoocAdminActions.GET_LEARNING_PATH, getLearningPathSaga);
  yield takeLatest(
    MoocAdminActions.GET_COURSE_LIST_DROPDOWN,
    getCourseListDropdownSaga
  );
  yield takeLatest(MoocAdminActions.CREATE_LEARNING_PATH, createLpSaga);
  yield takeLatest(
    MoocAdminActions.UPDATE_LEARNING_PATH,
    updateLearningPathSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_MY_LEARNING_PATH,
    getMyLearningPathSaga
  );
  yield takeLatest(
    MoocAdminActions.ENROLL_LEARNING_PATH,
    enrollLearningPathSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_COURSE_LIST_BY_LP_ID,
    getCourseByLpIdSaga
  );
  yield takeLatest(
    MoocAdminActions.GET_LP_COURSE_STATUS,
    getLpCourseStatusSaga
  );
  yield takeLatest(MoocAdminActions.GET_LP_BY_ID, getLpByIdSaga);
  yield takeLatest(MoocAdminActions.EDIT_LEARNING_PATH, editLearningPathSaga);
}

export default function* moocAdminSaga() {
  yield all([watchDummy()]);
}
