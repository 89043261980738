import { MoocAdminActions } from './MoocAdmin.constants';

export const setMookAdminDashboardLoader = (payload) => ({
  type: MoocAdminActions.MOOC_ADMIN_DASHBOARD_LOADER,
  payload,
});

export const getMookAdminStats = (payload) => ({
  type: MoocAdminActions.GET_MOOC_ADMIN_STATS,
  payload,
});

export const setMookAdminStatsSuccess = (payload) => ({
  type: MoocAdminActions.SET_MOOC_ADMIN_STATS_SUCCESS,
  payload,
});

export const setMookAdminStatsError = (payload) => ({
  type: MoocAdminActions.SET_MOOC_ADMIN_STATS_ERROR,
  payload,
});

export const resetMookAdminStats = () => ({
  type: MoocAdminActions.RESET_MOOC_ADMIN_STATS,
});

export const getAffiliatedCourses = (payload) => ({
  type: MoocAdminActions.GET_AFFILIATED_COURSES,
  payload,
});

export const setAffiliatedCoursesSuccess = (payload) => ({
  type: MoocAdminActions.SET_AFFILIATED_COURSES_SUCCESS,
  payload,
});

export const setAffiliatedCoursesError = (payload) => ({
  type: MoocAdminActions.SET_AFFILIATED_COURSES_ERROR,
  payload,
});

export const setMookAdminCoursesListLoader = (payload) => ({
  type: MoocAdminActions.MOOC_ADMIN_COURSES_LIST_LOADER,
  payload,
});

export const setMookAdminCoursesFilterLoader = (payload) => ({
  type: MoocAdminActions.MOOC_ADMIN_COURSES_FILTER_LOADER,
  payload,
});

export const getCoursesToAffiliate = (payload) => ({
  type: MoocAdminActions.GET_COURSES_TO_AFFILIATE,
  payload,
});

export const setCoursesToAffiliateSuccess = (payload) => ({
  type: MoocAdminActions.SET_COURSES_TO_AFFILIATE_SUCCESS,
  payload,
});

export const setCoursesToAffiliateError = (payload) => ({
  type: MoocAdminActions.SET_COURSES_TO_AFFILIATE_ERROR,
  payload,
});

export const resetCoursesList = () => ({
  type: MoocAdminActions.RESET_COURSES_LIST,
});

export const getCoursesToPublish = (payload) => ({
  type: MoocAdminActions.GET_COURSES_TO_PUBLISH,
  payload,
});

export const setCoursesToPublishSuccess = (payload) => ({
  type: MoocAdminActions.SET_COURSES_TO_PUBLISH_SUCCESS,
  payload,
});

export const setCoursesToPublishError = (payload) => ({
  type: MoocAdminActions.SET_COURSES_TO_PUBLISH_ERROR,
  payload,
});

export const getPublishedCourses = (payload) => ({
  type: MoocAdminActions.GET_PUBLISHED_COURSES,
  payload,
});

export const setPublishedCoursesSuccess = (payload) => ({
  type: MoocAdminActions.SET_PUBLISHED_COURSES_SUCCESS,
  payload,
});

export const setPublishedCoursesError = (payload) => ({
  type: MoocAdminActions.SET_PUBLISHED_COURSES_ERROR,
  payload,
});

export const getCourseDetails = (payload) => ({
  type: MoocAdminActions.GET_COURSE_DETAILS,
  payload,
});

export const setCourseDetailsSuccess = (payload) => ({
  type: MoocAdminActions.SET_COURSE_DETAILS_SUCCESS,
  payload,
});

export const setCourseDetailsError = (payload) => ({
  type: MoocAdminActions.SET_COURSE_DETAILS_ERROR,
  payload,
});

export const resetCourseDetails = () => ({
  type: MoocAdminActions.RESET_COURSE_DETAILS,
});

export const affiliatePublishMoocCourseLoader = (payload) => ({
  type: MoocAdminActions.AFFILIATE_PUBLISH_MOOC_COURSE_LOADER,
  payload,
});

export const affiliateMoocCourseAction = (payload) => ({
  type: MoocAdminActions.AFFILIATE_MOOC_COURSE,
  payload,
});

export const affiliateMoocCourseSuccess = (payload) => ({
  type: MoocAdminActions.AFFILIATE_MOOC_COURSE_SUCCESS,
  payload,
});

export const affiliateMoocCourseError = (payload) => ({
  type: MoocAdminActions.AFFILIATE_MOOC_COURSE_ERROR,
  payload,
});

export const resetAffiliateMoocCourse = () => ({
  type: MoocAdminActions.RESET_AFFILIATE_MOOC_COURSE,
});

export const publishMoocCourseAction = (payload) => ({
  type: MoocAdminActions.PUBLISH_MOOC_COURSE,
  payload,
});

export const publishMoocCourseSuccess = (payload) => ({
  type: MoocAdminActions.PUBLISH_MOOC_COURSE_SUCCESS,
  payload,
});

export const publishMoocCourseError = (payload) => ({
  type: MoocAdminActions.PUBLISH_MOOC_COURSE_ERROR,
  payload,
});

export const resetPublishMoocCourse = () => ({
  type: MoocAdminActions.RESET_PUBLISH_MOOC_COURSE,
});
export const getMoocAdminFilter = (payload) => ({
  type: MoocAdminActions.GET_MOOC_ADMIN_FILTER,
  payload,
});

export const setMoocAdminFilterSuccess = (payload) => ({
  type: MoocAdminActions.SET_MOOC_ADMIN_FILTER_SUCCESS,
  payload,
});

export const setMoocAdminFilterError = (payload) => ({
  type: MoocAdminActions.SET_MOOC_ADMIN_FILTER_ERROR,
  payload,
});

export const resetMoocAdminFilter = () => ({
  type: MoocAdminActions.RESET_MOOC_ADMIN_FILTER,
});

export const getMoocType = (payload) => ({
  type: MoocAdminActions.GET_MOOC_TYPE,
  payload,
});

export const setMoocTypeSuccess = (payload) => ({
  type: MoocAdminActions.SET_MOOC_TYPE_SUCCESS,
  payload,
});

export const setMoocTypeError = (payload) => ({
  type: MoocAdminActions.SET_MOOC_TYPE_ERROR,
  payload,
});

export const enrollMoocCourse = (payload) => ({
  type: MoocAdminActions.ENROLL_MOOC_COURSE,
  payload,
});

export const setEnrollMoocCourseSuccess = (payload) => ({
  type: MoocAdminActions.SET_ENROLL_MOOC_COURSE_SUCCESS,
  payload,
});

export const setEnrollMoocCourseError = (payload) => ({
  type: MoocAdminActions.SET_ENROLL_MOOC_COURSE_ERROR,
  payload,
});

export const resetEnrollMoocCourse = () => ({
  type: MoocAdminActions.RESET_ENROLL_MOOC_COURSE,
});

//Get All Courses
export const getAllCourses = (payload) => ({
  type: MoocAdminActions.GET_All_COURSES,
  payload,
});

export const setAllCoursesSuccess = (payload) => ({
  type: MoocAdminActions.SET_GET_All_COURSES_SUCCESS,
  payload,
});

export const setAllCoursesError = (payload) => ({
  type: MoocAdminActions.SET_GET_All_COURSES_ERROR,
  payload,
});
export const setAllCoursesLoader = (payload) => ({
  type: MoocAdminActions.SET_COURSES_LOADER,
  payload,
});

//Update course details
export const updateCourseDetails = (payload) => ({
  type: MoocAdminActions.UPDATE_COURSE_DETAILS,
  payload,
});

export const updateCourseDetailsSuccess = (payload) => ({
  type: MoocAdminActions.UPDATE_COURSE_DETAILS_SUCCESS,
  payload,
});

export const updateCourseDetailsError = (payload) => ({
  type: MoocAdminActions.UPDATE_COURSE_DETAILS_ERROR,
  payload,
});

//delete course details
export const deleteCourseDetails = (payload) => ({
  type: MoocAdminActions.DELETE_COURSE_DETAILS,
  payload,
});

export const deleteCourseDetailsSuccess = (payload) => ({
  type: MoocAdminActions.DELETE_COURSE_DETAILS_SUCCESS,
  payload,
});

export const deleteCourseDetailsError = (payload) => ({
  type: MoocAdminActions.DELETE_COURSE_DETAILS_ERROR,
  payload,
});
//Update Mooc Setting
export const updateMoocSetting = (payload, id) => ({
  type: MoocAdminActions.UPDATE_MOOC_SETTING,
  id: id,
  payload,
});

export const updateMoocSettingSuccess = (payload) => ({
  type: MoocAdminActions.UPDATE_MOOC_SETTING_SUCCESS,
  payload,
});

export const updateMoocSettingError = (payload) => ({
  type: MoocAdminActions.UPDATE_MOOC_SETTING_ERROR,
  payload,
});

//Get Mooc Setting
export const getMoocSetting = (payload) => ({
  type: MoocAdminActions.GET_MOOC_SETTING,
  payload,
});

export const setMoocSettingSuccess = (payload) => ({
  type: MoocAdminActions.SET_MOOC_SETTING_SUCCESS,
  payload,
});

export const setMoocSettingError = (payload) => ({
  type: MoocAdminActions.SET_MOOC_SETTING_ERROR,
  payload,
});

//Create Mooc Course certificate

export const createMoocCourseCertificate = (payload) => ({
  type: MoocAdminActions.CREATE_MOOC_COURSE_CERTIFICATE,
  payload: payload,
});

export const createMoocCourseCertificateSuccess = (payload) => ({
  type: MoocAdminActions.CREATE_MOOC_COURSE_CERTIFICATE_SUCCESS,
  payload,
});

export const createMoocCourseCertificateError = (payload) => ({
  type: MoocAdminActions.CREATE_MOOC_COURSE_CERTIFICATE_ERROR,
  payload,
});

export const resetCreateMoocCourseCertificate = () => ({
  type: MoocAdminActions.RESET_CREATE_MOOC_COURSE_CERTIFICATE,
});

export const uploadFileAction = (payload) => ({
  type: MoocAdminActions.UPLOAD_FILE,
  payload,
});

export const uploadFileSuccess = (payload) => ({
  type: MoocAdminActions.UPLOAD_FILE_SUCCESS,
  payload,
});

export const uploadFileeError = (payload) => ({
  type: MoocAdminActions.UPLOAD_FILE_ERROR,
  payload,
});
//
export const uploadFileSchemaAction = (payload) => ({
  type: MoocAdminActions.UPLOAD_FILE_SCHEMA,
  payload,
});

export const uploadFileSchemaSuccess = (payload) => ({
  type: MoocAdminActions.UPLOAD_FILE_SCHEMA_SUCCESS,
  payload,
});

export const uploadFileSchemaeError = (payload) => ({
  type: MoocAdminActions.UPLOAD_FILE_SCHEMA_ERROR,
  payload,
});

export const errorUploadFilee = () => ({
  type: MoocAdminActions.RESET_UPDATE_MOOC_SETTING,
});

//Enrolled Courses
export const getEnrolledCourses = (payload) => ({
  type: MoocAdminActions.GET_ENROLLED_COURSES,
  payload,
});

export const setEnrolledCoursesSuccess = (payload) => ({
  type: MoocAdminActions.SET_ENROLLED_COURSES_SUCCESS,
  payload,
});

export const setEnrolledCoursesError = (payload) => ({
  type: MoocAdminActions.SET_ENROLLED_COURSES_ERROR,
  payload,
});

export const resetEnrolledCourses = () => ({
  type: MoocAdminActions.RESET_ENROLLED_COURSES,
});

export const setEnrolledCoursesLoader = (payload) => ({
  type: MoocAdminActions.SET_ENROLLED_COURSES_LOADER,
  payload,
});

export const updateMoocCourseStatus = (payload) => ({
  type: MoocAdminActions.UPDATE_COURSE_STATUS,
  payload,
});

export const setupdateCourseStatusSuccess = (payload) => ({
  type: MoocAdminActions.UPDATE_COURSE_STATUS_SUCCESS,
  payload,
});

export const setupdateCourseStatusError = (payload) => ({
  type: MoocAdminActions.UPDATE_COURSE_STATUS_ERROR,
  payload,
});

export const resetUpdateCourseStatus = () => ({
  type: MoocAdminActions.RESET_UPDATE_COURSE_STATUS,
});

export const getMoocCourseStatus = (payload) => ({
  type: MoocAdminActions.GET_MOOC_COURSE_STATUS,
  payload,
});

export const setMoocCourseStatusSuccess = (payload) => ({
  type: MoocAdminActions.SET_MOOC_COURSE_STATUS_SUCCESS,
  payload,
});

export const setMoocCourseStatusError = (payload) => ({
  type: MoocAdminActions.SET_MOOC_COURSE_STATUS_ERROR,
  payload,
});

export const resetMoocCourseStatus = () => ({
  type: MoocAdminActions.RESET_MOOC_COURSE_STATUS,
});

export const moocAdminDashboard = (payload) => ({
  type: MoocAdminActions.MOOC_ADMIN_DASHBOARD,
  payload,
});

export const setMoocAdminDashboardSuccess = (payload) => ({
  type: MoocAdminActions.SET_MOOC_ADMIN_DASHBOARD_SUCCESS,
  payload,
});

export const setMoocAdminDashboardError = (payload) => ({
  type: MoocAdminActions.SET_MOOC_ADMIN_DASHBOARD_ERROR,
  payload,
});

export const setDashboardMoocTableLoading = (payload) => ({
  type: MoocAdminActions.SET_MOOC_ADMIN_DASHBOARD_LOADING,
  payload: payload,
});

export const getStudentListByCourseId = (payload) => ({
  type: MoocAdminActions.GET_STUDENT_LIST_BY_COURSE_ID,
  payload: payload,
});

export const setStudentListByCourseIdSuccess = (payload) => ({
  type: MoocAdminActions.SET_STUDENT_LIST_BY_COURSE_ID_SUCCESS,
  payload: payload,
});

export const setStudentListByCourseIdError = (payload) => ({
  type: MoocAdminActions.SET_STUDENT_LIST_BY_COURSE_ID_ERROR,
  payload: payload,
});

export const resetStudentListByCourseId = (payload) => ({
  type: MoocAdminActions.RESET_STUDENT_LIST_BY_COURSE_ID,
  payload: payload,
});

export const setSelectedCourse = (payload) => ({
  type: MoocAdminActions.SELECTED_COURSE_FOR_STUDENTS_LIST,
  payload: payload,
});

export const resetSelectedCourse = (payload) => ({
  type: MoocAdminActions.RESET_SELECTED_COURSE_FOR_STUDENTS_LIST,
  payload: payload,
});

// Assessment
export const assessmentLinkRequestAction = (payload) => ({
  type: MoocAdminActions.GET_ASSESSMENT_LINK_REQUEST,
  payload: payload,
});

export const assessmentLinkRequestActionSuccess = (payload) => ({
  type: MoocAdminActions.GET_ASSESSMENT_LINK_REQUEST_SUCCESS,
  payload: payload,
});

export const assessmentLinkRequestActionError = (payload) => ({
  type: MoocAdminActions.GET_ASSESSMENT_LINK_REQUEST_ERROR,
  payload: payload,
});

export const resetAssessmentLinkRequest = () => ({
  type: MoocAdminActions.RESET_ASSESSMENT_LINK_REQUEST,
});

// Video Notes
export const getVideoNotesRequest = (payload) => ({
  type: MoocAdminActions.GET_VIDEO_NOTES_REQUEST,
  payload: payload,
});

export const getVideoNotesRequestSuccess = (payload) => ({
  type: MoocAdminActions.GET_VIDEO_NOTES_REQUEST_SUCCESS,
  payload: payload,
});
export const getVideoNotesRequestError = (payload) => ({
  type: MoocAdminActions.GET_VIDEO_NOTES_REQUEST_ERROR,
  payload: payload,
});

export const getVideoNotesReset = () => ({
  type: MoocAdminActions.RESET_VIDEO_NOTES,
});

export const createVideoNotesRequest = (payload) => ({
  type: MoocAdminActions.CREATE_VIDEO_NOTES_REQUEST,
  payload: payload,
});

export const createVideoNotesSuccess = (payload) => ({
  type: MoocAdminActions.CREATE_VIDEO_NOTES_REQUEST_SUCCESS,
  payload: payload,
});

export const createVideoNotesError = (payload) => ({
  type: MoocAdminActions.CREATE_VIDEO_NOTES_REQUEST_ERROR,
  payload: payload,
});

export const downloadCertificateReq = (payload) => ({
  type: MoocAdminActions.DOWNLOAD_CERTIFICATE_REQUEST,
  payload: payload,
});

export const downloadCertificateSuccess = (payload) => ({
  type: MoocAdminActions.DOWNLOAD_CERTIFICATE_REQUEST_SUCCESS,
  payload: payload,
});

export const downloadCertificateError = (payload) => ({
  type: MoocAdminActions.DOWNLOAD_CERTIFICATE_REQUEST_ERROR,
  payload: payload,
});

export const resetDownloadCertificate = () => ({
  type: MoocAdminActions.RESET_DOWNLOAD_CERTIFICATE_REQUEST,
});

//Learning Path
export const learningPathToPublishReq = (payload) => ({
  type: MoocAdminActions.LEARNING_PATH_TO_PUBLISH,
  payload: payload,
});

export const learningPathToPublishSuccess = (payload) => ({
  type: MoocAdminActions.LEARNING_PATH_TO_PUBLISH_SUCCESS,
  payload: payload,
});

export const learningPathToPublishError = (payload) => ({
  type: MoocAdminActions.LEARNING_PATH_TO_PUBLISH_ERROR,
  payload: payload,
});

export const resetLearningPathToPublish = () => ({
  type: MoocAdminActions.RESET_LEARNING_PATH_TO_PUBLISH,
});

export const publishedLearningPathReq = (payload) => ({
  type: MoocAdminActions.PUBLISHED_LEARNING_PATH,
  payload: payload,
});

export const publishedLearningPathSuccess = (payload) => ({
  type: MoocAdminActions.PUBLISHED_LEARNING_PATH_SUCCESS,
  payload: payload,
});

export const publishedLearningPathError = (payload) => ({
  type: MoocAdminActions.PUBLISHED_LEARNING_PATH_ERROR,
  payload: payload,
});

export const resetPublishedLearningPath = () => ({
  type: MoocAdminActions.RESET_PUBLISHED_LEARNING_PATH,
});
//Get Learning Path
export const getLearningPath = (payload) => ({
  type: MoocAdminActions.GET_LEARNING_PATH,
  payload,
});

export const setLearningPathSuccess = (payload) => ({
  type: MoocAdminActions.SET_GET_LEARNING_PATH_SUCCESS,
  payload,
});

export const setLearningPathError = (payload) => ({
  type: MoocAdminActions.SET_GET_LEARNING_PATH_ERROR,
  payload,
});

export const resetLearningPath = () => ({
  type: MoocAdminActions.RESET_LEARNING_PATH,
});

export const setLearningPathLoader = (payload) => ({
  type: MoocAdminActions.SET_LEARNING_PATH_LOADER,
  payload,
});

export const getCourseListDropdown = (payload) => ({
  type: MoocAdminActions.GET_COURSE_LIST_DROPDOWN,
  payload,
});

export const getCourseListDropdownSuccess = (payload) => ({
  type: MoocAdminActions.GET_COURSE_LIST_DROPDOWN_SUCCESS,
  payload,
});

export const getCourseListDropdownError = (payload) => ({
  type: MoocAdminActions.GET_COURSE_LIST_DROPDOWN_ERROR,
  payload,
});
export const createLpReq = (payload) => ({
  type: MoocAdminActions.CREATE_LEARNING_PATH,
  payload,
});

export const createLpSuccess = (payload) => ({
  type: MoocAdminActions.CREATE_LEARNING_PATH_SUCCESS,
  payload,
});

export const createLpError = (payload) => ({
  type: MoocAdminActions.CREATE_LEARNING_PATH_ERROR,
  payload,
});

export const resetCreatLP = () => ({
  type: MoocAdminActions.CREATE_LEARNING_PATH_RESET,
});
//Update Learning Path
export const updateLearningStatus = (payload) => ({
  type: MoocAdminActions.UPDATE_LEARNING_PATH,
  payload,
});

export const setUpdateLearningStatusSuccess = (payload) => ({
  type: MoocAdminActions.SET_UPDATE_LEARNING_PATH_SUCCESS,
  payload,
});

export const setUpdateLearningStatusError = (payload) => ({
  type: MoocAdminActions.SET_UPDATE_LEARNING_PATH_ERROR,
  payload,
});
export const setUpdateLearningPathLoader = (payload) => ({
  type: MoocAdminActions.SET_UPDATE_LEARNING_PATH_LOADER,
  payload,
});

export const myLearningPathRequest = (payload) => ({
  type: MoocAdminActions.GET_MY_LEARNING_PATH,
  payload,
});

export const setMyLearningPathSuccess = (payload) => ({
  type: MoocAdminActions.SET_MY_LEARNING_PATH_SUCCESS,
  payload,
});

export const setMyLearningPathError = (payload) => ({
  type: MoocAdminActions.SET_MY_LEARNING_PATH_ERROR,
  payload,
});

export const resetMyLearningPath = () => ({
  type: MoocAdminActions.RESET_MY_LEARNING_PATH,
});

export const enrollLearningPathRequest = (payload) => ({
  type: MoocAdminActions.ENROLL_LEARNING_PATH,
  payload,
});

export const enrollLearningPathSuccess = (payload) => ({
  type: MoocAdminActions.ENROLL_LEARNING_PATH_SUCCESS,
  payload,
});

export const enrollLearningPathError = (payload) => ({
  type: MoocAdminActions.ENROLL_LEARNING_PATH_ERROR,
  payload,
});

export const resetEnrollLearningPath = () => ({
  type: MoocAdminActions.RESET_ENROLL_LEARNING_PATH,
});

export const setCourseByLPIdReq = (payload) => ({
  type: MoocAdminActions.GET_COURSE_LIST_BY_LP_ID,
  payload,
});

export const setCourseByLPIdSuccess = (payload) => ({
  type: MoocAdminActions.GET_COURSE_LIST_BY_LP_ID_SUCCESS,
  payload,
});

export const setCourseByLPIdError = (payload) => ({
  type: MoocAdminActions.GET_COURSE_LIST_BY_LP_ID_ERROR,
  payload,
});

export const resetCourseByLPId = () => ({
  type: MoocAdminActions.RESET_COURSE_LIST_BY_LP_ID,
});

export const getLpCourseStatus = (payload) => ({
  type: MoocAdminActions.GET_LP_COURSE_STATUS,
  payload,
});

export const setLpCourseStatusSuccess = (payload) => ({
  type: MoocAdminActions.SET_LP_COURSE_STATUS_SUCCESS,
  payload,
});

export const setLpCourseStatusError = (payload) => ({
  type: MoocAdminActions.SET_LP_COURSE_STATUS_ERROR,
  payload,
});

export const resetLpCourseStatus = () => ({
  type: MoocAdminActions.RESET_LP_COURSE_STATUS,
});

export const getLPByIdReq = (payload) => ({
  type: MoocAdminActions.GET_LP_BY_ID,
  payload,
});

export const getLPByIdSuccess = (payload) => ({
  type: MoocAdminActions.SET_LP_BY_ID_SUCCESS,
  payload,
});

export const getLPByIdError = (payload) => ({
  type: MoocAdminActions.SET_LP_BY_ID_ERROR,
  payload,
});

export const resetLpById = () => ({
  type: MoocAdminActions.RESET_LP_BY_ID,
});

export const editLpReq = (payload) => ({
  type: MoocAdminActions.EDIT_LEARNING_PATH,
  payload,
});

export const editLpSuccess = (payload) => ({
  type: MoocAdminActions.EDIT_LEARNING_PATH_SUCCESS,
  payload: payload,
});

export const editLpError = (payload) => ({
  type: MoocAdminActions.EDIT_LEARNING_PATH_ERROR,
  payload: payload,
});

export const resetEditLp = () => ({
  type: MoocAdminActions.RESET_EDIT_LEARNING_PATH,
});
