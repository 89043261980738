import * as React from 'react';

const Print = ({ fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    viewBox="0 96 960 960"
    width="20"
    fill={fill}
    {...rest}
  >
    <path d="M648 432V312H312v120h-72V240h480v192h-72Zm-480 72h625-625Zm539.789 96Q723 600 733.5 589.711q10.5-10.29 10.5-25.5Q744 549 733.711 538.5q-10.29-10.5-25.5-10.5Q693 528 682.5 538.289q-10.5 10.29-10.5 25.5Q672 579 682.289 589.5q10.29 10.5 25.5 10.5ZM648 840V696H312v144h336Zm72 72H240V768H96V528q0-40 28-68t68-28h576q40 0 68 28t28 68v240H720v144Zm73-216V542.328Q793 526 781 515t-28-11H206q-16.15 0-27.075 11.04T168 542.4V696h72v-72h480v72h73Z" />
  </svg>
);

export default Print;
