export const assessmentRepositoryActions = {
  QUESTION_BANK_LOADER: 'QUESTION_BANK_LOADER',
  QUESTION_BANK_RUBRICS_LOADER: 'QUESTION_BANK_RUBRICS_LOADER',
  SAVE_TITLE_COURSE: 'SAVE_TITLE_COURSE',
  createQuestionBank: {
    create: 'CREATE_QUESTION_BANK',
    success: 'CREATE_QUESTION_BANK_SUCCESS',
    error: 'CREATE_QUESTION_BANK_ERROR',
    reset: 'RESET_CREATE_QUESTION_BANK',
  },
  createQuestionBankBulkUpload: {
    create: 'CREATE_QUESTION_BANK_BULK_UPLOAD',
    success: 'CREATE_QUESTION_BANK_BULK_UPLOAD_SUCCESS',
    error: 'CREATE_QUESTION_BANK_BULK_UPLOAD_ERROR',
    reset: 'RESET_CREATE_QUESTION_BANK_BULK_UPLOAD',
  },
  createRubrics: {
    create: 'CREATE_QUESTION_BANK_RUBRICS',
    success: 'CREATE_QUESTION_BANK_RUBRICS_SUCCESS',
    error: 'CREATE_QUESTION_BANK_RUBRICS_ERROR',
    reset: 'RESET_CREATE_QUESTION_BANK_RUBRICS',
  },
  listRubrics: {
    get: 'LIST_QUESTION_BANK__RUBRICS',
    success: 'LIST_QUESTION_BANK__RUBRICS_SUCCESS',
    error: 'LIST_QUESTION_BANK__RUBRICS_ERROR',
    reset: 'RESET_LIST_QUESTION_BANK__RUBRICS',
  },
  getRubricsById: {
    get: 'GET_QUESTION_BANK__RUBRICS',
    success: 'GET_QUESTION_BANK__RUBRICS_SUCCESS',
    error: 'GET_QUESTION_BANK__RUBRICS_ERROR',
    reset: 'RESET_GET_QUESTION_BANK__RUBRICS',
  },
  deleteRubricsById: {
    delete: 'DELETE_QUESTION_BANK__RUBRICS',
    success: 'DELETE_QUESTION_BANK__RUBRICS_SUCCESS',
    error: 'DELETE_QUESTION_BANK__RUBRICS_ERROR',
    reset: 'RESET_DELETE_QUESTION_BANK__RUBRICS',
  },
  updateRubricsById: {
    update: 'UPDATE_QUESTION_BANK__RUBRICS',
    success: 'UPDATE_QUESTION_BANK__RUBRICS_SUCCESS',
    error: 'UPDATE_QUESTION_BANK__RUBRICS_ERROR',
    reset: 'RESET_UPDATE_QUESTION_BANK__RUBRICS',
  },
  getQuestionBankList: {
    get: 'GET_QUESTION_BANK_LIST',
    success: 'GET_QUESTION_BANK_LIST_SUCCESS',
    error: 'GET_QUESTION_BANK_LIST_ERROR',
    reset: 'RESET_GET_QUESTION_BANK_LIST',
  },
  getQuestionBankDetailsById: {
    get: 'GET_QUESTION_BANK_DETAILS',
    success: 'GET_QUESTION_BANK_DETAILS_SUCCESS',
    error: 'GET_QUESTION_BANK_DETAILS_ERROR',
    reset: 'RESET_QUESTION_BANK_DETAILS',
  },
  deleteQuestionBank: {
    delete: 'DELETE_QUESTION_BANK_DETAILS',
    success: 'DELETE_QUESTION_BANK_DETAILS_SUCCESS',
    error: 'DELETE_QUESTION_BANK_DETAILS_ERROR',
    reset: 'DELETE_QUESTION_BANK_DETAILS_RESET',
  },
  evaluateQuestionBank: {
    update: 'UPDATE_QUESTION_BANK_ACTION',
    success: 'UPDATE_QUESTION_BANK_ACTION_SUCCESS',
    error: 'UPDATE_QUESTION_BANK_ACTION_ERROR',
    reset: 'UPDATE_QUESTION_BANK_ACTION_RESET',
  },
  editQuestion: {
    update: 'UPDATE_QUESTION_ACTION',
    success: 'UPDATE_QUESTION_ACTION_SUCCESS',
    error: 'UPDATE_QUESTION_ACTION_ERROR',
    reset: 'UPDATE_QUESTION_ACTION_RESET',
  },
  deleteQuestion: {
    delete: 'DELETE_QUESTION_DETAILS',
    success: 'DELETE_QUESTION_DETAILS_SUCCESS',
    error: 'DELETE_QUESTION_DETAILS_ERROR',
    reset: 'DELETE_QUESTION_DETAILS_RESET',
  },
  createAssessment: {
    create: 'CREATE_ASSESSMENT_DETAILS',
    success: 'CREATE_ASSESSMENT_DETAILS_SUCCESS',
    error: 'CREATE_ASSESSMENT_DETAILS_ERROR',
    reset: 'CREATE_ASSESSMENT_DETAILS_RESET',
  },

  getAssessmentList: {
    get: 'GET_ASSESSMENT_LIST',
    success: 'GET_ASSESSMENT_LIST_SUCCESS',
    error: 'GET_ASSESSMENT_LIST_ERROR',
    reset: 'GET_ASSESSMENT_LIST_RESET',
  },
  deleteAssessment: {
    delete: 'DELETE_ASSESSMENT_DETAILS',
    success: 'DELETE_ASSESSMENT_DETAILS_SUCCESS',
    error: 'DELETE_ASSESSMENT_DETAILS_ERROR',
    reset: 'DELETE_ASSESSMENT_DETAILS_RESET',
  },
  getAssessmentDetailsById: {
    get: 'GET_ASSESSMENT_DETAILS',
    success: 'GET_ASSESSMENT_DETAILS_SUCCESS',
    error: 'GET_ASSESSMENT_DETAILS_ERROR',
    reset: 'RESET_ASSESSMENT_DETAILS',
  },
  editSection: {
    update: 'UPDATE_SECTION_ACTION',
    success: 'UPDATE_SECTION_ACTION_SUCCESS',
    error: 'UPDATE_SECTION_ACTION_ERROR',
    reset: 'UPDATE_SECTION_ACTION_RESET',
  },
  createSection: {
    create: 'CREATE_ASSESSMENT_SECTION',
    success: 'CREATE_ASSESSMENT_SECTION_SUCCESS',
    error: 'CREATE_ASSESSMENT_SECTION_ERROR',
    reset: 'CREATE_ASSESSMENT_SECTION_RESET',
  },
  deleteSection: {
    delete: 'DELETE_ASSESSMENT_SECTION_ACTION',
    success: 'DELETE_ASSESSMENT_SECTION_SUCCESS',
    error: 'DELETE_ASSESSMENT_SECTION_ERROR',
    reset: 'DELETE_ASSESSMENT_SECTION_RESET',
  },
  importQuestion: {
    import: 'IMPORT_QUESTION',
    success: 'IMPORT_QUESTION_SUCESS',
    error: 'IMPORT_QUESTION_ERROR',
    reset: 'IMPORT_QUESTION_RESET',
  },
  sendAssessmentForApproval: {
    update: 'SEND_FOR_APPROVAL',
    success: 'SEND_FOR_APPROVAL_SUCCESS',
    error: 'SEND_FOR_APPROVAL_ERROR',
    reset: 'SEND_FOR_APPROVAL_RESET',
  },
  updateAssessent: {
    update: 'UPDATE_ASSESSMENT',
    success: 'UPDATE_ASSESSMENT_SUCCESS',
    error: 'UPDATE_ASSESSMENT_ERROR',
    reset: 'UPDATE_ASSESSMENT_RESET',
  },
  evaluateAssesment: {
    evaluate: 'EVALUATE_ASSESSMENT_ACTION',
    success: 'EVALUATE_ASSESSMENT_ACTION_SUCCESS',
    error: 'EVALUATE_ASSESSMENT_ACTION_ERROR',
    reset: 'EVALUATE_ASSESSMENT_ACTION_RESET',
  },
};
