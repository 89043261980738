export const groupChatActions = {
  GROUPCHAT: {
    GET_CANDIDATE_LIST: 'GET_CANDIDATE_LIST',
    GET_CANDIDATE_LIST_SUCCESS: 'GET_CANDIDATE_LIST_SUCCESS',
    GET_CANDIDATE_LIST_FAIL: 'GET_CANDIDATE_LIST_FAIL',
    GET_CANDIDATE_LIST_RESET: 'GET_CANDIDATE_LIST_RESET',

    CREATE_GROUP: 'CREATE_GROUP',
    CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
    CREATE_GROUP_FAIL: 'CREATE_GROUP_FAIL',

    ADD_CANDIDATE_TO_GROUP: 'ADD_CANDIDATE_TO_GROUP',
    ADD_CANDIDATE_TO_GROUP_SUCCESS: 'ADD_CANDIDATE_TO_GROUP_SUCCESS',
    ADD_CANDIDATE_TO_GROUP_FAIL: 'ADD_CANDIDATE_TO_GROUP_FAIL',
    ADD_CANDIDATE_TO_GROUP_RESET: 'ADD_CANDIDATE_TO_GROUP_RESET',

    GET_GROUPS: 'GET_GROUPS',
    GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
    GET_GROUPS_FAIL: 'GET_GROUPS_FAIL',

    GET_GROUP_CANDIDATE_LIST: 'GET_GROUP_CANDIDATE_LIST',
    GET_GROUP_CANDIDATE_LIST_SUCCESS: 'GET_GROUP_CANDIDATE_LIST_SUCCESS',
    GET_GROUP_CANDIDATE_LIST_FAIL: 'GET_GROUP_CANDIDATE_LIST_FAIL',
    GET_GROUP_CANDIDATE_LIST_RESET: 'GET_GROUP_CANDIDATE_LIST_RESET',

    GET_CHAT_LIST: 'GET_CHAT_LIST',
    GET_CHAT_LIST_SUCCESS: 'GET_CHAT_LIST_SUCCESS',
    GET_CHAT_LIST_FAIL: 'GET_CHAT_LIST_FAIL',
    GET_CHAT_LIST_RESET: 'GET_CHAT_LIST_RESET',

    UPDATE_GROUP_DATA: 'UPDATE_GROUP_DATA',
    UPDATE_GROUP_DATA_SUCCESS: 'UPDATE_GROUP_DATA_SUCCESS',
    UPDATE_GROUP_DATA_FAIL: 'UPDATE_GROUP_DATA_FAIL',
    UPDATE_GROUP_DATA_RESET: 'UPDATE_GROUP_DATA_RESET',

    UPDATE_CHAT_DATA: 'UPDATE_CHAT_DATA',
    UPDATE_CHAT_DATA_SUCCESS: 'UPDATE_CHAT_DATA_SUCCESS',
    UPDATE_CHAT_DATA_FAIL: 'UPDATE_CHAT_DATA_FAIL',
    UPDATE_CHAT_DATA_RESET: 'UPDATE_CHAT_DATA_RESET',

    RESET_GROUP_CHAT_REDUCER: 'RESET_GROUP_CHAT_REDUCER',
  },
};
