import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  studentActions,
  getAssessmentListSuccess,
  getAssessmentListError,
  getAssignmentListApi,
  getAssignmentListSuccess,
  getAssignmentListError,
  getAssignmentByIdApi,
  getAssignmentByIdSuccess,
  getAssignmentByIdError,
  getAssessmentByIdApi,
  getAssessmentByIdSuccess,
  getAssessmentByIdError,
  getAssessmentListApi,
  getSubModulesApi,
  getCourseSubModuleError,
  getCourseSubModuleSuccess,
  getModulesApi,
  getCourseModuleError,
  getCourseModuleSuccess,
  postAssessmentApi,
  createAssessmentSuccess,
  createAssessmentError,
  postQuizApi,
  createQuizSuccess,
  createQuizError,
  getCourseByStudentIdApi,
  getCourseByStudentIdSuccess,
  getAssignmentBySubModuleApi,
  getAssignmentBySudModuleIdSuccess,
  getAssignmentBySudModuleIdError,
  getAssessmentBySudModuleIdSuccess,
  getAssessmentBySubModuleApi,
  getAssessmentBySudModuleIdError,
  createNotesApi,
  createNotesAndBookmarksSuccess,
  createNotesAndBookmarksError,
  updateNotesAndBookmarksApi,
  updateNotesAndBookmarksSuccess,
  updateNotesAndBookmarksError,
  deleteNotesAndBookmarkApi,
  deleteNotesAndBookmarksSuccess,
  deleteNotesAndBookmarksError,
  getNotesApi,
  getNotesAndBookmarksSuccess,
  getNotesAndBookmarksError,
  getDetailsByCourseIdError,
  getDetailsByCourseId,
  getDetailsByCourseIdSuccess,
  getLearningDetailsByCourseId,
  getNotesBySubModuleIdApi,
  getNotesByModuleIdApi,
  getNotesBySubModuleSuccess,
  getNotesBySubModuleError,
  getNotesByModuleSuccess,
  getNotesByModuleError,
  submitAssessmentApi,
  submitStudentResponseSuccess,
  submitStudentResponseError,
  getPendingAssignmentByIdError,
  getPendingAssignmentByIdSuccess,
  getPendingAssignmentById,
  getPendingAssessmentByIdError,
  getPendingAssessmentByIdSuccess,
  getPendingAssessmentById,
  getPendingAssessmentByApi,
  getPendingAssignmentByApi,
  getAssessmentByStudentIdApi,
  editAssessmentSuccess,
  editAssessmentError,
  putAssessmentApi,
  editQuizSuccess,
  editQuizError,
  putQuizApi,
  deleteAssessmentApi,
  deleteAssessmentSuccess,
  deleteAssessmentError,
  deleteQuizApi,
  deleteQuizSuccess,
  deleteQuizError,
  getPendingListByStudentByIdError,
  getPendingListByStudentByIdSuccess,
  getStudentIdActivitiesApi,
  getAllUserDetailsApi,
  successGetAllUsers,
  errorGetAllUsers,
  getByQuizIdApi,
  getByQuizIdSuccess,
  getByQuizIdError,
  setLoading,
  createStudentProgramDetailsApi,
  createStudentProgramDetailsSuccess,
  createStudentProgramDetailsError,
  getStudentProgramDetailsApi,
  getStudentProgramDetailsSuccesss,
  getStudentProgramDetailsError,
  updateStudentProgramDetailsApi,
  updateStudentProgramDetailsSuccess,
  updateStudentProgramDetailsError,
  deleteStudentProgramDetailsSuccess,
  deleteStudentProgramDetailsError,
  deleteStudentProgramDetailsApi,
  createBoomarksApi,
  addUpdateBookmarkSuccess,
  addUpdateBoomarksError,
  getNotesByContentApi,
  getNotesByContentSuccess,
  getNotesByContentError,
  getLearningQuizApi,
  learningQuizSuccess,
  learningQuizError,
  submitLearningQuizSuccess,
  submitLearningQuizError,
  submitLearningQuizApi,
  getLearningQuizSuccess,
  getLearningQuizError,
  submitAssigmentsApi,
  submitLearningAssignmentSuccess,
  submitLearningAssignmentError,
  getLearningAssignmentApi,
  getLearningAssignmentSuccess,
  getLearningAssignmentError,
  postReadingProgressApi,
  postVideoProgressApi,
  postVideoProgressSuccess,
  postVideoProgressError,
  postReadingsProgressSuccess,
  postReadingsProgressError,
  getVideoProgressApi,
  getVideoProgressSuccess,
  getVideoProgressError,
  getReadingProgressError,
  getReadingsProgressSuccess,
  getReadingProgressApi,
  getReadingsProgressError,
  getNotesByContentIdApi,
  getStudentYearListSuccesss,
  getStudentYearListError,
  getStudentSemListSuccesss,
  getStudentSemListError,
  getSubjectProgramDetailsApi,
  getSubjectProgramDetailsSuccess,
  getSubjectProgramDetailsError,
  getSubjectProgramDetailsLoading,
  getStudentConnectApi,
  getStudentConnectSuccess,
  getStudentConnectError,
  getStudentNotificationApi,
  getStudentNotificationSuccess,
  getStudentNotificationError,
  createStudentNotificationApi,
  createStudentNotificationSuccess,
  createStudentNotificationError,
  deleteStudentNotificationSuccess,
  deleteStudentNotificationError,
  deleteStudentNotificationApi,
  deleteBulkStudentNotificationError,
  deleteBulkStudentNotificationSuccess,
  deleteBulkStudentNotificationApi,
} from 'store/Student';
import { setToastMessage } from 'store/Toast';
import { statusCode } from 'constants/apis/apiStatus';
import { setSubModuleLoading } from 'store/CourseManagement';

function* getAssessmentListSaga(action) {
  try {
    const response = yield call(getAssessmentListApi);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentListSuccess(response?.data));
    } else {
      yield put(getAssessmentListError(response?.data));
    }
  } catch (error) {
    console.log('error occured');
  }
}

function* getAssignmentListSaga({ payload }) {
  try {
    console.log(payload);
    const response = yield call(getAssignmentListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssignmentListSuccess(response?.data));
    } else {
      yield put(getAssignmentListError(response?.data));
    }
  } catch (error) {
    console.log('error occured');
  }
}

function* getAssessmentByIdSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAssessmentByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentByIdSuccess(response?.data));
      yield put(setLoading(false));
    } else {
      yield put(getAssessmentByIdError(response?.data));
      yield put(setLoading(false));
    }
  } catch (error) {
    console.log('error occured');
    yield put(setLoading(false));
  }
}

function* getAssignmentByIdSaga({ payload }) {
  try {
    const response = yield call(getAssignmentByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssignmentByIdSuccess(response?.data));
    } else {
      yield put(getAssignmentByIdError(response?.data));
      yield put(
        setToastMessage({
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getAssignmentByIdError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getQuizByIdForEditSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getByQuizIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getByQuizIdSuccess(response?.data));
      yield put(setLoading(false));
    } else {
      yield put(setLoading(false));
      yield put(getByQuizIdError(response?.data));
      yield put(
        setToastMessage({
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(getByQuizIdError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getCourseModuleSaga({ payload }) {
  try {
    const response = yield call(getModulesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseModuleSuccess(response?.data));
    } else {
      yield put(getCourseModuleError(response?.data));
    }
  } catch (error) {
    yield put(getCourseModuleError(response?.data));
  }
}

function* getCourseSubModuleSaga({ payload }) {
  try {
    const response = yield call(getSubModulesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseSubModuleSuccess(response?.data));
    } else {
      yield put(getCourseSubModuleError(response?.data));
    }
  } catch (error) {
    yield put(getCourseSubModuleError(response?.data));
  }
}

function* createAssessmentSaga({ payload }) {
  try {
    const response = yield call(postAssessmentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createAssessmentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Assessment created.',
          severity: 'success',
        })
      );
    } else {
      yield put(createAssessmentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(createAssessmentError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* createQuizSaga({ payload }) {
  try {
    const response = yield call(postQuizApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createQuizSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Quiz created.',
          severity: 'success',
        })
      );
    } else {
      yield put(createQuizError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(createQuizError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getNotesAndBookmarksBySubModuleSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(getNotesBySubModuleIdApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getNotesBySubModuleSuccess(response?.data));
    }
  } catch (error) {
    yield put(getNotesBySubModuleError(error?.data));
  }
}

function* putAssessmentSaga({ payload }) {
  try {
    const response = yield call(putAssessmentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(editAssessmentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Assessment updated.',
          severity: 'success',
        })
      );
    } else {
      yield put(editAssessmentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(editAssessmentError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* putQuizSaga({ payload }) {
  try {
    const response = yield call(putQuizApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(editQuizSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Quiz updated.',
          severity: 'success',
        })
      );
    } else {
      yield put(editQuizError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(editQuizError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* deleteAssessentSaga({ payload }) {
  try {
    const deleteFailedMessage = 'Failed to delete assessment';
    const response = yield call(deleteAssessmentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteAssessmentSuccess(true));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(deleteAssessmentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? deleteFailedMessage,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(deleteAssessmentError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* deleteQuizSaga({ payload }) {
  try {
    const response = yield call(deleteQuizApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteQuizSuccess(true));
      yield put(
        setToastMessage({
          data: response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(deleteQuizError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to delete quiz.',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(deleteQuizError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getCourseByStudentIdSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(getCourseByStudentIdApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseByStudentIdSuccess(response?.data));
    }
  } catch (error) {
    yield put(createDepartmentError(error?.data));
  }
}

function* getAssessmentBySubModuleSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(getAssessmentBySubModuleApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentBySudModuleIdSuccess(response?.data));
    }
  } catch (error) {
    yield put(getAssessmentBySudModuleIdError(error?.data));
  }
}

function* getAssignmentBySubModuleSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(getAssignmentBySubModuleApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getAssignmentBySudModuleIdSuccess(response?.data));
    }
  } catch (error) {
    yield put(getAssignmentBySudModuleIdError(error?.data));
  }
}

function* getAllUsersSagaFunction({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(getAllUserDetailsApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(successGetAllUsers(response?.data));
    }
  } catch (error) {
    yield put(errorGetAllUsers(error?.data));
  }
}

function* createNotesAndBookmarksSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(createNotesApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createNotesAndBookmarksSuccess(true));
    }
  } catch (error) {
    yield put(createNotesAndBookmarksError(error?.data));
  }
}

function* updateNotesAndBookmarksSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(updateNotesAndBookmarksApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateNotesAndBookmarksSuccess(true));
    }
  } catch (error) {
    yield put(updateNotesAndBookmarksError(error?.data));
  }
}

function* deleteNotesAndBookmarksSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(deleteNotesAndBookmarkApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteNotesAndBookmarksSuccess(true));
    }
  } catch (error) {
    yield put(deleteNotesAndBookmarksError(error?.data));
  }
}

function* getNotesAndBookmarksSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(getNotesApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getNotesAndBookmarksSuccess(response?.data));
    }
  } catch (error) {
    yield put(getNotesAndBookmarksError(error?.data));
  }
}

function* getDetailsByCourseIdSaga({ payload }) {
  try {
    yield put(setSubModuleLoading(true));
    const response = yield call(getLearningDetailsByCourseId, payload);
    yield put(setSubModuleLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDetailsByCourseIdSuccess(response?.data));
      yield put(getDetailsByCourseIdError({ error: {} }));
    } else {
      yield put(getDetailsByCourseIdError(response?.data?.error));
      yield put(getDetailsByCourseIdSuccess({ modules: [] }));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getDetailsByCourseIdError(error?.data));
    yield put(
      setToastMessage({
        data: response?.data?.error?.message,
        severity: 'error',
      })
    );
  }
}

function* getNotesAndBookmarksByModuleSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(getNotesByModuleIdApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getNotesByModuleSuccess(response?.data));
    }
  } catch (error) {
    yield put(getNotesByModuleError(error?.data));
  }
}

function* submitAssessmentSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(submitAssessmentApi, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(submitStudentResponseSuccess(response?.data));
      yield put(
        setToastMessage({
          severity: 'success',
        })
      );
    } else
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
  } catch (error) {
    yield put(submitStudentResponseError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getPendingQuizListSaga({ payload }) {
  try {
    const response = yield call(getPendingAssignmentById, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getPendingAssignmentByIdSuccess(response?.data));
    }
  } catch (error) {
    yield put(getPendingAssignmentByIdError(error?.data));
  }
}

function* getStudentActivitiesSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getStudentIdActivitiesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getPendingListByStudentByIdSuccess(response?.data));
      yield put(setLoading(false));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
      yield put(setLoading(false));
      yield put(
        setToastMessage({
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getPendingListByStudentByIdError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
    yield put(setLoading(false));
  }
}

function* getPendingAssessmentListSaga({ payload }) {
  try {
    const response = yield call(getPendingAssessmentByApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getPendingAssessmentByIdSuccess(response?.data));
    }
  } catch (error) {
    yield put(getPendingAssessmentByIdError(error?.data));
  }
}

function* createStudentProgramDetailsSaga({ payload }) {
  try {
    const response = yield call(createStudentProgramDetailsApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createStudentProgramDetailsSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Created',
          severity: 'success',
        })
      );
    }
  } catch (error) {
    yield put(createStudentProgramDetailsError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* getStudentProgramDetailsSaga({ payload }) {
  try {
    const response = yield call(getStudentProgramDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentProgramDetailsSuccesss(response?.data));
    }
  } catch (error) {
    yield put(getStudentProgramDetailsError(error?.data));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getStudentYearListSaga({ payload }) {
  try {
    const response = yield call(getStudentProgramDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentYearListSuccesss(response?.data));
    }
  } catch (error) {
    yield put(getStudentYearListError(response?.data));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getStudentSemesterListSaga({ payload }) {
  try {
    const response = yield call(getStudentProgramDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentSemListSuccesss(response?.data));
    }
  } catch (error) {
    yield put(getStudentSemListError(response?.data));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* updateStudentProgramDetailsSaga({ payload }) {
  try {
    const response = yield call(updateStudentProgramDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateStudentProgramDetailsSuccess(response?.data));
    }
  } catch (error) {
    yield put(updateStudentProgramDetailsError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* deletStudentProgramDetailsSaga({ payload }) {
  try {
    const response = yield call(deleteStudentProgramDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteStudentProgramDetailsSuccess(response?.data));
    }
  } catch (error) {
    yield put(deleteStudentProgramDetailsError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* createBookmarksSaga({ payload }) {
  try {
    const response = yield call(createBoomarksApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(addUpdateBookmarkSuccess(response?.data));
    }
  } catch (error) {
    yield put(addUpdateBoomarksError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* getNotesByContentSaga({ payload }) {
  try {
    const response = yield call(getNotesByContentIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getNotesByContentSuccess(response?.data));
    }
  } catch (error) {
    yield put(getNotesByContentError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* submitLearningQuizSaga({ payload, params }) {
  try {
    const response = yield call(submitLearningQuizApi, payload, params);
    if (response?.status === statusCode.Created201) {
      yield put(submitLearningQuizSuccess(true));
      yield put(
        setToastMessage({
          severity: 'success',
        })
      );
    } else {
      yield put(submitLearningQuizError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(submitLearningQuizError(error?.data?.message));
    yield put(
      setToastMessage({
        data: error?.data?.message,
        severity: 'error',
      })
    );
  }
}

function* fetchLearningQuizSaga({ payload, params }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getLearningQuizApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getLearningQuizSuccess(response?.data));
      yield put(setLoading(false));
    } else {
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(getLearningQuizError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* fetchLearningAssignmentSaga({ payload, params }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getLearningAssignmentApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getLearningAssignmentSuccess(response?.data));
      yield put(setLoading(false));
    } else {
      yield put(setLoading(false));
      yield put(
        setToastMessage({
          data: 'Failed',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(getLearningAssignmentError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* submitLearningAssignmentSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(submitAssigmentsApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(submitLearningAssignmentSuccess(true));
      yield put(setLoading(false));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? response?.data?.success,
          severity: 'success',
        })
      );
    } else {
      yield put(setLoading(false));
      yield put(submitLearningAssignmentError(response?.data?.message));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(submitLearningAssignmentError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* postVideoProgressSaga({ payload }) {
  try {
    const response = yield call(postVideoProgressApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(postVideoProgressSuccess(true));
      yield put(setLoading(false));
    } else {
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(postVideoProgressError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* postReadingProgressSaga({ payload }) {
  try {
    const response = yield call(postReadingProgressApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(postReadingsProgressSuccess(true));
      yield put(setLoading(false));
      yield put(
        setToastMessage({
          severity: 'success',
        })
      );
    } else {
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(postReadingsProgressError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* getVideoProgressSaga({ payload, params }) {
  try {
    const response = yield call(getVideoProgressApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getVideoProgressSuccess(response?.data));
      yield put(setLoading(false));
      yield put(
        setToastMessage({
          severity: 'success',
        })
      );
    } else {
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(getVideoProgressError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* getReadingProgressSaga({ payload, params }) {
  try {
    const response = yield call(getReadingProgressApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getReadingsProgressSuccess(response?.data));
      yield put(setLoading(false));
      yield put(
        setToastMessage({
          severity: 'success',
        })
      );
    } else {
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(getReadingsProgressError(error?.data));
    yield put(
      setToastMessage({
        data: 'Failed',
        severity: 'error',
      })
    );
  }
}

function* getSubjectProgramDetailsSaga({ payload }) {
  try {
    yield put(getSubjectProgramDetailsLoading(true));
    const response = yield call(getSubjectProgramDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSubjectProgramDetailsSuccess(response?.data));
    } else {
      yield put(getSubjectProgramDetailsError(response?.data));
    }
    yield put(getSubjectProgramDetailsLoading(false));
  } catch (error) {
    yield put(getSubjectProgramDetailsLoading(false));
    yield put(getSubjectProgramDetailsError(error?.data));
  }
}
function* getStudentConnectSaga({ payload }) {
  try {
    yield put(getSubjectProgramDetailsLoading(true));
    const response = yield call(getStudentConnectApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentConnectSuccess(response?.data));
    } else {
      yield put(getStudentConnectError(response?.data));
    }
    yield put(getSubjectProgramDetailsLoading(false));
  } catch (error) {
    yield put(getSubjectProgramDetailsLoading(false));
    yield put(getStudentConnectError(error?.data));
  }
}

function* getStudentNotificationSaga({ payload }) {
  try {
    yield put(getSubjectProgramDetailsLoading(true));
    const response = yield call(getStudentNotificationApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentNotificationSuccess(response?.data));
    } else {
      yield put(getStudentNotificationError(response?.data));
    }
    yield put(getSubjectProgramDetailsLoading(false));
  } catch (error) {
    yield put(getSubjectProgramDetailsLoading(false));
    yield put(getStudentNotificationError(error?.data));
  }
}
function* createStudentNotificationSaga({ payload }) {
  try {
    yield put(getSubjectProgramDetailsLoading(true));
    const response = yield call(createStudentNotificationApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createStudentNotificationSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Connection Created',
          severity: 'success',
        })
      );
    } else {
      yield put(createStudentNotificationError(response?.data));
    }
    yield put(getSubjectProgramDetailsLoading(false));
  } catch (error) {
    yield put(getSubjectProgramDetailsLoading(false));
    yield put(createStudentNotificationError(error?.data));
  }
}
function* deleteStudentNotificationSaga({ payload }) {
  try {
    yield put(getSubjectProgramDetailsLoading(true));
    const response = yield call(deleteStudentNotificationApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteStudentNotificationSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Connection Deleted',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteStudentNotificationError(response?.data));
    }
    yield put(getSubjectProgramDetailsLoading(false));
  } catch (error) {
    yield put(getSubjectProgramDetailsLoading(false));
    yield put(deleteStudentNotificationError(error?.data));
  }
}
function* deleteBulkStudentNotificationSaga({ payload }) {
  try {
    yield put(getSubjectProgramDetailsLoading(true));
    const response = yield call(deleteBulkStudentNotificationApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(deleteBulkStudentNotificationSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Connection Deleted',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteBulkStudentNotificationError(response?.data));
    }
    yield put(getSubjectProgramDetailsLoading(false));
  } catch (error) {
    yield put(getSubjectProgramDetailsLoading(false));
    yield put(deleteBulkStudentNotificationError(error?.data));
  }
}

export function* watchDummy() {
  yield takeLatest(studentActions.ASSIGNMENT_LIST.get, getAssignmentListSaga);
  yield takeLatest(studentActions.ASSESSMENT_LIST.get, getAssessmentListSaga);
  yield takeLatest(
    studentActions.ASSIGNMENT_BY_ID.getById,
    getAssignmentByIdSaga
  );
  yield takeLatest(
    studentActions.ASSESSMENT_BY_ID.getById,
    getAssessmentByIdSaga
  );
  yield takeLatest(studentActions.MODULES.get, getCourseModuleSaga);
  yield takeLatest(studentActions.SUBMODULES.get, getCourseSubModuleSaga);
  yield takeLatest(
    studentActions.STUDENT_COURSE.get_course_by_student_id,
    getCourseByStudentIdSaga
  );
  yield takeLatest(
    studentActions.NOTES_BOOKMARKS_LIST.get,
    getNotesAndBookmarksSaga
  );
  yield takeLatest(
    studentActions.NOTES_BOOKMARKS_LIST.update_notes_bookmarks,
    updateNotesAndBookmarksSaga
  );
  yield takeLatest(
    studentActions.NOTES_BOOKMARKS_LIST.delete_notes_bookmarks,
    deleteNotesAndBookmarksSaga
  );
  yield takeLatest(
    studentActions.NOTES_BOOKMARKS_LIST.create_notes_bookmarks,
    createNotesAndBookmarksSaga
  );
  yield takeLatest(
    studentActions.ASSIGNMENT_BY_ID.get_assignment_by_subModule,
    getAssignmentBySubModuleSaga
  );
  yield takeLatest(
    studentActions.ASSESSMENT_BY_ID.get_assessment_by_subModule,
    getAssessmentBySubModuleSaga
  );
  yield takeLatest(
    studentActions.COURSE_DETAILS.get_details_by_course_id,
    getDetailsByCourseIdSaga
  );
  yield takeLatest(
    studentActions.ASSESSMENT_CREATE.create,
    createAssessmentSaga
  );
  yield takeLatest(studentActions.QUIZ_CREATE.create, createQuizSaga);
  yield takeLatest(
    studentActions.NOTES_BOOKMARKS_LIST.get_by_submodule_id,
    getNotesAndBookmarksBySubModuleSaga
  );
  yield takeLatest(
    studentActions.NOTES_BOOKMARKS_LIST.get_by_module_id,
    getNotesAndBookmarksByModuleSaga
  );
  yield takeLatest(
    studentActions.SUBMIT_QUIZ.submit_quiz,
    submitAssessmentSaga
  );
  yield takeLatest(
    studentActions.PENDING_ASSESSMENT.get_pending_assessment,
    getPendingAssessmentListSaga
  );
  yield takeLatest(
    studentActions.PENDING_ASSIGNMENT.get_pending_quiz,
    getPendingQuizListSaga
  );

  // yield takeLatest(
  //   studentActions.GET_ASSIGNMENT_BY_STUDENT_ID.get,
  //   getQuizAssessmentSaga
  // );
  yield takeLatest(studentActions.ASSESSMENT_EDIT.edit, putAssessmentSaga);
  yield takeLatest(studentActions.QUIZ_EDIT.edit, putQuizSaga);
  yield takeLatest(
    studentActions.ASSESSMENT_DELETE.delete,
    deleteAssessentSaga
  );
  yield takeLatest(studentActions.QUIZ_DELETE.delete, deleteQuizSaga);
  yield takeLatest(
    studentActions.GET_STUDENT_ACTIVITIES.get_student_activities,
    getStudentActivitiesSaga
  );
  yield takeLatest(studentActions.ALL_USERS.get, getAllUsersSagaFunction);
  yield takeLatest(
    studentActions.ASSIGNMENT_BY_ID.get_quiz_for_edit,
    getQuizByIdForEditSaga
  );
  yield takeLatest(
    studentActions.STUDENT_SEMESTER.create_student_program_details_action,
    createStudentProgramDetailsSaga
  );
  yield takeLatest(
    studentActions.STUDENT_SEMESTER.get_student_program_details_action,
    getStudentProgramDetailsSaga
  );
  yield takeLatest(
    studentActions.STUDENT_SEMESTER.update_student_program_details_action,
    updateStudentProgramDetailsSaga
  );
  yield takeLatest(
    studentActions.STUDENT_SEMESTER.delete_student_program_details_action,
    deletStudentProgramDetailsSaga
  );
  yield takeLatest(
    studentActions.NOTES_BOOKMARKS_LIST.get_by_content,
    getNotesByContentSaga
  );
  yield takeLatest(
    studentActions.NOTES_BOOKMARKS_LIST.addUpdate_bookmarks,
    createBookmarksSaga
  );
  yield takeLatest(
    studentActions.SUBMIT_QUIZ.submit_learning_quiz_request,
    submitLearningQuizSaga
  );
  yield takeLatest(
    studentActions.SUBMIT_QUIZ.learning_quiz_request,
    fetchLearningQuizSaga
  );
  yield takeLatest(
    studentActions.SUBMIT_QUIZ.submit_learning_assignment_request,
    submitLearningAssignmentSaga
  );
  yield takeLatest(
    studentActions.SUBMIT_QUIZ.learning_assignment_request,
    fetchLearningAssignmentSaga
  );
  yield takeLatest(
    studentActions.VIDEO_READINGS_PROGRESS.reading_progress,
    postReadingProgressSaga
  );
  yield takeLatest(
    studentActions.VIDEO_READINGS_PROGRESS.video_progress,
    postVideoProgressSaga
  );

  yield takeLatest(
    studentActions.VIDEO_READINGS_PROGRESS.get_reading_progress,
    getReadingProgressSaga
  );
  yield takeLatest(
    studentActions.VIDEO_READINGS_PROGRESS.get_video_progress,
    getVideoProgressSaga
  );

  // Year List
  yield takeLatest(
    studentActions.STUDENT_SEMESTER.GET_STUDENT_YEAR_LIST_REQUEST,
    getStudentYearListSaga
  );

  // Sem List
  yield takeLatest(
    studentActions.STUDENT_SEMESTER.GET_STUDENT_SEMESTER_LIST_REQUEST,
    getStudentSemesterListSaga
  );

  yield takeLatest(
    studentActions.GET_SUBJECT_PROGRAM_DETAILS.get,
    getSubjectProgramDetailsSaga
  );
  yield takeLatest(
    studentActions.GET_STUDENT_CONNECT.GET_STUDENT,
    getStudentConnectSaga
  );

  yield takeLatest(
    studentActions.GET_STUDENT_CONNECT.GET_STUDENT_NOTIFICTION,
    getStudentNotificationSaga
  );
  yield takeLatest(
    studentActions.GET_STUDENT_CONNECT.CREATE_STUDENT_NOTIFICTION,
    createStudentNotificationSaga
  );
  yield takeLatest(
    studentActions.GET_STUDENT_CONNECT.DELETE_STUDENT_NOTIFICTION,
    deleteStudentNotificationSaga
  );
  yield takeLatest(
    studentActions.GET_STUDENT_CONNECT.BULK_DELETE_STUDENT_NOTIFICTION,
    deleteBulkStudentNotificationSaga
  );
}

export default function* studentSaga() {
  yield all([watchDummy()]);
}
