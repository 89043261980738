export const prospectusActions = {
  APPLICATION_FORM: {
    patch: 'PATCH_APPLICATION_FORM',
    patch_success: 'PATCH_APPLICATION_FORM_SUCCESS',
    patch_error: 'PATCH_APPLICATION_FORM_ERROR',
    post: 'POST_APPLICATION_FORM',
    post_success: 'POST_APPLICATION_FORM_SUCCESS',
    post_error: 'POST_APPLICATION_FORM_ERROR',
    patch_fees: 'PATCH_COURSE_FEES_REQUEST',
    patch_fees_success: 'PATCH_COURSE_FEES_SUCCESS',
    patch_fees_error: 'PATCH_COURSE_FEES_ERROR'
  },
  PROSPECTUS_FORM_LIST: {
    get: 'GET_PROSPECTUS_FORM',
    get_success: 'GET_PROSPECTUS_FORM_SUCCESS',
    get_error: 'GET_PROSPECTUS_FORM_ERROR',
    get_form: 'GET_SINGLE_PROSPECTUS_FORM',
    get_form_success: 'GET_SINGLE_PROSPECTUS_FORM_SUCCESS',
    get_form_error: 'GET_SINGLE_PROSPECTUS_FORM_ERROR',
  },
};
