import * as React from 'react';

const WarningIcon = ({ fill, ...rest }) => {
  return (
    <svg
      id="ico_warning_32"
      xmlns="http://www.w3.org/2000/svg"
      height={rest?.height ?? '32'}
      viewBox={rest?.viewBox ?? '0 0 32 32'}
      width={rest?.width ?? '32'}
      fill={fill}
    >
      <rect
        id="icon_base"
        data-name="icon base"
        width="32"
        height="32"
        fill="none"
      />
      <g id="bx-error" transform="translate(0.453 -0.184)">
        <path
          id="Path_43671"
          data-name="Path 43671"
          d="M14.668,13.333h2.667V20H14.668Zm0,8h2.667V24H14.667Z"
          fill="#fe9700"
        />
        <path
          id="Path_43672"
          data-name="Path 43672"
          d="M18.357,5.6a2.669,2.669,0,0,0-4.714,0L3.859,24.085A2.667,2.667,0,0,0,6.215,28H25.786a2.667,2.667,0,0,0,2.357-3.915ZM6.215,25.333,16,6.849l9.792,18.484Z"
          fill="#fe9700"
        />
      </g>
    </svg>
  );
};

export default WarningIcon;
