import { calendarActions } from './Calendar.constants';
const { CALENDAR } = calendarActions;

export const getEvents = (payload) => ({
  type: CALENDAR.get,
  payload: payload,
});

export const getEventsSuccess = (payload) => ({
  type: CALENDAR.get_success,
  payload: payload,
});

export const createCalendarEvent = (payload) => ({
  type: CALENDAR.create,
  payload: payload,
});

export const updateCalendarEvent = (payload) => ({
  type: CALENDAR.update,
  payload: payload,
});

export const deleteCalendarEvent = (payload) => ({
  type: CALENDAR.delete,
  payload: payload,
});

export const createCalendarEventSuccess = (payload) => ({
  type: CALENDAR.create_success,
  payload: payload,
});

export const updateEventSuccess = (payload) => ({
  type: CALENDAR.update_event_success,
  payload: payload,
});

export const getUserDetailsAction = (payload) => ({
  type: CALENDAR.get_user_details,
  payload: payload,
});

export const getUserDetailsSuccess = (payload) => ({
  type: CALENDAR.get_user_details_success,
  payload: payload,
});

export const setEventsLoading = (payload) => ({
  type: CALENDAR.loading,
  payload: payload,
});

export const getCombinedListAction = () => ({
  type: CALENDAR.get_combined_list,
});

export const getCombinedListSuccess = (payload) => ({
  type: CALENDAR.get_combined_list_success,
  payload: payload,
});

export const getCombinedListError = (payload) => ({
  type: CALENDAR.get_combined_list_error,
  payload: payload,
});

export const getEmailList = (payload) => ({
  type: CALENDAR.get_email_list,
  payload: payload,
});

export const getEmailListSuccess = (payload) => ({
  type: CALENDAR.get_email_list_success,
  payload: payload,
});

export const getEmailListError = (payload) => ({
  type: CALENDAR.get_email_list_error,
  payload: payload,
});
