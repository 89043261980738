import React from 'react';

const TimerIcon = ({ fill, height = 40, width = 40 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      fill={fill}
    >
      <path d="M360 202.666V136h240v66.666H360Zm86.667 444.667h66.666V414h-66.666v233.333Zm33.333 328q-74 0-139.5-28.5t-114.5-77.5q-49-49-77.5-114.5t-28.5-139.5q0-74 28.5-139.5t77.5-114.5q49-49 114.5-77.5t139.5-28.5q65.333 0 123.667 21.667Q662 298.667 709.333 338L762 285.334 808.666 332 756 384.667Q792.666 428 816.333 485T840 615.333q0 74-28.5 139.5t-77.5 114.5q-49 49-114.5 77.5t-139.5 28.5Zm0-66.666q122 0 207.667-85.667t85.667-207.667q0-122-85.667-207.667Q602 322 480 322t-207.667 85.666q-85.667 85.667-85.667 207.667T272.333 823Q358 908.667 480 908.667ZM480 616Z" />
    </svg>
  );
};

export default TimerIcon;
