import { chatActions } from './Chat.constants';

const getChatHistory = (payload) => {
  return {
    type: chatActions.GET_CHAT_HISTORY,
    payload: payload,
  };
};
const getChatHistorySuccess = (assessments) => {
  return {
    type: chatActions.GET_CHAT_HISTORY_SUCCESS,
    payload: assessments,
  };
};
const getChatHistoryFail = (error) => {
  return {
    type: chatActions.GET_CHAT_HISTORY_FAIL,
    payload: error,
  };
};

const setChatHistory = (roomId, chat) => {
  return {
    type: chatActions.SET_CHAT_HISTORY_SUCCESS,
    payload: { roomId, chat },
  };
};

const setChatMessage = (roomId, chat) => {
  return {
    type: chatActions.SET_CHAT_MESSAGE,
    payload: { roomId, chat },
  };
};

const setChatHistorySuccess = (details) => {
  return {
    type: chatActions.SET_CHAT_HISTORY_SUCCESS,
    payload: details,
  };
};
const setChatHistoryFail = (error) => {
  return {
    type: chatActions.SET_CHAT_HISTORY_FAIL,
    payload: error,
  };
};
const setChatHistoryReset = (payload) => {
  return {
    type: chatActions.SET_CHAT_HISTORY_RESET,
    payload: payload,
  };
};
const resetChatHistory = (payload) => {
  return {
    type: chatActions.CHAT_HISTORY_RESET,
    payload: payload,
  };
};

export {
  getChatHistory,
  getChatHistorySuccess,
  getChatHistoryFail,
  setChatHistory,
  setChatHistorySuccess,
  setChatHistoryFail,
  setChatHistoryReset,
  setChatMessage,
  resetChatHistory,
};
