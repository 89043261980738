import { statusCode } from 'constants/apis/apiStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  DownloadMissionError,
  DownloadMissionSuccess,
  bulkDeleteApi,
  bulkDeleteError,
  bulkDeleteSuccess,
  courseManagementActions,
  createCbcApi,
  createClustersError,
  createClustersSuccess,
  createDepartmentError,
  createDepartmentSuccess,
  createGradePointsApi,
  createGradePointsErrorAction,
  createGradePointsSaveFeedbackAction,
  createGradePointsSuccessAction,
  createJoinSessionForVirtutorApi,
  createJoinSessionForVirtutorError,
  createJoinSessionForVirtutorLoader,
  createJoinSessionForVirtutorSuccess,
  createLiveSessionApi,
  createLiveSessionDataError,
  createLiveSessionSuccessAction,
  createMissionForDepartmentApi,
  createMissionForDepartmentSuccess,
  createProgramApi,
  createProgramSuccess,
  createProgramSuccessFeedback,
  createProgramYearApi,
  createProgramYearSuccess,
  createRubricConfigApi,
  createRubricConfigErrorAction,
  createRubricConfigSaveFeedbackAction,
  createRubricConfigSuccessAction,
  createRubricTableErrorAction,
  createRubricTableSuccessAction,
  createSemesterSuccess,
  createSemesterYearApi,
  createSubjectApi,
  createSubjectSuccess,
  createUniversityMissionApi,
  createUniversityMissionError,
  createUniversityMissionSuccess,
  createUniversityVissionApi,
  createUniversityVissionError,
  createUniversityVissionSuccess,
  createVisionForDepartmentApi,
  createVisionForDepartmentError,
  createVisionForDepartmentSuccess,
  // createdLiveSessionSuccessData,
  createdLiveSessionSuccessDataAction,
  deleteClusterApi,
  deleteDepartmentApi,
  deleteDepartmentSuccess,
  deleteFacultySubjectLinkApi,
  deleteFacultySubjectLinkSuccess,
  deleteProgramApi,
  deleteProgramSuccess,
  deleteProgramYearApi,
  deleteProgramYearSuccess,
  deleteSemesterSuccess,
  deleteSemesterYearApi,
  deleteSubjectApi,
  deleteSubjectSuccess,
  downloadMissionApi,
  downloadVisionApi,
  editCbcApi,
  editFacultySubjectLinkDetailsApi,
  editFacultySubjectLinkDetailsSuccess,
  editLiveSessionDataError,
  editRubricConfigApi,
  facultySubjectLinkSuccess,
  facultySubjectLinkingApi,
  getCBCListSuccessAction,
  getCbcListApi,
  getClustersApi,
  getClustersByPaginationError,
  getClustersByPaginationSuccess,
  getClustersError,
  getClustersIncrSuccess,
  getClustersLoading,
  getClustersSuccess,
  getCourseListByDepartmentIdApi,
  getCourseListByDepartmentIdError,
  getCourseListByDepartmentIdSuccess,
  getCourseOutcomeApi,
  getCourseOutcomeSuccess,
  getDepartmentByIdApi,
  getDepartmentDetailsError,
  getDepartmentDetailsSuccess,
  getDepartmentLoading,
  getDepartmentSuccess,
  getDepartmentVisionError,
  getDepartmentVisionSuccess,
  getDepartmentsApi,
  getDepartmentsByClusterIdApi,
  getDepartmentsByClusterIdByPaginationError,
  getDepartmentsByClusterIdByPaginationSuccess,
  getDepartmentsByClusterIdError,
  getDepartmentsByClusterIdSuccess,
  getDepartmentsIncrSuccess,
  getFacultySubjectDetailsSuccess,
  getGradePointsApi,
  getGradePointsSuccessAction,
  getProgramByIdSuccess,
  getProgramDetailsApi,
  getProgramListApi,
  getProgramListSuccess,
  getProgramListSuccessIncr,
  getProgramYearByIdApi,
  getProgramYearListApi,
  getProgramYearListSuccess,
  getRubricConfigApi,
  getRubricConfigSuccessAction,
  getSemesterByIdApi,
  getSemesterListApi,
  getSemesterListSuccess,
  getSessionByIdApi,
  getSessionByIdSuccessAction,
  getSessionForSelectedSubModuleIdApi,
  getSessionForSelectedSubModuleIdSuccessAction,
  getSubjectByIdApi,
  getSubjectByIdSuccess,
  getSubjectListApi,
  getSubjectListIncrSuccess,
  getSubjectListSuccess,
  getSuccessProgramsListByUserId,
  getUniversityMissionApi,
  getUniversityMissionError,
  getUniversityMissionSuccess,
  getUniversityVissionApi,
  getUniversiyVissionError,
  getUserMappingByUserIdApi,
  getUserMappingByUserIdError,
  getUserMappingByUserIdSuccess,
  getfacultySubjectDetailsApi,
  getuniversityVissionActionSuccess,
  patchProgramApi,
  patchProgramSuccess,
  postClustersApi,
  postDepartmentApi,
  saveProgramErrorAction,
  saveProgramYearErrorAction,
  saveSemesterErrorAction,
  saveSubjectErrorAction,
  sendApprovalApi,
  sendApprovalError,
  sendApprovalSuccess,
  setCourseListLoading,
  setDepartmentDetailsLoading,
  setGradePointsLoading,
  setLiveSessionLoading,
  setProgramLoading,
  setRubricConfigLoading,
  setRubricTableELoading,
  updateClusterApi,
  updateDepartmentApi,
  updateGradePointsApi,
  updateProgramApi,
  updateProgramYearApi,
  updateSchemaApi,
  updateSemesterSuccess,
  updateSemesterYearApi,
  updateSessionApi,
  updateSessionSuccess,
  updateSubjectApi,
  updateUserToProgramMappingApi,
  updateUserToProgramMappingError,
  updateUserToProgramMappingSuccess,
  uploadSchemaError,
  uploadSchemaSuccess,
} from 'store/CourseManagement';
import { setToastMessage } from 'store/Toast';
function* getClustersSaga({ payload }) {
  try {
    yield put(getClustersLoading(true));
    const response = yield call(getClustersApi, payload);
    yield put(getClustersLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getClustersSuccess(response?.data));
    } else {
      yield put(getClustersError(response?.data));
    }
  } catch (error) {
    console.error(error);
    yield put(getClustersLoading(false));
  }
}

function* getClustersIncrSaga({ payload }) {
  try {
    yield put(getClustersLoading(true));
    const response = yield call(getClustersApi, payload);
    yield put(getClustersLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getClustersIncrSuccess(response?.data));
    } else {
      yield put(getClustersError(response?.data));
    }
  } catch (error) {
    console.error(error);
    yield put(getClustersLoading(false));
  }
}

function* getClustersByPaginationSaga({ payload }) {
  try {
    yield put(getClustersLoading(true));
    const response = yield call(getClustersApi, payload);
    yield put(getClustersLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getClustersByPaginationSuccess(response?.data));
    } else {
      yield put(getClustersByPaginationError(response?.data));
    }
  } catch (error) {
    console.error(error);
    yield put(getClustersLoading(false));
  }
}

function* createClustersSaga({ payload }) {
  try {
    yield put(getClustersLoading(true));
    const response = yield call(postClustersApi, payload);
    yield put(getClustersLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createClustersSuccess(true));
      yield put(
        setToastMessage({
          data: 'Cluster creation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createClustersError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(getClustersLoading(false));
  }
}

function* getDepartmentSaga({ payload }) {
  try {
    yield put(getDepartmentLoading(true));
    const response = yield call(getDepartmentsApi, payload);
    yield put(getDepartmentLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDepartmentSuccess(response?.data));
    } else {
      yield put(getDepartmentsByClusterIdError(response?.data));
    }
  } catch (error) {
    console.error(error);
    yield put(getDepartmentLoading(false));
  }
}

function* getDepartmentsIncrSaga({ payload }) {
  try {
    yield put(getDepartmentLoading(true));
    const response = yield call(getDepartmentsApi, payload);
    yield put(getDepartmentLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDepartmentsIncrSuccess(response?.data));
    } else {
      yield put(getDepartmentsByClusterIdError(response?.data));
    }
  } catch (error) {
    console.error(error);
    yield put(getDepartmentLoading(false));
  }
}

function* getDepartmentsByClusterIdSaga({ payload }) {
  try {
    yield put(getDepartmentLoading(true));
    const response = yield call(getDepartmentsByClusterIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getDepartmentsByClusterIdSuccess(response?.data));
    } else {
      yield put(getDepartmentsByClusterIdError(response?.data));
    }
    yield put(getDepartmentLoading(false));
  } catch (error) {
    console.error(error);
    yield put(getDepartmentLoading(false));
  }
}
function* getDepartmentsByClusterIdByPaginationSaga({ payload }) {
  try {
    yield put(getDepartmentLoading(true));
    const response = yield call(getDepartmentsByClusterIdApi, payload);
    yield put(getDepartmentLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDepartmentsByClusterIdByPaginationSuccess(response?.data));
    } else {
      yield put(getDepartmentsByClusterIdByPaginationError(response?.data));
    }
  } catch (error) {
    console.error(error);
    yield put(getDepartmentLoading(false));
  }
}

function* createDepartmentSaga({ payload }) {
  try {
    yield put(getDepartmentLoading(true));
    const response = yield call(postDepartmentApi, payload);
    yield put(getDepartmentLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createDepartmentSuccess(true));
    } else {
      yield put(createDepartmentError(response?.data));
    }
  } catch (error) {
    yield put(createDepartmentError(error?.data));
  }
}

function* getDepartmentByIdSaga({ payload }) {
  try {
    yield put(setDepartmentDetailsLoading(true));
    const response = yield call(getDepartmentByIdApi, payload);
    yield put(setDepartmentDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDepartmentDetailsSuccess(response?.data));
    } else {
      yield put(getDepartmentDetailsError(response?.data));
    }
  } catch (error) {
    yield put(createDepartmentError(error?.data));
  }
}

function* getCourseListByDepartmentIdByScrollSaga({ payload }) {
  try {
    yield put(setCourseListLoading(true));
    const response = yield call(getCourseListByDepartmentIdApi, payload);
    yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseListByDepartmentIdSuccess(response?.data));
    } else {
      yield put(getCourseListByDepartmentIdError(response?.data));
    }
  } catch (error) {
    // yield put(getCourseListError(error?.data));
  }
}
function* putDepartmentSaga({ payload }) {
  try {
    yield put(getDepartmentLoading(true));
    const response = yield call(updateDepartmentApi, payload);
    yield put(getDepartmentLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createDepartmentSuccess(true));
      yield put(
        setToastMessage({
          data: 'Department Updated',
          severity: 'success',
        })
      );
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteDepartmentSaga({ payload }) {
  try {
    yield put(getDepartmentLoading(true));
    const response = yield call(deleteDepartmentApi, payload);
    yield put(getDepartmentLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteDepartmentSuccess(true));
      yield put(
        setToastMessage({
          data: 'Department deleted',
          severity: 'success',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteClusterSaga({ payload }) {
  try {
    yield put(getClustersLoading(true));
    const response = yield call(deleteClusterApi, payload);
    yield put(getClustersLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createClustersSuccess(true));
      yield put(
        setToastMessage({
          data: 'Cluster deleted',
          severity: 'success',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateClusterSaga({ payload }) {
  try {
    yield put(getClustersLoading(true));
    const response = yield call(updateClusterApi, payload);
    yield put(getClustersLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createClustersSuccess(true));
      yield put(
        setToastMessage({
          data: 'Cluster Updated',
          severity: 'success',
        })
      );
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* createLiveSessionSaga({ payload }) {
  try {
    yield put(setLiveSessionLoading(true));
    const response = yield call(createLiveSessionApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createdLiveSessionSuccessDataAction(response?.data));
      yield put(
        setToastMessage({
          data: 'Session created successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(createLiveSessionDataError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setLiveSessionLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setLiveSessionLoading(false));
  }
}

function* updateLiveSessionSaga({ payload }) {
  try {
    yield put(setLiveSessionLoading(true));
    const response = yield call(updateSessionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(createLiveSessionSuccessAction(true));
      yield put(updateSessionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Session updated successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(editLiveSessionDataError(response?.data));
      console.log('response?.data', response?.data);
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(setLiveSessionLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setLiveSessionLoading(false));
  }
}

function* getLiveSessionByIdSaga({ payload }) {
  try {
    yield put(setLiveSessionLoading(true));
    const response = yield call(getSessionByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSessionByIdSuccessAction(response?.data));
    }
    yield put(setLiveSessionLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setLiveSessionLoading(false));
  }
}

function* getLiveSessionBySubmoduleIdSaga({ payload }) {
  try {
    yield put(setLiveSessionLoading(true));
    const response = yield call(getSessionForSelectedSubModuleIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSessionForSelectedSubModuleIdSuccessAction(response?.data));
    }
    yield put(setLiveSessionLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setLiveSessionLoading(false));
  }
}

function* createRubricConfigSaga({ payload }) {
  try {
    yield put(setRubricConfigLoading(true));
    const response = yield call(createRubricConfigApi, payload);
    yield put(setRubricConfigLoading(false));
    yield put(createRubricConfigSaveFeedbackAction(response?.data));
    yield put(getRubricConfigSuccessAction(response?.data));
    if (response?.status === statusCode.Created201) {
      yield put(createRubricConfigSuccessAction(true));
      yield put(
        setToastMessage({
          data: 'Rubric creation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createRubricConfigErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getRubricConfigSaga({ payload }) {
  try {
    yield put(setRubricConfigLoading(true));
    const response = yield call(getRubricConfigApi, payload);
    yield put(setRubricConfigLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getRubricConfigSuccessAction(response?.data));
    } else {
      yield put(getRubricConfigSuccessAction({}));
      yield put(
        setToastMessage({
          data: 'Please add the rubrics',
          severity: 'info',
        })
      );
    }
  } catch (error) {
    yield put(getRubricConfigSuccessAction({}));
    yield put(
      setToastMessage({
        data: 'Failed to get rubrics config',
        severity: 'error',
      })
    );
  }
}

function* editRubricConfigSaga({ payload }) {
  try {
    yield put(setRubricConfigLoading(true));
    const response = yield call(editRubricConfigApi, payload);
    yield put(setRubricConfigLoading(false));
    yield put(createRubricConfigSaveFeedbackAction(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(createRubricConfigSuccessAction(true));
      yield put(
        setToastMessage({
          data: 'Rubric updated',
          severity: 'success',
        })
      );
    } else {
      yield put(createRubricConfigErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* createRubricTableSaga({ payload }) {
  try {
    yield put(setRubricTableELoading(true));
    const response = yield call(createCbcApi, payload);
    yield put(setRubricTableELoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createRubricTableSuccessAction(true));
      yield put(
        setToastMessage({
          data: 'CBC Created',
          severity: 'success',
        })
      );
    } else {
      yield put(createRubricTableErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}
function* editRubricTableSaga({ payload }) {
  try {
    yield put(setRubricTableELoading(true));
    const response = yield call(editCbcApi, payload);
    yield put(setRubricTableELoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createRubricTableSuccessAction(response));
      yield put(
        setToastMessage({
          data: 'CBC updated',
          severity: 'success',
        })
      );
    } else {
      yield put(createRubricTableErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getCBCListSaga({ payload }) {
  try {
    yield put(setRubricTableELoading(true));
    const response = yield call(getCbcListApi, payload);
    yield put(setRubricTableELoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getCBCListSuccessAction(response?.data));
    } else {
      yield put(getCBCListSuccessAction([]));
      yield put(
        setToastMessage({
          data: 'Failed to fetch Cbc list',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getCBCListSuccessAction([]));
    yield put(
      setToastMessage({
        data: 'Failed to fetch Cbc list',
        severity: 'error',
      })
    );
  }
}

function* createProgramSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(createProgramApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createProgramSuccess(true));
      yield put(createProgramSuccessFeedback(response?.data));
      yield put(
        setToastMessage({
          data: 'Program Creation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(saveProgramErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* patchProgramSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(patchProgramApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(patchProgramSuccess(response?.data));
    } else {
      yield put(saveProgramErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateProgramSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(updateProgramApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createProgramSuccess(true));
      yield put(createProgramSuccessFeedback(response?.data));
      if (response?.data?.is_archived == false) {
        yield put(
          setToastMessage({
            data: 'Program Restored successful',
            severity: 'success',
          })
        );
      } else {
        yield put(
          setToastMessage({
            data: 'Program Archived successful',
            severity: 'success',
          })
        );
      }
    } else {
      yield put(saveProgramErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteProgramSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(deleteProgramApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteProgramSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProgramListSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getProgramListApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getProgramListSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProgramListIncrSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getProgramListApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getProgramListSuccessIncr(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProgramDetailsByIdSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getProgramDetailsApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getProgramByIdSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}
function* createProgramYearSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(createProgramYearApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createProgramYearSuccess(response?.data));
    } else {
      yield put(saveProgramYearErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateProgramYearSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(updateProgramYearApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createProgramYearSuccess(response?.data));
    } else {
      yield put(saveProgramYearErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteProgramYearSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(deleteProgramYearApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteProgramYearSuccess(response?.data));
    } else {
      if (response?.data?.error)
        yield put(
          setToastMessage({
            data: response?.data?.message,
            severity: 'error',
          })
        );
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProgramYearByIdSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getProgramYearByIdApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteProgramYearSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProgramYearListSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getProgramYearListApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getProgramYearListSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* createSemesterSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(createSemesterYearApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createSemesterSuccess(response?.data));
    } else {
      yield put(saveSemesterErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateSemesterSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(updateSemesterYearApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(updateSemesterSuccess(response?.data));
    } else {
      yield put(saveSemesterErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteSemesterSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(deleteSemesterYearApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteSemesterSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getSemesterByIdSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getSemesterByIdApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(deleteSemesterSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getSemesterListSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getSemesterListApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSemesterListSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* createSubjectSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(createSubjectApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createSubjectSuccess(response?.data));
    } else {
      yield put(saveSubjectErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateSubjectSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(updateSubjectApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createSubjectSuccess(response?.data));
    } else {
      yield put(saveSubjectErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteSubjectSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(deleteSubjectApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteSubjectSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getSubjectByIdSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getSubjectByIdApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(getSubjectByIdSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getSubjectListSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getSubjectListApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSubjectListSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getSubjectListIncrSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getSubjectListApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSubjectListIncrSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* facultySubjectLinkSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(facultySubjectLinkingApi, payload);
    yield put(setProgramLoading(false));

    if (response?.status === statusCode.Created201) {
      yield put(facultySubjectLinkSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Operation Successful',
          severity: 'success',
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* facultySubjectDetailsSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getfacultySubjectDetailsApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultySubjectDetailsSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* programsListByUserId({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(getfacultySubjectDetailsApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessProgramsListByUserId(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteFacultySubjectLinkSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(deleteFacultySubjectLinkApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteFacultySubjectLinkSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* editFacultySubjectLinkSaga({ payload }) {
  try {
    yield put(setProgramLoading(true));
    const response = yield call(editFacultySubjectLinkDetailsApi, payload);
    yield put(setProgramLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(editFacultySubjectLinkDetailsSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* createGradePointSaga({ payload }) {
  try {
    yield put(setGradePointsLoading(true));
    const response = yield call(createGradePointsApi, payload);
    yield put(setGradePointsLoading(false));
    yield put(createGradePointsSaveFeedbackAction(response?.data));
    if (response?.status === statusCode.Created201) {
      yield put(createGradePointsSuccessAction(true));
      yield put(
        setToastMessage({
          data: 'Grade points  addition successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createGradePointsErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}
function* updateGradePointSaga({ payload }) {
  try {
    yield put(setGradePointsLoading(true));
    const response = yield call(updateGradePointsApi, payload);
    yield put(setGradePointsLoading(false));
    yield put(createGradePointsSaveFeedbackAction(response?.data));
    yield put(createGradePointsSuccessAction(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(createGradePointsErrorAction(true));
      yield put(
        setToastMessage({
          data: 'Grade points update successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createGradePointsErrorAction(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getGradePointSaga({ payload }) {
  try {
    yield put(setGradePointsLoading(true));
    const response = yield call(getGradePointsApi, payload);

    yield put(setGradePointsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getGradePointsSuccessAction(response?.data));
    } else {
      yield put(getGradePointsSuccessAction({}));
    }
  } catch (error) {
    console.log(error);
  }
}

// get user mapping by user Id

function* getUserMappingByUserIdSaga({ payload }) {
  try {
    const response = yield call(getUserMappingByUserIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUserMappingByUserIdSuccess(response?.data));
    } else {
      yield put(getUserMappingByUserIdError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getUserMappingByUserIdError(error));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

// update user to program mapping

function* updateUserToProgramMappingSaga({ payload }) {
  try {
    const response = yield call(updateUserToProgramMappingApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateUserToProgramMappingSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Updated successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(updateUserToProgramMappingError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateUserToProgramMappingError(error));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

// Join Session Virtutor API
function* createJoinSessionForVirtutorSaga({ payload }) {
  try {
    yield put(createJoinSessionForVirtutorLoader(true));
    const response = yield call(createJoinSessionForVirtutorApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(createJoinSessionForVirtutorSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Joined Session Successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(createJoinSessionForVirtutorError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(createJoinSessionForVirtutorLoader(false));
  } catch (error) {
    console.error(error);
    yield put(createJoinSessionForVirtutorLoader(false));
  }
}

// Create vision for department
function* createVisionForDepartmentSaga({ payload }) {
  try {
    const response = yield call(createVisionForDepartmentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createVisionForDepartmentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Vision Created Successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(createVisionForDepartmentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}
function* createMissionForDepartmentSaga({ payload }) {
  try {
    const response = yield call(createMissionForDepartmentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createMissionForDepartmentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Mission Created Successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(createMissionForDepartmentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

// Download Vision saga
function* getDepartmentVisionSaga({ payload }) {
  try {
    const response = yield call(downloadVisionApi, payload);
    if (
      response?.status === statusCode.Ok200 ||
      response?.status === statusCode.NoContent204
    ) {
      yield put(getDepartmentVisionSuccess(response?.data));
    } else {
      yield put(getDepartmentVisionError(response?.data));
    }
  } catch (error) {
    yield put(getDepartmentVisionError(error));
  }
}

// Download Mission saga
function* downloadMissionSaga({ payload }) {
  try {
    const response = yield call(downloadMissionApi, payload);
    if (
      response?.status === statusCode.Ok200 ||
      response?.status === statusCode.NoContent204
    ) {
      yield put(DownloadMissionSuccess(response?.data));
      // yield put(
      //   setToastMessage({
      //     data: '',
      //     severity: 'success',
      //   })
      // );
    } else {
      yield put(DownloadMissionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* getUniversityVissionSaga() {
  try {
    const response = yield call(getUniversityVissionApi);
    if (response?.status === statusCode.Ok200) {
      yield put(getuniversityVissionActionSuccess(response?.data));
    } else {
      yield put(getUniversiyVissionError(response?.data));
    }
  } catch (error) {
    yield put(getUniversiyVissionError(error));
  }
}
function* createUniversityVissionSaga({ payload }) {
  try {
    const response = yield call(createUniversityVissionApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createUniversityVissionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Vision Created Successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(createUniversityVissionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: error?.message,
        severity: 'error',
      })
    );
  }
}

function* getUniversityMissionSaga({ payload }) {
  try {
    const response = yield call(getUniversityMissionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUniversityMissionSuccess(response?.data));
    } else {
      yield put(getUniversityMissionError(response?.data));
      yield put(
        setToastMessage({
          data: 'Could not fetch vision',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    setToastMessage({
      data: error?.message,
      severity: 'error',
    });
  }
}

function* createUniversityMissionSaga({ payload }) {
  try {
    const response = yield call(createUniversityMissionApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createUniversityMissionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Mission Created Successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(createUniversityMissionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: error?.message,
        severity: 'error',
      })
    );
  }
}

function* getCourseOutcomeSaga({ payload }) {
  try {
    const response = yield call(getCourseOutcomeApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseOutcomeSuccess(response?.data));
    } else {
      yield put(getUniversityMissionError(response?.data));
      yield put(
        setToastMessage({
          data: 'Could not fetch Course Outcome',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    setToastMessage({
      data: error?.message,
      severity: 'error',
    });
  }
}
function* sendApprovalSaga({ payload }) {
  try {
    yield put(setCourseListLoading(true));
    const response = yield call(sendApprovalApi, payload);
    yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(sendApprovalSuccess(response?.data));
    } else {
      yield put(sendApprovalError(response?.data));

      // setToastMessage({
      //   data: 'Could not fetch Course Outcome',
      //   severity: 'error',
      // })
    }
  } catch (error) {
    setToastMessage({
      data: error?.message,
      severity: 'error',
    });
  }
}
function* bulkDeleteSaga({ payload }) {
  try {
    yield put(setCourseListLoading(true));
    const response = yield call(bulkDeleteApi, payload);
    yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(bulkDeleteSuccess(response?.data));
    } else {
      yield put(bulkDeleteError(response?.data));
    }
  } catch (error) {
    setToastMessage({
      data: error?.message,
      severity: 'error',
    });
  }
}
function* updateScehmaSaga({ payload }) {
  try {
    yield put(setCourseListLoading(true));
    const response = yield call(updateSchemaApi, payload);
    yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(uploadSchemaSuccess(response?.data));
    } else {
      yield put(uploadSchemaError(response?.data));
    }
  } catch (error) {
    setToastMessage({
      data: error?.message,
      severity: 'error',
    });
  }
}

export function* watchDummy() {
  yield takeLatest(courseManagementActions.CLUSTERS.get, getClustersSaga);
  yield takeLatest(
    courseManagementActions.CLUSTERS.get_by_pagination,
    getClustersByPaginationSaga
  );
  yield takeLatest(
    courseManagementActions.CLUSTERS.get_incr,
    getClustersIncrSaga
  );
  yield takeLatest(courseManagementActions.CLUSTERS.create, createClustersSaga);
  yield takeLatest(courseManagementActions.DEPARTMENT.get, getDepartmentSaga);
  yield takeLatest(
    courseManagementActions.DEPARTMENT.get_incr,
    getDepartmentsIncrSaga
  );
  yield takeLatest(
    courseManagementActions.DEPARTMENT.get_by_cluster_id,
    getDepartmentsByClusterIdSaga
  );
  yield takeLatest(
    courseManagementActions.DEPARTMENT.get_by_cluster_id_by_pagination,
    getDepartmentsByClusterIdByPaginationSaga
  );
  yield takeLatest(
    courseManagementActions.DEPARTMENT.create,
    createDepartmentSaga
  );
  yield takeLatest(
    courseManagementActions.DEPARTMENT_DETAILS.get,
    getDepartmentByIdSaga
  );

  yield takeLatest(
    courseManagementActions.PROGRAM.get_programs_by_user_id,
    programsListByUserId
  );
  yield takeLatest(courseManagementActions.DEPARTMENT.edit, putDepartmentSaga);
  yield takeLatest(
    courseManagementActions.DEPARTMENT.delete,
    deleteDepartmentSaga
  );

  yield takeLatest(courseManagementActions.CLUSTERS.delete, deleteClusterSaga);

  yield takeLatest(courseManagementActions.CLUSTERS.edit, updateClusterSaga);

  yield takeLatest(
    courseManagementActions.LIVE_SESSION.create,
    createLiveSessionSaga
  );

  yield takeLatest(
    courseManagementActions.RUBRICS.create,
    createRubricConfigSaga
  );
  yield takeLatest(courseManagementActions.RUBRICS.get, getRubricConfigSaga);
  yield takeLatest(courseManagementActions.RUBRICS.edit, editRubricConfigSaga);
  yield takeLatest(
    courseManagementActions.RUBRIC_TABLE.create,
    createRubricTableSaga
  );
  yield takeLatest(
    courseManagementActions.RUBRIC_TABLE.edit,
    editRubricTableSaga
  );
  yield takeLatest(courseManagementActions.CBC_LIST.get, getCBCListSaga);

  yield takeLatest(
    courseManagementActions.LIVE_SESSION.get_by_id,
    getLiveSessionByIdSaga
  );

  yield takeLatest(
    courseManagementActions.LIVE_SESSION.edit,
    updateLiveSessionSaga
  );

  yield takeLatest(
    courseManagementActions.LIVE_SESSION.get_by_submoduleId,
    getLiveSessionBySubmoduleIdSaga
  );

  yield takeLatest(courseManagementActions.PROGRAM.create, createProgramSaga);
  yield takeLatest(courseManagementActions.PROGRAM.update, updateProgramSaga);
  yield takeLatest(courseManagementActions.PROGRAM.patch, patchProgramSaga);
  yield takeLatest(courseManagementActions.PROGRAM.delete, deleteProgramSaga);
  yield takeLatest(courseManagementActions.PROGRAM.get, getProgramListSaga);
  yield takeLatest(
    courseManagementActions.PROGRAM.get_incr,
    getProgramListIncrSaga
  );
  yield takeLatest(
    courseManagementActions.PROGRAM.get_by_id,
    getProgramDetailsByIdSaga
  );

  yield takeLatest(
    courseManagementActions.PROGRAM_YEAR.create,
    createProgramYearSaga
  );
  yield takeLatest(
    courseManagementActions.PROGRAM_YEAR.update,
    updateProgramYearSaga
  );
  yield takeLatest(
    courseManagementActions.PROGRAM_YEAR.delete,
    deleteProgramYearSaga
  );
  yield takeLatest(
    courseManagementActions.PROGRAM_YEAR.get,
    getProgramYearListSaga
  );
  yield takeLatest(
    courseManagementActions.PROGRAM_YEAR.get_by_id,
    getProgramYearByIdSaga
  );

  yield takeLatest(courseManagementActions.SEMESTER.create, createSemesterSaga);
  yield takeLatest(
    courseManagementActions.SEMESTER.update,
    updateProgramYearSaga
  );
  yield takeLatest(courseManagementActions.SEMESTER.delete, deleteSemesterSaga);
  yield takeLatest(courseManagementActions.SEMESTER.get, getSemesterListSaga);
  yield takeLatest(
    courseManagementActions.SEMESTER.get_by_id,
    getProgramYearByIdSaga
  );

  yield takeLatest(courseManagementActions.SUBJECT.create, createSubjectSaga);
  yield takeLatest(courseManagementActions.SUBJECT.update, updateSubjectSaga);
  yield takeLatest(courseManagementActions.SUBJECT.delete, deleteSubjectSaga);
  yield takeLatest(courseManagementActions.SUBJECT.get, getSubjectListSaga);
  yield takeLatest(
    courseManagementActions.SUBJECT.get_incr,
    getSubjectListIncrSaga
  );
  yield takeLatest(
    courseManagementActions.SUBJECT.get_by_id,
    getSubjectByIdSaga
  );
  yield takeLatest(
    courseManagementActions.FACULTY_SUBJECT_LINK.faculty_subject_link,
    facultySubjectLinkSaga
  );
  yield takeLatest(
    courseManagementActions.FACULTY_SUBJECT_LINK.get_faculty_subject_details,
    facultySubjectDetailsSaga
  );
  yield takeLatest(
    courseManagementActions.FACULTY_SUBJECT_LINK.delete_faculty_subject_link,
    deleteFacultySubjectLinkSaga
  );
  yield takeLatest(
    courseManagementActions.FACULTY_SUBJECT_LINK
      .edit_faculty_subject_link_details,
    editFacultySubjectLinkSaga
  );

  yield takeLatest(
    courseManagementActions.GRADE_POINTS.create,
    createGradePointSaga
  );

  yield takeLatest(
    courseManagementActions.GRADE_POINTS.update,
    updateGradePointSaga
  );
  yield takeLatest(
    courseManagementActions.GRADE_POINTS.get_by_id,
    getGradePointSaga
  );

  yield takeLatest(
    courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .GET_USER_MAPPING_BY_USER_ID_REQUEST,
    getUserMappingByUserIdSaga
  );

  yield takeLatest(
    courseManagementActions.USER_MAPPING_TO_PROGRAM_SUBJECT
      .UPDATE_USER_MAPPING_BY_USER_ID_REQUEST,
    updateUserToProgramMappingSaga
  );

  // Join Session Virtutor API
  yield takeLatest(
    courseManagementActions.JOIN_SESSION_VIRTUTOR.JOIN_SESSION_VIRTUTOR_CREATE,
    createJoinSessionForVirtutorSaga
  );

  //create Vision For Department
  yield takeLatest(
    courseManagementActions.VISION_FOR_DEPARTMENT.VISION_FOR_DEPARTMENT_CREATE,
    createVisionForDepartmentSaga
  );

  //create Mission For Department
  yield takeLatest(
    courseManagementActions.MISSION_FOR_DEPARTMENT
      .MISSION_FOR_DEPARTMENT_CREATE,
    createMissionForDepartmentSaga
  );

  //Download vision for Department
  yield takeLatest(
    courseManagementActions.DOWNLOAD_FOR_VISION.DOWNLOAD_VISION_REQUEST,
    getDepartmentVisionSaga
  );

  //Download Mission for Department
  yield takeLatest(
    courseManagementActions.DOWNLOAD_FOR_MISSION.DOWNLOAD_MISSION_REQUEST,
    downloadMissionSaga
  );

  yield takeLatest(
    courseManagementActions.VISION_PROGRAM.CREATE_UNIVERSITY_VISSION,
    createUniversityVissionSaga
  );

  yield takeLatest(
    courseManagementActions.MISSION_PROGRAM.CREATE_UNIVERSITY_MISSION,
    createUniversityMissionSaga
  );

  yield takeLatest(
    courseManagementActions.VISION_PROGRAM.GET_UNIVERSITY_VISSION,
    getUniversityVissionSaga
  );

  yield takeLatest(
    courseManagementActions.MISSION_PROGRAM.GET_UNIVERSITY_MISSION,
    getUniversityMissionSaga
  );

  yield takeLatest(
    courseManagementActions.COURSE_OUTCOME.GET_COURSE_OUTCOME,
    getCourseOutcomeSaga
  );
  yield takeLatest(
    courseManagementActions.SEND_APPROVAL.SEND_APPROVAL_ACTION,
    sendApprovalSaga
  );
  yield takeLatest(
    courseManagementActions.BULK_DELETE.BULK_DELETE_ACTION,
    bulkDeleteSaga
  );
  yield takeLatest(
    courseManagementActions.COURSE_LIST.get_by_department_id,
    getCourseListByDepartmentIdByScrollSaga
  );
  yield takeLatest(
    courseManagementActions.UPDATE_SCHEMA.UPDATE_SCHEMA_REQUEST,
    updateScehmaSaga
  );
}
export default function* courseManagementSaga() {
  yield all([watchDummy()]);
}
