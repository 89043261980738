import { GamificationActions } from './Gamification.constants';
const { GAMIFICATIONS, COURSE, STUDENT_GAMIFICATION, BADGE } =
  GamificationActions;

export const getGamificationLoading = (payload) => ({
  type: GAMIFICATIONS.loading,
  payload: payload,
});

export const getCoursesLoading = (payload) => ({
  type: COURSE.loading,
  payload: payload,
});

export const gamificationStudentsLoading = (payload) => ({
  type: STUDENT_GAMIFICATION.loading,
  payload: payload,
});

export const getTopPerformers = (payload) => ({
  type: STUDENT_GAMIFICATION.get_top_performer,
  payload: payload,
});

export const resetGamificationList = () => ({
  type: STUDENT_GAMIFICATION.reset_gamification_list,
});

// Badge
export const requestBadgeList = (payload) => ({
  type: BADGE.get,
  payload: payload,
});

export const successBadgeList = (payload) => ({
  type: BADGE.get_success,
  payload: payload,
});

export const errorBadgeList = (payload) => ({
  type: BADGE.get_error,
  payload: payload,
});

// Create Badge
export const createBadgeAction = (payload) => ({
  type: BADGE.create,
  payload: payload,
});
export const createBadgeSuccess = (payload) => ({
  type: BADGE.create_success,
  payload: payload,
});
export const createBadgeError = (payload) => ({
  type: BADGE.create_error,
  payload: payload,
});
export const createBadgeReset = (payload) => ({
  type: BADGE.create_reset,
  payload: payload,
});
export const getBadgeIconsAction = (payload) => ({
  type: BADGE.icons,
  payload: payload,
});
export const getBadgeIconsSuccess = (payload) => ({
  type: BADGE.icons_success,
  payload: payload,
});
export const getBadgeIconsError = (payload) => ({
  type: BADGE.icons_error,
  payload: payload,
});
export const deleteBadgeAction = (payload) => ({
  type: BADGE.delete,
  payload: payload,
});
export const deleteBadgeSuccess = (payload) => ({
  type: BADGE.delete_success,
  payload: payload,
});
export const deleteBadgeError = (payload) => ({
  type: BADGE.delete_error,
  payload: payload,
});
export const getBadgeByIDAction = (payload) => ({
  type: BADGE.get_by_id,
  payload: payload,
});
export const getBadgeByIDSuccess = (payload) => ({
  type: BADGE.get_by_id_success,
  payload: payload,
});
export const getBadgeByIDError = (payload) => ({
  type: BADGE.get_by_id_error,
  payload: payload,
});
export const updateBadgeByIDAction = (payload) => ({
  type: BADGE.update,
  payload: payload,
});
export const updateBadgeByIDSuccess = (payload) => ({
  type: BADGE.update_success,
  payload: payload,
});
export const updateBadgeByIDError = (payload) => ({
  type: BADGE.update_error,
  payload: payload,
});
export const assignBadgeAction = (payload) => ({
  type: BADGE.assignBadge,
  payload: payload,
});
export const assignBadgeSuccess = (payload) => ({
  type: BADGE.assignBadge_success,
  payload: payload,
});
export const assignBadgeError = (payload) => ({
  type: BADGE.assignBadge_error,
  payload: payload,
});

export const getStudentBadgeList = (payload) => ({
  type: STUDENT_GAMIFICATION.get_student_badge_list,
  payload: payload,
});
export const getStudentBadgeListSuccess = (payload) => ({
  type: STUDENT_GAMIFICATION.get_student_badge_list_success,
  payload: payload,
});
export const getStudentBadgeListError = (payload) => ({
  type: STUDENT_GAMIFICATION.get_student_badge_list_error,
  payload: payload,
});

//get Badge Details of perticular sudent
export const getStudentBadgeDetail = (payload) => ({
  type: STUDENT_GAMIFICATION.get_student_badge_detail,
  payload: payload,
});
export const getStudentBadgeDetailSuccess = (payload) => ({
  type: STUDENT_GAMIFICATION.get_student_badge_detail_success,
  payload: payload,
});
export const getStudentBadgeDetailError = (payload) => ({
  type: STUDENT_GAMIFICATION.get_student_badge_detail_error,
  payload: payload,
});

// Student List for perticular program

export const getStudentProgram = (payload) => ({
  type: BADGE.get_student_program,
  payload: payload,
});
export const getStudentProgramSuccess = (payload) => ({
  type: BADGE.get_student_program_success,
  payload: payload,
});
export const getStudentProgramError = (payload) => ({
  type: BADGE.get_student_program_error,
  payload: payload,
});
