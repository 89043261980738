import * as React from 'react';

const BackButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      viewBox="0 -960 960 960"
      height="24"
      style={{ fill: '#616161', transform: 'msFilter' }}
    >
      <path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z"></path>
    </svg>
  );
};

export default BackButton;
