import { discussionBoardActions } from './DiscussionBoard.constants';
const { DISCUSSIONBOARD, REPLY, POLLING, REPORT, REPORT_ACTION } =
  discussionBoardActions;

export const getDiscussionBoard = (payload) => ({
  type: DISCUSSIONBOARD.get,
  payload: payload,
});

export const getDiscussionBoardSuccess = (payload) => ({
  type: DISCUSSIONBOARD.get_success,
  payload: payload,
});

export const getDiscussionBoardError = (payload) => ({
  type: DISCUSSIONBOARD.get_error,
  payload: payload,
});

export const setDiscussionBoardLoading = (payload) => ({
  type: DISCUSSIONBOARD.loading,
  payload: payload,
});

export const getDiscussionBoardId = (payload) => ({
  type: DISCUSSIONBOARD.get_by_id,
  payload: payload,
});

export const getDiscussionBoardIdSuccess = (payload) => ({
  type: DISCUSSIONBOARD.get_by_id_success,
  payload: payload,
});

export const getDiscussionBoardIdError = (payload) => ({
  type: DISCUSSIONBOARD.get_by_id_error,
  payload: payload,
});

export const createDiscussionBoard = (payload) => ({
  type: DISCUSSIONBOARD.create,
  payload: payload,
});

export const createDiscussionBoardSuccess = (payload) => {
  return {
    type: DISCUSSIONBOARD.create_success,
    payload: payload,
  };
};

export const createDiscussionBoardError = (payload) => ({
  type: DISCUSSIONBOARD.create_error,
  payload: payload,
});

export const updateDiscussionBoard = (payload) => ({
  type: DISCUSSIONBOARD.update,
  payload: payload,
});

export const updateDiscussionBoardSuccess = (payload) => ({
  type: DISCUSSIONBOARD.update_success,
  payload: payload,
});

export const updateDiscussionBoardError = (payload) => ({
  type: DISCUSSIONBOARD.update_error,
  payload: payload,
});

export const deleteDiscussionBoard = (payload) => ({
  type: DISCUSSIONBOARD.delete,
  payload: payload,
});

export const deleteDiscussionBoardSuccess = (payload) => ({
  type: DISCUSSIONBOARD.delete_success,
  payload: payload,
});

export const deleteDiscussionBoardError = (payload) => ({
  type: DISCUSSIONBOARD.delete_error,
  payload: payload,
});

export const createDiscussionReply = (payload) => ({
  type: REPLY.create_reply,
  payload: payload,
});

export const createDiscussionReplySuccess = (payload) => ({
  type: REPLY.create_reply_success,
  payload: payload,
});

export const createDiscussionReplyError = (payload) => ({
  type: REPLY.create_reply_error,
  payload: payload,
});

// Create comment/reply
export const createCommentReplyRequest = (payload) => ({
  type: REPLY.create_comment_reply_request,
  payload: payload,
});

export const createCommentReplySuccess = (payload) => ({
  type: REPLY.create_comment_reply_success,
  payload: payload,
});

export const createCommentReplyError = (payload) => ({
  type: REPLY.create_comment_reply_error,
  payload: payload,
});

export const createCommentReplyReset = () => ({
  type: REPLY.create_comment_reply_reset,
});

// Update user polling option

export const updateUserPollingRequest = (payload) => ({
  type: POLLING.update_by_id,
  payload: payload,
});

export const updateUserPollingSuccess = (payload) => ({
  type: POLLING.update_by_id_success,
  payload: payload,
});

export const updateUserPollingError = (payload) => ({
  type: POLLING.update_by_id_error,
  payload: payload,
});

export const updateUserPollingReset = () => ({
  type: POLLING.update_by_id_reset,
});

// Update pin/unpin comment

export const updatePinUnpinCommentRequest = (payload) => ({
  type: REPLY.update_pin_unpin_comment_request,
  payload: payload,
});

export const updatePinUnpinCommentSuccess = (payload) => ({
  type: REPLY.update_pin_unpin_comment_success,
  payload: payload,
});

export const updatePinUnpinCommentError = (payload) => ({
  type: REPLY.update_pin_unpin_comment_error,
  payload: payload,
});

export const updatePinUnpinCommentReset = () => ({
  type: REPLY.update_pin_unpin_comment_reset,
});

export const reportDiscussion = (payload) => ({
  type: REPORT.report_discussion,
  payload: payload,
});

export const reportDiscussionSuccess = (payload) => ({
  type: REPORT.report_discussion_success,
  payload: payload,
});

export const reportDiscussionError = (payload) => ({
  type: REPORT.report_discussion_error,
  payload: payload,
});

export const resetReportDiscussion = () => ({
  type: REPORT.reset,
});

export const reportDiscussionAction = (payload) => ({
  type: REPORT_ACTION.report_discussion_action,
  payload: payload,
});

export const reportDiscussionActionSuccess = (payload) => ({
  type: REPORT_ACTION.report_discussion_action_success,
  payload: payload,
});

export const reportDiscussionActionError = (payload) => ({
  type: REPORT_ACTION.report_discussion_action_error,
  payload: payload,
});

export const resetReportDiscussionAction = () => ({
  type: REPORT_ACTION.reset,
});

export const updateDiscussionBoardList = (payload) => ({
  type: DISCUSSIONBOARD.update_discussion,
  payload: payload,
});

export const updateDiscussionBoardListSuccess = (payload) => ({
  type: DISCUSSIONBOARD.update_discussion_success,
  payload: payload,
});

export const updateDiscussionBoardListError = (payload) => ({
  type: DISCUSSIONBOARD.update_discussion_error,
  payload: payload,
});

export const getUsersBySubject = (payload) => ({
  type: DISCUSSIONBOARD.get_user_by_subject,
  payload: payload,
});

export const getUsersBySubjectSuccess = (payload) => ({
  type: DISCUSSIONBOARD.get_user_by_subject_success,
  payload: payload,
});

export const getUsersBySubjectError = (payload) => ({
  type: DISCUSSIONBOARD.get_user_by_subject_error,
  payload: payload,
});
