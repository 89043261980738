import React from 'react';

const SquareWidgetSvg = ({ height = '40', width = '40', fill = '#BC0017' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill={fill}
      viewBox="0 0 26.472 26.472"
    >
      <path
        fill={fill}
        id="widgets_FILL0_wght400_GRAD0_opsz48"
        d="M23.283,20.231l-7.3-7.3,7.3-7.3,7.3,7.3ZM4.115,17.762V7.441H14.436V17.762ZM18.448,32.1V21.774H28.769V32.1Zm-14.333,0V21.774H14.436V32.1ZM6.172,15.7h6.206V9.5H6.172Zm17.213,1.749,4.423-4.423L23.386,8.607,18.962,13.03Zm-2.88,12.584h6.206V23.831H20.505Zm-14.333,0h6.206V23.831H6.172ZM12.379,15.7ZM18.962,13.03ZM12.379,23.831ZM20.505,23.831Z"
        transform="translate(-4.115 -5.624)"
      />
    </svg>
  );
};

export default SquareWidgetSvg;
