import React from 'react';
import { withFetchUrl } from 'HOC';
import DocumentViewerInModal from './DocumentViewerInModal';
import DocumentViewer from './DocumentViewer';
const DocumentViewerWithFetchUrl = ({ fileViewerInModal = true, ...props }) => {
  if (fileViewerInModal) {
    return withFetchUrl(
      DocumentViewerInModal,
      props,
      props?.fetchUrl,
      props?.attachmentList?.[0]?.fileURL
    );
  }
  return withFetchUrl(
    DocumentViewer,
    props,
    props?.fetchUrl,
    props?.attachmentList?.[0]?.fileURL
  );
};

export default DocumentViewerWithFetchUrl;
