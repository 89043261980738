import React from 'react';

const QuizIcon = ({ fill, height = 30, width = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      fill={fill}
    >
      <path d="M543 716q16.595 0 28.798-12.202Q584 691.595 584 675t-12.202-28.798Q559.595 634 543 634t-28.798 12.202Q502 658.405 502 675t12.202 28.798Q526.405 716 543 716Zm-25-126h47q2-29 8.5-43t32.5-39q27-26 37.5-45.395Q654 443.21 654 417q0-45.882-31.5-74.941Q591 313 540 313q-38 0-68 20.5T428 391l45 19q11-25 27.5-38t39.5-13q29.778 0 48.389 17Q607 393 607 419q0 20-9 35t-32 32q-32 29-40 46.5t-8 57.5ZM260 856q-24 0-42-18t-18-42V236q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560V236H260v560ZM140 976q-24 0-42-18t-18-42V296h60v620h620v60H140Zm120-740v560-560Z" />
    </svg>
  );
};

export default QuizIcon;
