export const examActions = {
  PROGRAM: {
    GET_PROGRAMS_LIST_INCR: 'GET_PROGRAMS_LIST_INCR',
    GET_SUCCESS_PROGRAMS_LIST_INCR: 'GET_SUCCESS_PROGRAMS_LIST_INCR',
    GET_NEW_PROGRAMS_LIST_REQUEST: 'GET_NEW_PROGRAMS_LIST_REQUEST',
    GET_NEW_PROGRAMS_LIST_SUCCESS: 'GET_NEW_PROGRAMS_LIST_SUCCESS',
  },
  PROGRAM_YEAR: {
    GET_EXAMS_PROGRAM_YEAR_LIST: 'GET_EXAMS_PROGRAM_YEAR_LIST',
    GET_EXAMS_SUCCESS_PROGRAM_YEAR_LIST: 'GET_EXAMS_SUCCESS_PROGRAM_YEAR_LIST',
    GET_NEW_PROGRAM_YEAR_LIST_REQUEST: 'GET_NEW_PROGRAM_YEAR_LIST_REQUEST',
    GET_NEW_PROGRAM_YEAR_LIST_SUCCESS: 'GET_NEW_PROGRAM_YEAR_LIST_SUCCESS',
  },
  SEMESTER: {
    GET_EXAMS_SEMESTER_LIST: 'GET_EXAMS_SEMESTER_LIST',
    GET_EXAMS_SUCCESS_SEMESTER_LIST: 'GET_EXAMS_SUCCESS_SEMESTER_LIST',
    GET_NEW_SEMESTER_LIST_REQUEST: 'GET_NEW_SEMESTER_LIST_REQUEST',
    GET_NEW_SEMESTER_LIST_SUCCESS: 'GET_NEW_SEMESTER_LIST_SUCCESS',
  },
  SUBJECT: {
    GET_SUBJECT_LIST_REQUEST: 'GET_SUBJECT_LIST_REQUEST',
    GET_SUBJECT_LIST_SUCCESS: 'GET_SUBJECT_LIST_SUCCESS',
  },
  EXAM: {
    GET_EXAMS_LIST: 'GET_EXAMS_LIST',
    GET_SUCCESS_EXAMS_LIST: 'GET_SUCCESS_EXAMS_LIST',

    GET_EXAMS_DASHBOARD_LIST: 'GET_EXAMS_DASHBOARD_LIST',
    GET_SUCCESS_EXAMS_DASHBOARD_LIST: 'GET_SUCCESS_EXAMS_DASHBOARD_LIST',
  },

  EXAMS: {
    get: 'EXAMS_MANAGEMENT_EXAMS_GET',
    get_success: 'EXAMS_MANAGEMENT_EXAMS_GET_SUCCESS',
    get_incr: 'EXAMS_MANAGEMENT_EXAMS_GET_INCR',
    get_success_incr: 'EXAMS_MANAGEMENT_EXAMS_GET_SUCCESS_INCR',
    reset_incr: 'EXAMS_MANAGEMENT_EXAMS_RESET_INCR',
    get_error: 'EXAMS_MANAGEMENT_EXAMS_GET_ERROR',
    create: 'EXAMS_MANAGEMENT_EXAMS_CREATE',
    create_success: 'EXAMS_MANAGEMENT_EXAMS_CREATE_SUCCESS',
    create_error: 'EXAMS_MANAGEMENT_EXAMS_CREATE_ERROR',
    edit: 'EXAMS_MANAGEMENT_EXAMS_EDIT',
    edit_success: 'EXAMS_MANAGEMENT_EXAMS_EDIT_SUCCESS',
    edit_error: 'EXAMS_MANAGEMENT_EXAMS_EDIT_ERROR',
    delete: 'EXAMS_MANAGEMENT_EXAMS_DELETE',
    delete_success: 'EXAMS_MANAGEMENT_EXAMS_DELETE_SUCCESS',
    delete_error: 'EXAMS_MANAGEMENT_EXAMS_DELETE_ERROR',
    loading: 'EXAMS_MANAGEMENT_EXAMS_SET_LOADING',
    get_by_id: 'REQUEST_EXAMS_BY_ID',
    get_by_id_success: 'SUCCESS_EXAMS_BY_ID',
    get_by_id_error: 'ERROR_EXAMS_BY_ID',
    reset: 'RESET_EXAMS_BY_ID',
    get_by_pagination: 'EXAMS_MANAGEMENT_EXAMS_GET_BY_PAGINATION',
    get_by_pagination_success:
      'EXAMS_MANAGEMENT_EXAMS_GET_BY_PAGINATION_SUCCESS',
    get_by_pagination_error: 'EXAMS_MANAGEMENT_EXAMS_GET_BY_PAGINATION_ERROR',
    create_content: 'EXAMS_MANAGEMENT_EXAMS_CREATE_CONTENT',
    create_content_success: 'EXAMS_MANAGEMENT_EXAMS_CREATE_CONTENT_SUCCESS',
    create_content_error: 'EXAMS_MANAGEMENT_EXAMS_CREATE_CONTENT_ERROR',
    publish_content: 'EXAMS_MANAGEMENT_EXAMS_PUBLISH_CONTENT',
    publish_content_success: 'EXAMS_MANAGEMENT_EXAMS_PUBLISH_CONTENT_SUCCESS',
    publish_content_error: 'EXAMS_MANAGEMENT_EXAMS_PUBLISH_CONTENT_ERROR',
    get_exam_content: 'EXAMS_MANAGEMENT_EXAMS_GET_CONTENT',
    get_exam_content_success: 'EXAMS_MANAGEMENT_EXAMS_GET_CONTENT_SUCCESS',
    get_exam_content_error: 'EXAMS_MANAGEMENT_EXAMS_GET_CONTENT_ERROR',
    save_theory_content_success: 'EXAMS_MANAGEMENT_SAVE_THEORY_SUCCESS',
    save_theory_content_reset: 'EXAMS_MANAGEMENT_SAVE_THEORY_RESET',
    save_practical_content_success: 'EXAMS_MANAGEMENT_SAVE_PRACTICAL_SUCCESS',
    get_practical_content: 'EXAMS_MANAGEMENT_EXAMS_GET_PRACTICAL_CONTENT',
    get_practical_content_success:
      'EXAMS_MANAGEMENT_EXAMS_GET_PRACTICAL_CONTENT_SUCCESS',
    get_practical_content_error:
      'EXAMS_MANAGEMENT_EXAMS_GET_PRACTICAL_CONTENT_ERROR',
    edit_content: 'EXAMS_MANAGEMENT_EXAMS_EDIT_CONTENT',
    edit_content_success: 'EXAMS_MANAGEMENT_EXAMS_EDIT_CONTENT_SUCCESS',
    edit_content_error: 'EXAMS_MANAGEMENT_EXAMS_EDIT_CONTENT_ERROR',
  },

  MULTIPLE_QUESTION_ANSWER: {
    set_multiple_choice_question_answer: 'SET_MULTIPLE_CHOICE_QUESTION_ANSWER',
    set_multiple_choice_question_answer_reset: 'SET_MULTIPLE_CHOICE_QUESTION_ANSWER_RESET',
  },
};
