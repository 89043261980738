import * as React from 'react';

const EditVideoNotes = ({ fill, height = 20, width = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21.82 19.797"
    // fill={'#02387A'}
  >
    <g
      id="Group_13121"
      data-name="Group 13121"
      transform="translate(-1097.609 -615.633)"
    >
      <path
        id="add_notes_FILL0_wght400_GRAD0_opsz48"
        d="M121.41,232.918a1.414,1.414,0,0,1-1.41-1.41v-14.1a1.414,1.414,0,0,1,1.41-1.41h14.1a1.414,1.414,0,0,1,1.41,1.41v7.731a3.654,3.654,0,0,0-.693-.305,6.463,6.463,0,0,0-.717-.188V217.41h-14.1v14.1h7.261q.094.376.212.732a4.842,4.842,0,0,0,.281.677Zm0-2.514v0Zm2.35-1.245h8.958q.094-.376.211-.728a3.781,3.781,0,0,1,.305-.681H123.76Zm0-3.995,9.4-.024c.219-.11-.251-.384,0-1.386h-9.4Zm0-3.995h9.4v-1.41h-9.4Z"
        transform="translate(977.609 399.633)"
        fill={'#02387A'}
      />
      <path
        id="edit_FILL0_wght400_GRAD0_opsz48"
        d="M6.957,16.081h.7l7.063-7.063-.7-.7L6.957,15.379Zm9.79-7.749L14.706,6.291l.67-.67a.963.963,0,0,1,1.339,0l.7.7a.963.963,0,0,1,0,1.339Zm-.67.67L8.041,17.037H6V15L14.036,6.96Zm-1.706-.335-.351-.351.7.7Z"
        transform="translate(1101.742 618.392)"
        fill={'#02387A'}
      />
    </g>
  </svg>
);

export default EditVideoNotes;
