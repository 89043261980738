import { notificationActions } from './Notification.constants';
const {
  NOTIFICATION_LIST,
  BROADCAST_LIST,
  GET_NOTIFICATION_BY_ID,
  UPDATE_NOTIFICATION_BY_ID,
  CREATE_NOTIFICATON,
  READ_NOTIFICATION,
  REVOKE_NOTIFICATION,
  GET_NOTIFICATION_CONTENT,
  SENT_NOTIFICATION_BROADCAST,
  UPLOAD_NOTIFICATION_BLOB,
  SENT_NOTIFICATION_LIST,
  CREATE_UPDATE_USER_NOTIFICATION,
  SET_LOADING,
} = notificationActions;

export const setNotification = (data) => {
  return {
    type: notificationActions.SET_NOTIFICATION,
    payload: data,
  };
};

export const resetNotification = () => {
  return {
    type: notificationActions.RESET_NOTIFICATION,
  };
};

export const createNotificationApi = (payload) => ({
  type: CREATE_NOTIFICATON.create_notification,
  payload: payload,
});

export const createNotificationSuccess = (payload) => ({
  type: CREATE_NOTIFICATON.create_notification_success,
  payload: payload,
});

export const createNotificationError = (payload) => ({
  type: CREATE_NOTIFICATON.create_notification_error,
  payload: payload,
});

export const readNotificationApi = (payload) => ({
  type: READ_NOTIFICATION.mark_as_read_notification,
  payload: payload,
});

export const readNotificationSuccess = (payload) => ({
  type: READ_NOTIFICATION.mark_as_read_notification_success,
  payload: payload,
});

export const readNotificationError = (payload) => ({
  type: READ_NOTIFICATION.mark_as_read_notification_error,
  payload: payload,
});

export const revokeNotificationApi = (payload) => ({
  type: REVOKE_NOTIFICATION.revoke_notification,
  payload: payload,
});

export const revokeNotificationSuccess = (payload) => ({
  type: REVOKE_NOTIFICATION.revoke_notification_success,
  payload: payload,
});

export const revokeNotificationError = (payload) => ({
  type: REVOKE_NOTIFICATION.revoke_notification_error,
  payload: payload,
});

export const getUserNotificationsApi = (payload) => ({
  type: GET_NOTIFICATION_CONTENT.get_notification_content,
  payload: payload,
});

export const getUserNotificationSuccess = (payload) => ({
  type: GET_NOTIFICATION_CONTENT.get_notification_content_success,
  payload: payload,
});

export const getUserNotificationError = (payload) => ({
  type: GET_NOTIFICATION_CONTENT.get_notification_content_error,
  payload: payload,
});

export const uploadNotificationBlobReset = () => ({
  type: UPLOAD_NOTIFICATION_BLOB.upload_notification_blob_reset,
});

export const getSentNotifications = (payload) => ({
  type: SENT_NOTIFICATION_LIST.sent_notification_list,
  payload: payload,
});

export const getSentNotificationsSuccess = (payload) => ({
  type: SENT_NOTIFICATION_LIST.sent_notification_list_success,
  payload: payload,
});

export const getSentNotificationsError = (payload) => ({
  type: SENT_NOTIFICATION_LIST.sent_notification_list_error,
  payload: payload,
});

export const createUpdateUserNotificationApi = (payload) => ({
  type: CREATE_UPDATE_USER_NOTIFICATION.create_update_user_notification_request,
  payload: payload,
});

export const createUpdateUserNotificationSuccess = (payload) => ({
  type: CREATE_UPDATE_USER_NOTIFICATION.create_update_user_notification_success,
  payload: payload,
});

export const createUpdateUserNotificationError = (payload) => ({
  type: CREATE_UPDATE_USER_NOTIFICATION.create_update_user_notification_error,
  payload: payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING.set_loading,
  payload: payload,
});
