export const discussionBoardApi = {
  // Discussion
  create: '/v1/discussion-board/discussion',
  update: '/v1/discussion-board/discussion',
  updateDiscussion: '/v1/discussion-board/discussion',
  delete: 'v1/discussion-board/discussion',
  getById: '/v1/discussion-board/discussion',
  get: '/v1/discussion-board/discussion',
  //Reply/Comment
  commentReply: '/v1/discussion-board/reply',
  pinUnpinComment: '/v1/discussion-board/reply',
  // Polling
  updatePolling: 'v1/discussion-board/poll',
  // getCommentById:'/v1/discussion-board/discussion',

  discussionReport: '/v1/discussion-board/discussion-report',
  reportAction: '/v1/discussion-board/discussion-report/take_action',
  userBySubject: '/v2/subject/users_by_subject',
};
