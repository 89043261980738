import axiosInstance from 'translator';
import { discussionBoardApi } from './DiscussionBoard.apiEndPoints';

export const getDiscussionBoardApi = async (payload) => {
  console.log('payload1234', payload);
  return axiosInstance
    .get(`${discussionBoardApi?.get}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDiscussionBoardIdApi = async (payload) => {
  console.log(payload, 'payload');
  return axiosInstance
    .get(`${discussionBoardApi?.getById}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// export const getDiscussionBoardIdApi = async (payload) => {
//   return axiosInstance
//     .get(`${discussionBoardApi?.getById}/${payload}`)
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });
// };

export const createDiscussionBoardApi = async (payload) => {
  return axiosInstance
    .post(`${discussionBoardApi?.create}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateDiscussionBoardApi = async (payload) => {
  return axiosInstance
    .patch(`${discussionBoardApi?.update}/${payload.id}`, {
      down_voted_by: payload?.down_voted_by,
      up_voted_by: payload?.up_voted_by,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteDiscussionBoardApi = async (payload) => {
  return axiosInstance
    .delete(`${discussionBoardApi?.delete}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createDiscussionReplyApi = async () => {
  return axiosInstance
    .post(`${discussionBoardApi?.createReporting}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// create comment/reply api

export const createDiscussionCommentReplyApi = async (payload) => {
  return axiosInstance
    .post(`${discussionBoardApi?.commentReply}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Update Polling

export const updateUserPollingApi = async (payload) => {
  return axiosInstance
    .put(
      `${discussionBoardApi?.updatePolling}/${payload.poll_id}?discussion_id=${payload?.discussionId}&user_id=${payload?.user_id}&poll_id=${payload?.poll_id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Update pin/unpin comment

export const updatePinUnpinCommentApi = async (payload) => {
  return axiosInstance
    .patch(`${discussionBoardApi?.pinUnpinComment}/${payload?.commentId}`, {
      pinned: payload.pinned,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const reportDiscussionApi = async (payload) => {
  return axiosInstance
    .post(`${discussionBoardApi.discussionReport}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const reportDiscussionActionApi = async (payload) => {
  return axiosInstance
    .post(`${discussionBoardApi.reportAction}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDiscussionBoardCommentApi = async (payload) => {
  return axiosInstance
    .get(`${discussionBoardApi?.getById}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateDiscussionBoardListApi = async (payload) => {
  console.log('payloadddd', payload);
  return axiosInstance
    .put(`${discussionBoardApi?.updateDiscussion}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserBySubjeactApi = async (payload) => {
  console.log('payload1234', payload);
  return axiosInstance
    .get(`${discussionBoardApi?.userBySubject}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
