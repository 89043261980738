import React from 'react';
import { Breadcrumbs } from 'components';
import { Typography, Stack, Box } from 'iiht-b2a-ui-components/build/components';

export const PageTitle = React.memo(
  ({ title = '', crumbs = [], extraComp }) => {
    return (
      <>
        <Stack>
          {crumbs?.length > 0 && (
            <Box sx={{mb:1}}>
              <Breadcrumbs aria-label="breadcrumb" crumbs={crumbs} />
            </Box>
          )}

          {title && (
            <Typography variant="h5" className="font-23" gutterBottom>
              {title}
              {extraComp}
            </Typography>
          )}
        </Stack>
      </>
    );
  }
);
