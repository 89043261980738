import React from 'react';
import { Tooltip, Typography } from 'iiht-b2a-ui-components/build/components';

const ItemDetails = ({
  label,
  value,
  fontSize = 'font-16',
  cropLength = 30,
}) => {
  return (
    <>
      <Typography color="grey.P1000" variant="bodyText" className="font-13">
        {label}
      </Typography>
      <Tooltip title={value}>
        <Typography className={fontSize} title={value}>
          {value?.length > cropLength
            ? `${value?.substring(0, cropLength)}...`
            : value}
        </Typography>
      </Tooltip>
    </>
  );
};

export default ItemDetails;
