import { SuperAdminActions } from './SuperAdmin.constants';
const { Tenant, MIGRATIONS_DB } = SuperAdminActions;

export const TenantLoading = (payload) => ({
  type: Tenant.loading,
  payload: payload,
});
export const MigrationLoading = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_LOADING,
  payload: payload,
});

// Get All
export const getAllTenantsAction = (payload) => ({
  type: Tenant.GET_ALL_TENANTS_ACTION,
  payload: payload,
});
export const getAllTenantsSuccess = (payload) => ({
  type: Tenant.GET_ALL_TENANTS_SUCCESS,
  payload: payload,
});
export const getAllTenantsError = (payload) => ({
  type: Tenant.GET_ALL_TENANTS_ERROR,
  payload: payload,
});
export const getAllTenantsReset = () => ({
  type: Tenant.GET_ALL_TENANTS_RESET,
});

// Get By ID
export const getTenantsDetailsAction = (payload) => ({
  type: Tenant.GET_TENANT_DETAILS,
  payload: payload,
});
export const getTenantsDetailsSuccess = (payload) => ({
  type: Tenant.GET_TENANT_DETAILS_SUCCESS,
  payload: payload,
});
export const getTenantsDetailsError = (payload) => ({
  type: Tenant.GET_TENANT_DETAILS_ERROR,
  payload: payload,
});
export const getTenantsDetailsReset = () => ({
  type: Tenant.GET_TENANT_DETAILS_RESET,
});

// Upload Files
export const uploadTenantAssetsAction = (payload) => ({
  type: Tenant.UPLOAD_TENANT_ASSETS,
  payload: payload,
});
export const uploadTenantAssetsSuccess = (payload) => ({
  type: Tenant.UPLOAD_TENANT_ASSETS_SUCCESS,
  payload: payload,
});
export const uploadTenantAssetsError = (payload) => ({
  type: Tenant.UPLOAD_TENANT_ASSETS_ERROR,
  payload: payload,
});
export const uploadTenantAssetsReset = () => ({
  type: Tenant.UPLOAD_TENANT_ASSETS_RESET,
});

// Upload Logo
export const uploadTenantLogoAction = (payload) => ({
  type: Tenant.UPLOAD_TENANT_LOGO,
  payload: payload,
});
export const uploadTenantLogoSuccess = (payload) => ({
  type: Tenant.UPLOAD_TENANT_LOGO_SUCCESS,
  payload: payload,
});
export const uploadTenantLogoError = (payload) => ({
  type: Tenant.UPLOAD_TENANT_LOGO_ERROR,
  payload: payload,
});
export const uploadTenantLogoReset = () => ({
  type: Tenant.UPLOAD_TENANT_LOGO_RESET,
});

// Upload Login
export const uploadTenantLoginAction = (payload) => ({
  type: Tenant.UPLOAD_TENANT_LOGIN,
  payload: payload,
});
export const uploadTenantLoginSuccess = (payload) => ({
  type: Tenant.UPLOAD_TENANT_LOGIN_SUCCESS,
  payload: payload,
});
export const uploadTenantLoginError = (payload) => ({
  type: Tenant.UPLOAD_TENANT_LOGIN_ERROR,
  payload: payload,
});
export const uploadTenantLoginReset = () => ({
  type: Tenant.UPLOAD_TENANT_LOGIN_RESET,
});

// Upload User Manual
export const uploadUserManualAction = (payload) => ({
  type: Tenant.UPLOAD_USER_MANUAL,
  payload: payload,
});
export const uploadUserManualSuccess = (payload) => ({
  type: Tenant.UPLOAD_USER_MANUAL_SUCCESS,
  payload: payload,
});
export const uploadUserManualError = (payload) => ({
  type: Tenant.UPLOAD_USER_MANUAL_ERROR,
  payload: payload,
});
export const uploadUserManualReset = () => ({
  type: Tenant.UPLOAD_USER_MANUAL_RESET,
});

// Create Tenant
export const createTenantAction = (payload) => ({
  type: Tenant.CREATE_TENANT_ACTION,
  payload: payload,
});
export const createTenantSuccess = (payload) => ({
  type: Tenant.CREATE_TENANT_SUCCESS,
  payload: payload,
});
export const createTenantError = (payload) => ({
  type: Tenant.CREATE_TENANT_ERROR,
  payload: payload,
});
export const createTenantReset = () => ({
  type: Tenant.CREATE_TENANT_RESET,
});

// Delete Tenants
export const deleteTenantAction = (payload) => ({
  type: Tenant.DELETE_TENANT_ACTION,
  payload: payload,
});
export const deleteTenantSuccess = (payload) => ({
  type: Tenant.DELETE_TENANT_SUCCESS,
  payload: payload,
});
export const deleteTenantError = (payload) => ({
  type: Tenant.DELETE_TENANT_ERROR,
  payload: payload,
});
export const deleteTenantReset = () => ({
  type: Tenant.DELETE_TENANT_RESET,
});

// Edit Tenants
export const editTenantAction = (payload) => ({
  type: Tenant.EDIT_TENANT_ACTION,
  payload: payload,
});
export const editTenantSuccess = (payload) => ({
  type: Tenant.EDIT_TENANT_SUCCESS,
  payload: payload,
});
export const editTenantError = (payload) => ({
  type: Tenant.EDIT_TENANT_ERROR,
  payload: payload,
});
export const editTenantReset = () => ({
  type: Tenant.EDIT_TENANT_RESET,
});

// Activate Deactivate  Tenants
export const activateTenantAction = (payload) => ({
  type: Tenant.ACTIVATE_TENANT_ACTION,
  payload: payload,
});
export const activateTenantSuccess = (payload) => ({
  type: Tenant.ACTIVATE_TENANT_SUCCESS,
  payload: payload,
});
export const activateTenantError = (payload) => ({
  type: Tenant.ACTIVATE_TENANT_ERROR,
  payload: payload,
});
export const activateTenantReset = () => ({
  type: Tenant.ACTIVATE_TENANT_RESET,
});

// Save Data
export const saveFirstFormData = (payload) => ({
  type: Tenant.SAVE_FIRST_FORMDATA_SUCCESS,
  payload: payload,
});
export const saveFirstFormDataReset = () => ({
  type: Tenant.SAVE_FIRST_FORMDATA_RESET,
});

export const saveSecondFormData = (payload) => ({
  type: Tenant.SAVE_SECOND_FORMDATA_SUCCESS,
  payload: payload,
});
export const saveSecondFormDataReset = () => ({
  type: Tenant.SAVE_SECOND_FORMDATA_RESET,
});

// Add Admin Image
export const uploadAddAdminAction = (payload) => ({
  type: Tenant.ADD_ADMIN_IMAGE,
  payload: payload,
});
export const uploadAddAdminSuccess = (payload) => ({
  type: Tenant.ADD_ADMIN_IMAGE_SUCCESS,
  payload: payload,
});
export const uploadAddAdminError = (payload) => ({
  type: Tenant.ADD_ADMIN_IMAGE_ERROR,
  payload: payload,
});
export const uploadAddAdminReset = () => ({
  type: Tenant.ADD_ADMIN_IMAGE_RESET,
});

// Get Add Admin
export const getAllAddAdminAction = (payload) => ({
  type: Tenant.GET_ADMIN_ACTION,
  payload: payload,
});
export const getAllAddAdminSuccess = (payload) => ({
  type: Tenant.GET_ADMIN_SUCCESS,
  payload: payload,
});
export const getAllAddAdminError = (payload) => ({
  type: Tenant.GET_ADMIN_ERROR,
  payload: payload,
});
export const getAllAddAdminReset = () => ({
  type: Tenant.GET_ADMIN_RESET,
});

// Delete Add Admin
export const deleteAddAdminAction = (payload) => ({
  type: Tenant.DELETE_ADMIN_ACTION,
  payload: payload,
});
export const deleteAddAdminSuccess = (payload) => ({
  type: Tenant.DELETE_ADMIN_SUCCESS,
  payload: payload,
});
export const deleteAddAdminError = (payload) => ({
  type: Tenant.DELETE_ADMIN_ERROR,
  payload: payload,
});
export const deleteAddAdminReset = () => ({
  type: Tenant.DELETE_ADMIN_RESET,
});

// Create Add Admin
export const createAdminAction = (payload) => ({
  type: Tenant.CREATE_ADMIN_ACTION,
  payload: payload,
});
export const createAdminSuccess = (payload) => ({
  type: Tenant.CREATE_ADMIN_SUCCESS,
  payload: payload,
});
export const createAdminError = (payload) => ({
  type: Tenant.CREATE_ADMIN_ERROR,
  payload: payload,
});
export const createAdminReset = () => ({
  type: Tenant.CREATE_ADMIN_RESET,
});

// Get By ID Admins
export const getAdminDetailsAction = (payload) => ({
  type: Tenant.GET_ADMIN_DETAILS_ACTION,
  payload: payload,
});
export const getAdminDetailsSuccess = (payload) => ({
  type: Tenant.GET_ADMIN_DETAILS_SUCCESS,
  payload: payload,
});
export const getAdminDetailsError = (payload) => ({
  type: Tenant.GET_ADMIN_DETAILS_ERROR,
  payload: payload,
});
export const getAdminDetailsReset = () => ({
  type: Tenant.GET_ADMIN_DETAILS_RESET,
});

// Activate Admin
export const activateAdminAction = (payload) => ({
  type: Tenant.ACTIVATE_ADMIN_ACTION,
  payload: payload,
});
export const activateAdminSuccess = (payload) => ({
  type: Tenant.ACTIVATE_ADMIN_SUCCESS,
  payload: payload,
});
export const activateAdminError = (payload) => ({
  type: Tenant.ACTIVATE_ADMIN_ERROR,
  payload: payload,
});
export const activateAdminReset = () => ({
  type: Tenant.ACTIVATE_ADMIN_RESET,
});

// Get All
export const getCardItemAction = (payload) => ({
  type: Tenant.GET_CARD_ITEM_ACTION,
  payload: payload,
});
export const getCardItemSuccess = (payload) => ({
  type: Tenant.GET_CARD_ITEM_SUCCESS,
  payload: payload,
});
export const getCardItemError = (payload) => ({
  type: Tenant.GET_CARD_ITEM_ERROR,
  payload: payload,
});
export const getCardItemReset = () => ({
  type: Tenant.GET_CARD_ITEM_RESET,
});

// Get All Migration Tenants List
export const migrationTenantListAction = (payload) => ({
  type: Tenant.MIGRATION_TENANT_LIST_ACTION,
  payload: payload,
});
export const migrationTenantListSuccess = (payload) => ({
  type: Tenant.MIGRATION_TENANT_LIST_SUCCESS,
  payload: payload,
});
export const migrationTenantListError = (payload) => ({
  type: Tenant.MIGRATION_TENANT_LIST_ERROR,
  payload: payload,
});
export const migrationTenantListReset = () => ({
  type: Tenant.MIGRATION_TENANT_LIST_RESET,
});

// Migration Admission
export const migrationAdmissionAction = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_ADMISSION_DB_ACTION,
  payload: payload,
});
export const migrationAdmissionSuccess = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_ADMISSION_DB_SUCCESS,
  payload: payload,
});
export const migrationAdmissionError = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_ADMISSION_DB_ERROR,
  payload: payload,
});
export const migrationAdmissionReset = () => ({
  type: MIGRATIONS_DB.MIGRATION_ADMISSION_DB_RESET,
});

// Migration Exam
export const migrationExamAction = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_EXAM_DB_ACTION,
  payload: payload,
});
export const migrationExamSuccess = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_EXAM_DB_SUCCESS,
  payload: payload,
});
export const migrationExamError = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_EXAM_DB_ERROR,
  payload: payload,
});
export const migrationExamReset = () => ({
  type: MIGRATIONS_DB.MIGRATION_EXAM_DB_RESET,
});

// Migration Attendance
export const migrationAttendanceAction = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_ACTION,
  payload: payload,
});
export const migrationAttendanceSuccess = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_SUCCESS,
  payload: payload,
});
export const migrationAttendanceError = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_ERROR,
  payload: payload,
});
export const migrationAttendanceReset = () => ({
  type: MIGRATIONS_DB.MIGRATION_ATTENDANCE_DB_RESET,
});

// Migration User Management
export const migrationUserManagementAction = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_ACTION,
  payload: payload,
});
export const migrationUserManagementSuccess = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_SUCCESS,
  payload: payload,
});
export const migrationUserManagementError = (payload) => ({
  type: MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_ERROR,
  payload: payload,
});
export const migrationUserManagementReset = () => ({
  type: MIGRATIONS_DB.MIGRATION_USERMANAGEMENT_DB_RESET,
});
