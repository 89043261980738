import React from 'react';

function HowToReg({ fill, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 96 960 960"
      width="20"
      fill={fill}
      {...rest}
    >
      <path d="M96 864v-92q0-26 12.5-47.5T143 690q54-32 115-49t126-17q23 0 46.5 3t49.5 9l-61 62q-15-2-22-2h-13q-53 0-105 14t-100 42q-4.95 2.941-7.975 8.235Q168 765.529 168 772v20h286l71 72H96Zm530 0L491 728l50-51 85 85 187-186 51 50-238 238ZM384 576q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm70 216Zm-69.788-288Q414 504 435 482.788q21-21.213 21-51Q456 402 434.788 381q-21.213-21-51-21Q354 360 333 381.212q-21 21.213-21 51Q312 462 333.212 483q21.213 21 51 21ZM384 432Z" />
    </svg>
  );
}

export default HowToReg;
