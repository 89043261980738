import { ExpandArrow } from 'icons';
import {
  Avatar,
  Box,
  CardHeader,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from 'iiht-b2a-ui-components/build/components';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { localStorageHelper } from 'utils';
import { useKeycloak } from '@react-keycloak/web';

function ProfileTab({ userDetails, theme, profileItems }) {
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const { keycloak } = useKeycloak();

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: theme.palette.info.light,
        color: theme.palette.secondary.main,
        border: `2px solid ${theme.palette.info.main}`,
        width: '36px',
        height: '36px',
        fontSize: theme.typography.fieldHeadings.fontSize,
        fontWeight: theme.typography.fieldHeadings.fontWeight,
      },
      children: name?.split(' ')[1]
        ? `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`
        : name?.split(' ')[0][0],
    };
  };

  const handleOpenProfileMenu = (event) => {
    profileItems?.length && setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };

  const handleLogout = () => {
    const tenantName = localStorageHelper.get('tenantName');
    setAnchorElProfile(null);
    localStorage.clear();
    sessionStorage.clear();
    keycloak.logout({
      redirectUri: window.location.origin,
    });
  };

  return (
    <Box sx={{ ml: { xs: 'none', md: '20px' } }}>
      <CardHeader
        avatar={
          <Avatar
            src={userDetails?.profile_img}
            alt={`${userDetails?.first_name} ${userDetails?.last_name}`}
            {...stringAvatar(
              `${userDetails?.first_name} ${userDetails?.last_name}`
            )}
          />
        }
        titleTypographyProps={{
          fontSize: theme.typography.headline.fontSize,
          fontFamily: theme.typography.headline.fontFamily,
        }}
        title={
          <Box sx={{ display: { xs: 'none', sm: 'unset' } }}>
            <Box className="content-center">
              <Tooltip title={userDetails?.first_name}>
                <span>
                  {userDetails?.first_name?.length +
                    userDetails?.last_name?.length >
                  8
                    ? `${(
                        userDetails?.first_name +
                        ' ' +
                        userDetails?.last_name
                      )?.substring(0, 8)}...`
                    : `${userDetails?.first_name} ${userDetails?.last_name}`}
                </span>
              </Tooltip>
              {profileItems?.length ? <ExpandArrow /> : null}
            </Box>
            <p className="designation-title">
              {localStorageHelper.get('role_name') || ''}
            </p>
          </Box>
        }
        onClick={handleOpenProfileMenu}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorElProfile}
        open={Boolean(anchorElProfile)}
        onClose={handleCloseProfileMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {profileItems?.map((item) => (
          <MenuItem disableRipple className="menu-item" key={item.link}>
            {item.link === '/logout' ? (
              <Link onClick={handleLogout}>
                {item.icon}
                <Typography variant="link" className="basic-menu-item-icon">
                  {item.item}
                </Typography>
              </Link>
            ) : (
              <Link to={item.link} onClick={handleCloseProfileMenu}>
                {item.icon}
                <Typography variant="link" className="basic-menu-item-icon">
                  {item.item}
                </Typography>
              </Link>
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default ProfileTab;
