import axiosInstance from 'translator';
import { feedbackAPI } from 'store/Feedback';

export const getFeedbackListAPI = async (payload) => {
  return axiosInstance
    .get(`${feedbackAPI?.feedbackList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postFeedbackApi = async (data, params) => {
  return axiosInstance
    .post(`${feedbackAPI?.submitStudentFeedback}`, data, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Get Course List
export const getCourseListAPI = async (payload) => {
  return axiosInstance
    .get(`${feedbackAPI?.courseList}/${payload.studentId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
