export const notificationActions = {
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',

  CREATE_NOTIFICATON: {
    create_notification: 'CREATE_NOTIFICATION',
    create_notification_success: 'CREATE_NOTIFICATION_SUCCESS',
    create_notification_error: 'CREATE_NOTIFICATION_ERROR',
  },
  READ_NOTIFICATION: {
    mark_as_read_notification: 'MARK_AS_READ_NOTIFICATION',
    mark_as_read_notification_success: 'MARK_AS_READ_NOTIFICATION_SUCCESS',
    mark_as_read_notification_error: 'MARK_AS_READ_NOTIFICATION_ERROR',
  },
  REVOKE_NOTIFICATION: {
    revoke_notification: 'REVOKE_NOTIFICATION',
    revoke_notification_success: 'REVOKE_NOTIFICATION_SUCCESS',
    revoke_notification_error: 'REVOKE_NOTIFICATION_ERROR',
  },
  GET_NOTIFICATION_CONTENT: {
    get_notification_content: 'GET_NOTIFICATION_CONTENT',
    get_notification_content_success: 'GET_NOTIFICATION_CONTENT_SUCCESS',
    get_notification_content_error: 'GET_NOTIFICATION_CONTENT_ERROR',
  },

  UPLOAD_NOTIFICATION_BLOB: {
    upload_notication_blob: 'UPLOAD_NOTIFICATION_BLOB',
    upload_notication_blob_success: 'UPLOAD_NOTIFICATION_BLOB_SUCCESS',
    upload_notication_blob_error: 'UPLOAD_NOTIFICATION_BLOB_ERROR',
    upload_notification_blob_reset: 'UPLOAD_NOTIFICATION_BLOB_RESET',
  },

  SENT_NOTIFICATION_LIST: {
    sent_notification_list: 'SENT_NOTIFICATION_LIST',
    sent_notification_list_success: 'SENT_NOTIFICATION_LIST_SUCCESS',
    sent_notification_list_error: 'SENT_NOTIFICATION_LIST_ERROR',
  },

  CREATE_UPDATE_USER_NOTIFICATION: {
    create_update_user_notification_request:
      'CREATE_UPDATE_USER_NOTIFICATION_REQUEST',
    create_update_user_notification_success:
      'CREATE_UPDATE_USER_NOTIFICATION_SUCCESS',
    create_update_user_notification_error:
      'CREATE_UPDATE_USER_NOTIFICATION_ERROR',
  },
  SET_LOADING: {
    set_loading: 'SET_LOADING',
  },
};
