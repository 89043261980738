import { chatActions } from './Chat.constants';

const INITIAL_STATE = {
  chatHistory: [],
  loading: false,
  error: '',
};

const chatReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case chatActions.GET_CHAT_HISTORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case chatActions.GET_CHAT_HISTORY_SUCCESS: {
      let preloadedData = state?.chatHistory;
      const roomIndex = preloadedData?.findIndex((history) => {
        return history.roomId === action?.payload?.roomId;
      });
      if (roomIndex !== -1) {
        preloadedData[roomIndex].chat = [
          ...preloadedData[roomIndex].chat,
          action?.payload?.chat,
        ];
      } else {
        preloadedData.push({
          roomId: action?.payload?.roomId,
          chat: [action?.payload?.chat],
        });
      }
      return {
        ...state,
        loading: false,
        chatHistory: preloadedData,
        id: Math.random(),
      };
    }
    case chatActions.GET_CHAT_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }

    case chatActions.SET_CHAT_HISTORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case chatActions.SET_CHAT_HISTORY_SUCCESS: {
      let preloadedData = state?.chatHistory;
      const roomIndex = preloadedData?.findIndex((history) => {
        return history.roomId === action?.payload?.roomId;
      });
      if (roomIndex !== -1) {
        preloadedData[roomIndex].chat = [...action?.payload?.chat];
      } else {
        preloadedData.push({
          roomId: action?.payload?.roomId,
          chat: [...action?.payload?.chat],
        });
      }
      return {
        ...state,
        loading: false,
        chatHistory: preloadedData,
        id: Math.random(),
      };
    }

    case chatActions.SET_CHAT_MESSAGE: {
      let preloadedData = state?.chatHistory;
      const roomIndex = preloadedData?.findIndex((history) => {
        return history.roomId === action?.payload?.roomId;
      });
      if (roomIndex !== -1) {
        preloadedData[roomIndex].chat = [
          ...preloadedData[roomIndex].chat,
          action?.payload?.chat,
        ];
      } else {
        preloadedData.push({
          roomId: action?.payload?.roomId,
          chat: [action?.payload?.chat],
        });
      }
      return {
        ...state,
        loading: false,
        chatHistory: preloadedData,
        id: Math.random(),
      };
    }
    case chatActions.SET_CHAT_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case chatActions.CHAT_HISTORY_RESET: {
      return {
        ...INITIAL_STATE,
        chatHistory: [],
      };
    }
    default:
      return state;
  }
};
export default chatReducer;
