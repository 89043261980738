import { VerifierActions } from './Verifier.constants';

export const getVerifierStats = (payload) => ({
  type: VerifierActions.GET_VERIFIER_STATS,
  payload,
});

export const setVerifierStatsSuccess = (payload) => ({
  type: VerifierActions.SET_VERIFIER_STATS_SUCCESS,
  payload,
});

export const setVerifierStatsError = (payload) => ({
  type: VerifierActions.SET_VERIFIER_STATS_ERROR,
  payload,
});

export const getCandidateList = (payload) => ({
  type: VerifierActions.GET_CANDIDATES_LIST,
  payload,
});

export const setCandidateListSuccess = (payload) => ({
  type: VerifierActions.SET_CANDIDATES_LIST_SUCCESS,
  payload,
});

export const setCandidateListError = (payload) => ({
  type: VerifierActions.SET_CANDIDATES_LIST_ERROR,
  payload,
});

export const setCandidateListLoading = (payload) => ({
  type: VerifierActions.SET_CANDIDATES_LIST_LOADING,
  payload,
});

export const getCandidateApplicationInfo = (payload) => ({
  type: VerifierActions.GET_CANDIDATE_APPLICATION_INFO,
  payload,
});

export const setCandidateApplicationInfoSuccess = (payload) => ({
  type: VerifierActions.SET_CANDIDATE_APPLICATION_INFO_SUCCESS,
  payload,
});

export const setCandidateApplicationInfoError = (payload) => ({
  type: VerifierActions.SET_CANDIDATE_APPLICATION_INFO_ERROR,
  payload,
});

export const resetCandidateApplicationInfo = () => ({
  type: VerifierActions.RESET_CANDIDATE_APPLICATION_INFO,
});

export const getProspectusFeedback = (payload) => ({
  type: VerifierActions.GET_PROSPECTUS_FEEDBACK,
  payload,
});

export const setProspectusFeedbackSuccess = (payload) => ({
  type: VerifierActions.SET_PROSPECTUS_FEEDBACK_SUCCESS,
  payload,
});

export const setProspectusFeedbackError = (payload) => ({
  type: VerifierActions.SET_PROSPECTUS_FEEDBACK_ERROR,
  payload,
});

export const setProspectusFeedbackLoading = (payload) => ({
  type: VerifierActions.SET_PROSPECTUS_FEEDBACK_LOADING,
  payload,
});

export const sendProspectusFeedback = (payload) => ({
  type: VerifierActions.SEND_PROSPECTUS_FEEDBACK,
  payload,
});

export const sendProspectusFeedbackSuccess = (payload) => ({
  type: VerifierActions.SEND_PROSPECTUS_FEEDBACK_SUCCESS,
  payload,
});

export const sendProspectusFeedbackError = (payload) => ({
  type: VerifierActions.SEND_PROSPECTUS_FEEDBACK_ERROR,
  payload,
});

export const setProspectusLoading = (payload) => ({
  type: VerifierActions.SET_PROSPECTUS_LOADING,
  payload,
});

export const updateProspectusStatus = (payload) => ({
  type: VerifierActions.UPDATE_PROSPECTUS_STATUS,
  payload,
});

export const updateProspectusStatusSuccess = (payload) => ({
  type: VerifierActions.UPDATE_PROSPECTUS_STATUS_SUCCESS,
  payload,
});

export const updateProspectusStatusError = (payload) => ({
  type: VerifierActions.UPDATE_PROSPECTUS_STATUS_ERROR,
  payload,
});

export const resetUpdateProspectusStatus = () => ({
  type: VerifierActions.RESET_UPDATE_PROSPECTUS_STATUS,
});

export const getFeesDetails = (payload) => ({
  type: VerifierActions.GET_FEES_DETAILS,
  payload,
});

export const setFeesDetailsSuccess = (payload) => ({
  type: VerifierActions.SET_FEES_DETAILS_SUCCESS,
  payload,
});

export const setFeesDetailsError = (payload) => ({
  type: VerifierActions.SET_FEES_DETAILS_ERROR,
  payload,
});

export const addDiscount = (payload) => ({
  type: VerifierActions.ADD_DISCOUNT,
  payload,
});

export const setAddDiscountSuccess = (payload) => ({
  type: VerifierActions.ADD_DISCOUNT_SUCCESS,
  payload,
});

export const setAddDiscountError = (payload) => ({
  type: VerifierActions.ADD_DISCOUNT_ERROR,
  payload,
});

export const resetAddDiscount = () => ({
  type: VerifierActions.RESET_ADD_DISCOUNT,
});

export const deleteDiscount = (payload) => ({
  type: VerifierActions.DELETE_DISCOUNT,
  payload,
});

export const setDeleteDiscountSuccess = (payload) => ({
  type: VerifierActions.DELETE_DISCOUNT_SUCCESS,
  payload,
});

export const setDeleteDiscountError = (payload) => ({
  type: VerifierActions.DELETE_DISCOUNT_ERROR,
  payload,
});

export const resetDeleteDiscount = () => ({
  type: VerifierActions.RESET_DELETE_DISCOUNT,
});

export const getLeadStageVerifier = (id, payload) => ({
  type: VerifierActions.GET_LEAD_STAGE_VERIFIER,
  id: id,
  payload,
});

export const successLeadStageVerifier = (payload) => ({
  type: VerifierActions.SUCCESS_LEAD_STAGE_VERIFIER,
  payload,
});

export const errorLeadStageVerifier = (payload) => ({
  type: VerifierActions.ERROR_LEAD_STAGE_VERIFIER,
  payload,
});

export const requestUpdatePaymentDetails = (id, payload) => ({
  type: VerifierActions.UPDATE_PAYMENT_DETAILS,
  id: id,
  payload,
});

export const successUpdatePaymentDetails = (payload) => ({
  type: VerifierActions.SUCCESS_UPDATE_PAYMENT_DETAILS,
  payload,
});

export const errorUpdatePaymentDetails = (payload) => ({
  type: VerifierActions.ERROR_UPDATE_PAYMENT_DETAILS,
  payload,
});

export const resetUpdatePaymentDetails = () => ({
  type: VerifierActions.RESET_UPDATE_PAYMENT_DETAILS,
});

export const sendPaymentLinkVerifier = (id, payload) => ({
  type: VerifierActions.SEND_PAYMENT_LINK,
  id,
  payload,
});

export const successSendPaymentLink = (payload) => ({
  type: VerifierActions.SUCCESS_SEND_PAYMENT_LINK,
  payload,
});

export const errorSendPaymentLink = (payload) => ({
  type: VerifierActions.ERROR_SEND_PAYMENT_LINK,
  payload,
});

export const resetSendPaymentLink = () => ({
  type: VerifierActions.RESET_SEND_PAYMENT_LINK,
});

export const sendToRegistrar = (payload) => ({
  type: VerifierActions.SEND_TO_REGISTRAR,
  payload,
});

export const successSendToRegistrar = (payload) => ({
  type: VerifierActions.SUCCESS_SEND_TO_REGISTRAR,
  payload,
});

export const errorSendToRegistrar = (payload) => ({
  type: VerifierActions.ERROR_SEND_TO_REGISTRAR,
  payload,
});

export const resetSendToRegistrar = () => ({
  type: VerifierActions.RESET_SEND_TO_REGISTRAR,
});

export const receiptFileUpload = (payload) => ({
  type: VerifierActions.RECEIPT_FILE_UPLOAD,
  payload,
});

export const receiptFileUploadSuccess = (payload) => ({
  type: VerifierActions.RECEIPT_FILE_UPLOAD_SUCCESS,
  payload,
});

export const getProfileDetail = (payload) => ({
  type: VerifierActions.GET_USER_DETAILS,
  payload,
});

export const getProfileDetailSuccess = (payload) => ({
  type: VerifierActions.GET_USER_DETAILS_SUCCESS,
  payload,
});

export const getProfileDetailError = (payload) => ({
  type: VerifierActions.GET_USER_DETAILS_ERROR,
  payload,
});
