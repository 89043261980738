import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FormAutoComplete, FormSelectField, LoaderComponent } from 'components';
import { AddIconCircle, DeleteSvg } from 'icons';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from 'iiht-b2a-ui-components/build/components';
import _ from 'lodash';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClusters,
  getClustersResetIncr,
  getDepartment,
  getDepartmentsResetIncr,
  getProgramListAction,
  getProgramListResetIncr,
  getProgramYearListAction,
  getSemesterListAction,
  getSubjectListResetIncr,
  resetAllAction,
} from 'store';
import {
  checkIfObjectNotEmpty,
  dynamicAutoListMapper,
  dynamicListMapper,
  localStorageHelper,
} from 'utils';

import { courseLanguageOptions } from 'constants/commonConstants';
import { ROUTES } from 'constants/routesConstants';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { resetFacultSubjectDetailsAction } from 'store/CourseManagement';
import { resetStudentProgramDetailsAction } from 'store/Student';
import {
  createStudent,
  getDesignation,
  resetStudentDetails,
  updateStudent,
} from 'store/UserManagement';

const StudentProgramDetails = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const [student, setStudent] = useState();
  const [selectedDropdown, setSelectedDropdown] = useState({
    cluster: '',
    department: '',
    program: '',
    programYear: '',
    semester: '',
    subject: '',
  });
  const [clustersList, setClustersList] = useState([]);
  const [departmentList, setDepartmentList] = useState({});
  const [programList, setProgramList] = useState({ 0: {}, 1: {} });
  const [programYearList, setProgramYearList] = useState({});
  const [semesterList, setSemesterList] = useState({});
  const [userRole, setUserRole] = useState([]);
  const [files, setFiles] = useState('');
  const [selectedType, setSelectedType] = useState(0);
  const [newSelectedType, setNewSelectedType] = useState(0);

  const {
    data: createdStudent,
    studentData,
    uploadUserImage,
  } = useSelector((state) => state.userManagementState.student);

  const { designationList } = useSelector(
    (state) => state.userManagementState.staff
  );
  const { loading } = useSelector((state) => state.userManagementState.user);

  // const clusterName =
  //   studentData?.user_program_mapping?.results[0]?.department?.cluster_name;

  const { clusters, allDepartment, program } =
    useSelector((state) => state.courseManagementState) || '';

  const {
    created_student_program_details,
    get_student_program_details_list,
    edit_student_program_details,
  } = useSelector((state) => state.studentState);

  const programInitialValues = {
    program_details: [
      {
        cluster_name: '',
        department_name: '',
        program_name: '',
        program_year: '',
        semester: '',
        mediumOfExamination: '',
        selected_type: 0,
      },
    ],
    // program_details: [
    //   {
    //     user_type: '',
    //     program_name: '',
    //     department: '',
    //     program: '',
    //     year: '',
    //     year_id: '',
    //     semester: '',
    //     semester_id: '',
    //     exam_medium: '',
    //   },
    // ],
  };

  const methods = useForm({
    defaultValues: programInitialValues,
  });

  const {
    fields: programFields,
    append: programAppend,
    remove: programRemove,
  } = useFieldArray({
    control: methods.control,
    name: 'program_details',
    rules: {
      required: true,
    },
  });

  const { handleSubmit, setValue, watch, reset, getValues } = methods;

  const addProgramDetails = () => {
    programAppend({
      cluster_name: '',
      department_name: '',
      program_name: '',
      program_year: '',
      semester: '',
      mediumOfExamination: '',
      selected_type: selectedType,
    });
  };

  const getDepartmentListHandler = useCallback(
    (
      SearchStringLocal = '',
      SkipCountLocal = 0,
      MaxResultCountLocal = 5,
      cluster = ''
    ) => {
      const req = {
        cluster,
        pagination: -1,
      };
      dispatch(getDepartment(req));
    },
    []
  );

  const getProgramListHandler = useCallback(
    (
      SearchStringLocal = '',
      SkipCountLocal = 0,
      MaxResultCountLocal = 5,
      department = '',
      selected_type = 0
    ) => {
      const req = {
        department,
        pagination: -1,
        program_pattern_type: selected_type,
      };
      dispatch(getProgramListAction(req));
    },
    []
  );

  const getProgramYearListHandler = useCallback(
    (
      SearchStringLocal = '',
      SkipCountLocal = 0,
      MaxResultCountLocal = 5,
      program = ''
    ) => {
      const req = {
        program,
        pagination: -1,
      };
      dispatch(getProgramYearListAction(req));
    },
    []
  );

  const getSemesterListHandler = useCallback(
    (
      SearchStringLocal = '',
      SkipCountLocal = 0,
      MaxResultCountLocal = 5,
      program_year = ''
    ) => {
      const req = {
        program_year,
        pagination: -1,
      };
      dispatch(getSemesterListAction(req));
    },
    []
  );

  const getDepartmentHandler = (e, newValue, index, callFrom) => {
    dispatch(resetAllAction());
    dispatch(getDepartmentsResetIncr());
    if (callFrom === 'multi') {
      setValue(`program_details.${index}.cluster_name`, newValue);
      setValue(`program_details.${index}.department_name`, '');
      setValue(`program_details.${index}.program_name`, '');
      setValue(`program_details.${index}.program_year`, '');
      setValue(`program_details.${index}.semester`, '');
      setValue(`program_details.${index}.mediumOfExamination`, '');
    }
    if (callFrom === 'single') {
      setValue('cluster', newValue);
      setValue(`department`, '');
    }

    const cluster = newValue?.value;

    if (cluster && !checkIfObjectNotEmpty(departmentList[cluster])) {
      setSelectedDropdown((e) => {
        return { ...e, cluster: cluster };
      });
      setDepartmentList((e) => {
        return { ...e, [cluster]: [] };
      });
      getDepartmentListHandler(undefined, undefined, undefined, cluster);
    }
  };

  const getProgramHandler = (e, newValue, index, selected_type) => {
    dispatch(getProgramListResetIncr());
    setValue(`program_details.${index}.program_name`, '');
    setValue(`program_details.${index}.program_year`, '');
    setValue(`program_details.${index}.semester`, '');
    setValue(`program_details.${index}.mediumOfExamination`, '');
    setValue(`program_details.${index}.department_name`, newValue);
    const department = newValue?.value;
    setSelectedDropdown((e) => {
      return { ...e, department: department };
    });
    setProgramList((e) => {
      return {
        ...e,
        [selected_type]: { ...e[selected_type], [department]: [] },
      };
    });
    setNewSelectedType(selected_type);
    getProgramListHandler(
      undefined,
      undefined,
      undefined,
      department,
      selected_type
    );
  };

  const getProgramYearHandler = (e, newValue, index) => {
    setValue(`program_details.${index}.program_year`, '');
    setValue(`program_details.${index}.semester`, '');
    setValue(`program_details.${index}.mediumOfExamination`, '');
    setValue(`program_details.${index}.program_name`, newValue);
    const program = newValue?.value;
    setSelectedDropdown((e) => {
      return { ...e, program: program };
    });
    setProgramYearList((e) => {
      return { ...e, [program]: [] };
    });
    getProgramYearListHandler(undefined, undefined, undefined, program);
  };

  const getSemesterHandler = (e, newValue, index, selected_type) => {
    setValue(`program_details.${index}.semester`, '');
    setValue(`program_details.${index}.program_year`, newValue);
    if (selected_type === 1) {
      setValue(`program_details.${index}.semester`, {
        label: 1,
        id: newValue?.annual_pattern?.semester_id,
        value: newValue?.annual_pattern?.semester_id,
      });
    } else {
      const semester = newValue?.value;
      setSelectedDropdown((e) => {
        return { ...e, semester: semester };
      });
      setSemesterList((e) => {
        return { ...e, [semester]: [] };
      });
      getSemesterListHandler(undefined, undefined, undefined, semester);
    }
  };

  useEffect(() => {
    // return () => {
    if (!location?.state?.isEdit) {
      dispatch(resetStudentDetails());
    }
  }, [location?.state?.isEdit]);

  useEffect(() => {
    dispatch(resetAllAction());
    dispatch(getDesignation({ display_name: 'student' }));
    dispatch(
      getClusters({
        pagination: -1,
      })
    );
    return () => {
      dispatch(resetAllAction());
    };
  }, []);

  const removeProgramDetails = (index) => {
    programFields?.length > 1 && programRemove(index);
  };

  useEffect(() => {
    dispatch(getClustersResetIncr());
    dispatch(getDepartmentsResetIncr());
    dispatch(getProgramListResetIncr());
    dispatch(getSubjectListResetIncr());

    return () => {
      dispatch(getClustersResetIncr());
      dispatch(getDepartmentsResetIncr());
      dispatch(getProgramListResetIncr());
      dispatch(getSubjectListResetIncr());
      dispatch(resetStudentProgramDetailsAction());
    };
  }, []);

  const getClusterHandler = useCallback(
    (SearchStringLocal = '', SkipCountLocal = 0, MaxResultCountLocal = 5) => {
      const req = {
        limit: MaxResultCountLocal,
        offset: SkipCountLocal,
      };
      // dispatch(getClustersIncr(req));
    },
    []
  );

  const resetValues = () => {
    // if (location?.state?.isEdit) {
    history.push(ROUTES?.STUDENT_MANAGEMENT?.ROOT);
    // } else {
    // reset(programInitialValues);
    // props.resetValues()
    // dispatch(resetAllAction());
  };
  // };

  useEffect(() => {
    const payload = {
      display_name: 'student',
    };
    dispatch(getDesignation(payload));
    getClusterHandler();
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(studentData) &&
      studentData?.user_program_mapping?.user_subjects?.length > 0
    ) {
      const program_list =
        studentData?.user_program_mapping?.user_subjects?.map((prog) => {
          return {
            cluster_name: {
              id: prog?.department?.cluster,
              value: prog?.department?.cluster,
              label: prog?.department?.cluster_name,
            },
            department_name: {
              id: prog?.department?.id,
              value: prog?.department?.id,
              label: prog?.department?.department_name,
            },
            program_name: {
              id: prog?.program,
              value: prog?.program,
              label: prog?.program_name,
            },
            program_year: {
              id: prog?.year_id,
              value: prog?.year_id,
              label: prog?.year,
            },
            semester: {
              id: prog?.semester_id,
              value: prog?.semester_id,
              label: prog?.semester,
            },
            mediumOfExamination: prog?.exam_medium,
            selected_type: prog?.program_pattern_type,
          };
        });
      reset({
        program_details: program_list,
      });
    } else {
      // reset(programInitialValues);
    }
  }, [studentData]);

  useEffect(() => {
    // return () => {
    dispatch(resetFacultSubjectDetailsAction());
    // };
  }, [!location.state.isEdit]);

  const onSubmit = (data) => {
    if (data?.program_details?.length === 1) {
      const programDetails = data.program_details[0];
      const isAllEmptyExceptSelectedType = Object.keys(programDetails).every(
        (key) => key === 'selected_type' || programDetails[key] === ''
      );

      if (isAllEmptyExceptSelectedType) {
        var programValues = [];
      } else {
        var programValues = data?.program_details?.map((item) => {
          return {
            department: item.department_name.value,
            program: item.program_name.value,
            program_name: item.program_name.label,
            user_type: 0,
            year_id: item.program_year.value,
            year: item.program_year.label,
            semester_id: item.semester.value,
            semester: item.selected_type === 0 ? item.semester?.label : 1,
            exam_medium: item.mediumOfExamination,
            program_pattern_type: item.selected_type,
          };
        });
      }
    } else {
      var programValues = data?.program_details?.map((item) => {
        return {
          department: item.department_name.value,
          program: item.program_name.value,
          program_name: item.program_name.label,
          user_type: 0,
          year_id: item.program_year.value,
          year: item.program_year.label,
          semester_id: item.semester.value,
          semester: item.selected_type === 0 ? item.semester?.label : 1,
          exam_medium: item.mediumOfExamination,
          program_pattern_type: item.selected_type,
        };
      });
    }

    // const roleID = designationList?.results?.[0]?.role_id;
    const roleID =
      designationList?.results?.find((item) => item.role_name === 'student')
        ?.role_id || '';

    const studentOnboardPayload = {
      tenant: localStorageHelper.get('tenantId'),
      first_name: props.studentData?.first_name,
      tenant_name: localStorageHelper.get('tenantName'),
      middle_name: props.studentData?.middle_name,
      last_name: props.studentData?.last_name,
      contact_no: props.studentData?.contact_no,
      email: props.studentData?.email,
      abc_id: props.studentData?.abc_id ? props.studentData?.abc_id : '0',
      study_center_code: props.studentData?.study_center_code,
      enrollment_id: props.studentData?.enrollment_no
        ? props.studentData?.enrollment_no
        : '',
      created_by: localStorageHelper.get('user_id'),
      medium: props.studentData?.medium,
      category: props.studentData?.category,
      specially_abled: props.studentData?.specially_abled,
      course_code: props.studentData?.course_code,
      college_id: props.studentData?.college_id,
      institute_name: props.studentData?.institute_name,
      gender: props.studentData?.gender,
      marital_status: props.studentData?.marital_status,
      DOB: props.studentData?.DOB
        ? moment(props.studentData?.DOB).format('YYYY-MM-DD')
        : '',
      id_type: props.studentData?.id_type,
      id_number: props.studentData?.id_number,
      father_name: props.studentData?.father_name,
      father_contact: props.studentData?.father_contact,
      father_email: props.studentData?.father_email,
      mother_name: props.studentData?.mother_name,
      mother_contact: props.studentData?.mother_contact,
      mother_email: props.studentData?.mother_email,
      mother_maiden_name: props.studentData?.mother_maiden_name,
      profile_picture: props.userImage?.image_url,
      student_address: [
        {
          p_locality: props.studentData?.student_address?.[0]?.p_locality || '',
          p_city: props.studentData?.student_address?.[0]?.p_city?.label || '',
          p_state:
            props.studentData?.student_address?.[0]?.p_state?.label || '',
          p_country:
            props.studentData?.student_address?.[0]?.p_country?.label || '',
          p_pincode: props.studentData?.student_address?.[0]?.p_pincode || '',
          is_current_address_same:
            props.studentData?.student_address?.[0]?.is_current_address_same,
          c_locality: props.studentData?.student_address?.[0]?.c_locality || '',
          c_city: props.studentData?.student_address?.[0]?.c_city?.label || '',
          c_state:
            props.studentData?.student_address?.[0]?.c_state?.label || '',
          c_country:
            props.studentData?.student_address?.[0]?.c_country?.label || '',
          c_pincode: props.studentData?.student_address?.[0]?.c_pincode || '',
        },
      ],
      user_program_mapping: programValues,
      user_group_id: [
        {
          group_id: '1',
        },
      ],
      education_detail: [
        ...props.studentData?.education_detail?.map((edu) => {
          return {
            ...edu,
            tenantId: localStorageHelper.get('tenantId'),
          };
        }),
      ],
    };
    // dispatch(createStudent(studentOnboardPayload));

    const editStudentPayload = {
      id: id,
      ...studentOnboardPayload,
      userId: id,
      userMetaData: {
        abcid: data?.abc_id,
        tenantId: localStorageHelper.get('tenantId'),
        uid: studentData?.userMetaData?.uid,
      },
      education_detail: [
        ...studentOnboardPayload?.education_detail?.map((edu) => {
          return {
            ...edu,
            tenantId: localStorageHelper.get('tenantId'),
            userId: id,
          };
        }),
      ],
    };
    if (location?.state?.isEdit) {
      props?.studentData?.gender !== '' &&
        dispatch(updateStudent(editStudentPayload));
      // dispatch(updateStudentProgramDetailsAction(payload));
    } else {
      props?.studentData?.gender !== '' &&
        dispatch(createStudent(studentOnboardPayload));
      // dispatch(createStudentProgramDetailsAction(payload));
      // history.push(ROUTES?.STUDENT_MANAGEMENT?.ROOT);
    }
    // dispatch(updateStudent(editStudentPayload));
  };

  useEffect(() => {
    if (createdStudent?.length > 0 || !_.isEmpty(createdStudent)) {
      history.push(ROUTES?.STUDENT_MANAGEMENT?.ROOT);
    }
  }, [createdStudent]);

  const handleSubmitClick = () => {
    const firstSubmitBtn = document.getElementById('firstSubmitBtn');
    if (firstSubmitBtn) {
      firstSubmitBtn.click();
    }
  };

  const handleTypeChange = (event) => {
    event.target.name === 'Semester Pattern'
      ? setSelectedType(0)
      : setSelectedType(1);
    const department = getValues(
      `program_details.${programFields.length - 1}.department_name`
    );
    if (!checkIfObjectNotEmpty(department)) {
      setValue(
        `program_details.${programFields.length - 1}.selected_type`,
        selectedType === 0 ? 1 : 0
      );
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const courseFieldName = name?.split('.');
      const index = courseFieldName?.[1];
      if (courseFieldName?.[2] === 'cluster_name') {
        dispatch(getDepartmentsResetIncr());
        dispatch(getProgramListResetIncr());
        setValue(`program_details.${index}.department_name`, '');
        setValue(`program_details.${index}.program_name`, '');
        setValue(`program_details.${index}.program_year`, '');
        setValue(`program_details.${index}.semester`, '');
        setValue(`program_details.${index}.mediumOfExamination`, '');
      }

      if (courseFieldName?.[2] === 'department_name') {
        dispatch(getProgramListResetIncr());
        setValue(`program_details.${index}.program_name`, '');
        setValue(`program_details.${index}.program_year`, '');
        setValue(`program_details.${index}.semester`, '');
        setValue(`program_details.${index}.mediumOfExamination`, '');
      }

      if (courseFieldName?.[2] === 'program_name') {
        setValue(`program_details.${index}.program_year`, '');
        setValue(`program_details.${index}.semester`, '');
        setValue(`program_details.${index}.mediumOfExamination`, '');
      }

      if (courseFieldName?.[2] === 'program_year') {
        setValue(`program_details.${index}.semester`, '');
        setValue(`program_details.${index}.mediumOfExamination`, '');
      }

      if (courseFieldName?.[2] === 'semester') {
        setValue(`program_details.${index}.mediumOfExamination`, '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (clusters?.data?.length > 0) {
      setClustersList(
        dynamicAutoListMapper(clusters?.data, 'cluster_name', 'id')
      );
    }
  }, [clusters]);

  useEffect(() => {
    if (allDepartment?.data?.length > 0) {
      selectedDropdown.cluster &&
        setDepartmentList((e) => {
          return {
            ...e,
            [selectedDropdown.cluster]:
              dynamicAutoListMapper(
                allDepartment.data,
                'department_name',
                'id'
              ) || [],
          };
        });
    }
  }, [allDepartment]);

  useEffect(() => {
    const programList = program?.programList?.results || [];
    if (programList?.length > 0) {
      selectedDropdown.department &&
        setProgramList((e) => {
          return {
            ...e,
            [newSelectedType]: {
              ...e[newSelectedType],
              [selectedDropdown.department]:
                dynamicAutoListMapper(programList, 'program_name', 'id') || [],
            },
          };
        });
    }
  }, [program.programList]);

  useEffect(() => {
    const programYears = program.programYearList?.results || [];
    if (programYears?.length > 0) {
      selectedDropdown.program &&
        setProgramYearList((e) => {
          return {
            ...e,
            [selectedDropdown.program]:
              dynamicListMapper(programYears, 'year', 'id') || [],
          };
        });
    }
  }, [program.programYearList]);

  useEffect(() => {
    const semesters = program.semesterList?.results || [];
    if (semesters?.length > 0) {
      selectedDropdown.semester &&
        setSemesterList((e) => {
          return {
            ...e,
            [selectedDropdown.semester]:
              dynamicListMapper(semesters, 'semester', 'id') || [],
          };
        });
    }
  }, [program.semesterList]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container className="py4 px0">
            <Grid item xl={2} lg={2} md={2}>
              <Typography variant="h4">
                {selectedType === 0 ? t('semesterPattern') : t('annualPattern')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item lg={2} md={2}>
              <Typography variant="body1">{t('programDetails')}</Typography>
            </Grid>

            <Grid item lg={2} md={2}>
              <FormControl>
                <RadioGroup
                  className="display-flex flex-direction-row"
                  name={t('annualPattern')}
                  onChange={handleTypeChange}
                  value={selectedType}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={t('annualPattern')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item lg={2} md={2}>
              <FormControl>
                <RadioGroup
                  className="display-flex flex-direction-row"
                  name={t('semesterPattern')}
                  onChange={handleTypeChange}
                  value={selectedType}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={t('semesterPattern')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {/* {programFields?.map((field, index) => {
              return (
                <StudentDetailsForm
                  key={index}
                  index={index}
                  length={programFields?.length}
                  addProgramDetails={addProgramDetails}
                  removeProgramDetails={removeProgramDetails}
                  name={'program_details'}
                  getClusterHandler={getClusterHandler}
                  selectedCluster={getValues(
                    `program_details.${index}.cluster_name`
                  )}
                  selectedDepartment={watch(
                    `program_details.${index}.department_name`
                  )}
                  selectedProgram={watch(
                    `program_details.${index}.program_name`
                  )}
                  selectedProgramYear={watch(
                    `program_details.${index}.program_year`
                  )}
                  selectedType={selectedType}
                />
              );
            })} */}

          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            xl={10}
            md={10}
            ml={1}
          >
            {programFields?.map((val, index) => {
              const {
                cluster_name,
                department_name,
                program_name,
                program_year,
                selected_type,
              } = watch(`program_details.${index}`);

              return (
                <Fragment key={index}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid xl={3} md={3} item>
                      <FormAutoComplete
                        onOpen={getClusterHandler}
                        name={`program_details.${index}.cluster_name`}
                        variant="outlined"
                        label={t('cluster')}
                        size="small"
                        // isAsterisk
                        fullWidth
                        handleFetchNext={getClusterHandler}
                        onChange={(e, val) =>
                          getDepartmentHandler(e, val, index, 'multi')
                        }
                        options={clustersList}
                        totalCount={clustersList?.length}
                        className="qa-cluster"
                      />
                    </Grid>
                    <Grid xl={3} md={3} item>
                      <FormAutoComplete
                        name={`program_details.${index}.department_name`}
                        variant="outlined"
                        label={t('department')}
                        size="small"
                        fullWidth
                        handleFetchNext={getDepartmentListHandler}
                        options={departmentList[cluster_name?.value] || []}
                        onChange={(e, val) =>
                          getProgramHandler(e, val, index, selected_type)
                        }
                        totalCount={
                          departmentList[cluster_name?.value]?.length || 0
                        }
                        className="qa-department"
                      />
                    </Grid>
                    <Grid xl={3} md={3} item>
                      <FormAutoComplete
                        name={`program_details.${index}.program_name`}
                        variant="outlined"
                        label={t('program')}
                        size="small"
                        fullWidth
                        handleFetchNext={getProgramListHandler}
                        options={
                          programList?.[selected_type]?.[
                            department_name?.value
                          ] || []
                        }
                        onChange={(e, val) =>
                          getProgramYearHandler(e, val, index)
                        }
                        totalCount={
                          programList?.[selected_type]?.[department_name?.value]
                            ?.length || 0
                        }
                        className="qa-program"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center">
                    <Grid xl={3} md={3} item>
                      <FormAutoComplete
                        name={`program_details.${index}.program_year`}
                        variant="outlined"
                        label={t('programYear')}
                        size="small"
                        fullWidth
                        handleFetchNext={getProgramYearListHandler}
                        options={programYearList[program_name?.value] || []}
                        onChange={(e, val) =>
                          getSemesterHandler(e, val, index, selected_type)
                        }
                        totalCount={
                          programYearList[program_name?.value]?.length || 0
                        }
                        className="qa-program"
                      />
                    </Grid>
                    <Grid xl={3} md={3} item>
                      <FormAutoComplete
                        name={`program_details.${index}.semester`}
                        variant="outlined"
                        label={t('semester')}
                        size="small"
                        fullWidth
                        handleFetchNext={getSemesterListHandler}
                        options={semesterList[program_year?.value] || []}
                        onChange={(e, val) =>
                          setValue(`program_details.${index}.semester`, val)
                        }
                        totalCount={
                          semesterList[program_year?.value]?.length || 0
                        }
                        disabled={selected_type === 1}
                        className="qa-program"
                      />
                    </Grid>
                    <Grid xl={3} md={3} item>
                      <FormSelectField
                        name={`program_details.${index}.mediumOfExamination`}
                        variant="outlined"
                        label={'Medium of Examination'}
                        size="small"
                        fullWidth
                        options={courseLanguageOptions}
                        className="qa-medium"
                      />
                    </Grid>

                    <Grid item xl={3} md={3}>
                      <IconButton
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={() => removeProgramDetails(index)}
                      >
                        <DeleteSvg />
                      </IconButton>
                      {programFields?.length - 1 === index && (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => addProgramDetails(index)}
                        >
                          <AddIconCircle className={`qa-add-${index}`} />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid xl={12} md={12} item>
                      <Divider />
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>

          <Grid container>
            <Grid
              item
              xl={12}
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'end',
                mb: 0.5,
              }}
              offset={9}
            >
              <Button
                variant="contained"
                color="grey"
                sx={{ margin: '1rem' }}
                className="qa-cancel-btn"
                onClick={resetValues}
              >
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ margin: '1rem' }}
                className="qa-submit-btn"
                onClick={handleSubmitClick}
              >
                {location?.state?.isEdit ? t('update') : t('submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default StudentProgramDetails;
