export const feedbackActions = {
  FEEDBACK: {
    GET_LIST: 'GET_FEEDBACK_LIST',
    GET_SUCCESS_FEEDBACK_LIST: 'GET_SUCCESS_FEEDBACK_LIST',
    GET_ERROR_FEEDBACK_LIST: 'GET_ERROR_FEEDBACK_LIST',
    POST_FFEDBACK: 'POST_FFEDBACK',
    POST_FFEDBACK_SUCCESS: 'POST_FFEDBACK_SUCCESS',
    POST_FFEDBACK_ERROR: 'POST_FFEDBACK_ERROR',
    LOADING: 'LOADING',
    GET_COURSE_LIST :'GET_COURSE_LIST',
    GET_COURSE_LIST_SUCCESS : 'GET_COURSE_LIST_SUCCESS',
    GET_COURSE_LIST_ERROR: 'GET_COURSE_LIST_ERROR',
  },
};
