import { userProfileActions } from './UserProfile.constants';
const { USER_PROFILE, SOCIAL_HANDLE } = userProfileActions;

export const getUserProfile = (payload) => ({
  type: USER_PROFILE.get,
  payload: payload,
});

export const getUserProfileSuccess = (payload) => ({
  type: USER_PROFILE.get_success,
  payload: payload,
});

export const getUserProfileError = (payload) => ({
  type: USER_PROFILE.get_error,
  payload: payload,
});

export const resetUserProfile = () => ({
  type: USER_PROFILE.reset_profile,
});

export const editUserProfile = (payload, data, file) => ({
  type: USER_PROFILE.update,
  payload: payload,
  data: data,
  file: file,
});

export const editUserProfileSuccess = (payload) => ({
  type: USER_PROFILE.update_success,
  payload: payload,
});

export const editUserProfileError = (payload) => ({
  type: USER_PROFILE.update_error,
  payload: payload,
});

export const successProfileUploadImage = (payload) => ({
  type: USER_PROFILE?.profile_upload_img_success,
  payload: payload,
});

export const errorProfileUploadImage = (payload) => ({
  type: USER_PROFILE?.profile_upload_img_error,
  payload: payload,
});

export const createChangePassword = (payload) => ({
  type: USER_PROFILE?.create,
  payload: payload,
});

export const createSuccessChangePassword = (payload) => ({
  type: USER_PROFILE?.create_success,
  payload: payload,
});

export const createErrorChangePassword = (payload) => ({
  type: USER_PROFILE?.create_error,
  payload: payload,
});

export const getSocialInformations = (payload) => ({
  type: SOCIAL_HANDLE.get,
  payload: payload,
});

export const getSocialInformationsSuccess = (payload) => ({
  type: SOCIAL_HANDLE.get_success,
  payload: payload,
});

export const getSocialInformationsError = (payload) => ({
  type: SOCIAL_HANDLE.get_error,
  payload: payload,
});

export const createSocialDetails = (payload) => ({
  type: SOCIAL_HANDLE.create,
  payload: payload,
});

export const createSocialDetailsSuccess = (payload) => ({
  type: SOCIAL_HANDLE.create_success,
  payload: payload,
});

export const createSocialDetailsError = (payload) => ({
  type: SOCIAL_HANDLE.create_error,
  payload: payload,
});
