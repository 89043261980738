import React from 'react';

const MenuBookSvg = ({ fill, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="32" width="40" fill={fill}>
      <path d="M23.333 16.208v-2.25q1.375-.583 2.813-.875 1.437-.291 3.021-.291 1.083 0 2.125.166 1.041.167 2.041.417v2.083q-1-.375-2.021-.562-1.02-.188-2.145-.188-1.584 0-3.042.396-1.458.396-2.792 1.104Zm0 9.167v-2.292q1.375-.583 2.813-.854 1.437-.271 3.021-.271 1.083 0 2.125.167 1.041.167 2.041.417v2.083q-1-.375-2.021-.563-1.02-.187-2.145-.187-1.584 0-3.042.375-1.458.375-2.792 1.125Zm0-4.583v-2.25q1.375-.584 2.813-.875 1.437-.292 3.021-.292 1.083 0 2.125.167 1.041.166 2.041.416v2.084q-1-.375-2.021-.563-1.02-.187-2.145-.187-1.584 0-3.042.396-1.458.395-2.792 1.104ZM10.5 27.208q2.125 0 4.167.5 2.041.5 4 1.5V11.875q-1.834-1.167-3.917-1.792-2.083-.625-4.25-.625-1.542 0-3.063.355-1.52.354-2.979.937v17.542q1.334-.542 2.917-.813 1.583-.271 3.125-.271Zm10.958 2q2-1 3.938-1.5 1.937-.5 4.104-.5 1.542 0 3.146.25 1.604.25 2.896.709V10.75q-1.375-.667-2.917-.979-1.542-.313-3.125-.313-2.167 0-4.208.625-2.042.625-3.834 1.792Zm-1.416 4.125q-2.084-1.583-4.521-2.437-2.438-.854-5.021-.854-1.583 0-3.125.396-1.542.395-3.042 1.02-.916.459-1.791-.083-.875-.542-.875-1.583V10.208q0-.541.27-1.041.271-.5.813-.75 1.792-.875 3.75-1.313 1.958-.437 4-.437 2.542 0 4.979.687 2.438.688 4.563 2.063 2.125-1.375 4.52-2.063 2.396-.687 4.938-.687 2.042 0 3.979.437 1.938.438 3.729 1.313.542.25.834.75.291.5.291 1.041v19.584q0 1.083-.895 1.646-.896.562-1.813.02-1.458-.666-3-1.041-1.542-.375-3.125-.375-2.583 0-4.979.875t-4.479 2.416Zm-8.5-13.791Z" />
    </svg>
  );
};

export default MenuBookSvg;
