import { EllipsisOptionComponent } from 'components';
import { Typography } from 'iiht-b2a-ui-components/build/components';
import { Link } from 'react-router-dom';
import { CreationStatus, evaluationStatus } from './commonConstants';
import { ROUTES } from './routesConstants';

export const coursesColumnRegistrar = (t, options) => [
  {
    field: 'courseName',
    headerName: t('courseManagement.courseName'),
    sortable: false,
    width: 270,
    renderCell: (item) => {
      return (
        <Link
          color="inherit"
          to={`${ROUTES.COURSE_MANAGEMENT.ROOT}/${ROUTES.COURSE_MANAGEMENT.COURSE_DETAILS}/${item?.row?.departmentId}/${item?.id}`}
          className={`qa-course-${item?.value}`}
        >
          {item?.value}
        </Link>
      );
    },
  },
  {
    field: 'noOfFaculty',
    headerName: t('courseManagement.noOfFaculty'),
    sortable: false,
    width: 150,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'totalCredits',
    headerName: t('courseManagement.totalCreditPoints'),
    type: 'number',
    width: 200,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'duration',
    headerName: t('courseManagement.courseDuration'),
    type: 'number',
    width: 270,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'students',
    headerName: t('courseManagement.students'),
    sortable: false,
    width: 270,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'actions',
    headerName: '',
    renderCell: (params) => (
      <>
        {console.log(params)}
        <EllipsisOptionComponent
          options={options}
          record={params.row}
          className={`qa-elipses-${params.row.courseName}`}
        />
      </>
    ),
  },
];

export const coursesColumnHod = (t, options) => [
  {
    field: 'courseName',
    headerName: t('courseManagement.courseName'),
    sortable: false,
    width: 180,
    renderCell: (item) => {
      return (
        <Link
          color="inherit"
          to={`/${ROUTES.COURSE_MANAGEMENT.DEPARTMENT_DETAILS}/${ROUTES.COURSE_MANAGEMENT.COURSE_DETAILS}/${item?.row?.departmentId}/${item.id}`}
          className={`qa-course-${item?.value}`}
        >
          {item?.value}
        </Link>
      );
    },
  },
  {
    field: 'courseCreator',
    headerName: t('courseManagement.courseCreator'),
    sortable: false,
    width: 130,
    align: 'center',
    headerAlign: 'left',
    flex: 1,
  },
  {
    field: 'creationStatus',
    headerName: t('courseManagement.creationStatus'),
    type: 'number',
    width: 40,
    sortable: false,
    align: 'center',
    headerAlign: 'left',
    flex: 1,
    renderCell: (item) => {
      return (
        <Typography>{CreationStatus[item?.value] || item?.value}</Typography>
      );
    },
  },
  {
    field: 'totalCredits',
    headerName: t('courseManagement.totalCreditPoints'),
    type: 'number',
    width: 120,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    flex: 1,
  },
  {
    field: 'duration',
    headerName: t('courseManagement.courseDuration'),
    type: 'number',
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    flex: 1,
  },
  {
    field: 'students',
    headerName: t('courseManagement.students'),
    sortable: false,
    width: 120,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'evaluationStatus',
    headerName: t('courseManagement.evaluationStatus'),
    sortable: false,
    width: 140,
    align: 'center',
    headerAlign: 'right',
    renderCell: (item) => {
      return (
        <Typography>{evaluationStatus[item?.value] || item?.value}</Typography>
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 10,
    renderCell: (params) => (
      <EllipsisOptionComponent
        options={options}
        record={params.row}
        className={`qa-elipses-${params.row.courseName}`}
      />
    ),
  },
];
