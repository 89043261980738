import { feeManagementActions } from 'store/Fees';

const INITIAL_STATE = {
  FeeManagment: {
    loading: false,
    totalCount: 0,
    data: [],
    feeSaveSuccess: false,
    feesUpload: '',
  },
  department: {
    loading: false,
    totalCount: 0,
    data: [],
    departmentDeleteSuccess: false,
    departmentSaveSuccess: false,
  },
  department_details: {
    data: {},
    loading: false,
  },
  modules: {
    module_list: { data: [], loading: false },
    active_module: {
      saveModuleFeedback: {},
      saveModuleSuccess: false,
      saveSubModuleFeedback: {},
      saveSubModuleSuccess: false,
      submodule_list: [],
    },
  },
};

const feesManagementReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case feeManagementActions.FEE.get: {
      return {
        ...state,
        payload: action?.payload,
      };
    }
    case feeManagementActions.FEE.get_success: {
      return {
        ...state,
        FeeManagment: {
          ...state.FeeManagment,
          totalCount: action?.payload?.totalCount,
          data: action?.payload,
        },
      };
    }
    case feeManagementActions.FEE.loading: {
      return {
        ...state,
        FeeManagment: {
          ...state.FeeManagment,
          loading: action?.payload,
        },
      };
    }
    case feeManagementActions.FEE.create_FeesUpload_success: {
      return {
        ...state,
        FeeManagment: { ...state.FeeManagment, feesUpload: action.payload },
      };
    }

    case feeManagementActions.FEE.reset_fees_upload: {
      return {
        ...state,
        FeeManagment: { ...state.FeeManagment, feesUpload: '' },
      };
    }

    case feeManagementActions.FEE.create_FeeEmailRemainder_error: {
      return {
        ...state,
        FeeManagment: { ...state.FeeManagment, feeSaveSuccess: action.payload },
      };
    }
    default:
      return state;
  }
};
export default feesManagementReducer;
