import { feedbackActions } from './Feedback.constants';
const { FEEDBACK } = feedbackActions;

export const getFeedBackList = (payload) => ({
  type: FEEDBACK.GET_LIST,
  payload: payload,
});

export const getSuccessFeedBackList = (payload) => ({
  type: FEEDBACK.GET_SUCCESS_FEEDBACK_LIST,
  payload: payload,
});

export const getErrorFeedBackList = (payload) => ({
  type: FEEDBACK.GET_ERROR_FEEDBACK_LIST,
  payload: payload,
});

export const createStudentFeedback = (payload) => ({
  type: FEEDBACK.POST_FFEDBACK,
  payload: payload,
});

export const createStudentFeedbackSuccess = (payload) => ({
  type: FEEDBACK.POST_FFEDBACK_SUCCESS,
  payload: payload,
});

export const createStudentFeedbackError = (payload) => ({
  type: FEEDBACK.POST_FFEDBACK_ERROR,
  payload: payload,
});

export const getFeedbackLoading = (payload) => ({
  type: FEEDBACK.LOADING,
  payload: payload,
});

//Get Course List
export const getFeedbackCourseList = (payload) => ({
  type: FEEDBACK.GET_COURSE_LIST,
  payload: payload,
});

export const getFeedbackCourseListSuccess = (payload) => ({
  type: FEEDBACK.GET_COURSE_LIST_SUCCESS,
  payload: payload,
});

export const getFeedbackCourseListError = (payload) => ({
  type: FEEDBACK.GET_COURSE_LIST_ERROR,
  payload: payload,
});
