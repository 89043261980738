import { discussionBoardActions } from './DiscussionBoard.constants';

const INITIAL_STATE = {
  loading: false,
  discussionBoard: {
    totalCount: 0,
    data: [],
    dataObj: {},
    discussionBoardDeleteSuccess: false,
    discussionBoardUpsertSuccess: false,
  },
  reportingType: {
    dataObj: {},
    reportingTypeDeleteSuccess: false,
  },

  replyData: {
    totalCount: 0,
    data: [],
    dataObj: {},
    commentReplyDataDeleteSuccess: false,
    commentReplyDataSaveSuccess: false,
    pinUnpinCommentUpdateSuccess: false,
  },
  polling: {
    pollingUpdateSuccess: false,
  },
  reportDiscussion: {
    success: '',
    error: '',
  },
  reportDiscussionAction: {
    success: '',
    error: '',
  },
};

const discussionBoardReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case discussionBoardActions.DISCUSSIONBOARD.loading: {
      return {
        ...state,
        loading: action?.payload,
      };
    }

    case discussionBoardActions.DISCUSSIONBOARD.get_success: {
      const discussionList = action?.payload;
      return {
        ...state,
        discussionBoard: {
          ...state.discussionBoard,
          totalCount: action?.payload?.totalCount,
          data: [...discussionList],
        },
      };
    }

    case discussionBoardActions.DISCUSSIONBOARD.create_success: {
      return {
        ...state,
        discussionBoard: {
          ...state.discussionBoard,
          discussionBoardUpsertSuccess: action?.payload,
        },
      };
    }

    case discussionBoardActions.DISCUSSIONBOARD.update_success: {
      return {
        ...state,
        discussionBoard: {
          ...state.discussionBoard,
          discussionBoardUpsertSuccess: action?.payload,
        },
      };
    }

    case discussionBoardActions.DISCUSSIONBOARD.delete_success: {
      return {
        ...state,
        discussionBoard: {
          ...state.discussionBoard,
          discussionBoardDeleteSuccess: action?.payload,
        },
      };
    }

    case discussionBoardActions.DISCUSSIONBOARD.get_by_id_success: {
      const discussionByIdData = action?.payload ?? {};
      return {
        ...state,
        discussionBoard: {
          ...state.discussionBoard,
          dataObj: { ...discussionByIdData },
        },
      };
    }

    case discussionBoardActions.REPLY.create_reply: {
      return {
        ...state,
        replyData: {
          ...state.replyData,
          totalCount: action?.payload?.totalCount,
          data: action?.payload?.items,
        },
      };
    }

    // Create reply/comment
    case discussionBoardActions.REPLY.create_comment_reply_success: {
      return {
        ...state,
        replyData: {
          ...state.replyData,
          commentReplyDataSaveSuccess: action?.payload,
        },
      };
    }

    // Pin/unpin comment
    case discussionBoardActions.REPLY.update_pin_unpin_comment_success: {
      return {
        ...state,
        replyData: {
          ...state.replyData,
          pinUnpinCommentUpdateSuccess: action?.payload,
        },
      };
    }

    case discussionBoardActions.REPLY.update_pin_unpin_comment_reset: {
      return {
        ...state,
        replyData: {
          ...state.replyData,
          pinUnpinCommentUpdateSuccess: false,
        },
      };
    }

    // Update Polling option
    case discussionBoardActions.POLLING.update_by_id_success: {
      return {
        ...state,
        polling: {
          ...state.polling,
          pollingUpdateSuccess: action?.payload,
        },
      };
    }
    case discussionBoardActions.POLLING.update_by_id_reset: {
      return {
        ...state,
        polling: {
          ...state.polling,
          pollingUpdateSuccess: false,
        },
      };
    }

    case discussionBoardActions.REPORT.report_discussion_success: {
      return {
        ...state,
        reportDiscussion: {
          success: action.payload,
          error: '',
        },
      };
    }
    case discussionBoardActions.REPORT.report_discussion_error: {
      return {
        ...state,
        reportDiscussion: {
          error: action.payload,
          success: '',
        },
      };
    }
    case discussionBoardActions.REPORT.reset: {
      return {
        ...state,
        reportDiscussion: {
          success: '',
          error: '',
        },
      };
    }
    case discussionBoardActions.REPORT_ACTION
      .report_discussion_action_success: {
      return {
        ...state,
        reportDiscussion: {
          success: action.payload,
          error: '',
        },
      };
    }
    case discussionBoardActions.REPORT_ACTION.report_discussion_action_error: {
      return {
        ...state,
        reportDiscussion: {
          error: action.payload,
          success: '',
        },
      };
    }
    case discussionBoardActions.REPORT_ACTION.reset: {
      return {
        ...state,
        reportDiscussion: {
          success: '',
          error: '',
        },
      };
    }

    case discussionBoardActions.DISCUSSIONBOARD.get_comment_by_id_success: {
      const discussionByIdData = action?.payload ?? {};
      return {
        ...state,
        discussionBoard: {
          ...state.discussionBoard,
          dataObj: { ...discussionByIdData },
        },
      };
    }

    case discussionBoardActions.DISCUSSIONBOARD.update_discussion_success: {
      return {
        ...state,
        discussionBoard: {
          ...state.discussionBoard,
          discussionBoardUpsertSuccess: action?.payload,
        },
      };
    }

    case discussionBoardActions.DISCUSSIONBOARD.get_user_by_subject_success: {
      return {
        ...state,
        discussionBoard: {
          ...state.discussionBoard,
          discussionBoardUpsertSuccess: action?.payload,
        },
      };
    }

    default:
      return state;
  }
};
export default discussionBoardReducer;
