import { LeadActions } from './Lead.constants';
const { LEAD } = LeadActions;

export const setLeadLoading = (payload) => ({
  type: LEAD.LOADING,
  payload: payload,
});

export const getLeadListSuccess = (payload) => ({
  type: LEAD.GET_LEAD_LIST_SUCCESS,
  payload: payload,
});

export const getLeadListError = (payload) => ({
  type: LEAD.GET_LEAD_LIST_ERROR,
  payload: payload,
});

export const getLeadListAction = (payload) => ({
  type: LEAD.GET_LEAD_LIST,
  payload: payload,
});

export const postLeadInfoAction = (payload) => ({
  type: LEAD.POST_LEAD,
  payload: payload,
});

export const postLeadInfoSuccess = (payload) => ({
  type: LEAD.POST_LEAD_SUCCESS,
  payload: payload,
});
// export const postLeadInfoSuccessFeedback = (payload) => ({
//   type: LEAD.POST_LEAD_SUCCESS_SAVE_FEEDBACK,
//   payload: payload,
// });

export const postLeadInfoError = (payload) => ({
  type: LEAD.POST_LEAD_ERROR,
  payload: payload,
});

export const deleteLeadInfoAction = (payload) => ({
  type: LEAD.DELETE_LEAD,
  payload: payload,
});

export const deleteLeadInfoSuccess = (payload) => ({
  type: LEAD.DELETE_LEAD_SUCCESS,
  payload: payload,
});

export const deleteLeadInforError = (payload) => ({
  type: LEAD.DELETE_LEAD_ERROR,
  payload: payload,
});

export const updateLeadInfoAction = (payload) => ({
  type: LEAD.UPDATE_LEAD,
  payload: payload,
});

export const updateLeadInfoSuccess = (payload) => ({
  type: LEAD.UPDATE_LEAD_SUCCESS,
  payload: payload,
});

export const updateLeadInforError = (payload) => ({
  type: LEAD.UPDATE_LEAD_ERROR,
  payload: payload,
});

export const postNotesAction = (payload) => ({
  type: LEAD.POST_NOTES,
  payload: payload,
});

export const postNotesSuccess = (payload) => ({
  type: LEAD.POST_NOTES_SUCCESS,
  payload: payload,
});

export const postNotesError = (payload) => ({
  type: LEAD.POST_NOTES_ERROR,
  payload: payload,
});

export const getNotesAction = (payload) => ({
  type: LEAD.GET_NOTES,
  payload: payload,
});

export const getNotesSuccess = (payload) => ({
  type: LEAD.GET_NOTES_SUCCESS,
  payload: payload,
});

export const getNotesReset = () => ({
  type: LEAD.GET_NOTES_RESET,
});

export const getNotesError = (payload) => ({
  type: LEAD.GET_NOTES_ERROR,
  payload: payload,
});

export const getMoreNotesRequest = (payload) => ({
  type: LEAD.GET_MORE_NOTES_REQUEST,
  payload: payload,
});

export const getMoreNotesSuccess = (payload) => ({
  type: LEAD.GET_MORE_NOTES_SUCCESS,
  payload: payload,
});

export const getLeadStatusAction = (payload) => ({
  type: LEAD.GET_LEAD_STATUS,
  payload: payload,
});

export const getLeadStatusSuccess = (payload) => ({
  type: LEAD.GET_LEAD_STATUS_SUCCESS,
  payload: payload,
});

export const getLeadStatusError = (payload) => ({
  type: LEAD.GET_LEAD_STATUS_ERROR,
  payload: payload,
});

export const getLeadStageAction = (payload) => ({
  type: LEAD.GET_LEAD_STAGE,
  payload: payload,
});

export const getLeadStageSuccess = (payload) => ({
  type: LEAD.GET_LEAD_STAGE_SUCCESS,
  payload: payload,
});

export const getLeadStageError = (payload) => ({
  type: LEAD.GET_LEAD_STAGE_ERROR,
  payload: payload,
});

export const updateLeadStatusAction = (payload) => ({
  type: LEAD.UPDATE_LEAD_STATUS,
  payload: payload,
});

export const updateLeadStatusSuccess = (payload) => ({
  type: LEAD.UPDATE_LEAD_STATUS_SUCCESS,
  payload: payload,
});

export const updateLeadStatusError = (payload) => ({
  type: LEAD.UPDATE_LEAD_STATUS_ERROR,
  payload: payload,
});

export const getDashboardStatsAction = (payload) => ({
  type: LEAD.GET_DASHBOARD_STATS,
  payload: payload,
});

export const getDashboardStatsSuccess = (payload) => ({
  type: LEAD.GET_DASHBOARD_STATS_SUCCESS,
  payload: payload,
});

export const getDashboardStatsError = (payload) => ({
  type: LEAD.GET_DASHBOARD_STATS_ERROR,
  payload: payload,
});

export const getLeadPlacementListAction = (payload) => ({
  type: LEAD.GET_LEAD_PLACEMENT,
  payload: payload,
});

export const getLeadPlacementListSuccess = (payload) => ({
  type: LEAD.GET_LEAD_PLACEMENT_SUCCESS,
  payload: payload,
});

export const getLeadPlacementListError = (payload) => ({
  type: LEAD.GET_LEAD_PLACEMENT_ERROR,
  payload: payload,
});

export const resetLeadPlacementListAction = () => ({
  type: LEAD.REST_LEAD_PLACEMENT,
});

export const updateLeadStageAction = (payload) => ({
  type: LEAD.UPDATE_LEAD_STAGE,
  payload: payload,
});

export const updateLeadStageSuccess = (payload) => ({
  type: LEAD.UPDATE_LEAD_STAGE_SUCCESS,
  payload: payload,
});

export const updateLeadStageError = (payload) => ({
  type: LEAD.UPDATE_LEAD_STAGE_ERROR,
  payload: payload,
});

export const getLeadInfoByIdAction = (payload) => ({
  type: LEAD.GET_LEAD_BY_ID,
  payload: payload,
});

export const getLeadInfoByIdSuccess = (payload) => ({
  type: LEAD.GET_LEAD_BY_ID_SUCCESS,
  payload: payload,
});

export const getLeadInfoByIdError = (payload) => ({
  type: LEAD.GET_LEAD_BY_ID_ERROR,
  payload: payload,
});

export const resetLeadInfoByIdAction = () => ({
  type: LEAD.RESET_GET_LEAD_BY_ID_SUCCESS,
});

export const postBulkLeadInfoAction = (payload) => ({
  type: LEAD.POST_BULK_LEAD,
  payload: payload,
});

export const postBulkLeadInfoSuccess = (payload) => ({
  type: LEAD.POST_BULK_LEAD_SUCCESS,
  payload: payload,
});

export const postBulkLeadInfoError = (payload) => ({
  type: LEAD.POST_BULK_LEAD_ERROR,
  payload: payload,
});

export const patchUpdateLeadStatus = (
  id,
  payload,
  successMessage,
  tenantId
) => ({
  type: LEAD.PATCH_UPDATE_LEAD_STATUS,
  id: id,
  payload: payload,
  successMessage: successMessage,
  tenantId: tenantId,
});

export const patchUpdateLeadStatusSuccess = (payload) => ({
  type: LEAD.PATCH_UPDATE_LEAD_STATUS_SUCCESS,
  payload: payload,
});

export const patchUpdateLeadStatusError = (payload) => ({
  type: LEAD.PATCH_UPDATE_LEAD_STATUS_ERROR,
  payload: payload,
});

export const resetUpdateLeadStatus = () => ({
  type: LEAD.RESET_UPDATE_LEAD_STATUS,
});

export const leadFileUploadAction = (payload) => ({
  type: LEAD.LEAD_FILE_UPLOAD,
  payload: payload,
});

export const leadFileUploadActionSuccess = (payload) => ({
  type: LEAD.LEAD_FILE_UPLOAD_SUCCESS,
  payload: payload,
});

export const resetLeadFileUploadAction = () => ({
  type: LEAD.RESET_LEAD_FILE_UPLOAD,
});

export const leadBulkUploadFile = (payload) => ({
  type: LEAD.LEAD_BULK_UPLOAD_FILE,
  payload: payload,
});

export const leadBulkUploadFileSuccess = (payload) => ({
  type: LEAD.LEAD_BULK_UPLOAD_FILE_SUCCESS,
  payload: payload,
});

export const leadConversionAction = (payload) => ({
  type: LEAD.GET_DASHBOARD_LEAD_CONVERSION,
  payload: payload,
});

export const leadConversionSuccess = (payload) => ({
  type: LEAD.GET_DASHBOARD_LEAD_CONVERSION_SUCCESS,
  payload: payload,
});

export const leadConversionError = (payload) => ({
  type: LEAD.GET_DASHBOARD_LEAD_CONVERSION_ERROR,
  payload: payload,
});

export const leadUploadMultiFile = (payload) => ({
  type: LEAD.LEAD_UPLOAD_MULTI_FILE,
  payload: payload,
});

export const leadUploadMultiFileSuccess = (payload) => ({
  type: LEAD.LEAD_UPLOAD_MULTI_FILE_SUCCESS,
  payload: payload,
});

export const getRoleAction = (payload) => ({
  type: LEAD.GET_ROLE,
  payload: payload
});

export const getRoleSuccess = (payload) => ({
  type: LEAD.GET_ROLE_SUCCESS,
  payload: payload
});

export const getRoleError = (payload) => ({
  type: LEAD.GET_ROLE_ERROR,
  payload: payload
})
