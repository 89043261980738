import { scheduleActions } from 'store/Schedule';
import { prospectusActions } from './Prospectus.constants';

const INITIAL_STATE = {
  prospectusFormsList: { loading: false, count: 0, results: [] },
  postApplicationFormData: { data: {} },
  patchedApplicationForm: { data: {} },
  patchedCourseFees: { data: {} },
  singleProspectusForm: { data: {} },
};

const prospectusReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case prospectusActions.PROSPECTUS_FORM_LIST.get_success: {
      return {
        ...state,
        prospectusFormsList: {
          ...state.prospectusFormsList,
          count: action?.payload?.count,
          results: action?.payload?.results,
        },
      };
    }

    case prospectusActions.PROSPECTUS_FORM_LIST.get_form_success: {
      return {
        ...state,
        singleProspectusForm: {
          ...state.singleProspectusForm,
          data: action?.payload,
        },
      };
    }

    case prospectusActions.APPLICATION_FORM.post_success: {
      return {
        ...state,
        postApplicationFormData: {
          ...state.postApplicationFormData,
          data: action?.payload,
        },
      };
    }

    case prospectusActions.APPLICATION_FORM.patch_success: {
      return {
        ...state,
        patchedApplicationForm: {
          ...state.patchedApplicationForm,
          data: action?.payload,
        },
      };
    }

    case prospectusActions.APPLICATION_FORM.patch_fees_success: {
      return {
        ...state,
        patchedCourseFees: {
          ...state.patchedCourseFees,
          data: action?.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default prospectusReducer;
