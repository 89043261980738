export const GamificationActions = {
  GAMIFICATIONS: {
    loading: 'LOADING',
  },
  STUDENT_GAMIFICATION: {
    loading: 'GAMIFICATION_LOADING',
    get: 'REQUEST_GAMIFICATON_STUDENTS_LIST',
    get_success: 'SUCCESS_GAMIFICATON_STUDENTS_LIST',
    get_error: 'ERROR_GAMIFICATON_STUDENTS_LIST',
    get_top_performer: 'GET_TOP_PERFORMER',
    reset_gamification_list: 'RESET_GAMIFICATIONS_LIST',
    get_student_badge_list: 'GET_STUDENT_BADGE_LIST',
    get_student_badge_list_success: 'GET_STUDENT_BADGE_LIST_SUCCESS',
    get_student_badge_list_error: 'GET_STUDENT_BADGE_LIST_ERROR',
    get_student_badge_detail: 'GET_STUDENT_BADGE_DETAIL',
    get_student_badge_detail_success: 'GET_STUDENT_BADGE_DETAIL_SUCCESS',
    get_student_badge_detail_error: 'GET_STUDENT_BADGE_DETAIL',
  },
  COURSE: {
    loading: 'LOADING',
  },
  BADGE: {
    loading: 'LOADING',
    get: 'REQUEST_BADGE_LIST',
    get_success: 'SUCCESS_BADGE_LIST',
    get_error: 'ERROR_BADGE_LIST',
    update: 'REQUEST_BADGE_UPDATE',
    update_success: 'SUCCESS_BADGE_LIST',
    update_error: 'ERROR_BADGE_LIST',
    create: 'CREATE_BADGE_REQUEST',
    get_by_id: 'REQUEST_BADGE_BY_ID',
    get_by_id_success: 'SUCCESS_BADGE_BY_ID',
    get_by_id_error: 'ERROR_BADGE_BY_ID',
    create_success: 'CREATE_BADGE_SUCCESS',
    create_error: 'CREATE_BADGE_ERROR',
    create_reset: 'CREATE_BADGE_RESET',
    delete: 'DELETE_BADGE_REQUEST',
    delete_success: 'DELETE_BADGE_SUCCESS',
    delete_error: 'DELETE_BADGE_ERROR',
    icons: 'GET_BADGE_ICONS',
    icons_success: 'GET_BADGE_ICONS_SUCCESS',
    icons_error: 'GET_BADGE_ICONS_ERROR',
    assignBadge: 'ASSIGN_BADGE_REQUEST',
    assignBadge_success: 'ASSIGN_BADGE_SUCCESS',
    assignBadge_error: 'ASSIGN_BADGE_ERROR',
    get_student_program: 'STUDENT_PROGRAM_REQUEST',
    get_student_program_success: 'STUDENT_PROGRAM_SUCCESS',
    get_student_program_error: 'STUDENT_PROGRAM_ERROR',
  },
};
