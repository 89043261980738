import * as React from 'react';

const DeleteSvg = ({
  color,
  height = '24',
  width = '24',
  className,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      className={className}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M280 936q-33 0-56.5-23.5T200 856V336h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680 936H280Zm400-600H280v520h400V336ZM360 776h80V416h-80v360Zm160 0h80V416h-80v360ZM280 336v520-520Z"
      />
    </svg>
  );
};

export default DeleteSvg;
