import { CommonModal, FormTextField } from 'components';
import Dropzone from 'components/common/DropZone';
import { FormAutoComplete } from 'components/common/FormAutoComplete';
import { ROUTES } from 'constants/routesConstants';
import { validationRules } from 'constants/validationRules';
import { Grid } from 'iiht-b2a-ui-components/build/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  leadFileUploadAction,
  leadFileUploadActionSuccess,
  resetLeadFileUploadAction,
} from 'store';
import {
  createNotificationApi,
  createNotificationSuccess,
} from 'store/Notifications';
import {
  getAllFacultyDetailsSuccess,
  getAllUserDetailsNotificationAction,
  resetFacultyDetails,
} from 'store/UserManagement';
import { createFormData, dynamicListMapper } from 'utils';
import './Notification.scss';

const CreateBrodcast = ({
  modalVisible,
  setModalVisible,
  isEdit = false,
  editData = {},
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const fileViewerSupportedType = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
    'text/plain': ['.txt'],
  };

  const fileSupportMessage = `Support .png, .jpg, .jpeg, .txt and .pdf formats`;

  const methods = useForm({
    defaultValues: useMemo(() => {
      if (isEdit) {
        let val = dynamicListMapper(
          editData?.recipients_list,
          'username',
          'user_id',
          true
        );
        const attachments = editData?.attachments_list?.map((fileData) => {
          return {
            ...fileData,
            fileURL: fileData.doc_path,
            fileName: fileData.doc_name
              ?.split('/')
              ?.slice(-1)?.[0]
              ?.split('_')
              ?.slice(-1)?.[0],
          };
        });
        editData.attachments = [...attachments];

        return {
          name: editData.display_name,
          description: editData.template_body,
          addMorePeople: val,
        };
      } else {
        return {
          name: '',
          description: '',
          invites: [],
        };
      }
    }),
  });

  const { createNotificationSuccess: notificationCreated } = useSelector(
    (state) => state.notificationState.notification
  );

  const { data: filteredUserList = [], totalCount } = useSelector(
    (state) => state.userManagementState.user_for_notification
  );
  const { fileUploadMetaData } = useSelector((state) => state.leadState.lead);

  const [senderList, setSenderList] = useState([]);

  const {
    idp_tenant_uuid: tenantId,
    idp_user_uuid: userId,
    first_name,
    middle_name,
    last_name,
  } = useSelector((state) => state.userManagementState.user.data);

  const [files, setFiles] = useState('');
  const [page, setPage] = useState(0);
  const [blobObject, setBlobObject] = useState([]);

  const handleClose = () => {
    setModalVisible(false);
    reset({
      defaultValues: {
        name: '',
        description: '',
        invites: [],
      },
    });
    setFiles('');
    setSenderList([]);
    setPage(0);
    setBlobObject([]);
    dispatch(getAllFacultyDetailsSuccess({ data: [], totalCount: 0 }));
    dispatch(resetFacultyDetails());
    // dispatch(uploadNotificationBlobSuccess(''));
  };
  const { handleSubmit, reset } = methods;

  const getFilteredUserListFunc = (
    SearchStringLocal = '',
    SkipCountLocal = 0,
    MaxResultCountLocal = 5
  ) => {
    const req = {
      TenantId: tenantId,
      MaxResultCount: MaxResultCountLocal,
      SkipCount: SkipCountLocal,
      SearchString: SearchStringLocal,
    };
    if (SearchStringLocal && SearchStringLocal?.length > 3)
      req.SearchString = SearchStringLocal;
    // dispatch(getAllFacultyDetailsAction(req));
    dispatch(getAllUserDetailsNotificationAction(req));
  };

  const onSubmit = (data) => {
    let request = {};
    const email_list = [];
    const recipients = data.addMorePeople.reduce(
      (acc, cur) => {
        email_list.push(cur?.email);
        acc.user_list.push({
          id: cur?.id || cur?.id,
          username: cur?.name ? `${cur.name} ${cur.surname}` : cur?.email,
        });
        // acc.group_list.push({ id: cur.group_id, group_name: cur?.group_name });
        return acc;
      },
      { user_list: [] }
    );
    const created_by_name = `${first_name ?? ''} ${middle_name ?? ''} ${
      last_name ?? ''
    }`;
    // for edit flow form the payload
    if (isEdit) {
      request = {
        created_by: userId,
        created_by_name,
        display_name: data?.name,
        template_body: data?.description,
        tenant_id: tenantId,
        // email_list,
        recipients,
        // send_on_mail: 0,
        category: 0,
        attachments: blobObject,
        type: 0,
        isEdit: true,
      };
    } else {
      request = {
        created_by: userId,
        created_by_name,
        display_name: data?.name,
        template_body: data?.description,
        tenant_id: tenantId,
        // email_list,
        recipients,
        // send_on_mail: 0,
        category: 0,
        attachments: blobObject,
        type: 0,
        // type: 10,
        // deliveryType: 10,
        // notificationMetaDatas: blobObject,
        // notificationMappings: mapping,
        // isEdit: false,
      };
    }
    dispatch(createNotificationApi(request));
    setBlobObject([]);
    dispatch(resetLeadFileUploadAction());
  };

  const handleDelete = (value) => {
    console.log(value);
  };

  const brodcastPageHandler = () => {
    history.push({
      pathname: ROUTES?.NOTIFICATIONS?.ROOT,
      state: { isBrodcast: true },
    });
    handleClose();
  };

  const addFileToData = (file, action) => {
    if (file && action === 'remove') {
      setFiles(file);
    } else {
      setFiles(file[0]);
    }
  };

  // const addFileToData = (payload) => {
  //   dispatch(leadFileUploadActionSuccess({}));

  //   const formData = createFormData({ file_type: 1 });
  //   if (payload?.file?.name) {
  //     formData?.set('file', payload?.file, payload?.file?.name);
  //     dispatch(leadFileUploadAction({ data: formData }));
  //   }
  // };

  const deleteAttachment = (record) => {
    // remove the deleted attachment.
  };

  const resetSearch = useCallback((searchUsers) => {
    dispatch(getAllFacultyDetailsSuccess([]));
    dispatch(resetFacultyDetails());
    getFilteredUserListFunc(searchUsers);
  }, []);

  useEffect(() => {
    if (fileUploadMetaData?.blob_name) {
      setBlobObject([fileUploadMetaData.blob_name]);
    }
  }, [fileUploadMetaData]);

  useEffect(() => {
    if (isEdit) {
      setBlobObject(editData?.attachments);
      // let listing = dynamicListMapper(editData?.notificationMappings, 'scopeName', 'scopeId');
    }
  }, [isEdit]);

  useEffect(() => {
    if (page) getFilteredUserListFunc(page);
  }, [page]);

  useEffect(() => {
    if (notificationCreated) {
      handleClose();
      dispatch(createNotificationSuccess(false));
    }
  }, [notificationCreated]);

  useEffect(() => {
    if (files) {
      // const formData = new FormData();
      // formData.append('file', files);
      // dispatch(uploadNotificationBlob(formData));

      dispatch(leadFileUploadActionSuccess({}));
      const formData = createFormData({ file_type: 1 });
      if (files?.name) {
        formData?.set('file', files, files?.name);
        dispatch(leadFileUploadAction({ data: formData }));
      }
    }
  }, [files]);

  useEffect(() => {
    // call api to get userDetails
    getFilteredUserListFunc();
    return () => {
      // dispatch(uploadNotificationBlobSuccess(''));
    };
  }, []);

  const loadOptions = () => {};
  return (
    (modalVisible && (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CommonModal
            open={modalVisible}
            onClose={handleClose}
            modalTitle={
              isEdit
                ? t('notificationsAndBrodcast.editNotification')
                : t('notificationsAndBrodcast.createNotification')
            }
            onSubmit={handleSubmit(onSubmit)}
            showDividers
            cancelBtnName={t('cancel')}
            submitBtnName={isEdit ? t('update') : t('courseManagement.create')}
            fullWidth
            pastBrodcast
            btnTitle={t('notificationsAndBrodcast.pastNotification')}
            redirectHandler={brodcastPageHandler}
          >
            <Grid container direction="column" display="flex">
              <Grid item lg={12} md={12}>
                <FormTextField
                  name="name"
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                  variant="outlined"
                  label={t('notificationsAndBrodcast.notificationName')}
                  size="small"
                  fullWidth
                  className={isEdit ? 'disabled' : ''}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <FormTextField
                  name="description"
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                  variant="outlined"
                  label={t('notificationsAndBrodcast.description')}
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item md={12} lg={12}>
                <FormAutoComplete
                  name="addMorePeople"
                  variant="outlined"
                  label={t('notificationsAndBrodcast.sendInvitesTo')}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                  size="small"
                  fullWidth
                  multiple
                  freeSolo={false}
                  handleFetchNext={getFilteredUserListFunc}
                  totalCount={totalCount || 0}
                  resetSearch={resetSearch}
                  options={
                    filteredUserList?.map((item) => ({
                      ...item,
                      value: item?.id,
                      key: item?.id,
                      label: item?.email,
                    })) || []
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.key === value.key
                  }
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <Dropzone
                  addFile={addFileToData}
                  mappings={editData?.attachments}
                  isEdit={isEdit}
                  deleteAttachment={deleteAttachment}
                  supportedFiles={fileViewerSupportedType}
                  isDocumentViewerAvailable={false}
                  invalidFileMessage={fileSupportMessage}
                  maxFileSize={1}
                />
              </Grid>
            </Grid>
          </CommonModal>
        </form>
      </FormProvider>
    )) ||
    null
  );
};

export default CreateBrodcast;
