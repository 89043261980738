import React from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalStepper } from '../HorizontalStepper';
import { CommonModal } from '../CommonModal';
import { VmConnectModalCheckIcon } from 'icons';
import connectVmGif from '../../gifs/connect-gif.gif';
import './VmConnectModal.scss';
import { Typography } from 'iiht-b2a-ui-components/build/components';

const VmConnectModal = ({ isVisible = false, handleCancel, step }) => {
  const { t } = useTranslation();
  const connectVmSteps = [
    t('myLabs.quotaCheck'),
    t('myLabs.powerCheck'),
    t('myLabs.vmStarted'),
    t('myLabs.vmwareToolsRunning'),
    t('myLabs.obtainingIp'),
  ];

  const commonClasses =
    'display-flex justify-center items-center flex-direction-column';

  return (
    isVisible && (
      <>
        <CommonModal
          onClose={handleCancel}
          hideActions={true}
          open={isVisible}
          maxWidth=""
        >
          <div className="modal-container">
            <div className={commonClasses}>
              {step <= 4 && (
                <img src={connectVmGif} alt="alt" className="connect-vm-gif" />
              )}
              {step >= 5 && (
                <VmConnectModalCheckIcon className="connect-vm-success" />
              )}
            </div>
            <div className={`${commonClasses} mt14`}>
              <Typography varient="headline" className=" font-bold">
                {step <= 4 && t('myLabs.checkingYourVmStatus')}
                {step >= 5 && t('myLabs.vmStatusSuccessFull')}
              </Typography>
            </div>
            <HorizontalStepper
              steps={connectVmSteps}
              activeStep={step}
              clickable={false}
              width="90%"
            />
          </div>
        </CommonModal>
      </>
    )
  );
};
export default VmConnectModal;
