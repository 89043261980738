import { obeActions } from './Obe.constants';

const {
  DEFINE_OUTCOMES_LOADER,
  CREATE_DEFINE_OUTCOMES,
  GET_DEFINE_OUTCOMES,
  GET_MISSION_VS_PEO_MAPPING,
  CREATE_MISSION_VS_PEO_MAPPING,
  GET_CO_VS_PO_MAPPING,
  CREATE_CO_VS_PO_MAPPING,
} = obeActions;

const INITIAL_STATE = {
  defineOutcomesLoader: false,
  createDefineOutcomes: {
    success: '',
    error: '',
  },
  getDefineOutcomes: {
    data: [],
    error: '',
  },
  getMissionvspeoMapping: {
    data: {},
    error: '',
  },
  createMapping: {
    data: {},
    error: '',
  },
  getcovspoMapping: {
    data: {},
    error: '',
  },
  createcopoMapping: {
    data: {},
    error: '',
  },
};

const obeReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case DEFINE_OUTCOMES_LOADER: {
      return {
        ...state,
        defineOutcomesLoader: action.payload,
      };
    }
    case CREATE_DEFINE_OUTCOMES.success: {
      return {
        ...state,
        createDefineOutcomes: {
          success: action.payload,
          error: '',
        },
      };
    }
    case CREATE_DEFINE_OUTCOMES.error: {
      return {
        ...state,
        createDefineOutcomes: {
          success: '',
          error: action.payload,
        },
      };
    }
    case CREATE_DEFINE_OUTCOMES.reset: {
      return {
        ...state,
        createDefineOutcomes: INITIAL_STATE.createDefineOutcomes,
      };
    }
    case GET_DEFINE_OUTCOMES.success: {
      return {
        ...state,
        getDefineOutcomes: {
          data: action.payload,
          error: '',
        },
      };
    }
    case GET_DEFINE_OUTCOMES.error: {
      return {
        ...state,
        getDefineOutcomes: {
          data: [],
          error: action.payload,
        },
      };
    }
    case GET_DEFINE_OUTCOMES.reset: {
      return {
        ...state,
        getDefineOutcomes: INITIAL_STATE.getDefineOutcomes,
      };
    }

    case GET_MISSION_VS_PEO_MAPPING.success: {
      return {
        ...state,
        getMissionvspeoMapping: {
          data: action.payload,
        },
      };
    }

    case GET_MISSION_VS_PEO_MAPPING.reset: {
      return {
        ...state,
        getMissionvspeoMapping: {
          data: {},
          error: '',
        },
      };
    }

    case CREATE_MISSION_VS_PEO_MAPPING.success: {
      return {
        ...state,
        createMapping: {
          data: action.payload,
        },
      };
    }

    case GET_CO_VS_PO_MAPPING.success: {
      return {
        ...state,
        getcovspoMapping: {
          data: action.payload,
          error: '',
        },
      };
    }

    case GET_CO_VS_PO_MAPPING.error: {
      return {
        ...state,
        getcovspoMapping: {
          data: {},
          error: action.payload,
        },
      };
    }

    case GET_CO_VS_PO_MAPPING.reset: {
      return {
        ...state,
        getcovspoMapping: {
          data: {},
          error: '',
        },
      };
    }
    case CREATE_CO_VS_PO_MAPPING.success: {
      return {
        ...state,
        createcopoMapping: {
          data: action.payload,
          error: '',
        },
      };
    }

    case CREATE_CO_VS_PO_MAPPING.error: {
      return {
        ...state,
        createcopoMapping: {
          data: {},
          error: action.payload,
        },
      };
    }
    case CREATE_CO_VS_PO_MAPPING.reset: {
      return {
        ...state,
        createcopoMapping: {
          data: {},
          error: '',
        },
      };
    }
    default:
      return state;
  }
};

export default obeReducer;
