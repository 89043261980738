export const feeManagementActions = {
  FEE: {
    get: 'FEE_MANAGEMENT_CLUSTERS_GET',
    get_success: 'FEE_MANAGEMENT_CLUSTERS_GET_SUCCESS',
    get_error: 'FEE_MANAGEMENT_CLUSTERS_GET_ERROR',
    loading: 'FEE_MANAGEMENT_CLUSTERS_SET_LOADING',

    create_FeesUpload: 'FEE_MANAGEMENT_FEESUPLOAD_CREATE',
    create_FeesUpload_success: 'FEE_MANAGEMENT_FEESUPLOAD_CREATE_SUCCESS',
    create_FeesUpload_error: 'FEE_MANAGEMENT_FEESUPLOAD_CREATE_ERROR',
    reset_fees_upload: 'RESET_FEES_UPLOAD',

    create_get_StatusBy_users: 'FEE_MANAGEMENT_GET_STATUS_BY_USERS',
    create_get_StatusBy_users_success:
      'FEE_MANAGEMENT_GET_STATUS_BY_USERS_SUCCESS',
    create_get_StatusBy_users_error: 'FEE_MANAGEMENT_GET_STATUS_BY_USERS_ERROR',

    create_FeeEmailRemainder: 'FEE_MANAGEMENT_SENDFEESEMAILREMAINDER_CREATE',
    create_FeeEmailRemainder_success:
      'FEE_MANAGEMENT_SENDFEESEMAILREMAINDER_CREATE_SUCCESS',
    create_FeeEmailRemainder_error:
      'FEE_MANAGEMENT_SENDFEESEMAILREMAINDER_CREATE_ERROR',
  },
};
