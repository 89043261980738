import { ROUTES } from 'constants/routesConstants';
import { theme } from 'themes';
import { Help, Logout, Profile, Repository, Settings, Chat } from 'icons';

const commonItems = [
  { item: 'Settings', link: ROUTES.SETTINGS.ROOT, icon: <Settings /> },
  {
    item: 'Feedback',
    link: ROUTES.FEEDBACK,
    icon: <Chat fill={theme.palette.secondary.main} />,
  },
  { item: 'Help', link: ROUTES.HELP.ROOT, icon: <Help /> },
  { item: 'Logout', link: ROUTES.LOGOUT, icon: <Logout /> },
];

export const moocAdminProfileItems = (t) => [
  // { item: 'My Profile', link: ROUTES.USER_PROFILE, icon: <Profile /> },
  // ...commonItems,
  { item: t('myProfile'), icon: <Profile /> },
  {
    item: t('myRepository.myRepositoryTitle'),
    icon: <Repository />,
  },
  {
    item: t('settings.settingsTitle'),
    link: ROUTES.SETTINGS.ROOT,
    icon: <Settings />,
  },
  {
    item: t('feedback.feedback_heading'),
    link: ROUTES.FEEDBACK,
    icon: <Chat fill={theme.palette.secondary.main} />,
  },
  { item: t('help.helpTitle'), link: ROUTES.HELP.ROOT, icon: <Help /> },
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
  // ...commonItems,
];

export const registrarAndHodItems = (t) => [
  { item: t('myProfile'), link: ROUTES.USER_PROFILE, icon: <Profile /> },
  {
    item: t('myRepository.myRepositoryTitle'),
    link: ROUTES.MY_REPOSITORY.ROOT,
    icon: <Repository />,
  },
  {
    item: t('settings.settingsTitle'),
    link: ROUTES.SETTINGS.ROOT,
    icon: <Settings />,
  },
  {
    item: t('feedback.feedback_heading'),
    link: ROUTES.FEEDBACK,
    icon: <Chat fill={theme.palette.secondary.main} />,
  },
  { item: t('help.helpTitle'), link: ROUTES.HELP.ROOT, icon: <Help /> },
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
  // ...commonItems,
];

export const studentProfileItems = (t) => [
  { item: t('myProfile'), link: ROUTES.USER_PROFILE, icon: <Profile /> },
  {
    item: t('myRepository.myRepositoryTitle'),
    link: ROUTES.MY_REPOSITORY.ROOT,
    icon: <Repository />,
  },
  {
    item: t('settings.settingsTitle'),
    link: ROUTES.SETTINGS.ROOT,
    icon: <Settings />,
  },
  {
    item: t('feedback.feedback_heading'),
    link: ROUTES.FEEDBACK,
    icon: <Chat fill={theme.palette.secondary.main} />,
  },
  { item: t('help.helpTitle'), link: ROUTES.HELP.ROOT, icon: <Help /> },
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
  // ...commonItems,
];

export const leadExecProfileItems = (t) => [
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
];

export const candidateProfileItems = (t) => [
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
];

export const counsellorProfileItems = (t) => [
  {
    item: t('myProfile'),
    link: ROUTES.COUNSELLOR.COUNSELLOR_PROFILE,
    icon: <Profile />,
  },
  {
    item: t('settings.settingsTitle'),
    link: ROUTES.COUNSELLOR.COUNSELLOR_SETTNGS,

    icon: <Settings />,
  },
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
];

export const verifierProfileItems = (t) => [
  {
    item: t('myProfile'),
    link: `${ROUTES.VERIFIER.ROOT}${ROUTES.VERIFIER.VERIFIER_PROFILE}`,
    icon: <Profile />,
  },
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
];

export const parentProfileItems = (t) => [
  {
    item: t('userProfile.studentProfile'),
    link: ROUTES.USER_PROFILE,
    icon: <Profile />,
  },
  {
    item: t('settings.settingsTitle'),
    link: ROUTES.SETTINGS.ROOT,
    icon: <Settings />,
  },
  { item: t('help.helpTitle'), link: ROUTES.HELP.ROOT, icon: <Help /> },
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
];

export const hrProfileItems = (t) => [
  { item: t('myProfile'), link: ROUTES.USER_PROFILE, icon: <Profile /> },
  {
    item: t('myRepository.myRepositoryTitle'),
    link: ROUTES.MY_REPOSITORY.ROOT,
    icon: <Repository />,
  },
  {
    item: t('settings.settingsTitle'),
    link: ROUTES.SETTINGS.ROOT,
    icon: <Settings />,
  },
  { item: t('help.helpTitle'), link: ROUTES.HELP.ROOT, icon: <Help /> },
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
];

export const superAdminProfileItems = (t) => [
  {
    item: t('candidate.myProfile'),
    link: ROUTES.SUPER_ADMIN.SUPER_ADMIN_PROFILE,
    icon: <Profile />,
  },
  {
    item: t('settings.settingsTitle'),
    link: ROUTES.SETTINGS.ROOT,
    icon: <Settings />,
  },
  { item: t('logout'), link: ROUTES.LOGOUT, icon: <Logout /> },
];
