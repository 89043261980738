import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  createLeadApi,
  deleteLeadInfoApi,
  getDashboardStatsApi,
  getLeadConversionApi,
  getLeadFileBlobUrlApi,
  getLeadInformationByIdApi,
  getLeadListApi,
  getLeadPlacementListApi,
  getLeadStageApi,
  getLeadStatusApi,
  getNotesByIdApi,
  getRoleApi,
  leadBulkFileUploadApi,
  leadFileUploadApi,
  leadUploadMultiFileApi,
  patchUpdateLeadStatusApi,
  postBulkLeadInfoApi,
  postNotesByIdApi,
  updateLeadInfoApi,
  updateLeadStageApi,
  updateLeadStatusApi,
} from './Lead.api';
import {
  setLeadLoading,
  getLeadListError,
  getLeadListSuccess,
  postLeadInfoSuccess,
  postLeadInforError,
  postLeadInfoAction,
  updateLeadInfoSuccess,
  updateLeadInforError,
  deleteLeadInfoSuccess,
  deleteLeadInforError,
  postNotesSuccess,
  postNotesError,
  getNotesError,
  getNotesSuccess,
  getLeadStatusSuccess,
  getLeadStatusError,
  getLeadStageSuccess,
  getLeadStageError,
  updateLeadStatusSuccess,
  updateLeadStatusError,
  getDashboardStatsSuccess,
  getDashboardStatsError,
  getLeadPlacementListSuccess,
  getLeadPlacementListError,
  updateLeadStageSuccess,
  updateLeadStageError,
  getLeadInfoByIdSuccess,
  getLeadInfoByIdError,
  postBulkLeadInfoSuccess,
  postBulkLeadInfoError,
  postLeadInfoError,
  getMoreNotesSuccess,
  patchUpdateLeadStatusSuccess,
  leadFileUploadActionSuccess,
  leadConversionSuccess,
  leadBulkUploadFileSuccess,
  leadUploadMultiFileSuccess,
  getRoleSuccess,
  getRoleError,
  // postLeadInfoSuccessFeedback,
} from './Lead.action';

import { LeadActions } from './Lead.constants';
import { statusCode } from 'constants/apis/apiStatus';

import { setToastMessage } from 'store/Toast';
import { updateLeadStageAction } from './Lead.action';
const { LEAD } = LeadActions;

function* getLeadListSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getLeadListApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getLeadListSuccess(response?.data));
    } else {
      yield put(getLeadListError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* postLeadInfoSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(createLeadApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Created201) {
      // to check what api returns and update
      yield put(getLeadInfoByIdSuccess(response?.data));
      yield put(postLeadInfoSuccess(true));
      setToastMessage({
        severity: 'success',
      });
    } else {
      yield put(postLeadInfoError(response?.data));
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    setToastMessage({
      severity: 'error',
    });
  }
}

function* updateLeadInfoSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(updateLeadInfoApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getLeadInfoByIdSuccess(response?.data));
      yield put(postLeadInfoSuccess(true));
    } else {
      yield put(postLeadInfoError(response?.data));
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    console.error(error);
    setToastMessage({
      severity: 'error',
    });
  }
}

function* deleteLeadInfoSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(deleteLeadInfoApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      // to check what api returns and update
      yield put(deleteLeadInfoSuccess(response?.data));
      setToastMessage({
        severity: 'success',
      });
    } else {
      yield put(deleteLeadInforError(response?.data));
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    console.error(error);
    setToastMessage({
      severity: 'error',
    });
  }
}

function* postNotesSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(postNotesByIdApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(postNotesSuccess(true));
      yield put(
        setToastMessage({
          data: 'Note created',
          severity: 'success',
        })
      );
    } else {
      yield put(postNotesSuccess(false));
      yield put(postNotesError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to create note',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(postNotesSuccess(false));
    yield put(
      setToastMessage({
        data: 'Failed to create note',
        severity: 'error',
      })
    );
  }
}

function* getNotesSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getNotesByIdApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getNotesSuccess(response?.data));
    } else {
      yield put(getNotesError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to get notes list',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: 'Failed to get note list',
        severity: 'error',
      })
    );
  }
}

function* getMoreNotesSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getNotesByIdApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getMoreNotesSuccess(response?.data));
    } else {
      yield put(getNotesError(response?.data));
      yield put(
        setToastMessage({
          data:
            response?.data?.error?.message ?? 'Failed to get more notes list',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: 'Failed to get note list',
        severity: 'error',
      })
    );
  }
}

function* getLeadStatusSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getLeadStatusApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      // to check what api returns and update
      yield put(getLeadStatusSuccess(response?.data));
      setToastMessage({
        severity: 'success',
      });
    } else {
      yield put(getLeadStatusError(response?.data));
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    console.error(error);
    setToastMessage({
      severity: 'error',
    });
  }
}

function* getLeadStageSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getLeadStageApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      // to check what api returns and update
      yield put(getLeadStageSuccess(response?.data));
      setToastMessage({
        severity: 'success',
      });
    } else {
      yield put(getLeadStageError(response?.data));
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    console.error(error);
    setToastMessage({
      severity: 'error',
    });
  }
}

function* updateLeadStatusSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(updateLeadStatusApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getLeadInfoByIdSuccess(response?.data));
      if (response?.data?.lead_status == 0) {
        yield put(
          updateLeadStageAction({
            lead: payload?.id,
            stage: '0',
            assignee: '059e0a31-d9ea-47af-9ea6-b7c6f3879888',
            assignee_name: payload?.assignee_name,
            current_status: response?.data?.lead_status,
            tenant_id: payload?.tenantId,
          })
        );
      }
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
    setToastMessage({
      severity: 'error',
    });
  }
}

function* getDashboardStatsSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getDashboardStatsApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      // to check what api returns and update
      yield put(getDashboardStatsSuccess(response?.data));
      setToastMessage({
        severity: 'success',
      });
    } else {
      yield put(getDashboardStatsError(response?.data));
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    console.error(error);
    setToastMessage({
      severity: 'error',
    });
  }
}

function* getDashboardLeadConversionSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getLeadConversionApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      // to check what api returns and update
      yield put(leadConversionSuccess(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLeadPlacementListSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getLeadPlacementListApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getLeadPlacementListSuccess(response?.data));
      setToastMessage({
        severity: 'success',
      });
    } else {
      yield put(getLeadPlacementListError(response?.data));
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    console.error(error);
    setToastMessage({
      severity: 'error',
    });
  }
}

function* updateLeadStageSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(updateLeadStageApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      // to check what api returns and update
      yield put(updateLeadStageSuccess(response?.data));
    } else {
      yield put(updateLeadStageError(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* patchUpdateLeadStatusSaga({
  id,
  payload,
  successMessage = '',
  tenantId,
}) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(patchUpdateLeadStatusApi, id, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(patchUpdateLeadStatusSuccess(true));
      if (response?.data?.lead_status == 0) {
        yield put(
          updateLeadStageAction({
            lead: id,
            stage: '0',
            assignee: '059e0a31-d9ea-47af-9ea6-b7c6f3879888',
            assignee_name: payload?.assignee_name,
            current_status: response?.data?.lead_status,
            tenant_id: tenantId,
          })
        );
      }
      yield put(
        setToastMessage({
          data: successMessage,
          severity: 'success',
        })
      );
    } else {
      yield put(
        setToastMessage({
          data:
            response?.data?.error?.message ?? 'Failed to update lead details',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    setToastMessage({
      data: error?.data?.error?.message ?? 'Failed to update lead details',
      severity: 'error',
    });
  }
}

function* getLeadByIdSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getLeadInformationByIdApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getLeadInfoByIdSuccess(response?.data));
    } else {
      yield put(getLeadInfoByIdError(response?.data));
      setToastMessage({
        data: response?.data?.error?.message ?? 'Failed to get lead details',
        severity: 'error',
      });
    }
  } catch (error) {
    setToastMessage({
      data: error?.data?.error?.message ?? 'Failed to get lead details',
      severity: 'error',
    });
  }
}

function* bulkUploadLeadInfoSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(postBulkLeadInfoApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      // to check what api returns and update
      yield put(postBulkLeadInfoSuccess(response?.data));
      setToastMessage({
        severity: 'success',
      });
    } else {
      yield put(postBulkLeadInfoError(response?.data));
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    console.error(error);
    setToastMessage({
      severity: 'error',
    });
  }
}

function* fileUploadLeadSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(leadFileUploadApi, payload?.data);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(
        leadFileUploadActionSuccess({
          blob_name: response?.data?.blob_name,
          fileType: payload?.data?.get?.('file_type'),
          fileIndex: payload?.fileIndex,
        })
      );
    } else {
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    setToastMessage({
      severity: 'error',
    });
  }
}

function* bulkUploadLeadSaga({ payload }) {
  try {
    const response = yield call(leadBulkFileUploadApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(leadBulkUploadFileSuccess(response?.data));
      yield put(setToastMessage({ severity: 'success' }));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(setToastMessage({ severity: 'error' }));
  }
}

function* multipleFileUploadLeadSaga({ payload }) {
  try {
    const response = yield call(leadUploadMultiFileApi, payload?.data);
    if (response?.status === statusCode.Created201) {
      yield put(
        leadUploadMultiFileSuccess({
          blob_name: response?.data?.blob_name,
          fileType: payload?.data?.get?.('file_type'),
          fileIndex: payload?.fileIndex,
        })
      );
    } else {
      setToastMessage({
        severity: 'error',
      });
    }
  } catch (error) {
    setToastMessage({
      severity: 'error',
    });
  }
}

function* getRoleSaga({ payload }) {
  try {
    yield put(setLeadLoading(true));
    const response = yield call(getRoleApi, payload);
    yield put(setLeadLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getRoleSuccess(response?.data));
    } else {
      yield put(getRoleError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchDummy() {
  yield takeLatest(LEAD.GET_LEAD_LIST, getLeadListSaga);
  yield takeLatest(LEAD.POST_LEAD, postLeadInfoSaga);
  yield takeLatest(LEAD.UPDATE_LEAD, updateLeadInfoSaga);
  yield takeLatest(LEAD.DELETE_LEAD, deleteLeadInfoSaga);
  yield takeLatest(LEAD.POST_NOTES, postNotesSaga);
  yield takeLatest(LEAD.GET_NOTES, getNotesSaga);
  yield takeLatest(LEAD.GET_MORE_NOTES_REQUEST, getMoreNotesSaga);
  yield takeLatest(LEAD.GET_LEAD_STATUS, getLeadStatusSaga);
  yield takeLatest(LEAD.GET_LEAD_STAGE, getLeadStageSaga);
  yield takeLatest(LEAD.UPDATE_LEAD_STATUS, updateLeadStatusSaga);
  yield takeLatest(LEAD.GET_DASHBOARD_STATS, getDashboardStatsSaga);
  yield takeLatest(LEAD.GET_LEAD_PLACEMENT, getLeadPlacementListSaga);
  yield takeLatest(LEAD.UPDATE_LEAD_STAGE, updateLeadStageSaga);
  yield takeLatest(LEAD.GET_LEAD_BY_ID, getLeadByIdSaga);
  yield takeLatest(LEAD.POST_BULK_LEAD, bulkUploadLeadInfoSaga);
  yield takeLatest(LEAD.PATCH_UPDATE_LEAD_STATUS, patchUpdateLeadStatusSaga);
  yield takeLatest(LEAD.LEAD_FILE_UPLOAD, fileUploadLeadSaga);
  yield takeLatest(LEAD.LEAD_BULK_UPLOAD_FILE, bulkUploadLeadSaga);
  yield takeLatest(
    LEAD.GET_DASHBOARD_LEAD_CONVERSION,
    getDashboardLeadConversionSaga
  );
  yield takeLatest(LEAD.LEAD_UPLOAD_MULTI_FILE, multipleFileUploadLeadSaga);
  yield takeLatest(LEAD.GET_ROLE, getRoleSaga);
}

export default function* leadSaga() {
  yield all([watchDummy()]);
}
