export const GENDER = {
  0: 'Male',
  1: 'Female',
};

export const LEAD_STATUS = {
  0: 'Closed',
  1: 'Hot',
  2: 'Warm',
  3: 'Cold',
  4: 'Missed',
};

export const MARTIAL_STATUS = {
  0: 'UnMarried',
  1: 'Married',
  2: 'Divorced',
};

export const DOCUMENT = {
  0: 'Aadhar',
  1: 'PAN',
  2: 'DrivingLicence',
  3: 'VoterID',
  4: 'Passport',
};

export const RELATION = {
  0: 'Father',
  1: 'Mother',
};

export const ADDRESS_TYPE = {
  0: 'Local',
  1: 'Permanent',
  2: 'Both',
};

export const PAYMENT_STATUS = {
  0: 'Pending',
  1: 'Paid',
  2: 'Awaiting',
};
