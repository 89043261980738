import axiosInstance from 'translator';
import { assessmentRepositoryApi } from './AssessmentRepository.apiEndpoints';

export const createQuestionBankApi = async (payload) => {
  return axiosInstance
    .post(`${assessmentRepositoryApi.questionBank}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const createQuestionBankBulkUploadApi = async (payload) => {
  return axiosInstance
    .post(`${assessmentRepositoryApi.questionBankBulkUpload}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const createRubricsApi = async (payload) => {
  return axiosInstance
    .post(`${assessmentRepositoryApi.rubrics}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const listRubricsApi = async () => {
  return axiosInstance
    .get(`${assessmentRepositoryApi.rubrics}`)
    .then((response) => response)
    .catch((error) => error);
};

export const getRubricsByIdApi = async (payload) => {
  return axiosInstance
    .get(`${assessmentRepositoryApi.rubrics}/${payload?.id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteRubricsByIdApi = async (payload) => {
  return axiosInstance
    .delete(`${assessmentRepositoryApi.rubrics}/${payload?.id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const updateRubricsByIdApi = async (payload) => {
  return axiosInstance
    .put(`${assessmentRepositoryApi.rubrics}/${payload?.id}`, payload?.data)
    .then((response) => response)
    .catch((error) => error);
};

export const getQuestionBankListApi = async (payload) => {
  return axiosInstance
    .get(assessmentRepositoryApi.questionBank, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getQuestionBankDetailstApi = async (payload) => {
  const params = { ...payload };
  delete params.id;
  return axiosInstance
    .get(`${assessmentRepositoryApi.questionBank}/${payload.id}`, {
      params: params,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const deleteQuestionBankApi = async (payload) => {
  return axiosInstance
    .delete(`${assessmentRepositoryApi.questionBank}/${payload.id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const evaluateQuestionBankApi = async (payload) => {
  return axiosInstance
    .patch(`${assessmentRepositoryApi.questionBank}/${payload.id}`, {
      question_bank_status: payload?.question_bank_status,
      approval_note: payload?.approval_note,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const editQuestionApi = async (payload) => {
  return axiosInstance
    .put(`${assessmentRepositoryApi.editQuestion}/${payload.id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteQuestionApi = async (payload) => {
  return axiosInstance
    .delete(
      `${assessmentRepositoryApi.editQuestion}/${payload.id}?question_type=${payload.question_type}`
    )
    .then((response) => response)
    .catch((error) => error);
};

export const createAssessmentApi = async (payload) => {
  return axiosInstance
    .post(`${assessmentRepositoryApi.assessment}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getAssessmentListApi = async (payload) => {
  return axiosInstance
    .get(assessmentRepositoryApi.assessment, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const deleteAssessmentApi = async (payload) => {
  return axiosInstance
    .delete(`${assessmentRepositoryApi.assessment}/${payload.id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const getAssessmentDetailstApi = async (payload) => {
  return axiosInstance
    .get(`${assessmentRepositoryApi.assessment}/${payload.id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const editAssessmentSectionApi = async (payload) => {
  return axiosInstance
    .put(`${assessmentRepositoryApi.editSection}/${payload.id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const createAssessmentSectionApi = async (payload) => {
  return axiosInstance
    .post(`${assessmentRepositoryApi.editSection}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteSectionApi = async (payload) => {
  return axiosInstance
    .delete(`${assessmentRepositoryApi.editSection}/${payload.id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const importQuestionApi = async (payload) => {
  return axiosInstance
    .post(`${assessmentRepositoryApi.importQuestion}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const sendForApprovalApi = async (payload) => {
  return axiosInstance
    .patch(`${assessmentRepositoryApi.assessment}/${payload.id}`, {
      assessment_status: payload?.assessment_status,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const updateAssessmentApi = async (payload) => {
  return axiosInstance
    .put(`${assessmentRepositoryApi.assessment}/${payload.id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const evaluateAssessmentApi = async (payload) => {
  return axiosInstance
    .patch(`${assessmentRepositoryApi.assessment}/${payload.id}`, {
      assessment_status: payload?.assessment_status,
      comments: payload?.comments,
    })
    .then((response) => response)
    .catch((error) => error);
};
