import * as React from 'react';

const VmConnectModalCheckIcon = (props) => {
  return (
    <span className="iiht-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={114}
        height={114}
        {...props}
      >
        <g data-name="Group 11977">
          <g data-name="Group 10904" transform="translate(-.055 -.055)">
            <circle
              data-name="Oval 1 Copy 25"
              cx={57}
              cy={57}
              r={57}
              transform="translate(.055 .055)"
              fill="#67ac5b"
            />
            <path
              data-name="Shape Copy 10"
              d="M75.065 41.858L51.052 65.872 39.045 53.865a3.773 3.773 0 10-5.336 5.336l14.674 14.676c.042.043.084.082.124.118a3.773 3.773 0 005.212-.118l26.684-26.682a3.773 3.773 0 10-5.336-5.336z"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default VmConnectModalCheckIcon;
