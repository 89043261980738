import React, { useCallback, useEffect, useState } from 'react';
import {
  CommonModal,
  FormTextField,
  FormAutoComplete,
  LoaderComponent,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid, DragDrop } from 'iiht-b2a-ui-components/build/components';
import FormRadioButtons from '../FormRadioButtons/FormRadioButtons';
import FormDatepicker from '../FormDatepicker/FormDatepicker';
import './AttendanceModal.scss';
import { getUserRole } from 'utils';
import { Role, USER_ROLE_ENUM } from 'data/roles';
import moment from 'moment';
import { validationRules } from 'constants/index';
import Dropzone from '../DropZone/Dropzone';
import { getLiveSessionListByFilterRequest } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { yyyy_mm_dd } from 'constants/DateConstants';
import { getLiveSessionListByFilterReset } from 'store';

const AttendanceOverrideModal = ({
  visible,
  modalCloseHandler = () => {},
  userType = '',
  data = {
    start: '',
    end: '',
    title: '',
  },
  readOnly = false,
  onFormSubmit = () => {},
}) => {
  const fileViewerSupportedType = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
  };
  const fileSupportMessage = `Support .png, .jpg, .jpeg and .pdf formats`;

  const { t } = useTranslation();
  const role = getUserRole();
  const dispatch = useDispatch();

  const presentyTypes = [
    { key: 1, value: 'absent', label: t('attendanceManagement.absent') },
    { key: 2, value: 'present', label: t('attendanceManagement.present') },
  ];
  const [files, setFiles] = useState('');
  const [startDateLiveSessionList, setStartDateLiveSessionList] = useState([]);

  const { data: liveSessionDataList, loading: liveSessionDataListLoading } =
    useSelector((state) => state.attendanceState.liveSessionList) || '';

  const { loading: createAttendanceLoading } =
    useSelector((state) => state.attendanceState.user_attendance) || '';

  const { loading: fileUploadLoading } =
    useSelector((state) => state.leadState.lead) || '';

  const { idp_user_uuid: user_id } =
    useSelector((state) => state.userManagementState.user.data) || '';

  const overrideData = {
    start_date: moment(data.start).format('MM/DD/yyyy'),
    start_time: moment(data?.start).format('hh:mm A'),
    end_time: moment(data?.end).format('hh:mm A'),
    end_date: data?.end ? moment(data.end).format('MM/DD/yyyy') : '',
    absent_reason: '',
    absent_note: '',
    presentyType:
      data?.title?.toLowerCase() === 'Present'.toLowerCase()
        ? presentyTypes[0].value
        : presentyTypes[1].value,
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      start_date: data?.start ? moment(data.start).format('MM/DD/yyyy') : '',
      end_date: data?.end ? moment(data.end).format('MM/DD/yyyy') : '',
      liveSessions: [],
      absent_reason: '',
      absent_note: '',
      start_time: data?.start ? moment(data?.start).format('hh:mm A') : '',
      end_time: data?.end ? moment(data?.start).format('hh:mm A') : '',
      presentyType:
        data?.title?.toLowerCase() === 'Present'.toLowerCase()
          ? presentyTypes[1].value
          : presentyTypes[0].value,
    },
  });

  const { handleSubmit, watch, setValue } = methods;

  const onSubmit = (data) => {
    onFormSubmit(data, files);
  };

  const addFileToData = (file, action) => {
    if (file && action === 'remove') {
      setFiles(file);
    } else {
      setFiles([...files, ...file]);
    }
  };

  const deleteAttachment = (record) => {
    // remove the deleted attachment.
  };

  useEffect(() => {
    return () => {
      dispatch(getLiveSessionListByFilterReset());
      setFiles([]);
    };
  }, []);

  const startDate = watch('start_date');

  useEffect(() => {
    if (startDate) {
      dispatch(getLiveSessionListByFilterReset());
      getLiveSessionList('', 0, 5, startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (liveSessionDataList.results?.length > 0) {
      const liveSessisonList = liveSessionDataList?.results?.map(
        (live, index) => {
          const startTime = moment(new Date(live.start_time)).format('LT');
          const endTime = moment(new Date(live.end_time)).format('LT');
          const title = live?.content_title?.concat(
            ' (',
            startTime,
            ' - ',
            endTime,
            ')'
          );
          return {
            label: title,
            key: index,
            value: live?.id,
            conduct_by_id: live?.conduct_by_id || '',
            session_title: live?.session_title || '',
            session_date: live?.content_date || '',
            start_time: live?.start_time || '',
            end_time: live?.end_time || '',
            ...live,
          };
        }
      );
      setStartDateLiveSessionList(liveSessisonList);
    } else {
      setStartDateLiveSessionList([]);
    }
  }, [liveSessionDataList]);

  const getLiveSessionList = useCallback(
    (SearchStringLocal = '', SkipCountLocal = 0, MaxResultCountLocal = 5) => {
      if (!liveSessionDataListLoading) {
        const payload = {
          data: {
            session_date: moment(startDate).format(yyyy_mm_dd),
            user_info: user_id,
            user_type: USER_ROLE_ENUM[role?.toLowerCase()],
            offset: SkipCountLocal || 0,
            limit: MaxResultCountLocal || 5,
          },
        };
        dispatch(getLiveSessionListByFilterRequest(payload));
      }
    }
  );

  return (
    <>
      <FormProvider {...methods}>
        <CommonModal
          open={visible}
          onClose={modalCloseHandler}
          modalTitle={
            role === Role.student
              ? t('attendanceManagement.markAttendance')
              : t('attendanceManagement.override')
          }
          showDividers
          cancelBtnName={t('cancel')}
          submitBtnName={t('submit')}
          fullWidth
          onSubmit={handleSubmit(onSubmit)}
        >
          {(createAttendanceLoading || fileUploadLoading) && (
            <LoaderComponent
              visible={createAttendanceLoading || fileUploadLoading}
            />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} className="override-modal">
              <>
                <Grid item lg={6} md={6}>
                  <FormDatepicker
                    name={'start_date'}
                    value={overrideData?.start_date}
                    label={t('attendanceManagement.startDate')}
                    fullWidth
                    className={readOnly ? 'disabled' : ''}
                    rules={{
                      required: validationRules.REQUIRED,
                    }}
                    isAsterisk
                    size={'small'}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                  <FormAutoComplete
                    name="liveSessions"
                    variant="outlined"
                    rules={{
                      required: validationRules.REQUIRED,
                    }}
                    label={t('attendanceManagement.selectLiveSessions')}
                    size="small"
                    fullWidth
                    options={startDateLiveSessionList}
                    isAsterisk
                    multiple
                    totalCount={liveSessionDataList?.count || 0}
                    handleFetchNext={getLiveSessionList}
                    freeSolo={false}
                    isOptionEqualToValue={(option, newValue) =>
                      option.key === newValue.key
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormRadioButtons
                    options={presentyTypes}
                    name={'presentyType'}
                    defaultValue={overrideData?.presentyType}
                  />
                </Grid>
              </>
              <Grid item lg={12} md={12}>
                <FormTextField
                  name={'absent_reason'}
                  label={t('attendanceManagement.reason')}
                  size="small"
                  fullWidth
                  multiline
                  rows={2}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                  isAsterisk
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <FormTextField
                  name={'absent_note'}
                  label={t('attendanceManagement.note')}
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  rules={{
                    required: validationRules.REQUIRED,
                  }}
                  isAsterisk
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <Dropzone
                  addFile={addFileToData}
                  deleteAttachment={deleteAttachment}
                  supportedFiles={fileViewerSupportedType}
                  isDocumentViewerAvailable={false}
                  invalidFileMessage={fileSupportMessage}
                  maxFileSize={3}
                  singleFileSupport
                />
              </Grid>
            </Grid>
          </form>
        </CommonModal>
      </FormProvider>
    </>
  );
};

export default AttendanceOverrideModal;
