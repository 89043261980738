import axiosInstance from 'translator';
import { ExamsAPI } from 'store/Exams';

export const getUserMappingDataApi = async (payload) => {
  return axiosInstance
    .get(`${ExamsAPI?.getUserMapping}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExamListApi = async (payload) => {
  return axiosInstance
    .get(`${ExamsAPI?.exam}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExamsDashboardListApi = async (payload) => {
  return axiosInstance
    .get(`${ExamsAPI?.examDashboard}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createExamsAPI = async (payload) => {
  return axiosInstance
    .post(`${ExamsAPI.exam}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const editExamsAPI = async (payload) => {
  return axiosInstance
    .put(`${ExamsAPI.exam}/${payload?.id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteExamsAPI = async (payload) => {
  return axiosInstance
    .delete(`${ExamsAPI.exam}/${payload}`)
    .then((response) => response)
    .catch((error) => error);
};

export const getExamByIdApi = async (payload) => {
  return axiosInstance
    .get(`${ExamsAPI.exam}/${payload?.id}`)
    .then((response) => response)
    .catch((error) => error);
};

export const createExamContentApi = async (payload) => {
  return axiosInstance
    .post(`${ExamsAPI.exam_content}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const publishExamContentApi = async (payload) => {
  return axiosInstance
    .patch(`${ExamsAPI.publish_exam}?exam_subject_id=${payload?.examSubjectId}`)
    .then((response) => response)
    .catch((error) => error);
};

export const getExamContentApi = async (payload) => {
  return axiosInstance
    .get(`${ExamsAPI.exam_content}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const editExamContentApi = async (payload) => {
  return axiosInstance
    .put(`${ExamsAPI.exam_content}/${payload?.id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getProgramYearSemMappingDataApi = async (payload) => {
  return axiosInstance
    .get(`${ExamsAPI?.getProgramYearSemMapping}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
