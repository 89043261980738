import React from 'react';
import {
  Backdrop,
  CircularProgress,
  Typography,
} from 'iiht-b2a-ui-components/build/components';

const LoaderComponent = ({ visible = false, loaderText }) => {
  return (
    <Backdrop
      sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={visible}
      className="display-flex flex-direction-column"
    >
      <CircularProgress color="inherit" />
      <Typography>{loaderText}</Typography>
    </Backdrop>
  );
};

export default LoaderComponent;
