import React, { useState, useEffect } from 'react';
import { useTheme } from 'iiht-b2a-ui-components/build/components/Styles';
import { Box } from 'iiht-b2a-ui-components/build/components';
import { parentProfileItems } from './profileItems';
import { parentHeaderOptions } from './headerTabOptions';
import ProfileTab from './ProfileTab';
import HeaderTabs from './HeaderTabs';
import NotificationTab from './NotificationTab';
import { changeStudentRequest } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { parentItems } from './headerMenus';
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from 'iiht-b2a-ui-components/build/components';
import { ExpandArrow } from 'icons';
import './headerStyle.scss';
import { localStorageHelper } from 'utils';
import { CloseIcon } from 'icons';

const ParentHeader = ({
  userDetails,
  role,
  notificationList,
  t,
  responsiveNav,
  setResponsiveNav,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: parentData } = useSelector(
    (state) => state.userManagementState.parent_user
  );
  const [profileItems, setProfileItems] = useState([]);
  const [headerTabs, setHeaderTabs] = useState([]);
  const [manageItems, setManageItems] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();
  const [anchorStudentItems, setAnchorStudentItems] = useState(null);
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const handleOpenStudentMenu = (event) => {
    setAnchorStudentItems(event.currentTarget);
  };

  const handleCloseStudentMenu = () => {
    setAnchorStudentItems(null);
  };

  const selectStudent = (val) => {
    setSelectedStudent(val);
    setAnchorStudentItems(false);
    // dispatch a new action to change the reducer pointing to new student object
    dispatch(changeStudentRequest(val));
  };

  const getHeaderTabs = () => {
    return parentHeaderOptions(t);
  };

  useEffect(() => {
    const tabOptions = getHeaderTabs(selectedLanguage);
    setHeaderTabs(tabOptions);
  }, [t]);

  useEffect(() => {
    if (role) {
      const taboptions = getHeaderTabs();
      setHeaderTabs(taboptions);
    }
    setManageItems(parentItems(t));
    setProfileItems(parentProfileItems(t));
  }, [role, userDetails, selectedLanguage, t]);

  useEffect(() => {
    if (parentData?.students?.length > 0) {
      console.log('parentData?.students?.[0]:', parentData?.students?.[0]);
      setStudentList(parentData?.students);
      setSelectedStudent(parentData?.students?.[0]);
      localStorageHelper.set('studentId', parentData?.students?.[0].id);
    }
  }, [parentData]);

  return (
    <>
      <Box className={responsiveNav ? 'navigations open' : 'navigations'}>
        {/* TO DO - uncomment it later when the approach is ready */}
        {studentList?.length > 1 ? (
          <>
            <Box sx={{ mt: '8px' }}>
              <Button
                color="secondary"
                variant="text"
                id="basic-button"
                onClick={handleOpenStudentMenu}
              >
                {selectedStudent?.firstName + ' ' + selectedStudent?.lastName}
                <span className="display-flex">
                  <ExpandArrow />
                </span>
              </Button>
            </Box>
            <Menu
              id="cluster-menu"
              anchorEl={anchorStudentItems}
              open={Boolean(anchorStudentItems)}
              onClose={handleCloseStudentMenu}
            >
              {studentList.map((item) => (
                <MenuItem
                  disableRipple
                  key={item.id}
                  onClick={() => {
                    selectStudent(item);
                    localStorageHelper.set('studentId', item.id);
                  }}
                  selected={item.id === selectedStudent.id}
                >
                  <div>{item?.firstName + ' ' + item?.lastName}</div>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <>
            {' '}
            <Typography className="font-16" sx={{ mt: '8px' }}>
              {selectedStudent?.firstName + ' ' + selectedStudent?.lastName}
            </Typography>
          </>
        )}
        <HeaderTabs
          headerTabs={headerTabs}
          manageItems={manageItems}
          theme={theme}
        />
        <div className="header-icons">
          <IconButton
            size="small"
            aria-label="main navigation"
            aria-controls="close-appbar"
            aria-haspopup="false"
            color="inherit"
            className="closeNav"
            onClick={() => {
              setResponsiveNav(false);
            }}
          >
            <CloseIcon />
          </IconButton>
          <NotificationTab notificationList={notificationList} t={t} />
        </div>
      </Box>
      <ProfileTab
        userDetails={parentData}
        theme={theme}
        profileItems={profileItems}
      />
    </>
  );
};

export default ParentHeader;
