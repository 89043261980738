import * as React from 'react';

const CampaignIconSvg = () => {
  return (
    <svg id="megaphone" xmlns="http://www.w3.org/2000/svg" width="20.548" height="18.108" viewBox="0 0 20.548 18.108">
    <g id="Group_11186" data-name="Group 11186">
      <path id="Path_43384" data-name="Path 43384" d="M16.7,4.926a1.926,1.926,0,1,1,3.853,0V19.053a1.926,1.926,0,0,1-3.853,0v-.275A21.836,21.836,0,0,0,7.819,16.1l.52,3.483a1.284,1.284,0,0,1-1.262,1.522h-.7A1.284,1.284,0,0,1,5.2,20.339L2.82,15.857l-.259,0A2.587,2.587,0,0,1,0,13.274V10.706A2.594,2.594,0,0,1,2.558,8.12q1.6-.022,3.189-.1A25.351,25.351,0,0,0,16.7,5.2Zm1.284,0V19.053a.642.642,0,1,0,1.284,0V4.926a.642.642,0,1,0-1.284,0ZM16.7,6.66A26.945,26.945,0,0,1,6.421,9.271v5.436q.347.019.69.046a24.014,24.014,0,0,1,9.584,2.56ZM5.137,14.642v-5.3q-1.279.047-2.558.067a1.31,1.31,0,0,0-1.295,1.3v2.569a1.3,1.3,0,0,0,1.292,1.3q1.281.015,2.561.072Zm-.844,1.252,2.066,3.9.013.031h.7l0-.018L6.5,16q-1.105-.067-2.211-.105Z" transform="translate(0 -3)" fill="#00224b"/>
    </g>
  </svg>
  );
};

export default CampaignIconSvg;
