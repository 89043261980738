import React from 'react';

const AssignmentInd = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
    >
      <path d="M200 936q-33 0-56.5-23.5T120 856V296q0-33 23.5-56.5T200 216h168q14-36 44-58t68-22q38 0 68 22t44 58h168q33 0 56.5 23.5T840 296v560q0 33-23.5 56.5T760 936H200Zm280-670q13 0 21.5-8.5T510 236q0-13-8.5-21.5T480 206q-13 0-21.5 8.5T450 236q0 13 8.5 21.5T480 266ZM200 810q54-53 125.5-83.5T480 696q83 0 154.5 30.5T760 810V296H200v514Zm280-194q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41ZM280 856h400v-10q-42-35-93-52.5T480 776q-56 0-107 17.5T280 846v10Zm200-320q-25 0-42.5-17.5T420 476q0-25 17.5-42.5T480 416q25 0 42.5 17.5T540 476q0 25-17.5 42.5T480 536Zm0 17Z" />
    </svg>
  );
};

export default AssignmentInd;
