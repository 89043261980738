import React, { useEffect, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Typography,
  Button,
  LinearProgress,
} from 'iiht-b2a-ui-components/build/components';
import './UseCollapsibleMenus.scss';
import { AddIconCircle } from 'icons';
import { EllipsisOptionComponent } from '../EllipsisOption';

export const useCollapsibleMenus = (
  menuTitle = 'menu list',
  listItems = [],
  subItemsList = [],
  subListLoading,
  showEllipsis,
  options
) => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedParent, setSelectedParent] = useState();
  const [selectedChild, setSelectedChild] = useState();
  const [id, setId] = useState();
  useEffect(() => {
    const findParent = listItems?.find(
      (item) => selectedParent?.id === item?.id
    );
    if (!selectedParent || !findParent) {
      setId('parent' + listItems?.[0]?.id);
      setSelectedComponent(listItems?.[0]?.component);
      setSelectedParent(listItems?.[0]);
    }
  }, [listItems]);

  useEffect(() => {
    if (selectedChild && subItemsList?.length) {
      const activeChild = subItemsList?.find((o) => o.id == selectedChild?.id);
      if (activeChild) setSelectedComponent(activeChild?.component);
      else setSelectedComponent(selectedParent?.component);
    }
  }, [subItemsList]);

  const setParentIdDetails = (parentId, item) => {
    setSelectedParent(item);
    setId((prevStateId) => (prevStateId === parentId ? '' : parentId));
    setSelectedChild(null);
    setSelectedComponent(item?.component);
  };

  const setChildIdDetails = (parentItem, item) => {
    if (selectedParent?.id !== parentItem?.id) {
      setSelectedParent(parentItem);
      setId(`parent${parentItem?.id}`);
    }
    setSelectedComponent(item?.component);
    setSelectedChild(item);
  };

  const onAddClick = (parentItem) => {
    if (selectedParent?.id !== parentItem?.id) {
      setSelectedParent(parentItem);
      setId(`parent${parentItem?.id}`);
    }
    setSelectedChild(null);
    setSelectedComponent(parentItem?.component);
  };

  const ListMenusDom = (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={<Typography variant="h6">{menuTitle}</Typography>}
      key="list#1"
    >
      {listItems.map((item, index) => {
        return (
          <>
            <div
              key={`parent${item?.id}`}
              onClick={() => setParentIdDetails(`parent${item?.id}`, item)}
              //divider
              className={
                item.id === selectedParent?.id
                  ? 'selectedParent display-flex m2 p2'
                  : ' display-flex m2 p2'
              }
            >
              <ListItemText
                className="mouse-pointer"
                primary={<Typography variant="h7">{item?.title}</Typography>}
              />
              {!!!showEllipsis ? (
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                    onAddClick(item);
                  }}
                  className="mouse-pointer "
                >
                  <AddIconCircle />
                </span>
              ) : (
                <span onClick={(event) => event.stopPropagation()}>
                  <EllipsisOptionComponent options={options} record={item} />
                </span>
              )}
              {subItemsList?.length > 0 ? (
                id === `parent${item?.id}` ? (
                  <ExpandLess fill={'#68747B'} className="mouse-pointer" />
                ) : (
                  <ExpandMore fill={'#68747B'} className="mouse-pointer" />
                )
              ) : null}
            </div>
            {
              (subItemsList?.length > 0 && id === `parent${item?.id}` && (
                <Collapse
                  in
                  timeout="auto"
                  unmountOnExit
                  key={`collapse${item?.id}`}
                >
                  <List
                    component="div"
                    disablePadding
                    key={`list#2${item?.id}`}
                  >
                    {subItemsList?.map?.((childItem, childIndex) => {
                      return (
                        <>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            divider
                            onClick={() => setChildIdDetails(item, childItem)}
                            key={`list#2${item?.id}${childIndex}`}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  color={
                                    selectedChild?.id == childItem?.id
                                      ? 'primary.main'
                                      : 'secondary.main'
                                  }
                                  variant="bodyText"
                                  className="twolineellipsis"
                                >
                                  {childItem?.title}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </>
                      );
                    })}
                    {/* <ListItemButton
                    divider
                    key="asb"
                    onClick={() => {
                      onAddClick(item);
                    }}
                  >
                    <Button
                      size="small"
                      className="display-block margin-x-auto"
                      variant="outlined"
                      color="secondary"
                    >
                      Add a Module
                    </Button>
                  </ListItemButton> */}
                  </List>
                </Collapse>
              )) ||
                (subListLoading && item.id === selectedParent?.id ? (
                  <LinearProgress />
                ) : null)
              // <Collapse
              //   in
              //   timeout="auto"
              //   unmountOnExit
              //   key={`collapse${item?.id}`}
              //   className="p2"
              // >
              //   <Button
              //     size="small"
              //     className=" display-block margin-x-auto"
              //     variant="outlined"
              //     color="secondary"
              //     onClick={() => {
              //       onAddClick(item);
              //     }}
              //   >
              //     Add a Module
              //   </Button>
              // </Collapse>
            }
          </>
        );
      })}
    </List>
  );

  return {
    ListMenusDom,
    selectedComponent,
    selectedParent,
    setChildIdDetails,
  };
};
