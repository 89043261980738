import axiosInstance from 'translator';
import { GamificationAPI } from './Gamification.apiEndPoints';
export const getStudentBadegListApi = async (payload) => {
  return axiosInstance
    .get(`${GamificationAPI.getStudentBadgeList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getBadgeApi = async (payload) => {
  return axiosInstance
    .get(`${GamificationAPI?.badge}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getBadgeByIdApi = async (payload) => {
  return axiosInstance
    .get(`${GamificationAPI?.badge}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createBadgeApi = async (payload) => {
  return axiosInstance
    .post(`${GamificationAPI?.badge}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteBadgeApi = async (payload) => {
  return axiosInstance
    .delete(`${GamificationAPI.badge}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getBadgeIconsListApi = async (payload) => {
  return axiosInstance
    .get(`${GamificationAPI.badgeIcons}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateBadgeApi = async (payload) => {
  const id = payload?.id;
  return axiosInstance
    .put(`${GamificationAPI.badge}/${id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const assignBadgeApi = async (payload) => {
  return axiosInstance
    .post(`${GamificationAPI.assignbadge}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentBadgeDetailApi = async (payload) => {
  return axiosInstance
    .get(`${GamificationAPI.getStudentBadgeList}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentProgramApi = async (payload) => {
  return axiosInstance
    .get(`${GamificationAPI.studentProgram}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
