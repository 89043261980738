export const scheduleApi = {
  getCourseSchedule: '/api/AssessmentService/Session/GetCourseScheduleDetails',
  getModuleSchedule: '/api/AssessmentService/Session/GetCourseModuleDetails',
  getTimeTableForUser: '/v2/time-table',
  getScheduleApprovals:
    '/api/scheduleService/TimeTable/GetFacultyModuleDetails',
  postTimeTable: '/api/scheduleService/TimeTable/UploadTimeTable',
  getFacultySchedule: '/api/scheduleService/TimeTable/GetFacultyTimeTable',
  putScheduleStatus: '/api/scheduleService/TimeTable/UpdateTimeTableRequest',
  getPastConnections: '/api/scheduleService/Student/GetList',
  deletePastConnection: '/api/scheduleService/Student/Delete',
  createPastConnection: '/api/scheduleService/Student/Create',
  getDeptUsers: '/v2/user-mapping',
  getStaffOfDept: '/v2/staff-department',
  getUserMappingByUserId: '/v2/user-mapping/get_user',
};
