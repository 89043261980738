import axiosInstance from 'translator';
import { studentAPI } from 'store/Student';
import { async } from 'q';

export const getAssessmentListApi = async () => {
  return axiosInstance
    .get(`${studentAPI?.getAssessmentList}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAssignmentListApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getAssignmentList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAssessmentByIdApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.createAssessment}/${payload?.assignment_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllUserDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getAllUserDetails}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAssignmentByIdApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getAssignmentById}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getByQuizIdApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.createQuiz}/${payload?.quiz_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getModulesApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getCourseModule}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubModulesApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getSubModule}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCourseByStudentIdApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getCourseByStudentId}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAssignmentBySubModuleApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.fetchAssignmentBySubModuleId}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAssessmentBySubModuleApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.fetchAssessmentBySubModuleId}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getNotesApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getNotesAndBookmarks}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createNotesApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.createNotesAndBookmarks}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateNotesAndBookmarksApi = async (payload) => {
  return axiosInstance
    .put(`${studentAPI?.updateNotesAndBookmarks}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteNotesAndBookmarkApi = async (payload) => {
  return axiosInstance
    .delete(`${studentAPI?.deleteNotesAndBookmarks}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLearningDetailsByCourseId = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getDetailsByCourseId}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const postAssessmentApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.createAssessment}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postQuizApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.createQuiz}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const putAssessmentApi = async (payload) => {
  return axiosInstance
    .put(`${studentAPI?.createAssessment}/${payload?.assignment_id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const putQuizApi = async (payload) => {
  return axiosInstance
    .put(`${studentAPI?.createQuiz}/${payload?.quiz_id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteAssessmentApi = async (payload) => {
  return axiosInstance
    .delete(`${studentAPI?.createAssessment}/${payload?.assignment_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteQuizApi = async (payload) => {
  return axiosInstance
    .delete(`${studentAPI?.createQuiz}/${payload?.quiz_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getNotesBySubModuleIdApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getNotesBySubModuleId}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getNotesByModuleIdApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getMyNotesByModuleId}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const submitAssessmentApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.submitAssessment}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPendingAssignmentByApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getPendingAssigments}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPendingAssessmentByApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getPendingAssessments}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentIdActivitiesApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.fetchAssessmentByStudentId}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createStudentProgramDetailsApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI.studentSemester}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getStudentProgramDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI.studentSemester}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const updateStudentProgramDetailsApi = async (payload) => {
  return axiosInstance
    .put(`${studentAPI.studentSemesterBulkUpdate}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteStudentProgramDetailsApi = async (payload) => {
  return axiosInstance
    .delete(`${studentAPI.studentSemesterBulkDelete}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getNotesByContentIdApi = async (payload) => {
  return axiosInstance
    .get(`${studentAPI?.getNotesByContentId}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createBoomarksApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.createBookmarks}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const submitLearningQuizApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.learningQuiz}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLearningQuizApi = async (payload, params) => {
  if (params) {
    return axiosInstance
      .get(`${studentAPI?.learningQuiz}/${params}`, { params: payload })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
};

export const submitAssigmentsApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.assignmentsApiUrl}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLearningAssignmentApi = async (payload, params) => {
  if (params) {
    return axiosInstance
      .get(`${studentAPI?.assignmentsApiUrl}/${params}`, { params: payload })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
};

export const postVideoProgressApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.videoProgress}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postReadingProgressApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.readingProgress}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getVideoProgressApi = async (payload, params) => {
  return axiosInstance
    .get(`${studentAPI?.videoProgress}/${payload?.video_id}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getReadingProgressApi = async (payload, params) => {
  return axiosInstance
    .get(`${studentAPI?.readingProgress}/${payload?.reading_id}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubjectProgramDetailsApi = async (params) => {
  return axiosInstance
    .get(`${studentAPI.subjectProgramDetails}`, {
      params: params,
    })
    .then((response) => response)
    .catch((error) => error);
};
export const getStudentConnectApi = async (params) => {
  return axiosInstance
    .get(`${studentAPI.StudentList}`, {
      params: params,
    })
    .then((response) => response)
    .catch((error) => error);
};
export const getStudentNotificationApi = async (params) => {
  return axiosInstance
    .get(`${studentAPI.getStudentNotifiation}`, {
      params: params,
    })
    .then((response) => response)
    .catch((error) => error);
};
export const createStudentNotificationApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.getStudentNotifiation}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const deleteStudentNotificationApi = async (payload) => {
  return axiosInstance
    .delete(`${studentAPI?.getStudentNotifiation}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const deleteBulkStudentNotificationApi = async (payload) => {
  return axiosInstance
    .post(`${studentAPI?.bulkDelete}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
