import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  prospectusActions,
  patchApplicationFormAction,
  patchApplicationFormSuccess,
  patchApplicationFormError,
  getProspectusFormsAction,
  getProspectusFormsSuccess,
  getProspectusFormsError,
  postNewApplicationFormAction,
  postNewApplicationFormSuccess,
  postNewApplicationFormError,
  patchApplicationFormApi,
  getProspectusFormsListApi,
  postNewApplicationFormApi,
  patchCourseFeesSuccess,
  patchCourseFeesError,
  getProspectusFormApi,
  getSingleProspectusFormSuccess,
  getSingleProspectusFormError,
} from 'store/Prospectus';
import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';

function* postNewApplicationFormSaga({ payload }) {
  try {
    const response = yield call(postNewApplicationFormApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(postNewApplicationFormSuccess(response?.data));
    } else {
      yield put(postNewApplicationFormError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message
            ? response.data.message
            : 'Course Prospectus could not be created.',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: 'Course Prospectus could not be created.',
        severity: 'error',
      })
    );
  }
}

function* patchApplicationFormSaga({ payload }) {
  try {
    const response = yield call(patchApplicationFormApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(patchApplicationFormSuccess(response?.data));
    } else {
      yield put(patchApplicationFormError(response?.data));
    }
  } catch (error) {}
}

function* patchCourseFeesSaga({ payload }) {
  try {
    const response = yield call(patchApplicationFormApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(patchCourseFeesSuccess(response?.data));
      if (response?.data?.course_prospectus_status === 0) {
        yield put(
          setToastMessage({
            data: 'Course Prospectus form published.',
            severity: 'success',
          })
        );
      }
    } else {
      yield put(patchCourseFeesError(response?.data));
      yield put(
        setToastMessage({
          data: 'Prospectus form could not be published.',
          severity: 'error',
        })
      );
    }
  } catch (error) {}
}

function* getProspectusFormsListSaga({ payload }) {
  try {
    const response = yield call(getProspectusFormsListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getProspectusFormsSuccess(response?.data));
    } else {
      yield put(getProspectusFormsError(response?.data));
    }
  } catch (error) {}
}

function* getProspectusFormSaga({ payload }) {
  try {
    const response = yield call(getProspectusFormApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSingleProspectusFormSuccess(response?.data));
    } else {
      yield put(getSingleProspectusFormError(response?.data));
    }
  } catch (error) {}
}

export function* watchDummy() {
  yield takeLatest(
    prospectusActions.APPLICATION_FORM.patch,
    patchApplicationFormSaga
  );
  yield takeLatest(
    prospectusActions.APPLICATION_FORM.patch_fees,
    patchCourseFeesSaga
  );
  yield takeLatest(
    prospectusActions.PROSPECTUS_FORM_LIST.get,
    getProspectusFormsListSaga
  );
  yield takeLatest(
    prospectusActions.APPLICATION_FORM.post,
    postNewApplicationFormSaga
  );
  yield takeLatest(
    prospectusActions.PROSPECTUS_FORM_LIST.get_form,
    getProspectusFormSaga
  );
}

export default function* studentSaga() {
  yield all([watchDummy()]);
}
