import * as React from 'react';

const Archive = ({ height = 24, width = 24, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
      {...rest}
    >
      <path d="M120-160v-452q-17-11-28.5-28T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11.5 40T840-612v452q0 33-23.5 56.5T760-80H200q-33 0-56.5-23.5T120-160Zm80-440v440h560v-440H200Zm600-80v-120H160v120h640ZM360-400h240v-80H360v80ZM200-160v-440 440Z" />
    </svg>
  );
};

export default Archive;
