import i18n from 'i18n';
export const validationRules = {
  FIRST_NAME: {
    value: /^[A-Za-z]+$/,
    message: i18n.t('invalidFirstName'),
  },
  MIDDLE_NAME: {
    value: /^[A-Za-z]+$/,
    message: i18n.t('invalidMiddleName'),
  },
  LAST_NAME: {
    value: /^[A-Za-z]+$/,
    message: i18n.t('invalidLastName'),
  },
  EMAIL: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: i18n.t('invalidEmail'),
  },
  URL: {
    value:
      /^(http|https):\/\/[(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,16}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
    message: i18n.t('invalidURL'),
  },
  NUMBER: {
    value: /^[0-9]+$/,
    message: i18n.t('invalidNUMBER'),
  },
  ALPHABETS_ONLY: {
    value: /^[A-Za-z ]+$/,
    message: i18n.t('should.alphabets.only'),
  },
  ORGANIZATION_NAME: {
    value: /^[A-Za-z0-9 ]+$/,
    message: i18n.t('invalid.organization.name'),
  },
  INSTITUTE_NAME: {
    value: /^[ a-zA-Z& ]+\.?[ a-zA-Z& ]*$/,
    message: i18n.t('invalidInstitute'),
  },
  UNIVERSITY_NAME: {
    value: /^[ a-zA-Z ]+\.?[ a-zA-Z ]*$/,
    message: i18n.t('invalidUniversity'),
  },
  DEGREE: {
    value: /^[ a-zA-Z ]+\.?[ a-zA-Z ]*$|^[ a-zA-Z ]+-?[ a-zA-Z ]*$/,
    message: i18n.t('invalidDegree'),
  },
  PIN_CODE: {
    value: /^[1-9][0-9]{5}$/,
    message: i18n.t('wrongFormat'),
  },
  DURATION: {
    value:
      /^(1[0-2]|[0][0-9]):([3][0,1]|[1,2][0-9]|[0][0-9]):(1[0-9]|[2][0-4]|[0][0-9])$/,
    message: i18n.t('wrongFormat'),
  },
  PHONE_NUMBER: {
    value: /^\d{10}$/,
    message: i18n.t('invalidContact'),
  },
  REQUIRED: {
    value: true,
    // message: i18n.t('required'),
    message: 'This field is required',
  },
  ALPHA_NUMERIC: {
    value: /^[a-zA-Z0-9]+$/,
    message: i18n.t('wrongFormat'),
  },
  PERCENTAGE: {
    value: /^(100|[1-9]?[0-9])$/,
    message: i18n.t('invalidPercentage'),
  },
  CHARACTER_NOT_ALLOWED: {
    value: /^[A-Za-z]+$/,
    message: i18n.t('invalidCharacterNotAllowed'),
  },
  CHAPTER_NAME_ALLOWED: {
    value: /^[a-zA-Z0-9._\- ]+$/,
    message: i18n.t('invalidChapterNameNotAllowed'),
  },
};
