import axiosInstance from 'translator';
import { SettingsAPI } from 'store/Settings';

export const createSettingApi = async (payload) => {
  return axiosInstance
    .post(`${SettingsAPI?.create}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSettingsApi = async () => {
  return axiosInstance
    .get(`${SettingsAPI?.getList}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSettingApi = async () => {
  return axiosInstance
    .get(`${SettingsAPI?.get}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const updateSettingApi = async (payload) => {
  return axiosInstance
    .put(`${SettingsAPI?.update}?id=${payload?.id}`, payload.payload, {
      accept: ' text/plain',
      'Content-Type': ' application/json',
      'X-Requested-With': ' XMLHttpRequest',
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteSettingApi = async (payload) => {
  return axiosInstance
    .delete(`${SettingsAPI?.delete}`, {
      params: { id: payload },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPrivacySettingsApi = async () => {
  return axiosInstance
    .get(`${SettingsAPI?.getPrivacyList}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllPrivacySettingsApi = async (payload) => {
  return axiosInstance
    .get(`${SettingsAPI?.getAllPrivacyList}`, {
      params: { id: payload },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePrivacySettingApi = async (payload) => {
  return axiosInstance
    .put(`${SettingsAPI?.updatePrivacy}?id=${payload?.id}`, payload.payload, {
      accept: ' text/plain',
      'Content-Type': ' application/json',
      'X-Requested-With': ' XMLHttpRequest',
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
