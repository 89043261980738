import React from 'react';

const MailSmall = ({ fill = '#02387A' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill={fill}>
      <path d="M3.5 16q-.625 0-1.062-.438Q2 15.125 2 14.5v-9q0-.625.438-1.062Q2.875 4 3.5 4h13q.625 0 1.062.438Q18 4.875 18 5.5v9q0 .625-.438 1.062Q17.125 16 16.5 16Zm6.5-5L3.5 7.271V14.5h13V7.271Zm0-1.771L16.5 5.5h-13ZM3.5 7.271V5.5v9Z" />
    </svg>
  );
};

export default MailSmall;
