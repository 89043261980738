import { studentActions } from './Student.constants';
const {
  ASSIGNMENT_LIST,
  ASSESSMENT_LIST,
  ASSESSMENT_BY_ID,
  ASSIGNMENT_BY_ID,
  MODULES,
  SUBMODULES,
  STUDENT_COURSE,
  NOTES_BOOKMARKS_LIST,
  COURSE_DETAILS,
  ASSESSMENT_CREATE,
  QUIZ_CREATE,
  PENDING_ASSESSMENT,
  PENDING_ASSIGNMENT,
  GET_ASSIGNMENT_BY_STUDENT_ID,
  ASSESSMENT_EDIT,
  ASSESSMENT_DELETE,
  QUIZ_EDIT,
  QUIZ_DELETE,
  GET_STUDENT_ACTIVITIES,
  SUBMIT_QUIZ,
  ALL_USERS,
  STUDENT_LOADING,
  STUDENT_SEMESTER,
  VIDEO_READINGS_PROGRESS,
  GET_SUBJECT_PROGRAM_DETAILS,
  GET_STUDENT_CONNECT,
} = studentActions;

export const getAssignmentList = (payload) => ({
  type: ASSIGNMENT_LIST.get,
  payload: payload,
});

export const getAssignmentListSuccess = (payload) => ({
  type: ASSIGNMENT_LIST.get_success,
  payload: payload,
});

export const getAssignmentListError = (payload) => ({
  type: ASSIGNMENT_LIST.get_error,
  payload: payload,
});

export const getAssessmentList = (payload) => ({
  type: ASSESSMENT_LIST.get,
  payload: payload,
});

export const getAssessmentListSuccess = (payload) => ({
  type: ASSESSMENT_LIST.get_success,
  payload: payload,
});

export const getAssessmentListError = (payload) => ({
  type: ASSESSMENT_LIST.get_error,
  payload: payload,
});

export const getAssessmentById = (payload) => ({
  type: ASSESSMENT_BY_ID.getById,
  payload: payload,
});

export const getAssessmentByIdSuccess = (payload) => ({
  type: ASSESSMENT_BY_ID.get_success,
  payload: payload,
});

export const getAssessmentByIdError = (payload) => ({
  type: ASSESSMENT_BY_ID.get_error,
  payload: payload,
});

export const resetAssignmentAction = () => ({
  type: QUIZ_CREATE.reset,
});

export const resetAssessmentById = () => ({
  type: ASSESSMENT_BY_ID.reset,
});

export const getAssignmentById = (payload) => ({
  type: ASSIGNMENT_BY_ID.getById,
  payload: payload,
});

export const getAssignmentByIdSuccess = (payload) => ({
  type: ASSIGNMENT_BY_ID.get_success,
  payload: payload,
});

export const getAssignmentByIdError = (payload) => ({
  type: ASSIGNMENT_BY_ID.get_error,
  payload: payload,
});

export const getByQuizIdAction = (payload) => ({
  type: ASSIGNMENT_BY_ID.get_quiz_for_edit,
  payload: payload,
});

export const getByQuizIdSuccess = (payload) => ({
  type: ASSIGNMENT_BY_ID.get_quiz_for_edit_success,
  payload: payload,
});

export const getByQuizIdError = (payload) => ({
  type: ASSIGNMENT_BY_ID.get_quiz_for_edit_error,
  payload: payload,
});

export const resetAssignmentById = () => ({
  type: ASSIGNMENT_BY_ID.reset,
});

export const getCourseModule = (payload) => ({
  type: MODULES.get,
  payload: payload,
});

export const getCourseModuleSuccess = (payload) => ({
  type: MODULES.get_success,
  payload: payload,
});

export const getCourseModuleError = (payload) => ({
  type: MODULES.get_error,
  payload: payload,
});

export const getCourseSubModule = (payload) => ({
  type: SUBMODULES.get,
  payload: payload,
});

export const getCourseSubModuleSuccess = (payload) => ({
  type: SUBMODULES.get_success,
  payload: payload,
});

export const getCourseSubModuleError = (payload) => ({
  type: SUBMODULES.get_error,
  payload: payload,
});

export const getCourseByStudentId = (payload) => ({
  type: STUDENT_COURSE.get_course_by_student_id,
  payload: payload,
});

export const getCourseByStudentIdSuccess = (payload) => ({
  type: STUDENT_COURSE.get_course_by_student_id_success,
  payload: payload,
});

export const resetGetCourseByStudentId = () => ({
  type: STUDENT_COURSE.reset_get_course_by_student_id,
});

export const getCourseByStudentIdError = (payload) => ({
  type: STUDENT_COURSE.get_course_by_student_id_error,
  payload: payload,
});

export const getAssessmentBySudModuleId = (payload) => ({
  type: ASSESSMENT_BY_ID.get_assessment_by_subModule,
  payload: payload,
});

export const getAssessmentBySudModuleIdSuccess = (payload) => ({
  type: ASSESSMENT_BY_ID.get_assessment_by_subModule_success,
  payload: payload,
});

export const getAssessmentBySudModuleIdError = (payload) => ({
  type: ASSESSMENT_BY_ID.get_assessment_by_subModule_error,
  payload: payload,
});

export const getAssignmentBySudModuleId = (payload) => ({
  type: ASSIGNMENT_BY_ID.get_assignment_by_subModule,
  payload: payload,
});

export const getAssignmentBySudModuleIdSuccess = (payload) => ({
  type: ASSIGNMENT_BY_ID.get_assignment_by_subModule_success,
  payload: payload,
});

export const getAssignmentBySudModuleIdError = (payload) => ({
  type: ASSIGNMENT_BY_ID.get_assignment_by_subModule_error,
  payload: payload,
});

export const createNotesAndBookmarksApi = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.create_notes_bookmarks,
  payload: payload,
});

export const createNotesAndBookmarksSuccess = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.create_notes_bookmarks_success,
  payload: payload,
});

export const createNotesAndBookmarksError = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.create_notes_bookmarks_error,
  payload: payload,
});

export const deleteNotesAndBookmarks = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.delete_notes_bookmarks,
  payload: payload,
});

export const deleteNotesAndBookmarksSuccess = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.delete_notes_bookmarks_success,
  payload: payload,
});

export const deleteNotesAndBookmarksError = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.delete_notes_bookmarks_error,
  payload: payload,
});

export const updateNotesAndBookmarks = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.update_notes_bookmarks,
  payload: payload,
});

export const updateNotesAndBookmarksSuccess = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.update_notes_bookmarks_success,
  payload: payload,
});

export const updateNotesAndBookmarksError = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.update_notes_bookmarks_error,
  payload: payload,
});

export const getNotesAndBookmarksApi = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get,
  payload: payload,
});

export const getNotesAndBookmarksSuccess = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_success,
  payload: payload,
});

export const getNotesAndBookmarksError = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_error,
  payload: payload,
});

export const getDetailsByCourseId = (payload) => ({
  type: COURSE_DETAILS.get_details_by_course_id,
  payload: payload,
});

export const getDetailsByCourseIdSuccess = (payload) => ({
  type: COURSE_DETAILS.get_details_by_course_id_success,
  payload: payload,
});

export const getDetailsByCourseIdError = (payload) => ({
  type: COURSE_DETAILS.get_details_by_course_id_error,
  payload: payload,
});
export const createAssessmentAction = (payload) => ({
  type: ASSESSMENT_CREATE.create,
  payload: payload,
});

export const createAssessmentSuccess = (payload) => ({
  type: ASSESSMENT_CREATE.create_success,
  payload: payload,
});

export const createAssessmentError = (payload) => ({
  type: ASSESSMENT_CREATE.create_error,
  payload: payload,
});

export const resetAssessmentAction = (payload) => ({
  type: ASSESSMENT_CREATE.reset,
  payload: payload,
});

export const createQuizAction = (payload) => ({
  type: QUIZ_CREATE.create,
  payload: payload,
});

export const createQuizSuccess = (payload) => ({
  type: QUIZ_CREATE.create_success,
  payload: payload,
});

export const createQuizError = (payload) => ({
  type: QUIZ_CREATE.create_error,
  payload: payload,
  errorResponse: payload,
});

export const editQuizAction = (payload) => ({
  type: QUIZ_EDIT.edit,
  payload: payload,
});

export const editQuizSuccess = (payload) => ({
  type: QUIZ_EDIT.edit_success,
  payload: payload,
});

export const editQuizError = (payload) => ({
  type: QUIZ_EDIT.edit_error,
  payload: payload,
});

export const deleteQuizAction = (payload) => ({
  type: QUIZ_DELETE.delete,
  payload: payload,
});

export const deleteQuizSuccess = (payload) => ({
  type: QUIZ_DELETE.delete_success,
  payload: payload,
});

export const deleteQuizError = (payload) => ({
  type: QUIZ_DELETE.delete_error,
  payload: payload,
});

export const editAssessmentAction = (payload) => ({
  type: ASSESSMENT_EDIT.edit,
  payload: payload,
});

export const editAssessmentSuccess = (payload) => ({
  type: ASSESSMENT_EDIT.edit_success,
  payload: payload,
});

export const editAssessmentError = (payload) => ({
  type: ASSESSMENT_EDIT.edit_error,
  payload: payload,
});

export const deleteAssessmentAction = (payload) => ({
  type: ASSESSMENT_DELETE.delete,
  payload: payload,
});

export const deleteAssessmentSuccess = (payload) => ({
  type: ASSESSMENT_DELETE.delete_success,
  payload: payload,
});

export const deleteAssessmentError = (payload) => ({
  type: ASSESSMENT_DELETE.delete_error,
  payload: payload,
});

export const getNotesBySubModuleRequest = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_submodule_id,
  payload: payload,
});

export const getNotesBySubModuleSuccess = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_submodule_id_success,
  payload: payload,
});

export const getNotesBySubModuleError = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_submodule_id_error,
  payload: payload,
});

export const getNotesByModuleRequest = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_module_id,
  payload: payload,
});

export const getNotesByModuleSuccess = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_module_id_success,
  payload: payload,
});

export const getNotesByModuleError = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_module_id_error,
  payload: payload,
});

export const submitStudentResponse = (payload) => ({
  type: SUBMIT_QUIZ.submit_quiz,
  payload: payload,
});

export const submitStudentResponseSuccess = (payload) => ({
  type: SUBMIT_QUIZ.submit_quiz_success,
  payload: payload,
});

export const submitStudentResponseError = (payload) => ({
  type: SUBMIT_QUIZ.submit_quiz_error,
  payload: payload,
});

export const getPendingAssessmentById = (payload) => ({
  type: PENDING_ASSESSMENT.get_pending_assessment,
  payload: payload,
});

export const getPendingAssessmentByIdSuccess = (payload) => ({
  type: PENDING_ASSESSMENT.get_assessment_success,
  payload: payload,
});

export const getPendingAssessmentByIdError = (payload) => ({
  type: PENDING_ASSESSMENT.get_error,
  payload: payload,
});

export const getPendingAssignmentById = (payload) => ({
  type: PENDING_ASSIGNMENT.get_pending_quiz,
  payload: payload,
});

export const getPendingAssignmentByIdSuccess = (payload) => ({
  type: PENDING_ASSIGNMENT.get_assignment_success,
  payload: payload,
});

export const getPendingAssignmentByIdError = (payload) => ({
  type: PENDING_ASSIGNMENT.get_error,
  payload: payload,
});

export const getPendingListByStudentId = (payload) => ({
  type: GET_STUDENT_ACTIVITIES.get_student_activities,
  payload: payload,
});

export const getPendingListByStudentByIdSuccess = (payload) => ({
  type: GET_STUDENT_ACTIVITIES.get_student_activities_success,
  payload: payload,
});

export const getPendingListByStudentByIdError = (payload) => ({
  type: GET_STUDENT_ACTIVITIES.get_student_activities_error,
  payload: payload,
});

export const requestGetAllUsers = (payload) => ({
  type: ALL_USERS.get,
  payload: payload,
});

export const successGetAllUsers = (payload) => ({
  type: ALL_USERS.get_success,
  payload: payload,
});

export const errorGetAllUsers = (payload) => ({
  type: ALL_USERS.get_error,
  payload: payload,
});

export const resetGetAllUsers = () => ({
  type: ALL_USERS.reset,
});

export const setLoading = (payload) => ({
  type: STUDENT_LOADING.student_loading,
  payload: payload,
});

export const saveQuizQuestionsAction = (payload) => ({
  type: QUIZ_CREATE.save_questions,
  payload: payload,
});

export const saveQuizConditionsAction = (payload) => ({
  type: QUIZ_CREATE.save_conditions,
  payload: payload,
});

export const saveAssignmentQuestionsAction = (payload) => ({
  type: ASSESSMENT_CREATE.save_assignment,
  payload: payload,
});

export const saveAssessmentConditionsAction = (payload) => ({
  type: ASSESSMENT_CREATE.save_assignment_conditions,
  payload: payload,
});

export const createStudentProgramDetailsAction = (payload) => ({
  type: STUDENT_SEMESTER.create_student_program_details_action,
  payload: payload,
});

export const createStudentProgramDetailsSuccess = (payload) => ({
  type: STUDENT_SEMESTER.create_student_program_details_succcess,
  payload: payload,
});

export const createStudentProgramDetailsError = (payload) => ({
  type: STUDENT_SEMESTER.create_student_program_details_error,
  payload: payload,
});

// Student program list
export const getStudentProgramDetailsAction = (payload) => ({
  type: STUDENT_SEMESTER.get_student_program_details_action,
  payload: payload,
});

export const getStudentProgramDetailsSuccesss = (payload) => ({
  type: STUDENT_SEMESTER.get_student_program_details_success,
  payload: payload,
});

// Student Year list
export const getStudentYearListRequest = (payload) => ({
  type: STUDENT_SEMESTER.GET_STUDENT_YEAR_LIST_REQUEST,
  payload: payload,
});

export const getStudentYearListSuccesss = (payload) => ({
  type: STUDENT_SEMESTER.GET_STUDENT_YEAR_LIST_SUCCESS,
  payload: payload,
});

export const getStudentYearListError = (payload) => ({
  type: STUDENT_SEMESTER.GET_STUDENT_YEAR_LIST_ERROR,
  payload: payload,
});

// Student Sem list
export const getStudentSemListRequest = (payload) => ({
  type: STUDENT_SEMESTER.GET_STUDENT_SEMESTER_LIST_REQUEST,
  payload: payload,
});

export const getStudentSemListSuccesss = (payload) => ({
  type: STUDENT_SEMESTER.GET_STUDENT_SEMESTER_LIST_SUCCESS,
  payload: payload,
});

export const getStudentSemListError = (payload) => ({
  type: STUDENT_SEMESTER.GET_STUDENT_SEMESTER_LIST_ERROR,
  payload: payload,
});

//

export const getStudentProgramDetailsError = (payload) => ({
  type: STUDENT_SEMESTER.get_student_program_details_error,
  payload: payload,
});

export const updateStudentProgramDetailsAction = (payload) => ({
  type: STUDENT_SEMESTER.update_student_program_details_action,
  payload: payload,
});

export const updateStudentProgramDetailsSuccess = (payload) => ({
  type: STUDENT_SEMESTER.update_student_program_details_success,
  payload: payload,
});

export const updateStudentProgramDetailsError = (payload) => ({
  type: STUDENT_SEMESTER.update_student_program_details_error,
  payload: payload,
});

export const deleteStudentProgramDetailsAction = (payload) => ({
  type: STUDENT_SEMESTER.delete_student_program_details_action,
  payload: payload,
});

export const deleteStudentProgramDetailsSuccess = (payload) => ({
  type: STUDENT_SEMESTER.delete_student_program_details_success,
  payload: payload,
});

export const deleteStudentProgramDetailsError = (payload) => ({
  type: STUDENT_SEMESTER.delete_student_program_details_error,
  payload: payload,
});

export const resetStudentProgramDetailsAction = () => ({
  type: STUDENT_SEMESTER.reset_student_program_details_action,
});

export const getNotesByContentApi = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_content,
  payload: payload,
});

export const getNotesByContentSuccess = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_content_success,
  payload: payload,
});

export const getNotesByContentError = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.get_by_content_error,
  payload: payload,
});

export const addUpdateBookmarkAction = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.addUpdate_bookmarks,
  payload: payload,
});

export const addUpdateBookmarkSuccess = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.addUpdate_bookmarks_success,
  payload: payload,
});

export const addUpdateBoomarksError = (payload) => ({
  type: NOTES_BOOKMARKS_LIST.addUpdate_bookmarks_error,
  payload: payload,
});

export const submitLearningQuizAction = (payload, params) => ({
  type: SUBMIT_QUIZ.submit_learning_quiz_request,
  payload: payload,
  params: params,
});

export const submitLearningQuizSuccess = (payload) => ({
  type: SUBMIT_QUIZ.submit_learning_quiz_success,
  payload: payload,
});

export const submitLearningQuizError = (payload) => ({
  type: SUBMIT_QUIZ.submit_learning_quiz_error,
  payload: payload,
});

export const getLearningQuizAction = (payload, params) => ({
  type: SUBMIT_QUIZ.learning_quiz_request,
  payload: payload,
  params: params,
});

export const getLearningQuizSuccess = (payload) => ({
  type: SUBMIT_QUIZ.learning_quiz_success,
  payload: payload,
});

export const getLearningQuizError = (payload) => ({
  type: SUBMIT_QUIZ.learning_quiz_error,
  payload: payload,
});

export const submitLearningAssignmentAction = (payload, params) => ({
  type: SUBMIT_QUIZ.submit_learning_assignment_request,
  payload: payload,
  params: params,
});

export const submitLearningAssignmentSuccess = (payload) => ({
  type: SUBMIT_QUIZ.submit_learning_assignment_success,
  payload: payload,
});

export const submitLearningAssignmentError = (payload) => ({
  type: SUBMIT_QUIZ.submit_learning_assignment_error,
  payload: payload,
});

export const getLearningAssignmentAction = (payload, params) => ({
  type: SUBMIT_QUIZ.learning_assignment_request,
  payload: payload,
  params: params,
});

export const getLearningAssignmentSuccess = (payload) => ({
  type: SUBMIT_QUIZ.learning_assignment_success,
  payload: payload,
});

export const getLearningAssignmentError = (payload) => ({
  type: SUBMIT_QUIZ.learning_assignment_error,
  payload: payload,
});

export const postVideoProgressAction = (payload, params) => ({
  type: VIDEO_READINGS_PROGRESS.video_progress,
  payload: payload,
  params: params,
});

export const postVideoProgressSuccess = (payload) => ({
  type: VIDEO_READINGS_PROGRESS.video_progress_success,
  payload: payload,
});

export const postVideoProgressError = (payload) => ({
  type: VIDEO_READINGS_PROGRESS.video_progress_error,
  payload: payload,
});

export const postReadingsProgressAction = (payload, params) => ({
  type: VIDEO_READINGS_PROGRESS.reading_progress,
  payload: payload,
  params: params,
});

export const postReadingsProgressSuccess = (payload) => ({
  type: VIDEO_READINGS_PROGRESS.reading_progress_success,
  payload: payload,
});

export const postReadingsProgressError = (payload) => ({
  type: VIDEO_READINGS_PROGRESS.reading_progress_error,
  payload: payload,
});

export const getVideoProgressAction = (payload, params) => ({
  type: VIDEO_READINGS_PROGRESS.get_video_progress,
  payload: payload,
  params: params,
});

export const getVideoProgressSuccess = (payload) => ({
  type: VIDEO_READINGS_PROGRESS.get_video_progress_success,
  payload: payload,
});

export const getVideoProgressError = (payload) => ({
  type: VIDEO_READINGS_PROGRESS.get_video_progress_error,
  payload: payload,
});

export const getReadingsProgressAction = (payload, params) => ({
  type: VIDEO_READINGS_PROGRESS.get_reading_progress,
  payload: payload,
  params: params,
});

export const getReadingsProgressSuccess = (payload) => ({
  type: VIDEO_READINGS_PROGRESS.get_reading_progress_success,
  payload: payload,
});

export const getReadingsProgressError = (payload) => ({
  type: VIDEO_READINGS_PROGRESS.get_reading_progress_error,
  payload: payload,
});

export const getSubjectProgramDetails = (payload) => ({
  type: GET_SUBJECT_PROGRAM_DETAILS.get,
  payload,
});

export const getSubjectProgramDetailsSuccess = (payload) => ({
  type: GET_SUBJECT_PROGRAM_DETAILS.success,
  payload,
});

export const getSubjectProgramDetailsError = (payload) => ({
  type: GET_SUBJECT_PROGRAM_DETAILS.error,
  payload,
});

export const resetSubjectProgramDetails = () => ({
  type: GET_SUBJECT_PROGRAM_DETAILS.reset,
});

export const getSubjectProgramDetailsLoading = (payload) => ({
  type: GET_SUBJECT_PROGRAM_DETAILS.loading,
  payload,
});

//Student Connect
export const getStudentConnectAction = (payload) => ({
  type: GET_STUDENT_CONNECT.GET_STUDENT,
  payload,
});

export const getStudentConnectSuccess = (payload) => ({
  type: GET_STUDENT_CONNECT.GET_STUDENT_SUCCESS,
  payload,
});

export const getStudentConnectError = (payload) => ({
  type: GET_STUDENT_CONNECT.GET_STUDENT_ERROR,
  payload,
});

export const resetStudentConnect = () => ({
  type: GET_STUDENT_CONNECT.GET_STUDENT_RESET,
});

//Student Connect Notification

export const getStudentNotificationAction = (payload) => ({
  type: GET_STUDENT_CONNECT.GET_STUDENT_NOTIFICTION,
  payload,
});

export const getStudentNotificationSuccess = (payload) => ({
  type: GET_STUDENT_CONNECT.GET_STUDENT_NOTIFICATION_SUCCESS,
  payload,
});

export const getStudentNotificationError = (payload) => ({
  type: GET_STUDENT_CONNECT.GET_STUDENT_NOTIFICATION_ERROR,
  payload,
});

export const resetStudentNotification = () => ({
  type: GET_STUDENT_CONNECT.GET_STUDENT_NOTIFICATION_RESET,
});

// Create Notification
export const createStudentNotificationAction = (payload) => ({
  type: GET_STUDENT_CONNECT.CREATE_STUDENT_NOTIFICTION,
  payload,
});

export const createStudentNotificationSuccess = (payload) => ({
  type: GET_STUDENT_CONNECT.CREATE_STUDENT_NOTIFICATION_SUCCESS,
  payload,
});

export const createStudentNotificationError = (payload) => ({
  type: GET_STUDENT_CONNECT.CREATE_STUDENT_NOTIFICATION_ERROR,
  payload,
});

export const resetcreateStudentNotification = () => ({
  type: GET_STUDENT_CONNECT.CREATE_STUDENT_NOTIFICATION_RESET,
});
// Delete notification
export const deleteStudentNotificationAction = (payload) => ({
  type: GET_STUDENT_CONNECT.DELETE_STUDENT_NOTIFICTION,
  payload,
});

export const deleteStudentNotificationSuccess = (payload) => ({
  type: GET_STUDENT_CONNECT.DELETE_STUDENT_NOTIFICATION_SUCCESS,
  payload,
});

export const deleteStudentNotificationError = (payload) => ({
  type: GET_STUDENT_CONNECT.DELETE_STUDENT_NOTIFICATION_ERROR,
  payload,
});

export const resetdeleteStudentNotification = () => ({
  type: GET_STUDENT_CONNECT.DELETE_STUDENT_NOTIFICATION_RESET,
});

//Bulk Delete
export const deleteBulkStudentNotificationAction = (payload) => ({
  type: GET_STUDENT_CONNECT.BULK_DELETE_STUDENT_NOTIFICTION,
  payload,
});

export const deleteBulkStudentNotificationSuccess = (payload) => ({
  type: GET_STUDENT_CONNECT.BULK_DELETE_STUDENT_NOTIFICATION_SUCCESS,
  payload,
});

export const deleteBulkStudentNotificationError = (payload) => ({
  type: GET_STUDENT_CONNECT.BULK_DELETE_STUDENT_NOTIFICATION_ERROR,
  payload,
});

export const resetBulkdeleteStudentNotification = () => ({
  type: GET_STUDENT_CONNECT.BULK_DELETE_STUDENT_NOTIFICATION_RESET,
});
