import React from 'react';

const ConversionPath = ({ fill = '#02387A', ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
      fill={fill}
      {...rest}
    >
      <path d="M760 932q-37.842 0-67.921-22.5Q662 887 649.836 852H440q-64.35 0-110.175-45.856T284 695.894q0-64.394 45.825-110.144T440 540h80q34.65 0 59.325-24.644Q604 490.712 604 456.106t-24.675-59.356Q554.65 372 520 372H310.177Q297 407 267.415 429.5T200 452q-48.5 0-82.25-33.868Q84 384.265 84 335.882q0-48.382 33.833-82.132Q151.667 220 200 220q37.83 0 67.415 22.5Q297 265 310.177 300H520q64.35 0 110.175 45.856T676 456.106q0 64.394-45.825 110.144T520 612h-80q-34.65 0-59.325 24.644Q356 661.288 356 695.894t24.675 59.356Q405.35 780 440 780h209.823q13.177-35 42.762-57.5T760 700q48.5 0 82.25 33.868Q876 767.735 876 816.118q0 48.382-33.833 82.132Q808.333 932 760 932ZM200 377q17 0 29-12t12-29q0-17-12-29t-29-12q-17 0-29 12t-12 29q0 17 12 29t29 12Z" />
    </svg>
  );
};

export default ConversionPath;
