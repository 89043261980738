import { VerifierActions } from './Verifier.constants';

const INITIAL_STATE = {
  verifierStats: '',
  verifierStatsError: '',
  candidatesListLoading: false,
  candidatesList: '',
  candidatesListError: '',
  candidateApplicationInfo: '',
  candidateApplicationInfoError: '',
  prospectusFeedbackLoading: false,
  prospectusFeedback: '',
  prospectusFeedbackError: '',
  sendProspectusFeedbackSuccess: '',
  sendProspectusFeedbackError: '',
  prospectusLoading: false,
  updateProspectusStatusSuccess: '',
  updateProspectusStatusError: '',
  feesDetails: '',
  feesDetailsError: '',
  addDiscountSuccess: '',
  addDiscountError: '',
  deleteDiscountSuccess: '',
  deleteDiscountError: '',
  leadStageVerifier: '',
  updatePaymentDetailsData: '',
  sendPaymentLinkData: '',
  sendToRegistrarData: '',
  fileUploadData: '',
  profileDetails:{},
  profileDetailsError:''
};

const verifierReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case VerifierActions.SET_VERIFIER_STATS_SUCCESS:
      return { ...state, verifierStats: action.payload };

    case VerifierActions.SET_VERIFIER_STATS_ERROR:
      return { ...state, verifierStatsError: action.payload };

    case VerifierActions.SET_CANDIDATES_LIST_SUCCESS:
      return { ...state, candidatesList: action.payload };

    case VerifierActions.SET_CANDIDATES_LIST_ERROR:
      return { ...state, candidatesListError: action.payload };

    case VerifierActions.SET_CANDIDATES_LIST_LOADING:
      return { ...state, candidatesListLoading: action.payload };

    case VerifierActions.SET_CANDIDATE_APPLICATION_INFO_SUCCESS:
      return { ...state, candidateApplicationInfo: action.payload };

    case VerifierActions.SET_CANDIDATE_APPLICATION_INFO_ERROR:
      return { ...state, candidateApplicationInfoError: action.payload };

    case VerifierActions.RESET_CANDIDATE_APPLICATION_INFO:
      return {
        ...state,
        candidateApplicationInfo: '',
        candidateApplicationInfoError: '',
      };

    case VerifierActions.SET_PROSPECTUS_FEEDBACK_LOADING:
      return { ...state, prospectusFeedbackLoading: action.payload };

    case VerifierActions.SET_PROSPECTUS_FEEDBACK_SUCCESS:
      return { ...state, prospectusFeedback: action.payload };

    case VerifierActions.SET_PROSPECTUS_FEEDBACK_ERROR:
      return { ...state, prospectusFeedbackError: action.payload };

    case VerifierActions.SEND_PROSPECTUS_FEEDBACK_SUCCESS:
      return { ...state, sendProspectusFeedbackSuccess: action.payload };

    case VerifierActions.SEND_PROSPECTUS_FEEDBACK_ERROR:
      return { ...state, sendProspectusFeedbackError: action.payload };

    case VerifierActions.SET_PROSPECTUS_LOADING:
      return { ...state, prospectusLoading: action.payload };

    case VerifierActions.UPDATE_PROSPECTUS_STATUS_SUCCESS:
      return { ...state, updateProspectusStatusSuccess: action.payload };

    case VerifierActions.UPDATE_PROSPECTUS_STATUS_ERROR:
      return { ...state, updateProspectusStatusError: action.payload };

    case VerifierActions.SUCCESS_LEAD_STAGE_VERIFIER:
      return { ...state, leadStageVerifier: action.payload };

    case VerifierActions.SUCCESS_UPDATE_PAYMENT_DETAILS:
      return { ...state, updatePaymentDetailsData: action.payload };

    case VerifierActions.RESET_UPDATE_PAYMENT_DETAILS:
      return { ...state, updatePaymentDetailsData: '' };

    case VerifierActions.RESET_UPDATE_PROSPECTUS_STATUS:
      return {
        ...state,
        updateProspectusStatusSuccess: '',
        updateProspectusStatusError: '',
      };

    case VerifierActions.SET_FEES_DETAILS_SUCCESS:
      return { ...state, feesDetails: action.payload };

    case VerifierActions.SET_FEES_DETAILS_ERROR:
      return { ...state, feesDetailsError: action.payload };

    case VerifierActions.RESET_FEES_DETAILS:
      return { ...state, feesDetails: '', feesDetailsError: '' };

    case VerifierActions.ADD_DISCOUNT_SUCCESS:
      return { ...state, addDiscountSuccess: action.payload };

    case VerifierActions.ADD_DISCOUNT_ERROR:
      return { ...state, addDiscountError: action.payload };

    case VerifierActions.RESET_ADD_DISCOUNT:
      return { ...state, addDiscountSuccess: '', addDiscountError: '' };

    case VerifierActions.DELETE_DISCOUNT_SUCCESS:
      return { ...state, deleteDiscountSuccess: action.payload };

    case VerifierActions.DELETE_DISCOUNT_ERROR:
      return { ...state, deleteDiscountError: action.payload };

    case VerifierActions.RESET_DELETE_DISCOUNT:
      return { ...state, deleteDiscountSuccess: '', deleteDiscountError: '' };

    case VerifierActions.SUCCESS_SEND_PAYMENT_LINK:
      return { ...state, sendPaymentLinkData: action.payload };

    case VerifierActions.RESET_SEND_PAYMENT_LINK:
      return { ...state, sendPaymentLinkData: '' };

    case VerifierActions.SUCCESS_SEND_TO_REGISTRAR:
      return { ...state, sendToRegistrarData: action.payload };

    case VerifierActions.RESET_SEND_TO_REGISTRAR:
      return { ...state, sendToRegistrarData: '' };

    case VerifierActions.RECEIPT_FILE_UPLOAD_SUCCESS:
      return { ...state, fileUploadData: action.payload };

      case VerifierActions.GET_USER_DETAILS_SUCCESS:
        return {...state, profileDetails: action.payload}

        case VerifierActions.GET_USER_DETAILS_ERROR:
        return {...state, profileDetailsError: action.payload}

    default:
      return state;
  }
};

export default verifierReducer;
