import { programContentActions } from './ProgramContent.constants';

const INITIAL_STATE = {
  isLoading: false,
  mappedDepartmentList: { loading: false, count: 0, results: [] },
  programList: { loading: false, count: 0, results: [] },
  yearList: { loading: false, count: 0, results: [] },
  subjectStats: { loading: false, results: {} },
  semesterList: { loading: false, count: 0, results: [] },
  subjectList: { loading: false, count: 0, results: [] },
  chapterList: { loading: false, count: 0, results: [] },
  practicalContent: { data: {}, getPracticalData: {}, deletePracticalData: '' },
  videoContent: {
    data: {},
    getVideoData: {},
    deleteVideoData: '',
    loading: false,
  },
  videoUploadData: '',
  fileTranscriptData: '',
  programDetails: { result: {} },
  chapterContent: {
    results: {
      chapter_content: [],
      chapter_credit_points: 0,
      chapter_details: [],
      chapter_name: '',
      objective: '',
      content_creator: '',
    },
  },
  readingContent: { results: [] },
  addChapter: { data: {} },
  chapterDetails: { results: {} },
  updateChapter: { data: {}, deleteReadingData: '' },
  deleteChapter: { data: {} },
  deleteLiveSession: { data: {} },
  facultyList: { results: {} },
  userDepartmentList: { loading: false, count: 0, results: [] },
  userProgramList: { loading: false, count: 0, results: [] },
  getReadingData: { data: {} },
  createVm: { data: '', error: '' },
  // Get Lab List
  labList: { loading: false, count: 0, results: [] },
  VmState: { data: {} },
};

const programContentReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case programContentActions.GET_MAPPED_DEPARTMENT_LIST.get_success: {
      return {
        ...state,
        mappedDepartmentList: {
          ...state.mappedDepartmentList,
          count: action?.payload?.count,
          results: action?.payload?.results,
        },
      };
    }

    case programContentActions.GET_PROGRAM_LIST_FOR_FACULTY.get_success: {
      return {
        ...state,
        programList: {
          ...state.programList,
          count: action?.payload?.count,
          results: action?.payload?.results,
        },
      };
    }
    case programContentActions.GET_MAPPED_YEAR_LIST.get_success: {
      return {
        ...state,
        yearList: {
          ...state.yearList,
          count: action?.payload?.count,
          results: action?.payload?.results,
        },
      };
    }

    case programContentActions.GET_SUBJECT_STATS.get_success: {
      return {
        ...state,
        subjectStats: {
          ...state.subjectStats,
          results: action?.payload,
        },
      };
    }

    case programContentActions.GET_MAPPED_SEMESTER_LIST.get_success: {
      return {
        ...state,
        semesterList: {
          ...state.semesterList,
          count: action?.payload?.count,
          results: [...action?.payload?.results],
        },
      };
    }

    case programContentActions.GET_CHAPTER_LIST_BY_SUBJECT.get_success: {
      return {
        ...state,
        chapterList: {
          ...state.chapterList,
          count: action?.payload?.count,
          results: action?.payload?.results,
        },
      };
    }

    case programContentActions.GET_CHAPTER_LIST_BY_SUBJECT.loader: {
      return {
        ...state,
        chapterList: {
          ...state.chapterList,
          loading: action.payload,
        },
      };
    }

    case programContentActions.GET_CHAPTER_LIST_BY_SUBJECT.reset: {
      return {
        ...state,
        chapterList: INITIAL_STATE.chapterList,
      };
    }

    case programContentActions.GET_MAPPED_SUBJECT_LIST.get_success: {
      return {
        ...state,
        subjectList: {
          ...state.subjectList,
          count: action?.payload?.count,
          results: action?.payload?.results,
        },
      };
    }
    case programContentActions.GET_MAPPED_SUBJECT_LIST.get_reset: {
      return {
        ...state,
        subjectList: INITIAL_STATE.subjectList,
      };
    }
    case programContentActions.GET_CHAPTER_CONTENT.get_success: {
      return {
        ...state,
        chapterContent: {
          ...state.chapterContent,
          results: action?.payload ?? {},
        },
      };
    }
    case programContentActions.GET_CHAPTER_CONTENT.get_reset: {
      return {
        ...state,
        chapterContent: INITIAL_STATE.chapterContent,
      };
    }
    case programContentActions.READING.create_success: {
      return {
        ...state,
        readingContent: {
          ...state.readingContent,
          results: action?.payload,
        },
      };
    }

    case programContentActions.READING.delete_success: {
      return {
        ...state,
        readingContent: {
          ...state.readingContent,
          deleteReadingData: action?.payload,
        },
      };
    }

    case programContentActions.GET_PROGRAM_DETAILS.get_success: {
      return {
        ...state,
        programDetails: {
          ...state.programDetails,
          results: action?.payload ?? {},
        },
      };
    }

    case programContentActions.PRACTICAL_CONTENT.set_success:
    case programContentActions.PRACTICAL_CONTENT.update_success: {
      return {
        ...state,
        practicalContent: {
          ...state.practicalContent,
          data: action?.payload,
        },
      };
    }

    case programContentActions.PRACTICAL_CONTENT.get_success: {
      return {
        ...state,
        practicalContent: {
          ...state.practicalContent,
          getPracticalData: action?.payload,
        },
      };
    }

    case programContentActions.PRACTICAL_CONTENT.delete_success_practical: {
      return {
        ...state,
        practicalContent: {
          ...state.practicalContent,
          deletePracticalData: action?.payload,
        },
      };
    }

    case programContentActions.VIDEO_CONTENT.loader: {
      return {
        ...state,
        videoContent: {
          ...state.videoContent,
          loading: action.payload,
        },
      };
    }

    case programContentActions.VIDEO_CONTENT.set_success:
    case programContentActions.VIDEO_CONTENT.update_success: {
      return {
        ...state,
        videoContent: {
          ...state.videoContent,
          data: action?.payload,
        },
      };
    }

    case programContentActions.VIDEO_CONTENT.get_success: {
      return {
        ...state,
        videoContent: {
          ...state.videoContent,
          getVideoData: action?.payload,
        },
      };
    }

    case programContentActions.VIDEO_CONTENT.delete_success_video: {
      return {
        ...state,
        videoContent: {
          ...state.videoContent,
          deleteVideoData: action?.payload,
        },
      };
    }

    case programContentActions.VIDEO_CONTENT.upload_file_success:
      return {
        ...state,
        videoUploadData: {
          ...state.videoUploadData,
          ...action.payload,
          error: '',
        },
      };

    case programContentActions.VIDEO_CONTENT.upload_file_error:
      console.log(action.payload);
      return { ...state, videoUploadData: { error: action.payload } };

    case programContentActions.VIDEO_CONTENT.upload_transcript_file_success:
      return { ...state, fileTranscriptData: action.payload };

    case programContentActions.LOADING.loading: {
      return {
        ...state,
        isLoading: action?.payload,
      };
    }

    case programContentActions.ADD_CHAPTER.create_success: {
      return {
        ...state,
        addChapter: {
          ...state.addChapter,
          data: action?.payload,
        },
      };
    }

    case programContentActions.GET_CHAPTER_DETAILS_BY_ID.get_success: {
      return {
        ...state,
        chapterDetails: {
          ...state.chapterDetails,
          results: action?.payload,
        },
      };
    }

    case programContentActions.UPDATE_CHAPTER.update_success: {
      return {
        ...state,
        updateChapter: {
          ...state.updateChapter,
          data: action?.payload,
        },
      };
    }

    case programContentActions.DELETE_CHAPTER.delete_success: {
      return {
        ...state,
        deleteChapter: {
          ...state.deleteChapter,
          data: action?.payload,
        },
      };
    }

    case programContentActions.DELETE_LIVE_SESSION.delete_success: {
      return {
        ...state,
        deleteLiveSession: {
          ...state.deleteLiveSession,
          data: action?.payload,
        },
      };
    }

    case programContentActions.GET_FACULTY_LIST_BY_SUBJECT_ID.get_success: {
      return {
        ...state,
        facultyList: {
          ...state.facultyList,
          results: action?.payload,
        },
      };
    }

    case programContentActions.GET_USER_DEPARTMENTS.get_success: {
      return {
        ...state,
        userDepartmentList: {
          ...state.userDepartmentList,
          results: action?.payload?.results,
        },
      };
    }

    case programContentActions.GET_USER_BASED_PROGRAM.get_success: {
      const userBasedProgram =
        action?.payload?.results.length > 0 ? action?.payload?.results : [];
      return {
        ...state,
        userProgramList: {
          ...state.userProgramList,
          results: [...userBasedProgram],
        },
      };
    }

    case programContentActions.GET_USER_BASED_PROGRAM.reset: {
      return {
        ...state,
        userProgramList: {
          ...INITIAL_STATE.userProgramList,
        },
      };
    }

    case programContentActions.READING.get_success: {
      return {
        ...state,
        getReadingData: {
          data: action?.payload?.results || {},
        },
      };
    }
    //Get virtual LAB
    case programContentActions.VIRTUAL_LAB.GET_LAB_LIST_REQUEST: {
      return {
        ...state,
        labList: {
          ...state.labList,
          loading: true,
          error: false,
          errorMessage: '',
          data: {},
        },
      };
    }

    case programContentActions.VIRTUAL_LAB.GET_LAB_LIST_SUCCESS: {
      return {
        ...state,
        labList: {
          ...INITIAL_STATE.labList,
          results: action.payload,
        },
      };
    }

    case programContentActions.VIRTUAL_LAB.GET_LAB_LIST_ERROR: {
      return {
        ...state,
        labList: {
          ...state.labList,
          loading: false,
          error: true,
          errorMessage: 'Something went wrong',
          data: {},
        },
      };
    }

    //Create VM
    case programContentActions.CREATE_VM.CREATE_VM_SUCCESS: {
      return {
        ...state,
        createVm: {
          data: action.payload,
          error: '',
        },
      };
    }

    case programContentActions.CREATE_VM.CREATE_VM_ERROR: {
      return {
        ...state,
        createVm: {
          data: '',
          error: action.payload,
        },
      };
    }

    case programContentActions.CREATE_VM.RESET_CREATE_VM_REQUEST: {
      return {
        ...state,
        createVm: INITIAL_STATE.createVm,
      };
    }
    //Get Vm State
    case programContentActions.GET_VM_STATE.GET_VM_STATE_REQUEST: {
      return {
        ...state,
        VmState: {
          ...state.VmState,
          loading: true,
          error: false,
          errorMessage: '',
          data: {},
        },
      };
    }

    case programContentActions.GET_VM_STATE.GET_VM_STATE_SUCCESS: {
      return {
        ...state,
        VmState: {
          ...INITIAL_STATE.VmState,
        },
      };
    }

    case programContentActions.GET_VM_STATE.GET_VM_STATE_ERROR: {
      return {
        ...state,
        VmState: {
          ...state.VmState,
          loading: false,
          error: true,
          errorMessage: 'Something went wrong',
          data: {},
        },
      };
    }
    default:
      return state;
  }
};

export default programContentReducer;
