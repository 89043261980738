export const VerifierAPI = {
  leadVerifier: '/v1/admission/lead-verifier',
  startVerifying: '/v1/admission/assignee-verifier',
  candidateInformation: '/v1/admission/lead',
  leadStageApi: '/v1/admission/lead-stage',
  verifierStats: '/v1/admission/verifier-stats',
  prospectusFeedback: '/v1/admission/prospectus-feedback',
  prospectus: '/v1/admission/prospectus',
  payment: '/v1/admission/discount',
  updatePayment: '/v1/admission/payment',
  sendPaymentLink: '/v1/admission/lead-counsellor-payment',
  sendToRegistrar: '/v1/admission/lead-registar',
  fileUpload: '/v1/admission/file',
  getProfileDetail: '/api/userManagement/UserManage/GetUserDetails'
};
