import React from 'react';
import { Stack, Typography } from 'iiht-b2a-ui-components/build/components';
import { PageNotFoundIcon } from 'icons';

const DomainNotFoundPage = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{
        height: '100vh',
      }}
    >
      <PageNotFoundIcon />
      <Typography variant="h2" fontWeight="300" color="#4f4d55">
        404
      </Typography>
      <Typography variant="h2" fontWeight="300" color="#4f4d55">
        Oops! page not found.
      </Typography>
      <Typography variant="headline" color="#4f4d55">
        Invalid domain, please contact administration.
      </Typography>
    </Stack>
  );
};

export default DomainNotFoundPage;
