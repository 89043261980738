export const drawerWidth = 250;
export const translator = {
  localhost3000: 'http://localhost:3000',
  localhost3001: 'http://localhost:3001',
  localhost3002: 'http://localhost:3002',
  baseURL:
    process?.env?.REACT_APP_API_ENDPOINT ||
    'http://myaksdev-ing.centralindia.cloudapp.azure.com',
  qaBaseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://qa.pro',
  stageBaseURL:
    process?.env?.REACT_APP_API_ENDPOINT || 'https://staging.pro/apps',
  prodBaseURL:
    process?.env?.REACT_APP_API_ENDPOINT || 'https://production.pro/apps',
  uatBaseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://uat.pro/apps',
  hotfixBaseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://uat.pro/apps',
  wsUrl: process?.env?.REACT_APP_WEBSOCKET_ENDPOINT || `wss://dev.pro/apps/`,
  wsMmlUrl: 'wss://laas.makemylabs.in',
};

export const dashboardActions = {
  apiAction: 'FETCH_DATA',
};

export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';

export const loginActions = {
  LOGIN_ACTION_ERROR: 'LOGIN_ACTION_ERROR',
  LOGIN_ACTION: 'LOGIN_ACTION',
  LOGIN_ACTION_SUCCESS: 'LOGIN_ACTION_SUCCESS',
  LOGIN_ACTION_RESET: 'LOGIN_ACTION_RESET',

  LOGOUT_ACTION: 'LOGOUT_ACTION',
  LOGOUT_ACTION_SUCCESS: 'LOGOUT_ACTION_SUCCESS',
  LOGOUT_ACTION_ERROR: 'LOGOUT_ACTION_ERROR',
  LOGOUT_ACTION_RESET: 'LOGOUT_ACTION_RESET',

  FORGET_PASSWORD_ACTION: 'FORGET_PASSWORD_ACTION',
  FORGET_PASSWORD_ACTION_ERROR: 'FORGET_PASSWORD_ACTION_ERROR',
  FORGET_PASSWORD_ACTION_SUCCESS: 'FORGET_PASSWORD_ACTION_SUCCESS',
  FORGET_PASSWORD_ACTION_RESET: 'FORGET_PASSWORD_ACTION_RESET',

  RESET_PASSWORD_ACTION: 'RESET_PASSWORD_ACTION',
  RESET_PASSWORD_ACTION_ERROR: 'RESET_PASSWORD_ACTION_ERROR',
  RESET_PASSWORD_ACTION_SUCCESS: 'RESET_PASSWORD_ACTION_SUCCESS',
  RESET_PASSWORD_ACTION_RESET: 'RESET_PASSWORD_ACTION_RESET',

  CHANGE_PASSWORD_ACTION: 'CHANGE_PASSWORD_ACTION',
  CHANGE_PASSWORD_ACTION_ERROR: 'CHANGE_PASSWORD_ACTION_ERROR',
  CHANGE_PASSWORD_ACTION_SUCCESS: 'CHANGE_PASSWORD_ACTION_SUCCESS',
  CHANGE_PASSWORD_ACTION_RESET: 'CHANGE_PASSWORD_ACTION_RESET',

  EDIT_PROFILE_DETAILS_ACTION: 'EDIT_PROFILE_DETAILS_ACTION',
  EDIT_PROFILE_DETAILS_ERROR: 'EDIT_PROFILE_DETAILS_ERROR',
  EDIT_PROFILE_DETAILS_SUCCESS: 'EDIT_PROFILE_DETAILS_SUCCESS',
  EDIT_PROFILE_DETAILS_RESET: 'EDIT_PROFILE_DETAILS_RESET',
};
