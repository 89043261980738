import React from 'react';
import { CommonModal } from '../CommonModal';
import DocumentViewer from './DocumentViewer';
import { useTranslation } from 'react-i18next';
import { Box } from 'iiht-b2a-ui-components/build/components';
import './DocumentViewerStyle.scss';

const DocumentViewerInModal = (props) => {
  const {
    isOpen = false,
    modalName = '',
    appendModalName = '',
    attachmentList = [],
    closeHandler = () => {},
    isFileNull = false,
    fileNullMessage = '',
    isFileDownloadAvailable = false,
  } = props || '';
  const { t } = useTranslation();

  const modalTitle =
    appendModalName === ''
      ? t(modalName)
      : t(modalName).concat(appendModalName);

  return (
    <>
      <section id="document-viewer-in-modal">
        <CommonModal
          open={isOpen}
          onClose={closeHandler}
          modalTitle={modalTitle}
          showDividers
          fullWidth
          hideActions
          onSubmit={closeHandler}
        >
          <Box className="documentViewerInModal">
            {!isFileNull && attachmentList?.length > 0 && (
              <DocumentViewer
                attachmentList={attachmentList}
                isFileDownloadAvailable={isFileDownloadAvailable}
              />
            )}
            {isFileNull && (
              <div className="invalidFileWrapper">{fileNullMessage}</div>
            )}
          </Box>
        </CommonModal>
      </section>
    </>
  );
};

export default DocumentViewerInModal;
