import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationHI from './locales/hi/translation.json';
import translationMA from './locales/mr/translation.json';
import translationKN from './locales/kn/translation.json';

const availableLanguages = ['en', 'hi', 'mr', 'kn'];
const resources = {
  en: {
    translation: translationEN,
  },
  hi: {
    translation: translationHI,
  },
  mr: {
    translation: translationMA,
  },
  kn: {
    translation: translationKN,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  whitelist: availableLanguages,
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
