import { scheduleActions } from './Schedule.constants';
const {
  COURSE_SCHEDULE,
  MODULE_SCHEDULE,
  USER_TIMETABLE,
  SCHEDULE_APPROVALS,
  STUDENT_CONNECT,
  USERS_UNDER_DEPT,
} = scheduleActions;

export const getCourseScheduleAction = (payload) => ({
  type: COURSE_SCHEDULE.get,
  payload: payload,
});

export const getCourseScheduleSuccess = (payload) => ({
  type: COURSE_SCHEDULE.get_success,
  payload: payload,
});

export const getCourseScheduleError = (payload) => ({
  type: COURSE_SCHEDULE.get_error,
  payload: payload,
});

export const getModuleScheduleAction = (payload) => ({
  type: MODULE_SCHEDULE.get,
  payload: payload,
});

export const getModuleScheduleSuccess = (payload) => ({
  type: MODULE_SCHEDULE.get_success,
  payload: payload,
});

export const getModuleScheduleError = (payload) => ({
  type: MODULE_SCHEDULE.get_error,
  payload: payload,
});

export const getTimeTableAction = (payload) => ({
  type: USER_TIMETABLE.get,
  payload: payload,
});

export const getTimeTableSuccess = (payload) => ({
  type: USER_TIMETABLE.get_success,
  payload: payload,
});

export const getTimeTableError = (payload) => ({
  type: USER_TIMETABLE.get_error,
  payload: payload,
});

export const resetTimeTableAction = (payload) => ({
  type: USER_TIMETABLE.reset,
  payload: payload,
});

export const getScheduleApprovalsAction = (payload) => ({
  type: SCHEDULE_APPROVALS.get,
  payload: payload,
});

export const getScheduleApprovalsSuccess = (payload) => ({
  type: SCHEDULE_APPROVALS.get_success,
  payload: payload,
});

export const getScheduleApprovalsError = (payload) => ({
  type: SCHEDULE_APPROVALS.get_error,
  payload: payload,
});

export const getScheduleApprovalsByScroll = (payload) => ({
  type: SCHEDULE_APPROVALS.get_by_scroll,
  payload: payload,
});

export const getScheduleApprovalsByScrollSuccess = (payload) => ({
  type: SCHEDULE_APPROVALS.get_by_scroll_success,
  payload: payload,
});

export const getScheduleApprovalByScrollError = (payload) => ({
  type: SCHEDULE_APPROVALS.get_by_scroll_error,
  payload: payload,
});

export const resetScheduleApprovals = (payload) => ({
  type: SCHEDULE_APPROVALS.reset,
  payload: payload,
});

export const postTimeTableAction = (payload) => ({
  type: USER_TIMETABLE.create,
  payload: payload,
});

export const postTimeTableSuccess = (payload) => ({
  type: USER_TIMETABLE.create_success,
  payload: payload,
});

export const postTimeTableError = (payload) => ({
  type: USER_TIMETABLE.create_error,
  payload: payload,
});

export const getFacultyScheduleAction = (payload) => ({
  type: USER_TIMETABLE.get_faculty,
  payload: payload,
});

export const getFacultyScheduleSuccess = (payload) => ({
  type: USER_TIMETABLE.get_faculty_success,
  payload: payload,
});

export const getFacultyScheduleError = (payload) => ({
  type: USER_TIMETABLE.get_faculty_error,
  payload: payload,
});

export const resetFacultyScheduleAction = (payload) => ({
  type: USER_TIMETABLE.reset_faculty,
  payload: payload,
});

export const putScheduleStatusAction = (payload) => ({
  type: SCHEDULE_APPROVALS.put_status,
  payload: payload,
});

export const putScheduleStatusSuccess = (payload) => ({
  type: SCHEDULE_APPROVALS.put_status_success,
  payload: payload,
});

export const putScheduleStatusError = (payload) => ({
  type: SCHEDULE_APPROVALS.put_status_error,
  payload: payload,
});

export const getPastConnectionsList = (payload) => ({
  type: STUDENT_CONNECT.get,
  payload: payload,
});

export const getSuccessPastConnectionsList = (payload) => ({
  type: STUDENT_CONNECT.get_success,
  payload: payload,
});

export const getErrorPastConnectionsList = (payload) => ({
  type: STUDENT_CONNECT.get_error,
  payload: payload,
});

export const deletePastConnection = (payload) => ({
  type: STUDENT_CONNECT.delete,
  payload: payload,
});

export const deleteSuccessPastConnection = (payload) => ({
  type: STUDENT_CONNECT.delete_success,
  payload: payload,
});

export const deleteErrorPastConnection = (payload) => ({
  type: STUDENT_CONNECT.delete_error,
  payload: payload,
});

export const resetDeletePastConnection = () => ({
  type: STUDENT_CONNECT.reset_delete,
});

export const createPastConnection = (payload) => ({
  type: STUDENT_CONNECT.create,
  payload: payload,
});

export const createSuccessPastConnection = (payload) => ({
  type: STUDENT_CONNECT.create_success,
  payload: payload,
});

export const createErrorPastConnection = (payload) => ({
  type: STUDENT_CONNECT.create_error,
  payload: payload,
});

export const resetCreatePastConnection = () => ({
  type: STUDENT_CONNECT.reset_create,
});

export const pastConnectionLoadng = (payload) => ({
  type: STUDENT_CONNECT.loading,
  payload: payload,
});

export const getUsersUnderDeptAction = (payload) => ({
  type: USERS_UNDER_DEPT.get,
  payload: payload,
});

export const getUsersUnderDeptSuccess = (payload) => ({
  type: USERS_UNDER_DEPT.get_success,
  payload: payload,
});

export const getUsersUnderDeptError = (payload) => ({
  type: USERS_UNDER_DEPT.get_error,
  payload: payload,
});

export const resetUsersUnderDept = (payload) => ({
  type: USERS_UNDER_DEPT.reset_users,
  payload: payload,
});

export const getStaffUnderDeptAction = (payload) => ({
  type: USERS_UNDER_DEPT.get_staff,
  payload: payload,
});

export const getStaffUnderDeptSuccess = (payload) => ({
  type: USERS_UNDER_DEPT.get_staff_success,
  payload: payload,
});

export const getStaffUnderDeptError = (payload) => ({
  type: USERS_UNDER_DEPT.get_staff_error,
  payload: payload,
});
