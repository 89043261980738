import { scheduleActions } from 'store/Schedule';

const INITIAL_STATE = {
  courseScheduleList: { loading: false, totalCount: 0, data: [] },
  moduleScheduleList: { loading: false, totalCount: 0, data: [] },
  facultyModuleScheduleList: { loading: false, totalCount: 0, data: [] },
  userTimeTable: { loading: false, data: {} },
  facultyTimeTable: { loading: false, data: {} },
  uploadedTimetable: { loading: false, data: {} },
  pastConnectionsList: {
    loading: false,
    data: [],
    totalCount: 0,
    deletedData: '',
    connectionCreatedData: '',
  },
  scheduleStatus: false,
  departmentUsers: { data: [], totalCount: 0 },
  departmentStaff: { data: [], totalCount: 0 },
};

const scheduleReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case scheduleActions.COURSE_SCHEDULE.get_success: {
      return {
        ...state,
        courseScheduleList: {
          ...state.courseScheduleList,
          totalCount: action?.payload?.totalCount,
          data: action?.payload?.scheduleDetails,
        },
      };
    }

    case scheduleActions.MODULE_SCHEDULE.get_success: {
      return {
        ...state,
        moduleScheduleList: {
          ...state.moduleScheduleList,
          totalCount: action?.payload?.totalCount,
          data: action?.payload?.courseTimeTableModules,
        },
      };
    }

    case scheduleActions.USER_TIMETABLE.get_success: {
      return {
        ...state,
        userTimeTable: {
          ...state.userTimeTable,
          totalCount: action?.payload?.totalCount,
          data: action?.payload,
        },
      };
    }

    case scheduleActions.USER_TIMETABLE.reset: {
      return {
        ...state,
        userTimeTable: {
          ...INITIAL_STATE.userTimeTable,
        },
      };
    }

    case scheduleActions.SCHEDULE_APPROVALS.get_success: {
      return {
        ...state,
        facultyModuleScheduleList: {
          ...state.facultyModuleScheduleList,
          totalCount: action?.payload?.totalCount,
          data: action?.payload?.facultyDetails,
        },
      };
    }

    case scheduleActions.SCHEDULE_APPROVALS.get_by_scroll_success: {
      return {
        ...state,
        facultyModuleScheduleList: {
          ...state.facultyModuleScheduleList,
          totalCount: action?.payload?.totalCount,
          data: [
            ...(state.facultyModuleScheduleList.data || []),
            ...action?.payload?.facultyDetails,
          ],
        },
      };
    }

    case scheduleActions.SCHEDULE_APPROVALS.reset: {
      return {
        ...state,
        facultyModuleScheduleList: {
          ...INITIAL_STATE.facultyModuleScheduleList,
        },
      };
    }

    case scheduleActions.USER_TIMETABLE.create_success: {
      return {
        ...state,
        uploadedTimetable: {
          ...state.uploadedTimetable,
          data: action?.payload,
        },
      };
    }

    case scheduleActions.USER_TIMETABLE.get_faculty_success: {
      return {
        ...state,
        facultyTimeTable: {
          ...state.facultyTimeTable,
          data: action?.payload?.slotDetails,
        },
      };
    }

    case scheduleActions.USER_TIMETABLE.reset_faculty: {
      return {
        ...state,
        facultyTimeTable: {
          ...INITIAL_STATE.facultyTimeTable,
        },
      };
    }

    case scheduleActions.SCHEDULE_APPROVALS.put_status_success: {
      return {
        ...state,
        scheduleStatus: {
          ...state.scheduleStatus,
          data: action?.payload,
        },
      };
    }

    case scheduleActions.STUDENT_CONNECT.get_success: {
      return {
        ...state,
        pastConnectionsList: {
          ...state.pastConnectionsList,
          totalCount: action?.payload?.totalCount || 0,
          data: action?.payload?.students || [],
        },
      };
    }
    case scheduleActions.STUDENT_CONNECT.loading: {
      return {
        ...state,
        pastConnectionsList: {
          ...state.pastConnectionsList,
          loading: action?.payload,
        },
      };
    }
    case scheduleActions.STUDENT_CONNECT.create_success: {
      return {
        ...state,
        pastConnectionsList: {
          ...state.pastConnectionsList,
          connectionCreatedData: action?.payload,
        },
      };
    }
    case scheduleActions.STUDENT_CONNECT.reset_create: {
      return {
        ...state,
        pastConnectionsList: {
          ...state.pastConnectionsList,
          connectionCreatedData: '',
        },
      };
    }

    case scheduleActions.STUDENT_CONNECT.delete_success: {
      return {
        ...state,
        pastConnectionsList: {
          ...state.pastConnectionsList,
          deletedData: action?.payload,
        },
      };
    }

    case scheduleActions.STUDENT_CONNECT.reset_delete: {
      return {
        ...state,
        pastConnectionsList: {
          ...state.pastConnectionsList,
          deletedData: '',
        },
      };
    }

    case scheduleActions.USERS_UNDER_DEPT.get_success: {
      const prevUsers = state?.departmentUsers?.data || [];
      const newUsers = action?.payload?.results || [];
      return {
        ...state,
        departmentUsers: {
          ...state.departmentUsers,
          data: [ ...prevUsers, ...newUsers ],
          totalCount: action?.payload?.count,
        },
      };
    }

    case scheduleActions.USERS_UNDER_DEPT.reset_users: {
      return {
        ...state,
        departmentUsers: {
          ...INITIAL_STATE.departmentUsers,
        },
      };
    }

    case scheduleActions.USERS_UNDER_DEPT.get_staff_success: {
      return {
        ...state,
        departmentStaff: {
          ...state.departmentStaff,
          data: action?.payload?.results || [],
          totalCount: action?.payload?.count || 0,
        },
      };
    }

    default:
      return state;
  }
};

export default scheduleReducer;
