export const evaluationAPI = {
  getUserMapping: '/v2/user-mapping/get_user',
  internalEvaluation: '/v2/internal-evaluation',
  assignmentsApiUrl: '/v2/learning/assignment',
  studentEvalList: '/v2/internal-evaluation/metadata',
  exam_assignment: '/v1/access/exam_assignment',
  exam_quiz: '/v1/access/exam_quiz',
  exam_evaluation: '/v1/access/exam_evaluate',
  studentInternalExamList: '/v2/student-internal-evaluation',
  exam_upload: '/v1/access/exam_upload',
  recheckRequest: '/v1/access/evaluate',
  getExamStudentsList: '/v1/access/evaluate',
  updateAssignmentEvaluation: '/v1/access/exam_assignment',
  getStudentExamsList:
    '/v1/access/evaluate/mid_end_exam_evaluation_student_view',
  getUserMappedSubjects: '/v2/user-mapped-subject',
  getStudentMappedSubjects: '/v2/student-mapped-program',
};
