import { courseContentActions } from './CourseContentFeedback.constants';
const INITIAL_STATE = {
  courseContentFeedback: {
    data: {},
  },
  getCourseSection: {
    data: [],
  },
  getCourseContent: {
    data: [],
    success: false,
    error: false,
  },
  getCourseContentById: {
    data: [],
  },
  getCourseQuestion: {
    data: [],
  },
  getCourseQuestionById: {
    data: [],
  },
  storeIds: {
    data: {},
  },
  getQuestionBySection: {
    data: [],
  },
  courseContentFeedbackLoader: false,
};
const courseContentReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case courseContentActions.COURSECONTENT.create_success: {
      return {
        ...state,
        courseContentFeedback: {
          ...state.courseContentFeedback,
          data: action?.payload,
        },
      };
    }
    case courseContentActions.COURSECONTENT.create_reset: {
      return {
        ...state,
        courseContentFeedback: {
          ...state.courseContentFeedback,
          data: INITIAL_STATE.courseContentFeedback,
        },
      };
    }
    // get Section
    case courseContentActions.GETSECTION.GET_SECTION_SUCCESS: {
      return {
        ...state,
        getCourseSection: {
          ...state.getCourseSection,
          data: action?.payload,
        },
      };
    }
    case courseContentActions.GETSECTION.GET_SECTION_RESET: {
      return {
        ...state,
        getCourseSection: {
          ...state.getCourseSection,
          data: [],
        },
      };
    }
    case courseContentActions.COURSE_CONTENT_FEEDBACK_LOADER: {
      return {
        ...state,
        courseContentFeedbackLoader: action.payload,
      };
    }
    //get content
    case courseContentActions.GETCOURSECONTENT.GET_COURSE_CONTENT_SUCCESS: {
      return {
        ...state,
        getCourseContent: {
          ...state.getCourseContent,
          data: action?.payload || [],
          success: true,
          error: false,
        },
      };
    }
    case courseContentActions.GETCOURSECONTENT.GET_COURSE_CONTENT_ERROR: {
      return {
        ...state,
        getCourseContent: {
          ...state.getCourseContent,
          success: false,
          error: true,
        },
      };
    }
    case courseContentActions.GETCOURSECONTENT.GET_COURSE_CONTENT_RESET: {
      return {
        ...state,
        getCourseContent: {
          ...state.getCourseContent,
          data: INITIAL_STATE.getCourseContent,
        },
      };
    }
    // get content by id
    case courseContentActions.GETCOURSECONTENTBYID
      .GET_COURSE_CONTENT_BY_ID_SUCCESS: {
      return {
        ...state,
        getCourseContentById: {
          ...state.getCourseContentById,
          data: action?.payload,
        },
      };
    }
    case courseContentActions.GETCOURSECONTENTBYID
      .GET_COURSE_CONTENT_BY_ID_RESET: {
      return {
        ...state,
        getCourseContentById: {
          ...state.getCourseContentById,
          data: INITIAL_STATE.getCourseContentById,
        },
      };
    }

    // get Question
    case courseContentActions.GETQUESTION.GET_QUESTION_SUCCESS: {
      return {
        ...state,
        getCourseQuestion: {
          ...state.getCourseQuestion,
          data: action?.payload,
        },
      };
    }
    case courseContentActions.GETQUESTION.GET_QUESTION_RESET: {
      return {
        ...state,
        getCourseQuestion: {
          ...state.getCourseQuestion,
          data: INITIAL_STATE.getCourseQuestion,
        },
      };
    }

    // get Question
    case courseContentActions.GETQUESTIONBYID.GET_QUESTION_BY_ID_SUCCESS: {
      return {
        ...state,
        getCourseQuestionById: {
          ...state.getCourseQuestionById,
          data: action?.payload,
        },
      };
    }
    case courseContentActions.GETQUESTIONBYID.GET_QUESTION_BY_ID_RESET: {
      return {
        ...state,
        getCourseQuestionById: {
          ...state.getCourseQuestionById,
          data: INITIAL_STATE.getCourseQuestionById,
        },
      };
    }
    case courseContentActions.STOREIDS.STORE_IDS_ACTION: {
      return {
        ...state,
        storeIds: {
          ...state.storeIds,
          data: action?.payload,
        },
      };
    }
    // get Question BY Section
    case courseContentActions.GETQUESTIONBYSECTION
      .GET_QUESTION_BY_SECTION_SUCCESS: {
      return {
        ...state,
        getQuestionBySection: {
          ...state.getQuestionBySection,
          data: action?.payload,
        },
      };
    }
    case courseContentActions.GETQUESTIONBYSECTION
      .GET_QUESTION_BY_SECTION_RESET: {
      return {
        ...state,
        getQuestionBySection: {
          ...state.getQuestionBySection,
          data: INITIAL_STATE.getQuestionBySection,
        },
      };
    }
    default:
      return state;
  }
};
export default courseContentReducer;
