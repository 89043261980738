export const SuperAdminActions = {
  Tenant: {
    loading: 'TENANT_LOADING',
    GET_ALL_TENANTS_ACTION: 'GET_ALL_TENANTS_ACTION',
    GET_ALL_TENANTS_SUCCESS: 'GET_ALL_TENANTS_SUCCESS',
    GET_ALL_TENANTS_ERROR: 'GET_ALL_TENANTS_ERROR',
    GET_ALL_TENANTS_RESET: 'GET_ALL_TENANTS_RESET',

    GET_TENANT_DETAILS: 'GET_TENANT_DETAILS',
    GET_TENANT_DETAILS_SUCCESS: 'GET_TENANT_DETAILS_SUCCESS',
    GET_TENANT_DETAILS_ERROR: 'GET_TENANT_DETAILS_ERROR',
    GET_TENANT_DETAILS_RESET: 'GET_TENANT_DETAILS_RESET',

    UPLOAD_TENANT_ASSETS: 'UPLOAD_TENANT_ASSETS',
    UPLOAD_TENANT_ASSETS_SUCCESS: 'UPLOAD_TENANT_ASSETS_SUCCESS',
    UPLOAD_TENANT_ASSETS_ERROR: 'UPLOAD_TENANT_ASSETS_ERROR',
    UPLOAD_TENANT_ASSETS_RESET: 'UPLOAD_TENANT_ASSETS_RESET',

    UPLOAD_TENANT_LOGO: 'UPLOAD_TENANT_LOGO',
    UPLOAD_TENANT_LOGO_SUCCESS: 'UPLOAD_TENANT_LOGO_SUCCESS',
    UPLOAD_TENANT_LOGO_ERROR: 'UPLOAD_TENANT_LOGO_ERROR',
    UPLOAD_TENANT_LOGO_RESET: 'UPLOAD_TENANT_LOGO_RESET',

    UPLOAD_TENANT_LOGIN: 'UPLOAD_TENANT_LOGIN',
    UPLOAD_TENANT_LOGIN_SUCCESS: 'UPLOAD_TENANT_LOGIN_SUCCESS',
    UPLOAD_TENANT_LOGIN_ERROR: 'UPLOAD_TENANT_LOGIN_ERROR',
    UPLOAD_TENANT_LOGIN_RESET: 'UPLOAD_TENANT_LOGIN_RESET',

    UPLOAD_USER_MANUAL: 'UPLOAD_USER_MANUAL',
    UPLOAD_USER_MANUAL_SUCCESS: 'UPLOAD_USER_MANUAL_SUCCESS',
    UPLOAD_USER_MANUAL_ERROR: 'UPLOAD_USER_MANUAL_ERROR',
    UPLOAD_USER_MANUAL_RESET: 'UPLOAD_USER_MANUAL_RESET',

    CREATE_TENANT_ACTION: 'CREATE_TENANT_ACTION',
    CREATE_TENANT_SUCCESS: 'CREATE_TENANT_SUCCESS',
    CREATE_TENANT_ERROR: 'CREATE_TENANT_ERROR',
    CREATE_TENANT_RESET: 'CREATE_TENANT_RESET',

    DELETE_TENANT_ACTION: 'DELETE_TENANT_ACTION',
    DELETE_TENANT_SUCCESS: 'DELETE_TENANT_SUCCESS',
    DELETE_TENANT_ERROR: 'DELETE_TENANT_ERROR',
    DELETE_TENANT_RESET: 'DELETE_TENANT_RESET',

    EDIT_TENANT_ACTION: 'EDIT_TENANT_ACTION',
    EDIT_TENANT_SUCCESS: 'EDIT_TENANT_SUCCESS',
    EDIT_TENANT_ERROR: 'EDIT_TENANT_ERROR',
    EDIT_TENANT_RESET: 'EDIT_TENANT_RESET',

    ACTIVATE_TENANT_ACTION: 'ACTIVATE_TENANT_ACTION',
    ACTIVATE_TENANT_SUCCESS: 'ACTIVATE_TENANT_SUCCESS',
    ACTIVATE_TENANT_ERROR: 'ACTIVATE_TENANT_ERROR',
    ACTIVATE_TENANT_RESET: 'ACTIVATE_TENANT_RESET',

    SAVE_FIRST_FORMDATA_SUCCESS: 'SAVE_FIRST_FORMDATA_SUCCESS',
    SAVE_FIRST_FORMDATA_RESET: 'SAVE_FIRST_FORMDATA_RESET',

    SAVE_SECOND_FORMDATA_SUCCESS: 'SAVE_SECOND_FORMDATA_SUCCESS',
    SAVE_SECOND_FORMDATA_RESET: 'SAVE_SECOND_FORMDATA_RESET',

    ADD_ADMIN_IMAGE: 'ADD_ADMIN_IMAGE',
    ADD_ADMIN_IMAGE_SUCCESS: 'ADD_ADMIN_IMAGE_SUCCESS',
    ADD_ADMIN_IMAGE_ERROR: 'ADD_ADMIN_IMAGE_ERROR',
    ADD_ADMIN_IMAGE_RESET: 'ADD_ADMIN_IMAGE_RESET',

    GET_ADMIN_ACTION: 'GET_ADMIN_ACTION',
    GET_ADMIN_SUCCESS: 'GET_ADMIN_SUCCESS',
    GET_ADMIN_ERROR: 'GET_ADMIN_ERROR',
    GET_ADMIN_RESET: 'GET_ADMIN_RESET',

    GET_BY_ADMIN_ACTION: 'GET_BY_ADMIN_ACTION',
    GET_BY_ADMIN_SUCCESS: 'GET_BY_ADMIN_SUCCESS',
    GET_BY_ADMIN_ERROR: 'GET_BY_ADMIN_ERROR',
    GET_BY_ADMIN_RESET: 'GET_BY_ADMIN_RESET',

    DELETE_ADMIN_ACTION: 'DELETE_ADMIN_ACTION',
    DELETE_ADMIN_SUCCESS: 'DELETE_ADMIN_SUCCESS',
    DELETE_ADMIN_ERROR: 'DELETE_ADMIN_ERROR',
    DELETE_ADMIN_RESET: 'DELETE_ADMIN_RESET',

    CREATE_ADMIN_ACTION: 'CREATE_ADMIN_ACTION',
    CREATE_ADMIN_SUCCESS: 'CREATE_ADMIN_SUCCESS',
    CREATE_ADMIN_ERROR: 'CREATE_ADMIN_ERROR',
    CREATE_ADMIN_RESET: 'CREATE_ADMIN_RESET',

    GET_ADMIN_DETAILS_ACTION: 'GET_ADMIN_DETAILS_ACTION',
    GET_ADMIN_DETAILS_SUCCESS: 'GET_ADMIN_DETAILS_SUCCESS',
    GET_ADMIN_DETAILS_ERROR: 'GET_ADMIN_DETAILS_ERROR',
    GET_ADMIN_DETAILS_RESET: 'GET_ADMIN_DETAILS_RESET',

    ACTIVATE_ADMIN_ACTION: 'ACTIVATE_ADMIN_ACTION',
    ACTIVATE_ADMIN_SUCCESS: 'ACTIVATE_ADMIN_SUCCESS',
    ACTIVATE_ADMIN_ERROR: 'ACTIVATE_ADMIN_ERROR',
    ACTIVATE_ADMIN_RESET: 'ACTIVATE_ADMIN_RESET',

    GET_CARD_ITEM_ACTION: 'GET_CARD_ITEM_ACTION',
    GET_CARD_ITEM_SUCCESS: 'GET_CARD_ITEM_SUCCESS',
    GET_CARD_ITEM_ERROR: 'GET_CARD_ITEM_ERROR',
    GET_CARD_ITEM_RESET: 'GET_CARD_ITEM_RESET',

    MIGRATION_TENANT_LIST_ACTION: 'MIGRATION_TENANT_LIST_ACTION',
    MIGRATION_TENANT_LIST_SUCCESS: 'MIGRATION_TENANT_LIST_SUCCESS',
    MIGRATION_TENANT_LIST_ERROR: 'MIGRATION_TENANT_LIST_ERROR',
    MIGRATION_TENANT_LIST_RESET: 'MIGRATION_TENANT_LIST_RESET',
  },

  MIGRATIONS_DB: {
    MIGRATION_LOADING: 'MIGRATION_LOADING',
    MIGRATION_ADMISSION_DB_ACTION: 'MIGRATION_ADMISSION_DB_ACTION',
    MIGRATION_ADMISSION_DB_SUCCESS: 'MIGRATION_ADMISSION_DB_SUCCESS',
    MIGRATION_ADMISSION_DB_ERROR: 'MIGRATION_ADMISSION_DB_ERROR',
    MIGRATION_ADMISSION_DB_RESET: 'MIGRATION_ADMISSION_DB_RESET',

    MIGRATION_EXAM_DB_ACTION: 'MIGRATION_EXAM_DB_ACTION',
    MIGRATION_EXAM_DB_SUCCESS: 'MIGRATION_EXAM_DB_SUCCESS',
    MIGRATION_EXAM_DB_ERROR: 'MIGRATION_EXAM_DB_ERROR',
    MIGRATION_EXAM_DB_RESET: 'MIGRATION_EXAM_DB_RESET',

    MIGRATION_ATTENDANCE_DB_ACTION: 'MIGRATION_ATTENDANCE_DB_ACTION',
    MIGRATION_ATTENDANCE_DB_SUCCESS: 'MIGRATION_ATTENDANCE_DB_SUCCESS',
    MIGRATION_ATTENDANCE_DB_ERROR: 'MIGRATION_ATTENDANCE_DB_ERROR',
    MIGRATION_ATTENDANCE_DB_RESET: 'MIGRATION_ATTENDANCE_DB_RESET',

    MIGRATION_USERMANAGEMENT_DB_ACTION: 'MIGRATION_USERMANAGEMENT_DB_ACTION',
    MIGRATION_USERMANAGEMENT_DB_SUCCESS: 'MIGRATION_USERMANAGEMENT_DB_SUCCESS',
    MIGRATION_USERMANAGEMENT_DB_ERROR: 'MIGRATION_USERMANAGEMENT_DB_ERROR',
    MIGRATION_USERMANAGEMENT_DB_RESET: 'MIGRATION_USERMANAGEMENT_DB_RESET',
  },
};
