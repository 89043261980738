export const programContentApi = {
  getProgramById: '/v2/program',
  getMappedDepartmentList: '/v2/department',
  getPrgoramListforFaculty: '/v2/program',
  getMappedYearList: '/v2/program-year',
  getMappedSemesterList: '/v2/semester',
  getSubjectStats: '/v2/subject-contents',
  getSubjectList: '/v2/subject',
  getChapterListBySubject: '/v2/chapters',
  getChapterContent: '/v2/chapter-contents',
  addPracticalContent: '/v2/content/practical',
  videoContent: '/v2/content/videos',
  fileUpload: '/v1/admission/file',
  addReading: '/v2/content/reading',
  chapterApi: '/v2/chapters',
  liveSessionApi: '/v2/content/live_sessions',
  facultyListBySubjectId: '/v2/user-mapping',
  getUserDepartment: '/v2/user-departments',
  getUserProgramsList: '/v2/user-subjects',
  getLabList: '/v2/learning_practical',
  launcVmLab: '/v2/launch_VM',
};
