import * as React from 'react';

const Chat = ({ fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 96 960 960"
    width="24"
    fill={fill}
    {...rest}
  >
    <path d="M240 656h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80 976V256q0-33 23.5-56.5T160 176h640q33 0 56.5 23.5T880 256v480q0 33-23.5 56.5T800 816H240L80 976Zm80-193 47-47h593V256H160v527Zm0-527v527-527Z" />
  </svg>
);

export default Chat;
