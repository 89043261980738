import React, { useState } from 'react';

const EditIconBlueSvg = ({ height = '24', width = '24', className }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const fillColor = isHovered ? '#AC0521' : '#02387A';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      className={className}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      {/* <title>Edit</title> */}
      <path
        d="M200 856h56l345-345-56-56-345 345v56Zm572-403L602 285l56-56q23-23 56.5-23t56.5 23l56 56q23 23 24 55.5T829 396l-57 57Zm-58 59L290 936H120V766l424-424 170 170Zm-141-29-28-28 56 56-28-28Z"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};

export default EditIconBlueSvg;
