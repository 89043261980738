import { groupChatActions } from './Groupchat.constants';
const { GROUPCHAT } = groupChatActions;

export const getCandidateList = (payload) => ({
  type: GROUPCHAT.GET_CANDIDATE_LIST,
  payload: payload,
});

export const getCandidateListSuccess = (payload) => ({
  type: GROUPCHAT.GET_CANDIDATE_LIST_SUCCESS,
  payload: payload,
});

export const getCandidateListFail = (payload) => ({
  type: GROUPCHAT.GET_CANDIDATE_LIST_FAIL,
  payload: payload,
});

export const getCandidateListReset = (payload) => ({
  type: GROUPCHAT.GET_CANDIDATE_LIST_RESET,
  payload: payload,
});

export const createGroup = (payload) => ({
  type: GROUPCHAT.CREATE_GROUP,
  payload: payload,
});

export const createGroupSuccess = (payload) => ({
  type: GROUPCHAT.CREATE_GROUP_SUCCESS,
  payload: payload,
});

export const createGroupFail = (payload) => ({
  type: GROUPCHAT.CREATE_GROUP_FAIL,
  payload: payload,
});

export const resetGroupChatReducer = (payload) => ({
  type: GROUPCHAT.RESET_GROUP_CHAT_REDUCER,
  payload: payload,
});

export const addCandidateToGroup = (payload) => ({
  type: GROUPCHAT.ADD_CANDIDATE_TO_GROUP,
  payload: payload,
});

export const addCandidateToGroupSuccess = (payload) => ({
  type: GROUPCHAT.ADD_CANDIDATE_TO_GROUP_SUCCESS,
  payload: payload,
});

export const addCandidateToGroupFail = (payload) => ({
  type: GROUPCHAT.ADD_CANDIDATE_TO_GROUP_FAIL,
  payload: payload,
});

export const addCandidateToGroupReset = (payload) => ({
  type: GROUPCHAT.ADD_CANDIDATE_TO_GROUP_RESET,
  payload: payload,
});

export const getGroups = (payload) => ({
  type: GROUPCHAT.GET_GROUPS,
  payload: payload,
});

export const getGroupsSuccess = (payload) => ({
  type: GROUPCHAT.GET_GROUPS_SUCCESS,
  payload: payload,
});

export const getGroupsFail = (payload) => ({
  type: GROUPCHAT.GET_GROUPS_FAIL,
  payload: payload,
});

export const getGroupCandidateList = (payload) => ({
  type: GROUPCHAT.GET_GROUP_CANDIDATE_LIST,
  payload: payload,
});

export const getGroupCandidateListSuccess = (payload) => ({
  type: GROUPCHAT.GET_GROUP_CANDIDATE_LIST_SUCCESS,
  payload: payload,
});

export const getGroupCandidateListFail = (payload) => ({
  type: GROUPCHAT.GET_GROUP_CANDIDATE_LIST_FAIL,
  payload: payload,
});

export const getGroupCandidateListReset = (payload) => ({
  type: GROUPCHAT.GET_GROUP_CANDIDATE_LIST_RESET,
  payload: payload,
});

export const getChatList = (payload) => ({
  type: GROUPCHAT.GET_CHAT_LIST,
  payload: payload,
});

export const getChatListSuccess = (payload) => ({
  type: GROUPCHAT.GET_CHAT_LIST_SUCCESS,
  payload: payload,
});

export const getChatListFail = (payload) => ({
  type: GROUPCHAT.GET_CHAT_LIST_FAIL,
  payload: payload,
});

export const getChatListReset = (payload) => ({
  type: GROUPCHAT.GET_CHAT_LIST_RESET,
  payload: payload,
});

export const updateGroupData = (payload) => ({
  type: GROUPCHAT.UPDATE_GROUP_DATA,
  payload: payload,
});

export const updateGroupDataSuccess = (payload) => ({
  type: GROUPCHAT.UPDATE_GROUP_DATA_SUCCESS,
  payload: payload,
});

export const updateGroupDataFail = (payload) => ({
  type: GROUPCHAT.UPDATE_GROUP_DATA_FAIL,
  payload: payload,
});

export const updateGroupDataReset = (payload) => ({
  type: GROUPCHAT.UPDATE_GROUP_DATA_RESET,
  payload: payload,
});

export const updateChatData = (payload) => ({
  type: GROUPCHAT.UPDATE_CHAT_DATA,
  payload: payload,
});

export const updateChatDataSuccess = (payload) => ({
  type: GROUPCHAT.UPDATE_CHAT_DATA_SUCCESS,
  payload: payload,
});

export const updateChatDataFail = (payload) => ({
  type: GROUPCHAT.UPDATE_CHAT_DATA_FAIL,
  payload: payload,
});

export const updateChatDataReset = (payload) => ({
  type: GROUPCHAT.UPDATE_CHAT_DATA_RESET,
  payload: payload,
});
