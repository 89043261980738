import React from 'react';

const FileCopyIcon = ({ fill, height = 30, width = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      fill={fill}
    >
      <path d="M760 856H320q-33 0-56.5-23.5T240 776V216q0-33 23.5-56.5T320 136h280l240 240v400q0 33-23.5 56.5T760 856ZM560 416V216H320v560h440V416H560Zm-400 600q-33 0-56.5-23.5T80 936V376h80v560h440v80H160Zm160-800v200-200 560-560Z" />
    </svg>
  );
};

export default FileCopyIcon;
