import { takeLatest, put, call, all } from 'redux-saga/effects';
import { setToastMessage } from 'store/Toast';
import { statusCode } from 'constants/apis/apiStatus';
import {
  courseContentApi,
  courseContentActions,
  createCourseContentError,
  createCourseContentSuccess,
  createCourseSectionError,
  createCourseSectionSuccess,
  courseSectionApi,
  getSectionApi,
  getCourseSectionSuccess,
  getCourseSectionError,
  courseContentFeedbackLoader,
  deleteCourseSectionError,
  deleteCourseSectionSuccess,
  deleteSectionApi,
  updateCourseSectionError,
  updateCourseSectionSuccess,
  updateSectionApi,
  getCourseContentError,
  getCourseContentSuccess,
  getCourseContentApi,
  getCourseContentByIdError,
  getCourseContentByIdSuccess,
  getCourseContentByIdApi,
  updateCourseContentApi,
  updateCourseContentError,
  updateCourseContentSuccess,
  createQuestionApi,
  createQuestionError,
  createQuestionSuccess,
  uploadFileApi,
  fileUploadError,
  fileUploadSuccess,
  createDuplicateError,
  createDuplicateSuccess,
  createDuplicateApi,
  deleteContentError,
  deleteContentSuccess,
  deleteCourseContentApi,
  getQuestionSuccess,
  getQuestionError,
  getQuestionApi,
  getQuestionByIdError,
  getQuestionByIdSuccess,
  getQuestionByIdApi,
  updateQuestionError,
  updateQuestionSuccess,
  updateQuestionApi,
  publishCourseContentError,
  publishCourseContentSuccess,
  publishContentApi,
  getQuestionBySectionError,
  getQuestionBySectionSuccess,
  getQuestionbySectionApi,
  saveFeedbackError,
  saveFeedbackSuccess,
  saveFeedbackApi,
} from 'store/CourseContentFeedback';

function* createCourseContentSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(courseContentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createCourseContentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Course Content FeedBack creation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createCourseContentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(createCourseContentError(error));
  }
}
function* createCourseSectionSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(courseSectionApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createCourseSectionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Section creation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createCourseSectionError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    // yield put(createCourseSectionError(error));
  }
}
function* getCourseSectionSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(getSectionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseSectionSuccess(response?.data));
    } else {
      yield put(getCourseSectionError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(getCourseSectionError(error));
  }
}
function* deleteCourseSectionSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(deleteSectionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteCourseSectionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Section Deleted successful',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteCourseSectionError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(deleteCourseSectionError(error));
  }
}
function* updateCourseSectionSaga({ payload, req }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(updateSectionApi, payload, req);
    if (response?.status === statusCode.Ok200) {
      yield put(updateCourseSectionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Section Updated successful',
          severity: 'success',
        })
      );
    } else {
      yield put(updateCourseSectionError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(updateCourseSectionError(error));
  }
}
function* getCourseContentSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(getCourseContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseContentSuccess(response?.data));
    } else {
      yield put(getCourseContentError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(getCourseContentError(error));
  }
}
function* getCourseContentByIdSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(getCourseContentByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCourseContentByIdSuccess(response?.data));
      yield put(courseContentFeedbackLoader(false));
    } else {
      yield put(getCourseContentByIdError(response?.data));
    }
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(getCourseContentByIdError(error));
  }
}
function* updateCourseContentByIdSaga({ payload, req }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(updateCourseContentApi, payload, req);
    if (response?.status === statusCode.Ok200) {
      yield put(updateCourseContentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Course Content Updated successful',
          severity: 'success',
        })
      );
    } else {
      yield put(updateCourseContentError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(updateCourseContentError(error));
  }
}

function* createQuestionSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(createQuestionApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createQuestionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Question creation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(createQuestionError(response?.data));
      yield put(
        setToastMessage({
          data: 'Select Section Before Adding Question',
          severity: 'error',
        })
      );
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(createQuestionError(error));
    yield put(
      setToastMessage({
        data: 'Select Section Before Adding Question',
        severity: 'error',
      })
    );
  }
}
function* createDuplicateSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(createDuplicateApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createDuplicateSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Duplicate successfully created',
          severity: 'success',
        })
      );
    } else {
      yield put(createDuplicateError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(createDuplicateError(error));
  }
}
function* deleteContentSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(deleteCourseContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteContentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Course Content Deleted successful',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteContentError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(deleteContentError(error));
  }
}
function* getQuestionSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(getQuestionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getQuestionSuccess(response?.data));
    } else {
      yield put(getQuestionError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(getQuestionError(error));
  }
}
function* getQuestionByIdSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(getQuestionByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getQuestionByIdSuccess(response?.data));
    } else {
      yield put(getQuestionByIdError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(getQuestionByIdError(error));
  }
}
function* updateQuestionSaga({ payload, req }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(updateQuestionApi, payload, req);
    if (response?.status === statusCode.Ok200) {
      yield put(updateQuestionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Question Updated successful',
          severity: 'success',
        })
      );
    } else {
      yield put(updateQuestionError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(updateQuestionError(error));
  }
}
function* publishCourseContentSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(publishContentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(publishCourseContentSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Course Content FeedBack publish successful',
          severity: 'success',
        })
      );
    } else {
      yield put(publishCourseContentError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(publishCourseContentError(error));
  }
}
function* getQuestionBySectionSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(getQuestionbySectionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getQuestionBySectionSuccess(response?.data));
    } else {
      yield put(getQuestionBySectionError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(getQuestionBySectionError(error));
  }
}
function* saveFeedBackSaga({ payload }) {
  try {
    yield put(courseContentFeedbackLoader(true));
    const response = yield call(saveFeedbackApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(saveFeedbackSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Feedback successfully saved',
          severity: 'success',
        })
      );
    } else {
      yield put(saveFeedbackError(response?.data));
    }
    yield put(courseContentFeedbackLoader(false));
  } catch (error) {
    yield put(courseContentFeedbackLoader(false));
    yield put(saveFeedbackError(error));
  }
}

export function* watchDummy() {
  yield takeLatest(
    courseContentActions.COURSECONTENT.create,
    createCourseContentSaga
  );
  yield takeLatest(
    courseContentActions.COURSESECTION.create,
    createCourseSectionSaga
  );
  yield takeLatest(
    courseContentActions.GETSECTION.GET_SECTION_ACTION,
    getCourseSectionSaga
  );
  yield takeLatest(
    courseContentActions.DELETESECTION.DELETE_SECTION_ACTION,
    deleteCourseSectionSaga
  );
  yield takeLatest(
    courseContentActions.UPDATESECTION.UPDATE_SECTION_ACTION,
    updateCourseSectionSaga
  );
  yield takeLatest(
    courseContentActions.GETCOURSECONTENT.GET_COURSE_CONTENT_ACTION,
    getCourseContentSaga
  );
  yield takeLatest(
    courseContentActions.GETCOURSECONTENTBYID.GET_COURSE_CONTENT_BY_ID_ACTION,
    getCourseContentByIdSaga
  );
  yield takeLatest(
    courseContentActions.UPDATECOURSECONTENT.UPDATE_COURSE_CONTENT_ACTION,
    updateCourseContentByIdSaga
  );
  yield takeLatest(
    courseContentActions.CREATEQUESTION.CREATE_QUESTION_ACTION,
    createQuestionSaga
  );
  yield takeLatest(
    courseContentActions.CREATEDUPLICATE.CREATE_DUPLICATE_ACTION,
    createDuplicateSaga
  );
  yield takeLatest(
    courseContentActions.DELETECONTENT.DELETE_CONTENT_ACTION,
    deleteContentSaga
  );
  yield takeLatest(
    courseContentActions.GETQUESTION.GET_QUESTION_ACTION,
    getQuestionSaga
  );
  yield takeLatest(
    courseContentActions.GETQUESTIONBYID.GET_QUESTION_BY_ID_ACTION,
    getQuestionByIdSaga
  );
  yield takeLatest(
    courseContentActions.UPDATEQUESTION.UPDATE_QUESTION_ACTION,
    updateQuestionSaga
  );
  yield takeLatest(
    courseContentActions.PUBLISHCOURSECONTENT.PUBLISH_COURSE_CONTENT_ACTION,
    publishCourseContentSaga
  );
  yield takeLatest(
    courseContentActions.GETQUESTIONBYSECTION.GET_QUESTION_BY_SECTION_ACTION,
    getQuestionBySectionSaga
  );
  yield takeLatest(
    courseContentActions.SAVEFEEDBACK.SAVE_FEEDBACK_ACTION,
    saveFeedBackSaga
  );
}
export default function* CourseContentSaga() {
  yield all([watchDummy()]);
}
