import { ROUTES } from '../../../constants/routesConstants';

export const moocAdminHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },
  {
    label: t('headerLabels.learning'),
    type: 'menu',
  },
  {
    label: t('headerLabels.mooc'),
    type: 'menu',
  },
  {
    label: t('moocAdmin.moocReport'),
    link: `${ROUTES.MOOC_ADMIN.ROOT}${ROUTES.MOOC_ADMIN.MOOC_REPORT}`,
  },
];

export const superAdminHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },
  {
    label: t('headerLabels.manage'),
    type: 'menu',
  },
];
export const registrarHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },

  {
    label: t('headerLabels.manage'),
    type: 'menu',
  },
  {
    label: t('headerLabels.timeTable'),
    link: '/schedule',
  },
];

export const hodHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },

  {
    label: t('headerLabels.manage'),
    type: 'menu',
  },

  {
    label: t('headerLabels.timeTable'),
    link: '/schedule',
  },
  {
    label: t('headerLabels.gamifications'),
    link: ROUTES.GAMIFICATION.ROOT,
  },
];
export const facultyHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },

  {
    label: t('headerLabels.manage'),
    type: 'menu',
  },

  {
    label: t('headerLabels.timeTable'),
    link: '/schedule',
  },
  {
    label: t('headerLabels.gamifications'),
    link: ROUTES.GAMIFICATION.ROOT,
  },
];

export const studentHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },
  {
    label: t('myLearningHeader.title'),
    type: 'menu',
  },
  {
    label: t('headerLabels.manage'),
    type: 'menu',
  },

  {
    label: t('headerLabels.timeTable'),
    link: '/schedule',
  },
  {
    label: t('headerLabels.gamifications'),
    link: ROUTES.GAMIFICATION.ROOT,
  },
];

export const leadHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },
  {
    label: t('headerLabels.leads'),
    link: '/leads',
  },
];

export const candidateHeaderOptions = (t) => [
  {
    label: t('candidate.profile'),
    link: `${ROUTES.CANDIDATE.ROOT}${ROUTES.CANDIDATE.MY_PROFILE}`,
  },
  {
    label: t('candidate.myApplications'),
    link: `${ROUTES.CANDIDATE.ROOT}${ROUTES.CANDIDATE.MY_APPLICATIONS}`,
  },
];

export const verifierHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },
  {
    label: t('verifier.candidates'),
    link: `${ROUTES.VERIFIER.ROOT}${ROUTES.VERIFIER.CANDIDATES_LIST}`,
  },
];

export const counsellorHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },
  {
    label: t('verifier.candidates'),
    link: `${ROUTES.COUNSELLOR.ROOT}${ROUTES.COUNSELLOR.CANDIDATES_LIST}`,
  },
];

export const parentHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },

  {
    label: t('management.attendance'),
    link: '/attendance/student',
  },
  {
    label: t('evaluation.title'),
    link: '/evaluation',
    // link: ROUTES.EVALUATION.ROOT,
  },
  {
    label: t('headerLabels.timeTable'),
    link: '/schedule',
  },
  {
    label: t('headerLabels.gamifications'),
    link: '/gamification',
  },
];

export const hrHeaderOptions = (t) => [
  {
    label: t('dashboard.title'),
    link: ROUTES.DASHBOARD,
  },
  {
    label: t('headerLabels.manage'),
    type: 'menu',
  },
];
