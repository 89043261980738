export const courseManagementAPI = {
  cluster: '/v2/cluster',
  program: '/v2/program',
  programYear: '/v2/program-year',
  semester: '/v2/semester',
  subject: '/v2/subject',
  gradePoints: '/v2/grade-points',

  department: '/v2/department',

  createLiveSession: '/v2/content/live_sessions',

  getSessionById: '/v2/content/live_sessions',
  sessionUpdate: '/v2/content/live_sessions',

  getSessionForSelectedSubModuleId:
    '/api/AssessmentService/Session/GetSessionForSelectedSubModuleId',

  rubric: '/v2/rubric',
  cbc: '/v2/cbc',
  facultySubjectLink: '/v2/user-mapping',
  deleteFacultySubjectLink: '/v2/user-mapping/delete_user',
  getUserMappingByUserId: '/v2/user-mapping/get_user',
  updateUserToProgramMapping: '/v2/user-mapping/update_user',
  createJoinSessionForVirtutor: '/v2/content/join-session',

  createVisionForDepartment: '/v2/department-vision',
  createMissionForDepartment: '/v2/department-mission',

  universityVission: '/v2/university-vision',
  universityMission: '/v2/university-mission',

  courseOutcome: '/v2/course-outcome',
  sendApproval: '/v2/content-status',
  bulkDelete: '/v2/chapter-contents/bulk-delete-content',
};
