import React from 'react';

const FiiledCheckmarkWithCircle = ({ fill, height = 16, width = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <g
        id="Group_13293"
        data-name="Group 13293"
        transform="translate(-388.539 -906.039)"
      >
        <g
          id="Path_49597"
          data-name="Path 49597"
          transform="translate(388.539 906.039)"
          fill="#4baf4f"
        >
          <path
            d="M 9 17.5 C 4.313079833984375 17.5 0.5 13.68692016601562 0.5 9 C 0.5 4.313079833984375 4.313079833984375 0.5 9 0.5 C 13.68692016601562 0.5 17.5 4.313079833984375 17.5 9 C 17.5 13.68692016601562 13.68692016601562 17.5 9 17.5 Z"
            stroke="none"
          />
          <path
            d="M 9 1 C 4.588789939880371 1 1 4.588789939880371 1 9 C 1 13.41121006011963 4.588789939880371 17 9 17 C 13.41121006011963 17 17 13.41121006011963 17 9 C 17 4.588789939880371 13.41121006011963 1 9 1 M 9 0 C 13.97056007385254 0 18 4.029439926147461 18 9 C 18 13.97056007385254 13.97056007385254 18 9 18 C 4.029439926147461 18 0 13.97056007385254 0 9 C 0 4.029439926147461 4.029439926147461 0 9 0 Z"
            stroke="none"
            fill="#fafafb"
          />
        </g>
        <path
          id="check_FILL0_wght400_GRAD0_opsz48"
          d="M10.38,17.823,7.7,15.143l.514-.514,2.165,2.165L14.974,12.2l.514.514Z"
          transform="translate(385.719 900.775)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default FiiledCheckmarkWithCircle;
