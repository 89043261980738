import React, { useState } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  IconButton,
  MenuItem,
} from 'iiht-b2a-ui-components/build/components';
import { Ellipses } from 'icons';
import { theme } from 'themes';

export const EllipsisOptionComponent = ({
  options,
  record,
  value,
  className,
  clickAction = () => {},
  icon,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (option) => {
    handleClose();
    option?.actionFunction(record, value?.row);
  };
  return (
    <div className={className}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={className}
      >
        {icon || <Ellipses />}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options?.map((option, index) => (
          <MenuItem
            disabled={option?.disabled}
            key={index}
            onClick={() => onMenuItemClick(option)}
            classes={option?.className}
          >
            <ListItemIcon
              sx={{
                fill: theme.palette.secondary.main,
                '& :hover': { fill: theme.palette.primary.main },
              }}
            >
              {option?.icon}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  ':hover': { color: theme.palette.primary.main },
                  fontSize: '14px',
                },
              }}
            >
              {option?.text}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
