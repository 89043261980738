export const scheduleActions = {
  COURSE_SCHEDULE: {
    get: 'GET_COURSE_SCHEDULE',
    get_success: 'GET_COURSE_SCHEDULE_SUCCESS',
    get_error: 'GET_COURSE_SCHEDULE_ERROR',
  },
  MODULE_SCHEDULE: {
    get: 'GET_MODULE_SCHEDULE',
    get_success: 'GET_MODULE_SCHEDULE_SUCCESS',
    get_error: 'GET_MODULE_SCHEDULE_ERROR',
  },
  SCHEDULE_APPROVALS: {
    get: 'GET_SCHEDULE_APPROVALS',
    get_success: 'GET_SCHEDULE_APPROVALS_SUCCESS',
    get_error: 'GET_SCHEDULE_APPROVALS_ERROR',
    get_by_scroll: 'GET_SCHEDULE_APPROVALS_BY_SCROLL',
    get_by_scroll_success: 'GET_SCHEDULE_APPROVALS_BY_SCROLL_SUCCESS',
    get_by_scroll_error: 'GET_SCHEDULE_APPROVALS_BY_SCROLL_ERROR',
    reset: 'RESET_SCHEDULE_APPROVALS',
    put_status: 'PUT_SCHEDULE_STATUS',
    put_status_success: 'PUT_SCHEDULE_STATUS_SUCCESS',
    put_status_error: 'PUT_SCHEDULE_STATUS_ERROR',
  },
  USER_TIMETABLE: {
    get: 'GET_TIME_TABLE',
    get_success: 'GET_TIME_TABLE_SUCCESS',
    get_error: 'GET_TIME_TABLE_ERROR',
    reset: 'RESET_TIMETABLE',
    create: 'CREATE_TIME_TABLE',
    create_success: 'GET_TIME_TABLE_SUCCESS',
    create_error: 'GET_TIME_TABLE_ERROR',
    get_faculty: 'GET_FACULTY_TIMETABLE',
    get_faculty_success: 'GET_FACULTY_TIMETABLE_SUCCESS',
    get_faculty_error: 'GET_FACULTY_TIMETABLE_ERROR',
    reset_faculty: 'RESET_FACULTY_SCHEDULE',
  },
  STUDENT_CONNECT: {
    get: 'GET_PAST_CONNECTIONS',
    get_success: 'GET_SUCCESS_PAST_CONNECTIONS',
    get_error: 'GET_ERROR_PAST_CONNECTIONS',
    delete: 'DELETE_PAST_CONNECTION',
    delete_success: 'DELETE_SUCCESS_PAST_CONNECTION',
    delete_error: 'DELETE_ERROR_PAST_CONNECTION',
    reset_delete: 'RESET_DELETE_PAST_CONNECTION',
    create: 'CREATE_PAST_CONNECTION',
    create_success: 'CREATE_SUCCESS_PAST_CONNECTION',
    create_error: 'CREATE_ERROR_PAST_CONNECTION',
    reset_create: 'RESET_CREATE_PAST_CONNECTION',
    loading: 'PAST_CONNECTION_LOADING',
  },
  USERS_UNDER_DEPT: {
    get: 'GET_USERS_UNDER_DEPT',
    get_success: 'GET_USERS_UNDER_DEPT_SUCCESS',
    get_error: 'GET_USERS_UNDER_DEPT_ERROR',
    reset_users: 'RESET_USERS_UNDER_DEPT',
    get_staff: 'GET_STAFF_UNDER_DEPT',
    get_staff_success: 'GET_STAFF_UNDER_DEPT_SUCCESS',
    get_staff_error: 'GET_STAFF_UNDER_DEPT_ERROR',
  },
};
