import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormSelectField } from '../FormSelectField';
import { validationRules } from 'constants/validationRules';
import { useTranslation } from 'react-i18next';
import './SelectLanguage.scss';

const SelectLanguage = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language
  );

  const methods = useForm({
    defaultValues: {},
  });

  const languageOption = [
    { code: 'en', lang: 'English' },
    { code: 'hi', lang: 'Hindi' },
    { code: 'mr', lang: 'Marathi' },
    { code: 'kn', lang: 'Kannada' },
  ];

  const handleLanguageChange = (event) => {
    const selectedLanguageCode = event.target.value;
    setSelectedLanguage(selectedLanguageCode);
    i18n.changeLanguage(selectedLanguageCode);
    localStorage.setItem('selectedLanguage', selectedLanguageCode);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage && storedLanguage === selectedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <>
      <FormProvider {...methods}>
        <form className="mb2">
          <FormSelectField
            className="lang-select"
            name="select_lang"
            removeBorderAndOutline={true}
            rules={{
              required: validationRules.REQUIRED,
            }}
            variant="outlined"
            label=""
            size="small"
            fullWidth
            value={selectedLanguage}
            onChange={handleLanguageChange}
            options={languageOption.map((option) => ({
              label: option.lang,
              value: option.code,
            }))}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default SelectLanguage;
