import { CandidateAction } from './Candidate.constants';

export const setCandidateLoading = (payload) => ({
  type: CandidateAction.SET_CANDIDATE_LOADING,
  payload,
});

export const getCandidateCourseForm = (payload) => ({
  type: CandidateAction.GET_CANDIDATE_COURSE_FORM,
  payload,
});

export const getCandidateCourseFormSuccess = (payload) => ({
  type: CandidateAction.SET_CANDIDATE_COURSE_FORM_SUCCESS,
  payload,
});

export const getCandidateCourseFormError = (payload) => ({
  type: CandidateAction.SET_CANDIDATE_COURSE_FORM_ERROR,
  payload,
});

export const getCandidateCourseFormInfo = (payload) => ({
  type: CandidateAction.GET_CANDIDATE_COURSE_FORM_INFO,
  payload,
});

export const getCandidateCourseFormInfoSuccess = (payload) => ({
  type: CandidateAction.SET_CANDIDATE_COURSE_FORM_INFO_SUCCESS,
  payload,
});

export const getCandidateCourseFormInfoError = (payload) => ({
  type: CandidateAction.SET_CANDIDATE_COURSE_FORM_INFO_ERROR,
  payload,
});

export const postCandidateCourseFormInfo = (payload) => ({
  type: CandidateAction.POST_CANDIDATE_COURSE_FORM_INFO,
  payload: payload,
});

export const postCandidateCourseFormInfoSuccess = (payload) => ({
  type: CandidateAction.POST_CANDIDATE_COURSE_FORM_INFO_SUCCESS,
  payload: payload,
});

export const postCandidateCourseFormInfoError = (payload) => ({
  type: CandidateAction.POST_CANDIDATE_COURSE_FORM_INFO_ERROR,
  payload: payload,
});

export const updateCandidateCourseFormInfo = (
  payload,
  candidateFormInfoId
) => ({
  type: CandidateAction.UPDATE_CANDIDATE_COURSE_FORM_INFO,
  payload: payload,
  candidateFormInfoId: candidateFormInfoId,
});

export const updateCandidateCourseFormInfoSuccess = (payload) => ({
  type: CandidateAction.UPDATE_CANDIDATE_COURSE_FORM_INFO_SUCCESS,
  payload: payload,
});

export const updateCandidateCourseFormInfoError = (payload) => ({
  type: CandidateAction.UPDATE_CANDIDATE_COURSE_FORM_INFO_ERROR,
  payload: payload,
});

export const setCandidateProspectusLoading = (payload) => ({
  type: CandidateAction.SET_CANDIDATE_PROSPECTUS_LOADING,
  payload,
});

export const getCandidateCourseProspectus = (payload) => ({
  type: CandidateAction.GET_CANDIDATE_COURSE_PROSPECTUS,
  payload,
});

export const getCandidateCourseProspectusSuccess = (payload) => ({
  type: CandidateAction.SET_CANDIDATE_COURSE_PROSPECTUS_SUCCESS,
  payload,
});

export const getCandidateCourseProspectusError = (payload) => ({
  type: CandidateAction.SET_CANDIDATE_COURSE_PROSPECTUS_ERROR,
  payload,
});
