import axiosInstance from 'translator';
import { attendanceAPI } from './Attendance.apiEndpoints';

// Mark Attendance API:

export const createMarkReadAttendanceApi = async (payload) => {
  return axiosInstance
    .post(`${attendanceAPI?.markAttendance}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Update override attendance / Update Attendance API:

export const updateOverrideAttendanceApi = async (
  payload = '',
  params = ''
) => {
  return axiosInstance
    .put(`${attendanceAPI?.markAttendance}/${payload?.user_id}`, payload, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Apply leave

export const applyLeaveAttendanceApi = async (payload) => {
  return axiosInstance
    .put(
      `${attendanceAPI?.markAttendance}/${payload?.user_id}?start_date=${payload?.startDate}&end_date=${payload.endDate}`,
      payload?.apiBody
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Get Student Attendance :

export const getStudentAttendanceApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.markAttendance}/${payload?.user_id}`, {
      params: { freq: payload?.freq },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Get stud data based on session id

export const getStudentSessionAttendanceByIdApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.sessionAttendance}/${payload?.session_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Get session list based on filter

export const getSessionListByFilterAttendanceApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.sessionsList}/${payload?.user_id}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// post method for getting list of session according to meet type
export const postSessionListByFilterAttendanceApi = async (payload) => {
  return axiosInstance
    .post(`${attendanceAPI?.sessionsMeetList}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Session Attendance API: Used while initial entry for student while create session

export const postStudentsSessionAttendanceApi = async (payload) => {
  return axiosInstance
    .post(`${attendanceAPI?.sessionAttendance}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Session Attendance API: Used while initial entry for student while edit session

export const updateStudentsSessionAttendanceApi = async (payload) => {
  return axiosInstance
    .put(`${attendanceAPI?.sessionAttendance}/${payload?.session_id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Update multiple student attendance -  Bulk Attendance API:

export const updateBulkAttendanceApi = async (payload) => {
  return axiosInstance
    .put(`${attendanceAPI?.markBulkAttendance}/${payload?.session_id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const downloadAttandanceApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.sessionsList}/${payload?.user_id}`, {
      params: payload?.data,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getProgramAttendanceTabApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.sessionsList}`, {
      params: payload?.data,
    })
    .then((response) => response)
    .catch((error) => error);
};

export const getFacultyProgramListApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI.getFacultyProgramList}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getProgramAttendanceApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.sessionsList}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const downloadProgramAttendanceApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.downloadProgramAttendance}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getFacultyStudentAttendanceApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.getFacultyStudentAttendance}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

// Get live session list based on filter

export const getLiveSessionListByFilterApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.getFilterLiveSession}`, {
      params: payload.data,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const sendStudentAttendanceNotificationApi = async (payload) => {
  return axiosInstance
    .post(`${attendanceAPI.sendAttendanceStudentNotification}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getStudentParentEmailsApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI.getUserEmails}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

// get faculty student present count for dashboard

export const getAttendanceDetailsDashboardCardApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.getAttendanceDetailsDashboardCard}`, {
      params: payload,
    })
    .then((response) => response)
    .catch((error) => error);
};

//apply for leave

export const appyForLeaveApi = async (payload) => {
  return axiosInstance
    .post(`${attendanceAPI?.attendanceApi}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

//List to fetch students all request

export const fetchStudentAttendanceRequestApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.attendanceApi}`, { params: payload })
    .then((response) => response)
    .catch((error) => error);
};

export const getRequestByIdApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.attendanceApi}/${payload.id}`)
    .then((response) => response)
    .catch((error) => error);
};

// Approve Attendance
export const approveStudentAttendanceRequestActionAPI = async (payload) => {
  return axiosInstance
    .patch(`${attendanceAPI?.attendanceApi}/${payload?.req_id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

// Update API

export const updateRemarkApi = async (payload) => {
  return axiosInstance
    .put(`${attendanceAPI?.attendanceApi}/${payload?.req_id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//update leave request
export const updateLeaveRequestApi = async (payload) => {
  return axiosInstance
    .put(`${attendanceAPI?.attendanceApi}/${payload?.req_id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getOrdianceSettingApi = async (payload) => {
  return axiosInstance
    .get(`${attendanceAPI?.ordinanceSetting}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const updateOrdianceSettingApi = async (payload) => {
  return axiosInstance
    .put(`${attendanceAPI?.ordinanceSetting}/${payload?.id}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

// update attendance api
export const updateAttendanceApi = async (payload, params) => {
  return axiosInstance
    .patch(`${attendanceAPI?.markAttendance}/${payload?.user_id}`, payload, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Downlaod all attendance API
export const downloadAllRequestApi = async (payload) => {
  console.log('payload:', payload);
  return axiosInstance
    .get(`${attendanceAPI?.downloadAllRequest}`, {
      params: payload,
    })
    .then((response) => response)
    .catch((error) => error);
};
