export const attendanceActions = {
  ATTENDANCE: {
    download_attendance_request: 'DOWNLOAD_ATTENDANCE_REQUEST',
    download_attendance_success: 'DOWNLOAD_ATTENDANCE_SUCCESS',
    download_attendance_error: 'DOWNLOAD_ATTENDANCE_ERROR',

    create_attendance_request: 'CREATE_ATTENDANCE_REQUEST',
    create_attendance_success: 'CREATE_ATTENDANCE_SUCCESS',
    create_attendance_error: 'CREATE_ATTENDANCE_ERROR',

    get_sessions_by_date_request: 'SESSION_BY_DATE_REQUEST',
    get_sessions_by_date_success: 'SESSION_BY_DATE_SUCCESS',
    get_sessions_by_date_error: 'SESSION_BY_DATE_ERROR',

    update_bulk_attendance_request: 'MARK_ATTENDANCE_REQUEST',
    update_bulk_attendance_success: 'MARK_ATTENDANCE_SUCCESS',
    update_bulk_attendance_error: 'MARK_ATTENDANCE_ERROR',
    update_bulk_attendance_reset: 'MARK_ATTENDANCE_RESET',

    get_session_attendance_request: 'SESSION_ATTENDANCE_REQUEST',
    get_session_attendance_success: 'SESSION_ATTENDANCE_SUCCESS',
    get_session_attendance_error: 'SESSION_ATTENDANCE_ERROR',

    get_student_attendance_request: 'GET_STUDENT_ATTENDANCE_REQUEST',
    get_student_attendance_success: 'GET_STUDENT_ATTENDANCE_SUCCESS',
    get_student_attendance_error: 'GET_STUDENT_ATTENDANCE_ERROR',

    get_student_session_attendance_request:
      'GET_STUDENT_SESSION_ATTENDANCE_REQUEST',
    get_student_session_attendance_success:
      'GET_STUDENT_SESSION_ATTENDANCE_SUCCESS',
    get_student_session_attendance_error:
      'GET_STUDENT_SESSION_ATTENDANCE_ERROR',

    get_session_list_filter_request: 'GET_SESSION_LIST_FILTER_REQUEST',
    get_session_list_filter_success: 'GET_SESSION_LIST_FILTER_SUCCESS',
    get_session_list_filter_error: 'GET_SESSION_LIST_FILTER_ERROR',
    post_session_list_filter_request: 'POST_SESSION_LIST_FILTER_REQUEST',
    post_session_list_filter_success: 'POST_SESSION_LIST_FILTER_SUCCESS',
    post_session_list_filter_error: 'POST_SESSION_LIST_FILTER_ERROR',

    post_students_session_attendance_request:
      'POST_STUDENTS_SESSION_ATTENDANCE_REQUEST',
    post_students_session_attendance_success:
      'POST_STUDENTS_SESSION_ATTENDANCE_SUCCESS',
    post_students_session_attendance_error:
      'POST_STUDENTS_SESSION_ATTENDANCE_ERROR',

    put_students_session_attendance_request:
      'PUT_STUDENTS_SESSION_ATTENDANCE_REQUEST',
    put_students_session_attendance_success:
      'PUT_STUDENTS_SESSION_ATTENDANCE_SUCCESS',
    put_students_session_attendance_error:
      'PUT_STUDENTS_SESSION_ATTENDANCE_ERROR',
    download_faculty_attendance_request: 'DOWNLOAD_FACULTY_ATTENDANCE_REQUEST',
    download_faculty_attendance_success: 'DOWNLOAD_FACULTY_ATTENDANCE_SUCCESS',
    download_faculty_attendance_error: 'DOWNLOAD_FACULTY_ATTENDANCE_SUCCESS',
    download_faculty_attendance_reset: 'DOWNLOAD_FACULTY_ATTENDANCE_RESET',

    get_faculty_program_list_request: 'GET_FACULTY_PROGRAM_LIST_REQUEST',
    get_faculty_program_list_success:
      'GET_FACULTY_PROGRAM_LIST_REQUEST_SUCCESS',
    get_faculty_program_list_error: 'GET_FACULTY_PROGRAM_LIST_REQUEST_ERROR',
    get_program_attendance_request: 'GET_PROGRAM_ATTENDANCE_REQUEST',
    get_program_attendance_success: 'GET_PROGRAM_ATTENDANCE_SUCCESS',
    get_program_attendance_error: 'GET_PROGRAM_ATTENDANCE_ERROR',
    download_program_attendance_request: 'DOWNLOAD_PROGRAM_ATTENDANCE_REQUEST',
    download_program_attendance_success: 'DOWNLOAD_PROGRAM_ATTENDANCE_SUCCESS',
    download_program_attendance_error: 'DOWNLOAD_PROGRAM_ATTENDANCE_ERROR',
    get_faculty_student_attendance_request:
      'GET_FACULTY_STUDENT_ATTENDANCE_REQUEST',
    get_faculty_student_attendance_success:
      'GET_FACULTY_STUDENT_ATTENDANCE_SUCCESS',
    get_faculty_student_attendance_error:
      'GET_FACULTY_STUDENT_ATTENDANCE_ERROR',
    get_faculty_student_attendance_reset:
      'GET_FACULTY_STUDENT_ATTENDANCE_RESET',

    //get live session based on start date

    GET_LIVE_SESSION_BY_FILTER_REQUEST: 'GET_LIVE_SESSION_BY_FILTER_REQUEST',
    GET_LIVE_SESSION_BY_FILTER_SUCCESS: 'GET_LIVE_SESSION_BY_FILTER_SUCCESS',
    GET_LIVE_SESSION_BY_FILTER_ERROR: 'GET_LIVE_SESSION_BY_FILTER_ERROR',
    GET_LIVE_SESSION_BY_FILTER_RESET: 'GET_LIVE_SESSION_BY_FILTER_RESET',

    // Apply leave put
    APPLY_LEAVE_PUT_REQUEST: 'APPLY_LEAVE_PUT_REQUEST',
    APPLY_LEAVE_PUT_SUCCESS: 'APPLY_LEAVE_PUT_SUCCESS',
    APPLY_LEAVE_PUT_ERROR: 'APPLY_LEAVE_PUT_ERROR',
    APPLY_LEAVE_PUT_RESET: 'APPLY_LEAVE_PUT_RESET',

    send_student_attendance_notification: 'SEND_STUDET_ATTENDANCE_NOTIFICATION',
    send_student_attendance_notification_success:
      'SEND_STUDET_ATTENDANCE_NOTIFICATION_SUCCESS',
    send_student_attendance_notification_error:
      'SEND_STUDET_ATTENDANCE_NOTIFICATION_ERROR',
    send_student_attendance_notification_loader:
      'SEND_STUDET_ATTENDANCE_NOTIFICATION_LOADER',
    send_student_attendance_notification_reset:
      'SEND_STUDET_ATTENDANCE_NOTIFICATION_RESET',

    get_student_parent_emails: 'GET_STUDENT_PARANT_EMAILS',
    get_student_parent_emails_success: 'GET_STUDENT_PARANT_EMAILS_SUCCESS',
    get_student_parent_emails_error: 'GET_STUDENT_PARANT_EMAILS_ERROR',

    // get faculty student present count for dashboard
    GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_REQUEST:
      'GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_REQUEST',
    GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_SUCCESS:
      'GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_SUCCESS',
    GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_ERROR:
      'GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_ERROR',
    GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_RESET:
      'GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_RESET',
    SET_FETCH_STUDENT_ATTENDANCE_LOADING:
      'SET_FETCH_STUDENT_ATTENDANCE_LOADING',

    //Apply for leave in student flow
    APPLY_FOR_LEAVE: 'APPLY_FOR_LEAVE',
    APPLY_FOR_LEAVE_SUCCESS: 'APPLY_FOR_LEAVE_SUCCESS',
    APPLY_FOR_LEAVE_ERROR: 'APPLY_FOR_LEAVE_ERROR',

    //get my leave request in student
    FETCH_STUDENT_ATTENDANCE_REQUEST: 'FETCH_STUDENT_ATTENDANCE_REQUEST',
    SET_FETCH_STUDENT_ATTENDANCE_SUCCESS:
      'SET_FETCH_STUDENT_ATTENDANCE_SUCCESS',
    SET_FETCH_STUDENT_ATTENDANCE_ERROR: 'SET_FETCH_STUDENT_ATTENDANCE_ERROR',
    SET_FETCH_STUDENT_ATTENDANCE_RESET: 'SET_FETCH_STUDENT_ATTENDANCE_RESET',

    //get request by id
    GET_REQUEST_BY_ID: 'GET_REQUEST_BY_ID',
    SET_GET_REQUEST_BY_ID_SUCCESS: 'SET_GET_REQUEST_BY_ID_SUCCESS',
    SET_GET_REQUEST_BY_ID_ERROR: 'SET_GET_REQUEST_BY_ID_ERROR',
    RESET_REQUEST_BY_ID: 'RESET_REQUEST_BY_ID',

    // Approve Attendance
    FACULTY_STUDENT_APPROVE_REQUEST: 'FACULTY_STUDENT_APPROVE_REQUEST',
    FACULTY_STUDENT_APPROVE_SUCCESS: 'FACULTY_STUDENT_APPROVE_SUCCESS',
    FACULTY_STUDENT_APPROVE_ERROR: 'FACULTY_STUDENT_APPROVE_ERROR',
    FACULTY_STUDENT_APPROVE_RESET: 'FACULTY_STUDENT_APPROVE_RESET',

    //update Remark
    // Approve Attendance
    UPDATE_REMARK_REQUEST: 'UPDATE_REMARK_REQUEST',
    UPDATE_REMARK_SUCCESS: 'UPDATE_REMARK_SUCCESS',
    UPDATE_REMARKE_ERROR: 'UPDATE_REMARKE_ERROR',
    UPDATE_REMARK_RESET: 'UPDATE_REMARK_RESET',

    //edit leave request
    UPDATE_MY_REQUEST: 'UPDATE_MY_REQUEST',
    SET_UPDATE_MY_REQUEST_SUCCESS: 'SET_UPDATE_MY_REQUEST_SUCCESS',
    SET_UPDATE_MY_REQUEST_ERROR: 'SET_UPDATE_MY_REQUEST_ERROR',

    //ordinance-setting
    GET_ORDINANCE_SETTING_REQUEST: 'GET_ORDINANCE_SETTING_REQUEST',
    SET_ORDINANCE_SETTING_REQUEST_SUCCESS:
      'SET_ORDINANCE_SETTING_REQUEST_SUCCESS',
    SET_ORDINANCE_SETTING_REQUEST_ERROR: 'SET_ORDINANCE_SETTING_REQUEST_ERROR',

    UPDATE_ORDINANCE_SETTING_REQUEST: 'UPDATE_ORDINANCE_SETTING_REQUEST',
    SET_UPDATE_ORDINANCE_SETTING_REQUEST_SUCCESS:
      'SET_UPDATE_ORDINANCE_SETTING_REQUEST_SUCCESS',
    SET_UPDATE_ORDINANCE_SETTING_REQUEST_ERROR:
      'SET_UPDATE_ORDINANCE_SETTING_REQUEST_ERROR',

    // Update attendance
    UPDATE_ATTENDACE_REQUEST: 'UPDATE_ATTENDACE_REQUEST',
    UPDATE_ATTENDACE_SUCCESS: 'UPDATE_ATTENDACE_SUCCESS',
    UPDATE_ATTENDACE_ERROR: 'UPDATE_ATTENDACE_ERROR',

    // Download Attendance
    DOWNLOAD_ALL_REQUEST: 'DOWNLOAD_ALL_REQUEST',
    DOWNLOAD_ALL_SUCCESS: 'DOWNLOAD_ALL_SUCCESS',
    DOWNLOAD_ALL_ERROR: 'DOWNLOAD_ALL_ERROR',
    DOWNLOAD_ALL_RESET: 'DOWNLOAD_ALL_RESET',
  },
};
