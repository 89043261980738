import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  userProfileActions,
  getUserProfileApi,
  getUserProfileSuccess,
  getUserProfileError,
  updateUserProfileApi,
  getSocialInformationsApi,
  getSocialInformationsSuccess,
  getSocialInformationsError,
  postSocialDetailsApi,
  createSocialDetailsSuccess,
  createSocialDetailsError,
  editUserProfileSuccess,
  editUserProfileError,
  successProfileUploadImage,
  errorProfileUploadImage,
  createChangePasswordApi,
  createSuccessChangePassword,
  createErrorChangePassword,
} from 'store/UserProfile';
import { statusCode } from 'constants/apis/apiStatus';
import { uploadUserImageApi } from 'store/UserManagement';
import { setToastMessage } from 'store/Toast';

function* getUserProfileSaga(payload) {
  try {
    const response = yield call(getUserProfileApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUserProfileSuccess(response?.data));
    } else {
      yield put(getUserProfileError(response?.data));
    }
  } catch (error) {
    yield put(getUserProfileError(error?.data));
  }
}

function* updateUserProfileSaga({ payload, data, file }) {
  try {
    const response = yield call(updateUserProfileApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(editUserProfileSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
      if (file) {
        const resp = yield call(uploadUserImageApi, data, file);
        if (resp?.status === statusCode.Ok200) {
          yield put(successProfileUploadImage(resp?.data));
        } else {
          yield put(errorProfileUploadImage(resp?.data));
        }
      }
    } else {
      yield put(editUserProfileError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* createChangePasswordSaga({ payload }) {
  try {
    const response = yield call(createChangePasswordApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(createSuccessChangePassword(true));
      yield put(
        setToastMessage({
          data: '',
          severity: 'success',
        })
      );
    } else {
      yield put(createErrorChangePassword(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* getSocialInformationsSaga({ payload }) {
  try {
    const response = yield call(getSocialInformationsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSocialInformationsSuccess(response?.data));
    } else {
      yield put(getSocialInformationsError(response?.data));
    }
  } catch (error) {
    yield put(getSocialInformationsError(error?.data));
  }
}

function* createSocialDetailsSaga({ payload }) {
  const updateSocialDetailsSuccess = 'Social handles updated';
  const updateSocialDetailsFailed = 'Failed to update data';
  try {
    yield put(createSocialDetailsSuccess(true));
    const response = yield call(postSocialDetailsApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createSocialDetailsSuccess(response?.data));
      yield put(
        setToastMessage({
          data: updateSocialDetailsSuccess,
          severity: 'success',
        })
      );
    } else {
      yield put(createSocialDetailsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? updateSocialDetailsFailed,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: response?.data?.error?.message ?? updateSocialDetailsFailed,
        severity: 'error',
      })
    );
  }
}
export function* watchDummy() {
  yield takeLatest(userProfileActions.USER_PROFILE.get, getUserProfileSaga);
  yield takeLatest(
    userProfileActions.USER_PROFILE.update,
    updateUserProfileSaga
  );
  yield takeLatest(
    userProfileActions.SOCIAL_HANDLE.get,
    getSocialInformationsSaga
  );
  yield takeLatest(
    userProfileActions.SOCIAL_HANDLE.create,
    createSocialDetailsSaga
  );
  yield takeLatest(
    userProfileActions.USER_PROFILE.create,
    createChangePasswordSaga
  );
}

export default function* UserProfileSaga() {
  yield all([watchDummy()]);
}
