import axiosInstance from 'translator';
import { scheduleApi } from 'store/Schedule';

export const getCourseScheduleApi = async (payload) => {
  return axiosInstance
    .get(`${scheduleApi?.getCourseSchedule}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getModuleScheduleApi = async (payload) => {
  return axiosInstance
    .get(`${scheduleApi?.getModuleSchedule}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTimeTableApi = async (payload) => {
  return axiosInstance
    .get(`${scheduleApi?.getTimeTableForUser}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getScheduleApprovalsApi = async (payload) => {
  return axiosInstance
    .get(`${scheduleApi?.getScheduleApprovals}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postTimeTableApi = async (payload) => {
  return axiosInstance
    .post(`${scheduleApi?.postTimeTable}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getFacultyTimeTableApi = async (payload) => {
  return axiosInstance
    .get(`${scheduleApi?.getFacultySchedule}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const putScheduleStatusApi = async (payload) => {
  return axiosInstance
    .put(`${scheduleApi?.putScheduleStatus}`, {
      params: {
        timeTableId: payload?.timeTableId,
        isApproveTimeTable: payload?.isApproveTimeTable,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPastConnectionsListApi = async (payload) => {
  return axiosInstance
    .get(`${scheduleApi?.getPastConnections}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deletePastConnectionApi = async (payload) => {
  return axiosInstance
    .delete(`${scheduleApi?.deletePastConnection}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createPastConnectionApi = async (payload) => {
  return axiosInstance
    .post(`${scheduleApi?.createPastConnection}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDeptUsersApi = async (payload) => {
  return axiosInstance
    .get(`${scheduleApi?.getDeptUsers}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStaffUnderDeptApi = async (payload) => {
  return axiosInstance
    .get(`${scheduleApi?.getStaffOfDept}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
