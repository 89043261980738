import React from 'react';

const ChevronLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="m14 18-6-6 6-6 1.4 1.4-4.6 4.6 4.6 4.6Z" />
    </svg>
  );
};

export default ChevronLeft;
