import { toastActions } from './Toast.constants';

export const setToastMessage = (data) => {
  return {
    type: toastActions.SET_TOAST_MESSAGE,
    payload: data,
  };
};

export const resetToastMessage = () => {
  return {
    type: toastActions.RESET_TOAST_MESSAGE,
  };
};
