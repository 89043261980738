import { programContentActions } from './ProgramContent.constants';
const {
  GET_MAPPED_DEPARTMENT_LIST,
  GET_MAPPED_YEAR_LIST,
  GET_PROGRAM_LIST_FOR_FACULTY,
  GET_SUBJECT_STATS,
  GET_MAPPED_SEMESTER_LIST,
  GET_CHAPTER_LIST_BY_SUBJECT,
  GET_MAPPED_SUBJECT_LIST,
  GET_CHAPTER_CONTENT,
  PRACTICAL_CONTENT,
  VIDEO_CONTENT,
  GET_PROGRAM_DETAILS,
  LOADING,
  ADD_CHAPTER,
  GET_CHAPTER_DETAILS_BY_ID,
  UPDATE_CHAPTER,
  READING,
  DELETE_CHAPTER,
  DELETE_LIVE_SESSION,
  GET_FACULTY_LIST_BY_SUBJECT_ID,
  GET_USER_DEPARTMENTS,
  GET_USER_BASED_PROGRAM,
  VIRTUAL_LAB,
  CREATE_VM,
  GET_VM_STATE,
} = programContentActions;

export const getMappedDepartmentList = (payload) => ({
  type: GET_MAPPED_DEPARTMENT_LIST.get,
  payload: payload,
});

export const getMappedDepartmentListSuccess = (payload) => ({
  type: GET_MAPPED_DEPARTMENT_LIST.get_success,
  payload: payload,
});

export const getMappedDepartmentListError = (payload) => ({
  type: GET_MAPPED_DEPARTMENT_LIST.get_error,
  payload: payload,
});

export const getProgramForFacultyAction = (payload) => ({
  type: GET_PROGRAM_LIST_FOR_FACULTY.get,
  payload: payload,
});

export const getProgramForFacultySuccess = (payload) => ({
  type: GET_PROGRAM_LIST_FOR_FACULTY.get_success,
  payload: payload,
});

export const getProgramForFacultyError = (payload) => ({
  type: GET_PROGRAM_LIST_FOR_FACULTY.get_error,
  payload: payload,
});

export const getYearListAction = (payload) => ({
  type: GET_MAPPED_YEAR_LIST.get,
  payload: payload,
});

export const getYearListSuccess = (payload) => ({
  type: GET_MAPPED_YEAR_LIST.get_success,
  payload: payload,
});

export const getYearListError = (payload) => ({
  type: GET_MAPPED_YEAR_LIST.get_error,
  payload: payload,
});

export const getSubjectStatsAction = (params, payload) => ({
  type: GET_SUBJECT_STATS.get,
  payload: payload,
  params: params,
});

export const getSubjectStatsSuccess = (payload) => ({
  type: GET_SUBJECT_STATS.get_success,
  payload: payload,
});

export const getSubjectStatsError = (payload) => ({
  type: GET_SUBJECT_STATS.get_error,
  payload: payload,
});

export const getSemesterListAction = (payload) => ({
  type: GET_MAPPED_SEMESTER_LIST.get,
  payload: payload,
});

export const getSemesterListSuccess = (payload) => ({
  type: GET_MAPPED_SEMESTER_LIST.get_success,
  payload: payload,
});

export const getSemesterListError = (payload) => ({
  type: GET_MAPPED_SEMESTER_LIST.get_error,
  payload: payload,
});

// Get subject List

export const getSubjectListAction = (payload) => ({
  type: GET_MAPPED_SUBJECT_LIST.get,
  payload: payload,
});

export const getSubjectListSuccess = (payload) => ({
  type: GET_MAPPED_SUBJECT_LIST.get_success,
  payload: payload,
});

export const getSubjectListError = (payload) => ({
  type: GET_MAPPED_SUBJECT_LIST.get_error,
  payload: payload,
});
export const getSubjectListReset = (payload) => ({
  type: GET_MAPPED_SUBJECT_LIST.get_reset,
  payload: payload,
});

// Get Chapeter on subject id

export const getChapterListBySubjectRequest = (payload) => ({
  type: GET_CHAPTER_LIST_BY_SUBJECT.get,
  payload: payload,
});

export const getChapterListBySubjectSuccess = (payload) => ({
  type: GET_CHAPTER_LIST_BY_SUBJECT.get_success,
  payload: payload,
});

export const getChapterListBySubjectError = (payload) => ({
  type: GET_CHAPTER_LIST_BY_SUBJECT.get_error,
  payload: payload,
});

export const resetChapterListBySubject = () => ({
  type: GET_CHAPTER_LIST_BY_SUBJECT.reset,
});

export const getChapterListBySubjectLoader = (payload) => ({
  type: GET_CHAPTER_LIST_BY_SUBJECT.loader,
  payload: payload,
});

// Get chapter content

export const getChapterContentRequest = (payload, params) => ({
  type: GET_CHAPTER_CONTENT.get,
  payload: payload,
  params: params,
});

export const getChapterContentSuccess = (payload) => ({
  type: GET_CHAPTER_CONTENT.get_success,
  payload: payload,
});

export const getChapterContentError = (payload) => ({
  type: GET_CHAPTER_CONTENT.get_error,
  payload: payload,
});
export const getChapterContentReset = (payload) => ({
  type: GET_CHAPTER_CONTENT.get_reset,
  payload: payload,
});

export const addPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.set,
  payload: payload,
});

export const addSuccessPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.set_success,
  payload: payload,
});

export const addErrorPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.set_error,
  payload: payload,
});

export const getPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.get,
  payload: payload,
});

export const getSuccessPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.get_success,
  payload: payload,
});

export const getErrorPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.get_error,
  payload: payload,
});

export const updatePracticalContent = (id, payload) => ({
  type: PRACTICAL_CONTENT.update,
  id: id,
  payload: payload,
});

export const updateSuccessPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.update_success,
  payload: payload,
});

export const updateErrorPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.update_error,
  payload: payload,
});

export const deletePracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.delete_practical,
  payload: payload,
});

export const deleteSuccessPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.delete_success_practical,
  payload: payload,
});

export const deleteErrorPracticalContent = (payload) => ({
  type: PRACTICAL_CONTENT.delete_error_practical,
  payload: payload,
});

export const videoContentLoader = (payload) => ({
  type: VIDEO_CONTENT.loader,
  payload: payload,
});

export const addVideoContent = (payload) => ({
  type: VIDEO_CONTENT.set,
  payload: payload,
});

export const addSuccessVideoContent = (payload) => ({
  type: VIDEO_CONTENT.set_success,
  payload: payload,
});

export const addErrorVideoContent = (payload) => ({
  type: VIDEO_CONTENT.set_error,
  payload: payload,
});

export const getVideoContent = (payload, params) => ({
  type: VIDEO_CONTENT.get,
  payload: payload,
  params: params,
});

export const getSuccessVideoContent = (payload) => ({
  type: VIDEO_CONTENT.get_success,
  payload: payload,
});

export const getErrorVideoContent = (payload) => ({
  type: VIDEO_CONTENT.get_error,
  payload: payload,
});

export const updateVideoContent = (id, payload) => ({
  type: VIDEO_CONTENT.update,
  id: id,
  payload: payload,
});

export const updateSuccessVideoContent = (payload) => ({
  type: VIDEO_CONTENT.update_success,
  payload: payload,
});

export const updateErrorVideoContent = (payload) => ({
  type: VIDEO_CONTENT.update_error,
  payload: payload,
});

export const deleteVideoContent = (payload) => ({
  type: VIDEO_CONTENT.delete_video,
  payload: payload,
});

export const deleteSuccessVideoContent = (payload) => ({
  type: VIDEO_CONTENT.delete_success_video,
  payload: payload,
});

export const deleteErrorVideoContent = (payload) => ({
  type: VIDEO_CONTENT.delete_error_video,
  payload: payload,
});

export const uploadFile = (payload) => ({
  type: VIDEO_CONTENT.upload_file,
  payload: payload,
});

export const uploadFileSuccess = (payload) => ({
  type: VIDEO_CONTENT.upload_file_success,
  payload: payload,
});

export const uploadFileError = (payload) => ({
  type: VIDEO_CONTENT.upload_file_error,
  payload: payload,
});

export const uploadTrancriptFile = (payload) => ({
  type: VIDEO_CONTENT.upload_transcript_file,
  payload: payload,
});

export const uploadTranscriptFileSuccess = (payload) => ({
  type: VIDEO_CONTENT.upload_transcript_file_success,
  payload: payload,
});
// Get program details

export const getProgramDetailsRequest = (payload) => ({
  type: GET_PROGRAM_DETAILS.get,
  payload: payload,
});

export const getProgramDetailsSuccess = (payload) => ({
  type: GET_PROGRAM_DETAILS.get_success,
  payload: payload,
});

export const getProgramDetailsError = (payload) => ({
  type: GET_PROGRAM_DETAILS.get_error,
  payload: payload,
});

export const setIsLoading = (payload) => ({
  type: LOADING.loading,
  payload: payload,
});

export const createReadingAction = (payload) => ({
  type: READING.create,
  payload: payload,
});

export const createReadingSuccess = (payload) => ({
  type: READING.create_success,
  payload: payload,
});

export const updateReadingAction = (payload) => ({
  type: READING.update,
  payload: payload,
});

export const udpateReadingSuccess = (payload) => ({
  type: READING.update_success,
  payload: payload,
});

export const deleteReadingAction = (payload) => ({
  type: READING.delete,
  payload: payload,
});
export const deleteReadingSuccess = (payload) => ({
  type: READING.delete_success,
  payload: payload,
});

export const addChapter = (payload) => ({
  type: ADD_CHAPTER.create,
  payload: payload,
});

export const addChapterSuccess = (payload) => ({
  type: ADD_CHAPTER.create_success,
  payload: payload,
});

export const addChapterError = (payload) => ({
  type: ADD_CHAPTER.create_error,
  payload: payload,
});

export const getChapterDetailsById = (payload) => ({
  type: GET_CHAPTER_DETAILS_BY_ID.get,
  payload: payload,
});

export const getChapterDetailsByIdSuccess = (payload) => ({
  type: GET_CHAPTER_DETAILS_BY_ID.get_success,
  payload: payload,
});

export const getChapterDetailsByIdError = (payload) => ({
  type: GET_CHAPTER_DETAILS_BY_ID.get_error,
  payload: payload,
});

export const updateChapter = (payload) => ({
  type: UPDATE_CHAPTER.update,
  payload: payload,
});

export const updateChapterSuccess = (payload) => ({
  type: UPDATE_CHAPTER.update_success,
  payload: payload,
});

export const updateChapterError = (payload) => ({
  type: UPDATE_CHAPTER.update_error,
  payload: payload,
});

export const deleteChapter = (payload) => ({
  type: DELETE_CHAPTER.delete,
  payload: payload,
});

export const deleteChapterSuccess = (payload) => ({
  type: DELETE_CHAPTER.delete_success,
  payload: payload,
});

export const deleteChapterError = (payload) => ({
  type: DELETE_CHAPTER.delete_error,
  payload: payload,
});

export const deleteLiveSession = (payload) => ({
  type: DELETE_LIVE_SESSION.delete,
  payload: payload,
});

export const deleteLiveSessionSuccess = (payload) => ({
  type: DELETE_LIVE_SESSION.delete_success,
  payload: payload,
});

export const deleteLiveSessionError = (payload) => ({
  type: DELETE_LIVE_SESSION.delete_error,
  payload: payload,
});

export const getFacultyListBySubjectId = (payload) => ({
  type: GET_FACULTY_LIST_BY_SUBJECT_ID.get,
  payload: payload,
});

export const getFacultyListBySubjectIdSuccess = (payload) => ({
  type: GET_FACULTY_LIST_BY_SUBJECT_ID.get_success,
  payload: payload,
});

export const getFacultyListBySubjectIdError = (payload) => ({
  type: GET_FACULTY_LIST_BY_SUBJECT_ID.get_error,
  payload: payload,
});

export const getUserDepartmentListAction = (payload) => ({
  type: GET_USER_DEPARTMENTS.get,
  payload: payload,
});

export const getUserDepartmentListSuccess = (payload) => ({
  type: GET_USER_DEPARTMENTS.get_success,
  payload: payload,
});

export const getUserDepartmentListError = (payload) => ({
  type: GET_USER_DEPARTMENTS.get_error,
  payload: payload,
});

export const getUserProgramListAction = (payload) => ({
  type: GET_USER_BASED_PROGRAM.get,
  payload: payload,
});

export const getUserProgramListSuccess = (payload) => ({
  type: GET_USER_BASED_PROGRAM.get_success,
  payload: payload,
});

export const getUserProgramListError = (payload) => ({
  type: GET_USER_BASED_PROGRAM.get_error,
  payload: payload,
});

export const getUserProgramListReset = () => ({
  type: GET_USER_BASED_PROGRAM.reset,
});

export const getReadingAction = (payload, params) => ({
  type: READING.get,
  payload: payload,
  params: params,
});

export const getReadingSuccess = (payload) => ({
  type: READING.get_success,
  payload: payload,
});

export const getReadingError = (payload) => ({
  type: READING.get_error,
  payload: payload,
});

//Virtual Lab
export const getLabListRequest = (payload) => ({
  type: VIRTUAL_LAB.GET_LAB_LIST_REQUEST,
  payload: payload,
});

export const getLabListSuccess = (payload) => ({
  type: VIRTUAL_LAB.GET_LAB_LIST_SUCCESS,
  payload: payload,
});

export const getLabListError = (payload) => ({
  type: VIRTUAL_LAB.GET_LAB_LIST_ERROR,
  payload: payload,
});

//Create VM Request
export const createVmRequest = (payload) => ({
  type: CREATE_VM.CREATE_VM_REQUEST,
  payload: payload,
});

export const createVmSuccess = (payload) => ({
  type: CREATE_VM.CREATE_VM_SUCCESS,
  payload: payload,
});

export const createVmError = (payload) => ({
  type: CREATE_VM.CREATE_VM_ERROR,
  payload: payload,
});

export const resetCreateVmRequest = () => ({
  type: CREATE_VM.RESET_CREATE_VM_REQUEST,
});

//Get VM State
export const getVmStateRequest = (payload) => ({
  type: GET_VM_STATE.GET_VM_STATE_REQUEST,
  payload: payload,
});

export const getVmStateSuccess = (payload) => ({
  type: GET_VM_STATE.GET_VM_STATE_SUCCESS,
  payload: payload,
});

export const getVmStateError = (payload) => ({
  type: GET_VM_STATE.GET_VM_STATE_ERROR,
  payload: payload,
});
