export const CandidateAction = {
  SET_CANDIDATE_LOADING: 'SET_CANDIDATE_LOADING',
  GET_CANDIDATE_COURSE_FORM: 'GET_CANDIDATE_COURSE_FORM',
  SET_CANDIDATE_COURSE_FORM_SUCCESS: 'SET_CANDIDATE_COURSE_FORM_SUCCESS',
  SET_CANDIDATE_COURSE_FORM_ERROR: 'SET_CANDIDATE_COURSE_FORM_ERROR',
  GET_CANDIDATE_COURSE_FORM_INFO: 'GET_CANDIDATE_COURSE_FORM_INFO',
  SET_CANDIDATE_COURSE_FORM_INFO_SUCCESS:
    'SET_CANDIDATE_COURSE_FORM_INFO_SUCCESS',
  SET_CANDIDATE_COURSE_FORM_INFO_ERROR: 'SET_CANDIDATE_COURSE_FORM_INFO_ERROR',
  POST_CANDIDATE_COURSE_FORM_INFO: 'POST_CANDIDATE_COURSE_FORM_INFO',
  POST_CANDIDATE_COURSE_FORM_INFO_SUCCESS:
    'POST_CANDIDATE_COURSE_FORM_INFO_SUCCESS',
  POST_CANDIDATE_COURSE_FORM_INFO_ERROR:
    'POST_CANDIDATE_COURSE_FORM_INFO_ERROR',
  UPDATE_CANDIDATE_COURSE_FORM_INFO: 'UPDATE_CANDIDATE_COURSE_FORM_INFO',
  UPDATE_CANDIDATE_COURSE_FORM_INFO_SUCCESS:
    'UPDATE_CANDIDATE_COURSE_FORM_INFO_SUCCESS',
  UPDATE_CANDIDATE_COURSE_FORM_INFO_ERROR:
    'UPDATE_CANDIDATE_COURSE_FORM_INFO_ERROR',
  SET_CANDIDATE_PROSPECTUS_LOADING: 'SET_CANDIDATE_PROSPECTUS_LOADING',
  GET_CANDIDATE_COURSE_PROSPECTUS: 'GET_CANDIDATE_COURSE_PROSPECTUS',
  SET_CANDIDATE_COURSE_PROSPECTUS_SUCCESS:
    'SET_CANDIDATE_COURSE_PROSPECTUS_SUCCESS',
  SET_CANDIDATE_COURSE_PROSPECTUS_ERROR:
    'SET_CANDIDATE_COURSE_PROSPECTUS_ERROR',
};
