import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createNotification,
  createNotificationError,
  createNotificationSuccess,
  createUpdateUserNotification,
  createUpdateUserNotificationSuccess,
  deleteNotification,
  fetchNotificationContent,
  getSentNotificationsError,
  getSentNotificationsSuccess,
  getUserNotificationError,
  getUserNotificationSuccess,
  markAsReadNotification,
  notificationActions,
  readNotificationError,
  readNotificationSuccess,
  revokeNotificationError,
  revokeNotificationSuccess,
  sentNotificationListApi,
  setLoading,
} from 'store/Notifications';

import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';

function* createNotificationSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(createNotification, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createNotificationSuccess(true));
      payload?.isEdit
        ? yield put(
            setToastMessage({
              data: 'Notification updated successfully',
              severity: 'success',
            })
          )
        : yield put(
            setToastMessage({
              data: 'Notification created successfully',
              severity: 'success',
            })
          );
    } else {
      yield put(createNotificationError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(createNotificationError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* deleteNotificationSaga({ payload }) {
  try {
    const response = yield call(deleteNotification, payload);
    if (
      response?.status === statusCode.NoContent204 ||
      response?.status === statusCode.Ok200
    ) {
      yield put(revokeNotificationSuccess(true));
      yield put(
        setToastMessage({
          data: 'Notification revoked successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(revokeNotificationError(error?.data));
  }
}

function* readNotificationSaga({ payload }) {
  try {
    // yield put(setCourseListLoading(true));
    const response = yield call(markAsReadNotification, payload);
    // yield put(setCourseListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(readNotificationSuccess(true));
    }
  } catch (error) {
    yield put(readNotificationError(error?.data));
  }
}

function* getNotificationContentSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(fetchNotificationContent, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUserNotificationSuccess(response?.data));
      yield put(setLoading(false));
    } else {
      yield put(getUserNotificationSuccess(response?.data));
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(getUserNotificationError(error?.data));
    yield put(setLoading(false));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getSentNotificationListSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(sentNotificationListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setLoading(false));
      yield put(getSentNotificationsSuccess(response?.data));
    } else {
      yield put(getSentNotificationsError(response?.data));
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(getSentNotificationsError(error?.data));
    yield put(setLoading(false));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* createUpdateUserNotifcationSaga({ payload }) {
  try {
    const response = yield call(createUpdateUserNotification, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(createUpdateUserNotificationSuccess(response?.data));
    } else {
      yield put(createUpdateUserNotificationSuccess(response?.data));
    }
  } catch (error) {
    yield put(createUpdateUserNotificationError(error?.data));
  }
}
export function* watchDummy() {
  yield takeLatest(
    notificationActions.CREATE_NOTIFICATON.create_notification,
    createNotificationSaga
  );

  yield takeLatest(
    notificationActions.REVOKE_NOTIFICATION.revoke_notification,
    deleteNotificationSaga
  );
  yield takeLatest(
    notificationActions.READ_NOTIFICATION.mark_as_read_notification,
    readNotificationSaga
  );
  yield takeLatest(
    notificationActions.GET_NOTIFICATION_CONTENT.get_notification_content,
    getNotificationContentSaga
  );

  yield takeLatest(
    notificationActions.SENT_NOTIFICATION_LIST.sent_notification_list,
    getSentNotificationListSaga
  );

  yield takeLatest(
    notificationActions.CREATE_UPDATE_USER_NOTIFICATION
      .create_update_user_notification_request,
    createUpdateUserNotifcationSaga
  );
}

export default function* notificationSaga() {
  yield all([watchDummy()]);
}
