export const userManagementActions = {
  USER: {
    get: 'USER_MANAGEMENT_USER_GET',
    get_success: 'USER_MANAGEMENT_USER_GET_SUCCESS',
    get_error: 'USER_MANAGEMENT_USER_GET_ERROR',
    create: 'USER_MANAGEMENT_USER_CREATE',
    create_success: 'USER_MANAGEMENT_USER_CREATE_SUCCESS',
    create_error: 'USER_MANAGEMENT_USER_CREATE_ERROR',
    update: 'USER_MANAGEMENT_USER_UPDATE',
    update_success: 'USER_MANAGEMENT_USER_UPDATE_SUCCESS',
    update_error: 'USER_MANAGEMENT_USER_UPDATE_ERROR',
    edit: 'USER_MANAGEMENT_USER_EDIT',
    edit_success: 'USER_MANAGEMENT_USER_EDIT_SUCCESS',
    edit_error: 'USER_MANAGEMENT_USER_EDIT_ERROR',
    delete: 'USER_MANAGEMENT_USER_DELETE',
    delete_success: 'USER_MANAGEMENT_USER_DELETE_SUCCESS',
    delete_error: 'USER_MANAGEMENT_USER_DELETE_ERROR',
    loading: 'USER_MANAGEMENT_USER_SET_LOADING',
  },
  USER_ROLE: {
    get: 'USER_ROLE_MANAGEMENT_USER_ROLE_GET',
    get_success: 'USER_ROLE_MANAGEMENT_USER_ROLE_GET_SUCCESS',
    get_error: 'USER_ROLE_MANAGEMENT_USER_ROLE_GET_ERROR',
    create: 'USER_ROLE_MANAGEMENT_USER_ROLE_CREATE',
    create_success: 'USER_ROLE_MANAGEMENT_USER_ROLE_CREATE_SUCCESS',
    create_error: 'USER_ROLE_MANAGEMENT_USER_ROLE_CREATE_ERROR',
    edit: 'USER_ROLE_MANAGEMENT_USER_ROLE_EDIT',
    edit_success: 'USER_ROLE_MANAGEMENT_USER_ROLE_EDIT_SUCCESS',
    edit_error: 'USER_ROLE_MANAGEMENT_USER_ROLE_EDIT_ERROR',
    delete: 'USER_ROLE_MANAGEMENT_USER_ROLE_DELETE',
    delete_success: 'USER_ROLE_MANAGEMENT_USER_ROLE_DELETE_SUCCESS',
    delete_error: 'USER_ROLE_MANAGEMENT_USER_ROLE_DELETE_ERROR',
    loading: 'USER_ROLE_MANAGEMENT_USER_ROLE_SET_LOADING',
    get_all_roles: 'USER_ROLE_MANAGEMENT_GET_ALL_ROLES',
    get_all_roles_success: 'USER_ROLE_MANAGEMENT_GET_ALL_ROLES_SUCCESS',
    get_all_roles_error: 'USER_ROLE_MANAGEMENT_GET_ALL_ROLES_ERROR',
  },
  USER_DETAILS: {
    reset_all_faculties: 'RESET_ALL_FACULTIES',
    get_all_faculties: 'USER_ROLE_MANAGEMENT_GET_ALL_FACULTIES',
    get_all_faculties_success: 'USER_ROLE_MANAGEMENT_GET_ALL_FACULTIES_SUCCESS',
    get_all_faculties_error: 'USER_ROLE_MANAGEMENT_GET_ALL_FACULTIES_ERROR',
    get_all_hods: 'USER_ROLE_MANAGEMENT_GET_ALL_HODS',
    get_all_hods_success: 'USER_ROLE_MANAGEMENT_GET_ALL_HODS_SUCCESS',
    get_all_hods_error: 'USER_ROLE_MANAGEMENT_GET_ALL_HODS_ERROR',
    get_all_hods_reset: 'USER_ROLE_MANAGEMENT_GET_ALL_HODS_RESET',
    get_all_hods_by_scroll: 'USER_ROLE_MANAGEMENT_GET_ALL_HODS_BY_SCROLL',
    get_all_hods_by_scroll_success:
      'USER_ROLE_MANAGEMENT_GET_ALL_HODS_BY_SCROLL_SUCCESS',
    get_all_hods_by_scroll_error:
      'USER_ROLE_MANAGEMENT_GET_ALL_HODS_BY_SCROLL_ERROR',
    get_all_students: 'USER_ROLE_MANAGEMENT_GET_ALL_STUDENTS',
    get_all_students_success: 'USER_ROLE_MANAGEMENT_GET_ALL_STUDENTS_SUCCESS',
    get_all_students_error: 'USER_ROLE_MANAGEMENT_GET_ALL_STUDENTS_ERROR',
    get_all_registrars: 'USER_ROLE_MANAGEMENT_GET_ALL_REGISTRARS',
    get_all_registrars_success:
      'USER_ROLE_MANAGEMENT_GET_ALL_REGISTRARS_SUCCESS',
    get_all_registrars_error: 'USER_ROLE_MANAGEMENT_GET_ALL_REGISTRARS_ERROR',
    get_dept_user_details: 'USER_ROLE_MANAGEMENT.GET_DEPT_USER_DETAILS',
    get_dept_user_details_success:
      'USER_ROLE_MANAGEMENT.GET_DEPT_USER_DETAILS_SUCCESS',
    get_dept_user_details_error:
      'USER_ROLE_MANAGEMENT.GET_DEPT_USER_DETAILS_ERROR',
    get_filtered_user_details: 'USER_ROLE_MANAGEMENT.GET_FILTERED_USER_DETAILS',
    get_filtered_user_details_success:
      'USER_ROLE_MANAGEMENT.GET_FILTERED_USER_DETAILS_SUCCESS',
    reset_filtered_user_details:
      'USER_ROLE_MANAGEMENT.RESET_FILTERED_USER_DETAILS',
    get_filtered_user_details_error:
      'USER_ROLE_MANAGEMENT.GET_FILTERED_USER_DETAILS_ERROR',

    get_faculties_by_dept: 'USER_ROLE_MANAGEMENT_GET_FACULTIES_BY_DEPT',
    get_faculties_by_dept_success:
      'USER_ROLE_MANAGEMENT_GET_FACULTIES_BY_DEPT_SUCCESS',
    get_faculties_by_dept_error:
      'USER_ROLE_MANAGEMENT_GET_FACULTIES_BY_DEPT_ERROR',

    get_add_more_user_details: 'USER_ROLE_MANAGEMENT.GET_add_more_USER_DETAILS',
    get_add_more_user_details_success:
      'USER_ROLE_MANAGEMENT.GET_add_more_USER_DETAILS_SUCCESS',
    get_add_more_user_details_error:
      'USER_ROLE_MANAGEMENT.GET_add_more_USER_DETAILS_ERROR',
    get_dept_user_details_reset:
      'USER_ROLE_MANAGEMENT.GET_DEPT_USER_DETAILS_RESET',
    get_dept_user_details_by_pagination:
      'USER_ROLE_MANAGEMENT.GET_DEPT_USER_DETAILS_BY_PAGINATION',
    get_dept_user_details_by_pagination_success:
      'USER_ROLE_MANAGEMENT.GET_DEPT_USER_DETAILS_BY_PAGINATION_SUCCESS',
    get_dept_user_details_by_pagination_error:
      'USER_ROLE_MANAGEMENT.GET_DEPT_USER_DETAILS_BY_PAGINATION_ERROR',
    get_user_for_notification_action:
      'USER_ROLE_MANAGEMENT.GET_USER_FOR_NOTIFICATION_ACTION',
    get_user_for_notification_sucess:
      'USER_ROLE_MANAGEMENT.GET_USER_FOR_NOTIFICATION_SUCCESS',
    get_user_for_notification_error:
      'USER_ROLE_MANAGEMENT.GET_USER_FOR_NOTIFICATION_ERROR',
  },
  STUDENT: {
    get: 'GET_STUDENTS_LIST',
    get_success: 'GET_SUCCESS_STUDENTS_LIST',
    get_error: 'GET_ERROR_STUDENTS_LIST',
    get_student_details_with_program_list:
      'GET_STUDENT_DEAILS_WITH_PROGRAM_LIST',
    get_student_details_with_program_list_success:
      'GET_STUDENT_DEAILS_WITH_PROGRAM_LIST_SUCCESS',
    get_student_details_with_program_list_error:
      'GET_STUDENT_DEAILS_WITH_PROGRAM_LIST_ERROR',
    reset: 'RESET_STUDENTS_LIST',
    loading: 'STUDENTS_LOADING',
    create: 'CREATE_STUDENT',
    create_success: 'SUCCESS_CREATE_STUDENT',
    create_error: 'ERROR_CREATE_STUDENT',
    reset_create_student: 'RESET_CREATE_STUDENT',
    create_student_course_mapping: 'CREATE_STUDENT_COURSE_MAPPING',
    create_student_course_mapping_success:
      'CREATE_STUDENT_COURSE_MAPPING_SUCCESS',
    create_student_course_mapping_error: 'CREATE_STUDENT_COURSE_MAPPING_ERROR',
    get_details: 'GET_STUDENT_DETAILS',
    get_details_success: 'GET_SUCCESS_STUDENT_DETAILS',
    get_details_error: 'GET_ERROR_STUDENT_DETAILS',
    reset_details: 'RESET_STUDENT_DETAILS',
    upload_img: 'REQUEST_UPLOAD_IMAGE',
    upload_img_success: 'SUCCESS_UPLOAD_IMAGE',
    upload_img_error: 'ERROR_UPLOAD_IMAGE',
    reset_upload_img: 'RESET_UPLOAD_IMAGE',
    delete_student: 'DELETE_STUDENT',
    delete_student_success: 'DELETE_STUDENT_SUCCESS',
    delete_student_error: 'DELETE_STUDENT_ERROR',
    reset_delete_user: 'RESET_DELETE_USER',
    upload_validation: 'UPLOAD_VALIDATION',
    upload_validation_success: 'UPLOAD_VALIDATION_SUCCESS',
    upload_validation_error: 'UPLOAD_VALIDATION_ERROR',
    reset_upload_validation: 'RESET_UPLOAD_VALIDATION',
    upload_user: 'UPLOAD_USER',
    upload_user_success: 'UPLOAD_USER_SUCCESS',
    upload_user_error: 'UPLOAD_USER_ERROR',
    reset_upload_user: 'RESET_UPLOAD_USER',
    update: 'UPDATE_STUDENT',
    update_success: 'SUCCESS_UPDATE_STUDENT',
    update_error: 'ERROR_UPDATE_STUDENT',
    bulk_upload_student: 'BULK_UPLOAD_STUDENT',
    bulk_upload_student_success: 'BULK_UPLOAD_STUDENT_SUCCESSS',
    bulk_upload_student_error: 'BULK_UPLOAD_STUDENT_ERROR',
    bulk_upload_student_reset: 'BULK_UPLOAD_STUDENT_RESET',
    get_all_program_list: 'GET_ALL_PROGRAM_LIST',
    get_all_program_list_success: 'GET_ALL_PROGRAM_LIST_SUCCESS',
    get_all_program_list_error: 'GET_ALL_PROGRAM_LIST_error',
  },

  STAFF: {
    block: 'BLOCK',
    block_success: 'BLOCK_SUCCESS',
    block_error: 'BLOCK_ERROR',
    get: 'GET_STAFF_LIST',
    get_success: 'GET_SUCCESS_STAFF_LIST',
    get_error: 'GET_ERROR_STAFF_LIST',
    loading: 'STAFFS_LOADING',
    create: 'CREATE_STAFF',
    create_success: 'SUCCESS_CREATE_STAFF',
    create_error: 'ERROR_CREATE_STAFF',
    update: 'UPDATE_STAFF',
    update_success: 'SUCCESS_UPDATE_STAFF',
    update_error: 'ERROR_UPDATE_STAFF',
    reset_create_staff: 'RESET_CREATE_STAFF',
    get_details: 'GET_STAFF_DETAILS',
    get_details_success: 'GET_SUCCESS_STAFF_DETAILS',
    get_details_error: 'GET_ERROR_STAFF_DETAILS',
    reset_details: 'RESET_STAFF_DETAILS',
    get_designation: 'GET_DESIGNATION',
    get_designation_success: 'GET_DESIGNATION_SUCCESS',
    get_designation_error: 'GET_DESIGNATION_ERROR',
    reset_designation: 'RESET_DESIGNATION',
    staff_upload_img: 'REQUEST_STAFF_UPLOAD_IMAGE',
    staff_upload_img_success: 'SUCCESS_STAFF_UPLOAD_IMAGE',
    staff_upload_img_error: 'ERROR_STAFF_UPLOAD_IMAGE',
    staff_reset_upload_img: 'RESET_STAFF_UPLOAD_IMAGE',
    delete_user: 'DELETE_USER',
    delete_user_success: 'DELETE_USER_SUCCESS',
    delete_user_error: 'DELETE_USER_SUCCESS_ERROR',
    set_theory_state: 'SET_THEORY_STATE',
  },

  CLUSTERS: {
    get: 'GET_CLUSTERS_LIST',
    get_success: 'GET_SUCCESS_CLUSTERS_LIST',
    get_error: 'GET_ERROR_CLUSTERS_LIST',
    reset: 'RESET_CLUSTERS_LIST',
  },
  DEPARTMENTS: {
    get_by_id: 'GET_DEPARTMENT_BY_ID',
    get_by_id_success: 'GET_SUCCESS_DEPARTMENT_BY_ID',
    get_by_id_error: 'GET_ERROR_DEPARTMENT_BY_ID',
    reset_by_id: 'RESET_DEPARTMENT_BY_ID',
  },
  COURSES: {
    get_by_id: 'GET_COURSES_BY_ID',
    get_by_id_success: 'GET_SUCCESS_COURSES_BY_ID',
    get_by_id_error: 'GET_ERROR_COURSES_BY_ID',
    reset_by_id: 'RESET_COURSES_BY_ID',
  },
  AUTHENTICATION: {
    SAVE_TENANT_NAME: 'SAVE_TENANT_NAME',
    SAVE_LOGIN_TYPE: 'SAVE_LOGIN_TYPE',
    LOCAL_USER_LOGIN: 'LOCAL_USER_LOGIN',
    LOCAL_USER_LOGOUT: 'LOCAL_USER_LOGOUT',
    LOCAL_USER_LOGIN_SUCCESS: 'LOCAL_USER_LOGIN_SUCCESS',
    LOCAL_USER_LOGOUT_SUCCESS: 'LOCAL_USER_LOGOUT_SUCCESS',
  },
  TENANT_SPECIFIC_LOGO: {
    TENANT_SPECIFIC_LOGO_REQUEST: 'TENANT_SPECIFIC_LOGO_REQUEST',
    TENANT_SPECIFIC_LOGO_SUCCESS: 'TENANT_SPECIFIC_LOGO_SUCCESS',
    TENANT_SPECIFIC_LOGO_RESET: 'TENANT_SPECIFIC_LOGO_RESET',
  },
  CHANGE_STUDENT: {
    CHANGE_STUDENT_REQUEST: 'CHANGE_STUDENT_REQUEST',
  },
  CANDIDATE_STUDENT: {
    CANDIDATE_STUDENT_GET: 'CANDIDATE_STUDENT_GET',
    CANDIDATE_STUDENT_SUCCESS: 'CANDIDATE_STUDENT_SUCCESS',
    CANDIDATE_STUDENT_ERROR: 'CANDIDATE_STUDENT_ERROR',
  },
  CANDIDATE_ONBOARDING_PENDING: {
    CANDIDATE_ONBOARDING_GET: 'CANDIDATE_ONBOARDING_GET',
    CANDIDATE_ONBOARDING_SUCCESS: 'CANDIDATE_ONBOARDING_SUCCESS',
    CANDIDATE_ONBOARDING_ERROR: 'CANDIDATE_ONBOARDING_ERROR',
  },
  CANDIDATE_ONBOARDED: {
    CANDIDATE_ONBOARDED_GET: 'CANDIDATE_ONBOARDED_GET',
    CANDIDATE_ONBOARDED_SUCCESS: 'CANDIDATE_ONBOARDED_SUCCESS',
    CANDIDATE_ONBOARDED_ERROR: 'CANDIDATE_ONBOARDED_ERROR',
  },
  CANDIDATE_LOGIN: {
    SEND_OTP: 'SEND_OTP',
    SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
    SEND_OTP_ERROR: 'SEND_OTP_ERROR',

    VERIFY_OTP: 'VERIFY_OTP',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_ERROR: 'VERIFY_OTP_ERROR',

    RESET_CANDIDATE_LOGIN: 'RESET_CANDIDATE_LOGIN',

    CANDIDATE_INFORMATION: 'CANDIDATE_INFORMATION',
  },
  HR: {
    GET_ALL_USER_REQUEST: 'GET_ALL_USER_REQUEST',
    GET_ALL_USER_SUCCESS: 'GET_ALL_USER_SUCCESS',
    GET_ALL_USER_ERROR: 'GET_ALL_USER_ERROR',
  },

  ALL_USER_ROLE: {
    GET_ALL_USER_ROLES: 'GET_ALL_USER_ROLES',
    GET_ALL_USER_ROLES_SUCCESS: 'GET_ALL_USER_ROLES_SUCCESS',
    GET_ALL_USER_ROLES_ERROR: 'GET_ALL_USER_ROLES_ERROR',
    GET_ALL_USER_ROLES_RESET: 'GET_ALL_USER_ROLES_RESET',
  },
  DELETE_USER_ROLE: {
    DELETE_USER_ROLES: 'DELETE_USER_ROLES',
    DELETE_USER_ROLES_SUCCESS: 'DELETE_USER_ROLES_SUCCESS',
    DELETE_USER_ROLES_ERROR: 'DELETE_USER_ROLES_ERROR',
  },

  USER_ROLE_DETAILS: {
    GET_USER_ROLE_DETAILS: 'GET_USER_ROLE_DETAILS',
    GET_USER_ROLE_DETAILS_SUCCESS: 'GET_USER_ROLE_DETAILS_SUCCESS',
    GET_USER_ROLE_DETAILS_ERROR: 'GET_USER_ROLE_DETAILS_ERROR',
    RESET_CREATE_USER_ROLE: 'RESET_CREATE_USER_ROLE',
  },

  CREATE_USER_ROLES: {
    CREATE_USER_ROLE: 'CREATE_USER_ROLE',
    CREATE_USER_ROLE_SUCCESS: 'CREATE_USER_ROLE_SUCCESS',
    CREATE_USER_ROLE_ERROR: 'CREATE_USER_ROLE_ERROR',
    RESET_CREATE_USER_ROLE: 'RESET_CREATE_USER_ROLE',
  },

  UPDATE_USER_ROLES: {
    UPDATE_USER_ROLE: 'UPDATE_USER_ROLE',
    UPDATE_USER_ROLE_SUCCESS: 'UPDATE_USER_ROLE_SUCCESS',
    UPDATE_USER_ROLE_ERROR: 'UPDATE_USER_ROLE_ERROR',
  },
  GROUP: {
    get_group_details: 'GET_GROUP_DETAILS',
    get_group_details_success: 'GET_GROUP_DETAILS_SUCCESS',
    get_group_details_error: 'GET_GROUP_DETAILS_ERROR',
    get_group_details_by_id: 'GET_GROUP_DETAILS_BY_Id',
    get_group_details_by_id_success: 'GET_GROUP_DETAILS_BY_Id_SUCCESS',
    get_group_details_by_id_error: 'GET_GROUP_DETAILS_BY_Id_ERROR',
    get_group_details_by_id_reset: 'GET_GROUP_DETAILS_BY_Id_RESET',
    CREATE_GROUP: 'CREATE_GROUP',
    SUCCESS_CREATE_GROUP: 'SUCCESS_CREATE_GROUP',
    ERROR_CREATE_GROUP: 'ERROR_CREATE_GROUP',
    EDIT_GROUP: 'EDIT_GROUP',
    SUCCESS_EDIT_GROUP: 'SUCCESS_EDIT_GROUP',
    ERROR_EDIT_GROUP: 'ERROR_EDIT_GROUP',
    DELETE_GROUP: 'DELETE_GROUP',
    SUCCESS_DELETE_GROUP: 'SUCCESS_DELETE_GROUP',
    ERROR_DELETE_GROUP: 'ERROR_DELETE_GROUP',
    SEARCH_USER: 'SEARCH_USER',
    SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
    SEARCH_USER_ERROR: 'SEARCH_USER_ERROR',
    APPLY_FILTER: 'APPLY_FILTER',
    APPLY_FILTER_SUCCESS: 'APPLY_FILTER_SUCCESS',
    APPLY_FILTER_ERROR: 'APPLY_FILTER_ERROR',
    RESET_FILTERE: 'RESET_FILTERE',
    APPLY_ALL_FILTER: 'APPLY_ALL_FILTER',
    APPLY_ALL_FILTER_SUCCESS: 'APPLY_ALL_FILTER_SUCCESS',
    APPLY_ALL_FILTER_ERROR: 'APPLY_ALL_FILTER_ERROR',
    RESET_ALL_FILTERE: 'RESET_ALL_FILTERE',
    RESET_GROUP_BY_ID: 'RESET_GROUP_BY_ID',
    REMOVE_USER: 'REMOVE_USER',
    SET_REMOVE_USER_SUCCESS: 'SET_REMOVE_USER_SUCCESS',
    SET_REMOVE_USER_ERROR: 'SET_REMOVE_USER_ERROR',
    ADD_SELECTED_USER_TO_GROUP: 'ADD_SELECTED_USER_TO_GROUP',
    SET_ADD_SELECTED_USER_TO_GROUP_SUCCESS:
      'SET_ADD_SELECTED_USER_TO_GROUP_SUCCESS',
    SET_ADD_SELECTED_USER_TO_GROUP_ERROR:
      'SET_ADD_SELECTED_USER_TO_GROUP_ERROR',
    USER_GROUP_BULK_UPLOAD_REQUEST: 'USER_GROUP_BULK_UPLOAD_REQUEST',
    SET_USER_GROUP_BULK_UPLOAD_SUCCESS: 'SET_USER_GROUP_BULK_UPLOAD_SUCCESS',
    SET_USER_GROUP_BULK_UPLOAD_ERROR: 'SET_USER_GROUP_BULK_UPLOAD_SUCCESS',
    DEACTIVATE_GROUP: 'DEACTIVATE_GROUP',
    SET_DEACTIVATE_GROUP_SUCCESS: 'SET_DEACTIVATE_GROUP_SUCCESS',
    SET_DEACTIVATE_GROUP_ERROR: 'SET_DEACTIVATE_GROUP_ERROR',
  },

  FORGOT_PASSWORD: {
    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  },

  USER_LOGIN_DETAILS: {
    USER_LOGIN_DETAILS_REQUEST: 'USER_LOGIN_DETAILS_REQUEST',
    USER_LOGIN_DETAILS_SUCCESS: 'USER_LOGIN_DETAILS_SUCCESS',
    USER_LOGIN_DETAILS_ERROR: 'USER_LOGIN_DETAILS_ERROR',
  },
  ANY_USER_DETAILS: {
    ANY_USER_DETAILS_REQUEST: 'ANY_USER_DETAILS_REQUEST',
    ANY_USER_DETAILS_SUCCESS: 'ANY_USER_DETAILS_SUCCESS',
    ANY_USER_DETAILS_ERROR: 'ANY_USER_DETAILS_ERROR',
    ANY_USER_DETAILS_RESET: 'ANY_USER_DETAILS_RESET',
  },
  BULK_USER_ROLES: {
    BULK_USER_ROLE_REQUEST: 'BULK_USER_ROLE_REQUEST',
    BULK_USER_ROLE_SUCCESS: 'BULK_USER_ROLE_SUCCESS',
    BULK_USER_ROLE_ERROR: 'BULK_USER_ROLE_ERROR',
  },
};
