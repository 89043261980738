import * as React from 'react';

const PsychologySvg = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.544"
      height="30.967"
      viewBox="0 0 29.544 30.967"
      fill={fill}
    >
      <path
        id="psychology_FILL0_wght400_GRAD0_opsz48"
        d="M10.645,34.967V28.309a14.3,14.3,0,0,1-3.426-4.7A13.581,13.581,0,0,1,6,17.935a13.439,13.439,0,0,1,4.064-9.871A13.439,13.439,0,0,1,19.935,4a13.768,13.768,0,0,1,8.574,2.845,12.941,12.941,0,0,1,4.858,7.413L35.5,22.7a1.051,1.051,0,0,1-.194.987,1.12,1.12,0,0,1-.929.445h-3.6v5.419a2.313,2.313,0,0,1-2.323,2.323H24.58v3.1H22.258V29.548h6.193V21.806h4.413l-1.742-6.968A10.747,10.747,0,0,0,27.058,8.7a11.471,11.471,0,0,0-7.122-2.381A11.265,11.265,0,0,0,11.71,9.671a11.071,11.071,0,0,0-3.387,8.187A11.234,11.234,0,0,0,9.348,22.58a12.611,12.611,0,0,0,2.884,3.987l.735.7v7.7ZM20.593,20.645Zm-1.858,2.942h2.323l.116-1.7a2.385,2.385,0,0,0,.871-.329,3.939,3.939,0,0,0,.716-.561l1.626.542,1.084-1.858-1.161-.929a3.352,3.352,0,0,0,0-2.245l1.161-.929-1.084-1.858-1.626.542a3.2,3.2,0,0,0-.735-.542,3.95,3.95,0,0,0-.852-.348l-.116-1.7H18.735l-.116,1.7a3.95,3.95,0,0,0-.852.348,3.2,3.2,0,0,0-.735.542l-1.626-.542-1.084,1.858,1.161.929a3.352,3.352,0,0,0,0,2.245l-1.161.929,1.084,1.858,1.626-.542a3.939,3.939,0,0,0,.716.561,2.385,2.385,0,0,0,.871.329ZM19.9,20.335a2.71,2.71,0,0,1-1.916-4.626,2.71,2.71,0,0,1,3.832,3.832,2.611,2.611,0,0,1-1.916.794Z"
        transform="translate(-6 -4)"
        fill="#ac0521"
      />
    </svg>
  );
};

export default PsychologySvg;
