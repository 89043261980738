import * as React from 'react';

function PageNotFoundIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={197.64}
      height={216.996}
      {...props}
    >
      <g data-name="Group 11929" transform="translate(0 .001)">
        <path
          data-name="Path 43761"
          d="M137.239 44.023a293.7 293.7 0 00-45.551-3.353 299.719 299.719 0 00-42.018 2.815c0-.2.006-.394.015-.591a43.787 43.787 0 0187.548.535c.003.201.006.398.006.594z"
          fill="#039be5"
        />
        <g data-name="Group 11929">
          <path
            data-name="Path 43762"
            d="M99.197.397a43.8 43.8 0 00-49.645 42.091c-.009.2-.012.394-.015.591a257.289 257.289 0 0111.8-1.406A43.794 43.794 0 0199.197.397z"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <path
          data-name="Path 43763"
          d="M156.395 47.496a176.368 176.368 0 00-19.161-4.067 277.222 277.222 0 00-31.956-3.071 333.558 333.558 0 00-24.395-.1 286.023 286.023 0 00-31.2 2.636 183.459 183.459 0 00-22.7 4.6C9.583 52.305-.001 58.752-.001 65.647S9.582 78.989 26.983 83.8c12.237 3.385 27.324 5.692 43.764 6.75 6.785.441 13.8.664 20.941.664 7.165 0 14.2-.226 21.009-.667a230.247 230.247 0 0040.387-5.878c.062-.018.121-.032.182-.047.782-.194 1.552-.4 2.313-.6.273-.076.544-.15.814-.223a.281.281 0 01.047-.015c.259-.071.514-.144.77-.215.511-.147 1.017-.291 1.513-.441l.065-.018c.432-.129.855-.259 1.275-.388.344-.109.685-.215 1.023-.323l.732-.238c.176-.059.35-.115.52-.173.232-.076.464-.156.694-.235.1-.032.194-.065.288-.1.22-.073.435-.15.649-.226.232-.082.464-.168.694-.25.191-.068.382-.138.57-.209a75.443 75.443 0 001.745-.673c.209-.085.417-.168.623-.253s.409-.168.608-.253q.494-.207.97-.414c.153-.071.309-.138.458-.2l.341-.156c.082-.035.165-.073.244-.112.3-.135.591-.273.879-.411q1.658-.794 3.094-1.622c6.01-3.441 9.181-7.259 9.181-11.226.002-6.893-9.581-13.341-26.981-18.152zm-.159 35.741c-12.252 3.388-27.38 5.7-43.864 6.744-6.7.426-13.633.647-20.683.647-7.027 0-13.933-.217-20.615-.644-16.51-1.046-31.662-3.353-43.931-6.747C10.019 78.5.589 72.255.589 65.649a7.088 7.088 0 01.218-1.731c1.5-5.96 10.718-11.538 26.337-15.857a183.324 183.324 0 0122.522-4.576 286.822 286.822 0 0131.386-2.645q5.255-.172 10.632-.17c4.529 0 9.007.091 13.409.267a276.644 276.644 0 0132.141 3.086 175.981 175.981 0 0119 4.038c15.617 4.32 24.832 9.895 26.334 15.852a7 7 0 01.22 1.737c.002 6.605-9.428 12.85-26.552 17.588z"
          fill="#3f3d56"
        />
        <path
          data-name="Path 43764"
          d="M183.21 65.917c0 3.926-3.236 7.641-9.013 10.959a49.523 49.523 0 01-4.217 2.145l-.341.156c-.15.065-.306.132-.458.2q-.476.207-.97.414c-.2.085-.406.17-.608.253l-.623.253a78.916 78.916 0 01-2.315.882l-.694.25c-.215.076-.429.153-.649.226a46.916 46.916 0 01-.981.335c-.17.059-.344.115-.52.173l-.732.238c-.338.109-.679.215-1.023.323-.42.129-.843.259-1.275.388l-.065.018c-.5.15-1 .294-1.513.441-.256.071-.511.144-.77.215a.287.287 0 00-.047.015c-.27.073-.541.147-.814.223-.758.2-1.531.4-2.313.6-.062.015-.121.029-.182.047a232.3 232.3 0 01-40.4 5.857c-6.7.432-13.692.661-20.871.661-7.244 0-14.291-.232-21.047-.673C30.438 87.886.421 77.871.421 65.916a7.368 7.368 0 01.329-2.145c.018.05.035.1.056.147C4.999 74.932 34.76 83.834 73.375 86.006c5.916.335 12.043.508 18.314.508 6.3 0 12.448-.176 18.388-.511 38.583-2.181 68.314-11.085 72.493-22.091q.106-.264.185-.529a7.438 7.438 0 01.455 2.534z"
          fill="#3f3d56"
        />
        <path
          data-name="Path 43765"
          d="M26.939 62.753c-4.891 0-10.089-1.052-10.089-3s5.2-3 10.089-3 10.089 1.052 10.089 3-5.198 3-10.089 3zm0-5.417c-5.6 0-9.5 1.273-9.5 2.415s3.9 2.415 9.5 2.415 9.5-1.273 9.5-2.415-3.901-2.412-9.5-2.412z"
          fill="#3f3d56"
        />
        <path
          data-name="Path 43766"
          d="M47.805 76.565c-4.891 0-10.089-1.052-10.089-3s5.2-3 10.089-3 10.089 1.052 10.089 3-5.198 3-10.089 3zm0-5.417c-5.6 0-9.5 1.273-9.5 2.415s3.9 2.415 9.5 2.415 9.5-1.273 9.5-2.415-3.901-2.412-9.5-2.412z"
          fill="#3f3d56"
        />
        <path
          data-name="Path 43767"
          d="M156.244 62.753c-4.891 0-10.089-1.052-10.089-3s5.2-3 10.089-3 10.089 1.052 10.089 3-5.198 3-10.089 3zm0-5.417c-5.6 0-9.5 1.273-9.5 2.415s3.9 2.415 9.5 2.415 9.5-1.273 9.5-2.415-3.901-2.412-9.5-2.412z"
          fill="#3f3d56"
        />
        <path
          data-name="Path 43768"
          d="M135.379 76.565c-4.891 0-10.089-1.052-10.089-3s5.2-3 10.089-3 10.089 1.052 10.089 3-5.198 3-10.089 3zm0-5.417c-5.6 0-9.5 1.273-9.5 2.415s3.9 2.415 9.5 2.415 9.5-1.273 9.5-2.415-3.901-2.412-9.5-2.412z"
          fill="#3f3d56"
        />
        <path
          data-name="Path 43769"
          d="M91.592 83.03c-4.891 0-10.089-1.052-10.089-3s5.2-3 10.089-3 10.089 1.052 10.089 3-5.198 3-10.089 3zm0-5.417c-5.6 0-9.5 1.273-9.5 2.415s3.9 2.415 9.5 2.415 9.5-1.273 9.5-2.415-3.901-2.412-9.5-2.412z"
          fill="#3f3d56"
        />
        <circle
          data-name="Ellipse 870"
          cx={12.346}
          cy={12.346}
          r={12.346}
          transform="translate(86.683 120.104)"
          fill="#2f2e41"
        />
        <path
          data-name="Rectangle 4013"
          fill="#2f2e41"
          d="M88.325 143.572l6.009-3.009 1.68 3.354-6.01 3.009z"
        />
        <ellipse
          data-name="Ellipse 871"
          cx={1.172}
          cy={3.126}
          rx={1.172}
          ry={3.126}
          transform="rotate(-56.601 176.295 -6.807)"
          fill="#2f2e41"
        />
        <path
          data-name="Rectangle 4014"
          fill="#2f2e41"
          d="M102.044 143.914l1.68-3.354 6.008 3.01-1.68 3.353z"
        />
        <ellipse
          data-name="Ellipse 872"
          cx={3.126}
          cy={1.172}
          rx={3.126}
          ry={1.172}
          transform="rotate(-33.399 295.98 -104.887)"
          fill="#2f2e41"
        />
        <circle
          data-name="Ellipse 873"
          cx={4.22}
          cy={4.22}
          r={4.22}
          transform="translate(93.945 125.21)"
          fill="#fff"
        />
        <ellipse
          data-name="Ellipse 874"
          cx={1.401}
          cy={1.411}
          rx={1.401}
          ry={1.411}
          transform="rotate(-45 202.15 -52.324)"
          fill="#3f3d56"
        />
        <path
          data-name="Path 43770"
          d="M108.769 119.014c.186-4.571-3.754-8.442-8.8-8.647s-9.286 3.334-9.471 7.905 3.321 5.609 8.367 5.814 9.718-.497 9.904-5.072z"
          fill="#039be5"
        />
        <ellipse
          data-name="Ellipse 875"
          cx={1.938}
          cy={6.173}
          rx={1.938}
          ry={6.173}
          transform="rotate(-40.645 229.786 -78.203)"
          fill="#2f2e41"
        />
        <ellipse
          data-name="Ellipse 876"
          cx={6.173}
          cy={1.938}
          rx={6.173}
          ry={1.938}
          transform="rotate(-49.355 190.162 -19.032)"
          fill="#2f2e41"
        />
        <path
          data-name="Path 43771"
          d="M102.42 137.315a2.813 2.813 0 11-5.535 1.008v-.005c-.277-1.529.905-2.07 2.434-2.346s2.825-.185 3.101 1.343z"
          fill="#fff"
        />
        <path
          data-name="Path 43772"
          d="M78.171 145.583a.588.588 0 01-.588-.588v-34.677a.588.588 0 111.175 0v34.677a.588.588 0 01-.587.588z"
          fill="#ccc"
        />
        <path
          data-name="Path 43773"
          d="M69.354 176.734a.588.588 0 01-.588-.588v-25.273a.588.588 0 011.176 0v25.273a.588.588 0 01-.588.588z"
          fill="#ccc"
        />
        <path
          data-name="Path 43774"
          d="M91.983 155.869a.588.588 0 01-.588-.588v-34.677a.588.588 0 111.175 0v34.677a.588.588 0 01-.588.588z"
          fill="#ccc"
        />
        <path
          data-name="Path 43775"
          d="M83.461 180.849a.588.588 0 01-.588-.588v-14.106a.588.588 0 011.176 0v14.106a.588.588 0 01-.588.588z"
          fill="#ccc"
        />
        <path
          data-name="Path 43776"
          d="M95.509 108.554a.588.588 0 01-.588-.588V93.861a.588.588 0 111.175 0v14.106a.588.588 0 01-.587.587z"
          fill="#ccc"
        />
        <path
          data-name="Path 43777"
          d="M66.122 114.726a.588.588 0 01-.588-.588v-14.105a.588.588 0 111.175 0v14.106a.588.588 0 01-.588.588z"
          fill="#ccc"
        />
        <path
          data-name="Path 43778"
          d="M117.256 116.196a.588.588 0 01-.588-.588v-14.106a.588.588 0 011.176 0v14.106a.588.588 0 01-.588.588z"
          fill="#ccc"
        />
        <path
          data-name="Path 43779"
          d="M116.08 160.277a.588.588 0 01-.588-.588v-17.044a.588.588 0 011.175 0v17.045a.588.588 0 01-.588.588z"
          fill="#ccc"
        />
        <path
          data-name="Path 43780"
          d="M104.326 175.265a.588.588 0 01-.588-.588v-25.273a.588.588 0 111.176 0v25.273a.588.588 0 01-.588.588z"
          fill="#ccc"
        />
        <path
          data-name="Path 43781"
          d="M106.677 132.065a.588.588 0 01-.588-.588V96.8a.588.588 0 111.176 0v34.677a.588.588 0 01-.588.588z"
          fill="#ccc"
        />
        <ellipse
          data-name="Ellipse 877"
          cx={1.987}
          cy={6.328}
          rx={1.987}
          ry={6.328}
          transform="rotate(-39.938 356.216 -133.956)"
          fill="#2f2e41"
        />
        <circle
          data-name="Ellipse 878"
          cx={12.656}
          cy={12.656}
          r={12.656}
          transform="rotate(-71.58 215.996 2.679)"
          fill="#2f2e41"
        />
        <path
          data-name="Rectangle 4015"
          fill="#2f2e41"
          d="M162.721 208.739h3.845v6.889h-3.845z"
        />
        <path
          data-name="Rectangle 4016"
          fill="#2f2e41"
          d="M155.031 208.739h3.845v6.889h-3.845z"
        />
        <ellipse
          data-name="Ellipse 879"
          cx={3.204}
          cy={1.202}
          rx={3.204}
          ry={1.202}
          transform="translate(160.157 214.507)"
          fill="#2f2e41"
        />
        <ellipse
          data-name="Ellipse 880"
          cx={3.204}
          cy={1.202}
          rx={3.204}
          ry={1.202}
          transform="translate(152.467 214.346)"
          fill="#2f2e41"
        />
        <path
          data-name="Path 43782"
          d="M157.235 182.897c1.13-4.551 6.119-7.229 11.142-5.982s8.18 5.948 7.05 10.5-4.88 4.566-9.9 3.319-9.423-3.286-8.292-7.837z"
          fill="#ccc"
        />
        <ellipse
          data-name="Ellipse 881"
          cx={1.987}
          cy={6.328}
          rx={1.987}
          ry={6.328}
          transform="rotate(-64.626 221.987 -14.97)"
          fill="#2f2e41"
        />
        <circle
          data-name="Ellipse 882"
          cx={4.22}
          cy={4.22}
          r={4.22}
          transform="translate(155.097 191.916)"
          fill="#fff"
        />
        <circle
          data-name="Ellipse 883"
          cx={1.407}
          cy={1.407}
          r={1.407}
          transform="translate(156.176 193.217)"
          fill="#3f3d56"
        />
        <circle
          data-name="Ellipse 884"
          cx={1.646}
          cy={1.646}
          r={1.646}
          transform="translate(157.568 203.377)"
          fill="#fff"
        />
        <path
          data-name="Path 43783"
          d="M197.346 216.995h-69.354a.294.294 0 110-.588h69.354a.294.294 0 110 .588z"
          fill="#3f3d56"
        />
      </g>
    </svg>
  );
}

export default PageNotFoundIcon;
