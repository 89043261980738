import * as React from 'react';

const BackArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      style={{ fill: '#616161', transform: 'msFilter' }}
    >
      <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
    </svg>
  );
};

export default BackArrowSvg;
