import { CandidateAction } from './Candidate.constants';

const INITIAL_STATE = {
  candidateCourseForm: {
    formTitle: '',
    formDescription: '',
  },
  candidateCourseFormError: '',
  candidateCourseFormInfo: '',
  candidateCourseFormInfoError: '',
  postFormInfoSuccess: false,
  postCandidateCourseFormInfoError: '',
  updateFormInfoSuccess: false,
  updateCandidateCourseFormInfoError: '',
  loading: false,
  prospectusLoading: false,
  candidateProspectusInfo: '',
  candidateProspectusError: '',
};

const candidateReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case CandidateAction.SET_CANDIDATE_LOADING:
      return { ...state, loading: action.payload };
    case CandidateAction.SET_CANDIDATE_COURSE_FORM_SUCCESS:
      return { ...state, candidateCourseForm: action.payload };
    case CandidateAction.SET_CANDIDATE_COURSE_FORM_ERROR:
      return { ...state, candidateCourseFormError: action.payload };
    case CandidateAction.SET_CANDIDATE_COURSE_FORM_INFO_SUCCESS:
      return { ...state, candidateCourseFormInfo: action.payload[0] };
    case CandidateAction.SET_CANDIDATE_COURSE_FORM_INFO_ERROR:
      return { ...state, candidateCourseFormInfoError: action.payload };
    case CandidateAction.POST_CANDIDATE_COURSE_FORM_INFO_SUCCESS:
      return {
        ...state,
        candidateCourseFormInfo: action.payload?.data,
        postFormInfoSuccess: action.payload?.status,
      };
    case CandidateAction.POST_CANDIDATE_COURSE_FORM_INFO_ERROR:
      return { ...state, postCandidateCourseFormInfoError: action.payload };
    case CandidateAction.UPDATE_CANDIDATE_COURSE_FORM_INFO_SUCCESS:
      return {
        ...state,
        candidateCourseFormInfo: action.payload?.data,
        updateFormInfoSuccess: action.payload?.status,
      };
    case CandidateAction.UPDATE_CANDIDATE_COURSE_FORM_INFO_ERROR:
      return { ...state, updateCandidateCourseFormInfoError: action.payload };
    case CandidateAction.SET_CANDIDATE_PROSPECTUS_LOADING:
      return { ...state, prospectusLoading: action.payload };
    case CandidateAction.SET_CANDIDATE_COURSE_PROSPECTUS_SUCCESS:
      return { ...state, candidateProspectusInfo: action.payload };
    case CandidateAction.SET_CANDIDATE_COURSE_PROSPECTUS_ERROR:
      return { ...state, candidateProspectusError: action.payload };
    default:
      return state;
  }
};

export default candidateReducer;
