import { attendanceActions } from 'store/Attendance';

const INITIAL_STATE = {
  user_attendance: {
    mark_as_read_success: false,
    create_attendance_success: false,
    get_student_attendance: { data: [] },
    // get_student_session_attendance: { data: {}, loading: false},
    session_list_by_filter: { results: [], count: 0 },
    // bulk_attendance_mark_success: false,
    session_create_bulk_attendance: false,
    session_edit_bulk_attendance: false,
    update_bulk_attendance_success: '',
    faculty_attendance: {},
    loading: false,
    update_attendace: '',
  },
  get_student_session_attendance: {
    session_attendance: {},
    loading: false,
  },
  faculty: {
    facultyProgramList: {},
    programAttendance: '',
    studentAttendance: '',
    loading: false,
  },
  liveSessionList: {
    // data: {
    //   count: 0,
    //   next: '',
    //   results: [],
    // },
    data: {},
    error: false,
    errorMessage: '',
    loading: false,
  },
  applyLeaveAttendance: {
    data: '',
    error: false,
    errorMessage: '',
    loading: false,
  },
  studentNotification: {
    success: '',
    error: '',
    loading: false,
  },
  studentParentEmails: {
    data: '',
    error: '',
  },
  dashboardCardDetails: '',
  fetchAttendanceRequst: {
    success: '',
    error: '',
    loading: false,
  },
  applyLeave: {
    success: '',
    error: '',
    loading: false,
  },
  fetchRequestId: {
    success: '',
    error: '',
  },
  approveDeclineRequest: {
    success: '',
    error: '',
  },

  updateRemark: {
    data: '',
    error: false,
    errorMessage: '',
    loading: false,
  },
  updateRequest: {
    success: '',
    error: '',
    loading: false,
  },
  ordinanceSetting: {
    success: '',
    error: '',
  },
  updateOrdinanceSetting: {
    success: '',
    error: '',
  },
  DownloadAllRequest: {
    data: {},
  },
  getSessionList: {
    data: [],
  },
};

const attendanceReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case attendanceActions.ATTENDANCE.create_attendance_request: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          mark_as_read_success: false,
          create_attendance_success: false,
          loading: true,
        },
      };
    }

    case attendanceActions.ATTENDANCE.create_attendance_success: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          mark_as_read_success: action?.payload,
          create_attendance_success: action?.payload,
          loading: false,
        },
      };
    }

    case attendanceActions.ATTENDANCE.create_attendance_error: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          mark_as_read_success: false,
          create_attendance_success: false,
          loading: false,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_attendance_setting_reset: {
      return {
        ...state,
        mark_attendance_setting: {
          ...INITIAL_STATE.mark_attendance_setting,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_student_attendance_success: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          get_student_attendance: action?.payload || [],
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_student_session_attendance_success: {
      return {
        ...state,
        get_student_session_attendance: {
          ...state.get_student_session_attendance,
          session_attendance: action?.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_session_list_filter_success: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          session_list_by_filter: action?.payload || [],
          totalCount: action?.payload?.count,
        },
      };
    }

    case attendanceActions.ATTENDANCE
      .post_students_session_attendance_success: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          session_create_bulk_attendance: action?.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE.put_students_session_attendance_success: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          session_edit_bulk_attendance: action?.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE.update_bulk_attendance_success: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          update_bulk_attendance_success: action?.payload || '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.update_bulk_attendance_reset: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          update_bulk_attendance_success: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.download_faculty_attendance_request: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          faculty_attendance: {},
          loading: true,
        },
      };
    }

    case attendanceActions.ATTENDANCE.download_faculty_attendance_success: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          faculty_attendance: { ...action?.payload },
          loading: false,
        },
      };
    }

    case attendanceActions.ATTENDANCE.download_faculty_attendance_error: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          faculty_attendance: {},
          loading: false,
        },
      };
    }

    //post attendance for meet type
    case attendanceActions.ATTENDANCE.post_session_list_filter_request: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          faculty_attendance: {},
          loading: true,
        },
      };
    }

    case attendanceActions.ATTENDANCE.post_session_list_filter_success: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          faculty_attendance: { ...action?.payload },
          loading: false,
        },
      };
    }

    case attendanceActions.ATTENDANCE.post_session_list_filter_error: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          faculty_attendance: {},
          loading: false,
        },
      };
    }

    case attendanceActions.ATTENDANCE.download_faculty_attendance_reset: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          faculty_attendance: {},
          loading: false,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_faculty_program_list_success: {
      return {
        ...state,
        faculty: {
          ...state.faculty,
          facultyProgramList: action?.payload,
        },
      };
    }
    case attendanceActions.ATTENDANCE.get_program_attendance_success: {
      return {
        ...state,
        faculty: {
          ...state.faculty,
          programAttendance: action?.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_faculty_student_attendance_request: {
      return {
        ...state,
        faculty: {
          ...state.faculty,
          studentAttendance: '',
          loading: true,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_faculty_student_attendance_success: {
      return {
        ...state,
        faculty: {
          ...state.faculty,
          studentAttendance: action?.payload,
          loading: false,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_faculty_student_attendance_error: {
      return {
        ...state,
        faculty: {
          ...state.faculty,
          studentAttendance: '',
          loading: false,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_faculty_student_attendance_reset: {
      return {
        ...state,
        faculty: {
          ...state.faculty,
          studentAttendance: '',
          loading: false,
        },
      };
    }

    // Get Live session list

    case attendanceActions.ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_REQUEST: {
      return {
        ...state,
        liveSessionList: {
          ...state.liveSessionList,
          loading: true,
          error: false,
          errorMessage: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_SUCCESS: {
      return {
        ...state,
        liveSessionList: { data: action?.payload, error: false },
      };
    }

    case attendanceActions.ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_ERROR: {
      return {
        ...state,
        liveSessionList: {
          ...state.liveSessionList,
          loading: false,
          data: [],
          error: true,
          errorMessage: action?.payload || '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_RESET: {
      return {
        ...state,
        liveSessionList: {
          ...state.liveSessionList,
          loading: false,
          data: [],
          error: false,
          errorMessage: '',
        },
      };
    }

    // Apply leave attendance

    case attendanceActions.ATTENDANCE.APPLY_LEAVE_PUT_REQUEST: {
      return {
        ...state,
        applyLeaveAttendance: {
          ...state.applyLeaveAttendance,
          loading: true,
          error: false,
          errorMessage: '',
          data: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.APPLY_LEAVE_PUT_SUCCESS: {
      return {
        ...state,
        applyLeaveAttendance: {
          ...state.applyLeaveAttendance,
          loading: false,
          data: action.payload || '',
          error: false,
          errorMessage: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.APPLY_LEAVE_PUT_ERROR: {
      return {
        ...state,
        applyLeaveAttendance: {
          ...state.applyLeaveAttendance,
          loading: false,
          data: '',
          error: true,
          errorMessage: action?.payload || '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.APPLY_LEAVE_PUT_RESET: {
      return {
        ...state,
        applyLeaveAttendance: {
          ...state.applyLeaveAttendance,
          loading: false,
          data: '',
          error: false,
          errorMessage: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE
      .send_student_attendance_notification_success: {
      return {
        ...state,
        studentNotification: {
          ...state.studentNotification,
          success: action.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE
      .send_student_attendance_notification_error: {
      return {
        ...state,
        studentNotification: {
          ...state.studentNotification,
          error: action.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE
      .send_student_attendance_notification_loader: {
      return {
        ...state,
        studentNotification: {
          ...state.studentNotification,
          loader: action.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE
      .send_student_attendance_notification_reset: {
      return {
        ...state,
        studentNotification: {
          ...INITIAL_STATE.studentNotification,
        },
        studentParentEmails: { ...INITIAL_STATE.studentParentEmails },
      };
    }

    case attendanceActions.ATTENDANCE.get_student_parent_emails_success: {
      return {
        ...state,
        studentParentEmails: {
          ...state.studentParentEmails,
          data: action.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE.get_student_parent_emails_error: {
      return {
        ...state,
        studentParentEmails: {
          ...state.studentParentEmails,
          data: '',
          error: action.payload,
        },
      };
    }

    // dashboard card attendance details

    case attendanceActions.ATTENDANCE
      .GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_SUCCESS: {
      return {
        ...state,
        dashboardCardDetails: action?.payload || '',
      };
    }

    case attendanceActions.ATTENDANCE
      .GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_ERROR: {
      return {
        ...state,
        dashboardCardDetails: '',
      };
    }

    case attendanceActions.ATTENDANCE
      .GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_RESET: {
      return {
        ...state,
        dashboardCardDetails: '',
      };
    }

    case attendanceActions.ATTENDANCE.APPLY_FOR_LEAVE_SUCCESS: {
      return {
        ...state,
        applyLeave: {
          success: action?.payload,
          error: '',
          loading: action?.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE.APPLY_FOR_LEAVE_ERROR: {
      return {
        ...state,
        applyLeave: { success: '', error: action?.payload },
      };
    }

    case attendanceActions.ATTENDANCE.SET_FETCH_STUDENT_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        fetchAttendanceRequst: {
          success: action?.payload,
          error: '',
          loading: false,
        },
      };
    }
    case attendanceActions.ATTENDANCE.FETCH_STUDENT_ATTENDANCE_REQUEST: {
      return {
        ...state,
        fetchAttendanceRequst: {
          loading: true,
        },
      };
    }

    case attendanceActions.ATTENDANCE.SET_FETCH_STUDENT_ATTENDANCE_ERROR: {
      return {
        ...state,
        fetchAttendanceRequst: {
          success: '',
          error: action?.payload,
          loading: true,
        },
      };
    }

    case attendanceActions.ATTENDANCE.SET_FETCH_STUDENT_ATTENDANCE_LOADING: {
      return {
        ...state,
        fetchAttendanceRequst: {
          ...state.fetchAttendanceRequst,
          loading: action?.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE.SET_GET_REQUEST_BY_ID_SUCCESS: {
      return {
        ...state,
        fetchRequestId: {
          success: action?.payload,
          error: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.SET_GET_REQUEST_BY_ID_ERROR: {
      return {
        ...state,
        fetchRequestId: {
          success: '',
          error: action?.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE.RESET_REQUEST_BY_ID: {
      return {
        ...state,
        fetchRequestId: {
          success: '',
          error: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.SET_FETCH_STUDENT_ATTENDANCE_RESET: {
      return {
        ...state,
        fetchAttendanceRequst: '',
      };
    }

    case attendanceActions.ATTENDANCE.FACULTY_STUDENT_APPROVE_SUCCESS: {
      return {
        ...state,
        approveDeclineRequest: {
          success: action?.payload,
          error: '',
        },
      };
    }

    // update Remark attendance

    case attendanceActions.ATTENDANCE.UPDATE_REMARK_REQUEST: {
      return {
        ...state,
        updateRemark: {
          ...state.updateRemark,
          loading: true,
          error: false,
          errorMessage: '',
          data: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.UPDATE_REMARK_SUCCESS: {
      return {
        ...state,
        updateRemark: {
          ...state.updateRemark,
          loading: false,
          data: action.payload || '',
          error: false,
          errorMessage: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.UPDATE_REMARKE_ERROR: {
      return {
        ...state,
        updateRemark: {
          ...state.updateRemark,
          loading: false,
          data: '',
          error: true,
          errorMessage: action?.payload || '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.UPDATE_REMARK_RESET: {
      return {
        ...state,
        updateRemark: {
          ...state.updateRemark,
          loading: false,
          data: '',
          error: false,
          errorMessage: '',
        },
      };
    }

    case attendanceActions.ATTENDANCE.SET_UPDATE_MY_REQUEST_SUCCESS: {
      return {
        ...state,
        updateRequest: {
          success: action?.payload,
          error: '',
          loading: action?.payload,
        },
      };
    }
    case attendanceActions.ATTENDANCE.SET_UPDATE_MY_REQUEST_ERROR: {
      return {
        ...state,
        updateRequest: {
          success: '',
          error: action?.payload,
        },
      };
    }
    case attendanceActions.ATTENDANCE.SET_ORDINANCE_SETTING_REQUEST_SUCCESS: {
      return {
        ...state,
        ordinanceSetting: {
          success: action?.payload,
          error: '',
        },
      };
    }
    case attendanceActions.ATTENDANCE.SET_ORDINANCE_SETTING_REQUEST_ERROR: {
      return {
        ...state,
        ordinanceSetting: {
          success: '',
          error: action?.payload,
        },
      };
    }

    case attendanceActions.ATTENDANCE
      .SET_UPDATE_ORDINANCE_SETTING_REQUEST_SUCCESS: {
      return {
        ...state,
        updateOrdinanceSetting: {
          success: action?.payload,
          error: '',
        },
      };
    }
    case attendanceActions.ATTENDANCE
      .SET_UPDATE_ORDINANCE_SETTING_REQUEST_ERROR: {
      return {
        ...state,
        updateOrdinanceSetting: {
          success: '',
          error: action?.payload,
        },
      };
    }
    case attendanceActions.ATTENDANCE.UPDATE_ATTENDACE_SUCCESS: {
      return {
        ...state,
        user_attendance: {
          ...state.user_attendance,
          UPDATE_ATTENDACE_SUCCESS: action?.payload,
        },
      };
    }
    case attendanceActions.ATTENDANCE.DOWNLOAD_ALL_SUCCESS: {
      return {
        ...state,
        DownloadAllRequest: {
          ...state.DownloadAllRequest,
          data: action?.payload || {},
        },
      };
    }
    case attendanceActions.ATTENDANCE.DOWNLOAD_ALL_RESET: {
      return {
        ...state,
        DownloadAllRequest: {
          ...state.DownloadAllRequest,
          data: {},
        },
      };
    }
    case attendanceActions.PostSessionListByFilterReset: {
      return {
        ...state,
        getSessionList: {
          ...state.getSessionList,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};

export default attendanceReducer;
