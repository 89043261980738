export const ddmmmyyyy = 'DD MMM YYYY';
export const yyyy_mm_dd = 'YYYY-MM-DD';
export const dd_mm_yyyy = 'DD-MM-YYYY';
export const hh_mm_A = 'hh:mm A';
export const ddmmmmyyyy = 'DD MMMM YYYY';
export const hh_mm_ss_A = 'hh:mm:ss A';
export const hh_mm_a = 'hh:mm a';
export const D_MMMM_YYYY_h_mm_a = 'D MMMM YYYY | h:mm A';
export const D_MMMM_YYYY_h_mm_A = 'D MMMM YYYY, h:mm A';
export const D_MMM_YYYY_h_mm_A = 'D MMM YYYY, h:mm A';
export const D_MMMM_YYYY_h_mm_ss_A = 'D MMMM YYYY | h:mm:ss A';
export const D_MMMM_YYYY_h_mm_ss = 'D MMMM YYYY | h:mm:ss';
export const MM_DD_YYYY_HH_mm_ss = 'MM/DD/YYYY HH:mm:ss';
export const D_MMMM_YYYY = 'D MMMM YYYY';
export const D_MMMM = 'D MMMM';
export const D_MMM_YYYY_h_mm_ss_a = 'D MMM YYYY | h:mm:ss A';
export const hh_mm = 'hh:mm';
export const D_MMMM_YYYY_h_mm = 'D MMM YYYY | h:mm';
export const HH_mm_ss = 'HH:mm:ss';
export const YYYY_MM_DD_hh_mm = 'YYYY-MM-DD hh:mm';
export const YYYY_MM_DD_hh_mm_ss = 'YYYY-MM-DD hh:mm:ss';
export const D_MMMM_YYYY_hh_mm = 'D MMM YYYY | hh:mm';
export const D_MMM_YY_hh_mm_a = 'D MMM YY,h:mm A';
export const HH_mm = 'HH:mm';
export const ddddMMMMD = 'dddd MMMM D';
export const h_mm_A = 'h:mm A';
export const dddd = 'dddd';
export const DD_MM_YYYY_HH_mm = 'DD-MM-YYYY HH:mm';
export const YYYY_MM_DDTHH_mm = 'YYYY-MM-DDTHH:mm';
