import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormTextField } from 'components';
import {
  Grid,
  Typography,
  Divider,
  Button,
  Stack,
  TextArea,
  TextField,
} from 'iiht-b2a-ui-components/build/components';
import { validationRules } from 'constants/index';

const DescriptiveAnswer = ({
  id,
  question,
  answer = '',
  status = 0,
  idx,
  questionId,
  displayTextField,
  handleAnswerChange = () => {},
  displayMarks = false,
  marks = 0,
  imgUrl = '',
}) => {
  const { t } = useTranslation();
  const [ans, setAns] = useState('');

  const methods = useForm({
    defaultValues: {
      answer: '',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    if (ans !== '') {
      handleAnswerChange(questionId, ans);
    }
  }, [ans]);

  return (
    <>
      {(status === 0 && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Grid item>
                <Typography
                  className="font-19"
                  sx={{
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    fontWeight: 'bold',
                  }}
                  key={`question_${id}`}
                >
                  {`Q. ${question}`}
                </Typography>
                {displayMarks &&
                  typeof imgUrl === 'string' &&
                  imgUrl?.length !== 0 && (
                    <Grid item xl={3} md={3}>
                      <img src={imgUrl} alt="dep-image" className="dep-image" />
                    </Grid>
                  )}
              </Grid>
              <Grid item>
                {displayMarks && (
                  <Typography
                    className="font-12 mt3"
                    align="right"
                  >{`Marks: ${marks}`}</Typography>
                )}
              </Grid>
            </Grid>
            {status === 0 && (
              <FormTextField
                id="search-bar"
                name="answer"
                onInput={(e) => {
                  setAns(e.target.value);
                }}
                label={t('myLearning.writeYourAnswer')}
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={6}
              />
            )}

            {status === 3 && displayTextField && (
              <FormTextField
                name={`description_${id}`}
                rules={{
                  required: validationRules.REQUIRED,
                }}
                variant="outlined"
                label={t('myLearning.writeYourAnswer')}
                size="small"
                fullWidth
                multiline
                rows={6}
                value={answer?.[0]?.answer || answer}
                readOnly={true}
                disabled={true}
              />
            )}
          </form>
        </FormProvider>
      )) ||
        (status === 3 && displayTextField && (
          <div className="preview_answer">
            <Grid
              container
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Grid item>
                <Typography
                  className="font-19"
                  sx={{
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    fontWeight: 'bold',
                  }}
                  key={`question_${id}`}
                >
                  {`Q. ${question}`}
                </Typography>
                {displayMarks &&
                  typeof imgUrl === 'string' &&
                  imgUrl?.length !== 0 && (
                    <Grid item xl={3} md={3}>
                      <img src={imgUrl} alt="dep-image" className="dep-image" />
                    </Grid>
                  )}
              </Grid>
              <Grid item>
                {displayMarks && (
                  <Typography
                    className="font-12 mt3"
                    align="right"
                  >{`Marks: ${marks}`}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Typography variant="body2">
                {answer?.[0]?.answer || answer}
              </Typography>
            </Grid>
          </div>
        ))}
    </>
  );
};

export default DescriptiveAnswer;
