import React, { useEffect, useState } from 'react';
import { TextField } from 'iiht-b2a-ui-components/build/components';
import IconButton from 'iiht-b2a-ui-components/build/components/IconButton';
import EditIconSvg from 'icons/EditIcon';
import SearchIcon from 'icons/SearchIcon';
import { theme } from 'themes';

export const SearchBar = ({
  details,
  className,
  placeholder = 'Search...',
  ...rest
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (details) {
      details(searchQuery);
    }
  }, [searchQuery]);

  return (
    <form
      onSubmit={(eve) => {
        eve.preventDefault();
        console.log(eve.target[0].value);
      }}
    >
      <TextField
        id="search-bar"
        className={className}
        onInput={(e) => {
          setSearchQuery(e.target.value);
        }}
        variant="outlined"
        placeholder={placeholder}
        size="small"
        InputProps={{
          endAdornment: (
            <IconButton type="submit" aria-label="search">
              <SearchIcon fill={theme.palette.grey.P1000} />
            </IconButton>
          ),
        }}
        {...rest}
      />
    </form>
  );
};
