import axios from 'axios';
import { translator } from './constants/actionTypes';
import { localStorageHelper } from './utils/storageHelper';
import { getOidcUserDetails, saveUserInLocalStorage } from 'utils';
import QueryString from 'qs';
import { User } from 'data/roles';

const urlPath = window.location.href;
const axiosInstance = axios.create({
  paramsSerializer: (params) =>
    QueryString.stringify(params, { arrayFormat: 'repeat' }),
});

axiosInstance.interceptors.request.use(async function (config) {
  config.baseURL = `${config?.baseURL}/`;

  const access_token = localStorageHelper.get('access_token');
  const login_type = localStorageHelper.get('loginType');
  const tenantId = localStorageHelper.get('tenantId');
  if (!!!access_token) {
    const userDetails = getOidcUserDetails();
    if (userDetails?.access_token) {
      const info = await saveUserInLocalStorage(userDetails);
      if (info.access_token)
        config.headers.Authorization = `Bearer ${info.access_token}`;
      if (info.tenantId) config.headers.tenant = `${info.tenantId}`;
    }
  } else {
    config.headers.Authorization = `Bearer ${access_token}`;
    config.headers.tenant = `${tenantId}`;
  }

  if (login_type === User.otpUser) {
    config.headers['Auth-Type'] = 'CandidateUser';
  }

  // config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const errorData = error?.response?.data?.message;
    if (
      errorData == 'Token is expired' ||
      errorData === 'Token required' ||
      errorData === 'Invalid token'
    ) {
      localStorage.clear();

      window.location.href = '/';
    }
    return error?.response;
  }
);

if (urlPath.includes(translator.baseURL)) {
  axiosInstance.defaults.baseURL = translator.baseURL;
} else if (urlPath.includes(translator.localhost3000)) {
  axiosInstance.defaults.baseURL = translator.baseURL;
} else if (urlPath.includes(translator.localhost3001)) {
  axiosInstance.defaults.baseURL = translator.baseURL;
} else if (urlPath.includes(translator.qaBaseURL)) {
  axiosInstance.defaults.baseURL = translator.qaBaseURL;
} else if (urlPath.includes(translator.prefBaseURL)) {
  axiosInstance.defaults.baseURL = translator.prefBaseURL;
} else if (urlPath.includes(translator.stageBaseURL)) {
  axiosInstance.defaults.baseURL = translator.stageBaseURL;
} else if (urlPath.includes(translator.prodBaseURL)) {
  axiosInstance.defaults.baseURL = translator.prodBaseURL;
} else if (urlPath.includes(translator.uatBaseURL)) {
  axiosInstance.defaults.baseURL = translator.uatBaseURL;
} else if (urlPath.includes(translator.hotfixBaseURL)) {
  axiosInstance.defaults.baseURL = translator.hotfixBaseURL;
} else {
  axiosInstance.defaults.baseURL = translator.baseURL;
}

export default axiosInstance;
