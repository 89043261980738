import React from 'react';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { resetToastMessage } from 'store/Toast';

function ToastMessage() {
  const { show, data, severity, duration, variant, vertical, horizontal } =
    useSelector((state) => state.toastState);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetToastMessage());
  };

  const defaultMessage = (severity) => {
    switch (severity) {
      case 'success':
        return i18n.t('operation.success');
      case 'error':
        return i18n.t('something.wrong');
      default:
        return i18n.t('something.wrong');
    }
  };

  const Alert = React.forwardRef((props, ref) => {
    return (
      <MuiAlert {...props} ref={ref}>
        {data || defaultMessage(props.severity)}
      </MuiAlert>
    );
  });

  return show ? (
    <Snackbar
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      open={show}
      autoHideDuration={duration || 5}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant={variant}
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}
      />
    </Snackbar>
  ) : (
    <></>
  );
}

export default ToastMessage;
