import React from 'react';
const StarIcon = ({ fill, height = 40, width = 40 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      fill={fill}
      width={width}
      height={height}
    >
      <path d="m668 716 165.334-142L930 582 745.333 741l55.333 239.667L717.333 930 668 716Zm-93.333-290-52.001-122.001L559.333 216l92 217-76.666-7ZM273.333 837.001 420 749l146.667 89.001-39-166.668 129.001-112-170.001-15L420 386.999l-66.667 156.334-170.001 15 129.001 112.334-39 166.334ZM173 976l65-281L20 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-343.333Z" />
    </svg>
  );
};

export default StarIcon;
