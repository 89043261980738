import { takeLatest, put, call, all } from 'redux-saga/effects';
import { statusCode } from 'constants/apis/apiStatus';
import { setToastMessage } from 'store/Toast';
import { assessmentRepositoryActions } from './AssessmentRepository.constants';
import {
  createQuestionBankApi,
  createQuestionBankBulkUploadApi,
  createRubricsApi,
  deleteQuestionBankApi,
  deleteRubricsByIdApi,
  evaluateQuestionBankApi,
  getQuestionBankDetailstApi,
  getQuestionBankListApi,
  getRubricsByIdApi,
  listRubricsApi,
  updateRubricsByIdApi,
  editQuestionApi,
  deleteQuestionApi,
  createAssessmentApi,
  getAssessmentListApi,
  deleteAssessmentApi,
  getAssessmentDetailstApi,
  editAssessmentSectionApi,
  createAssessmentSectionApi,
  deleteSectionApi,
  importQuestionApi,
  sendForApprovalApi,
  updateAssessmentApi,
  evaluateAssessmentApi,
} from './AssessmentRepository.api';
import {
  createAssessmentRepositoryBulkUploadError,
  createAssessmentRepositoryBulkUploadSuccess,
  createAssessmentRepositoryError,
  createAssessmentRepositoryRubricsError,
  createAssessmentRepositoryRubricsSuccess,
  createAssessmentRepositorySuccess,
  deleteAssessmentRepositoryRubricsError,
  deleteAssessmentRepositoryRubricsSuccess,
  deleteQuestionBankError,
  deleteQuestionBankSuccess,
  evaluateQuestionBankError,
  evaluateQuestionBankSuccess,
  getAssessmentRepositoryRubricsError,
  getAssessmentRepositoryRubricsSuccess,
  getQuestionBankDetailsByIdError,
  getQuestionBankDetailsByIdSuccess,
  getQuestionBankListError,
  getQuestionBankListSuccess,
  listAssessmentRepositoryRubricsError,
  listAssessmentRepositoryRubricsSuccess,
  questionBankLoader,
  rubricsLoader,
  updateAssessmentRepositoryRubricsError,
  updateAssessmentRepositoryRubricsSuccess,
  editQuestionSuccess,
  editQuestionError,
  deleteQuestionError,
  deleteQuestionSuccess,
  createAssessmentDetailsError,
  createAssessmentDetailsSuccess,
  getAssessmentListError,
  getAssessmentListSuccess,
  deleteAssessmentDetailsSuccess,
  deleteAssessmentDetailsError,
  getAssessmentDetailsByIdSuccess,
  getAssessmentDetailsByIdError,
  updateSectionError,
  updateSectionSuccess,
  createAssessmentSectionSuccess,
  createAssessmentSectionError,
  deleteSectionSuccess,
  deleteSectionError,
  importQuestionSuccess,
  importQuestionError,
  sendForApprovalSuccess,
  sendForApprovalError,
  updateAssessmentSuccess,
  updateAssessmentError,
  evaluateAssessmentSuccess,
  evaluateAssessmentError,
} from './AssessmentRepository.action';

function* createQuestionBankSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(createQuestionBankApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createAssessmentRepositorySuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Question bank created successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(createAssessmentRepositoryError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(createAssessmentRepositoryError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* createQuestionBankBulkUploadSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(createQuestionBankBulkUploadApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createAssessmentRepositoryBulkUploadSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Question bank created successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(createAssessmentRepositoryBulkUploadError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(createAssessmentRepositoryBulkUploadError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* createQuestionBankRubricsSaga({ payload }) {
  try {
    yield put(rubricsLoader(true));
    const response = yield call(createRubricsApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createAssessmentRepositoryRubricsSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Rubric created successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(createAssessmentRepositoryRubricsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(rubricsLoader(false));
  } catch (error) {
    yield put(createAssessmentRepositoryRubricsError(error));
    yield put(rubricsLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* listQuestionBankRubricsSaga({ payload }) {
  try {
    yield put(rubricsLoader(true));
    const response = yield call(listRubricsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(listAssessmentRepositoryRubricsSuccess(response?.data));
    } else {
      yield put(listAssessmentRepositoryRubricsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(rubricsLoader(false));
  } catch (error) {
    yield put(listAssessmentRepositoryRubricsError(error));
    yield put(rubricsLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getRubricsByIdSaga({ payload }) {
  try {
    yield put(rubricsLoader(true));
    const response = yield call(getRubricsByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentRepositoryRubricsSuccess(response?.data));
    } else {
      yield put(getAssessmentRepositoryRubricsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(rubricsLoader(false));
  } catch (error) {
    yield put(getAssessmentRepositoryRubricsError(error));
    yield put(rubricsLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* deleteRubricsByIdSaga({ payload }) {
  try {
    yield put(rubricsLoader(true));
    const response = yield call(deleteRubricsByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteAssessmentRepositoryRubricsSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Rubric deleted successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteAssessmentRepositoryRubricsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(rubricsLoader(false));
  } catch (error) {
    yield put(deleteAssessmentRepositoryRubricsError(error));
    yield put(rubricsLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* updateRubricsByIdSaga({ payload }) {
  try {
    yield put(rubricsLoader(true));
    const response = yield call(updateRubricsByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateAssessmentRepositoryRubricsSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Rubric updated successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(updateAssessmentRepositoryRubricsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(rubricsLoader(false));
  } catch (error) {
    yield put(updateAssessmentRepositoryRubricsError(error));
    yield put(rubricsLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getQuestionBankListSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(getQuestionBankListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getQuestionBankListSuccess(response.data));
    } else {
      yield put(getQuestionBankListError(response.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(getQuestionBankListError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getQuestionBankDetailsSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(getQuestionBankDetailstApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getQuestionBankDetailsByIdSuccess(response.data));
    } else {
      yield put(getQuestionBankDetailsByIdError(response.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(getQuestionBankDetailsByIdError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* deleteQuestionBankSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(deleteQuestionBankApi, payload);
    if (response.status === statusCode.NoContent204) {
      yield put(deleteQuestionBankSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Question bank deleted successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteQuestionBankError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(deleteQuestionBankError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* evaluateQuestionBankSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(evaluateQuestionBankApi, payload);
    if (response.status === statusCode.Ok200) {
      yield put(evaluateQuestionBankSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Evaluation Updated Successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(evaluateQuestionBankError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(evaluateQuestionBankError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* editQuestionSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(editQuestionApi, payload);
    if (response.status === statusCode.Ok200) {
      yield put(editQuestionSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Question Updated Successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(editQuestionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(editQuestionError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* deleteQuestionSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(deleteQuestionApi, payload);
    if (response.status === statusCode.Ok200) {
      yield put(deleteQuestionSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Question deleted successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteQuestionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(deleteQuestionError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* createAssessmentSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(createAssessmentApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createAssessmentDetailsSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Assessment created successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(createAssessmentDetailsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(createAssessmentDetailsError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getAssessmentListSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(getAssessmentListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentListSuccess(response.data));
    } else {
      yield put(getAssessmentListError(response.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(getAssessmentListError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* deleteAssessmentSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(deleteAssessmentApi, payload);
    if (response.status === statusCode.Ok200) {
      yield put(deleteAssessmentDetailsSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Assessment deleted successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteAssessmentDetailsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(deleteAssessmentDetailsError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getAssessmentDetailsSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(getAssessmentDetailstApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentDetailsByIdSuccess(response.data));
    } else {
      yield put(getAssessmentDetailsByIdError(response.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(getAssessmentDetailsByIdError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* editSectionSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(editAssessmentSectionApi, payload);
    if (response.status === statusCode.Created201) {
      yield put(updateSectionSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Section Updated Successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(updateSectionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(updateSectionError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* createAssessmentSectionSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(createAssessmentSectionApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createAssessmentSectionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Section created successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(createAssessmentSectionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(createAssessmentSectionError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* deleteSectionSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(deleteSectionApi, payload);
    if (response.status === statusCode.Ok200) {
      yield put(deleteSectionSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Section deleted successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteSectionError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(deleteSectionError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* importQuestionSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(importQuestionApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(importQuestionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Questions Imported successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(importQuestionError(response?.data));
      yield put(
        setToastMessage({
          data:
            response?.data?.message ||
            'Total Score of section questions exceeds section marks',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(importQuestionError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* sendForApprovalSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(sendForApprovalApi, payload);
    if (response.status === statusCode.Ok200) {
      yield put(sendForApprovalSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Sent for Approval.',
          severity: 'success',
        })
      );
    } else {
      yield put(sendForApprovalError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Validation Failed',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(sendForApprovalError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Validation Failed',
        severity: 'error',
      })
    );
  }
}

function* updateAssessmentSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(updateAssessmentApi, payload);
    if (response.status === statusCode.Created201) {
      yield put(updateAssessmentSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Assessment Updated Successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(updateAssessmentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(updateAssessmentError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* evaluateAssessmentSaga({ payload }) {
  try {
    yield put(questionBankLoader(true));
    const response = yield call(evaluateAssessmentApi, payload);
    if (response.status === statusCode.Ok200) {
      yield put(evaluateAssessmentSuccess(response.data));
      yield put(
        setToastMessage({
          data: 'Evaluation Updated Successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(evaluateAssessmentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(questionBankLoader(false));
  } catch (error) {
    yield put(evaluateAssessmentError(error));
    yield put(questionBankLoader(false));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}
export function* watchDummy() {
  yield takeLatest(
    assessmentRepositoryActions.createQuestionBank.create,
    createQuestionBankSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.createQuestionBankBulkUpload.create,
    createQuestionBankBulkUploadSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.createRubrics.create,
    createQuestionBankRubricsSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.listRubrics.get,
    listQuestionBankRubricsSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.getRubricsById.get,
    getRubricsByIdSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.deleteRubricsById.delete,
    deleteRubricsByIdSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.updateRubricsById.update,
    updateRubricsByIdSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.getQuestionBankList.get,
    getQuestionBankListSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.getQuestionBankDetailsById.get,
    getQuestionBankDetailsSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.deleteQuestionBank.delete,
    deleteQuestionBankSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.evaluateQuestionBank.update,
    evaluateQuestionBankSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.editQuestion.update,
    editQuestionSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.deleteQuestion.delete,
    deleteQuestionSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.createAssessment.create,
    createAssessmentSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.getAssessmentList.get,
    getAssessmentListSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.deleteAssessment.delete,
    deleteAssessmentSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.getAssessmentDetailsById.get,
    getAssessmentDetailsSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.editSection.update,
    editSectionSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.createSection.create,
    createAssessmentSectionSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.deleteSection.delete,
    deleteSectionSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.importQuestion.import,
    importQuestionSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.sendAssessmentForApproval.update,
    sendForApprovalSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.updateAssessent.update,
    updateAssessmentSaga
  );
  yield takeLatest(
    assessmentRepositoryActions.evaluateAssesment.evaluate,
    evaluateAssessmentSaga
  );
}

export default function* attendanceSaga() {
  yield all([watchDummy()]);
}
