export function isTextValid(value) {
  return value?.length > 3 ? true : false;
}

export function isEmpty(value) {
  return !value || value.length === 0;
}

export function isPasswordValid(value) {
  return value?.length > 0 ? true : false;
}

export function isPasswordConfirmed(password, confirmPassword) {
  return password === confirmPassword;
}

export function isValidPassword(value) {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,20}$/;
  const flag = re.test(value);
  return flag;
}

export function isEmailValid(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value?.toLowerCase()) ? true : false;
}

export function isValidUUID(value) {
  const re =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return re.test(value?.toLowerCase()) ? true : false;
}

export function IsUrl(value) {
  const re =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return re.test(value?.toLowerCase()) ? true : false;
}

export function validateFileType(fileTypeArr, file) {
  if (file.type) {
    return fileTypeArr.includes(file?.type);
  } else {
    // in .srt file type getting null in file object
    const getFileType = file?.name.split('.')[1];
    const foundType = fileTypeArr.find(
      (f) => f.split('/')[1]?.toLowerCase() === getFileType?.toLowerCase()
    );
    if (foundType) {
      return true;
    }
  }
  return false;
}

export function validateFileSize(validSize, file) {
  const fSize = (file?.size / 1024 / 1024).toFixed(2);
  return parseInt(fSize) <= validSize;
}
