import * as React from 'react';

const Medal = ({ fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="15"
    viewBox="0 -960 960 960"
    width="24"
    fill={fill}
    {...rest}
  >
    <path d="m385-412 36-115-95-74h116l38-119 37 119h117l-95 74 35 115-94-71-95 71ZM244-40v-304q-45-47-64.5-103T160-560q0-136 92-228t228-92q136 0 228 92t92 228q0 57-19.5 113T716-344v304l-236-79-236 79Zm236-260q109 0 184.5-75.5T740-560q0-109-75.5-184.5T480-820q-109 0-184.5 75.5T220-560q0 109 75.5 184.5T480-300ZM304-124l176-55 176 55v-171q-40 29-86 42t-90 13q-44 0-90-13t-86-42v171Zm176-86Z" />
  </svg>
);

export default Medal;
