import { courseContentActions } from './CourseContentFeedback.constants';
const {
  COURSECONTENT,
  COURSESECTION,
  GETSECTION,
  DELETESECTION,
  UPDATESECTION,
  GETCOURSECONTENT,
  GETCOURSECONTENTBYID,
  UPDATECOURSECONTENT,
  CREATEQUESTION,
  CREATEDUPLICATE,
  DELETECONTENT,
  GETQUESTION,
  GETQUESTIONBYID,
  UPDATEQUESTION,
  PUBLISHCOURSECONTENT,
  STOREIDS,
  GETQUESTIONBYSECTION,
  SAVEFEEDBACK,
} = courseContentActions;

export const courseContentFeedbackLoader = (payload) => ({
  type: courseContentActions.COURSE_CONTENT_FEEDBACK_LOADER,
  payload,
});

export const createCourseContentAction = (payload) => ({
  type: COURSECONTENT.create,
  payload: payload,
});

export const createCourseContentSuccess = (payload) => ({
  type: COURSECONTENT.create_success,
  payload: payload,
});

export const createCourseContentError = (payload) => ({
  type: COURSECONTENT.create_error,
  payload: payload,
});
export const createCourseContentReset = (payload) => ({
  type: COURSECONTENT.create_reset,
  payload: payload,
});
//section
export const createCourseSectionAction = (payload) => ({
  type: COURSESECTION.create,
  payload: payload,
});

export const createCourseSectionSuccess = (payload) => ({
  type: COURSESECTION.create_success,
  payload: payload,
});

export const createCourseSectionError = (payload) => ({
  type: COURSESECTION.create_error,
  payload: payload,
});
export const createCourseSectionReset = (payload) => ({
  type: COURSESECTION.create_reset,
  payload: payload,
});
// get section
export const getCourseSectionAction = (payload) => ({
  type: GETSECTION.GET_SECTION_ACTION,
  payload: payload,
});

export const getCourseSectionSuccess = (payload) => ({
  type: GETSECTION.GET_SECTION_SUCCESS,
  payload: payload,
});

export const getCourseSectionError = (payload) => ({
  type: GETSECTION.GET_SECTION_ERROR,
  payload: payload,
});
export const getCourseSectionReset = (payload) => ({
  type: GETSECTION.GET_SECTION_RESET,
  payload: payload,
});

//delete
export const deleteCourseSectionAction = (payload) => ({
  type: DELETESECTION.DELETE_SECTION_ACTION,
  payload: payload,
});

export const deleteCourseSectionSuccess = (payload) => ({
  type: DELETESECTION.DELETE_SECTION_SUCCESS,
  payload: payload,
});

export const deleteCourseSectionError = (payload) => ({
  type: DELETESECTION.DELETE_SECTION_ERROR,
  payload: payload,
});
export const deleteCourseSectionReset = (payload) => ({
  type: DELETESECTION.DELETE_SECTION_RESET,
  payload: payload,
});

// update
export const updateCourseSectionAction = (payload, req) => ({
  type: UPDATESECTION.UPDATE_SECTION_ACTION,
  payload: payload,
  req: req,
});

export const updateCourseSectionSuccess = (payload) => ({
  type: UPDATESECTION.UPDATE_SECTION_SUCCESS,
  payload: payload,
});

export const updateCourseSectionError = (payload) => ({
  type: UPDATESECTION.UPDATE_SECTION_ERROR,
  payload: payload,
});
export const updateCourseSectionReset = (payload) => ({
  type: UPDATESECTION.UPDATE_SECTION_RESET,
  payload: payload,
});
// get CourseContent
export const getCourseContentAction = (payload) => ({
  type: GETCOURSECONTENT.GET_COURSE_CONTENT_ACTION,
  payload: payload,
});

export const getCourseContentSuccess = (payload) => ({
  type: GETCOURSECONTENT.GET_COURSE_CONTENT_SUCCESS,
  payload: payload,
});

export const getCourseContentError = (payload) => ({
  type: GETCOURSECONTENT.GET_COURSE_CONTENT_ERROR,
  payload: payload,
});
export const getCourseContentReset = (payload) => ({
  type: GETCOURSECONTENT.GET_COURSE_CONTENT_RESET,
  payload: payload,
});
// get course content by id
export const getCourseContentByIdAction = (payload) => ({
  type: GETCOURSECONTENTBYID.GET_COURSE_CONTENT_BY_ID_ACTION,
  payload: payload,
});

export const getCourseContentByIdSuccess = (payload) => ({
  type: GETCOURSECONTENTBYID.GET_COURSE_CONTENT_BY_ID_SUCCESS,
  payload: payload,
});

export const getCourseContentByIdError = (payload) => ({
  type: GETCOURSECONTENTBYID.GET_COURSE_CONTENT_BY_ID_ERROR,
  payload: payload,
});
export const getCourseContentByIdReset = (payload) => ({
  type: GETCOURSECONTENTBYID.GET_COURSE_CONTENT_BY_ID_RESET,
  payload: payload,
});
//update Course Content
export const updateCourseContentAction = (payload, req) => ({
  type: UPDATECOURSECONTENT.UPDATE_COURSE_CONTENT_ACTION,
  payload: payload,
  req: req,
});

export const updateCourseContentSuccess = (payload) => ({
  type: UPDATECOURSECONTENT.UPDATE_COURSE_CONTENT_SUCCESS,
  payload: payload,
});

export const updateCourseContentError = (payload) => ({
  type: UPDATECOURSECONTENT.UPDATE_COURSE_CONTENT_ERROR,
  payload: payload,
});
export const updateCourseContentReset = (payload) => ({
  type: UPDATECOURSECONTENT.UPDATE_COURSE_CONTENT_RESET,
  payload: payload,
});
//create Question
export const createQuestionAction = (payload, req) => ({
  type: CREATEQUESTION.CREATE_QUESTION_ACTION,
  payload: payload,
  req: req,
});

export const createQuestionSuccess = (payload) => ({
  type: CREATEQUESTION.CREATE_QUESTION_SUCCESS,
  payload: payload,
});

export const createQuestionError = (payload) => ({
  type: CREATEQUESTION.CREATE_QUESTION_ERROR,
  payload: payload,
});
export const createQuestionReset = (payload) => ({
  type: CREATEQUESTION.CREATE_QUESTION_RESET,
  payload: payload,
});
// Create Duplicate
export const createDuplicateAction = (payload) => ({
  type: CREATEDUPLICATE.CREATE_DUPLICATE_ACTION,
  payload: payload,
});

export const createDuplicateSuccess = (payload) => ({
  type: CREATEDUPLICATE.CREATE_DUPLICATE_SUCCESS,
  payload: payload,
});

export const createDuplicateError = (payload) => ({
  type: CREATEDUPLICATE.CREATE_DUPLICATE_ERROR,
  payload: payload,
});
export const createDuplicateReset = (payload) => ({
  type: CREATEDUPLICATE.CREATE_DUPLICATE_RESET,
  payload: payload,
});
// delete
export const deleteContentAction = (payload) => ({
  type: DELETECONTENT.DELETE_CONTENT_ACTION,
  payload: payload,
});

export const deleteContentSuccess = (payload) => ({
  type: DELETECONTENT.DELETE_CONTENT_SUCCESS,
  payload: payload,
});

export const deleteContentError = (payload) => ({
  type: DELETECONTENT.DELETE_CONTENT_ERROR,
  payload: payload,
});
export const deleteContentReset = (payload) => ({
  type: DELETECONTENT.DELETE_CONTENT_RESET,
  payload: payload,
});
//get Question
export const getQuestionAction = (payload) => ({
  type: GETQUESTION.GET_QUESTION_ACTION,
  payload: payload,
});

export const getQuestionSuccess = (payload) => ({
  type: GETQUESTION.GET_QUESTION_SUCCESS,
  payload: payload,
});

export const getQuestionError = (payload) => ({
  type: GETQUESTION.GET_QUESTION_ERROR,
  payload: payload,
});
export const getQuestionReset = (payload) => ({
  type: GETQUESTION.GET_QUESTION_RESET,
  payload: payload,
});
//get Question bu id
export const getQuestionByIdAction = (payload) => ({
  type: GETQUESTIONBYID.GET_QUESTION_BY_ID_ACTION,
  payload: payload,
});

export const getQuestionByIdSuccess = (payload) => ({
  type: GETQUESTIONBYID.GET_QUESTION_BY_ID_SUCCESS,
  payload: payload,
});

export const getQuestionByIdError = (payload) => ({
  type: GETQUESTIONBYID.GET_QUESTION_BY_ID_ERROR,
  payload: payload,
});
export const getQuestionByIdReset = (payload) => ({
  type: GETQUESTIONBYID.GET_QUESTION_BY_ID_RESET,
  payload: payload,
});

// update question
export const updateQuestionAction = (payload, req) => ({
  type: UPDATEQUESTION.UPDATE_QUESTION_ACTION,
  payload: payload,
  req: req,
});

export const updateQuestionSuccess = (payload) => ({
  type: UPDATEQUESTION.UPDATE_QUESTION_SUCCESS,
  payload: payload,
});

export const updateQuestionError = (payload) => ({
  type: UPDATEQUESTION.UPDATE_QUESTION_ERROR,
  payload: payload,
});
export const updateQuestionReset = (payload) => ({
  type: UPDATEQUESTION.UPDATE_QUESTION_RESET,
  payload: payload,
});

// publish course
export const publishCourseContentAction = (payload) => ({
  type: PUBLISHCOURSECONTENT.PUBLISH_COURSE_CONTENT_ACTION,
  payload: payload,
});

export const publishCourseContentSuccess = (payload) => ({
  type: PUBLISHCOURSECONTENT.PUBLISH_COURSE_CONTENT_SUCCESS,
  payload: payload,
});

export const publishCourseContentError = (payload) => ({
  type: PUBLISHCOURSECONTENT.PUBLISH_COURSE_CONTENT_ERROR,
  payload: payload,
});
export const publishCourseContentReset = (payload) => ({
  type: PUBLISHCOURSECONTENT.PUBLISH_COURSE_CONTENT_RESET,
  payload: payload,
});
//save
export const saveIdsAction = (payload) => ({
  type: STOREIDS.STORE_IDS_ACTION,
  payload: payload,
});
//get Question
export const getQuestionBySectionAction = (payload) => ({
  type: GETQUESTIONBYSECTION.GET_QUESTION_BY_SECTION_ACTION,
  payload: payload,
});

export const getQuestionBySectionSuccess = (payload) => ({
  type: GETQUESTIONBYSECTION.GET_QUESTION_BY_SECTION_SUCCESS,
  payload: payload,
});

export const getQuestionBySectionError = (payload) => ({
  type: GETQUESTIONBYSECTION.GET_QUESTION_BY_SECTION_ERROR,
  payload: payload,
});
export const getQuestionBySectionReset = (payload) => ({
  type: GETQUESTIONBYSECTION.GET_QUESTION_BY_SECTION_RESET,
  payload: payload,
});

// save Feedback
export const saveFeedbackAction = (payload) => ({
  type: SAVEFEEDBACK.SAVE_FEEDBACK_ACTION,
  payload: payload,
});

export const saveFeedbackSuccess = (payload) => ({
  type: SAVEFEEDBACK.SAVE_FEEDBACK_SUCCESS,
  payload: payload,
});

export const saveFeedbackError = (payload) => ({
  type: SAVEFEEDBACK.SAVE_FEEDBACK_ERROR,
  payload: payload,
});
export const saveFeedbackReset = (payload) => ({
  type: SAVEFEEDBACK.SAVE_FEEDBACK_RESET,
  payload: payload,
});
