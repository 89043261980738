import { notesActions } from './Notes.constants';

const initialState = {
  noteSaveSuccess: false,
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case notesActions.SET_NOTE_SAVE_SUCCESS:
      return {
        ...state,
        noteSaveSuccess: true,
      };

    case notesActions.RESET_NOTE_SAVE_SUCCESS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default notesReducer;
