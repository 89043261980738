import { userManagementAPI } from 'store/UserManagement';
import axiosInstance from 'translator';
import { localStorageHelper } from 'utils';

export const getUserRolePermissionsApi = (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getUserRolePermissions}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserDetailsApi = (payload) => {
  payload?.userId ? (payload = payload.userId) : payload;
  return axiosInstance
    .get(`${userManagementAPI?.getUserDetails}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllRolesApi = () => {
  return axiosInstance
    .get(`${userManagementAPI?.getAllRoles}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllUserDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getAllUserDetails}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramStaffDetailsApi = (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getProgramStaffDetails}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramWithStudentDetailsApi = (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getProgramStudentDetails}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramDataApi = () => {
  return axiosInstance
    .get(`${userManagementAPI?.getAllProgramList}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createUserApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.createUser}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const createStudentApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.createStudent}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const updateStudentApi = async (payload) => {
  const id = payload?.id;
  return axiosInstance
    .put(`${userManagementAPI?.updateStudent}/${id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createUserCourseMappingApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.userMapping}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const userUploadValidationApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.userUploadValidation}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const userUploadApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.uploadUser}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStudentDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getStudentDetails}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDeptUserDetailsApi = (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getDeptUserDetails}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const uploadUserImageApi = (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.uploadUserImage}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getFilteredUserDetailsApi = (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getFilteredUserList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDesignationApi = () => {
  return axiosInstance
    .get(`${userManagementAPI?.getDesignationList}`, {
      params: { pagination: -1 },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteUserApi = async (payload) => {
  return axiosInstance
    .delete(`${userManagementAPI?.deleteUser}/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteStudentApi = async (payload) => {
  return axiosInstance
    .delete(`${userManagementAPI?.deleteStudent}/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getClustersListApi = (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getClusters}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getDepartmentsByIdApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getDepartmentsById}`, {
      params: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCoursesByIdApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getCoursesById}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const localUserLoginApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.localUserLogin}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const localUserLogoutApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.localUserLogout}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTenantPublicImagesRequest = async (payload) => {
  return axiosInstance
    .get(
      `${userManagementAPI?.get_public_tenant_images}?searchString=${payload?.tenantName}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCandidateToStudentApi = (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.update_candidate_to_student}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadToBeOnboardedApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.lead_onboarding_pending}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadOnboardedListApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.lead_onboarded}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCandidateLoginOtpApi = async (payload) => {
  return axiosInstance
    .post(
      `${userManagementAPI.candidateSendOtp}?type=${payload?.type}`,
      payload
    )
    .then((response) => response)
    .catch((error) => error);
};

export const getCandidateLoginVerifyOtpApi = async (payload) => {
  return axiosInstance
    .post(
      `${userManagementAPI.candidateVerifyOtp}?type=${payload?.type}`,
      payload
    )
    .then((response) => response)
    .catch((error) => error);
};

export const getCandidateNewAccessTokenApi = async (payload) => {
  return axiosInstance
    .post(userManagementAPI.refreshToken, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getCandidateInformationByIdApi = async (id) => {
  return axiosInstance
    .get(`${userManagementAPI.admission_lead}/${id}`)
    .then((response) => response)
    .catch((error) => error);
};

// Get all user list

export const getAllUserListApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getAllUserList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Get all User Roles
export const getAllUserRolesApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getAllUserRoles}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Delete User Role
export const deleteUserRolesApi = async (payload) => {
  return axiosInstance
    .delete(`${userManagementAPI?.getAllUserRoles}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Get  User Roles Details
export const getUserRoleDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getAllUserRoles}/${payload}`, {
      params: { pagination: -1 },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Create user role
export const createUserRoleApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.getAllUserRoles}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// user role
export const updateUserRoleApi = async (payload) => {
  const id = payload?.id;
  payload = payload?.payload;
  return axiosInstance
    .put(`${userManagementAPI?.getAllUserRoles}/${id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Create Group
export const createGroupApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.userGroup}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
// Edit Group
export const editGroupApi = async (payload, group_id) => {
  return axiosInstance
    .put(`${userManagementAPI?.userGroup}/${group_id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
//Delete group
export const deleteGroupApi = async (payload, group_id) => {
  return axiosInstance
    .delete(`${userManagementAPI?.userGroup}/${group_id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
//get group details
export const getGroupDetailsApi = async () => {
  return axiosInstance
    .get(`${userManagementAPI?.userGroup}`, {
      params: {
        pagination: -1,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
//get group details by id
export const getGropDetailsByIdApi = async (id) => {
  return axiosInstance
    .get(`${userManagementAPI.userGroupbyId}/${id}`)
    .then((response) => response)
    .catch((error) => error);
};

// search Api in group
export const searchUserApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI.searchUserGroup}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//filter user based on role and cluster
export const FilterUserApi = async (payload) => {
  const params = {
    ...payload,
    pagination: -1,
  };

  return axiosInstance
    .get(`${userManagementAPI?.filterStudentUser}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const FilterAllUserApi = async (payload) => {
  const params = {
    ...payload,
    pagination: -1,
  };

  return axiosInstance
    .get(`${userManagementAPI?.filterAllUser}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const removeUserAPI = async (groupId, userData) => {
  return axiosInstance
    .delete(`${userManagementAPI?.addAndRemoveUserGroup}/${groupId}`, {
      data: userData,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Add selected user to the group
export const addSelectedUserApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.addSelectedUser}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const forgotPasswordAPI = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.forgotPassword}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const userBulkGroupUpload = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.userGroupBulkUpload}`, payload)

    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const userDeactivateGroupAPI = async (group_id) => {
  return axiosInstance
    .patch(`${userManagementAPI?.userGroupbyId}/${group_id}`)

    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const blockUserApi = async (payload) => {
  const uid = payload.id;
  // const requestData = {
  //   is_active: payload.is_active,
  // };
  return axiosInstance
    .patch(`${userManagementAPI?.blockUser}/${uid}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserApi = async (payload) => {
  const id = payload.id;
  return axiosInstance
    .put(`${userManagementAPI?.updateUser}/${id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// login

export const getLoginDetailsApi = async (payload) => {
  const loginEndpoint =
    localStorageHelper.get('tenantName') === 'default'
      ? userManagementAPI?.superAdminLogin
      : userManagementAPI?.getUserLogin;
  return axiosInstance
    .get(loginEndpoint, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Student Bulk Upload
export const studentUploadValidationApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.studentUploadValidation}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//Bulk user role
export const bulkUserRoleApi = async (payload) => {
  return axiosInstance
    .post(`${userManagementAPI?.bulkUserRoles}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
// user for notification
export const getUserforNotificationApi = async (payload) => {
  return axiosInstance
    .get(`${userManagementAPI?.getAllUserForNotification}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
