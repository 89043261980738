export const LeadActions = {
  LEAD: {
    LOADING: 'LOADING',
    GET_LEAD_LIST: 'GET_LEAD_LIST',
    GET_LEAD_LIST_SUCCESS: 'GET_LEAD_LIST_SUCCESS',
    GET_LEAD_LIST_ERROR: 'GET_LEAD_LIST_ERROR',
    SET_LEAD_LIST_LOADING: 'SET_LEAD_LIST_LOADING',

    POST_LEAD: 'POST_LEAD',
    POST_LEAD_SUCCESS: 'POST_LEAD_SUCCESS',
    // POST_LEAD_SUCCESS_SAVE_FEEDBACK: 'POST_LEAD_SUCCESS_SAVE_FEEDBACK',
    POST_LEAD_ERROR: 'POST_LEAD_ERROR',

    DELETE_LEAD: 'DELETE_LEAD',
    DELETE_LEAD_SUCCESS: 'DELETE_LEAD_SUCCESS',
    DELETE_LEAD_ERROR: 'DELETE_LEAD_ERROR',

    UPDATE_LEAD: 'UPDATE_LEAD',
    UPDATE_LEAD_SUCCESS: 'UPDATE_LEAD_SUCCESS',
    UPDATE_LEAD_ERROR: 'UPDATE_LEAD_ERROR',

    POST_NOTES: 'POST_NOTES',
    POST_NOTES_SUCCESS: 'POST_NOTES_SUCCESS',
    POST_NOTES_ERROR: 'POST_NOTES_ERROR',

    // Get Notes list
    GET_NOTES: 'GET_NOTES',
    GET_NOTES_SUCCESS: 'GET_NOTES_SUCCESS',
    GET_NOTES_ERROR: 'GET_NOTES_ERROR',
    GET_NOTES_RESET: 'GET_NOTES_RESET',

    // Get Notes List Pagination
    GET_MORE_NOTES_REQUEST: 'GET_MORE_NOTES_REQUEST',
    GET_MORE_NOTES_SUCCESS: 'GET_MORE_NOTES_SUCCESS',
    GET_MORE_NOTES_ERROR: 'GET_MORE_NOTES_ERROR',

    GET_LEAD_STAGE: 'GET_LEAD_STAGE',
    GET_LEAD_STAGE_SUCCESS: 'GET_LEAD_STAGE_SUCCESS',
    GET_LEAD_STAGE_ERROR: 'GET_LEAD_STAGE_ERROR',

    GET_LEAD_STATUS: 'GET_LEAD_STATUS',
    GET_LEAD_STATUS_SUCCESS: 'GET_LEAD_STATUS_SUCCESS',
    GET_LEAD_STATUS_ERROR: 'GET_LEAD_STATUS_ERROR',

    UPDATE_LEAD_STATUS: 'UPDATE_LEAD_STATUS',
    UPDATE_LEAD_STATUS_SUCCESS: 'UPDATE_LEAD_STATUS_SUCCESS',
    UPDATE_LEAD_STATUS_ERROR: 'UPDATE_LEAD_STATUS_ERROR',

    GET_DASHBOARD_STATS: 'GET_DASHBOARD_STATS',
    GET_DASHBOARD_STATS_SUCCESS: 'GET_DASHBOARD_STATS_SUCCESS',
    GET_DASHBOARD_STATS_ERROR: 'GET_DASHBOARD_STATS_ERROR',

    GET_DASHBOARD_LEAD_CONVERSION: 'GET_DASHBOARD_LEAD_CONVERSION',
    GET_DASHBOARD_LEAD_CONVERSION_SUCCESS:
      'GET_DASHBOARD_LEAD_CONVERSION_SUCCESS',
    GET_DASHBOARD_LEAD_CONVERSION_ERROR: 'GET_DASHBOARD_LEAD_CONVERSION_ERROR',

    GET_LEAD_PLACEMENT: 'GET_LEAD_PLACEMENT',
    GET_LEAD_PLACEMENT_SUCCESS: 'GET_LEAD_PLACEMENT_SUCCESS',
    GET_LEAD_PLACEMENT_ERROR: 'GET_LEAD_PLACEMENT_ERROR',
    REST_LEAD_PLACEMENT: 'REST_LEAD_PLACEMENT',

    UPDATE_LEAD_STAGE: 'UPDATE_LEAD_STATGE',
    UPDATE_LEAD_STAGE_SUCCESS: 'UPDATE_LEAD_STAGE_SUCCESS',
    UPDATE_LEAD_STAGE_ERROR: 'UPDATE_LEAD_STAGE_ERROR',

    GET_LEAD_BY_ID: 'GET_LEAD_BY_ID',
    GET_LEAD_BY_ID_SUCCESS: 'GET_LEAD_BY_ID_SUCCESS',
    GET_LEAD_BY_ID_ERROR: 'GET_LEAD_BY_ID_ERROR',
    RESET_GET_LEAD_BY_ID_SUCCESS: 'RESET_GET_LEAD_BY_ID_SUCCESS',

    POST_BULK_LEAD: 'POST_BULK_LEAD',
    POST_BULK_LEAD_SUCCESS: 'POST_BULK_LEAD_SUCCESS',
    POST_BULK_LEAD_ERROR: 'POST_BULK_LEAD_ERROR',

    PATCH_UPDATE_LEAD_STATUS: 'PATCH_UPDATE_LEAD_STATUS',
    PATCH_UPDATE_LEAD_STATUS_SUCCESS: 'PATCH_UPDATE_LEAD_STATUS_SUCCESS',
    PATCH_UPDATE_LEAD_STATUS_ERROR: 'PATCH_UPDATE_LEAD_STATUS_ERROR',
    RESET_UPDATE_LEAD_STATUS: 'RESET_UPDATE_LEAD_STATUS',

    LEAD_FILE_UPLOAD: 'LEAD_FILE_UPLOAD',
    LEAD_FILE_UPLOAD_SUCCESS: 'LEAD_FILE_UPLOAD_SUCCESS',
    RESET_LEAD_FILE_UPLOAD: 'RESET_LEAD_FILE_UPLOAD',

    LEAD_BULK_UPLOAD_FILE: 'LEAD_BULK_UPLOAD_FILE',
    LEAD_BULK_UPLOAD_FILE_SUCCESS: 'LEAD_BULK_UPLOAD_FILE_SUCCESS',
    LEAD_UPLOAD_MULTI_FILE: 'LEAD_UPLOAD_MULTI_FILE',
    LEAD_UPLOAD_MULTI_FILE_SUCCESS: 'LEAD_UPLOAD_MULTI_FILE_SUCCESS',

    GET_ROLE : 'GET_ROLE',
    GET_ROLE_SUCCESS : 'GET_ROLE_SUCCESS',
    GET_ROLE_ERROR : 'GET_ROLE_ERROR'
  },
};
