import { statusCode } from 'constants/apis/apiStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { setToastMessage } from 'store/Toast';
import {
  createDiscussionBoardApi,
  createDiscussionCommentReplyApi,
  createDiscussionReplyApi,
  deleteDiscussionBoardApi,
  getDiscussionBoardApi,
  getDiscussionBoardIdApi,
  getUserBySubjeactApi,
  reportDiscussionActionApi,
  reportDiscussionApi,
  updateDiscussionBoardApi,
  updateDiscussionBoardListApi,
  updatePinUnpinCommentApi,
  updateUserPollingApi,
} from './DiscussionBoard.api';
import { discussionBoardActions } from './DiscussionBoard.constants';
import {
  createCommentReplyError,
  createCommentReplySuccess,
  createDiscussionBoardError,
  createDiscussionBoardSuccess,
  createDiscussionReplyError,
  createDiscussionReplySuccess,
  deleteDiscussionBoardError,
  deleteDiscussionBoardSuccess,
  getDiscussionBoardError,
  getDiscussionBoardIdError,
  getDiscussionBoardIdSuccess,
  getDiscussionBoardSuccess,
  getUsersBySubjectError,
  getUsersBySubjectSuccess,
  reportDiscussionActionError,
  reportDiscussionActionSuccess,
  reportDiscussionError,
  reportDiscussionSuccess,
  setDiscussionBoardLoading,
  updateDiscussionBoardError,
  updateDiscussionBoardListError,
  updateDiscussionBoardListSuccess,
  updateDiscussionBoardSuccess,
  updatePinUnpinCommentError,
  updatePinUnpinCommentSuccess,
  updateUserPollingError,
  updateUserPollingSuccess,
} from './Discussionboard.action';

function* getDiscussionBoardSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(getDiscussionBoardApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDiscussionBoardSuccess(response?.data));
    } else {
      yield put(getDiscussionBoardError(response?.data));
    }
  } catch (error) {
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to fetch discussion list',
        severity: 'error',
      })
    );
  }
}

function* getDiscussionBoardByIdSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(getDiscussionBoardIdApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDiscussionBoardIdSuccess(response?.data));
    } else {
      yield put(getDiscussionBoardIdError(response?.data));
    }
  } catch (error) {
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to fetch discussion by id',
        severity: 'error',
      })
    );
  }
}

function* createDiscussionBoardSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(createDiscussionBoardApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createDiscussionBoardSuccess(true));
      yield put(
        setToastMessage({
          data: 'Discussion created',
          severity: 'success',
        })
      );
    } else {
      yield put(createDiscussionBoardError(false));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to create',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(setDiscussionBoardLoading(false));
    yield put(createDiscussionBoardError(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to create',
        severity: 'error',
      })
    );
  }
}

function* updateDiscussionBoardSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(updateDiscussionBoardApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateDiscussionBoardSuccess(true));
      yield put(
        setToastMessage({
          data: 'Discussion updated',
          severity: 'success',
        })
      );
    } else {
      yield put(updateDiscussionBoardError(false));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to update',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateDiscussionBoardError(false));
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to update',
        severity: 'error',
      })
    );
  }
}

function* deleteDiscussionBoardSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(deleteDiscussionBoardApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (
      response?.status === statusCode.Ok200 ||
      response?.status === statusCode.NoContent204
    ) {
      yield put(deleteDiscussionBoardSuccess(true));
      yield put(
        setToastMessage({
          data: 'Discussion deleted',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteDiscussionBoardError(false));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to delete',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to delete',
        severity: 'error',
      })
    );
  }
}

function* createDiscussionReplySaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(createDiscussionReplyApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createDiscussionReplySuccess(response?.data));
    } else {
      yield put(createDiscussionReplyError(response?.data));
    }
  } catch (error) {
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* createCommentReplySaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(createDiscussionCommentReplyApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createCommentReplySuccess(true));
      yield put(
        setToastMessage({
          data: 'Comment added',
          severity: 'success',
        })
      );
    } else {
      yield put(createCommentReplyError(false));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to add comment',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(createCommentReplyError(false));
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* updatePollingOptionSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(updateUserPollingApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateUserPollingSuccess(true));
      yield put(
        setToastMessage({
          data: 'Polling updated',
          severity: 'success',
        })
      );
    } else {
      yield put(updateUserPollingError(false));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to update polling',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateUserPollingError(false));
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to update polling',
        severity: 'error',
      })
    );
  }
}

function* updatePinUnpinCommentSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(updatePinUnpinCommentApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updatePinUnpinCommentSuccess(true));
      const successMessage = payload?.pinned
        ? 'Comment pinned'
        : 'Comment unpinned';
      yield put(
        setToastMessage({
          data: successMessage,
          severity: 'success',
        })
      );
    } else {
      yield put(updatePinUnpinCommentError(false));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to update',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updatePinUnpinCommentError(false));
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to update',
        severity: 'error',
      })
    );
  }
}

function* reportDiscussionSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(reportDiscussionApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(reportDiscussionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Reported to higher authority successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(reportDiscussionError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(setDiscussionBoardLoading(false));
  } catch (error) {
    yield put(reportDiscussionError(error?.data));
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* reportDiscussionActionSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(reportDiscussionActionApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(reportDiscussionActionSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(reportDiscussionActionError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
    yield put(setDiscussionBoardLoading(false));
  } catch (error) {
    yield put(reportDiscussionActionError(error?.data));
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.message ?? 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* updateDiscussionBoardListSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(updateDiscussionBoardListApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateDiscussionBoardListSuccess(true));
      yield put(
        setToastMessage({
          data: 'Discussion updated',
          severity: 'success',
        })
      );
    } else {
      yield put(updateDiscussionBoardListError(false));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message ?? 'Failed to update',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateDiscussionBoardError(false));
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to update',
        severity: 'error',
      })
    );
  }
}

function* getUserBySubjectSaga({ payload }) {
  try {
    yield put(setDiscussionBoardLoading(true));
    const response = yield call(getUserBySubjeactApi, payload);
    yield put(setDiscussionBoardLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getUsersBySubjectSuccess(response?.data));
    } else {
      yield put(getUsersBySubjectError(response?.data));
    }
  } catch (error) {
    yield put(setDiscussionBoardLoading(false));
    yield put(
      setToastMessage({
        data: error?.data?.error?.message ?? 'Failed to fetch discussion list',
        severity: 'error',
      })
    );
  }
}

export function* watchDummy() {
  yield takeLatest(
    discussionBoardActions.DISCUSSIONBOARD.get,
    getDiscussionBoardSaga
  );
  yield takeLatest(
    discussionBoardActions.DISCUSSIONBOARD.get_by_id,
    getDiscussionBoardByIdSaga
  );
  yield takeLatest(
    discussionBoardActions.DISCUSSIONBOARD.create,
    createDiscussionBoardSaga
  );
  yield takeLatest(
    discussionBoardActions.DISCUSSIONBOARD.update,
    updateDiscussionBoardSaga
  );
  yield takeLatest(
    discussionBoardActions.DISCUSSIONBOARD.delete,
    deleteDiscussionBoardSaga
  );
  yield takeLatest(
    discussionBoardActions.REPLY.create_reply,
    createDiscussionReplySaga
  );

  // create comment/reply
  yield takeLatest(
    discussionBoardActions.REPLY.create_comment_reply_request,
    createCommentReplySaga
  );

  // Update user polling
  yield takeLatest(
    discussionBoardActions.POLLING.update_by_id,
    updatePollingOptionSaga
  );

  // Pin/unpin comment
  yield takeLatest(
    discussionBoardActions.REPLY.update_pin_unpin_comment_request,
    updatePinUnpinCommentSaga
  );

  yield takeLatest(
    discussionBoardActions.REPORT.report_discussion,
    reportDiscussionSaga
  );
  yield takeLatest(
    discussionBoardActions.REPORT_ACTION.report_discussion_action,
    reportDiscussionActionSaga
  );

  yield takeLatest(
    discussionBoardActions.DISCUSSIONBOARD.update_discussion,
    updateDiscussionBoardListSaga
  );
  yield takeLatest(
    discussionBoardActions.DISCUSSIONBOARD.get_user_by_subject,
    getUserBySubjectSaga
  );
}

export default function* discussionBoardSaga() {
  yield all([watchDummy()]);
}
