export const obeApiEndpoints = {
  peo: '/v2/program-educational-objective',
  po: '/v2/program-outcomes',
  pso: '/v2/program-specific-outcomes',
  co: '/v2/course-outcome',
  pi: '/v2/performance-indicator',
  cl: '/v2/competency-levels',
};

export const mappingEndpoint = {
  missionvspeoMapping: 'v2/mission-peo-mapping',
  copoMapping: '/v2/mission-co-po-mapping',
};
