import { CounsellorActions } from './Counsellor.constants';
const { COUNSELLOR } = CounsellorActions;

export const setCounsellorLoading = (payload) => ({
  type: COUNSELLOR.LOADING,
  payload: payload,
});

export const getCounsellorStatsAction = (payload) => ({
  type: COUNSELLOR.GET_COUNSELLOR_STATS,
  payload: payload,
});

export const getCounsellorStatsSuccess = (payload) => ({
  type: COUNSELLOR.GET_COUNSELLOR_STATS_SUCCESS,
  payload: payload,
});

export const getCounsellorStatsError = (payload) => ({
  type: COUNSELLOR.GET_COUNSELLOR_STATS_ERROR,
  payload: payload,
});

export const getCounsellorLeadAction = (payload) => ({
  type: COUNSELLOR.GET_COUNSELLOR_LEADS,
  payload: payload,
});

export const getCounsellorLeadSuccess = (payload) => ({
  type: COUNSELLOR.GET_COUNSELLOR_LEADS_SUCCESS,
  payload: payload,
});

export const getCounsellorLeadError = (payload) => ({
  type: COUNSELLOR.GET_COUNSELLOR_LEADS_ERROR,
  payload: payload,
});

export const resetCandidatesList = () => ({
  type: COUNSELLOR.RESET_CANDIDATES_LIST,
});

export const resetSelectedProspect = () => ({
  type: COUNSELLOR.RESET_SELECTED_PROSPECTUS,
});

export const getInterestedProspectusPriceAction = (payload) => ({
  type: COUNSELLOR.GET_INTERESTED_PROSPECTUS,
  payload: payload,
});

export const getInterestedProspectusPriceSuccess = (payload) => ({
  type: COUNSELLOR.GET_INTERESTED_PROSPECTUS_SUCCESS,
  payload: payload,
});

export const getInterestedProspectusPriceError = (payload) => ({
  type: COUNSELLOR.GET_INTERESTED_PROSPECTUS_ERROR,
  payload: payload,
});

export const resetInterestedProspect = () => ({
  type: COUNSELLOR.RESET_INTERESTED_PROSPECTUS,
});

export const addSelectedProspectAction = (payload) => ({
  type: COUNSELLOR.ADD_SELECTED_PROSPECT,
  payload: payload,
});

export const addSelectedProspectSuccess = (payload) => ({
  type: COUNSELLOR.ADD_SELECTED_PROSPECT_SUCCESS,
  payload: payload,
});

export const addSelectedProspectError = (payload) => ({
  type: COUNSELLOR.ADD_SELECTED_PROSPECT_ERROR,
  payload: payload,
});

export const resetAddSelectedProspect = () => ({
  type: COUNSELLOR.RESET_ADD_SELECTED_PROSPECT,
});

export const getSelectedProspectListAction = (payload) => ({
  type: COUNSELLOR.GET_SELECTED_PROSPECT_LIST,
  payload: payload,
});

export const getSelectedProspectListSuccess = (payload) => ({
  type: COUNSELLOR.GET_SELECTED_PROSPECT_LIST_SUCCESS,
  payload: payload,
});

export const getSelectedProspectListError = (payload) => ({
  type: COUNSELLOR.GET_SELECTED_PROSPECT_LIST_ERROR,
  payload: payload,
});

export const deleteSelectedProspectAction = (payload) => ({
  type: COUNSELLOR.DELETE_SELECTED_PROSPECT,
  payload: payload,
});

export const deleteSelectedProspectSuccess = (payload) => ({
  type: COUNSELLOR.DELETE_SELECTED_PROSPECT_SUCCESS,
  payload: payload,
});

export const deleteSelectedProspectError = (payload) => ({
  type: COUNSELLOR.DELETE_SELECTED_PROSPECT_ERROR,
  payload: payload,
});

export const sendProspectPaymentLinkAction = (payload) => ({
  type: COUNSELLOR.SEND_PAYMENT_LINK,
  payload: payload,
});

export const sendProspectPaymentLinkSuccess = (payload) => ({
  type: COUNSELLOR.SEND_PAYMENT_LINK_SUCCESS,
  payload: payload,
});

export const sendProspectPaymentLinkError = (payload) => ({
  type: COUNSELLOR.SEND_PAYMENT_LINK_ERROR,
  payload: payload,
});

export const updatePaymentLinkAction = (payload) => ({
  type: COUNSELLOR.UPDATE_PAYMENT_LINK,
  payload: payload,
});

export const updatePaymentLinkSuccess = (payload) => ({
  type: COUNSELLOR.UPDATE_PAYMENT_LINK_SUCCESS,
  payload: payload,
});

export const updatePaymentLinkError = (payload) => ({
  type: COUNSELLOR.UPDATE_PAYMENT_LINK_ERROR,
  payload: payload,
});

export const startCandidateCounsellingAction = (payload) => ({
  type: COUNSELLOR.START_CANDIDATE_COUNSELLING,
  payload: payload,
});

export const startCandidateCounsellingSuccess = (payload) => ({
  type: COUNSELLOR.START_CANDIDATE_COUNSELLING_SUCCESS,
  payload: payload,
});

export const startCandidateCounsellingError = (payload) => ({
  type: COUNSELLOR.START_CANDIDATE_COUNSELLING_ERROR,
  payload: payload,
});

export const sendToVerifierAction = (payload) => ({
  type: COUNSELLOR.SEND_TO_VERIFIER,
  payload: payload,
});

export const sendToVerifierSuccess = (payload) => ({
  type: COUNSELLOR.SEND_TO_VERIFIER_SUCCESS,
  payload: payload,
});

export const sendToVerifierError = (payload) => ({
  type: COUNSELLOR.SEND_TO_VERIFIER_ERROR,
  payload: payload,
});

export const getLeadCurrentStageAction = (payload) => ({
  type: COUNSELLOR.GET_CURRENT_STAGE,
  payload: payload,
});

export const getLeadCurrentStageSuccess = (payload) => ({
  type: COUNSELLOR.GET_CURRENT_STAGE_SUCCESS,
  payload: payload,
});

export const getLeadCurrentStageError = (payload) => ({
  type: COUNSELLOR.GET_CURRENT_STAGE_ERROR,
  payload: payload,
});

export const sendProspectFormOnlineAction = (payload) => ({
  type: COUNSELLOR.SEND_PROSPECT_ONLINE,
  payload: payload,
});

export const sendProspectFormOnlineSuccess = (payload) => ({
  type: COUNSELLOR.SEND_PROSPECT_ONLINE_SUCCESS,
  payload: payload,
});

export const sendProspectFormOnlineError = (payload) => ({
  type: COUNSELLOR.SEND_PROSPECT_ONLINE_ERROR,
  payload: payload,
});

export const getAppFormDetailsAction = (payload) => ({
  type: COUNSELLOR.GET_APP_FORM_DETAILS,
  payload: payload,
});

export const getAppFormDetailsSuccess = (payload) => ({
  type: COUNSELLOR.GET_APP_FORM_DETAILS_SUCCESS,
  payload: payload,
});

export const getAppFormDetailsError = (payload) => ({
  type: COUNSELLOR.GET_APP_FORM_DETAILS_ERROR,
  payload: payload,
});
