import React, { useState } from 'react';
import {
  FormControl,
  MenuItem,
  TextField,
  InputAdornment,
} from 'iiht-b2a-ui-components/build/components';
import { ExpandArrow } from 'icons';
import { Controller, useFormContext } from 'react-hook-form';
import './FormSelectTextField.scss';

const FormSelectTextField = ({
  textFieldName,
  textFieldLabel,
  textFieldRules,
  textFieldFullWidth,
  textFieldClassName,
  textFieldInputClassName,
  textFieldRequired = false,
  multiline,
  rows,
  inputType = 'text',
  setInputType,
  textFieldSize,
  textFieldColor = 'secondary',
  selectFieldName,
  selectFieldLabel,
  selectFieldRules,
  selectFieldRequired,
  selectFieldSize,
  selectFieldColor,
  options,
  onSelectChange,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { getFieldState } = control;

  return (
    <div className="form-select-text-field">
      <FormControl
        margin="normal"
        fullWidth={textFieldFullWidth}
        className={textFieldClassName}
        size={textFieldSize}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '94%',
        }}
      >
        <Controller
          name={textFieldName}
          control={control}
          rules={textFieldRules}
          noRef={true}
          render={({ field }) => {
            const { name, value, onChange, onBlur } = field;
            const { invalid, error } = getFieldState(name);
            return (
              <TextField
                name={textFieldName}
                label={textFieldLabel}
                required={textFieldRequired}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error ? true : false}
                aria-invalid={invalid ? 'true' : 'false'}
                helperText={error?.message}
                size={textFieldSize}
                color={textFieldColor}
                multiline={multiline}
                rows={rows}
                inputProps={{ maxLength: 10000 }}
                className={textFieldInputClassName}
                InputLabelProps={{ shrink: true }}
                type={inputType}
                style={{
                  width: '65%',
                }}
                {...rest}
              />
            );
          }}
        ></Controller>

        <Controller
          name={selectFieldName}
          control={control}
          rules={selectFieldRules}
          noRef={true}
          render={({ field }) => {
            const { name, value, onChange, onBlur } = field;
            const { invalid, error } = getFieldState(name);
            return (
              <TextField
                select
                name={name}
                required={selectFieldRequired}
                value={value == null ? '' : value}
                onChange={(e) => {
                  onChange(e);
                  onSelectChange(e);
                }}
                onBlur={onBlur}
                error={error ? true : false}
                aria-invalid={invalid ? 'true' : 'false'}
                helperText={error?.message}
                size={selectFieldSize}
                style={{
                  width: '35%',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ExpandArrow />
                    </InputAdornment>
                  ),
                }}
                color={selectFieldColor}
                {...rest}
              >
                {options?.map((item) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        ></Controller>
      </FormControl>
    </div>
  );
};

export default FormSelectTextField;
