import * as React from 'react';

const AddBookmarkIcon = ({ fill, height=20,width=16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 96 960 960"
    width={width}
    fill={fill}
  >
    <path d="M120 1016V316q0-24 18-42t42-18h480q24 0 42.5 18t18.5 42v700L420 889l-300 127Zm60-91 240-103 240 103V316H180v609Zm600 1V196H233v-60h547q24 0 42 18t18 42v730h-60ZM180 316h480-480Z"/>
  </svg>
);

export default AddBookmarkIcon;