import axios from 'axios';
import { userManagementAPI } from 'store/UserManagement';
import { localStorageHelper } from './storageHelper';
import { User } from 'data/roles';
export const getOidcUserDetails = () => {
  const user = localStorageHelper.get(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}/:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  );
  return JSON.parse(user);
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};
export const isLoggedIn = () => {
  const token = localStorageHelper.get('access_token');
  const decodedJwt = token ? parseJwt(token) : null;
  return !!token && decodedJwt?.exp && decodedJwt?.exp * 1000 >= Date.now();
};

export const getExternalAuthTokenApi = async (userDetails) => {
  const userInfo = await axios.get(
    `${process.env.REACT_APP_AUTH_URL}` +
      userManagementAPI.getExternalAuthToken,
    {
      headers: {
        Authorization: `Bearer ${userDetails.access_token}`, //the token is a variable which holds the token
      },
    }
  );
  if (userInfo?.data?.accessToken) {
    return userInfo?.data;
  } else {
    return null;
  }
};

export const getUserRole = () => {
  let role = localStorageHelper.get('role_name') || '';
  return role;
};

export const saveUserInLocalStorage = async (userDetails) => {
  const info = await getExternalAuthTokenApi(userDetails);
  localStorageHelper.set(
    'access_token',
    info?.accessToken || JSON.stringify(null)
  );
  localStorageHelper.set('user_id', info?.userId || JSON.stringify(null));
  return info;
};
