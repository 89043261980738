import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { courseManagementReducer } from 'store/CourseManagement';
import { userManagementReducer } from 'store/UserManagement';
import { studentReducer } from 'store/Student';
import { notificationReducer } from 'store/Notifications';
import { userProfileReducer } from 'store/UserProfile';
import { calendarReducer } from 'store/Calendar';
import { Settingsreducer } from './Settings';
import { feesManagementReducer } from './Fees';
import { discussionBoardReducer } from './DiscussionBoard';
import { gamificationReducer } from './Gamification';
import { myRepositoryReducer } from './MyRepository';
import { scheduleReducer } from 'store/Schedule';
import { attendanceReducer } from 'store/Attendance';
import { dashboardReducer } from 'store/Dashboard';
import { evaluationReducer } from 'store/Evaluation';
import { feedbackReducer } from './Feedback';
import { toastReducer } from 'store/Toast';
import { notesReducer } from './Notes';
import { leadReducer } from 'store/Lead';
import { counsellorReducer } from 'store/Counsellor';
import { verifierReducer } from 'store/Verifier';
import { candidateReducer } from './Candidate';
import { prospectusReducer } from 'store/Prospectus';
import { chatReducer } from 'store/Chat';
import { groupChatReducer } from 'store/Groupchat';
import { programContentReducer } from './ProgramContent';
import { ExamsReducer } from './Exams';
import { loaderReducer } from './Loader';
import { moocAdminReducer } from './MoocAdmin';
import { assessmentRepositoryReducer } from './AssessmentRepository';
import { obeReducer } from './Obe';
import { courseContentReducer } from './CourseContentFeedback';
import { SuperAdminReducer } from './SuperAdmin';

const MainReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    courseManagementState: courseManagementReducer,
    userManagementState: userManagementReducer,
    notificationState: notificationReducer,
    studentState: studentReducer,
    userProfileState: userProfileReducer,
    calendarState: calendarReducer,
    settingState: Settingsreducer,
    feemanagmentstate: feesManagementReducer,
    discussionBoardState: discussionBoardReducer,
    gamificationstate: gamificationReducer,
    scheduleState: scheduleReducer,
    attendanceState: attendanceReducer,
    dashboardState: dashboardReducer,
    repositorystate: myRepositoryReducer,
    evaluationState: evaluationReducer,
    toastState: toastReducer,
    feedbackState: feedbackReducer,
    notesState: notesReducer,
    leadState: leadReducer,
    counsellorState: counsellorReducer,
    verifierState: verifierReducer,
    candidateState: candidateReducer,
    prospectusState: prospectusReducer,
    chatState: chatReducer,
    groupChatState: groupChatReducer,
    programContentState: programContentReducer,
    examsState: ExamsReducer,
    globalLoaderState: loaderReducer,
    moocAdminState: moocAdminReducer,
    assessmentRepositoryState: assessmentRepositoryReducer,
    obeState: obeReducer,
    courseContentState: courseContentReducer,
    superAdminState: SuperAdminReducer,
  });

export default MainReducer;
