import * as React from 'react';

const SheildSmallSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.294"
      height="19.77"
      viewBox="0 0 18.294 19.77"
    >
      <g
        id="Group_12979"
        data-name="Group 12979"
        transform="translate(-1980 -7883)"
      >
        <g id="shield-solid-badged" transform="translate(1980 7883)">
          <path
            id="Path_49577"
            data-name="Path 49577"
            d="M20.677,9.082a4.408,4.408,0,0,1-4.29-5.506A24.556,24.556,0,0,1,13.914,2.2L13.6,2l-.319.207A26.556,26.556,0,0,1,9.689,4.1,25.842,25.842,0,0,1,5.776,5.482l-.443.112v4.922c0,7.9,7.985,10.859,8.061,10.888l.2.071.2-.071c.083,0,8.061-2.98,8.061-10.888v-1.6A4.42,4.42,0,0,1,20.677,9.082Z"
            transform="translate(-5.333 -1.705)"
            fill="#02387a"
          />
          <circle
            id="Ellipse_870"
            data-name="Ellipse 870"
            cx="2.951"
            cy="2.951"
            r="2.951"
            transform="translate(12.393 0)"
            fill="#02387a"
          />
        </g>
      </g>
    </svg>
  );
};

export default SheildSmallSvg;
