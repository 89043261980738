import React, { useEffect, useMemo, useState } from 'react';
import { LoaderComponent } from 'components';

const withFetchUrl = (OriginalComponent, props, fetchUrl, blob_name) => {
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    setLoading(true);
    const callFetchUrl = async () => {
      const response = await fetchUrl?.({ blob_name });
      const index = props.attachmentList?.findIndex(
        (file) => file?.fileURL === blob_name
      );
      if (index !== -1) {
        props.attachmentList[index].fileURL = response?.data?.blob_url;
        setFileUrl(response?.data?.blob_url);
      }
      setLoading(false);
    };
    try {
      callFetchUrl();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const Comp = useMemo(() => {
    if (loading) {
      return <LoaderComponent visible={loading} />;
    } else if (fileUrl) {
      return <OriginalComponent {...props} />;
    }
  }, [loading, fileUrl]);

  return Comp;
};

export default withFetchUrl;
