export const MyRepositoryActions = {
  MYREPOSITORY: {
    get: 'GET_REPOSITORY_LIST',
    get_success: 'GET_REPOSITORY_SUCCESS',
    get_error: 'GET_REPOSITORY_ERROR',
    get_reset: 'GET_REPOSITORY_RESET',
    loading: 'GET_REPOSITORY_SET_LOADING',

    create_Report_Document: 'MYREPOSITORY_FEESUPLOAD_CREATE',
    create_Report_Document_success: 'MYREPOSITORY_FEESUPLOAD_CREATE_SUCCESS',
    create_Report_Document_error: 'MYREPOSITORY_FEESUPLOAD_CREATE_ERROR',
    create_Report_Document_reset: 'MYREPOSITORY_FEESUPLOAD_CREATE_RESET',

    delete: 'MYREPOSITORY_RECORDS_DELETE',
    delete_success: 'MYREPOSITORY_RECORDS_DELETE_SUCCESS',
    delete_error: 'MYREPOSITORY_RECORDS_DELETE_ERROR',

    get_by_user: 'GET_USER_LIST',
    get_by_user_success: 'GET_USER_SUCCESS',
    get_by_user_error: 'GET_USER_ERROR',

    UPDATE_LIST: 'UPDATE_LIST',
    UPDATE_LIST_SUCCESS: 'UPDATE_LIST_SUCCESS',
    UPDATE_LIST_ERROR: 'UPDATE_LIST_ERROR',

    SHARE_FILE: 'SHARE_FILE',
    SHARE_FILE_SUCCESS: 'SHARE_FILE_SUCCESS',
    SHARE_FILE_ERROR: 'SHARE_FILE_ERROR',

    BULK_SHARE_FILE: 'BULK_SHARE_FILE',
    BULK_SHARE_FILE_SUCCESS: 'BULK_SHARE_FILE_SUCCESS',
    BULK_SHARE_FILE_ERROR: 'BULK_SHARE_FILE_ERROR',

    BULK_DELETE_FILE: 'BULK_DELETE_FILE',
    BULK_DELETE_FILE_SUCCESS: 'BULK_DELETE_FILE_SUCCESS',
    BULK_DELETE_FILE_ERROR: 'BULK_DELETE_FILE_ERROR',
  },
};
