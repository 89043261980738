import { ExpandArrow } from 'icons';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
} from 'iiht-b2a-ui-components/build/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

function HeaderNotification({
  headerTabs,
  manageItems,
  theme,
  learningItems = [],
  reportItems = [],
}) {
  const { t } = useTranslation();
  const [anchorElItems, setAnchorElItems] = useState(null);
  const [anchorLearningItems, setAnchorLearningItems] = useState('');
  const [anchorMoocReportItems, setAnchorMoocReportItems] = useState('');

  const handleOpenItemsMenu = (event, label) => {
    if (label === t('headerLabels.manage') || label === t('headerLabels.mooc'))
      setAnchorElItems(event.currentTarget);
    else if (label === 'MOOC Report') {
      setAnchorMoocReportItems(event.currentTarget);
    } else setAnchorLearningItems(event.currentTarget);
  };

  const handleCloseItemsMenu = () => {
    setAnchorElItems(null);
    setAnchorLearningItems(null);
    setAnchorMoocReportItems(null);
  };

  const BottomBorder = {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  };

  return (
    <Box
      sx={{
        ml: '20px',
        display: { md: 'flex' },
      }}
    >
      <div className="manage-actions">
        {headerTabs?.map((headerItem, index) => {
          return (
            <div key={index}>
              {headerItem.type === 'menu' ? (
                <Button
                  color="secondary"
                  variant="text"
                  id="basic-button"
                  onClick={(e) => handleOpenItemsMenu(e, headerItem?.label)}
                >
                  {headerItem.label}
                  <ExpandArrow />
                </Button>
              ) : (
                <Button color="secondary" variant="text">
                  <NavLink to={headerItem.link} activeStyle={BottomBorder}>
                    <Typography variant="link">{headerItem.label}</Typography>
                  </NavLink>
                </Button>
              )}
            </div>
          );
        })}
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorElItems}
        open={Boolean(anchorElItems)}
        onClose={handleCloseItemsMenu}
      >
        {manageItems?.map((item) => (
          <MenuItem disableRipple key={item?.link} className="menu-item">
            <Link to={item?.link} onClick={handleCloseItemsMenu}>
              <Typography variant="link" className="basic-menu-item-icon">
                {item?.item}
              </Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>

      <Menu
        id="basic-learning-menu"
        anchorEl={anchorLearningItems}
        open={Boolean(anchorLearningItems)}
        onClose={handleCloseItemsMenu}
      >
        {learningItems?.map((item) => (
          <MenuItem disableRipple key={item?.link} className="menu-item">
            <Link to={item?.link} onClick={handleCloseItemsMenu}>
              <Typography variant="link" className="basic-menu-item-icon">
                {item?.item}
              </Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="basic-learning-menu"
        anchorEl={anchorMoocReportItems}
        open={Boolean(anchorMoocReportItems)}
        onClose={handleCloseItemsMenu}
      >
        {reportItems?.map((item) => {
          return (
            <MenuItem disableRipple key={item?.link} className="menu-item">
              <Link to={item?.link} onClick={handleCloseItemsMenu}>
                <Typography variant="link" className="basic-menu-item-icon">
                  {item?.item}
                </Typography>
              </Link>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default HeaderNotification;
