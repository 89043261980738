import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  getUserDetailsSuccess,
  setUserDetailsLoading,
  setUserRoleLoading,
  getUserRolePermissionsApi,
  getUserDetailsApi,
  userManagementActions,
  getAllRolesApi,
  getAllRolesSuccess,
  getAllRolesError,
  getAllUserDetailsApi,
  getAllFacultyDetailsSuccess,
  getAllFacultyDetailsError,
  getAllHodDetailsSuccess,
  getAllHodDetailsError,
  getAllStudentsDetailsSuccess,
  getAllStudentsDetailsError,
  getAllRegistrarDetailsSuccess,
  getAllRegistrarDetailsError,
  getDeptUserDetailsApi,
  getDeptUserDetailsSuccess,
  getDeptUserDetailsError,
  createUserApi,
  getStudentDetailsApi,
  uploadUserImageApi,
  createSuccessStudent,
  getSuccessStudentDetails,
  getFilteredUserDetailsApi,
  getDesignationApi,
  deleteUserRolesApi,
  deleteUserApi,
  getClustersListApi,
  getDepartmentsByIdApi,
  getCoursesByIdApi,
  userUploadValidationApi,
  userUploadApi,
  localUserLoginApi,
  localUserLogoutApi,
  getTenantPublicImagesRequest,
  updateCandidateToStudentApi,
  getLeadToBeOnboardedApi,
  getLeadOnboardedListApi,
  getCandidateLoginOtpApi,
  getCandidateLoginVerifyOtpApi,
  getCandidateInformationByIdApi,
  getAllUserListApi,
  getAllUserRolesApi,
  createUserCourseMappingApi,
  getProgramStaffDetailsApi,
  getProgramStudentDetailsApi,
  updateUserApi,
  createStudentApi,
  blockUserApi,
  deleteStudentApi,
  updateStudentApi,
  getUserRoleDetailsApi,
  createUserRoleApi,
  updateUserRoleApi,
  createGroupApi,
  getGroupDetailsApi,
  getGropDetailsByIdApi,
  editGroupApi,
  deleteGroupApi,
  searchUserApi,
  FilterUserApi,
  removeUserAPI,
  addSelectedUserApi,
  forgotPasswordAPI,
  userBulkGroupUpload,
  getLoginDetailsApi,
  userDeactivateGroupAPI,
  studentUploadValidationApi,
  bulkUserRoleApi,
  getProgramWithStudentDetailsApi,
  getProgramDataApi,
  getUserforNotificationApi,
  FilterAllUserApi,
} from 'store/UserManagement';
import {
  blockStaffSuccess,
  createErrorStudent,
  createSuccessStaff,
  createSuccessStudentCourseMapping,
  deleteStudentError,
  deleteStudentSuccess,
  deleteUserError,
  deleteUserSuccess,
  errorUploadUser,
  getAllHodDetailsByScrollError,
  getAllHodDetailsByScrollSuccess,
  getCandidateInfoById,
  getCandidateOnboardedError,
  getCandidateOnboardedSuccess,
  getCandidatePendingError,
  getCandidatePendingSuccess,
  getDeptUserDetailsErrorByPagination,
  getDeptUserDetailsSuccessByPagination,
  getDesignationSuccess,
  geterrorAllClustersList,
  getErrorCoursesbyId,
  getErrorDepartmentbyId,
  getFacultyByDeptDetailsError,
  getFacultyByDeptDetailsSuccess,
  getFilteredUserDetailsError,
  getFilteredUserDetailsSuccess,
  getSuccessAllClustersList,
  getSuccessCoursesbyId,
  getSuccessDepartmentbyId,
  getSuccessStaffDetails,
  getSuccessStaffsList,
  getSuccessStudentsList,
  getTenantImagesSuccess,
  getUserDetailsAction,
  getUserListForHRError,
  getUserListForHRSuccess,
  getUserRoleAction,
  getUserRoleSuccess,
  localUserLoginSuccessAction,
  localUserLogoutSuccessAction,
  saveLoginTypeAction,
  setCandidateLoginOtpError,
  setCandidateLoginOtpSuccess,
  setCandidateLoginVerifyOtpError,
  setCandidateLoginVerifyOtpSuccess,
  staffsListLoading,
  studentsListLoading,
  successStaffUploadImage,
  successUploadImage,
  successUploadUser,
  updateCandidateToStudentError,
  updateCandidateToStudentSuccess,
  updateStaffSuccess,
  uploadValidationError,
  uploadValidationSuccess,
  getAllUserRolesSuccess,
  getAllUserRolesError,
  deleteUserRolesSuccess,
  deleteUserRolesError,
  createUserRoleSuccess,
  createUserRoleError,
  getUserRoleDetailsSuccess,
  getUserRoleDetailsError,
  updateUserRoleSuccess,
  updateUserRoleError,
  createSuccessGroup,
  createErrorGroup,
  getGroupDetailsSuccess,
  getGroupDetailsError,
  getGroupDetailsByIdSuccess,
  getGroupDetailsByIdError,
  editGroupSuccess,
  editGroupError,
  deleteGroupSuccess,
  deleteGroupError,
  searchUserSuccess,
  searchUserError,
  filterUserSuccess,
  filterUserError,
  setRemoveUserSuccess,
  setRemoveUserError,
  setAddSelectedUserSuccess,
  setAddSelectedUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  userGrpBulkUploadSuccess,
  userGrpBulkUploadError,
  getLoginDetails,
  getLoginDetailsSuccess,
  getLoginDetailsError,
  deactivateGroupSuccess,
  deactivateGroupError,
  bulkUserRoleSuccess,
  bulkUserRoleError,
  getStudentListWithProgramSuccess,
  getStudentListWithProgramError,
  getAllProgramDataSuccess,
  getAllProgramDataError,
  getErrorStaffDetails,
  createErrorStaff,
  resetCreateStaff,
  getAllUserDetailsNotificationSuccess,
  getAllUserDetailsNotificationError,
  updateStaffError,
  setStaffLoading,
  studentBulkUploadSuccess,
  studentBulkUploadError,
  geAnyDetailsSuccess,
  geAnyDetailsError,
  filterAllUserSuccess,
  filterAllUserError,
} from './UserManagement.action';
import { statusCode } from 'constants/apis/apiStatus';
import {
  generateUserDetailsForCandidate,
  localStorageHelper,
  parseJwt,
} from 'utils';
import { setToastMessage } from 'store/Toast';
import { getLeadInfoByIdAction } from 'store/Lead';
import { Role, User } from 'data/roles';
import moment from 'moment';
import { GENDER, MARTIAL_STATUS } from 'common/enum/LeadEnum';

const {
  USER,
  USER_ROLE,
  USER_DETAILS,
  STUDENT,
  STAFF,
  CLUSTERS,
  DEPARTMENTS,
  COURSES,
  AUTHENTICATION,
  TENANT_SPECIFIC_LOGO,
  CANDIDATE_STUDENT,
  CANDIDATE_ONBOARDING_PENDING,
  CANDIDATE_ONBOARDED,
  CANDIDATE_LOGIN,
  HR,
  ALL_USER_ROLE,
  DELETE_USER_ROLE,
  USER_ROLE_DETAILS,
  ANY_USER_DETAILS,
  CREATE_USER_ROLES,
  UPDATE_USER_ROLES,
  GROUP,
  FORGOT_PASSWORD,
  BULK_USER_ROLES,
  USER_LOGIN_DETAILS,
} = userManagementActions;

function* getUserDetailsSaga({ payload }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(getUserDetailsApi, payload);
    yield put(setUserDetailsLoading(false));
    yield put(getUserDetailsSuccess(response?.data));
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessStaffDetails(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStudentListWithProgramSaga(payload) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(
      getProgramWithStudentDetailsApi,
      payload.action
    );
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentListWithProgramSuccess(response?.data));
    } else {
      yield put(getStudentListWithProgramError('Error Fetching Response'));
    }
  } catch (error) {
    yield put(getStudentListWithProgramError(error));
  }
}
function* getProgramDataSaga() {
  try {
    const response = yield call(getProgramDataApi);
    if (response?.status === statusCode.Ok200) {
      yield put(getAllProgramDataSuccess(response?.data));
    } else {
      yield put(getAllProgramDataError('Error Fetching Response'));
    }
  } catch (error) {
    yield put(getAllProgramDataError(error));
  }
}
function* getUserRolePermissionsSaga({ payload }) {
  try {
    yield put(setUserRoleLoading(true));
    const response = yield call(getUserRolePermissionsApi, payload);
    yield put(setUserRoleLoading(false));

    if (response?.status === statusCode.Ok200) {
      if (response?.data?.userRole) {
        localStorageHelper.set('role_name', response?.data?.userRole?.name);
      }
      yield put(getUserRoleSuccess(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllRolesSaga({ payload }) {
  try {
    const response = yield call(getAllRolesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAllRolesSuccess(response?.data));
    } else {
      yield put(getAllRolesError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllFacultyDetailsSaga({ payload }) {
  try {
    const response = yield call(getAllUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAllFacultyDetailsSuccess(response?.data));
    } else {
      yield put(getAllFacultyDetailsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllHodsDetailsSaga({ payload }) {
  try {
    const response = yield call(getAllUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAllHodDetailsSuccess(response?.data));
    } else {
      yield put(getAllHodDetailsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllHodsDetailsByScrollSaga({ payload }) {
  try {
    const response = yield call(getAllUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAllHodDetailsByScrollSuccess(response?.data));
    } else {
      yield put(getAllHodDetailsByScrollError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllStudentsDetailsSaga({ payload }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(getAllUserDetailsApi, payload);
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getAllStudentsDetailsSuccess(response?.data));
    } else {
      yield put(getAllStudentsDetailsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllRegistrarDetailsSaga({ payload }) {
  try {
    const response = yield call(getAllUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAllRegistrarDetailsSuccess(response?.data));
    } else {
      yield put(getAllRegistrarDetailsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* getStudentsListSaga({ payload }) {
  try {
    yield put(studentsListLoading(true));
    const response = yield call(getAllUserDetailsApi, payload);
    yield put(studentsListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessStudentsList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStaffsListSaga({ payload }) {
  try {
    yield put(staffsListLoading(true));
    const response = yield call(getAllUserDetailsApi, payload);
    yield put(staffsListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessStaffsList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteUserSaga({ payload }) {
  try {
    const response = yield call(deleteUserApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteUserSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(deleteUserError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: response?.data?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* deleteStudentSaga({ payload }) {
  try {
    const response = yield call(deleteStudentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteStudentSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(deleteStudentError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* getDeptUserDetailsSaga({ payload }) {
  try {
    const response = yield call(getDeptUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getDeptUserDetailsSuccess(response?.data));
    } else {
      yield put(getDeptUserDetailsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getDeptUserDetailsByPaginationSaga({ payload }) {
  try {
    const response = yield call(getDeptUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getDeptUserDetailsSuccessByPagination(response?.data));
    } else {
      yield put(getDeptUserDetailsErrorByPagination(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* getDeptFacultyDetailsSaga({ payload }) {
  try {
    const response = yield call(getDeptUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyByDeptDetailsSuccess(response?.data));
    } else {
      yield put(getFacultyByDeptDetailsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* createStudentSaga({ payload }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(createStudentApi, payload);
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createSuccessStudent(response?.data));
      yield put(setToastMessage({ data: 'Student Onboarded Successfully.' }));
    } else {
      yield put(createErrorStudent(response?.data));
      yield put(
        setToastMessage({
          data:
            // Object.keys(response?.data?.message)?.[0] +
            Object.values(response?.data?.message)?.[0],
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
    yield put(setUserDetailsLoading(false));
  }
}

function* updateStudentSaga({ payload }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(updateStudentApi, payload);
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createSuccessStudent(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(createErrorStudent(response?.data?.error));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
    yield put(setUserDetailsLoading(false));
  }
}

function* createStudentCourseMappingSaga({ payload }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(createUserCourseMappingApi, payload);
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createSuccessStudentCourseMapping(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

// function* createStaffSaga({ payload }) {
//   try {
//     // yield put(setUserDetailsLoading(true));
//     const response = yield call(createUserApi, payload);
//     // yield put(setUserDetailsLoading(false));
//     if (response?.status === statusCode.Created201) {
//       response.data.isSuccess = true;
//       yield put(createSuccessStaff(response?.data));
//       yield put(setToastMessage({ data: 'Staff Onboarded Successfully.' }));
//     } else {
//       yield put(createErrorStaff(response?.data));
//       yield put(
//         setToastMessage({
//           data: response?.data?.message?.non_field_errors?.[0],
//           severity: 'error',
//         })
//       );
//       for (const errorArray of Object.values(response?.data?.message)) {
//         if (Array.isArray(errorArray)) {
//           for (const errorMessage of errorArray) {
//             yield put(
//               setToastMessage({
//                 data: Object.values(errorMessage)?.[0],
//                 severity: 'error',
//               })
//             );
//           }
//         }
//       }
//     }
//   } catch (error) {
//     console.error(error);
//     yield put(setToastMessage({ data: error?.message, severity: 'error' }));
//   }
// }

function* createStaffSaga({ payload }) {
  try {
    const response = yield call(createUserApi, payload);

    if (response?.status === statusCode.Created201) {
      response.data.isSuccess = true;
      yield put(createSuccessStaff(response?.data));
      yield put(setToastMessage({ data: 'Staff Onboarded Successfully.' }));
    } else {
      yield put(createErrorStaff(response?.data));
      let errorMessage = null;
      for (const field in response?.data?.message) {
        if (
          Array.isArray(response?.data?.message[field]) &&
          response?.data?.message[field].length > 0
        ) {
          errorMessage = response?.data?.message[field][0];
          break;
        }
      }
      if (errorMessage) {
        yield put(
          setToastMessage({
            data: errorMessage || response?.data?.message,
            severity: 'error',
          })
        );
      } else if (response?.data?.message?.non_field_errors?.length > 0) {
        yield put(
          setToastMessage({
            data:
              response?.data?.message?.non_field_errors[0] ||
              response?.data?.message,
            severity: 'error',
          })
        );
      } else {
        for (const errorArray of Object.values(response?.data?.message)) {
          if (Array.isArray(errorArray)) {
            for (const errorMessage of errorArray) {
              yield put(
                setToastMessage({
                  data:
                    Object.values(errorMessage)?.[0] || response?.data?.message,
                  severity: 'error',
                })
              );
            }
          }
        }
      }
    }
  } catch (error) {
    yield put(setToastMessage({ data: error?.message, severity: 'error' }));
  }
}

function* updateStaffSaga({ payload }) {
  try {
    yield put(setStaffLoading(true));
    const response = yield call(updateUserApi, payload);
    yield put(setStaffLoading(false));
    if (
      response?.status === statusCode.Ok200 ||
      response?.status === statusCode.Created201
    ) {
      yield put(updateStaffSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Staff Updated Succesfully',
        })
      );
    } else {
      yield put(updateStaffError(response?.data));
      yield put(setStaffLoading(false));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    // yield put(
    //   setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    // );
    console.error(error);
  }
}

function* blockUserSaga({ payload }) {
  try {
    // yield put(setUserDetailsLoading(true));
    const response = yield call(blockUserApi, payload);
    // yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(blockStaffSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    // yield put(
    //   setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    // );
    console.error(error);
  }
}

function* uploadUserImageSaga({ payload, data }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(uploadUserImageApi, payload, data);
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(successUploadImage(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStudentDetailsSaga({ payload }) {
  try {
    yield put(studentsListLoading(true));
    const response = yield call(getStudentDetailsApi, payload);
    yield put(studentsListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessStudentDetails(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStaffDetailsSaga({ payload }) {
  try {
    yield put(staffsListLoading(true));
    const response = yield call(getUserDetailsApi, payload.userId);
    yield put(staffsListLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessStaffDetails(response?.data));
    } else {
      yield put(getErrorStaffDetails(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getErrorStaffDetails(response?.data));
    yield put(
      setToastMessage({
        data: response?.data?.message,
        severity: 'error',
      })
    );
    console.error(error);
  }
}

function* userUploadValidationSaga({ payload }) {
  try {
    // yield put(setUserDetailsLoading(true));
    const response = yield call(userUploadValidationApi, payload);
    // yield put(setUserDetailsLoading(false));

    if (response?.status === statusCode.Ok200) {
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(errorUploadUser(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* getDesignationListSaga({ payload }) {
  try {
    // yield put(setUserDetailsLoading(true));
    const response = yield call(getDesignationApi, payload);
    // yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getDesignationSuccess(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* uploadStaffImageSaga({ payload }) {
  try {
    // yield put(setUserDetailsLoading(true));
    const response = yield call(uploadUserImageApi, payload);
    // yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(successStaffUploadImage(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getFilteredUserDetailsSaga({ payload }) {
  try {
    const response = yield call(getFilteredUserDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getFilteredUserDetailsSuccess(response?.data));
    } else {
      yield put(getFilteredUserDetailsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAddMoreUserSaga({ payload }) {
  try {
    // const response = yield call(getFilteredUserDetailsApi, payload);
    const response = yield call(getAllUserDetailsApi, payload);

    if (response?.status === statusCode.Ok200) {
      yield put(getFilteredUserDetailsSuccess(response?.data));
    } else {
      yield put(getFilteredUserDetailsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getClustersForUserSaga({ payload }) {
  try {
    // const response = yield call(getFilteredUserDetailsApi, payload);
    const response = yield call(getClustersListApi, payload);

    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessAllClustersList(response?.data));
    } else {
      yield put(geterrorAllClustersList(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* getDepartmentByIdUserSaga({ payload }) {
  try {
    // const response = yield call(getFilteredUserDetailsApi, payload);
    const response = yield call(getDepartmentsByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessDepartmentbyId(response?.data));
    } else {
      yield put(getErrorDepartmentbyId(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* getCourseByIdUserSaga({ payload }) {
  try {
    // const response = yield call(getFilteredUserDetailsApi, payload);
    const response = yield call(getCoursesByIdApi, payload);

    if (response?.status === statusCode.Ok200) {
      yield put(getSuccessCoursesbyId(response?.data));
    } else {
      yield put(getErrorCoursesbyId(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* localUserLoginSaga({ payload }) {
  try {
    const response = yield call(localUserLoginApi, payload);
    if (response?.status === statusCode.Ok200) {
      if (response?.data?.errorMessage === 'InvalidUserNameOrEmailAddress') {
        yield put(
          setToastMessage({
            data: response?.data?.errorMessage || 'Username is incorrect.',
            severity: 'error',
          })
        );
      } else if (response?.data?.errorMessage === 'InvalidPassword') {
        yield put(
          setToastMessage({
            data: response?.data?.errorMessage || 'Password is incorrect.',
            severity: 'error',
          })
        );
      } else {
        const token = response?.data?.accessToken;
        const decodedJwtToken = token ? parseJwt(token) : null;
        localStorageHelper.set('access_token', response?.data?.accessToken);
        localStorageHelper.set('tenantId', response?.data?.tenantId);
        yield put(localUserLoginSuccessAction(true));
        localStorageHelper.set('user_id', response?.data?.userId);
        localStorageHelper.set('loginType', User.localUser);
        yield put(saveLoginTypeAction(User.localUser));
        yield put(
          getLoginDetails({
            userId: response?.data?.userId,
            role: decodedJwtToken?.role?.toLowerCase(),
          })
        );
        // yield put(getUserRoleAction());
      }
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: 'An error occurred during login.',
        severity: 'error',
      })
    );
    console.error(error);
  }
}

function* localUserLogoutSaga({ payload }) {
  try {
    const response = yield call(localUserLogoutApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(localUserLogoutSuccessAction(true));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getTenantPublicImages({ payload }) {
  try {
    const response = yield call(getTenantPublicImagesRequest, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getTenantImagesSuccess(response?.data));
    } else {
      yield put(getTenantImagesSuccess({}));
    }
  } catch (error) {
    yield put(getTenantImagesSuccess({}));
  }
}

function* updateCandidateToStudentSaga({ payload }) {
  try {
    const response = yield call(updateCandidateToStudentApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateCandidateToStudentSuccess(response?.data));
    } else {
      yield put(updateCandidateToStudentSuccess({}));
    }
  } catch (error) {
    yield put(updateCandidateToStudentError({}));
  }
}

function* getCandidateOnbardingPendingSaga({ payload }) {
  try {
    const response = yield call(getLeadToBeOnboardedApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCandidatePendingSuccess(response?.data));
    } else {
      yield put(getCandidatePendingSuccess({}));
    }
  } catch (error) {
    yield put(getCandidatePendingError({}));
  }
}

function* getCandidateOnbardedSaga({ payload }) {
  try {
    const response = yield call(getLeadOnboardedListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCandidateOnboardedSuccess(response?.data));
    } else {
      yield put(getCandidateOnboardedSuccess({}));
    }
  } catch (error) {
    yield put(getCandidateOnboardedError({}));
  }
}

export function* getCandidateLoginOtpSaga({ payload }) {
  try {
    const response = yield call(getCandidateLoginOtpApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(setCandidateLoginOtpSuccess(response?.data));
      yield put(
        setToastMessage({ data: 'OTP sent successfully', severity: 'success' })
      );
    } else {
      yield put(setCandidateLoginOtpError(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    yield put(setCandidateLoginOtpError(error));
  }
}

export function* getCandidateLoginVerifyOtpSaga({ payload }) {
  try {
    const response = yield call(getCandidateLoginVerifyOtpApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(
        setToastMessage({ data: 'Login Successful', severity: 'success' })
      );
      localStorageHelper.set(
        'access_token',
        response?.data?.access_token?.access
      );
      localStorageHelper.set(
        'refresh_token',
        response?.data?.access_token?.refresh
      );
      localStorageHelper.set('user_id', response?.data?.user_id);
      localStorageHelper.set('loginType', User.otpUser);
      localStorageHelper.set('role_name', Role.candidate);
      yield put(saveLoginTypeAction(User.otpUser));
      yield put(getCandidateInfoById(response?.data?.user_id));
      yield put(setCandidateLoginVerifyOtpSuccess(response?.data));
    } else {
      yield put(setCandidateLoginVerifyOtpError(response?.error));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    yield put(setCandidateLoginVerifyOtpError(error));
  }
}

function* getCandidateByIdSaga({ payload }) {
  try {
    const response = yield call(getCandidateInformationByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      const userDatails = generateUserDetailsForCandidate(response?.data);
      const userRoleData = {
        userRole: {
          name: Role.candidate,
          id: '',
        },
        rolePermissions: [],
      };
      yield put(getUserDetailsSuccess(userDatails));
      yield put(getUserRoleSuccess(userRoleData));
    } else {
      setToastMessage({
        data: response?.data?.message ?? 'Failed to get candiadate details',
        severity: 'error',
      });
    }
  } catch (error) {
    setToastMessage({
      data: error?.data?.message ?? 'Failed to get candiadate details',
      severity: 'error',
    });
  }
}

function* getAllUserListSaga({ payload }) {
  try {
    const response = yield call(getAllUserListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setUserDetailsLoading(true));
      yield put(getUserListForHRSuccess(response?.data));
      yield put(setUserDetailsLoading(false));
    } else {
      yield put(
        setToastMessage({ data: 'Something went wrong', severity: 'error' })
      );
      yield put(getUserListForHRError('Something went wrong'));
    }
  } catch (error) {
    yield put(getUserListForHRSuccess({}));
    yield put(getUserListForHRError(error));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

function* getAllUserRolesSaga({ payload }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(getAllUserRolesApi, payload);
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getAllUserRolesSuccess(response?.data));
    } else {
      yield put(getAllUserRolesError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteUserRolesSaga({ payload }) {
  try {
    const response = yield call(deleteUserRolesApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteUserRolesSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Role Deleted Succesfully',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteUserRolesError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* getUserRoleDetailsSaga({ payload }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(getUserRoleDetailsApi, payload);
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getUserRoleDetailsSuccess(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

//Create user role
function* createUserRoleSaga({ payload }) {
  try {
    yield put(setUserDetailsLoading(true));
    const response = yield call(createUserRoleApi, payload);
    yield put(setUserDetailsLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createUserRoleSuccess(response?.data?.message));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'success' })
      );
    } else if (response?.status === statusCode.Conflict409) {
      yield put(createUserRoleError('Role already exists'));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    } else {
      // yield put(createUserRoleError('Something went wrong'));
      // yield put(
      //   setToastMessage({ data: response?.data?.message, severity: 'error' })
      // );
    }
  } catch (error) {
    yield put(createUserRoleError(error));
    // yield put(
    //   setToastMessage({ data: 'Something went wrong', severity: 'error' })
    // );
  }
}

//Update user role
function* updateUserRoleSaga({ payload }) {
  try {
    const response = yield call(updateUserRoleApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateUserRoleSuccess(response?.data?.message));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'success' })
      );
    } else {
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
      yield put(updateUserRoleError('Something went wrong'));
    }
  } catch (error) {
    yield put(updateUserRoleError(error));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

function* createGroupSaga({ payload }) {
  try {
    const response = yield call(createGroupApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(createSuccessGroup(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(createErrorGroup(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}
//Edit Group
function* editGroupSaga({ payload }) {
  try {
    const { group_id } = payload;
    const response = yield call(editGroupApi, payload, group_id);
    if (response?.status === statusCode.Ok200) {
      yield put(editGroupSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(editGroupError(response?.data?.error));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}
//Delete Group
function* deleteGroupSaga({ payload }) {
  try {
    const { group_id } = payload;
    const response = yield call(deleteGroupApi, payload, group_id);
    if (response?.status === statusCode.Ok200) {
      yield put(deleteGroupSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(deleteGroupError(response?.data?.error));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

//get Group details
function* getGroupListSaga({ payload }) {
  try {
    const response = yield call(getGroupDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getGroupDetailsSuccess(response?.data));
    } else {
      yield put(
        setToastMessage({ data: 'Something went wrong', severity: 'error' })
      );
      yield put(getGroupDetailsError('Something went wrong'));
    }
  } catch (error) {
    yield put(getGroupDetailsSuccess({}));
    yield put(getGroupDetailsError(error));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

//get group details by id
function* getGroupDetailsByIdSaga({ payload }) {
  try {
    const response = yield call(getGropDetailsByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getGroupDetailsByIdSuccess(response?.data));
    } else {
      yield put(
        setToastMessage({ data: 'Something went wrong', severity: 'error' })
      );
      yield put(getGroupDetailsByIdError('Something went wrong'));
    }
  } catch (error) {
    yield put(getGroupDetailsByIdError(error));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}
//Search User
function* searchUserSaga({ payload }) {
  try {
    const response = yield call(searchUserApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(searchUserSuccess(response?.data));
    } else {
      yield put(
        setToastMessage({ data: 'Something went wrong', severity: 'error' })
      );
      yield put(searchUserError('Something went wrong'));
    }
  } catch (error) {
    yield put(searchUserSuccess({}));
    yield put(searchUserError(error));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}
//FiLter User
function* filterUserSaga({ payload }) {
  try {
    const response = yield call(FilterUserApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(filterUserSuccess(response?.data));
    } else {
      yield put(
        setToastMessage({ data: 'Something went wrong', severity: 'error' })
      );
      yield put(filterUserError('Something went wrong'));
    }
  } catch (error) {
    yield put(searchUserSuccess({}));
    yield put(searchUserError(error));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

function* filterAllUserSaga({ payload }) {
  try {
    const response = yield call(FilterAllUserApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(filterAllUserSuccess(response?.data));
    } else {
      yield put(
        setToastMessage({ data: 'Something went wrong', severity: 'error' })
      );
      yield put(filterAllUserError('Something went wrong'));
    }
  } catch (error) {
    yield put(searchUserSuccess({}));
    yield put(searchUserError(error));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

//Remove user group
function* removeUserSaga(action) {
  try {
    const { groupId, userData } = action.payload;
    const response = yield call(removeUserAPI, groupId, userData);
    if (response?.status === statusCode.Ok200) {
      yield put(setRemoveUserSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(setRemoveUserError(response?.data?.error));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}
function* userGroupBulkUploadSaga(action) {
  const payload = action.payload;
  try {
    const response = yield call(userBulkGroupUpload, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(userGrpBulkUploadSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(userGrpBulkUploadError(response?.data?.error));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

//Add selected user
function* addSelectedUserSaga({ payload }) {
  try {
    const response = yield call(addSelectedUserApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setAddSelectedUserSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'User added Successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(setAddSelectedUserError(response?.data?.error));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

// forgot password
function* forgotPasswordSaga({ payload }) {
  try {
    const response = yield call(forgotPasswordAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(forgotPasswordSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(forgotPasswordError(response?.data?.message));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

// Login Saga
function* getUserLoginDetailsSaga({ payload }) {
  try {
    const response = yield call(getLoginDetailsApi, payload);
    yield put(setUserDetailsLoading(true));
    if (response?.status === statusCode.Ok200) {
      yield put(getLoginDetailsSuccess(response?.data));
      yield put(getUserDetailsSuccess(response?.data));
      yield put(getSuccessStaffDetails(response?.data));
    } else {
      yield put(
        setToastMessage({
          data: 'Failed to fetch user details',
          severity: 'error',
        })
      );
      yield put(getLoginDetailsError('Something went wrong'));
    }
    yield put(setUserDetailsLoading(true));
  } catch (error) {
    yield put(getLoginDetailsError(error));
    yield put(setUserDetailsLoading(true));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

//
function* getUserAnyDetailsSaga({ payload }) {
  try {
    const response = yield call(getLoginDetailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(geAnyDetailsSuccess(response?.data));
    } else {
      yield put(
        setToastMessage({ data: 'Something went wrong', severity: 'error' })
      );
      yield put(geAnyDetailsError('Something went wrong'));
    }
  } catch (error) {
    yield put(geAnyDetailsError(error));
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
  }
}

// Deactivate password
function* deactivateGroupSaga({ payload }) {
  try {
    const response = yield call(userDeactivateGroupAPI, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(deactivateGroupSuccess(response?.data));
      yield put(setToastMessage({ data: '', severity: 'success' }));
    } else {
      yield put(deactivateGroupError(response?.data?.message));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

function* studentUploadValidationSaga({ payload }) {
  try {
    // yield put(setUserDetailsLoading(true));
    const response = yield call(studentUploadValidationApi, payload);
    // yield put(setUserDetailsLoading(false));

    if (response?.status === statusCode.Ok200) {
      yield put(studentBulkUploadSuccess(response?.data));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'success' })
      );
    } else {
      yield put(studentBulkUploadError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'User not uploaded',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({ data: error?.data?.error?.message, severity: 'error' })
    );
    console.error(error);
  }
}

//bulk user role
function* bulkUserRoleSaga({ payload }) {
  try {
    const response = yield call(bulkUserRoleApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(bulkUserRoleSuccess(response?.data?.message));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'success' })
      );
    } else {
      yield put(bulkUserRoleError('Something went wrong'));
      yield put(
        setToastMessage({ data: response?.data?.message, severity: 'error' })
      );
    }
  } catch (error) {
    yield put(bulkUserRoleError(error));
    yield put(
      setToastMessage({ data: 'Something went wrong', severity: 'error' })
    );
  }
}

//notification
function* getUserForNotificationSaga({ payload }) {
  try {
    const response = yield call(getUserforNotificationApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAllUserDetailsNotificationSuccess(response?.data));
    } else {
      yield put(getAllUserDetailsNotificationError(response?.data));
    }
  } catch (error) {
    yield put(getAllUserDetailsNotificationError(error));
  }
}

export function* watchDummy() {
  yield takeLatest(USER.get, getUserDetailsSaga);
  yield takeLatest(USER_ROLE.get, getUserRolePermissionsSaga);
  yield takeLatest(USER_ROLE.get_all_roles, getAllRolesSaga);
  yield takeLatest(USER_DETAILS.get_all_faculties, getAllFacultyDetailsSaga);
  yield takeLatest(USER_DETAILS.get_all_hods, getAllHodsDetailsSaga);
  yield takeLatest(
    USER_DETAILS.get_all_hods_by_scroll,
    getAllHodsDetailsByScrollSaga
  );
  yield takeLatest(USER_DETAILS.get_all_students, getAllStudentsDetailsSaga);
  yield takeLatest(USER_DETAILS.get_all_registrars, getAllRegistrarDetailsSaga);
  yield takeLatest(USER_DETAILS.get_dept_user_details, getDeptUserDetailsSaga);
  yield takeLatest(
    USER_DETAILS.get_dept_user_details_by_pagination,
    getDeptUserDetailsByPaginationSaga
  );
  yield takeLatest(STUDENT.get, getStudentsListSaga);
  yield takeLatest(STAFF.get, getStaffsListSaga);
  yield takeLatest(STUDENT.create, createStudentSaga);
  yield takeLatest(STUDENT.update, updateStudentSaga);
  yield takeLatest(STAFF.block, blockUserSaga);

  yield takeLatest(
    STUDENT.create_student_course_mapping,
    createStudentCourseMappingSaga
  );
  yield takeLatest(STAFF.create, createStaffSaga);
  yield takeLatest(STAFF.update, updateStaffSaga);
  yield takeLatest(STUDENT.get_details, getStudentDetailsSaga);
  yield takeLatest(STAFF.get_details, getStaffDetailsSaga);
  yield takeLatest(STUDENT.upload_img, uploadUserImageSaga);
  yield takeLatest(STAFF.get_designation, getDesignationListSaga);
  yield takeLatest(STAFF.staff_upload_img, uploadStaffImageSaga);
  yield takeLatest(STAFF.delete_user, deleteUserSaga);
  yield takeLatest(STUDENT.delete_student, deleteStudentSaga);
  yield takeLatest(
    USER_DETAILS.get_filtered_user_details,
    getFilteredUserDetailsSaga
  );
  yield takeLatest(USER_DETAILS.get_add_more_user_details, getAddMoreUserSaga);
  yield takeLatest(CLUSTERS.get, getClustersForUserSaga);
  yield takeLatest(DEPARTMENTS.get_by_id, getDepartmentByIdUserSaga);
  yield takeLatest(COURSES.get_by_id, getCourseByIdUserSaga);
  yield takeLatest(STUDENT.upload_validation, userUploadValidationSaga);
  yield takeLatest(STUDENT.bulk_upload_student, studentUploadValidationSaga);

  yield takeLatest(
    USER_DETAILS.get_faculties_by_dept,
    getDeptFacultyDetailsSaga
  );
  yield takeLatest(AUTHENTICATION.LOCAL_USER_LOGIN, localUserLoginSaga);
  yield takeLatest(AUTHENTICATION.LOCAL_USER_LOGOUT, localUserLogoutSaga);
  yield takeLatest(
    USER_LOGIN_DETAILS.USER_LOGIN_DETAILS_REQUEST,
    getUserLoginDetailsSaga
  );

  // get tenant specific and sponsor logo's - Public API
  yield takeLatest(
    TENANT_SPECIFIC_LOGO.TENANT_SPECIFIC_LOGO_REQUEST,
    getTenantPublicImages
  );

  yield takeLatest(
    CANDIDATE_STUDENT.CANDIDATE_STUDENT_GET,
    updateCandidateToStudentSaga
  );
  yield takeLatest(
    CANDIDATE_ONBOARDED.CANDIDATE_ONBOARDED_GET,
    getCandidateOnbardedSaga
  );
  yield takeLatest(
    CANDIDATE_ONBOARDING_PENDING.CANDIDATE_ONBOARDING_GET,
    getCandidateOnbardingPendingSaga
  );
  yield takeLatest(CANDIDATE_LOGIN.SEND_OTP, getCandidateLoginOtpSaga);
  yield takeLatest(CANDIDATE_LOGIN.VERIFY_OTP, getCandidateLoginVerifyOtpSaga);
  yield takeLatest(CANDIDATE_LOGIN.CANDIDATE_INFORMATION, getCandidateByIdSaga);
  yield takeLatest(HR.GET_ALL_USER_REQUEST, getAllUserListSaga);
  yield takeLatest(ALL_USER_ROLE.GET_ALL_USER_ROLES, getAllUserRolesSaga);
  yield takeLatest(DELETE_USER_ROLE.DELETE_USER_ROLES, deleteUserRolesSaga);
  yield takeLatest(
    USER_ROLE_DETAILS.GET_USER_ROLE_DETAILS,
    getUserRoleDetailsSaga
  );
  yield takeLatest(
    ANY_USER_DETAILS.ANY_USER_DETAILS_REQUEST,
    getUserAnyDetailsSaga
  );
  yield takeLatest(CREATE_USER_ROLES.CREATE_USER_ROLE, createUserRoleSaga);
  yield takeLatest(UPDATE_USER_ROLES.UPDATE_USER_ROLE, updateUserRoleSaga);

  yield takeLatest(GROUP.CREATE_GROUP, createGroupSaga);
  yield takeLatest(GROUP.EDIT_GROUP, editGroupSaga);
  yield takeLatest(GROUP.DELETE_GROUP, deleteGroupSaga);
  yield takeLatest(GROUP.SEARCH_USER, searchUserSaga);
  yield takeLatest(GROUP.APPLY_FILTER, filterUserSaga);
  yield takeLatest(GROUP.APPLY_ALL_FILTER, filterAllUserSaga);
  yield takeLatest(GROUP.get_group_details, getGroupListSaga);
  yield takeLatest(
    GROUP.USER_GROUP_BULK_UPLOAD_REQUEST,
    userGroupBulkUploadSaga
  );
  yield takeLatest(GROUP.DEACTIVATE_GROUP, deactivateGroupSaga);
  yield takeLatest(GROUP.get_group_details_by_id, getGroupDetailsByIdSaga);
  yield takeLatest(GROUP.REMOVE_USER, removeUserSaga);
  yield takeLatest(GROUP.ADD_SELECTED_USER_TO_GROUP, addSelectedUserSaga);
  yield takeLatest(FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
  yield takeLatest(BULK_USER_ROLES.BULK_USER_ROLE_REQUEST, bulkUserRoleSaga);
  yield takeLatest(
    STUDENT.get_student_details_with_program_list,
    getStudentListWithProgramSaga
  );
  yield takeLatest(STUDENT.get_all_program_list, getProgramDataSaga);
  yield takeLatest(
    USER_DETAILS.get_user_for_notification_action,
    getUserForNotificationSaga
  );
}

export default function* userManagementSaga() {
  yield all([watchDummy()]);
}
