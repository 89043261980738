export const courseContentActions = {
  COURSE_CONTENT_FEEDBACK_LOADER: 'COURSE_CONTENT_FEEDBACK_LOADER',
  COURSECONTENT: {
    create: 'COURSE_CONTENT_FEEDBACK_ACTION',
    create_success: 'COURSE_CONTENT_FEEDBACK_SUCCESS',
    create_error: 'COURSE_CONTENT_FEEDBACK_ERROR',
    create_reset: 'COURSE_CONTENT_FEEDBACK_RESET',
  },
  COURSESECTION: {
    create: 'COURSE_CONTENT_FEEDBACK_SECTION_ACTION',
    create_success: 'COURSE_CONTENT_FEEDBACK_SECTION_SUCCESS',
    create_error: 'COURSE_CONTENT_FEEDBACK_SECTION_ERROR',
    create_reset: 'COURSE_CONTENT_FEEDBACK_SECTION_RESET',
  },
  GETSECTION: {
    GET_SECTION_ACTION: 'GET_SECTION_ACTION',
    GET_SECTION_SUCCESS: 'GET_SECTION_SUCCESS',
    GET_SECTION_ERROR: 'GET_SECTION_ERROR',
    GET_SECTION_RESET: 'GET_SECTION_RESET',
  },
  DELETESECTION: {
    DELETE_SECTION_ACTION: 'DELETE_SECTION_ACTION',
    DELETE_SECTION_SUCCESS: 'DELETE_SECTION_SUCCESS',
    DELETE_SECTION_ERROR: 'DELETE_SECTION_ERROR',
    DELETE_SECTION_RESET: 'DELETE_SECTION_RESET',
  },
  UPDATESECTION: {
    UPDATE_SECTION_ACTION: 'UPDATE_SECTION_ACTION',
    UPDATE_SECTION_SUCCESS: 'UPDATE_SECTION_SUCCESS',
    UPDATE_SECTION_ERROR: 'UPDATE_SECTION_ERROR',
    UPDATE_SECTION_RESET: 'UPDATE_SECTION_RESET',
  },
  GETCOURSECONTENT: {
    GET_COURSE_CONTENT_ACTION: 'GET_COURSE_CONTENT_ACTION',
    GET_COURSE_CONTENT_SUCCESS: 'GET_COURSE_CONTENT_SUCCESS',
    GET_COURSE_CONTENT_ERROR: 'GET_COURSE_CONTENT_ERROR',
    GET_COURSE_CONTENT_RESET: 'GET_COURSE_CONTENT_RESET',
  },
  GETCOURSECONTENTBYID: {
    GET_COURSE_CONTENT_BY_ID_ACTION: 'GET_COURSE_CONTENT_BY_ID_ACTION',
    GET_COURSE_CONTENT_BY_ID_SUCCESS: 'GET_COURSE_CONTENT_BY_ID_SUCCESS',
    GET_COURSE_CONTENT_BY_ID_ERROR: 'GET_COURSE_CONTENT_BY_ID_ERROR',
    GET_COURSE_CONTENT_BY_ID_RESET: 'GET_COURSE_CONTENT_BY_ID_RESET',
  },
  UPDATECOURSECONTENT: {
    UPDATE_COURSE_CONTENT_ACTION: 'UPDATE_COURSE_CONTENT_ACTION',
    UPDATE_COURSE_CONTENT_SUCCESS: 'UPDATE_COURSE_CONTENT_SUCCESS',
    UPDATE_COURSE_CONTENT_ERROR: 'UPDATE_COURSE_CONTENT_ERROR',
    UPDATE_COURSE_CONTENT_RESET: 'UPDATE_COURSE_CONTENT_RESET',
  },
  CREATEQUESTION: {
    CREATE_QUESTION_ACTION: 'CREATE_QUESTION_ACTION',
    CREATE_QUESTION_SUCCESS: 'CREATE_QUESTION_SUCCESS',
    CREATE_QUESTION_ERROR: 'CREATE_QUESTION_ERROR',
    CREATE_QUESTION_RESET: 'CREATE_QUESTION_RESET',
  },
  CREATEDUPLICATE: {
    CREATE_DUPLICATE_ACTION: 'CREATE_DUPLICATE_ACTION',
    CREATE_DUPLICATE_SUCCESS: 'CREATE_DUPLICATE_SUCCESS',
    CREATE_DUPLICATE_ERROR: 'CREATE_DUPLICATE_ERROR',
    CREATE_DUPLICATE_RESET: 'CREATE_DUPLICATE_RESET',
  },
  DELETECONTENT: {
    DELETE_CONTENT_ACTION: 'DELETE_CONTENT_ACTION',
    DELETE_CONTENT_SUCCESS: 'DELETE_CONTENT_SUCCESS',
    DELETE_CONTENT_ERROR: 'DELETE_CONTENT_ERROR',
    DELETE_CONTENT_RESET: 'DELETE_CONTENT_RESET',
  },
  GETQUESTION: {
    GET_QUESTION_ACTION: 'GET_QUESTION_ACTION',
    GET_QUESTION_SUCCESS: 'GET_QUESTION_SUCCESS',
    GET_QUESTION_ERROR: 'GET_QUESTION_ERROR',
    GET_QUESTION_RESET: 'GET_QUESTION_RESET',
  },
  GETQUESTIONBYID: {
    GET_QUESTION_BY_ID_ACTION: 'GET_QUESTION_BY_ID_ACTION',
    GET_QUESTION_BY_ID_SUCCESS: 'GET_QUESTION_BY_ID_SUCCESS',
    GET_QUESTION_BY_ID_ERROR: 'GET_QUESTION_BY_ID_ERROR',
    GET_QUESTION_BY_ID_RESET: 'GET_QUESTION_BY_ID_RESET',
  },
  UPDATEQUESTION: {
    UPDATE_QUESTION_ACTION: 'UPDATE_QUESTION_ACTION',
    UPDATE_QUESTION_SUCCESS: 'UPDATE_QUESTION_SUCCESS',
    UPDATE_QUESTION_ERROR: 'UPDATE_QUESTION_ERROR',
    UPDATE_QUESTION_RESET: 'UPDATE_QUESTION_RESET',
  },
  PUBLISHCOURSECONTENT: {
    PUBLISH_COURSE_CONTENT_ACTION: 'PUBLISH_COURSE_CONTENT_ACTION',
    PUBLISH_COURSE_CONTENT_SUCCESS: 'PUBLISH_COURSE_CONTENT_SUCCESS',
    PUBLISH_COURSE_CONTENT_ERROR: 'PUBLISH_COURSE_CONTENT_ERROR',
    PUBLISH_COURSE_CONTENT_RESET: 'PUBLISH_COURSE_CONTENT_RESET',
  },
  STOREIDS: {
    STORE_IDS_ACTION: 'STORE_IDS_ACTION',
  },
  GETQUESTIONBYSECTION: {
    GET_QUESTION_BY_SECTION_ACTION: 'GET_QUESTION_BY_SECTION_ACTION',
    GET_QUESTION_BY_SECTION_SUCCESS: 'GET_QUESTION_BY_SECTION_SUCCESS',
    GET_QUESTION_BY_SECTION_ERROR: 'GET_QUESTION_BY_SECTION_ERROR',
    GET_QUESTION_BY_SECTION_RESET: 'GET_QUESTION_BY_SECTION_RESET',
  },
  SAVEFEEDBACK: {
    SAVE_FEEDBACK_ACTION: 'SAVE_FEEDBACK_ACTION',
    SAVE_FEEDBACK_SUCCESS: 'SAVE_FEEDBACK_SUCCESS',
    SAVE_FEEDBACK_ERROR: 'SAVE_FEEDBACK_ERROR',
    SAVE_FEEDBACK_RESET: 'SAVE_FEEDBACK_RESET',
  },
};
