import { MyRepositoryActions } from './MyRepository.constants';
const { MYREPOSITORY } = MyRepositoryActions;

export const getRepositoryLoading = (payload) => ({
  type: MYREPOSITORY.loading,
  payload: payload,
});

export const getRepositories = (payload) => {
  {
    return {
      type: MYREPOSITORY.get,
      payload: payload,
    };
  }
};

export const getRepositoriesSuccess = (payload) => {
  {
    return {
      type: MYREPOSITORY.get_success,
      payload: payload,
    };
  }
};

export const getRepositoriesError = (payload) => {
  {
    return {
      type: MYREPOSITORY.get_error,
      payload: payload,
    };
  }
};

export const getRepositoriesReset = () => {
  {
    return {
      type: MYREPOSITORY.get_reset,
    };
  }
};

export const createReportDocument = (payload) => {
  {
    return {
      type: MYREPOSITORY.create_Report_Document,
      payload: payload,
    };
  }
};

export const createReportDocumentSuccess = (payload) => {
  {
    return {
      type: MYREPOSITORY.create_Report_Document_success,
      payload: payload,
    };
  }
};

export const createReportDocumentError = (payload) => {
  {
    return {
      type: MYREPOSITORY.create_Report_Document_error,
      payload: payload,
    };
  }
};

export const createReportDocumentReset = () => {
  {
    return {
      type: MYREPOSITORY.create_Report_Document_reset,
    };
  }
};

export const deleteReportDocument = (payload) => ({
  type: MYREPOSITORY.delete,
  payload: payload,
});

export const deleteReportDocumentSuccess = (payload) => {
  {
    return {
      type: MYREPOSITORY.delete_success,
      payload: payload,
    };
  }
};

export const deleteReportDocumentError = (payload) => {
  {
    return {
      type: MYREPOSITORY.delete_error,
      payload: payload,
    };
  }
};
export const updateReport = (payload) => ({
  type: MYREPOSITORY.UPDATE_LIST,
  payload: payload,
});

export const updateReportSuccess = (payload) => {
  {
    return {
      type: MYREPOSITORY.UPDATE_LIST_SUCCESS,
      payload: payload,
    };
  }
};

export const updateReportError = (payload) => {
  {
    return {
      type: MYREPOSITORY.UPDATE_LIST_ERROR,
      payload: payload,
    };
  }
};

export const shareFile = (payload) => ({
  type: MYREPOSITORY.SHARE_FILE,
  payload: payload,
});

export const shareFileSuccess = (payload) => {
  {
    return {
      type: MYREPOSITORY.SHARE_FILE_SUCCESS,
      payload: payload,
    };
  }
};

export const shareFileError = (payload) => {
  {
    return {
      type: MYREPOSITORY.SHARE_FILE_ERROR,
      payload: payload,
    };
  }
};

export const getUser = (payload) => {
  {
    return {
      type: MYREPOSITORY.get_by_user,
      payload: payload,
    };
  }
};

export const getUserSuccess = (payload) => ({
  type: MYREPOSITORY.get_by_user_success,
  payload: payload,
});

export const getUserError = (payload) => ({
  type: MYREPOSITORY.get_by_user_error,
  payload: payload,
});

export const bulkShareFileAction = (payload) => ({
  type: MYREPOSITORY.BULK_SHARE_FILE,
  payload: payload,
});

export const bulkShareFileSuccess = (payload) => ({
  type: MYREPOSITORY.BULK_SHARE_FILE_SUCCESS,
  payload: payload,
});

export const bulkShareFileError = (payload) => ({
  type: MYREPOSITORY.BULK_SHARE_FILE_ERROR,
  payload: payload,
});

export const bulkDeleteFileAction = (payload) => ({
  type: MYREPOSITORY.BULK_DELETE_FILE,
  payload: payload,
});

export const bulkDeleteFileSuccess = (payload) => ({
  type: MYREPOSITORY.BULK_DELETE_FILE_SUCCESS,
  payload: payload,
});

export const bulkDeleteFileError = (payload) => ({
  type: MYREPOSITORY.BULK_DELETE_FILE_ERROR,
  payload: payload,
});
