import * as React from 'react';

function DoubleLeftArrow({ className, ...props }) {
  return (
    <span className={`iiht-svg ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14.333}
        height={8.19}
        {...props}
      >
        <path d="M13.821.864v6.459L7.95 4.1 13.821.868M6.65.9v6.412L1.024 4.1 6.65.89M7.167 0L0 4.1l7.167 4.1V4.249l7.167 3.941V0L7.167 3.941z" />
      </svg>
    </span>
  );
}

export default DoubleLeftArrow;
