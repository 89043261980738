import React from 'react';

const VideoCam = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
      fill={fill}
    >
      <path d="M160 896q-33 0-56.5-23.5T80 816V336q0-33 23.5-56.5T160 256h480q33 0 56.5 23.5T720 336v180l160-160v440L720 636v180q0 33-23.5 56.5T640 896H160Zm0-80h480V336H160v480Zm0 0V336v480Z" />
    </svg>
  );
};

export default VideoCam;
