export const courseContentFeedbackAPI = {
  createContent: 'v2/course-content-feedback',
  createSection: 'v2/course-content-section',
  getSection: 'v2/course-content-section',
  createQuestion: '/v2/course-content-question',
  createDuplicate: '/v2/course-content-feedback/duplicate',
  deleteContent: '/v2/course-content-feedback',
  getQuestion: '/v2/course-content-question',
  publishContent: '/v2/course-content-feedback/publish',
  getQuestionbySection: '/v2/course-content-question',
  saveFeedback: '/v2/course-content-answer',
};
