import * as React from 'react';

const Remove = ({ fill = '#02387A', height = 20, width = 20, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 -960 960 960"
    width={width}
    fill={fill}
    {...rest}
  >
    <path d="M200-440v-80h560v80H200Z" />
  </svg>
);

export default Remove;
