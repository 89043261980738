import React from 'react';

const SchoolCapSvg = ({ fill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.153"
      height="32.034"
      viewBox="0 0 39.153 32.034"
      fill={fill}
    >
      <path
        id="school_FILL0_wght400_GRAD0_opsz48"
        d="M21.532,38.034,8.629,30.96V20.282L2,16.678,21.532,6,41.153,16.678v14.1h-2.67V18.235l-4.049,2.047V30.96Zm0-13.7,14.015-7.653L21.532,9.159,7.606,16.678Zm0,10.678,10.233-5.65V21.884L21.532,27.356,11.3,21.795v7.564ZM21.576,24.331ZM21.532,27.623ZM21.532,27.623Z"
        transform="translate(-2 -6)"
        fill="#ac0521"
      />
    </svg>
  );
};

export default SchoolCapSvg;
