import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  createSettingApi,
  deleteSettingApi,
  getSettingApi,
  getSettingsApi,
  updateSettingApi,
  getAllPrivacySettingsApi,
  getPrivacySettingsApi,
  updatePrivacySettingApi,
} from './settings.api';

import {
  createSettingsSuccess,
  createSettingsError,
  getSettingLoading,
  getSettingsSuccess,
  getSettingsError,
  getSettingSuccess,
  getSettingError,
  updateSettingSuccess,
  updateSettingError,
  deleteSettingSuccess,
  deleteSettingError,
  getAllPrivacySettingsSuccess,
  getAllPrivacySettingsError,
  getPrivacySettingsSuccess,
  getPrivacySettingsError,
  updatePrivacySettingSuccess,
  updatePrivacySettingError,
} from './Settings.action';
import { settingsActions } from './Settings.constants';
import { statusCode } from 'constants/apis/apiStatus';

function* settingsCreateSaga(action) {
  try {
    yield put(getSettingLoading(true));
    const response = yield call(createSettingApi);
    yield put(getSettingLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(createSettingsSuccess(response?.data));
    } else {
      yield put(createSettingsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* settingsGetListSaga(action) {
  try {
    yield put(getSettingLoading(true));
    const response = yield call(getSettingsApi);
    yield put(getSettingLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSettingsSuccess(response?.data));
    } else {
      yield put(getSettingsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* settingsGetSaga(action) {
  try {
    yield put(getSettingLoading(true));
    const response = yield call(getSettingApi);
    yield put(getSettingLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSettingSuccess(response?.data));
    } else {
      yield put(getSettingError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* settingsUpdateSaga(action) {
  try {
    yield put(getSettingLoading(true));
    const response = yield call(updateSettingApi, action.payload);
    yield put(getSettingLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateSettingSuccess(response?.data));
    } else {
      yield put(updateSettingError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* settingsDeleteSaga(action) {
  try {
    yield put(getSettingLoading(true));
    const response = yield call(deleteSettingApi);
    yield put(getSettingLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteSettingSuccess(response?.data));
    } else {
      yield put(deleteSettingError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* settingsAllPrivacyGetListSaga(action) {
  try {
    const response = yield call(getAllPrivacySettingsApi);
    if (response?.status === statusCode.Ok200) {
      yield put(getAllPrivacySettingsSuccess(response?.data));
    } else {
      yield put(getAllPrivacySettingsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* settingsPrivacyGetListSaga(action) {
  try {
    const response = yield call(getPrivacySettingsApi);
    if (response?.status === statusCode.Ok200) {
      yield put(getPrivacySettingsSuccess(response?.data));
    } else {
      yield put(getPrivacySettingsError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
function* settingsPrivacyUpdateSaga(action) {
  try {
    const response = yield call(updatePrivacySettingApi, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updatePrivacySettingSuccess(response?.data));
    } else {
      yield put(updatePrivacySettingError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}
export function* watchDummy() {
  yield takeLatest(settingsActions.PRIVACY.create, settingsCreateSaga);
  yield takeLatest(settingsActions.PRIVACY.get_list, settingsGetListSaga);
  yield takeLatest(settingsActions.PRIVACY.get, settingsGetSaga);
  yield takeLatest(settingsActions.PRIVACY.update, settingsUpdateSaga);
  yield takeLatest(settingsActions.PRIVACY.delete, settingsDeleteSaga);
  yield takeLatest(
    settingsActions.PRIVACY_NOTIFICATION.get_all_privacy_notification_list,
    settingsAllPrivacyGetListSaga
  );
  yield takeLatest(
    settingsActions.PRIVACY_NOTIFICATION.get_privacy_notification_list,
    settingsPrivacyGetListSaga
  );
  yield takeLatest(
    settingsActions.PRIVACY_NOTIFICATION.update_privacy_notification,
    settingsPrivacyUpdateSaga
  );
}

export default function* settingSaga() {
  yield all([watchDummy()]);
}
