import axiosInstance from 'translator';
import { CandidateAPI } from 'store/Candidate';

export const getCandidateCourseFormApi = async (payload) => {
  return axiosInstance
    .get(`${CandidateAPI.getCandidateCourseForm}?course_id=${payload.courseId}`)
    .then((response) => response)
    .catch((error) => error);
};

export const getCandidateCourseProspectusApi = async (payload) => {
  return axiosInstance
    .get(
      `${CandidateAPI.getCandidateCourseProspectus}?course_id=${payload?.courseId}`
    )
    .then((response) => response)
    .catch((error) => error);
};

export const getCandidateCourseFormInfoApi = async (payload) => {
  return axiosInstance
    .get(
      `${CandidateAPI.getCandidateCourseFormInfo}?lead=${payload?.uuid}&interested_course_id=${payload?.courseId}`
    )
    .then((response) => response)
    .catch((error) => error);
};

export const postCandidateCourseFormInfoApi = async (payload) => {
  return axiosInstance
    .post(`${CandidateAPI.postCandidateCourseFormInfo}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCandidateCourseFormInfoApi = async (
  payload,
  candidateFormInfoId
) => {
  return axiosInstance
    .put(
      `${CandidateAPI.postCandidateCourseFormInfo}/${candidateFormInfoId}`,
      payload
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
