import axiosInstance from 'translator';
import { groupChatApi } from './Groupchat.apiEndpoints';

export const getCandidateListApi = async (payload) => {
  return axiosInstance
    .get(`${groupChatApi?.candidateList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createGroupApi = async (payload) => {
  return axiosInstance
    .post(`${groupChatApi?.createGroup}`, { ...payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addCandidateToGroupApi = async (payload) => {
  return axiosInstance
    .post(`${groupChatApi?.addCandidate}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getGroupsAPI = async (payload) => {
  console.log('payload', payload);
  return axiosInstance
    .get(`${groupChatApi?.getGroups}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getGroupCandidateAPI = async (payload) => {
  return axiosInstance
    .get(`${groupChatApi?.getGroupCandidate}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getChatListAPI = async (payload) => {
  return axiosInstance
    .get(`${groupChatApi?.getChat}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateGroupAPI = async (payload) => {
  return axiosInstance
    .post(`${groupChatApi?.updateGroup}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const generateQueryParams = (payload) => {
  const params = {};
  params.user_id_to_block_unblock = payload?.user_id_to_block_unblock || '';
  return params;
};

export const updateChatAPI = async (payload) => {
  const params = generateQueryParams(payload);
  return axiosInstance
    .put(`${groupChatApi?.updateChat}`, payload, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
