export const LeadAPI = {
  getLeadList: '/v1/admission/lead',
  deleteLeadInformation: '/api/to/be/updted',
  postLeadInformation: '/api/to/be/updted',
  updateLeadInformation: '/api/to/be/updted',
  getNotesByLeadId: '/v1/admission/note',
  postNotesById: '/v1/admission/note',
  getLeadStatus: '',
  getLeadStage: '',
  updateLeadStatus: '/v1/admission/lead/',
  getLeadPlacementList: '/v1/admission/lead-placed',
  updateLeadStage: '/v1/admission/lead-stage', // to change the stage from generated->counseling->verfication,
  getLeadById: '/v1/admission/lead',
  bulkUploadLeadData: '',
  admission_lead: '/v1/admission/lead',
  getDashboardStats: '/v1/admission/lead-stats',
  fileUpload: '/v1/admission/file',
  uploadFile: 'v1/admission/upload-file',
  leadConversion: '/v1/admission/lead-conversion',
  multipleFileUpload: '/v1/admission/multi_file',
  getRole: '/v1/rbac/role'
};
