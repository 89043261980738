import * as React from 'react';

const UserTickIcon = ({ width = '24', height = '24', fill = '#21005D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
  >
    <path
      d="M2.47119 19.3077V17.0846C2.47119 16.5539 2.60485 16.0901 2.87217 15.6933C3.13947 15.2965 3.49427 14.986 3.93659 14.7616C4.78659 14.3283 5.68402 13.9776 6.62887 13.7097C7.57373 13.4417 8.68783 13.3078 9.97117 13.3078C10.4712 13.3078 10.9362 13.328 11.3664 13.3683C11.7965 13.4087 12.1981 13.4693 12.5711 13.5501L11.2443 14.8769C11.0609 14.8436 10.8625 14.8237 10.6491 14.8173C10.4356 14.8109 10.2096 14.8077 9.97117 14.8077C8.78783 14.8077 7.75097 14.9398 6.86059 15.2039C5.97021 15.468 5.22502 15.7667 4.62502 16.1C4.3981 16.2218 4.23239 16.3641 4.12789 16.5269C4.02341 16.6898 3.97117 16.8757 3.97117 17.0846V17.8077H10.0288L11.5288 19.3077H2.47119ZM15.425 20.0539L12.3212 16.95L13.375 15.8962L15.425 17.9462L20.475 12.8962L21.5288 13.95L15.425 20.0539ZM9.97117 11.6923C9.00868 11.6923 8.18473 11.3496 7.49932 10.6642C6.81392 9.97879 6.47122 9.15484 6.47122 8.19236C6.47122 7.22986 6.81392 6.40591 7.49932 5.72051C8.18473 5.03509 9.00868 4.69238 9.97117 4.69238C10.9337 4.69238 11.7576 5.03509 12.443 5.72051C13.1284 6.40591 13.4711 7.22986 13.4711 8.19236C13.4711 9.15484 13.1284 9.97879 12.443 10.6642C11.7576 11.3496 10.9337 11.6923 9.97117 11.6923ZM9.97117 10.1924C10.5212 10.1924 10.992 9.99653 11.3837 9.60486C11.7753 9.21319 11.9712 8.74236 11.9712 8.19236C11.9712 7.64236 11.7753 7.17153 11.3837 6.77986C10.992 6.38819 10.5212 6.19236 9.97117 6.19236C9.42117 6.19236 8.95033 6.38819 8.55867 6.77986C8.167 7.17153 7.97117 7.64236 7.97117 8.19236C7.97117 8.74236 8.167 9.21319 8.55867 9.60486C8.95033 9.99653 9.42117 10.1924 9.97117 10.1924Z"
      fill={fill}
    />
  </svg>
);

export default UserTickIcon;
