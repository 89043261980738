import { groupChatActions } from './Groupchat.constants';
const { GROUPCHAT } = groupChatActions;

const INITIAL_STATE = {
  loading: false,
  candidates: [],
  error: false,
  isGroupCreatedSuccessfully: false,
  isCandidateAddedSuccessfully: false,
  groupList: [],
  groupCandidates: [],
  chats: [],
  isGroupUpdatedSuccessfully: false,
  isChatUpdatedSuccessfully: false,
};

const groupChatReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GROUPCHAT?.GET_CANDIDATE_LIST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case GROUPCHAT.GET_CANDIDATE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        candidates: action?.payload,
        error: false,
      };
    }
    case GROUPCHAT.GET_CANDIDATE_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        candidates: [],
        error: true,
      };
    }
    case GROUPCHAT.CREATE_GROUP: {
      return {
        ...state,
        loading: true,
        isGroupCreatedSuccessfully: false,
      };
    }
    case GROUPCHAT.CREATE_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        isGroupCreatedSuccessfully: true,
      };
    }
    case GROUPCHAT.CREATE_GROUP_FAIL: {
      return {
        ...state,
        loading: false,
        isGroupCreatedSuccessfully: false,
        error: true,
      };
    }
    case GROUPCHAT.ADD_CANDIDATE_TO_GROUP: {
      return {
        ...state,
        loading: true,
      };
    }
    case GROUPCHAT.ADD_CANDIDATE_TO_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        isCandidateAddedSuccessfully: true,
      };
    }
    case GROUPCHAT.ADD_CANDIDATE_TO_GROUP_FAIL: {
      return {
        ...state,
        loading: false,
        isCandidateAddedSuccessfully: false,
      };
    }
    case GROUPCHAT.ADD_CANDIDATE_TO_GROUP_RESET: {
      return {
        ...state,
        loading: false,
        isCandidateAddedSuccessfully: false,
      };
    }
    case GROUPCHAT.RESET_GROUP_CHAT_REDUCER: {
      return {
        ...state,
        isGroupCreatedSuccessfully: false,
      };
    }
    case GROUPCHAT.GET_GROUPS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GROUPCHAT.GET_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        groupList: action.payload,
      };
    }
    case GROUPCHAT.GET_GROUPS_FAIL: {
      return {
        ...state,
        loading: false,
        groupList: [],
        error: true,
      };
    }
    case GROUPCHAT.GET_GROUP_CANDIDATE_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GROUPCHAT.GET_GROUP_CANDIDATE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        groupCandidates: action.payload,
      };
    }
    case GROUPCHAT.GET_GROUP_CANDIDATE_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
        groupCandidates: [],
      };
    }
    case GROUPCHAT.GET_GROUP_CANDIDATE_LIST_RESET: {
      return {
        ...state,
        loading: false,
        error: false,
        groupCandidates: [],
      };
    }
    case GROUPCHAT.GET_CHAT_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GROUPCHAT.GET_CHAT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        chats: action.payload,
      };
    }
    case GROUPCHAT.GET_CHAT_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case GROUPCHAT.GET_CHAT_LIST_RESET: {
      return {
        ...state,
        loading: false,
        error: false,
        chats: [],
      };
    }
    case GROUPCHAT.UPDATE_GROUP_DATA: {
      return {
        ...state,
        isGroupUpdatedSuccessfully: false,
      };
    }
    case GROUPCHAT.UPDATE_GROUP_DATA_SUCCESS: {
      return {
        ...state,
        isGroupUpdatedSuccessfully: true,
      };
    }
    case GROUPCHAT.UPDATE_GROUP_DATA_FAIL: {
      return {
        ...state,
        isGroupUpdatedSuccessfully: false,
        error: true,
      };
    }
    case GROUPCHAT.UPDATE_GROUP_DATA_RESET: {
      return {
        ...state,
        isGroupUpdatedSuccessfully: false,
        error: false,
      };
    }
    case GROUPCHAT.UPDATE_CHAT_DATA: {
      return {
        ...state,
        isChatUpdatedSuccessfully: false,
      };
    }
    case GROUPCHAT.UPDATE_CHAT_DATA_SUCCESS: {
      return {
        ...state,
        isChatUpdatedSuccessfully: true,
      };
    }
    case GROUPCHAT.UPDATE_CHAT_DATA_FAIL: {
      return {
        ...state,
        isChatUpdatedSuccessfully: false,
        error: true,
      };
    }
    case GROUPCHAT.UPDATE_CHAT_DATA_RESET: {
      return {
        ...state,
        isChatUpdatedSuccessfully: false,
        error: false,
      };
    }
    default:
      return state;
  }
};
export default groupChatReducer;
