import React from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
} from 'iiht-b2a-ui-components/build/components';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useStyles } from './ExpandableTableRow.classes';

const ExpandableTableRow = ({
  children,
  expandableRowContent,
  ...otherProps
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const classes = useStyles();
  return (
    <>
      <TableRow {...otherProps} className={`${classes.tableCollapseRow}`}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {!isExpanded ? (
              <ExpandMore className={`${classes.iconColor} mouse-pointer`} />
            ) : (
              <ExpandLess className={`${classes.iconColor} mouse-pointer`} />
            )}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && <>{expandableRowContent}</>}
    </>
  );
};

export default ExpandableTableRow;
