import { assessmentRepositoryActions } from './AssessmentRepository.constants';

const {
  createQuestionBank,
  createQuestionBankBulkUpload,
  createRubrics,
  listRubrics,
  getRubricsById,
  deleteRubricsById,
  updateRubricsById,
  getQuestionBankList,
  getQuestionBankDetailsById,
  deleteQuestionBank,
  evaluateQuestionBank,
  editQuestion,
  deleteQuestion,
  createAssessment,
  getAssessmentList,
  deleteAssessment,
  getAssessmentDetailsById,
  editSection,
  createSection,
  deleteSection,
  importQuestion,
  sendAssessmentForApproval,
  updateAssessent,
  evaluateAssesment,
} = assessmentRepositoryActions;

export const questionBankLoader = (payload) => ({
  type: assessmentRepositoryActions.QUESTION_BANK_LOADER,
  payload: payload,
});

export const createAssessmentRepository = (payload) => ({
  type: createQuestionBank.create,
  payload: payload,
});

export const createAssessmentRepositorySuccess = (payload) => ({
  type: createQuestionBank.success,
  payload: payload,
});

export const createAssessmentRepositoryError = (payload) => ({
  type: createQuestionBank.error,
  payload: payload,
});

export const createAssessmentRepositoryReset = () => ({
  type: createQuestionBank.reset,
});

export const createAssessmentRepositoryBulkUpload = (payload) => ({
  type: createQuestionBankBulkUpload.create,
  payload: payload,
});

export const createAssessmentRepositoryBulkUploadSuccess = (payload) => ({
  type: createQuestionBankBulkUpload.success,
  payload: payload,
});

export const createAssessmentRepositoryBulkUploadError = (payload) => ({
  type: createQuestionBankBulkUpload.error,
  payload: payload,
});

export const createAssessmentRepositoryBulkUploadReset = () => ({
  type: createQuestionBankBulkUpload.reset,
});

export const rubricsLoader = (payload) => ({
  type: assessmentRepositoryActions.QUESTION_BANK_RUBRICS_LOADER,
  payload: payload,
});

export const createAssessmentRepositoryRubrics = (payload) => ({
  type: createRubrics.create,
  payload: payload,
});

export const createAssessmentRepositoryRubricsSuccess = (payload) => ({
  type: createRubrics.success,
  payload: payload,
});

export const createAssessmentRepositoryRubricsError = (payload) => ({
  type: createRubrics.error,
  payload: payload,
});

export const createAssessmentRepositoryRubricsReset = () => ({
  type: createRubrics.reset,
});

export const listAssessmentRepositoryRubrics = (payload) => ({
  type: listRubrics.get,
  payload: payload,
});

export const listAssessmentRepositoryRubricsSuccess = (payload) => ({
  type: listRubrics.success,
  payload: payload,
});

export const listAssessmentRepositoryRubricsError = (payload) => ({
  type: listRubrics.error,
  payload: payload,
});

export const listAssessmentRepositoryRubricsReset = () => ({
  type: listRubrics.reset,
});

export const getAssessmentRepositoryRubrics = (payload) => ({
  type: getRubricsById.get,
  payload: payload,
});

export const getAssessmentRepositoryRubricsSuccess = (payload) => ({
  type: getRubricsById.success,
  payload: payload,
});

export const getAssessmentRepositoryRubricsError = (payload) => ({
  type: getRubricsById.error,
  payload: payload,
});

export const getAssessmentRepositoryRubricsReset = () => ({
  type: getRubricsById.reset,
});

export const deleteAssessmentRepositoryRubrics = (payload) => ({
  type: deleteRubricsById.delete,
  payload: payload,
});

export const deleteAssessmentRepositoryRubricsSuccess = (payload) => ({
  type: deleteRubricsById.success,
  payload: payload,
});

export const deleteAssessmentRepositoryRubricsError = (payload) => ({
  type: deleteRubricsById.error,
  payload: payload,
});

export const deleteAssessmentRepositoryRubricsReset = () => ({
  type: deleteRubricsById.reset,
});

export const updateAssessmentRepositoryRubrics = (payload) => ({
  type: updateRubricsById.update,
  payload: payload,
});

export const updateAssessmentRepositoryRubricsSuccess = (payload) => ({
  type: updateRubricsById.success,
  payload: payload,
});

export const updateAssessmentRepositoryRubricsError = (payload) => ({
  type: updateRubricsById.error,
  payload: payload,
});

export const updateAssessmentRepositoryRubricsReset = () => ({
  type: updateRubricsById.reset,
});

export const getQuestionBankListAction = (payload) => ({
  type: getQuestionBankList.get,
  payload: payload,
});

export const getQuestionBankListSuccess = (payload) => ({
  type: getQuestionBankList.success,
  payload: payload,
});

export const getQuestionBankListError = (payload) => ({
  type: getQuestionBankList.error,
  payload: payload,
});

export const getQuestionBankListReset = () => ({
  type: getQuestionBankList.reset,
});

export const getQuestionBankDetailsByIdAction = (payload) => ({
  type: getQuestionBankDetailsById.get,
  payload: payload,
});

export const getQuestionBankDetailsByIdSuccess = (payload) => ({
  type: getQuestionBankDetailsById.success,
  payload: payload,
});

export const getQuestionBankDetailsByIdError = (payload) => ({
  type: getQuestionBankDetailsById.error,
  payload: payload,
});
export const getQuestionBankDetailsByIdReset = () => ({
  type: getQuestionBankDetailsById.reset,
});

export const deleteQuestionBankAction = (payload) => ({
  type: deleteQuestionBank.delete,
  payload: payload,
});

export const deleteQuestionBankSuccess = (payload) => ({
  type: deleteQuestionBank.success,
  payload: payload,
});

export const deleteQuestionBankError = (payload) => ({
  type: deleteQuestionBank.error,
  payload: payload,
});

export const deleteQuestionBankReset = () => ({
  type: deleteQuestionBank.reset,
});

export const evaluateQuestionBankAction = (payload) => ({
  type: evaluateQuestionBank.update,
  payload: payload,
});

export const evaluateQuestionBankSuccess = (payload) => ({
  type: evaluateQuestionBank.success,
  payload: payload,
});

export const evaluateQuestionBankError = (payload) => ({
  type: evaluateQuestionBank.error,
  payload: payload,
});

export const evaluateQuestionBankReset = () => ({
  type: evaluateQuestionBank.reset,
});

export const editQuestionAction = (payload) => ({
  type: editQuestion.update,
  payload: payload,
});

export const editQuestionSuccess = (payload) => ({
  type: editQuestion.success,
  payload: payload,
});

export const editQuestionError = (payload) => ({
  type: editQuestion.error,
  payload: payload,
});

export const editQuestionReset = () => ({
  type: editQuestion.reset,
});

export const saveTitleandCourse = (payload) => ({
  type: assessmentRepositoryActions.SAVE_TITLE_COURSE,
  payload: payload,
});

export const deleteQuestionAction = (payload) => ({
  type: deleteQuestion.delete,
  payload: payload,
});

export const deleteQuestionSuccess = (payload) => ({
  type: deleteQuestion.success,
  payload: payload,
});

export const deleteQuestionError = (payload) => ({
  type: deleteQuestion.error,
  payload: payload,
});

export const deleteQuestionReset = () => ({
  type: deleteQuestion.reset,
});

export const createAssessmentDetailsAction = (payload) => ({
  type: createAssessment.create,
  payload: payload,
});

export const createAssessmentDetailsSuccess = (payload) => ({
  type: createAssessment.success,
  payload: payload,
});

export const createAssessmentDetailsError = (payload) => ({
  type: createAssessment.error,
  payload: payload,
});

export const createAssessmentDetailsReset = () => ({
  type: createAssessment.reset,
});

export const getAssessmentListAction = (payload) => ({
  type: getAssessmentList.get,
  payload: payload,
});

export const getAssessmentListSuccess = (payload) => ({
  type: getAssessmentList.success,
  payload: payload,
});

export const getAssessmentListError = (payload) => ({
  type: getAssessmentList.error,
  payload: payload,
});

export const getAssessmentListReset = () => ({
  type: getAssessmentList.reset,
});

export const deleteAssessmentDetailsAction = (payload) => ({
  type: deleteAssessment.delete,
  payload: payload,
});

export const deleteAssessmentDetailsSuccess = (payload) => ({
  type: deleteAssessment.success,
  payload: payload,
});

export const deleteAssessmentDetailsError = (payload) => ({
  type: deleteAssessment.error,
  payload: payload,
});

export const deleteAssessmentDetailsReset = () => ({
  type: deleteAssessment.reset,
});

export const getAssessmentDetailsByIdAction = (payload) => ({
  type: getAssessmentDetailsById.get,
  payload: payload,
});

export const getAssessmentDetailsByIdSuccess = (payload) => ({
  type: getAssessmentDetailsById.success,
  payload: payload,
});

export const getAssessmentDetailsByIdError = (payload) => ({
  type: getAssessmentDetailsById.error,
  payload: payload,
});

export const getAssessmentDetailsByIdReset = () => ({
  type: getAssessmentDetailsById.reset,
});

export const updateSection = (payload) => ({
  type: editSection.update,
  payload: payload,
});

export const updateSectionSuccess = (payload) => ({
  type: editSection.success,
  payload: payload,
});

export const updateSectionError = (payload) => ({
  type: editSection.error,
  payload: payload,
});

export const updateSectionReset = () => ({
  type: editSection.reset,
});

export const createAssessmentSectionAction = (payload) => ({
  type: createSection.create,
  payload: payload,
});

export const createAssessmentSectionSuccess = (payload) => ({
  type: createSection.success,
  payload: payload,
});

export const createAssessmentSectionError = (payload) => ({
  type: createSection.error,
  payload: payload,
});

export const createAssessmentSectionReset = () => ({
  type: createSection.reset,
});

export const deleteSectionAction = (payload) => ({
  type: deleteSection.delete,
  payload: payload,
});

export const deleteSectionSuccess = (payload) => ({
  type: deleteSection.success,
  payload: payload,
});

export const deleteSectionError = (payload) => ({
  type: deleteSection.error,
  payload: payload,
});

export const deleteSectionReset = () => ({
  type: deleteSection.reset,
});

export const importQuestionAction = (payload) => ({
  type: importQuestion.import,
  payload: payload,
});

export const importQuestionSuccess = (payload) => ({
  type: importQuestion.success,
  payload: payload,
});

export const importQuestionError = (payload) => ({
  type: importQuestion.error,
  payload: payload,
});

export const importQuestionReset = () => ({
  type: importQuestion.reset,
});

export const sendForApprovalAction = (payload) => ({
  type: sendAssessmentForApproval.update,
  payload: payload,
});

export const sendForApprovalSuccess = (payload) => ({
  type: sendAssessmentForApproval.success,
  payload: payload,
});

export const sendForApprovalError = (payload) => ({
  type: sendAssessmentForApproval.error,
  payload: payload,
});

export const sendForApprovalReset = () => ({
  type: sendAssessmentForApproval.reset,
});

export const updateAssessmentAction = (payload) => ({
  type: updateAssessent.update,
  payload: payload,
});

export const updateAssessmentSuccess = (payload) => ({
  type: updateAssessent.success,
  payload: payload,
});

export const updateAssessmentError = (payload) => ({
  type: updateAssessent.error,
  payload: payload,
});

export const updateAssessmentReset = () => ({
  type: updateAssessent.reset,
});

export const evaluateAssessmentAction = (payload) => ({
  type: evaluateAssesment.evaluate,
  payload: payload,
});

export const evaluateAssessmentSuccess = (payload) => ({
  type: evaluateAssesment.success,
  payload: payload,
});

export const evaluateAssessmentError = (payload) => ({
  type: evaluateAssesment.error,
  payload: payload,
});

export const evaluateAssessmentReset = () => ({
  type: evaluateAssesment.reset,
});
