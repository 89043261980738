import * as React from 'react';

function DoubleRightArrow({ className, ...props }) {
  return (
    <span className={`iiht-svg ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14.333}
        height={8.19}
        {...props}
      >
        <path d="M.512.864v6.46L6.383 4.1.512.864M7.678.9v6.412L13.309 4.1 7.678.89M7.167 0l7.167 4.1-7.167 4.1V4.249L0 8.19V0l7.167 3.941z" />
      </svg>
    </span>
  );
}

export default DoubleRightArrow;
