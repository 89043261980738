import { statusCode } from 'constants/apis/apiStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  DownloadAllError,
  DownloadAllSuccess,
  GetSessionListByFilterError,
  GetSessionListByFilterSuccess,
  GetStudentAttendanceError,
  GetStudentAttendanceSuccess,
  GetStudentSessionAttendanceError,
  GetStudentSessionAttendanceSuccess,
  MarkAttendanceError,
  MarkAttendanceSuccess,
  PostSessionListByFilterError,
  PostSessionListByFilterSuccess,
  SendStudentsSessionAttendanceError,
  SendStudentsSessionAttendanceSuccess,
  UpdateBulkAttendanceError,
  UpdateBulkAttendanceSuccess,
  UpdateStudentsSessionAttendanceError,
  UpdateStudentsSessionAttendanceSuccess,
  applyForLeaveError,
  applyForLeaveSuccess,
  applyLeaveAttendanceApi,
  applyLeavePutError,
  applyLeavePutSuccess,
  approveStudentAttendanceRequestActionAPI,
  appyForLeaveApi,
  attendanceActions,
  createMarkReadAttendanceApi,
  downloadAllRequestApi,
  downloadAttandanceApi,
  downloadProgramAttendanceApi,
  downloadProgramAttendanceError,
  downloadProgramAttendanceSuccess,
  fetchStudentAttendanceError,
  fetchStudentAttendanceLoading,
  fetchStudentAttendanceRequestApi,
  fetchStudentAttendanceSuccess,
  getAttendanceDetailsDashboardCardApi,
  getAttendanceDetailsDashboardCardError,
  getAttendanceDetailsDashboardCardSuccess,
  getFacultyAttendanceError,
  getFacultyAttendanceSuccess,
  getFacultyProgramListApi,
  getFacultyProgramListError,
  getFacultyProgramListSuccess,
  getFacultyStudentAttendanceApi,
  getFacultyStudentAttendanceError,
  getFacultyStudentAttendanceSuccess,
  getLiveSessionListByFilterApi,
  getLiveSessionListByFilterError,
  getLiveSessionListByFilterSuccess,
  getOrdianceSettingApi,
  getProgramAttendanceApi,
  getProgramAttendanceError,
  getProgramAttendanceSuccess,
  getProgramAttendanceTabApi,
  getRequestByIdApi,
  getSessionListByFilterAttendanceApi,
  getStudentAttendanceApi,
  getStudentParentEmailsApi,
  getStudentParentEmailsError,
  getStudentParentEmailsSuccess,
  getStudentSessionAttendanceByIdApi,
  postSessionListByFilterAttendanceApi,
  postStudentsSessionAttendanceApi,
  sendStudentAttendanceNotificationApi,
  sendStudentAttendanceNotificationError,
  sendStudentAttendanceNotificationLoader,
  sendStudentAttendanceNotificationSuccess,
  setOrdinanceSettingError,
  setOrdinanceSettingSuccess,
  setRequestByIdError,
  setRequestByIdSuccess,
  setUpdateOrdinanceSettingError,
  setUpdateOrdinanceSettingSuccess,
  setUpdatedMyRequestError,
  setUpdatedMyRequestSuccess,
  studetAttendanceApproveError,
  studetAttendanceApproveSuccess,
  updateAttendaceError,
  updateAttendaceSuccess,
  updateAttendanceApi,
  updateBulkAttendanceApi,
  updateLeaveRequestApi,
  updateOrdianceSettingApi,
  updateRemarkApi,
  updateRemarkError,
  updateRemarkSuccess,
  updateStudentsSessionAttendanceApi,
} from 'store/Attendance';
import { setEnableDisableLoaderAction } from 'store/Loader';
import { setToastMessage } from 'store/Toast';

// Apply leave

function* applyLeaveAttendanceSaga({ payload }) {
  try {
    const response = yield call(applyLeaveAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(applyLeavePutSuccess(response?.data));
      yield put(fetchStudentAttendanceLoading(false));
      yield put(
        setToastMessage({
          data: 'Leave applied',
          severity: 'success',
        })
      );
    } else {
      yield put(applyLeavePutError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(applyLeavePutError(error));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* createAttendanceRequestSaga({ payload }) {
  try {
    const response = yield call(createMarkReadAttendanceApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(MarkAttendanceSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Attendance marked successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(MarkAttendanceError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
          duration: 6000,
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(
      setToastMessage({
        data: error?.data?.message,
        severity: 'error',
        duration: 6000,
      })
    );
  }
}

function* getSessionAttendanceSaga({ payload }) {
  try {
    const response = yield call(getStudentSessionAttendanceByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(GetStudentSessionAttendanceSuccess(response?.data));
    } else {
      yield put(GetStudentSessionAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateBulkAttendanceSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(updateBulkAttendanceApi, payload);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(UpdateBulkAttendanceSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Attendance marked successfully.',
          severity: 'success',
        })
      );
    } else {
      yield put(UpdateBulkAttendanceError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(UpdateBulkAttendanceError(error));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* putStudentsSessionAttendanceSaga({ payload }) {
  try {
    const response = yield call(updateStudentsSessionAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(UpdateStudentsSessionAttendanceSuccess(response?.data));
    } else {
      yield put(UpdateStudentsSessionAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* postStudentsSessionAttendanceSaga({ payload }) {
  try {
    const response = yield call(postStudentsSessionAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(SendStudentsSessionAttendanceSuccess(response?.data));
    } else {
      yield put(SendStudentsSessionAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getSessionListFilterSaga({ payload }) {
  try {
    const response = yield call(getSessionListByFilterAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(GetSessionListByFilterSuccess(response?.data));
    } else {
      yield put(GetSessionListByFilterError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* postSessionListFilterSaga({ payload }) {
  try {
    const response = yield call(postSessionListByFilterAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(PostSessionListByFilterSuccess(response?.data));
    } else {
      yield put(PostSessionListByFilterError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStudentSessionAttendanceSaga({ payload }) {
  try {
    const response = yield call(getStudentSessionAttendanceByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(GetStudentSessionAttendanceSuccess(response?.data));
    } else {
      yield put(GetStudentSessionAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStudentAttendanceSaga({ payload }) {
  try {
    const response = yield call(getStudentAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(GetStudentAttendanceSuccess(response?.data));
    } else {
      yield put(GetStudentAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getFacultyAttendanceSaga({ payload }) {
  try {
    let response = {};
    if (payload?.callFrom === 'WITHOUT_QUERY_PARAM') {
      response = yield call(downloadAttandanceApi, payload);
    } else if (payload?.callFrom === 'WITH_QUERY_PARAM') {
      response = yield call(getProgramAttendanceTabApi, payload);
    }
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyAttendanceSuccess(response?.data));
      if (payload?.data['download-attendance']) {
        yield put(
          setToastMessage({
            data: 'Attendance downloaded successfully',
            severity: 'success',
          })
        );
      }
    } else {
      yield put(getFacultyAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getFacultyProgramListSaga({ payload }) {
  try {
    const response = yield call(getFacultyProgramListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyProgramListSuccess(response?.data));
    } else {
      yield put(getFacultyProgramListError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getProgramAttendanceSaga({ payload }) {
  try {
    const response = yield call(getProgramAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getProgramAttendanceSuccess(response?.data));
    } else {
      yield put(getProgramAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* downloadProgramAttendanceSaga({ payload }) {
  try {
    const response = yield call(downloadProgramAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(downloadProgramAttendanceSuccess(response?.data));
    } else {
      yield put(downloadProgramAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getFacultyStudentAttendanceSaga({ payload }) {
  try {
    const response = yield call(getFacultyStudentAttendanceApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getFacultyStudentAttendanceSuccess(response?.data));
      if (payload['download-attendance']) {
        yield put(
          setToastMessage({
            data: 'Attendance downloaded successfully',
            severity: 'success',
          })
        );
      }
    } else {
      yield put(getFacultyStudentAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

// Get Live session

function* getLiveSessionListByFilterSaga({ payload }) {
  try {
    const response = yield call(getLiveSessionListByFilterApi, payload);

    if (response?.status === statusCode.Ok200) {
      yield put(getLiveSessionListByFilterSuccess(response?.data));
    } else {
      yield put(getLiveSessionListByFilterError(response?.data));
      yield put(
        setToastMessage({
          data: 'Something went wrong',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getLiveSessionListByFilterError(error?.data));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* sendStudentAttendanceNotificationSaga({ payload }) {
  try {
    yield put(sendStudentAttendanceNotificationLoader(true));
    const response = yield call(sendStudentAttendanceNotificationApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(sendStudentAttendanceNotificationSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'success',
        })
      );
    } else {
      yield put(sendStudentAttendanceNotificationError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
    yield put(sendStudentAttendanceNotificationLoader(false));
  } catch (error) {
    console.error(error);
    yield put(sendStudentAttendanceNotificationLoader(false));
  }
}

function* getStudentParentEmailsApiSaga({ payload }) {
  try {
    yield put(sendStudentAttendanceNotificationLoader(true));
    const response = yield call(getStudentParentEmailsApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentParentEmailsSuccess(response?.data));
    } else {
      yield put(getStudentParentEmailsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
    yield put(sendStudentAttendanceNotificationLoader(false));
  } catch (error) {
    yield put(getStudentParentEmailsError(error?.data));
    yield put(sendStudentAttendanceNotificationLoader(false));
  }
}

function* getAttendanceDetailsDashboardCardSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getAttendanceDetailsDashboardCardApi, payload);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getAttendanceDetailsDashboardCardSuccess(response?.data));
    } else {
      yield put(getAttendanceDetailsDashboardCardError(response?.data));
    }
  } catch (error) {
    yield put(getAttendanceDetailsDashboardCardError(error?.data));
    yield put(setEnableDisableLoaderAction(false));
  }
}

function* applyForLeaveSaga({ payload }) {
  try {
    yield put(fetchStudentAttendanceLoading(true));
    const response = yield call(appyForLeaveApi, payload);
    if (response?.status === statusCode.Created201) {
      yield put(applyForLeaveSuccess(response?.data));
      yield put(setToastMessage('Operation completed successfully', 'success'));
      yield put(fetchStudentAttendanceLoading(false));
    } else {
      yield put(applyForLeaveError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Request not raised',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* fetchStudentAttendanceRequestSaga({ payload }) {
  try {
    yield put(fetchStudentAttendanceLoading(true));
    const response = yield call(fetchStudentAttendanceRequestApi, payload);
    yield put(fetchStudentAttendanceLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(fetchStudentAttendanceSuccess(response?.data));
    } else {
      yield put(fetchStudentAttendanceError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getRequestByIdSaga({ payload }) {
  try {
    const response = yield call(getRequestByIdApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setRequestByIdSuccess(response?.data));
    } else {
      yield put(setRequestByIdError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

// Approve request
function* approveStudentAttendanceRequestActionSaga({ payload }) {
  try {
    const response = yield call(
      approveStudentAttendanceRequestActionAPI,
      payload
    );
    if (response?.status === statusCode.Ok200) {
      yield put(studetAttendanceApproveSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'success',
        })
      );
    } else {
      yield put(studetAttendanceApproveError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

//Update Remark

function* updateRemarkSaga({ payload }) {
  try {
    const response = yield call(updateRemarkApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(updateRemarkSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Update Remark',
          severity: 'success',
        })
      );
    } else {
      yield put(updateRemarkError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateRemarkError(error));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

// Update MY request
function* updateMyRequestSaga({ payload }) {
  try {
    yield put(fetchStudentAttendanceLoading(true));
    const response = yield call(updateLeaveRequestApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setUpdatedMyRequestSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'success',
        })
      );
      yield put(fetchStudentAttendanceLoading(false));
    } else {
      yield put(setUpdatedMyRequestError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

// Ordinance-setting
function* getOrdinanceSettingRequestSaga({ payload }) {
  try {
    const response = yield call(getOrdianceSettingApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setOrdinanceSettingSuccess(response?.data));
    } else {
      yield put(setOrdinanceSettingError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateOrdinanceSettingRequestSaga({ payload }) {
  try {
    const response = yield call(updateOrdianceSettingApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setUpdateOrdinanceSettingSuccess(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'success',
        })
      );
    } else {
      yield put(setUpdateOrdinanceSettingError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

// update attendance saga

function* updateAttendanceSaga({ payload = '', params = '' }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(updateAttendanceApi, payload, params);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateAttendaceSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Attendance marked successfully',
          severity: 'success',
        })
      );
    } else {
      yield put(updateAttendaceError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message || 'Something went wrong',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateAttendaceError(error));
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

// Download all attendance saga
function* downloadAllRequestSaga({ payload }) {
  try {
    const response = yield call(downloadAllRequestApi, payload);
    if (
      response?.status === statusCode.Ok200 ||
      response?.status === statusCode.NoContent204
    ) {
      yield put(DownloadAllSuccess(response?.data));
      yield put(
        setToastMessage({
          data: '',
          severity: 'success',
        })
      );
    } else {
      yield put(DownloadAllError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message ?? '',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchDummy() {
  yield takeLatest(
    attendanceActions.ATTENDANCE.create_attendance_request,
    createAttendanceRequestSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.get_session_attendance_request,
    getSessionAttendanceSaga
  );
  // -----------------
  yield takeLatest(
    attendanceActions.ATTENDANCE.update_bulk_attendance_request,
    updateBulkAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.put_students_session_attendance_request,
    putStudentsSessionAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.post_students_session_attendance_request,
    postStudentsSessionAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.get_session_list_filter_request,
    getSessionListFilterSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.post_session_list_filter_request,
    postSessionListFilterSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.get_student_session_attendance_request,
    getStudentSessionAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.get_student_attendance_request,
    getStudentAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.download_faculty_attendance_request,
    getFacultyAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.get_faculty_program_list_request,
    getFacultyProgramListSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.get_program_attendance_request,
    getProgramAttendanceSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.download_program_attendance_request,
    downloadProgramAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.get_faculty_student_attendance_request,
    getFacultyStudentAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_REQUEST,
    getLiveSessionListByFilterSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.APPLY_LEAVE_PUT_REQUEST,
    applyLeaveAttendanceSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.send_student_attendance_notification,
    sendStudentAttendanceNotificationSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.get_student_parent_emails,
    getStudentParentEmailsApiSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_REQUEST,
    getAttendanceDetailsDashboardCardSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.APPLY_FOR_LEAVE,
    applyForLeaveSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.FETCH_STUDENT_ATTENDANCE_REQUEST,
    fetchStudentAttendanceRequestSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.GET_REQUEST_BY_ID,
    getRequestByIdSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.FACULTY_STUDENT_APPROVE_REQUEST,
    approveStudentAttendanceRequestActionSaga
  );

  yield takeLatest(
    attendanceActions.ATTENDANCE.UPDATE_REMARK_REQUEST,
    updateRemarkSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.UPDATE_MY_REQUEST,
    updateMyRequestSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.GET_ORDINANCE_SETTING_REQUEST,
    getOrdinanceSettingRequestSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.UPDATE_ORDINANCE_SETTING_REQUEST,
    updateOrdinanceSettingRequestSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.UPDATE_ATTENDACE_REQUEST,
    updateAttendanceSaga
  );
  yield takeLatest(
    attendanceActions.ATTENDANCE.DOWNLOAD_ALL_REQUEST,
    downloadAllRequestSaga
  );
}

export default function* attendanceSaga() {
  yield all([watchDummy()]);
}
