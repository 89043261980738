import axiosInstance from 'translator';
import { prospectusApi } from 'store/Prospectus';

export const postNewApplicationFormApi = async (payload) => {
  return axiosInstance
    .post(`${prospectusApi?.prospectusApplicationForm}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const patchApplicationFormApi = async (payload) => {
  return axiosInstance
    .patch(
      `${prospectusApi?.prospectusApplicationForm}/${payload?.id}`,
      payload
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProspectusFormsListApi = async (payload) => {
  return axiosInstance
    .get(`${prospectusApi?.prospectusApplicationForm}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProspectusFormApi = async (payload) => {
  return axiosInstance
    .get(`${prospectusApi?.prospectusApplicationForm}/${payload?.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
