import { LeadActions } from 'store/Lead';
const { LEAD } = LeadActions;

const INITIAL_STATE = {
  leadList: { loading: false, totalCount: 0, data: [] },
  lead: {
    loading: false,
    deleteLeadSuccess: false,
    updateLeadSuccess: false,
    saveLeadSuccessFeedback: {},
    saveLeadSuccess: false,
    leadSaveError: {},
    leadStatus: '',
    leadStage: '',
    updateLeadStatus: false,
    updateLeadStage: false,
    activeLead: {},
    bulkLeadUploadedSuccess: false,
    fileUploadMetaData: {},
    bulkUploadData: '',
    multipleFileUploadMetaData: {},
    getRole: {}
  },
  dashboardStats: {
    total_leads: '',
    closed_leads: '',
    active_leads: '',
    missed_leads: '',
    lead_conversion_percent: '',
  },
  leadPlacementList: { loading: false, totalCount: 0, data: [] },
  notes: {
    createNotesSuccess: false,
  },
  notesList: {
    loading: false,
    totalCount: 0,
    data: null,
    next: '',
    previous: '',
  },
  loading: false,
};

const leadReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case LEAD.GET_LEAD_LIST_SUCCESS: {
      return {
        ...state,
        leadList: {
          ...state.leadList,
          data: action?.payload?.results,
          totalCount: action?.payload?.count,
        },
      };
    }

    case LEAD.GET_ROLE_SUCCESS :{
      return {
        ...state,
        lead: {
          ...state.lead,
          getRole: action.payload,
        },
      };
    }

    case LEAD.DELETE_LEAD_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          deleteLeadSuccess: action.payload,
        },
      };
    }

    case LEAD.POST_LEAD_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          saveLeadSuccess: action?.payload,
          leadSaveError: {},
        },
      };
    }

    case LEAD.LOADING: {
      return {
        ...state,
        lead: {
          ...state.lead,
          loading: action?.payload,
        },
      };
    }

    // case LEAD.POST_LEAD_SUCCESS_SAVE_FEEDBACK: {
    //   return {
    //     ...state,
    //     lead: {
    //       ...state.lead,
    //       saveLeadSuccessFeedback: action?.payload,
    //       leadSaveError: {},
    //     },
    //   };
    // }

    case LEAD.POST_LEAD_ERROR: {
      return {
        ...state,
        lead: {
          ...state.lead,
          leadSaveError: action.payload,
        },
      };
    }

    case LEAD.UPDATE_LEAD_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          updateLeadSuccess: action.payload,
        },
      };
    }

    case LEAD.POST_NOTES_SUCCESS: {
      return {
        ...state,
        notes: {
          ...state.notes,
          createNotesSuccess: action.payload,
        },
      };
    }

    case LEAD.GET_NOTES_SUCCESS: {
      const notesListData =
        action?.payload?.results.length > 0 ? action?.payload?.results : [];

      return {
        ...state,
        notesList: {
          ...state.notesList,
          data: [...notesListData],
          totalCount: action?.payload?.count ?? 0,
          next: action?.payload?.next ?? '',
          previous: action?.payload?.previous ?? '',
        },
      };
    }

    case LEAD.GET_MORE_NOTES_SUCCESS: {
      const nextNotesListData =
        action?.payload?.results.length > 0 ? action?.payload?.results : [];
      const oldNotesList = state.notesList.data ?? [];

      return {
        ...state,
        notesList: {
          ...state.notesList,
          data: [...oldNotesList, ...nextNotesListData],
          totalCount: action?.payload?.count ?? 0,
          next: action?.payload?.next ?? '',
          previous: action?.payload?.previous ?? '',
        },
      };
    }

    case LEAD.GET_NOTES_RESET: {
      return {
        ...state,
        notesList: {
          loading: false,
          totalCount: 0,
          data: [],
          next: '',
          previous: '',
        },
      };
    }

    case LEAD.GET_LEAD_STAGE_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          leadStage: action.payload,
        },
      };
    }

    case LEAD.GET_LEAD_STATUS_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          leadStatus: action.payload,
        },
      };
    }

    case LEAD.PATCH_UPDATE_LEAD_STATUS_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          updateLeadStatus: action.payload,
        },
      };
    }

    case LEAD.RESET_UPDATE_LEAD_STATUS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          updateLeadStatus: INITIAL_STATE.lead.updateLeadStatus,
        },
      };
    }

    case LEAD.GET_DASHBOARD_STATS_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          dashboardStats: action.payload || {},
        },
      };
    }

    case LEAD.GET_DASHBOARD_LEAD_CONVERSION_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          dashboardStats: {
            ...state.lead.dashboardStats,
            lead_conversion_percent: action?.payload,
          },
        },
      };
    }

    case LEAD.LEAD_FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          fileUploadMetaData: action.payload,
        },
      };
    }

    case LEAD.RESET_LEAD_FILE_UPLOAD: {
      return {
        ...state,
        lead: {
          ...state.lead,
          fileUploadMetaData: {},
        },
      };
    }

    case LEAD.LEAD_BULK_UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          bulkUploadData: action.payload,
        },
      };
    }

    case LEAD.GET_LEAD_PLACEMENT_SUCCESS: {
      return {
        ...state,
        leadPlacementList: {
          data: action?.payload?.data,
          totalCount: action?.payload?.count,
        },
      };
    }

    case LEAD.REST_LEAD_PLACEMENT: {
      return {
        ...state,
        leadPlacementList: {
          ...INITIAL_STATE.leadPlacementList,
        },
      };
    }

    case LEAD.UPDATE_LEAD_STAGE_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          updateLeadStage: action.payload,
        },
      };
    }

    case LEAD.GET_LEAD_BY_ID_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          activeLead: action.payload,
          getLeadSuccess: { ...action.payload } ?? {},
        },
      };
    }

    case LEAD.RESET_GET_LEAD_BY_ID_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          getLeadSuccess: {},
          activeLead: {},
        },
      };
    }

    case LEAD.POST_BULK_LEAD_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          bulkLeadUploadedSuccess: action.payload,
        },
      };
    }

    case LEAD.LEAD_UPLOAD_MULTI_FILE_SUCCESS: {
      return {
        ...state,
        lead: {
          ...state.lead,
          multipleFileUploadMetaData: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default leadReducer;
