import axiosInstance from 'translator';
import { mappingEndpoint, obeApiEndpoints } from './Obe.apiEndpoints';

export const createDefineOutcomesApi = async (payload, outcome_type) => {
  return axiosInstance
    .post(`${obeApiEndpoints[outcome_type]}`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const getDefineOutcomesApi = async (payload) => {
  return axiosInstance
    .get(`${obeApiEndpoints[payload?.outcome_type]}`)
    .then((response) => response)
    .catch((error) => error);
};

export const getMissionvspeoApi = async (params) => {
  return axiosInstance
    .get(`${mappingEndpoint?.missionvspeoMapping}`, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createMappingApi = async (payload) => {
  return axiosInstance
    .post(
      `${mappingEndpoint?.missionvspeoMapping}?department=${payload?.department}`,
      payload?.req
    )
    .then((response) => response)
    .catch((error) => error);
};

export const getcovspoApi = async (params) => {
  return axiosInstance
    .get(`${mappingEndpoint?.copoMapping}`, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createCOPOMappingApi = async (payload) => {
  return axiosInstance
    .post(
      `${mappingEndpoint?.copoMapping}?department_id=${payload?.department_id}&program_id=${payload?.program_id}&subject_id=${payload?.subject_id}`,
      payload?.req
    )
    .then((response) => response)
    .catch((error) => error);
};
