import { settingsActions } from './Settings.constants';

const INITIAL_STATE = {
  settings: {
    loading: false,
    data: '',
    list: [],
    privacyData: '',
    privacyList: [],
    allList: [],
  },
};
const settingsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case settingsActions.PRIVACY.loading: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: action?.payload,
        },
      };
    }

    case settingsActions.PRIVACY.create_success: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload?.items,
        },
      };
    }
    case settingsActions.PRIVACY.get_list_success: {
      return {
        ...state,
        settings: {
          ...state.settings,
          list: action?.payload?.items,
        },
      };
    }
    case settingsActions.PRIVACY.get_success: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload?.items,
        },
      };
    }
    case settingsActions.PRIVACY.update_success: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload,
        },
      };
    }
    case settingsActions.PRIVACY.reset_update: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: '',
        },
      };
    }
    case settingsActions.PRIVACY.delete_success: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload?.items,
        },
      };
    }

    case settingsActions.PRIVACY.create_error: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload,
        },
      };
    }
    case settingsActions.PRIVACY.get_list_error: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload,
        },
      };
    }
    case settingsActions.PRIVACY.get_error: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload,
        },
      };
    }
    case settingsActions.PRIVACY.update_error: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload,
        },
      };
    }
    case settingsActions.PRIVACY.delete_error: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action?.payload,
        },
      };
    }
    case settingsActions.PRIVACY_NOTIFICATION
      .get_all_privacy_notification_list_success: {
      return {
        ...state,
        settings: {
          ...state.settings,
          allList: action?.payload,
        },
      };
    }

    case settingsActions.PRIVACY_NOTIFICATION
      .get_all_privacy_notification_list_error: {
      return {
        ...state,
        settings: {
          ...state.settings,
          allList: action?.payload,
        },
      };
    }

    case settingsActions.PRIVACY_NOTIFICATION
      .get_privacy_notification_list_success: {
      return {
        ...state,
        settings: {
          ...state.settings,
          privacyList: action?.payload?.items,
        },
      };
    }

    case settingsActions.PRIVACY_NOTIFICATION
      .get_privacy_notification_list_error: {
      return {
        ...state,
        settings: {
          ...state.settings,
          privacyList: action?.payload,
        },
      };
    }

    case settingsActions.PRIVACY_NOTIFICATION
      .update_privacy_notification_success: {
      return {
        ...state,
        settings: {
          ...state.settings,
          privacyData: action?.payload,
        },
      };
    }
    case settingsActions.PRIVACY_NOTIFICATION
      .reset_privacy_notification_update: {
      return {
        ...state,
        settings: {
          ...state.settings,
          privacyData: '',
        },
      };
    }
    case settingsActions.PRIVACY_NOTIFICATION
      .update_privacy_notification_error: {
      return {
        ...state,
        settings: {
          ...state.settings,
          privacyData: action?.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default settingsReducer;
