import { Grid } from 'iiht-b2a-ui-components/build/components';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormSelectField, FormAutoComplete } from 'components';
import { AddIconCircle, DeleteSvg } from 'icons';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'translator';
import { courseManagementAPI } from 'store/CourseManagement';
import { validationRules } from 'constants/validationRules';
const CourseDetailsForm = React.memo(
  ({
    name,
    length,
    addCoursesInterested,
    deleteCoursesInterested,
    getClusterListFunc,
    selectedCluster,
    selectedDepartment,
    resetClusterSearch,
    tenantId,
    index,
  }) => {
    const { t } = useTranslation();
    const [department_list, setDepartmentList] = useState([]);
    const [course_list, setCourseList] = useState([]);
    const [depCount, setDepCount] = useState(0);
    const [courseCount, setCourseCount] = useState(0);

    const {
      data: clusters,
      loading: cluster_loading,
      count: clusterCount,
    } = useSelector((state) => state.courseManagementState.clusters);
    const getDepartmentsByClusterIdApi = useCallback(
      async (SearchString = '', SkipCount = 0, MaxResultCountLocal = 5) => {
        return axiosInstance
          .get(`${courseManagementAPI?.department}`, {
            params: {
              SearchString: SearchString,
              cluster: selectedCluster?.id,
              offset: SkipCount,
              limit: MaxResultCountLocal,
              // tenantId: tenantId,
            },
          })
          .then((response) => {
            setDepCount(response?.data?.count);
            setDepartmentList((prevState) => [
              ...prevState,
              ...response?.data?.results?.map((item) => ({
                ...item,
                key: item?.id,
                label: item?.department_name,
                value: item?.id,
              })),
            ]);
            return response;
          })
          .catch((error) => {
            return error;
          });
      },
      [selectedCluster]
    );

    const getCourseListByDeptIdApi = useCallback(
      async (SearchString = '', SkipCount = 0, MaxResultCountLocal = 5) => {
        return axiosInstance
          .get(`${courseManagementAPI?.program}`, {
            params: {
              SearchString: SearchString,
              department: selectedDepartment?.id,
              offset: SkipCount,
              limit: MaxResultCountLocal,
              // tenantId: tenantId,
            },
          })
          .then((response) => {
            setCourseCount(response?.data?.count);
            setCourseList((prevState) => [
              ...prevState,
              ...response?.data?.results?.map((item) => ({
                ...item,
                key: item?.id,
                label: item?.program_name,
                value: item?.id,
              })),
            ]);
          })
          .catch((error) => {
            return error;
          });
      },
      [selectedDepartment]
    );

    const resetCoursesSearch = useCallback(
      (searchQuery) => {
        setCourseList([]);
        getCourseListByDeptIdApi(searchQuery);
      },
      [getCourseListByDeptIdApi]
    );
    const resetDepartmentsSearch = useCallback(
      (searchQuery) => {
        setDepartmentList([]);
        getDepartmentsByClusterIdApi(searchQuery);
      },
      [getDepartmentsByClusterIdApi]
    );

    useEffect(() => {
      setDepartmentList([]);
      setCourseList([]);
      if (selectedCluster?.id) {
        getDepartmentsByClusterIdApi();
      }
    }, [selectedCluster]);

    useEffect(() => {
      setCourseList([]);
      if (selectedDepartment?.id) {
        getCourseListByDeptIdApi();
      }
    }, [selectedDepartment]);

    return (
      <Grid
        container
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="start"
        alignItems="start"
      >
        <Grid item xl={3} md={3}>
          <FormSelectField
            name={`${name}[${index}].academic_year`}
            rules={{
              required: validationRules.REQUIRED,
            }}
            variant="outlined"
            label={t('studentManagement.academicYear')}
            size="small"
            fullWidth
            options={[{ id: 1, key: 1, value: '2022-23', label: '2022-23' }]}
            isAsterisk
          />
        </Grid>

        <Grid item xl={3} md={3}>
          <FormAutoComplete
            name={`${name}[${index}].cluster_new`}
            variant="outlined"
            label={t('evaluation.selectCluster')}
            size="small"
            fullWidth
            rules={{
              required: validationRules.REQUIRED,
            }}
            handleFetchNext={getClusterListFunc}
            options={clusters?.map((item) => ({
              ...item,
              key: item?.id,
              label: item?.cluster_name,
              value: item?.id,
            }))}
            resetSearch={resetClusterSearch}
            totalCount={clusterCount}
            isAsterisk
            filterOptions={(x) => x}
          />
        </Grid>
        <Grid item xl={3} md={3}>
          <FormAutoComplete
            name={`${name}[${index}].department_new`}
            rules={{
              required: validationRules.REQUIRED,
            }}
            variant="outlined"
            label={t('evaluation.selectDepartment')}
            size="small"
            options={department_list}
            handleFetchNext={getDepartmentsByClusterIdApi}
            resetSearch={resetDepartmentsSearch}
            totalCount={depCount}
            fullWidth
            isAsterisk
            filterOptions={(x) => x}
          />
        </Grid>

        <Grid item xl={2} md={2}>
          <FormAutoComplete
            name={`${name}[${index}].course`}
            rules={{
              required: validationRules.REQUIRED,
            }}
            variant="outlined"
            label={t('evaluation.selectCourse')}
            size="small"
            options={course_list}
            handleFetchNext={getCourseListByDeptIdApi}
            resetSearch={resetCoursesSearch}
            fullWidth
            isAsterisk
            totalCount={courseCount}
            filterOptions={(x) => x}
          />
        </Grid>

        {length - 1 === index && (
          <Grid item auto>
            <div className="mt5 qa-add-circle" onClick={addCoursesInterested}>
              <AddIconCircle />
            </div>
          </Grid>
        )}
        {length !== 1 && (
          <Grid item auto>
            <div
              className="mt5 qa-remove-svg"
              onClick={() => deleteCoursesInterested(index)}
            >
              <DeleteSvg />
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
);
export default CourseDetailsForm;
