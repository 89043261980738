import { attendanceActions } from './Attendance.constants';
const { ATTENDANCE } = attendanceActions;
// MARK ATTENDANCE

export const MarkAttendanceRequest = (payload) => ({
  type: ATTENDANCE.create_attendance_request,
  payload: payload,
});

export const MarkAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.create_attendance_success,
  payload: payload,
});

export const MarkAttendanceError = (payload) => ({
  type: ATTENDANCE.create_attendance_error,
  payload: payload,
});

// Get Student Attendance :

export const GetStudentAttendanceRequest = (payload) => ({
  type: ATTENDANCE.get_student_attendance_request,
  payload: payload,
});

export const GetStudentAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.get_student_attendance_success,
  payload: payload,
});

export const GetStudentAttendanceError = (payload) => ({
  type: ATTENDANCE.get_student_attendance_error,
  payload: payload,
});

// Get stud data based on session id

export const GetStudentSessionAttendanceRequest = (payload) => ({
  type: ATTENDANCE.get_student_session_attendance_request,
  payload: payload,
});

export const GetStudentSessionAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.get_student_session_attendance_success,
  payload: payload,
});

export const GetStudentSessionAttendanceError = (payload) => ({
  type: ATTENDANCE.get_student_session_attendance_error,
  payload: payload,
});

// Get session list based on filter

export const GetSessionListByFilterRequest = (payload) => ({
  type: ATTENDANCE.get_session_list_filter_request,
  payload: payload,
});

export const GetSessionListByFilterSuccess = (payload) => ({
  type: ATTENDANCE.get_session_list_filter_success,
  payload: payload,
});

export const GetSessionListByFilterError = (payload) => ({
  type: ATTENDANCE.get_session_list_filter_error,
  payload: payload,
});
// Get session list based on filter

export const PostSessionListByFilterRequest = (payload) => ({
  type: ATTENDANCE.post_session_list_filter_request,
  payload: payload,
});

export const PostSessionListByFilterSuccess = (payload) => ({
  type: ATTENDANCE.post_session_list_filter_success,
  payload: payload,
});

export const PostSessionListByFilterError = (payload) => ({
  type: ATTENDANCE.post_session_list_filter_error,
  payload: payload,
});

export const PostSessionListByFilterReset = () => ({
  type: ATTENDANCE.post_session_list_filter_error,
});

// Session Attendance API: Used while initial entry for student while create session

export const SendStudentsSessionAttendanceRequest = (payload) => ({
  type: ATTENDANCE.post_students_session_attendance_request,
  payload: payload,
});

export const SendStudentsSessionAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.post_students_session_attendance_success,
  payload: payload,
});

export const SendStudentsSessionAttendanceError = (payload) => ({
  type: ATTENDANCE.post_students_session_attendance_error,
  payload: payload,
});

// Session Attendance API: Used while initial entry for student while edit session

export const UpdateStudentsSessionAttendanceRequest = (payload) => ({
  type: ATTENDANCE.put_students_session_attendance_request,
  payload: payload,
});

export const UpdateStudentsSessionAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.put_students_session_attendance_success,
  payload: payload,
});

export const UpdateStudentsSessionAttendanceError = (payload) => ({
  type: ATTENDANCE.put_students_session_attendance_error,
  payload: payload,
});

// Update multiple student attendance -  Bulk Attendance API:

export const UpdateBulkAttendanceRequest = (payload) => ({
  type: ATTENDANCE.update_bulk_attendance_request,
  payload: payload,
});

export const UpdateBulkAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.update_bulk_attendance_success,
  payload: payload,
});

export const UpdateBulkAttendanceError = (payload) => ({
  type: ATTENDANCE.update_bulk_attendance_error,
  payload: payload,
});

export const UpdateBulkAttendanceReset = () => ({
  type: ATTENDANCE.update_bulk_attendance_reset,
});

export const getFacultyAttendanceRequest = (payload) => ({
  type: ATTENDANCE.download_faculty_attendance_request,
  payload: payload,
});

export const getFacultyAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.download_faculty_attendance_success,
  payload: payload,
});

export const getFacultyAttendanceError = (payload) => ({
  type: ATTENDANCE.download_faculty_attendance_error,
  payload: payload,
});

export const getFacultyAttendanceReset = () => ({
  type: ATTENDANCE.download_faculty_attendance_reset,
});

export const getFacultyProgramListRequest = (payload) => ({
  type: ATTENDANCE.get_faculty_program_list_request,
  payload: payload,
});

export const getFacultyProgramListSuccess = (payload) => ({
  type: ATTENDANCE.get_faculty_program_list_success,
  payload: payload,
});

export const getFacultyProgramListError = (payload) => ({
  type: ATTENDANCE.get_faculty_program_list_error,
  payload: payload,
});

export const getProgramAttendanceRequest = (payload) => ({
  type: ATTENDANCE.get_program_attendance_request,
  payload: payload,
});

export const getProgramAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.get_program_attendance_success,
  payload: payload,
});

export const getProgramAttendanceError = (payload) => ({
  type: ATTENDANCE.get_program_attendance_error,
  payload: payload,
});

export const downloadProgramAttendanceRequest = (payload) => ({
  type: ATTENDANCE.download_program_attendance_request,
  payload: payload,
});

export const downloadProgramAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.download_program_attendance_success,
  payload: payload,
});

export const downloadProgramAttendanceError = (payload) => ({
  type: ATTENDANCE.download_program_attendance_error,
  payload: payload,
});

// Get Student attendance for Faculty

export const getFacultyStudentAttendanceRequest = (payload) => ({
  type: ATTENDANCE.get_faculty_student_attendance_request,
  payload: payload,
});

export const getFacultyStudentAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.get_faculty_student_attendance_success,
  payload: payload,
});

export const getFacultyStudentAttendanceError = (payload) => ({
  type: ATTENDANCE.get_faculty_student_attendance_error,
  payload: payload,
});

export const getFacultyStudentAttendanceReset = () => ({
  type: ATTENDANCE.get_faculty_student_attendance_reset,
});

// Get Live session based on start date

export const getLiveSessionListByFilterRequest = (payload) => ({
  type: ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_REQUEST,
  payload: payload,
});

export const getLiveSessionListByFilterSuccess = (payload) => ({
  type: ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_SUCCESS,
  payload: payload,
});

export const getLiveSessionListByFilterError = (payload) => ({
  type: ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_ERROR,
  payload: payload,
});

export const getLiveSessionListByFilterReset = () => ({
  type: ATTENDANCE.GET_LIVE_SESSION_BY_FILTER_RESET,
});

// Apply leave put

export const applyLeavePutRequest = (payload) => ({
  type: ATTENDANCE.APPLY_LEAVE_PUT_REQUEST,
  payload: payload,
});

export const applyLeavePutSuccess = (payload) => ({
  type: ATTENDANCE.APPLY_LEAVE_PUT_SUCCESS,
  payload: payload,
});

export const applyLeavePutError = (payload) => ({
  type: ATTENDANCE.APPLY_LEAVE_PUT_ERROR,
  payload: payload,
});

export const applyLeavePutReset = () => ({
  type: ATTENDANCE.APPLY_LEAVE_PUT_RESET,
});

export const sendStudentAttendanceNotification = (payload) => ({
  type: ATTENDANCE.send_student_attendance_notification,
  payload: payload,
});

export const sendStudentAttendanceNotificationSuccess = (payload) => ({
  type: ATTENDANCE.send_student_attendance_notification_success,
  payload: payload,
});

export const sendStudentAttendanceNotificationError = (payload) => ({
  type: ATTENDANCE.send_student_attendance_notification_error,
  payload: payload,
});

export const sendStudentAttendanceNotificationLoader = (payload) => ({
  type: ATTENDANCE.send_student_attendance_notification_loader,
  payload: payload,
});

export const sendStudentAttendanceNotificationReset = () => ({
  type: ATTENDANCE.send_student_attendance_notification_reset,
});

export const getStudentParentEmails = (payload) => ({
  type: ATTENDANCE.get_student_parent_emails,
  payload: payload,
});

export const getStudentParentEmailsSuccess = (payload) => ({
  type: ATTENDANCE.get_student_parent_emails_success,
  payload: payload,
});

export const getStudentParentEmailsError = (payload) => ({
  type: ATTENDANCE.get_student_parent_emails_error,
  payload: payload,
});

// get faculty student present count for dashboard

export const getAttendanceDetailsDashboardCardRequest = (payload) => ({
  type: ATTENDANCE.GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_REQUEST,
  payload: payload,
});

export const getAttendanceDetailsDashboardCardSuccess = (payload) => ({
  type: ATTENDANCE.GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_SUCCESS,
  payload: payload,
});

export const getAttendanceDetailsDashboardCardError = (payload) => ({
  type: ATTENDANCE.GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_ERROR,
  payload: payload,
});

export const getAttendanceDetailsDashboardCardReset = () => ({
  type: ATTENDANCE.GET_ATTENDANCE_DETAILS_DASHBOARD_CARD_RESET,
});

export const applyForLeave = (payload) => ({
  type: ATTENDANCE.APPLY_FOR_LEAVE,
  payload: payload,
});

export const applyForLeaveSuccess = (payload) => ({
  type: ATTENDANCE.APPLY_FOR_LEAVE_SUCCESS,
  payload: payload,
});

export const applyForLeaveError = (payload) => ({
  type: ATTENDANCE.APPLY_FOR_LEAVE_ERROR,
  payload: payload,
});

//get my leave request in student
export const fetchStudentAttendanceRequest = (payload) => ({
  type: ATTENDANCE.FETCH_STUDENT_ATTENDANCE_REQUEST,
  payload: payload,
});

export const fetchStudentAttendanceSuccess = (payload) => ({
  type: ATTENDANCE.SET_FETCH_STUDENT_ATTENDANCE_SUCCESS,
  payload: payload,
});

export const fetchStudentAttendanceError = (payload) => ({
  type: ATTENDANCE.SET_FETCH_STUDENT_ATTENDANCE_ERROR,
  payload: payload,
});

export const fetchStudentAttendanceLoading = (payload) => ({
  type: ATTENDANCE.SET_FETCH_STUDENT_ATTENDANCE_LOADING,
  payload: payload,
});

export const fetchStudentAttendanceReset = () => ({
  type: ATTENDANCE.SET_FETCH_STUDENT_ATTENDANCE_RESET,
});

export const getRequestById = (payload) => ({
  type: ATTENDANCE.GET_REQUEST_BY_ID,
  payload: payload,
});
export const setRequestByIdSuccess = (payload) => ({
  type: ATTENDANCE.SET_GET_REQUEST_BY_ID_SUCCESS,
  payload: payload,
});
export const setRequestByIdError = (payload) => ({
  type: ATTENDANCE.SET_GET_REQUEST_BY_ID_ERROR,
  payload: payload,
});
export const resetRequestById = () => ({
  type: ATTENDANCE.RESET_REQUEST_BY_ID,
});

// Approve Request
export const studetAttendanceApproveRequest = (payload) => ({
  type: ATTENDANCE.FACULTY_STUDENT_APPROVE_REQUEST,
  payload: payload,
});

export const studetAttendanceApproveSuccess = (payload) => ({
  type: ATTENDANCE.FACULTY_STUDENT_APPROVE_SUCCESS,
  payload: payload,
});

export const studetAttendanceApproveError = (payload) => ({
  type: ATTENDANCE.FACULTY_STUDENT_APPROVE_ERROR,
  payload: payload,
});

export const studetAttendanceApproveReset = () => ({
  type: ATTENDANCE.FACULTY_STUDENT_APPROVE_RESET,
});

//update API
export const updateRemarkRequest = (payload) => ({
  type: ATTENDANCE.UPDATE_REMARK_REQUEST,
  payload: payload,
});

export const updateRemarkSuccess = (payload) => ({
  type: ATTENDANCE.UPDATE_REMARK_SUCCESS,
  payload: payload,
});

export const updateRemarkError = (payload) => ({
  type: ATTENDANCE.UPDATE_REMARKE_ERROR,
  payload: payload,
});

export const updateRemarkReset = () => ({
  type: ATTENDANCE.UPDATE_REMARK_RESET,
});

//Update my request
export const updateMyRequest = (payload) => ({
  type: ATTENDANCE.UPDATE_MY_REQUEST,
  payload: payload,
});

export const setUpdatedMyRequestSuccess = (payload) => ({
  type: ATTENDANCE.SET_UPDATE_MY_REQUEST_SUCCESS,
  payload: payload,
});

export const setUpdatedMyRequestError = (payload) => ({
  type: ATTENDANCE.SET_UPDATE_MY_REQUEST_ERROR,
  payload: payload,
});

//ordinance-setting
export const getOrdinanceSettingRequest = (payload) => ({
  type: ATTENDANCE.GET_ORDINANCE_SETTING_REQUEST,
  payload: payload,
});

export const setOrdinanceSettingSuccess = (payload) => ({
  type: ATTENDANCE.SET_ORDINANCE_SETTING_REQUEST_SUCCESS,
  payload: payload,
});

export const setOrdinanceSettingError = (payload) => ({
  type: ATTENDANCE.SET_ORDINANCE_SETTING_REQUEST_ERROR,
  payload: payload,
});

export const updateOrdinanceSettingRequest = (payload) => ({
  type: ATTENDANCE.UPDATE_ORDINANCE_SETTING_REQUEST,
  payload: payload,
});

export const setUpdateOrdinanceSettingSuccess = (payload) => ({
  type: ATTENDANCE.SET_UPDATE_ORDINANCE_SETTING_REQUEST_SUCCESS,
  payload: payload,
});

export const setUpdateOrdinanceSettingError = (payload) => ({
  type: ATTENDANCE.SET_UPDATE_ORDINANCE_SETTING_REQUEST_ERROR,
  payload: payload,
});

// update attendance
export const updateAttendaceRequest = (payload) => ({
  type: ATTENDANCE.UPDATE_ATTENDACE_REQUEST,
  payload: payload,
});
export const updateAttendaceSuccess = (payload) => ({
  type: ATTENDANCE.UPDATE_ATTENDACE_SUCCESS,
  payload: payload,
});
export const updateAttendaceError = (payload) => ({
  type: ATTENDANCE.UPDATE_ATTENDACE_ERROR,
  payload: payload,
});

// Download All attendance
export const DownloadAllRequest = (payload) => ({
  type: ATTENDANCE.DOWNLOAD_ALL_REQUEST,
  payload: payload,
});
export const DownloadAllSuccess = (payload) => ({
  type: ATTENDANCE.DOWNLOAD_ALL_SUCCESS,
  payload: payload,
});
export const DownloadAllError = (payload) => ({
  type: ATTENDANCE.DOWNLOAD_ALL_ERROR,
  payload: payload,
});
export const DownloadAllRequestReset = () => ({
  type: ATTENDANCE.DOWNLOAD_ALL_RESET,
});
