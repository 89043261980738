export const discussionBoardActions = {
  DISCUSSIONBOARD: {
    loading: 'DISCUSSION_BOARD_LOADING',

    create: 'DISCUSSION_BOARD_CREATE',
    create_success: 'DISCUSSION_BOARD_CREATE_SUCCESS',
    create_error: 'DISCUSSION_BOARD_CREATE_ERROR',

    delete: 'DISCUSSION_BOARD_DELETE',
    delete_success: 'DISCUSSION_BOARD_DELETE_SUCCESS',
    delete_error: 'DISCUSSION_BOARD_DELETE_ERROR',

    update: 'DISCUSSION_BOARD_UPDATE',
    update_success: 'DISCUSSION_BOARD_UPDATE_SUCCESS',
    update_error: 'DISCUSSION_BOARD_UPDATE_ERROR',

    get: 'DISCUSSION_BOARD_GET',
    get_success: 'DISCUSSION_BOARD_GET_SUCCESS',
    get_error: 'DISCUSSION_BOARD_GET_ERROR',

    get_by_id: 'DISCUSSION_BOARD_GET_BY_ID',
    get_by_id_success: 'DISCUSSION_BOARD_GET_BY_ID_SUCCESS',
    get_by_id_error: 'DISCUSSION_BOARD_GET_BY_ID_ERROR',

    get_comment_by_id: 'DISCUSSION_BOARD_COMMENT_GET_BY_ID',
    get_comment_by_id_success: 'DISCUSSION_BOARD_COMMENT_GET_BY_ID_SUCCESS',
    get_comment_by_id_error: 'DISCUSSION_BOARD_COMMENT_GET_BY_ID_ERROR',

    update_discussion: 'DISCUSSION_BOARD_LIST_UPDATE',
    update_discussion_success: 'DISCUSSION_BOARD_LIST_UPDATE_SUCCESS',
    update_discussion_error: 'DISCUSSION_BOARD_UPDATE_LIST_ERROR',

    get_user_by_subject: 'DISCUSSION_BOARD_LIST_USER_BY_SUBJECT',
    get_user_by_subject_success:
      'DISCUSSION_BOARD_LIST_USER_BY_SUBJECT_SUCCESS',
    get_user_by_subject_error: 'DISCUSSION_BOARD_LIST_USER_BY_SUBJECT_ERROR',
  },

  REPLY: {
    create_reply: 'DISCUSSION_SERVICE_REPLY_CREATE',
    create_reply_success: 'DISCUSSION_SERVICE_REPLY_CREATE_SUCCESS',
    create_reply_error: 'DISCUSSION_SERVICE_REPLY_CREATE_ERROR',

    // create reply/comment
    create_comment_reply_request: 'CREATE_COMMENT_REPLY_REQUEST',
    create_comment_reply_success: 'CREATE_COMMENT_REPLY_SUCCESS',
    create_comment_reply_error: 'CREATE_COMMENT_REPLY_ERROR',
    create_comment_reply_reset: 'CREATE_COMMENT_REPLY_RESET',

    // pin/unpin comment
    update_pin_unpin_comment_request: 'UPDATE_PIN_UNPIN_COMMENT_REQUEST',
    update_pin_unpin_comment_success: 'UPDATE_PIN_UNPIN_COMMENT_SUCCESS',
    update_pin_unpin_comment_error: 'UPDATE_PIN_UNPIN_COMMENT_ERROR',
    update_pin_unpin_comment_reset: 'UPDATE_PIN_UNPIN_COMMENT_RESET',
  },
  POLLING: {
    update_by_id: 'UPDATE_POLLING_OPTION_REQUEST',
    update_by_id_success: 'UPDATE_POLLING_OPTION_SUCCESS',
    update_by_id_error: 'UPDATE_POLLING_OPTION_ERROR',
    update_by_id_reset: 'UPDATE_POLLING_OPTION_RESET',
  },
  REPORT: {
    report_discussion: 'REPORT_DISCUSSION',
    report_discussion_success: 'REPORT_DISCUSSION_SUCCESS',
    report_discussion_error: 'REPORT_DISCUSSION_ERROR',
    reset: 'RESET_REPORT_DISCUSSION',
  },
  REPORT_ACTION: {
    report_discussion_action: 'REPORT_DISCUSSION_ACTION',
    report_discussion_action_success: 'REPORT_DISCUSSION_ACTION_SUCCESS',
    report_discussion_action_error: 'REPORT_DISCUSSION_ACTION_ERROR',
    reset: 'RESET_REPORT_DISCUSSION_ACTION',
  },
};
