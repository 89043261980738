import React from 'react';
const StarFilledSvg = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill={fill}>
      <path fill={fill} d="M7.333 14.896 10 13.312l2.688 1.584-.709-3 2.313-1.979-3.063-.271L10 6.792 8.771 9.646l-3.063.271 2.334 1.979ZM5.062 18l1.313-5.542L2 8.729l5.75-.5L10 3l2.25 5.25 5.75.479-4.375 3.729L14.938 18 10 15.062ZM10 11.062Z" />
    </svg>
  );
};

export default StarFilledSvg;
