import { statusCode } from 'constants/apis/apiStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  evaluationActions,
  examEvaluationApi,
  examSubjectEvaluationApi,
  getAssignmentSuccessAction,
  getExamAssignmentApi,
  getExamQuizApi,
  getExamStudentsListApi,
  getInteralEvaluationExportError,
  getInteralEvaluationExportSuccess,
  getInteralEvaluationListError,
  getInteralEvaluationListSuccess,
  getInternalEvaluationStudentsListApi,
  getInternalEvaluationStudentsListLoader,
  getInternalEvaluationsErrorApi,
  getInternalEvaluationsListApi,
  getMidEndTermExamListSuccessAction,
  getQuizSuccessAction,
  getStudentEvaluationDetailsError,
  getStudentEvaluationDetailsListApi,
  getStudentEvaluationDetailsSuccess,
  getStudentExamsListSuccessAction,
  getStudentInternalEvalListApi,
  getStudentInternalEvalListError,
  getStudentInternalEvalListSuccess,
  getStudentInternalExamListApi,
  getStudentInternalExamListError,
  getStudentInternalExamListSuccess,
  getStudentListForExamError,
  getStudentListForExamSuccess,
  getStudentMappedSubjectsApi,
  getSubjectExamListSuccessAction,
  getSubjectListError,
  getSubjectListSuccess,
  getUploadedAssignmentSuccessAction,
  getUploadedExamApi,
  getUserMappedProgramsSuccess,
  getUserMappedSemesterSuccess,
  getUserMappedSubjectsApi,
  getUserMappedSubjectsError,
  getUserMappedSubjectsLoader,
  getUserMappedSubjectsSuccess,
  getUserMappedYearSuccess,
  getUserMappingDataApi,
  postUploadExamApi,
  recheckRequestApi,
  recheckRequestSuccessAction,
  setExamLoading,
  setInternalEvaluationStudentsListError,
  setInternalEvaluationStudentsListSuccess,
  studentExamsListApi,
  submitAssignmentSuccessAction,
  submitExamAssignmentApi,
  submitExamQuizApi,
  submitInternalAssignmentMarksError,
  submitInternalAssignmentMarksLoader,
  submitInternalAssignmentMarksSuccess,
  submitInternalAssignmentStudentMarksApi,
  submitInternalMarksError,
  submitInternalMarksSuccess,
  submitQuizSuccessAction,
  submitStudentMarksApi,
  submitUploadAssignmentSuccessAction,
  updateAssignmentEvaluationForExamError,
  updateAssignmentEvaluationForExamSuccess,
  updateExamAssignmentEvaluationApi,
  updateExamStudentsEvaluationApi,
  updateStudentEvaluationForExamError,
  updateStudentEvaluationForExamSuccess,
} from 'store/Evaluation';
import { setEnableDisableLoaderAction } from 'store/Loader';
import { setToastMessage } from 'store/Toast';

function* getSubjectsListSaga({ payload }) {
  try {
    const response = yield call(getUserMappingDataApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getSubjectListSuccess(response?.data));
      if (response?.data) {
        yield put(
          setToastMessage({
            severity: 'success',
          })
        );
      } else
        yield put(
          setToastMessage({
            severity: 'error',
          })
        );
    } else {
      yield put(getSubjectListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getSubjectListError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getInternalEvaluationListSaga({ payload, params }) {
  try {
    yield put(getUserMappedSubjectsLoader(true));
    const response = yield call(getInternalEvaluationsListApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getInteralEvaluationListSuccess(response?.data));
      if (response?.data) {
        yield put(
          setToastMessage({
            severity: 'success',
          })
        );
      } else
        yield put(
          setToastMessage({
            severity: 'error',
          })
        );
    } else {
      yield put(getInteralEvaluationListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(getUserMappedSubjectsLoader(false));
  } catch (error) {
    yield put(getUserMappedSubjectsLoader(false));
    yield put(getInteralEvaluationListError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.message,
        severity: 'error',
      })
    );
  }
}

function* getInternalEvaluationExportSaga({ payload, params }) {
  try {
    yield put(getUserMappedSubjectsLoader(true));
    const response = yield call(
      getInternalEvaluationsErrorApi,
      payload,
      params
    );
    if (response?.status === statusCode.Ok200) {
      yield put(getInteralEvaluationExportSuccess(response?.data));
      if (response?.data) {
        yield put(
          setToastMessage({
            severity: 'success',
          })
        );
      } else
        yield put(
          setToastMessage({
            severity: 'error',
          })
        );
    } else {
      yield put(getInteralEvaluationExportError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(getUserMappedSubjectsLoader(false));
  } catch (error) {
    yield put(getUserMappedSubjectsLoader(false));
    yield put(getInteralEvaluationExportError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.message,
        severity: 'error',
      })
    );
  }
}

function* submitInternalMarksSaga({ payload, params }) {
  try {
    const response = yield call(submitStudentMarksApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(submitInternalMarksSuccess(response?.data));
      if (response?.data) {
        yield put(
          setToastMessage({
            severity: 'success',
          })
        );
      } else
        yield put(
          setToastMessage({
            data: response?.data?.message,
            severity: 'error',
          })
        );
    } else {
      yield put(submitInternalMarksError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(submitInternalMarksError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* submitInternalAssignmentMarksSaga({ payload, params }) {
  try {
    yield put(submitInternalAssignmentMarksLoader(true));
    const response = yield call(
      submitInternalAssignmentStudentMarksApi,
      payload,
      params
    );
    if (response?.status === statusCode.Created201) {
      yield put(submitInternalAssignmentMarksSuccess(response?.data));
      if (response?.data) {
        yield put(
          setToastMessage({
            data: response.data?.message,
            severity: 'success',
          })
        );
      } else
        yield put(
          setToastMessage({
            data: response.data?.message,
            severity: 'error',
          })
        );
    } else {
      yield put(submitInternalAssignmentMarksError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(submitInternalAssignmentMarksLoader(false));
  } catch (error) {
    yield put(submitInternalAssignmentMarksError(error?.data));
    yield put(submitInternalAssignmentMarksLoader(false));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getInternalStudentListSaga({ payload }) {
  try {
    const response = yield call(getStudentInternalEvalListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentInternalEvalListSuccess(response?.data));
      if (response?.data) {
        yield put(
          setToastMessage({
            severity: 'success',
          })
        );
      } else
        yield put(
          setToastMessage({
            severity: 'error',
          })
        );
    } else {
      yield put(getStudentInternalEvalListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getStudentInternalEvalListError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getInternalEvaluationStudentsListSaga({ payload }) {
  try {
    yield put(getInternalEvaluationStudentsListLoader(true));
    const response = yield call(getInternalEvaluationStudentsListApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(setInternalEvaluationStudentsListSuccess(response?.data));
    } else {
      yield put(setInternalEvaluationStudentsListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(getInternalEvaluationStudentsListLoader(false));
  } catch (error) {
    yield put(setInternalEvaluationStudentsListError(error?.data));
    yield put(
      setToastMessage({
        data: error,
        severity: 'error',
      })
    );
  }
  yield put(getInternalEvaluationStudentsListLoader(false));
}

function* getStudentExamQuizSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    const response = yield call(getExamQuizApi, payload);
    yield put(setExamLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getQuizSuccessAction(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* submitStudentExamQuizSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    const response = yield call(submitExamQuizApi, payload);
    yield put(setExamLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'success',
        })
      );
      yield put(submitQuizSuccessAction(true));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStudentExamAssignmentSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    const response = yield call(getExamAssignmentApi, payload);
    yield put(setExamLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getAssignmentSuccessAction(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* submitStudentExamAssignmentSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    const response = yield call(submitExamAssignmentApi, payload);
    yield put(setExamLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(
        setToastMessage({
          data: 'Exam submitted successfully',
          severity: 'success',
        })
      );
      yield put(submitAssignmentSuccessAction(true));
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'success',
      })
    );
  }
}
function* getExamMidEndTermExamListSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(examEvaluationApi, payload);
    yield put(setExamLoading(false));
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getMidEndTermExamListSuccessAction(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getExamSubjectListSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(examSubjectEvaluationApi, payload);
    yield put(setExamLoading(false));
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getSubjectExamListSuccessAction(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStudentExamsListSaga({ payload }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(studentExamsListApi, payload);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentExamsListSuccessAction(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getInternalStudentExamListSaga({ payload, params }) {
  try {
    yield put(getUserMappedSubjectsLoader(true));
    const response = yield call(getStudentInternalExamListApi, payload, params);
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentInternalExamListSuccess(response?.data));
      if (response?.data) {
        yield put(
          setToastMessage({
            severity: 'success',
          })
        );
      } else
        yield put(
          setToastMessage({
            severity: 'error',
          })
        );
    } else {
      yield put(getStudentInternalExamListError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
    yield put(getUserMappedSubjectsLoader(false));
  } catch (error) {
    yield put(getUserMappedSubjectsLoader(false));
    yield put(getStudentInternalExamListError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
      })
    );
  }
}

function* getExamStudentsListSaga({ params }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(getExamStudentsListApi, params);
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentListForExamSuccess(response?.data));
    } else {
      yield put(getStudentListForExamError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getStudentListForExamError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
        error: 'Something went wrong',
      })
    );
  }
}

function* updateExamStudentsEvaluationSaga({ payload = '', params = '' }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(
      updateExamStudentsEvaluationApi,
      payload,
      params
    );
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateStudentEvaluationForExamSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Operation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(updateStudentEvaluationForExamError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(updateStudentEvaluationForExamError(error?.data));
    yield put(
      setToastMessage({
        data: error?.data?.message,
        severity: 'error',
      })
    );
  }
}

function* getStudentEvaluationDetailsSaga({ payload, params }) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(
      getStudentEvaluationDetailsListApi,
      payload,
      params
    );
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentEvaluationDetailsSuccess(response?.data));
    } else {
      yield put(getStudentEvaluationDetailsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.error?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(getStudentEvaluationDetailsError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
        error: 'Something went wrong',
      })
    );
  }
}

function* getUploadedAssignmentSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    const response = yield call(getUploadedExamApi, payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getUploadedAssignmentSuccessAction(response?.data));
    }
    yield put(setExamLoading(false));
  } catch (error) {
    console.error(error);
    yield put(setExamLoading(false));
  }
}

function* recheckRequestSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    const response = yield call(recheckRequestApi, payload);
    yield put(setExamLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(recheckRequestSuccessAction(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* submitUploadedAssignmentSaga({ payload }) {
  try {
    yield put(setExamLoading(true));
    const response = yield call(postUploadExamApi, payload);
    yield put(setExamLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(submitUploadAssignmentSuccessAction(response?.data));
      yield put(
        setToastMessage({
          data: 'Exam submission successful.',
          severity: 'success',
        })
      );
    }
  } catch (error) {
    yield put(
      setToastMessage({
        data: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* updateExamAssignmentEvaluationSaga({
  payload = '',
  requestPath = '',
  params = '',
}) {
  try {
    yield put(setEnableDisableLoaderAction(true));
    const response = yield call(
      updateExamAssignmentEvaluationApi,
      payload,
      requestPath,
      params
    );
    yield put(setEnableDisableLoaderAction(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateAssignmentEvaluationForExamSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Operation successful',
          severity: 'success',
        })
      );
    } else {
      yield put(updateAssignmentEvaluationForExamError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(setEnableDisableLoaderAction(false));
    yield put(updateAssignmentEvaluationForExamError(error?.data));
    yield put(
      setToastMessage({
        severity: 'error',
        data: 'Something went wrong',
      })
    );
  }
}

function* getUserMappedSubjectsSaga({ payload }) {
  try {
    yield put(getUserMappedSubjectsLoader(true));
    const response = yield call(getUserMappedSubjectsApi, payload);
    if (response?.status === statusCode.Ok200) {
      if (
        payload?.user &&
        payload?.program &&
        payload?.year_id &&
        payload?.semester_id
      ) {
        yield put(getUserMappedSubjectsSuccess(response?.data));
      } else if (payload?.user && payload?.program && payload?.year_id) {
        yield put(getUserMappedSemesterSuccess(response?.data));
        yield put(getUserMappedSubjectsSuccess(response?.data));
      } else if (payload?.user && payload?.program) {
        yield put(getUserMappedYearSuccess(response?.data));
      } else if (payload?.user) {
        yield put(getUserMappedProgramsSuccess(response?.data));
      }
    } else {
      yield put(getUserMappedSubjectsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(getUserMappedSubjectsLoader(false));
  } catch (error) {
    yield put(getUserMappedSubjectsLoader(false));
    yield put(
      setToastMessage({
        error: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

function* getStudentMappedSubjectsSaga({ payload }) {
  try {
    yield put(getUserMappedSubjectsLoader(true));
    const response = yield call(getStudentMappedSubjectsApi, payload);
    if (response?.status === statusCode.Ok200) {
      if (
        payload?.user &&
        payload?.program &&
        payload?.year_id &&
        payload?.semester_id
      ) {
        yield put(getUserMappedSubjectsSuccess(response?.data));
      } else if (payload?.user && payload?.program && payload?.year_id) {
        yield put(getUserMappedSemesterSuccess(response?.data));
        yield put(
          getUserMappedSubjectsSuccess(response?.data?.[0]?.subject_info)
        );
      } else if (payload?.user && payload?.program) {
        yield put(getUserMappedYearSuccess(response?.data));
      } else if (payload?.user) {
        yield put(getUserMappedProgramsSuccess(response?.data));
      }
    } else {
      yield put(getUserMappedSubjectsError(response?.data));
      yield put(
        setToastMessage({
          data: response?.data?.message,
          severity: 'error',
        })
      );
    }
    yield put(getUserMappedSubjectsLoader(false));
  } catch (error) {
    yield put(getUserMappedSubjectsLoader(false));
    yield put(
      setToastMessage({
        error: 'Something went wrong',
        severity: 'error',
      })
    );
  }
}

export function* watchDummy() {
  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.recheck_request,
    recheckRequestSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.get_uploaded_assignment,
    getUploadedAssignmentSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.submit_uploaded_assignment,
    submitUploadedAssignmentSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.get_mid_end_term_exam_list,
    getExamMidEndTermExamListSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.get_exam_subject_list,
    getExamSubjectListSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.get_student_exams_list_action,
    getStudentExamsListSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.get_quiz,
    getStudentExamQuizSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.submit_student_quiz,
    submitStudentExamQuizSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.get_assignment,
    getStudentExamAssignmentSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.submit_student_assignment,
    submitStudentExamAssignmentSaga
  );

  yield takeLatest(
    evaluationActions.SUBJECT.get_subject_list,
    getSubjectsListSaga
  );

  yield takeLatest(
    evaluationActions.INTERNAL_EVALUATIONS_LIST.get_internal_evaluation_list,
    getInternalEvaluationListSaga
  );

  yield takeLatest(
    evaluationActions.INTERNAL_EVALUATIONS_EXPORT
      .get_internal_evaluation_export,
    getInternalEvaluationExportSaga
  );

  yield takeLatest(
    evaluationActions.SUBMIT_INTERNAL_EVAL_MARKS.submit_internal_eval_marks,
    submitInternalMarksSaga
  );

  yield takeLatest(
    evaluationActions.SUBMIT_INTERNAL_EVAL_ASSIGNMENT_MARKS
      .submit_internal_eval_assignment_marks,
    submitInternalAssignmentMarksSaga
  );

  yield takeLatest(
    evaluationActions.INTERNAL_STUDENT_EVAL_LIST.get_internal_student_eval_list,
    getInternalStudentListSaga
  );

  yield takeLatest(
    evaluationActions.INTERNAL_EVALUATION_STUDENTS_LIST
      .get_internal_evaluation_students__list,
    getInternalEvaluationStudentsListSaga
  );

  yield takeLatest(
    evaluationActions.INTERNAL_STUDENT_EXAM_LIST.get_internal_student_exam_list,
    getInternalStudentExamListSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS.GET_EXAM_STUDENTS_REQUEST,
    getExamStudentsListSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS
      .UPDATE_EXAM_STUDENTS_EVALUATION_REQUEST,
    updateExamStudentsEvaluationSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS
      .GET_STUDENT_EVALUATION_DETAILS_REQUEST,
    getStudentEvaluationDetailsSaga
  );

  yield takeLatest(
    evaluationActions.STUDENT_EXAM_AND_RESULTS
      .UPDATE_EXAM_ASSIGNMENT_EVALUATION_REQUEST,
    updateExamAssignmentEvaluationSaga
  );

  yield takeLatest(
    evaluationActions.GET_USER_MAPPED_SUBJECTS_ACTION,
    getUserMappedSubjectsSaga
  );

  yield takeLatest(
    evaluationActions.GET_STUDENT_MAPPED_SUBJECTS_ACTION,
    getStudentMappedSubjectsSaga
  );
}

export default function* evaluationSaga() {
  yield all([watchDummy()]);
}
