import axiosInstance from 'translator';
import { programContentApi } from 'store/ProgramContent';

export const getMappedDepartmentListApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.getMappedDepartmentList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramListforFacultyApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.getPrgoramListforFaculty}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getMappedYearListApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.getMappedYearList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubjectStatsApi = async (params, payload) => {
  return axiosInstance
    .get(`${programContentApi?.getSubjectStats}/${payload?.program_id}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getMappedSemesterListApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.getMappedSemesterList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getChapterListBySubjectApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.getChapterListBySubject}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubjectListApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.getSubjectList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getChapterContentApi = async (payload, params) => {
  return axiosInstance
    .get(`${programContentApi?.getChapterContent}/${payload?.chapter_id}`, {
      params: params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addPracticalContentApi = async (payload) => {
  return axiosInstance
    .post(`${programContentApi?.addPracticalContent}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createReadingContentApi = async (payload) => {
  return axiosInstance
    .post(`${programContentApi?.addReading}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateReadingContentApi = async (payload) => {
  return axiosInstance
    .put(`${programContentApi?.addReading}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteReadingContentApi = async (payload) => {
  return axiosInstance
    .delete(`${programContentApi?.addReading}/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addVideoContentApi = async (payload) => {
  return axiosInstance
    .post(`${programContentApi?.videoContent}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fileUploadApi = async (payload) => {
  return axiosInstance
    .post(`${programContentApi?.fileUpload}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getFileBlobUrlApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.fileUpload}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProgramDetailsApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.getProgramById}/${payload?.programId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPracticalContentApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.addPracticalContent}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addChapterApi = async (payload) => {
  return axiosInstance
    .post(`${programContentApi?.chapterApi}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getVideoContentApi = async (payload, params) => {
  return axiosInstance
    .get(`${programContentApi?.videoContent}/${payload}`, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getChapterDetailById = async (payload) => {
  return axiosInstance
    .get(`${programContentApi?.chapterApi}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePracticalContentApi = async (id, payload) => {
  return axiosInstance
    .put(`${programContentApi?.addPracticalContent}/${id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateVideoContentApi = async (id, payload) => {
  return axiosInstance
    .put(`${programContentApi?.videoContent}/${id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteVideoContentApi = async (payload) => {
  return axiosInstance
    .delete(`${programContentApi.videoContent}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deletePracticalContentApi = async (payload) => {
  return axiosInstance
    .delete(`${programContentApi.addPracticalContent}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateChapterApi = async (payload) => {
  return axiosInstance
    .put(`${programContentApi.chapterApi}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteChapterApi = async (payload) => {
  return axiosInstance
    .delete(`${programContentApi.chapterApi}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteLiveSessionApi = async (payload) => {
  return axiosInstance
    .delete(`${programContentApi.liveSessionApi}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getFacultyListBySubjectIdApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi.facultyListBySubjectId}?subject=${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserDepartmentsApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi.getUserDepartment}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserProgramsApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi.getUserProgramsList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getReadingContentApi = async (payload, params) => {
  return axiosInstance
    .get(`${programContentApi.addReading}/${payload}`, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
//GET Lab List
export const getLabListApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi.getLabList}`, { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//CREATE VM
export const createVmApi = async (payload) => {
  return axiosInstance
    .post(`${programContentApi.getLabList}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//GET VM State
export const getVmStateApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi.getLabList}/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const launchVmApi = async (payload) => {
  return axiosInstance
    .get(`${programContentApi.launcVmLab}/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
