import { LoaderActions } from 'store/Loader';

const INITIAL_STATE = {
  isGlobalLoading: false,
};

const loaderReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case LoaderActions.ENABLE_DISABLE_LOADER: {
      return {
        ...state,
        isGlobalLoading: action.payload || false,
      };
    }

    default:
      return state;
  }
};

export default loaderReducer;
