import {
  Button,
  Stack,
  Link,
  IconButton,
  Grid,
} from 'iiht-b2a-ui-components/build/components';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { theme } from 'themes';
import { Download, FileUpload, ImageIcon } from 'icons';
import './dropzone.css';
import { useTranslation } from 'react-i18next';
import DocumentViewerInModal from '../DocumentViewer/DocumentViewerInModal';
import { DeleteSvg, Attachment } from 'icons';

const Dropzone = forwardRef((props, ref) => {
  const {
    addFile = () => {},
    mappings = [],
    isEdit = false,
    deleteAttachment = () => {},
    singleFileSupport = false,
    supportedFiles = {},
    documentViewerModalName = 'myRepository.preview',
    isDocumentViewerAvailable = false,
    invalidFileMessage = 'File not support',
    maxFileSize = 3, // 3MB,
    fileName,
  } = props || '';
  const [myFiles, setMyFiles] = useState([]);
  const [fileData, setFileData] = useState('');
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const { t } = useTranslation();
  const [invalidMessage, setIsInvalidMessage] = useState(invalidFileMessage);
  const sizeInBytes = maxFileSize * Math.pow(1024, 2); // covert to mb to byte

  const CALL_FROM = {
    preUpload: 'PRE_UPLOAD',
    postUpload: 'POST_UPLOAD',
  };

  useEffect(() => {
    if (fileName) {
      setFileData([fileName]);
    }
  }, [fileName]);

  // Document viewer
  const [attachment, setAttachment] = useState([]);
  const [attachmentViewModalOpen, setAttachmentViewModalOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    removeFile(file) {
      const newFiles = [...myFiles];
      newFiles.splice(newFiles.indexOf(file), 1);
      setMyFiles(newFiles);
      addFile(newFiles, 'remove');
    },
  }));

  const handleViewDocument = (selectedFile, callFrom = '') => {
    let selectedFileName = '';
    let selectedFileURL = '';
    if (callFrom === CALL_FROM.preUpload) {
      selectedFileName = selectedFile?.name;
      selectedFileURL = window.URL.createObjectURL(selectedFile);
    }
    if (callFrom === CALL_FROM.postUpload) {
      selectedFileName = selectedFile?.fileName;
      selectedFileURL = selectedFile?.fileURL;
    }
    let fileObj = {
      fileName: selectedFileName,
      fileURL: selectedFileURL,
      fileType: selectedFileName?.split('.')[1],
    };
    setAttachment([fileObj]);
    setAttachmentViewModalOpen(true);
  };

  const onCloseAttachementModalView = () => {
    setAttachmentViewModalOpen(false);
    setAttachment([]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const fileType = acceptedFiles[0]?.name?.split('.')[1];
    if (fileType) {
      setIsInvalidFile(false);
      if (singleFileSupport) {
        setMyFiles([...acceptedFiles]);
        setFileData(acceptedFiles);
      } else {
        setMyFiles([...myFiles, ...acceptedFiles]);
        setFileData(acceptedFiles);
      }
    } else {
      setIsInvalidFile(true);
    }
  });

  const fileValidator = (file) => {
    let supportedFilesArr = [].concat.apply([], Object.values(supportedFiles));
    if (
      file.size > sizeInBytes &&
      supportedFilesArr.includes('.' + file?.name?.split('.')[1]?.toLowerCase())
    ) {
      setIsInvalidMessage(`File is larger than ${maxFileSize}MB`);
    } else {
      setIsInvalidMessage(invalidFileMessage);
    }
    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: supportedFiles,
    maxSize: sizeInBytes,
    validator: fileValidator,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    addFile(newFiles, 'remove');
  };

  useEffect(() => {
    addFile(fileData, '');
  }, [fileData]);

  const files = myFiles.map((file) => (
    <li key={file.path} className="list-item">
      <Grid container spacing={2}>
        <Grid item className="filepath truncate">
          {file.path && typeof file.path === 'string' ? file.path : ''}
        </Grid>
        <Grid display="flex" alignItems="center">
          <Button
            onClick={() => handleViewDocument(file, CALL_FROM.preUpload)}
            sx={{ textDecoration: 'underline' }}
            variant="text"
            color="secondary"
          >
            <ImageIcon fill={theme.palette.grey.P1000} className="mr2" />
            Preview
          </Button>
          <Button onClick={removeFile(file)}>
            <DeleteSvg color={theme.palette.secondary.main} />
          </Button>
        </Grid>
      </Grid>
    </li>
  ));

  return (
    <>
      <section className="container mouse-pointer">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <span>
            <FileUpload fill={theme.palette.primary.main} />
          </span>
        </div>
        {isInvalidFile && (
          <div className="fileTypeNotSupportedMessage">{invalidMessage}</div>
        )}
        <aside className="asideMarginTop">
          {/* {files?.length > 0 && <ul>{files}</ul>}*/}
          {(files.length > 0 || fileName) && <ul>{files}</ul>}
          {isEdit &&
            mappings?.map((mp, index) => {
              if (mp) {
                return (
                  <Stack
                    direction="row"
                    className="display-flex"
                    key={'uploadedFile-' + index}
                  >
                    <Link href={mp?.fileURL}>
                      <Download fill={theme.palette.primary.main} />
                    </Link>
                    {isDocumentViewerAvailable && (
                      <Link
                        className="font-16 fileViewLink truncate"
                        color={'inherit'}
                        onClick={() =>
                          handleViewDocument(mp, CALL_FROM.postUpload)
                        }
                        sx={{ pb: 2, fontSize: '16px', pl: 1.5 }}
                      >
                        {mp?.fileName}
                      </Link>
                    )}
                    {!isDocumentViewerAvailable && (
                      <Link
                        className="font-16 truncate"
                        color={'inherit'}
                        underline="none"
                        href={mp?.fileURL}
                        sx={{ pb: 2, fontSize: '16px', pl: 1.5, maxWidth: 480 }}
                      >
                        {mp?.fileName}
                      </Link>
                    )}
                  </Stack>
                );
              }
            })}
        </aside>
      </section>

      {attachmentViewModalOpen && (
        <DocumentViewerInModal
          isOpen={attachmentViewModalOpen}
          modalName={documentViewerModalName}
          attachmentList={attachment}
          closeHandler={onCloseAttachementModalView}
        />
      )}
    </>
  );
});

export default Dropzone;
