const ROOT = '/';
const DASHBOARD = '/dashboard';
const PAGE_NOT_FOUND = '/pageNotFound';
const SYSTEM_ADMIN = '/systemAdmin';
const LOGIN = '/login';
const PROFILE = '/profile';
const FORGOT_PASSWORD = '/forgot-password';
const RESET_PASSWORD = '/resetpassword';
const CHANGE_PASSWORD = '/reset-password/:uid/:token';
const USER_PROFILE = '/user-profile';
const LOGOUT = '/logout';
const ROLEWISE_ROUTER = '/redirectR';
const SIGNIN_OIDC = '/signin-oidc';
const SIGN_IN_REDIRECT = '/sign-in-redirect';
const LOGOUT_CALLBACK = '/logout-callback';
const SILENT_RENEW = '/silentrenew';
const LEAD_PROFILE = '/lead-profile';
const FEEDBACK = '/feedback';
const ASSESS_CANDIDATE = '/assess-candidate';
const FORGOT_PASSWORD_LINK = '/forgot-password-success';
const FACULTY_DETAILS = '/faculty-detail';

const COURSE_MANAGEMENT = {
  ROOT: '/manage-course',
  DEPARTMENT_DETAILS: 'department',
  CREATE: 'create',
  CREATE_COURSE: 'create-course',
  EDIT_COURSE: 'edit-course',
  CREATE_PROGRAM: 'create-program',
  EDIT_PROGRAM: 'edit-program',
  COURSE_DETAILS: 'course-details',
  CREATE_ASSESSMENT_QUIZ: 'create-assessment',
  CREATE_QUIZ: 'create-quiz',
  EDIT_ASSIGNMENT: 'edit-assignment',
  EDIT_QUIZ: 'edit-quiz',
  CREATE_SESSION: 'create-session',
  EDIT_SESSION: 'edit-session',
  PROGRAM_DETAILS: 'program-details',
  ABOUT_DEPARTMENT: 'about-department',
  ABOUT_FACULTIES: 'about-faculties',
  SUBJECT_DETAILS:
    ':id/:subjectId/:yearId/:programId/:subjectName/:semesterId/:semesteruId/subject-details',
};

const ASSESSMENT_REPOSITORY = {
  ROOT: 'assessment-repository',
  QUESTION_BANK_DETAILS: 'question-bank-details',
  CREATE_ASSESSMENT: 'CreateAssessment',
  EDIT_QUESTION: 'edit-question',
  ASSESSMENT_DETAILS: 'assessment-details',
  EDIT: 'edit-assessment',
  IMPORT_QUESTION_LIST: 'import-question-list',
  IMPORT_QUESTION: 'import-question',
  CONDUCT_ASSESSMENT: 'conduct-assessment',
  START_ASSESSMENT: 'start-assessment',
  LIVE_ASSESSMENT: 'live-assessment',
};

const STAFF_MANAGEMENT = {
  ROOT: '/manage-staff',
  STAFF_DETAILS: 'staff-details',
  ONBOARD_STAFF: 'onboard-staff',
  EDIT_STAFF: 'edit-staff',
  STAFF_ATTENDANCE: 'staff-attendance',
};

const EXAMS = {
  ROOT: '/exams',
  CREATE_EXAM: 'create-exam',
};

const EXAMS_AND_RESULTS = {
  ROOT: '/exams-and-results',
  STUDENT_QUIZ: 'student-quiz',
  STUDENT_ASSESSMENT: 'student-assessment',
  UPLOAD_EXAM: 'student-upload-exam',
};
const FEES = {
  ROOT: '/fees',
};

const MY_REPOSITORY = {
  ROOT: '/my-repository',
};

const ATTENDANCE = {
  ROOT: '/attendance/:id/:from',
  MAIN_ATTENDANCE_PAGE: '/attendance',
  LIVE_SESSION_BULK_ATTENDANCE: '/attendance/bulk/live-session',
  STUDENT_ROLE_ATTENDANCE: '/attendance/student',
  ATTENDANCE_REQUEST: '/attendance/attendance-request',
  ATTENDANCE_APPROVED: '/attendance/attendance-approved',
};

const SETTINGS = {
  ROOT: '/settings',
};
const ORDINANCE_SETTING = {
  ROOT: '/ordinance-settings',
};

const HELP = {
  ROOT: '/help',
};

const SCHEDULE = {
  ROOT: '/schedule',
  PAST_SCHEDULE: 'past-schedule',
  SCHEDULE_DETAILS: 'schedule-details',
};

const CALENDAR = {
  ROOT: '/calendar',
};

const NOTIFICATIONS = {
  ROOT: '/notifications',
  NOTIFICATION_APPROVAL: '/notification_approval',
  VIEW_CONTENT: '/view_content',
};
const COURSE_CONTENT_FEEDBACK = {
  ROOT: '/course-content-feedback',
  CREATE_FORM: '/create_form',
  PREVIEW_QUESTION: '/preview_question',
};
const EVALUATION = {
  ROOT: '/evaluation',
  COURSE_DETAILS: 'course-details',
  EVALUATE: 'evaluate',
  REEVALUATE: 're-evaluate',
  INTERNAL_EVALUATE: ':from/:content_type/:content_id/evaluate',
  FACULTY_MIDTERM_EVALUATE:
    'exam/:examId/subject/:subjectId/examDetail/:examDetailId/',
  INTERNAL_EXAMINATION: 'internal-evaluation',
  UPLOAD_ASSESSMENT:
    'upload-assessment/view/:contentType/:assignmentId/:studentId',
  QUIZ: 'quiz/view/:contentType/:quizId/:studentId',
  END_TERM_EXAMINATION: 'end-term-examination',
  STUDENT_REEVALUATE: 'student/reevaluate',
  ASSESSMENT_DETAILS: ':courseId/:moduleName/:subModuleName/assessment-details',
  STUDENT_PROGRESS: 'student-progress',
  EVALUATION_FEEDBACK:
    ':courseId/:moduleName/:subModuleName/assessment-details/evaluation-feedback/:activityId/:resultId',
  REEVALUATION:
    ':courseId/:moduleName/:subModuleName/assessment-details/re-evaluation/:activityId/:resultId',
  QUIZ_EVALUATION_FEEDBACK:
    ':courseId/:moduleName/:subModuleName/assignment-details/evaluation-feedback/:activityId/:resultId',
  QUIZ_REEVALUATION:
    ':courseId/:moduleName/:subModuleName/assignment-details/re-evaluation/:activityId/:resultId',
  INTERNAL_EVALUATION: ':content_id/:content_type/:user_id/evaluation-feedback',
  INTERNAL_REEVALUATION: ':content_id/:content_type/:user_id/re-evaluation',
};
const STUDENT_ATTENDANCE = {
  ROOT: '/studentLanding',
  SESSION_ATTENDANCE: '/sessionAttendance',
};

const FACULTY_ATTENDANCE = {
  ROOT: '/facultyLanding',
};

const DISCUSSION = {
  ROOT: '/discussion',
  REPORT_ACTION: 'report-action',
};

const PEER_GROUP = {
  ROOT: '/peer-group',
};

const STUDENT_MANAGEMENT = {
  ROOT: '/manage-students',
  STUDENT_DETAILS: 'student-details',
  ONBOARD_STUDENT: 'onboard-student',
  EDIT_STUDENT: 'edit-student',
  STUDENT_CONNECT: 'student-connect',
};

const GAMIFICATION = {
  ROOT: '/gamification',
};
const STUDENT_LEARNING = {
  ROOT: '/my-learning',
  ASSESSMENT_LIST: 'assessment-list',
  QUIZ: ':deptId/:programId/:yearId/:semesterId/:subjectId/:subjectName/:chapterId/:quizId/quiz',
  DESCRIPTVE_ASSESSMENT:
    ':deptId/:programId/:yearId/:semesterId/:subjectId/:subjectName/:chapterId/:assignmentId/assessment',
  UPLOAD_ASSESSMENT:
    ':deptId/:programId/:yearId/:semesterId/:subjectId/:subjectName/:chapterId/:assignmentId/upload-assessment',
  VIEW_QUIZ:
    ':moduleId/:moduleName/:subModuleId/:contentId/:title/:type/:id/viewQuiz',
  ASSESSMENT:
    ':moduleId/:moduleName/:subModuleId/:contentId/:title/:type/:id/assessmentTest',
  VIEW_ASSESSMENT:
    ':moduleId/:moduleName/:subModuleId/:contentId/:title/:type/:id/viewAssessment',
  MODULES: ':moduleId/:moduleName',
  CONTENT: ':subModuleId/:contentId/:title/:type',
  QUIZ_LIST: 'quiz-list',
  COURSE_DETAILS_HOME: ':moduleId/:moduleName/course-details-home',
  NOTES_BOOKMARKS:
    ':deptId/:programId/:yearId/:semesterId/:subjectId/:subjectName/:chapterId/notes-bookmarks',
  SUBJECT_DETAILS:
    ':id/:subjectId/:yearId/:programId/:subjectName/:semesterId/subject-details',
  PROGRAM_DETAILS: 'program-details/:deptId/program/:programId',
  VIDEO_LINK:
    ':deptId/:programId/:yearId/:semesterId/:subjectId/:subjectName/:chapterId/:videoId/video',
  READING_LINK:
    ':deptId/:programId/:yearId/:semesterId/:subjectId/:subjectName/:chapterId/:readingId/reading',
  MY_LABS: '/myLabs',
  SUBMIT_COURSE_CONTENT_FEEDBACK: 'course-content-feedback',
};

const MOOCS = {
  ROOT: '/moocs',
};

const REPORTS = {
  ROOT: '/reports',
};

const LEADS = {
  ROOT: '/leads',
  ONBOARD_LEAD: 'onboard-lead',
};

const CANDIDATE = {
  ROOT: '/candidate',
  LOGIN: '/login',
  MY_PROFILE: '/my-profile',
  MY_APPLICATIONS: '/my-applications',
  APPLICATION_FORM: '/candidate-form',
  FEES_DETAILS: '/fees-details',
};

const COUNSELLOR = {
  ROOT: '/counsellor',
  COUNSEL_CANDIDATE_PROFILE: '/candidate-profile',
  CANDIDATES_LIST: '/candidates-list',
  CANDIDATE_APP_FORM: '/candidate-prospect/application',
  COUNSELLOR_PROFILE: '/counsellor/my-profile',
  COUNSELLOR_SETTNGS: '/counsellor/settings',
};

const VERIFIER = {
  ROOT: '/verifier',
  CANDIDATES_LIST: '/candidates-list',
  CANDIDATE_FORM: '/candidate-form-details',
  VERIFIER_PROFILE: '/my-profile',
  VERIFIER_SETTNGS: '/settings',
};

const PROSPECTUS = {
  ROOT: '/prospectus',
  ADMISSION_FORM: 'admission-form',
};

const ASSETS = {
  ROOT: '/assets',
};

const CONVERSATION = {
  ROOT: '/chats',
};

const USER_MANAGEMENT = {
  ROOT: '/user-management',
  CREATE_USER: 'create-user',
  CREATE_USER_ROLE: 'create-user-role',
  USER_DETAILS: 'user-details',
  USER_ROLE_DETAILS: 'user-role-details',
  EDIT_USER: 'edit-user',
  EDIT_USER_ROLE: 'edit-user-role',
  ADD_USER: 'add-user',
};

const MOOC_ADMIN = {
  ROOT: '/mooc-admin',
  AFFILIATE_COURSES: '/affiliate-courses',
  PUBLISH_COURSES: '/publish-courses',
  MONITOR_SUBSCRIPTIONS: '/monitor-subscriptions',
  MOOC_SETTINGS: '/mooc-settings',
  MOOC_COURSE_CERTIFICATE: '/mooc-course-certificate',
  MOOC_COURSE_EVALUTION: '/mooc-course-evalution',
  MONITOR_LEARNING_PATH: '/monitor-learning-path',
  LEARNING_PATH: '/learning-path',
  CREATE_LEARNING_PATH: '/create-learning-path',
  MOOC_REPORT: '/mooc-report',
  EDIT_LEARNING_PATH: '/edit-learning-path',
};

const SUPER_ADMIN = {
  ROOT: '/super-admin',
  CREATE_TENANT: 'create-tenant',
  EDIT_TENANT: 'edit-tenant',
  TENANT_DETAILS: 'tenant-details',
  SUPER_ADMIN_PROFILE: 'super-admin-profile',
  TENANT_MIGRATION: 'tenant-migration',
  ADD_ADMIN: 'add-admin',
  EDIT_ADMIN: 'edit-admin',
  FEATURES: 'features',
};

const MOOC_COURSE = {
  ROOT: '/mooc-courses',
  COURSE_DETAILS: '/course-details',
  CERTIFICATE: '/certificate',
};

const LEARNING_PATH = {
  ROOT: '/learning-path',
  LP_COURSES: '/courses',
  COURSE_DETAILS: '/course-details',
};

const BADGE = {
  ROOT: '/manage-badge',
  CREATE_BADGE: '/create-badge',
  EDIT_BADGE: '/edit-badge',
};

const OBE = {
  ROOT: '/outcome-based-education',
  CO_PO_MAPPING: 'co-po-mapping',
};

const USER_PROFILE_EDIT = {
  ROOT: '/user-profile-edit',
};

export const ROUTES = {
  ROOT: ROOT,
  LOGIN: LOGIN,
  DASHBOARD: DASHBOARD,
  PAGE_NOT_FOUND: PAGE_NOT_FOUND,
  SYSTEM_ADMIN: SYSTEM_ADMIN,
  PROFILE: PROFILE,
  FORGOT_PASSWORD: FORGOT_PASSWORD,
  FORGOT_PASSWORD_LINK: FORGOT_PASSWORD_LINK,
  CHANGE_PASSWORD: CHANGE_PASSWORD,
  RESET_PASSWORD: RESET_PASSWORD,
  COURSE_MANAGEMENT: COURSE_MANAGEMENT,
  STAFF_MANAGEMENT: STAFF_MANAGEMENT,
  STUDENT_MANAGEMENT: STUDENT_MANAGEMENT,
  MY_REPOSITORY: MY_REPOSITORY,
  SETTINGS: SETTINGS,
  HELP: HELP,
  USER_PROFILE: USER_PROFILE,
  FEES: FEES,
  SCHEDULE: SCHEDULE,
  CALENDAR: CALENDAR,
  EVALUATION: EVALUATION,
  NOTIFICATIONS: NOTIFICATIONS,
  EVALUATION: EVALUATION,
  GAMIFICATION: GAMIFICATION,
  LOGOUT: LOGOUT,
  SIGNIN_OIDC: SIGNIN_OIDC,
  SIGN_IN_REDIRECT: SIGN_IN_REDIRECT,
  SILENT_RENEW,
  LOGOUT_CALLBACK: LOGOUT_CALLBACK,
  STUDENT_LEARNING: STUDENT_LEARNING,
  DISCUSSION: DISCUSSION,
  PEER_GROUP: PEER_GROUP,
  REPORTS: REPORTS,
  ATTENDANCE: ATTENDANCE,
  ROLEWISE_ROUTER: ROLEWISE_ROUTER,
  LEAD_PROFILE: LEAD_PROFILE,
  LEADS: LEADS,
  FEEDBACK: FEEDBACK,
  CANDIDATE: CANDIDATE,
  COUNSELLOR: COUNSELLOR,
  VERIFIER: VERIFIER,
  ASSESS_CANDIDATE: ASSESS_CANDIDATE,
  PROSPECTUS: PROSPECTUS,
  ASSETS: ASSETS,
  BADGE: BADGE,
  CONVERSATION: CONVERSATION,
  USER_MANAGEMENT: USER_MANAGEMENT,
  EXAMS: EXAMS,
  MOOCS: MOOCS,
  LEARNING_PATH: LEARNING_PATH,
  EXAMS_AND_RESULTS: EXAMS_AND_RESULTS,
  MOOC_ADMIN: MOOC_ADMIN,
  MOOC_COURSE: MOOC_COURSE,
  STUDENT_ATTENDANCE: STUDENT_ATTENDANCE,
  ORDINANCE_SETTING: ORDINANCE_SETTING,
  FACULTY_ATTENDANCE: FACULTY_ATTENDANCE,
  OBE: OBE,
  FACULTY_DETAILS: FACULTY_DETAILS,
  ASSESSMENT_REPOSITORY: ASSESSMENT_REPOSITORY,
  COURSE_CONTENT_FEEDBACK: COURSE_CONTENT_FEEDBACK,
  SUPER_ADMIN: SUPER_ADMIN,
  USER_PROFILE_EDIT: USER_PROFILE_EDIT,
};
