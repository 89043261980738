import * as React from 'react';

const ArrowUpward = ({
  fill = '#012148',
  height = '24',
  width = '24',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 96 960 960"
      width={width}
      fill={fill}
    >
      <path d="M450 896V370L202 618l-42-42 320-320 320 320-42 42-248-248v526h-60Z" />
    </svg>
  );
};

export default ArrowUpward;
