import React from 'react';

const Share = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
    >
      <path d="M720 976q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322 664q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638 384L356 548q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760 296q0-17-11.5-28.5T720 256q-17 0-28.5 11.5T680 296q0 17 11.5 28.5T720 336ZM240 616q17 0 28.5-11.5T280 576q0-17-11.5-28.5T240 536q-17 0-28.5 11.5T200 576q0 17 11.5 28.5T240 616Zm480 280q17 0 28.5-11.5T760 856q0-17-11.5-28.5T720 816q-17 0-28.5 11.5T680 856q0 17 11.5 28.5T720 896Zm0-600ZM240 576Zm480 280Z" />
    </svg>
  );
};

export default Share;
