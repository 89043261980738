import React from 'react';

const FileUpload = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
      <path fill="#68747B" d="M10.542 33.333q-3.625 0-6.25-2.604T1.667 24.5q0-3.25 2.021-5.75 2.02-2.5 5.27-3.083.875-3.959 3.979-6.459 3.105-2.5 7.105-2.5 4.791 0 8.041 3.396t3.25 8.188v.666q2.959.042 4.979 2.104 2.021 2.063 2.021 5.063 0 2.958-2.125 5.083t-5.083 2.125h-9.75q-1.125 0-1.937-.833-.813-.833-.813-1.958v-10l-3.208 3.166-1.959-2L20 15.167l6.542 6.541-1.959 2-3.208-3.166v10h9.75q1.833 0 3.125-1.292t1.292-3.125q0-1.833-1.292-3.125t-3.125-1.292h-2.583v-3.416q0-3.625-2.48-6.229-2.479-2.605-6.104-2.605t-6.146 2.605q-2.52 2.604-2.52 6.229H10.5q-2.542 0-4.292 1.77-1.75 1.771-1.75 4.355 0 2.541 1.792 4.333 1.792 1.792 4.292 1.792h5.291v2.791ZM20 21.375Z" />
    </svg>
  );
};

export default FileUpload;
