import * as React from 'react';

const Note = ({ fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    viewBox="0 96 960 960"
    width="20"
    fill={fill}
    {...rest}
  >
    <path d="M288 768h72l235-235-72-72-235 235v72Zm341-269 36-36q8-8 8-17t-8-17l-38-38q-8-8-17-8t-17 8l-36 36 72 72ZM216 912q-29.7 0-50.85-21.15Q144 869.7 144 840V312q0-29.7 21.15-50.85Q186.3 240 216 240h171q8-31 33.5-51.5T480 168q34 0 59.5 20.5T573 240h171q29.7 0 50.85 21.15Q816 282.3 816 312v528q0 29.7-21.15 50.85Q773.7 912 744 912H216Zm0-72h528V312H216v528Zm264-552q10.4 0 17.2-6.8 6.8-6.8 6.8-17.2 0-10.4-6.8-17.2-6.8-6.8-17.2-6.8-10.4 0-17.2 6.8-6.8 6.8-6.8 17.2 0 10.4 6.8 17.2 6.8 6.8 17.2 6.8ZM216 840V312v528Z" />
  </svg>
);

export default Note;
