import { statusCode } from 'constants/apis/apiStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { setToastMessage } from 'store/Toast';
import {
  assignBadgeError,
  assignBadgeSuccess,
  createBadgeError,
  createBadgeSuccess,
  deleteBadgeError,
  deleteBadgeSuccess,
  errorBadgeList,
  gamificationStudentsLoading,
  getBadgeByIDError,
  getBadgeByIDSuccess,
  getBadgeIconsError,
  getBadgeIconsSuccess,
  getGamificationLoading,
  getStudentBadgeDetailError,
  getStudentBadgeDetailSuccess,
  getStudentBadgeListError,
  getStudentBadgeListSuccess,
  getStudentProgramError,
  getStudentProgramSuccess,
  successBadgeList,
  updateBadgeByIDError,
  updateBadgeByIDSuccess,
} from './Gamification.actions';
import {
  assignBadgeApi,
  createBadgeApi,
  deleteBadgeApi,
  getBadgeApi,
  getBadgeByIdApi,
  getBadgeIconsListApi,
  getStudentBadegListApi,
  getStudentBadgeDetailApi,
  getStudentProgramApi,
  updateBadgeApi,
} from './Gamification.api';
import { GamificationActions } from './Gamification.constants';

function* getStudentBadgeListSaga({ payload }) {
  try {
    yield put(getGamificationLoading(true));
    const response = yield call(getStudentBadegListApi, payload);
    yield put(getGamificationLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentBadgeListSuccess(response?.data));
    } else {
      yield put(getStudentBadgeListError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStudentBadgeDetailSaga({ payload }) {
  try {
    yield put(getGamificationLoading(true));
    const response = yield call(getStudentBadgeDetailApi, payload);
    yield put(getGamificationLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentBadgeDetailSuccess(response?.data));
    } else {
      yield put(getStudentBadgeDetailError(response?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getBadgeListSaga({ payload }) {
  try {
    yield put(gamificationStudentsLoading(true));
    const response = yield call(getBadgeApi, payload);
    yield put(gamificationStudentsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(successBadgeList(response?.data));
    } else {
      yield put(errorBadgeList(response?.data));
    }
  } catch (error) {
    yield put(errorBadgeList(error?.data));
  }
}

function* createBadgeListSaga({ payload }) {
  try {
    yield put(gamificationStudentsLoading(true));
    const response = yield call(createBadgeApi, payload);
    yield put(gamificationStudentsLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(createBadgeSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Badge created succesfully',
          severity: 'success',
        })
      );
    } else {
      yield put(createBadgeError(response?.data));
      yield put(
        setToastMessage({
          data:
            response?.data?.message?.title ||
            response?.data?.message?.start_date ||
            response?.data?.message?.badge_icon,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(createBadgeError(error?.data));
  }
}

function* getBadgeIconsListSaga({ payload }) {
  try {
    yield put(gamificationStudentsLoading(true));
    const response = yield call(getBadgeIconsListApi, payload);
    yield put(gamificationStudentsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getBadgeIconsSuccess(response?.data));
    } else {
      yield put(getBadgeIconsError(response?.data));
    }
  } catch (error) {
    yield put(getBadgeIconsError(error?.data));
  }
}

function* deleteBadgeSaga({ payload }) {
  try {
    yield put(gamificationStudentsLoading(true));
    const response = yield call(deleteBadgeApi, payload);
    yield put(gamificationStudentsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(deleteBadgeSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Badge deleted succesfully',
          severity: 'success',
        })
      );
    } else {
      yield put(deleteBadgeError(response?.data));
      yield put(
        setToastMessage({
          data: 'Badge deleted unsuccesfully',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(deleteBadgeError(error?.data));
  }
}
function* updateBadgeByIDSaga({ payload }) {
  try {
    yield put(gamificationStudentsLoading(true));
    const response = yield call(updateBadgeApi, payload);
    yield put(gamificationStudentsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(updateBadgeByIDSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Badge updated succesfully',
          severity: 'success',
        })
      );
    } else {
      yield put(updateBadgeByIDError(response?.data));
      yield put(
        setToastMessage({
          data:
            response?.data?.message?.title ||
            response?.data?.message?.badge_icon,
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(deleteBadgeError(error?.data));
  }
}

function* getBadgeByIDSaga({ payload }) {
  try {
    yield put(gamificationStudentsLoading(true));
    const response = yield call(getBadgeByIdApi, payload);
    yield put(gamificationStudentsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getBadgeByIDSuccess(response?.data));
    } else {
      yield put(getBadgeByIDError(response?.data));
    }
  } catch (error) {
    yield put(getBadgeByIDError(error?.data));
  }
}

function* assignBadgeSaga({ payload }) {
  try {
    yield put(gamificationStudentsLoading(true));
    const response = yield call(assignBadgeApi, payload);
    yield put(gamificationStudentsLoading(false));
    if (response?.status === statusCode.Created201) {
      yield put(assignBadgeSuccess(response?.data));
      yield put(
        setToastMessage({
          data: 'Badge assigned succesfully',
          severity: 'success',
        })
      );
    } else {
      yield put(assignBadgeError(response?.data));
      yield put(
        setToastMessage({
          data: 'Badge assigned unsuccesfully',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    yield put(assignBadgeError(error?.data));
  }
}

function* getStudentProgramSaga({ payload }) {
  try {
    yield put(gamificationStudentsLoading(true));
    const response = yield call(getStudentProgramApi, payload);
    yield put(gamificationStudentsLoading(false));
    if (response?.status === statusCode.Ok200) {
      yield put(getStudentProgramSuccess(response?.data));
    } else {
      yield put(getStudentProgramError(response?.data));
    }
  } catch (error) {
    yield put(getStudentProgramError(error?.data));
  }
}

export function* watchDummy() {
  yield takeLatest(
    GamificationActions.STUDENT_GAMIFICATION.get_student_badge_list,
    getStudentBadgeListSaga
  );
  yield takeLatest(
    GamificationActions.STUDENT_GAMIFICATION.get_student_badge_detail,
    getStudentBadgeDetailSaga
  );
  yield takeLatest(GamificationActions.BADGE.get, getBadgeListSaga);
  yield takeLatest(GamificationActions.BADGE.create, createBadgeListSaga);
  yield takeLatest(GamificationActions.BADGE.icons, getBadgeIconsListSaga);
  yield takeLatest(GamificationActions.BADGE.delete, deleteBadgeSaga);
  yield takeLatest(GamificationActions.BADGE.get_by_id, getBadgeByIDSaga);
  yield takeLatest(GamificationActions.BADGE.update, updateBadgeByIDSaga);
  yield takeLatest(GamificationActions.BADGE.assignBadge, assignBadgeSaga);
  yield takeLatest(
    GamificationActions.BADGE.get_student_program,
    getStudentProgramSaga
  );
}

export default function* gamificationSaga() {
  yield all([watchDummy()]);
}
