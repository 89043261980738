import React from 'react';

const TrendingDown = ({ color, height = '24', width = '24', fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 -960 960 960"
      fill={fill}
      style={{ transform: 'scaleX(-1)', marginLeft: '1rem' }}
    >
      <path
        fill={fill}
        d="M640-240v-80h104L536-526 376-366 80-664l56-56 240 240 160-160 264 264v-104h80v240H640Z"
      />
    </svg>
  );
};

export default TrendingDown;
