import React from 'react';

const ImageIcon = ({ fill, height = 40, width = 40, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
      fill={fill}
      className={className}
    >
      <path d="M200 976q-50 0-85-35t-35-85V296q0-50 35-85t85-35h560q50 0 85 35t35 85v560q0 50-35 85t-85 35H200Zm0-80h560q17 0 28.5-11.5T800 856V296q0-17-11.5-28.5T760 256H200q-17 0-28.5 11.5T160 296v560q0 17 11.5 28.5T200 896Zm40-80 160-160 72 71 88-111 160 200H240Zm80-320q-33 0-56.5-23.5T240 416q0-33 23.5-56.5T320 336q33 0 56.5 23.5T400 416q0 33-23.5 56.5T320 496Z" />
    </svg>
  );
};

export default ImageIcon;
