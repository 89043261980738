export const settingsActions = {
  PRIVACY: {
    loading: 'LOADING',

    create: 'SETTINGS_PRIVACY_CREATE',
    create_success: 'SETTINGS_PRIVACY_CREATE_SUCCESS',
    create_error: 'SETTINGS_PRIVACY_CREATE_ERROR',

    get_list: 'SETTINGS_PRIVACY_GET_LIST',
    get_list_success: 'SETTINGS_PRIVACY_GET_LIST_SUCCESS',
    get_list_error: 'SETTINGS_PRIVACY_GET_LIST_ERROR',

    get: 'SETTINGS_PRIVACY_GET',
    get_success: 'SETTINGS_PRIVACY_GET_SUCCESS',
    get_error: 'SETTINGS_PRIVACY_GET_ERROR',

    delete: 'SETTINGS_PRIVACY_DELETE',
    delete_success: 'SETTINGS_PRIVACY_DELETE_SUCCESS',
    delete_error: 'SETTINGS_PRIVACY_DELETE_ERROR',

    update: 'SETTINGS_PRIVACY_UPDATE',
    reset_update: 'RESET_SETTINGS_PRIVACY_UPDATE',
    update_success: 'SETTINGS_PRIVACY_UPDATE_SUCCESS',
    update_error: 'SETTINGS_PRIVACY_UPDATE_ERROR',
  },
  PRIVACY_NOTIFICATION: {
    get_all_privacy_notification_list: 'SETTINGS_ALL_GET_LIST',
    get_all_privacy_notification_list_success: 'SETTINGS_ALL_GET_LIST_SUCCESS',
    get_all_privacy_notification_list_error: 'SETTINGS_ALL_GET_LIST_ERROR',
    get_privacy_notification_list: 'SETTINGS_PRIVACY_NOTIFICATION_GET_LIST',
    get_privacy_notification_list_success:
      'SETTINGS_PRIVACY_NOTIFICATION_GET_LIST_SUCCESS',
    get_privacy_notification_list_error:
      'SETTINGS_PRIVACY_NOTIFICATION_GET_LIST_ERROR',
    update_privacy_notification: 'SETTINGS_PRIVACY_NOTIFICATION_UPDATE',
    reset_privacy_notification_update:
      'RESET_SETTINGS_PRIVACY_NOTIFICATION_UPDATE',
    update_privacy_notification_success:
      'SETTINGS_PRIVACY_NOTIFICATION_UPDATE_SUCCESS',
    update_privacy_notification_error:
      'SETTINGS_PRIVACY_NOTIFICATION_UPDATE_ERROR',
  },
};
